import React, { Component } from "react"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import GamerMatchmakerPage from "../../pages/GamerMatchmakerPage";
import GamerMatchmakerProfile from "./GamerMatchmakerProfile";

// Visit Account Tabs
class GamerMatchmakerTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: undefined,
            userdata: undefined,
        }

        // These are the eventKeys of the tabs for non-new users.
        const tabs = [
            `${this.props.gamer}'s profile`,
        ]
        this.state.activeKey = tabs[0]

        // If there is a hash in the URL specifying which tab, go to that tab.
        if (!window.location.hash.empty) {
            const removedHash = window.location.hash.substring(1)
            const index = tabs.findIndex(x => x === removedHash)
            if (index >= 0)
                this.state.activeKey = tabs[index]
        }

        window.addEventListener(
            "hashchange",
            () => {
                const removedHash = window.location.hash.substring(1)
                const index = tabs.findIndex(x => x === removedHash)
                if (index >= 0)
                    this.setState({ activeKey: tabs[index] })
            },
            false,
        );
    }

    render() {
        return (
            <>
                <>
                    <Tabs
                        activeKey={this.state.activeKey}
                        onSelect={(k) => window.location.hash = `#${k}`}
                        transition={false}
                        id="visit-account-tabs"
                        className="mb-2 pb-2"
                        justify
                    >
                        <Tab eventKey={`${this.props.gamer}'s profile`} title="Profile" tabClassName="tab-color">
                            <GamerMatchmakerProfile
                                fbsAuth={this.props.fbsAuth}
                                fbsFirestore={this.props.fbsFirestore}
                                user={this.props.user}
                                username={this.props.username}
                                userdata={this.props.userdata}
                                gamer={this.props.gamer}
                                platformChoice={this.props.platformChoice}
                                neededChoice={this.props.neededChoice}
                                startChoice={this.props.startChoice}
                                objectiveChoice={this.props.objectiveChoice}
                                commsChoice={this.props.commsChoice}
                                sessionChoice={this.props.sessionChoice}
                                skillChoice={this.props.skillChoice}
                                ageChoice={this.props.ageChoice}
                                genderChoice={this.props.genderChoice}
                                locationChoice={this.props.locationChoice}
                                languageChoice={this.props.languageChoice}
                                personalityChoice={this.props.personalityChoice}
                                occupationChoice={this.props.occupationChoice}
                                educationChoice={this.props.educationChoice}
                                longtermChoice={this.props.longtermChoice}
                                gameChoice={this.props.gameChoice}
                                interestChoice={this.props.interestChoice}
                            />
                        </Tab>
                    </Tabs>
                </>
            </>
        );
    }
}

export default GamerMatchmakerTabs;
