// The page where users can reset their password if they
// forgot it and are not currently logged in.
import { Button, Alert } from "react-bootstrap";
import React, { Component } from "react"
import {
    onAuthStateChanged,
    sendPasswordResetEmail,
} from "firebase/auth";
import {
    logout,
    setUser
} from "../utils.js"
import BasicNavbar from "../components/Navbar/Navbar";

class ResetPasswordPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: undefined,
            userdata: undefined,
            email: undefined,
            showResetPasswordAlert: false, // shows success alert & instructions for resetting pasword
        }

        this.logout = this.logout.bind(this)
        this.setUser = this.setUser.bind(this)
        this.resetPassowrd = this.resetPassowrd.bind(this)

    
        onAuthStateChanged(this.props.fbsAuth, async user => {
            console.log('fbsAuth1:', this.props.fbsAuth);
            if (user) {
                await this.setUser(user)
            }
            else {
                this.logout();
            }
        })
    }

    setUser = setUser;
    logout = logout;

    async resetPassowrd() {
        console.log('fbsAuth:', this.props.fbsAuth);
        await sendPasswordResetEmail(this.props.fbsAuth, this.state.email)
            .then(() => {
                console.log("Password Reset Email Sent!");
                // Displays Success alert for resseting password
                this.setState({showResetPasswordAlert: true})
            })
            .catch((error) => {
                console.error(`Reauthentication error: ${error.code} - ${error.message}`);
                window.alert("Something went wrong, sorry! Please make sure you entered the correct email and try again. If the problem persists, reach out to our help staff at contact@indemni.io")
            });
    }

    render() {
        return (
            <>  
                <BasicNavbar
                    user={this.state.user}
                    userdata={{...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata}}
                    universityName={(this.state.readonlyUserdata && this.state.readonlyUserdata.inTheUniveristyProgram) ? this.state.readonlyUserdata.universityName : "University"}
                />
                <div className="d-flex fluid vh-100 w-100 justify-content-center page-fade-animation">
                    <div
                        style={{"max-width":"800px"}}
                        className="text-center"
                    >
                        <h1 style={{"margin-top":"3em", "font-weight":"700", "text-decoration":"underline"}}>
                            Reset Your Password
                        </h1>
                        <h4
                            style={{"color":"#fdffd7"}}
                            className="p-3"
                        >
                            If you have forgotten your password, input your email below and click "Reset Password" to have an email sent to you to reset it.
                        </h4>
                        <div
                            style={{"max-width":"800px", "padding":"1em"}}
                            className="d-flex flex-column justify-content-between align-items-center change-settings-justify-small-screens"
                        > 
                            <div
                                className="form-group my-3"
                                style={{"width":"100%"}}
                            >
                                <input
                                    type="email"
                                    style={{"width":"100%"}}
                                    className="form-control"
                                    value={this.state.email}
                                    onChange={(x) => this.setState({ email: x.target.value })}
                                    placeholder="Enter your Indemni email"
                                />
                            </div>
                            {!this.state.showResetPasswordAlert ? (
                                <>
                                    <Button
                                        className="game-details-information fw-bold px-1 me-3 change-settings-width-percentage-small-screens change-settings-add-margin-left-small-screens"
                                        style={{"width":"80%", "border": "1px solid"}}
                                        onClick={this.resetPassowrd}
                                    >
                                        Reset Password
                                    </Button> 
                                </>
                            ) : (
                                <>
                                    {/* Shows success alert message telling user what to do next to finish resetting password */}
                                    <Alert
                                        variant="success" 
                                        style={{"width":"100%"}}
                                        onClose={() => 
                                            this.setState({showResetPasswordAlert: false })
                                        }
                                        dismissible
                                    >
                                        <Alert.Heading> Password Reset Email sent to {this.state.email} </Alert.Heading>
                                        <p>
                                            Head over to your email to finish resetting your password!
                                        </p>
                                        <hr />
                                        <p className="mb-0" style={{"font-style":"italic"}}>
                                            * Be sure to check your spam folder, or wait a minute, if you cant find the email.
                                        </p>
                                        </Alert>
                                </>
                            )}                                                          
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ResetPasswordPage;
