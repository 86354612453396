import React, { Component } from "react"
import { Button, Form, Modal, Stack } from "react-bootstrap"
import { updateDoc, doc, getDoc, getDocs, arrayUnion, query, where, collection, collectionGroup } from "@firebase/firestore";
import { Pencil, PersonPlus, PersonVcard } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

class SocialSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEditing: false,
            bio: "",
            show: false,
            otherUsername: "",
            otherUser: undefined,
            err: false,
            friends: [],
            profile: "",
            profileparam: [],
            searchTerm: "", // Search for users by name
            foundUser: null, // Is set to the doc userdata of searched for user
        }

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleKey = this.handleKey.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.loadFriends = this.loadFriends.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.searchForUsers = this.searchForUsers.bind(this);
        this.resetSearchForUsers = this.resetSearchForUsers.bind(this);
    }

    async componentDidMount() {
        try {
            const userDocRef = doc(this.props.fbsFirestore, "Users", `${this.props.userdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
            const userDoc = await getDoc(userDocRef);
            if (await userDoc.exists) {
                const userData = userDoc.data();
                const userBio = userData.bio;
                this.setState({ bio: userBio });
            }
        } catch (error) {
            console.error('Error fetching bio:', error);
        }

        this.loadFriends();
    }

    handleInputChange(event) {
        this.setState({ bio: event.target.value });
    }

    // Handles the updating of a user's bio in the db
    async handleKeyDown(event) {
        if (event.key === 'Enter') {
            this.setState({ isEditing: false });
            try {
                // Reward the Xp to the user for writing the bio one time via cloud function rewardWriteBio
                if (this.props.userdata.hasWrittenBio === undefined) {
                    const idToken = await this.props.fbsAuth.currentUser.getIdToken(/* forceRefresh */ true)
                    const response = await fetch("https://rewardwritebio-cuw3q2hq5q-uc.a.run.app", {
                        method: "post",
                        headers: {
                            "Accept": "application/json",
                            "Content-Type": "application/json",
                            // Custom auth header required to authorize call.
                            "Authorization": `ID ${idToken}`,
                        },

                        // Serialize request body as JSON, as specified by Content-Type header.
                        body: JSON.stringify({
                            address: this.props.addressChange ? this.props.addressChange : this.props.address,
                        })
                    })
                    if (response.ok) {
                        console.log(`Bio Xp Rewarded ${response.status}: ${response.statusText}`)
                    }
                    else {
                        console.log(`Error Rewarding Bio Xp ${response.status}: ${response.statusText}`)
                    }
                }
                //  Defualt - add the bio text to the user's "PublicReadonlyPrivateWritable" doc
                const userDocRef = doc(this.props.fbsFirestore, "Users", `${this.props.userdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
                await updateDoc(userDocRef, { bio: this.state.bio });
            } catch (error) {
                console.error('Error updating bio:', error);
            }
        }
    }
    
    // Modal state contorl
    handleClose() {
        this.setState({show: false});
    }

    // Modal state control
    handleShow() {
        this.setState({show: true});
    }

    // Searches db where selected username string equals that username.
    async handleSearch() {
        const {otherUsername} = this.state;
        const q = query(
            collectionGroup(this.props.fbsFirestore, "Readonly"),
            where("username", "==", otherUsername)
        );
        try {
            const querySnapshot = await getDocs(q);
            if (querySnapshot.empty) {
                // No user found with the provided username
                window.alert(`User ${otherUsername} not found: The input is case-sensitive, so be sure to enter the exact username.`);
            } else {
                querySnapshot.forEach((doc) => {
                    this.setState({ otherUser: doc.data() });
                });
            }
        } catch (error) {if (error) alert("User Not Found")}
    }

    // On enter, searches for users to add as friend's with handleSearch above.
    handleKey(e) {
        if (e.code === "Enter") {
            e.preventDefault();
            this.handleSearch();
        }
    }

    // When clicking on the user it creates friends in the database.
    // The selected user (friend) is also updated/added to contacts for messaging purposes.
    // Otherwise, adds there names to friends and then calls loadFriends.
    async handleSelect() {
        // Checks if user is trying to add thenmselves
        if(this.props.userdata.username === this.state.otherUsername) {
            window.alert("You can't add or message yourself! Lol.")
            this.setState({
                otherUser: undefined,
                otherUsername: "",
            });
            return;
        }

        // Rewards xp first-time adding a friend via cloud function rewardAddFriend
        if (this.props.userdata.hasAddedFriend === undefined) {
            const idToken = await this.props.fbsAuth.currentUser.getIdToken(/* forceRefresh */ true)
            const response = await fetch("https://rewardaddfriend-cuw3q2hq5q-uc.a.run.app", {
                method: "post",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json",
                    // Custom auth header required to authorize call.
                    "Authorization": `ID ${idToken}`,
                },
            
                // Serialize request body as JSON, as specified by Content-Type header.
                body: JSON.stringify({
                    userNumber: this.props.userdata.userNumber,
                    address: this.props.addressChange ? this.props.addressChange : this.props.address,
                })
            })
            if (response.ok) {
                console.log(`Added Friend Xp Reward ${response.status}: ${response.statusText}`)
            }
            else {
                console.log(`Error Adding Friend Reward ${response.status}: ${response.statusText}`)
            }
        }

        // Checks if the user has already added the friend
        if (this.props.userdata.friends.includes(this.state.otherUsername)) {
           window.alert("User Already added - If trying to add them as a friend, this can be done in your Home Profile page.")
           this.setState({
               otherUser: undefined,
               otherUsername: "",
           });
           return;
        }
        // Updates user docs to include selected friend in field db friends & contacts.
        const addFriendDocRef = await doc(this.props.fbsFirestore, "Users", `${this.props.userdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
        const addContactDocRef = await doc(this.props.fbsFirestore, "Users", `${this.props.userdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0");
        try {
            await updateDoc(addFriendDocRef, { friends: arrayUnion(this.state.otherUsername) })
        } catch (error) {
            console.log(error)
        };
        try {
            await updateDoc(addContactDocRef, { contacts: arrayUnion(this.state.otherUsername) })
        } catch (error) {
            console.log(error)
        };

        // Update state variables
        this.setState({
            friends: this.state.friends.concat(this.state.otherUsername),
            otherUser: undefined,
            otherUsername: "", 
        });
    }

    async loadFriends() {
        const friendsDocRef = doc(this.props.fbsFirestore, "Users", `${this.props.userdata.userNumber}`, "PublicReadonlyPrivateWritable", "0")
        try {
            const friendsSnapshot = await getDoc(friendsDocRef)
            this.setState({ friends: friendsSnapshot.data().friends });
        } catch (error) {
            console.error('Error loading friends:', error);
        }
    }

    // Changes the timestamp.now to a real js object and transformed to an
    // actual number and date.
    formatDate(date) {
        const options = { month: 'short', day: 'numeric', year: 'numeric' };
        return date.toLocaleDateString('default', options);
    }

    // Filters and Searches for a user by username - used onClick of "Search" button
    async searchForUsers() {
        const {searchTerm} = this.state;
        if (searchTerm) {

            const searchQuery = query(
                collectionGroup(this.props.fbsFirestore, "Readonly"),
                where("username", "==", searchTerm)
            );
    
            const querySnapshot = await getDocs(searchQuery);
            if (querySnapshot.empty) {
                window.alert(`User ${searchTerm} not found: The input is case-sensitive, so be sure to enter the exact username.`);
                return false;
            }
    
            querySnapshot.forEach((userdoc) => {
                this.setState({ foundUser: userdoc.data() })
            });
        }
    }

    // Resets input and state variables back to default i.e, cancels search for a specific user.
    resetSearchForUsers() {
        this.setState({
            searchTerm: '',
            foundUser: null,
        });
    };

    render() {
        const { isEditing, bio, show, otherUsername, otherUser, friends } = this.state;
        return (
          	<>
                <Stack 
                    gap={2} 
                    className="col-12 fluid mx-auto"
                >
                    <div className="p-2 home-content-width-mobile">
                        <div 
                            className="d-flex flex-column align-items-center justify-content-center" 
                            style={{"margin-bottom":".5em", "margin-right":"2.5em"}}
                        >
                            <span
                                className="mb-3 mt-2"
                                style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%", "text-wrap":"pretty"}}
                            >
                                Search For Users
                            </span>
                            <div className="d-flex flex-row justify-content-between">
                                <div
                                    className="navuser justify-content-between"
                                    style={{"width":"100%"}}
                                >
                                    {/* Searches for users in db - onClick of username, takes currentUser to vistingHomePage of foundUser (just like friends).
                                    Shows input until a user in the db is searched for, then shows the searched for user when foundUser now no longer
                                    ... equals null. The function this.resetSearchForUser, resets states to default and shows input again.  */}
                                    <div className="d-flex align-items-center">
                                        <div>
                                            { !this.state.foundUser 
                                              ?
                                                <input
                                                    className="navsearchform home-profile-placeholder px-3 py-2 home-input-width-mobile home-profile-search-medium-screen"
                                                    style={{"width":"275px", "height":"30px", "margin-left":"12em", "background-color":"#938f8f7a", "color":"#ede7e7"}}
                                                    type="text"
                                                    onChange={(e) => {
                                                        if (this.state.foundUser !== undefined) {
                                                            this.setState({ foundUser: undefined });
                                                        }
                                                        this.setState({searchTerm: e.target.value })
                                                    }}
                                                    value={this.state.searchTerm}
                                                    placeholder="Search for user's & visit their profile..."
                                                />
                                            : null
                                            }
                                            { this.state.foundUser
                                              ? (
                                                <Link 
                                                    className="home-profile-anchor-remove" 
                                                    to={`/visitaccount?gamer=${this.state.searchTerm}`}
                                                >
                                                    <div 
                                                        style={{"border":"none", "margin-left":"1em", "padding":"1em", "border-radius":"10px 10px 10px 10px", 
                                                        "box-shadow":"0px 0px 5px 0px #d9dddaab", "height":"30px", "width":"275px"}}
                                                        className="navsearchbutton-alternate" 
                                                    >
                                                        <img 
                                                            className="navuserchat"
                                                            style={{"height":"25px", "width":"25px"}} 
                                                            src="/logo-color.png" 
                                                        />
                                                        <div className="navuserchatInfo">
                                                            <span style={{"font-size": "1em", "font-weight": "700", "color":"black"}}>
                                                                Visit {this.state.searchTerm}'s Profile
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Link>
                                              )
                                              : null
                                            }
                                        </div>
                                        <div className="home-flex-mobile">
                                            <Button
                                                className="ms-3 me-2 nav-search-users-button home-profile-font-mobile"
                                                style={{"font-size":".8em", "font-weight":"600", "color":"#88ff87b3"}}
                                                onClick={this.searchForUsers}
                                            >
                                                Search
                                            </Button>
                                            <Button
                                                className="ms-1 nav-search-users-button home-profile-font-mobile"
                                                style={{"font-size":".8em", "font-weight":"600", "color":"#e98181e3"}}
                                                onClick={this.resetSearchForUsers}
                                            >
                                                Reset
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{"width":"100%"}}
                                className=" d-flex flex-column align-items-center justify-content-center mb-3"
                            >
                                <span
                                    className="mt-3"
                                    style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%", "text-wrap":"pretty"}}
                                >
                                    My Friends <span style={{"text-decoration":"none"}}>😊</span>
                                </span>
                                <Button  
                                    style={{"font-size":".9em", "font-weight":"600", "width":"300px", "text-align":"center"}} 
                                    className="standard-action-button home-profile-add-friend-medium-screen mt-3" 
                                    onClick={this.handleShow}
                                >
                                    Add Friends<PersonPlus style={{"color":"white", "margin-left":".25em", "margin-bottom":".2em", "align-items":"center"}}/>
                                </Button>
                            </div>
                            <Modal 
                                show={show} 
                                onHide={this.handleClose}
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Add Friends</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group className="mb-3" controlId="search-users-form">
                                            <Form.Label>Type a username & press enter - Click the name to add to friends</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Search, press enter, & then click on the user to add to friends..."
                                                autoFocus
                                                onKeyDown={this.handleKey}
                                                onChange={(e) => {
                                                    if (this.state.otherUser !== undefined) {
                                                        this.setState({ otherUser: undefined });
                                                    }
                                                    this.setState({ otherUsername: e.target.value });
                                                }}
                                                value={otherUsername}
                                            />
                                        </Form.Group>
                                    </Form>
                                    {otherUser && (
                                        <div 
                                            className="navsearchbutton" 
                                            onClick={this.handleSelect}
                                        >
                                            <img 
                                                className="navuserchat" 
                                                src="/logo-color.png" 
                                                alt="Indemni Logo" 
                                            />
                                            <div className="navuserchatInfo">
                                                <span style={{"font-size": "16px", "font-weight": "500"}}>
                                                    {otherUsername}
                                                </span>
                                            </div>
                                        </div>)}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button 
                                        variant="secondary" 
                                        onClick={this.handleClose}
                                    >
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                        <div 
                            className="d-flex flex-column tab-textbox-hover-variation asset-tab-scroll asset-tab-scrollbar py-3 px-3" 
                            style={{"height":"600px", "margin-right":"2.5em", "flex-wrap":"wrap"}}
                        >   
                            { friends.length === 0 &&
                                <div 
                                    className="d-flex flex-column align-items-center justify-content-center"
                                    style={{"width":"100%", "font-size":"1.1em"}}
                                >
                                    <span
                                        className="home-profile-content-font-mobile"
                                        style={{"font-size":"1.15em", "text-align":"center", "width":"100%", "color":"#c0bbbb"}}
                                    >
                                        Use the search bar above to find other user's. Click on their name to go to their profile's. Username must be an exact match, so check your spelling. 
                                    </span>
                                    <span
                                        className="home-profile-content-font-mobile"
                                        style={{"font-size":"1.15em", "text-align":"center", "width":"100%", "color":"#c0bbbb"}}
                                    >
                                        * Once added here, clicking on your friend's username will take you to their profile.  
                                    </span>
                                </div>
                            }
                            { friends.length !== 0 && friends.map((friend, index) => (
                                <div 
                                    className="navuserchatInfo d-flex align-item-center" 
                                    key={index}
                                >
                                    <span style={{"padding": ".5em", "width":"100%"}}>
                                        <Link to={`/visitaccount?gamer=${friend}`}>
                                            <Button 
                                                className="tab-textbox-buttons desktop-increase-demo-gamer-profile-text-size"
                                                style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "width":"100%", "font-weight":"600"}}
                                            >
                                                {friend}
                                                <PersonVcard style={{"color":"white", "margin-left":".25em", "margin-bottom":".2em", "align-items":"center"}}/>
                                            </Button>
                                        </Link>
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </Stack>
			</>
        );
	}
}

export default SocialSearch;
