// Soon to be the Content Page, to show reviews, updates on Indemni, etc.
import React, { Component } from "react"
import { onAuthStateChanged, signOut } from "firebase/auth";
import BasicNavbar from "../components/Navbar/Navbar"
import { doc, collectionGroup, where, query, getDocs, getDoc, onSnapshot } from "firebase/firestore";
import { Row } from "react-bootstrap";

class AskADevExplorePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: undefined,
            userdata: undefined,
        }

        this.logout = this.logout.bind(this)
        this.setUser = this.setUser.bind(this)

        this.setUpMessageListener = this.setUpMessageListener.bind(this);

        onAuthStateChanged(this.props.fbsAuth, async user => {
            if (user) {
                //console.log(`User ${user.uid} logging in (email: ${user.email})`)
                await this.setUser(user);
                await this.setUpMessageListener();
            }
            else {
                this.logout();
                // Sends the user back to LandingPage immediately if they aren't logged in
                // ToDo: Make sure this doesn't bug out anything, and also add to every page that we require user auth.
                // window.location.href = '/';
            }
        })
    }

    // Unsubscribe from Firestore listener.
    componentWillUnmount() {
        if (this.unsubscribeMessageListener)
            this.unsubscribeMessageListener();
    } 

    logout() {
        /* If not logged in, don't try to log out. */
        if (this.state.user === undefined) return;

        signOut(this.props.fbsAuth)
            .then(() => {
                this.setState({
                    user: undefined,
                    readonlyUserdata: undefined,
                    writableUserdata: undefined,
                    hiddenWritableUserdata: undefined
                }, () => {
                    console.log("Logged out!")
                })
            })
            .catch((error) => {
                // TODO: What do we do if signing out fails?
                console.error("Could not sign out!")
                console.error(`FIREBASEAUTH (${error.code}): ${error.message}`)
                console.error(error)
            })
    }

    async setUser(newUser) {
        return new Promise(async resolve => {
            // Somewhat validate new user
            if (typeof newUser !== "object" || typeof newUser.uid === "undefined") resolve(false);

            const userReadonlyQuery = query(
                collectionGroup(this.props.fbsFirestore, "Readonly"),
                where("uid", "==", newUser.uid)
            );

            const userReadonlySnapshots = await getDocs(userReadonlyQuery);
            if (userReadonlySnapshots.empty) {
                console.error(`Login failed: Could not find user in database with uid == ${newUser.uid}`)
                resolve(false)
                return;
            }

            const userReadonlySnapshot = userReadonlySnapshots.docs[0]
            if (!await userReadonlySnapshot.exists()) {
                console.error(`Login failed: Could not find user in database with uid == ${newUser.uid}`)
                resolve(false)
                return;
            }

            const userReadonlyData = userReadonlySnapshot.data()

            const userNumber = userReadonlyData.userNumber
            const userNumberString = `${userNumber}`

            const userWritableSnapshot = await getDoc(doc(this.props.fbsFirestore, "Users", userNumberString, "PublicReadonlyPrivateWritable", "0"));
            const userWritableData = userWritableSnapshot.data();

            const userHiddenSnapshot = await getDoc(doc(this.props.fbsFirestore, "Users", userNumberString, "PublicHiddenPrivateWritable", "0", "Inbox", "0"));
            const userHiddenData = userHiddenSnapshot.data();

            this.setState({
                user: newUser,                          // Firebase Authentication user object
                readonlyUserdata: userReadonlyData,     // /Users/<userNumber>/Readonly/0
                writableUserdata: userWritableData,     // /Users/<userNumber>/PublicReadonlyPrivateWritable/0
                hiddenWritableUserdata: userHiddenData, // /Users/<userNumber>/PublicHiddenPrivateWritable/0
            }, () => {
                resolve(true)
            })
        })
    }

    // Listen to changes in the Firestore collection where messages are and passed to Navbar
    setUpMessageListener() {
        const currentUser = this.state.readonlyUserdata.username;
        const InboxDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0");
        const unsubscribe = onSnapshot(InboxDocRef, (doc) => {
            if (doc.exists()) {
                const messages = doc.data().messages || [];
                // Applies the filter to messages where toName matches the current user's name
                const userMessages = messages.filter((message) => message.toName === currentUser);
                // Saves filtered out messages in state to be updated in markMessageAsRead
                const filteredOutMessages = messages.filter((message) => message.toName !== currentUser);
                this.setState({ filteredOutMessages });
                userMessages.sort((a, b) => b.timeSent - a.timeSent);
                // Counts the number of unread messages.
                const unreadCount = userMessages.filter((message) => !message.read).length;
                // Checks privacy settings & removes unreadCount if the user has any
                if (doc.data().receiveMessages === false) {
                    this.setState({unreadCount: null, messages: userMessages, hideMessages: true })
                }
                // If no privacy settings - sets default unreadCount
                else {
                    this.setState({ unreadCount, messages: userMessages  })
                }
                console.log("Sorted Message Data:", userMessages);
            }
        });
        this.unsubscribeMessageListener = unsubscribe;
    }


    render() {
        // TODO TODO: Add search bar to top that allows calling "toGame", basically.
        return (
            <>
            <BasicNavbar 
            user={this.state.user} userdata={{...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata}}
            unreadCount={this.state.unreadCount}/>
            <Row
            style={{"margin-right":"0", "margin-left":"0"}}
            className="vh-100 fluid d-flex flex-grow-1 justify-content-center align-items-center glow page-fade-animation">
                <h1
                className="p-3"
                style={{"font-weight":"700", "font-style":"italic", "text-align":"center", "margin-bottom":"6em"}}>
                    Coming Soon ... Talk directly with the devs from your favorite games!
                </h1>
            </Row>
            </>
        )
    }
}

export default AskADevExplorePage;
