import React, { Component } from "react"
import {
    Button,
    Modal,
    Dropdown,
    Stack,
    Pagination,
} from "react-bootstrap"
import {
    doc,
    getDoc,
    updateDoc,
    arrayUnion,
    arrayRemove,
} from "firebase/firestore"

class UniversityEvents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            postUploadText: "",
            postDisplayText: "",
            editingPostText: "",
            subjectUploadText: "",
            subjectDisplayText: "",
            editingsubjectText: "",
            postTextCooldown: false,
            editingPost: false,
        }
    }

    render() {

        return (
            <>
                <div
                    className="body-content visting-home-page-fade-animation change-explore-body-content-small-screen explore-body-content-padding-mobile
                    explore-body-content-padding-large ask-a-gamer-explore-padding-left-right-1-mobile"
                    style={{"font-size":".88em"}}
                >
                    <div
                        className="d-flex flex-column align-items-center justify-content-center"
                        style={{"text-align":"center", "width":"100%", "padding":"1em", "font-size":"1.5em", "height":"600px","font-weight":"700", "margin-top":"-5em"}}                        
                    >
                        Coming Soon! We will be adding club events and online activities here in the coming weeks.
                    </div>
                </div>
            </>
        );
    }
}

export default UniversityEvents;