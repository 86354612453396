import { Component } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { PersonGear, PersonBadge } from "react-bootstrap-icons";

// NOTE: Expects "links" array property.
// A link is an object { url: "/string", name: "string" }
// NOTE: Expects "rightSide" prop that takes in a react element to
// render on the right side of the navbar, in the top right.
// newUserLinks are shown when db field newUser: true - newUser db field is deleted after onboarding process completes in HomePage
// levelOne prop is passed from HomePage to show defaultLinks during final onboarding animations
class BasicNavbar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showNavbar: false, // Hides the Navbar for 1.1 (1 sec was to fast sometimes lol) sec to prevent jarring change of links during onAuth change.

            defaultLinks: () => [
                    { url: "/", name: "Login", className: this.props.user ? "display-remove" : "center-and-flex-important" },
                    // { url: "/inbox",
                    //   name: this.props.unreadCount > 0 ? `Inbox (${this.props.unreadCount})` : "Inbox",
                    //   className: this.props.unreadCount > 0 ? "inbox-new-message" : ""
                    // },
                    { url: "/home", name: "LFG", className: "center-and-flex-important" },
                    { url: "/gamermatchmaker", name: "Gamer Matchmaker", className: "center-and-flex-important" },
                    { url: "/account", name: "Gamer Profile", className: "center-and-flex-important" },
                    { url: "/gamerlounge",
                        name: this.props.unreadCount > 0 ? `Gamer Lounge (${this.props.unreadCount })` : "Gamer Lounge",
                        className: this.props.unreadCount > 0 ? `center-and-flex-important inbox-new-message` : `center-and-flex-important`,
                    },
                    { url: "/myuniversity", name: `${this.props.universityName} Gaming`, className: "center-and-flex-important" },
                    // { url: "/leaderboards", name: "Leaderboards", className: "center-and-flex-important" },
                    // { url: "/news", name: "News", className: "center-and-flex-important" },
                    { url: "/faq", name: "FAQ", className: "center-and-flex-important" },
                    { url: "/settings", name: `My Settings`, className: this.props.user ? "center-and-flex-important remove-for-tablet-and-desktop" : "display-remove" },

                ],
            defaultRightSide: () => this.props.user
                ?   
                    <div>
                      <a className={ this.props.userdata ? "text-muted text-decoration-none remove-for-mobile-nav" : "display-remove"} href="/settings">
                         {this.props.userdata.username} 
                          <PersonGear className="ms-1 remove-for-mobile-nav" style={{"color":"white", "align-items":"center"}}/>
                      </a>
                    </div>
                :   
                    <div>
                        <a className="text-muted text-decoration-none remove-for-mobile-nav" href="/#sign-up">
                             Sign Up ! 
                             <PersonBadge className="ms-1 remove-for-mobile-nav" style={{"color":"white", "align-items":"center"}}/>
                        </a>
                    </div>
        }
    }

    componentDidMount() {
        // Sets 1.1 second timeout before displaying Navbar
        setTimeout(() => {
            this.setState({ showNavbar: true });
        }, 1100);
    }

    render() {
        if (!this.state.showNavbar) {
            return (
                <Navbar
                    className="border-bottom border-1"
                    variant="dark"
                    expand="lg"
                    sticky="top"
                    style={{
                        "background-color": "#000",
                        "border-color": "#f8f8f8"
                    }}
                >
                    <Container className="mx-4 remove-margin-for-mobile-nav">
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Brand className="navbar-image-container-mobile" href="/">
                            <img className="me-1" src="/favicon.ico" alt="Indemni Logo" width={32} />
                            <span className="remove-for-mobile">Indemni</span>
                        </Navbar.Brand>
                    </Container>
                </Navbar>
            )
        }

        return (
            <Navbar
                className="border-bottom border-1" variant="dark" expand="lg" sticky="top"
                style={{"background-color": "#000", "border-color": "#f8f8f8", }}
            >
                <Container className="mx-4 remove-margin-for-mobile-nav">
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    { this.props.user
                      ?
                        <Navbar.Brand className="navbar-image-container-mobile" href="/">
                            <img className="me-1" src="/favicon.ico" alt="Indemni Logo" width={32} />
                            <span className="remove-for-mobile">Indemni</span>
                        </Navbar.Brand>
                      :
                        // TODO: Works, but this is wonky with removal based on media queries, should probably update it.
                        <Navbar.Brand className="navbar-image-container-mobile" href="/#sign-up">
                            <span className="text-muted text-decoration-none remove-for-tablet-and-desktop">
                                 Sign Up ! 
                                 <PersonBadge className="ms-1" style={{"color":"white", "align-items":"center"}}/>
                            </span>
                            <img className="me-1 remove-for-mobile-nav" src="/favicon.ico" alt="Indemni Logo" width={32} />
                            <span className="remove-for-mobile-nav">Indemni</span>
                        </Navbar.Brand>
                    }

                    <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav activeKey={window.location.pathname} className="me-auto nav-fade-animation">
                                { this.props.links
                                    ? this.props.links.map(link => <Nav.Link style={{"margin-right":".25em", "margin-left":".25em"}} className={link.className}  href={link.url}>{link.name}</Nav.Link> )
                                    : this.state.defaultLinks().map(link => <Nav.Link style={{"margin-right":".25em", "margin-left":".25em"}} className={link.className}  href={link.url}>{link.name}</Nav.Link> )
                                }
                            </Nav> 
                    </Navbar.Collapse>
                    <Navbar.Text className="nav-fade-animation">
                        { this.props.rightSide
                              ? this.props.rightSide
                              : this.state.defaultRightSide()
                        }
                    </Navbar.Text>
                </Container>
            </Navbar>
        );
    }
}

export default BasicNavbar;
