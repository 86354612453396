import React, { Component } from "react"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import SocialLFGPost from "./SocialLFGPost";
import SocialLFGPostChat from "./SocialLFGPostChat";

class SocialLFGPostTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: undefined,
            userdata: undefined,
        }

        // These are the eventKeys of the tabs for non-new users.
        const tabs = [
            "my-group",
            "my-group-chat",
        ]
        this.state.activeKey = tabs[0]

        // If there is a hash in the URL specifying which tab, go to that tab.
        if (!window.location.hash.empty) {
            const removedHash = window.location.hash.substring(1)
            const index = tabs.findIndex(x => x === removedHash)
            if (index >= 0)
                this.state.activeKey = tabs[index]
        }

        window.addEventListener(
            "hashchange",
            () => {
                const removedHash = window.location.hash.substring(1)
                const index = tabs.findIndex(x => x === removedHash)
                if (index >= 0)
                    this.setState({ activeKey: tabs[index] })
            },
            false,
        );
    }

    render() {
        return (
            <>
                <>
                    <Tabs
                        activeKey={this.state.activeKey}
                        onSelect={(k) => window.location.hash = `#${k}`}
                        transition={false}
                        id="social-lfg-tabs"
                        className="mb-2 pb-2"
                        justify
                    >
                        <Tab eventKey="my-group" title="My Posted Group" tabClassName="tab-color">
                            <SocialLFGPost
                                fbsAuth={this.props.fbsAuth}
                                fbsFirestore={this.props.fbsFirestore}
                                user={this.props.user}
                                username={this.props.username}
                                userdata={this.props.userdata}
                                answererData={this.props.answererData}
                                profile={this.props.profile}
                                myGroupMessages={this.props.myGroupMessages}
                                myGroupMembers={this.props.myGroupMembers}
                            />
                        </Tab>
                        <Tab
                            eventKey="my-group-chat"
                            title="My Group's Chat"
                            tabClassName="tab-color"
                        >
                            <SocialLFGPostChat
                                fbsAuth={this.props.fbsAuth}
                                fbsFirestore={this.props.fbsFirestore}
                                user={this.props.user}
                                username={this.props.username}
                                userdata={this.props.userdata}
                                messages={this.props.messages}
                                unreadCount={this.props.unreadCount}
                                gamerIsLive={this.props.gamerIsLive}
                                queue={this.props.queue}
                                queueLength={this.props.queueLength}
                                updatedQueueLength={this.props.updatedQueueLength}
                                startChat={this.props.startChat}
                                endChat={this.props.endChat}
                                playAudio={this.props.playAudio}
                                chatStarted={this.props.chatStarted}
                                profile={this.props.profile}
                                myGroupMessages={this.props.myGroupMessages}
                                myGroupMembers={this.props.myGroupMembers}
                            />
                        </Tab>
                    </Tabs>
                </>
            </>
        );
    }
}

export default SocialLFGPostTabs;
