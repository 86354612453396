// The profile the users see when they view
// other users accounts.
import React, { Component } from "react"
import {
    Button,
    Dropdown,
    Stack,
    Pagination,
} from "react-bootstrap"
import {
    updateDoc,
    doc,
    getDoc,
    arrayRemove,
    arrayUnion,
} from "@firebase/firestore";
import { Pencil, Check2Square } from "react-bootstrap-icons";
import { joinGroup, leaveGroupAlterData, updateJoinedGroupsDateAndCount } from "../../utils.js";

class VisitAccountProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cooldown: false, // Conditional to set timeout of .5s to allow DB to update and prevent user activity before it ends.
            groupIsFull: false, // set to true during joinLFGPost if the group the user is trying to join is full.
            groupJoined: false, // set to true if succesful in joining the group.

            justLeftVisitedUsersGroup: false, // set if the current user just left the visited user's group, so that they can rejoin.
        }

        this.joinLFGPost = this.joinFGPost.bind(this);
        this.leaveGroup = this.leaveGroup.bind(this);
        this.leaveVisitedUsersGroup = this.leaveVisitedUsersGroup.bind(this);

        this.formatDate = this.formatDate.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.passesFilters = this.passesFilters.bind(this);
        this.displayGame = this.displayGame.bind(this);
        this.renderFormattedText = this.renderFormattedText.bind(this);
        this.playAudio = this.playAudio.bind(this);
    }

    // Calls removePostData() from utils.js.
    // Removes the users LFG Post from the people array for the specific game
    // and updates their user doc with isLFG === false
    async leaveGroup(gameNumber) {
        this.setState({ cooldown: true })
        // Check if the user is currently in another group and remove them from that
        // group if they are. Then put them in the new group.
        if(this.props.userdata.joinedGroupOne !== -1) {
            console.log("Removing User from previous group")
            // Calls removePosttData with appropriate parameters
            await leaveGroupAlterData(
                this.props.userdata.inTheUniveristyProgram,
                this.props.fbsFirestore,
                this.props.userdata.userNumber,  // User number
                this.props.userdata.joinedGroupOneGameNumber,  // Game Number
                this.props.userdata.joinedGroupOne, // Joined Group Number
            );
            console.log("Removed User from previous group");
        } else {
            console.log("No previous group to remove user from");
        }

        console.log("Attempting to add user to new group");
        await this.joinFGPost();
    }

    // Called if the current user is already in the visited users group.
    async leaveVisitedUsersGroup(gameNumber) {
            this.setState({ cooldown: true })
            await leaveGroupAlterData(
                this.props.userdata.inTheUniveristyProgram,
                this.props.fbsFirestore,
                this.props.userdata.userNumber,  // User number
                this.props.userdata.joinedGroupOneGameNumber,  // Game Number
                this.props.userdata.joinedGroupOne, // Joined Group Number
            );
            this.setState({justLeftVisitedUsersGroup: true, cooldown: false})
        }


    // Calls this util Function joinGroup().
    // Updates the Games Collection, the GroupChats collection for the
    // user who post the group (visited user), and updates the current User's User Collection.
    // * We first check if the group is full, before calling the function and throw
    // an error and conditional if it is full.
    async joinFGPost() {
        const { fbsFirestore, gamer, userdata, fbsAuth } = this.props;

        if(gamer.userNumber === userdata.joinedGroupOne) {
            window.alert("Your are already in this group! Try messaging the user to see if they want to play. Or, try leaving/joining a different group. * We will make sure to update the UI soon to prevent this error from occuring.");
            this.setState({ cooldown: false, groupJoined: false });
            console.log("The Group you are trying to join is full.");
            return;
        }
        
        // First check if the visited user is still looking for a group aka the LFG post exists.
        if (gamer.isLFG) {
            const gameDocRef = doc(fbsFirestore, "Games", `${gamer.looking_game_id}`);
            const gameDocSnapshot = await getDoc(gameDocRef);
            if (gameDocSnapshot.exists()) {
                const gameData = gameDocSnapshot.data();
                let newPeople = gameData.people;
    
                // Find the index of the object corresponding to the userNumber
                const gameIndex = newPeople.findIndex(person => person.userNumber === gamer.userNumber);
                if (gameIndex !== -1) {
                    const groupMembers = newPeople[gameIndex].groupMembers || [];
                    const totalGroupMembers = groupMembers.length - 1;
                    
                    if (gamer.looking_needed_number <= totalGroupMembers) {
                        window.alert("This group is now Full! Try messaging the user to see if you can still make it in or play with them later. Or, try joining a different group.");
                        this.setState({ groupIsFull: true });
                        console.log("The Group you are trying to join is full.");
                        return;
                    } else {
                        console.log("Attempting to join group:", gameIndex);
                        this.setState({ groupIsFull: false });
                        
                        // Checks for if the user added/updated their Discord name, gaming platform name
                        // or gaming platform username. If they did, we update their user doc and pass in the const
                        // value for joinGroup to be updated elsewhere.
                        // TODO: Update this later
                        // const discordName = this.state.updatedDiscordName ? this.state.discordNameUploadText : userdata.joinGroupDiscord;
                        // const platformName = this.state.updatedPlatformName ? this.state.platformNameUploadText : userdata.joinGroupPlatform;
                        // const platformUsername = this.state.updatedPlatformUsername ? this.state.platformUsernameUploadText : userdata.joinGroupPlatformUsername;

                        // Checks for if the user added/updated their Discord name, gaming platform name
                        // or gaming platform username. If they did, we update their user doc and pass in the const
                        // value for joinGroup to be updated elsewhere.
                        const discordName = userdata.joinGroupDiscord;
                        const platformName = userdata.joinGroupPlatform;
                        const platformUsername = userdata.joinGroupPlatformUsername;
    
                        // We also check if the user is currently in a group by looking at the field joinedGroupOne in their user data.
                        // If joinGroupOne equals -1 (which means the aren't in a group), we set update updateGroupOne to true, else false.
                        // const updateGroupOne = userdata.joinedGroupOne === -1;

                        // Calls utils function to update joined groups counts and dates.
                        try {
                            await updateJoinedGroupsDateAndCount(this.props.fbsFirestore, this.props.userdata.userNumber)
                            console.log("New Joined Groups Count/Date Completed!")
                        } catch (error) {
                            console.error("New Joined Groups Count/Date Failed!", error);
                        }
    
                        await joinGroup(
                            userdata.inTheUniveristyProgram,
                            fbsAuth,
                            fbsFirestore,
                            gamer.looking_game_id, // Game Number
                            gamer.userNumber,  // Visited User's number
                            gamer.username, // Visited User's username
                            userdata.userNumber, // Current User's number
                            userdata.username, // Current User's username
                            discordName,
                            platformName,
                            platformUsername,
                            true,
                        );
                        this.setState({ groupJoined: true, cooldown: false})

                    }
                } else {
                    console.log("Could not find the LFG:", gameIndex);
                    window.alert("Could not locate the LFG Post. Try messaging the user to see if you can play with them. Or, try joining a different group.");
                    this.setState({ groupJoined: false, cooldown: false})
                }
            }
        } else {
            console.log("User is not currently looking for a group");
            window.alert("This post is either full or no longer available. Try messaging the user to see if you can play with them. Or, try joining a different group.");
            this.setState({ groupJoined: false, cooldown: false})
        }
        this.setState({ cooldown: false})
    }

    // Called onCLick of certain actions that plays an mp3 file from the 'Public' folder.
    // Currently set to 20% volume for all files, but can be adjusted to be higher/per file accordingly.
    // Passed down as props to children components as well.
    // TODO (eventually - not a priority): Add a "Adjust Sound Effect Volume" option in Settings,
    // so that users can set audio.volume globally however they'd like.
    playAudio(source) {
        const audio = new Audio(source);
        audio.volume = 0.2;
        audio.play();
    };

    // Changes the firestore timestamp to a real js object date.
    // Called in displayPosts() to show when/how long ago a post was made.
    formatDate(date) {
        const now = new Date();
        const pastTime = new Date(date.seconds * 1000); // Converts Firestore timestamp to Date object
        const diff = now - pastTime; // Difference in milliseconds
        const diffMinutes = Math.floor(diff / 60000); // Converts to minutes
        const diffHours = Math.floor(diffMinutes / 60); // Converts to hours
        const diffDays = Math.floor(diffHours / 24); // Converts to days
        
        // Display render of date with user's timezone
        const options = { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        const formattedDate = pastTime.toLocaleDateString('default', options);
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
        // Shows how long ago the post was in minutes, hours, or days
        let timeAgo = '';
        if (diffMinutes < 60) {
            timeAgo = `${diffMinutes} min ago`;
        } else if (diffHours < 24) {
            timeAgo = `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
        } else {
            timeAgo = `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
        }
    
        return {
            formattedDate,
            timezone,
            timeAgo
        };
    }

    // Looks for %0A URL encoded line breaks and links.
    // Handles reformatting text to wrap links in <a> tags, and
    // shows the user visual spaces with <br /> if they pressed enter or
    // shift+enter as represented by "\n".
    renderFormattedText = (text) => {
        const linkRegex = /(?:https?|ftp):\/\/[^\s]+(?:\s|$)/g;
        const lines = text.split(/(?:\n|%0A)/);
      
        const elements = [];
      
        lines.forEach((line, lineIndex) => {
            const linkMatches = line.match(linkRegex);
            if (linkMatches) {
                let lastIndex = 0;

                linkMatches.forEach((linkMatch, index) => {
                    const linkStart = line.indexOf(linkMatch, lastIndex);
                    const linkEnd = linkStart + linkMatch.length;

                    // Adds the preceding text as regular text
                    elements.push(<span key={`text-${lineIndex}-${index}`}>{line.substring(lastIndex, linkStart)}</span>);

                    // Wraps the link in an anchor
                    elements.push(
                        <a
                            key={`link-${lineIndex}-${index}`}
                            className="change-link-color-chat"
                            style={{
                                wordWrap: 'break-word',
                                wordBreak: 'break-all',
                                whiteSpace: 'normal',
                            }}
                            href={decodeURIComponent(linkMatch)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                        {decodeURIComponent(linkMatch)}
                        </a>
                    );

                    // Updates the lastIndex to the end of the current link
                    lastIndex = linkEnd;
                });
            
                // Adds the remaining text after the last link
                elements.push(<span key={`text-${lineIndex}-last`}>{line.substring(lastIndex)}</span>);
            } else {
                // If no links, add the entire line as regular text
                elements.push(<span key={`text-${lineIndex}`}>{line}</span>);
            }

            // Add the line break after each line (except the last line)
            if (lineIndex < lines.length - 1) {
                elements.push(<br key={`br-${lineIndex}`} />);
            }
        });
      
        return elements;
    };

    // Return true if game matches the current filter(s) or nameIncludeFilter === ""
    passesFilters(game) {
        if (!game) return false;
    
        if (this.state.nameIncludeFilter !== "" && game.gameName.toLowerCase().includes(this.state.nameIncludeFilter.toLowerCase()) ) return true;
    }

    // Checks for search input and updates page.
    handleSearchChange(e) {
        const searchValue = e.target.value;
        this.setState({ nameIncludeFilter: searchValue, currentPage: 1 });
    };

    // Display of games when editing and choosing a game for LFG Post.
    displayGame(game) {
        if (this.passesFilters(game)) {
            return (
                    <>
                        <div className="me-2 px-2 explore-games-background ask-a-gamer-explore-margin-right-0-mobile">
                            <div
                                className="d-flex flex-column py-3 px-4 ask-a-gamer-game-list-border ask-a-gamer-explore-padding-left-right-halfem-mobile"
                                onClick={() => {
                                    this.setState({
                                        editingGameName: game.gameName,
                                        editingGameImage: game.gameImagePath,
                                        editingGameId: game.gameNumber,
                                        nameIncludeFilter: "",
                                    });
                                }}
                            >
                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                    <div
                                        className="d-inline-flex flex-row align-items-center justify-content-center flex-wrap ask-a-gamer-explore-flex-column-mobile"
                                        style={{"width":"100%"}}
                                    >
                                        <div
                                            className="d-flex flex-row align-items-center justify-content-center me-2 ask-a-gamer-explore-margin-right-left-0-tablet"
                                            style={{"width":"200px"}}
                                        >
                                            <img
                                                src={game.gameImagePath}
                                                width="250"
                                                alt="Video Game Cover Art"
                                                style={{"width":"100px"}}
                                                className="img-fluid ask-a-gamer-explore-image-tablet"
                                            />
                                            <h5
                                                style={{"font-size":"1em", "text-align":"center"}}
                                                className="mx-3"
                                            >
                                                {game.gameName}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <hr className="ask-a-gamer-answer-spotlight-hr"/>
                    </>
            )
        } else return null;
    }

    render() {
        const { formattedDate, timezone, timeAgo } = this.formatDate(this.props.gamer.postTime);

        return (
            <>
                <Stack
                    gap={2}
                    className="col-12 fluid mx-auto"
                >
                    { this.props.gamer.isLFG
                      ?
                        <div className="p-2 ask-a-gamer-profile-containers-mobile mt-3">
                            <div className="new-home-profile-flex-between">
                                <span style={{"font-size":"1.5em", "text-align":"center", "width":"100%", "text-wrap":"pretty"}}>
                                    <span style={{"text-decoration":"underline"}}>{this.props.gamer.username}'s Post</span>
                                    <br/>
                                    <span style={{"fontt-size":".9em"}}>{this.props.gamer.looking_gameName === "" ? "N/A": this.props.gamer.looking_gameName}</span>
                                </span>
                            </div>
                            <div
                                style={{"margin-bottom":"1em"}}
                                className={!this.state.isEditingLFGPost ? "d-flex flex-column tab-textbox-hover-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile" : "d-flex flex-column tab-textbox-edit-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile"}
                            >
                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                    <div
                                        className="d-inline-flex flex-row align-items-center flex-wrap desktop-display-flex-column"
                                        style={{"width":"100%"}}
                                    >
                                        <div
                                            style={{"width":"100%"}}
                                            className="d-flex flex-column align-items-center justify-content-center"
                                        >   
                                            { this.state.groupJoined
                                              ?
                                                <Button
                                                    className="d-flex flex-column mb-3 mx-2 p-2 align-items-center change-padding-tablet font-size-75-tablet width-auto-tablet"
                                                    style={{"background-color":"#1616169e", "width":"200px", "text-wrap":"balance", "fonts-zie":"1.2em"}}
                                                    href="/gamerlounge#joined-groups"
                                                >
                                                    Go to Group
                                                </Button>
                                              :
                                               <>
                                                    { this.props.userdata.joinedGroupOne === this.props.gamer.userNumber && !this.state.justLeftVisitedUsersGroup
                                                      ?
                                                        <h5
                                                            style={{"text-wrap":"balance", "color":"#ffff77", "font-size":"1.5em", "font-weight":"625"}}
                                                            className="mb-3"
                                                        >
                                                            In {this.props.gamer.username}'s Group!
                                                        </h5>
                                                      :
                                                        <h5
                                                            style={{"text-wrap":"balance", "color":"#5bff87", "font-size":"1.5em", "font-weight":"625"}}
                                                            className="mb-3"
                                                        >
                                                            Currently LFG!
                                                        </h5>
                                                    }
                                                </>
                                            }
                                        </div>
                                        { this.state.cooldown
                                          ?
                                            <div
                                                style={{"width":"100%"}}
                                                className="d-flex flex-column align-items-center justify-content-center"
                                            >
                                                <Button
                                                    className="d-flex flex-column mb-4 mx-1 p-1 align-items-center chat-with-gamer-button game-details-play desktop-increase-demo-gamer-profile-text-size"
                                                    style={{"width":"200px", "box-shadow":"#fffzfff82 0px 0px 10px 3px", "font-size":"1em", "font-weight":"550", "text-shadow":"black 0px 2px 2px", "text-wrap":"balance"}}
                                                    disabled={this.state.cooldown}
                                                >
                                                    Joining Group ... <div className="vote-circle-inline-loading-animation" />
                                                </Button>
                                            </div>
                                          :
                                            <div
                                                style={{"width":"100%"}}
                                                className="d-flex flex-column align-items-center justify-content-center"
                                            >
                                                { this.state.groupJoined
                                                  ?
                                                    <Button
                                                        className="d-flex flex-column mb-4 mx-1 p-1 align-items-center chat-with-gamer-button desktop-increase-demo-gamer-profile-text-size"
                                                        style={{"width":"200px", "box-shadow":"#fffzfff82 0px 0px 10px 3px", "font-size":"1em", "font-weight":"550", "text-shadow":"black 0px 2px 2px", "text-wrap":"balance"}}
                                                        disabled
                                                    >
                                                        Group Joined!
                                                    </Button>
                                                  :
                                                    <>
                                                        { this.props.userdata.joinedGroupOne === this.props.gamer.userNumber && !this.state.justLeftVisitedUsersGroup
                                                          ?
                                                            <Button
                                                                className="d-flex flex-column mb-4 mx-1 p-1 align-items-center account-profile-cancel desktop-increase-demo-gamer-profile-text-size"
                                                                style={{"width":"200px", "box-shadow":"#fffzfff82 0px 0px 10px 3px", "font-size":"1em", "font-weight":"550", "text-shadow":"black 0px 2px 2px", "text-wrap":"balance"}}
                                                                disabled={this.state.cooldown || !this.props.user || this.props.userdata.userNumber === this.props.gamer.userNumber}
                                                                onClick={async () => {
                                                                    await this.leaveVisitedUsersGroup();
                                                                }}
                                                            >
                                                                Leave Group
                                                            </Button>
                                                          :
                                                            <Button
                                                                className="d-flex flex-column mb-4 mx-1 p-1 align-items-center chat-with-gamer-button game-details-play desktop-increase-demo-gamer-profile-text-size"
                                                                style={{"width":"200px", "box-shadow":"#fffzfff82 0px 0px 10px 3px", "font-size":"1em", "font-weight":"550", "text-shadow":"black 0px 2px 2px", "text-wrap":"balance"}}
                                                                disabled={this.state.cooldown || !this.props.user || this.props.userdata.userNumber === this.props.gamer.userNumber}
                                                                onClick={async () => {
                                                                    this.playAudio('/EnterQueueSound.mp3');
                                                                    await this.leaveGroup();
                                                                }}
                                                            >
                                                                Join Group
                                                            </Button>
                                                        }
                                                    </>
                                                }
                                            </div>
                                        }
                                        <div className="d-flex flex-column align-items-center justify-content-center me-2 glow ask-a-gamer-explore-margin-right-left-0-tablet desktop-no-margin-x" >
                                            <h5
                                                style={{"text-wrap":"balance", "text-align":"center"}}
                                                className="my-2"
                                            >
                                                {this.props.gamer.looking_gameName === "" ? "N/A": this.props.gamer.looking_gameName}
                                            </h5>
                                            <img
                                                src={this.props.gamer.looking_gameImagePath === "" ? "/UserNeverPostedSendMessage.png": this.props.gamer.looking_gameImagePath}
                                                width="250"
                                                alt="Video Game Cover Art"
                                                style={{"width":"400px"}}
                                                className="img-fluid ask-a-gamer-explore-image-tablet"
                                            />
                                            <h5 className="my-2">
                                                {this.props.gamer.looking_platform === "" ? "Any": this.props.gamer.looking_platform}
                                            </h5>
                                        </div>
                                        <div
                                            className="d-flex flex-column flex-grow align-items-center justify-content-center ask-a-gamer-profile-margins-game-spotlight-mobile desktop-increase-posted-time-demo-gamer-profile"
                                            style={{"flex-grow":"1"}}
                                        >
                                            <span
                                                className="my-2"
                                                style={{"font-size":".9em", "text-align":"center", "text-wrap":"balance"}}
                                            >
                                                Posted on: {this.props.gamer.postTime === "" ? "N/A": formattedDate + ` (${timeAgo})`}
                                            </span>
                                            <span
                                                className="list-of-stats"
                                                style={{"color":"#75f7fff0", "font-weight":"600"}}
                                            >
                                                Needed: {this.props.gamer.looking_needed === "" ? "1 Gamer": this.props.gamer.looking_needed}
                                            </span>
                                            <span
                                                className="list-of-stats"
                                                style={{"color":"#75f7fff0", "font-weight":"600"}}
                                            >
                                                Start Time: {this.props.gamer.looking_time === "" ? "Now": this.props.gamer.looking_time}
                                            </span>
                                        </div>
                                        <hr
                                            className="ask-a-gamer-answer-spotlight-hr"
                                            style={{"width":"100%"}}
                                        />
                                        <div
                                            className="d-flex flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                            style={{"flex-grow":"1"}}
                                        >
                                            <p style={{"width":"100%"}}>
                                                <div className="d-flex flex-row flex-wrap justify-content-between overflow-auto" >
                                                    <small
                                                        style={{"font-size":"1.2em", "color":"#ffea99", "text-align":"center", "text-shadow":"0px -3px 2px #000000", "width":"100%", "text-wrap":"pretty"}}
                                                        className="desktop-increase-demo-gamer-profile-text-size desktop-change-width-lfgposts-lookingMessage-900"
                                                    >
                                                        "{this.props.gamer.looking_message === "" ? `${this.props.gamer.username} did not specify what they wanted to.` : this.renderFormattedText(this.props.gamer.looking_message)}"
                                                     </small>
                                                </div>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <hr className="ask-a-gamer-answer-spotlight-hr"/>
                                <div
                                    className="d-flex flex-row"
                                    style={{"flex-wrap":"wrap", "border":"none"}}
                                >
                                    <div className="navuserchatInfo d-flex align-item-center ask-a-gamer-profile-mastered-games-tablet change-demo-gamer-profile-objective-comms-commitment">
                                        <div className="desktop-display-flex-column desktop-width-200">
                                            <h5 style={{"font-size":"1.1em"}}>Objective - {this.props.gamer.looking_objective_flexRequire === "" ? "Flexible": this.props.gamer.looking_objective_flexRequire}</h5>
                                            <div className="d-flex flex-row align-items-center justify-content-between desktop-margin-top-1">
                                                <span style={{"padding": ".75em", "width":"100%"}}>
                                                    <div
                                                        className="tab-textbox-buttons justify-content-around px-3 py-2 text-center"
                                                        style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                                    >
                                                        {this.props.gamer.looking_objective === "" ? "Any": this.props.gamer.looking_objective}
                                                        <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="desktop-display-flex-column desktop-width-200">
                                            <h5 style={{"font-size":"1.1em"}}>Skill & Comms</h5>
                                            <div className="d-flex flex-row align-items-center justify-content-between desktop-margin-top-1">
                                                <span style={{"padding": ".75em"}}>
                                                    <div
                                                        className="tab-textbox-buttons justify-content-around px-2 py-2 text-center"
                                                        style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em", "width":"105px"}}
                                                    >
                                                        {this.props.gamer.looking_skill === "" ? "Any": this.props.gamer.looking_skill}
                                                        <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                    </div>
                                                </span>
                                                <span style={{"padding": ".75em"}}>
                                                    <div
                                                        className="tab-textbox-buttons justify-content-around px-2 py-2 text-center"
                                                        style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em", "width":"105px"}}
                                                    >
                                                        {this.props.gamer.looking_comms === "" ? "Any": this.props.gamer.looking_comms}
                                                        <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                        <div className="desktop-display-flex-column desktop-width-200">
                                            <h5
                                                className="desktop-width-max-content"
                                                style={{"font-size":"1.1em"}}
                                            >
                                                Session Length - {this.props.gamer.looking_session_flexRequire === "" ? "Flexible": this.props.gamer.looking_session_flexRequire}
                                            </h5>
                                            <div className="d-flex flex-row align-items-center justify-content-between desktop-margin-top-1">
                                                <span style={{"padding": ".75em", "width":"100%"}}>
                                                    <div
                                                        className="tab-textbox-buttons justify-content-around px-3 py-2 text-center"
                                                        style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                                    >
                                                        {this.props.gamer.looking_session === "" ? "Any": this.props.gamer.looking_session}
                                                        <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    :
                        <div className="p-2 ask-a-gamer-profile-containers-mobile mt-3">
                            <div className="new-home-profile-flex-between">
                            { this.props.gamer.looking_gameImagePath === ""
                              ?
                                <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%", "text-wrap":"pretty"}}>
                                    {this.props.gamer.username} Has Never Posted
                                </span>
                              :
                                <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%", "text-wrap":"pretty"}}>
                                    {this.props.gamer.username}'s Last Post - {this.props.gamer.looking_gameName === "" ? "Unknown Game": this.props.gamer.looking_gameName}
                                </span>
                            }
                            </div>
                            <div
                                style={{"margin-bottom":"1em"}}
                                className={!this.state.isEditingLFGPost ? "d-flex flex-column tab-textbox-hover-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile" : "d-flex flex-column tab-textbox-edit-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile"}
                            >
                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                    <div
                                        className="d-inline-flex flex-row align-items-center flex-wrap desktop-display-flex-column"
                                        style={{"width":"100%"}}
                                    >
                                        { this.props.gamer.looking_gameImagePath !== "" &&
                                            <div
                                                style={{"width":"100%"}}
                                                className="d-flex flex-column align-items-center justify-content-center"
                                            >
                                                <h5
                                                    style={{"text-wrap":"balance", "color":"#ff9d75", "font-size":"1.5em", "font-weight":"625"}}
                                                    className="mb-3"
                                                >
                                                    Not Currently Posted
                                                </h5>
                                            </div>
                                        }
                                        <div className="d-flex flex-column align-items-center justify-content-center me-2 glow ask-a-gamer-explore-margin-right-left-0-tablet desktop-no-margin-x" >
                                            { this.props.gamer.looking_gameName !== "" &&
                                                <h5
                                                    style={{"text-wrap":"balance", "text-align":"center"}}
                                                    className="my-2"
                                                >
                                                    {this.props.gamer.looking_gameName === "" ? "Unknown Game": this.props.gamer.looking_gameName}
                                                </h5>
                                            }
                                            <img
                                                src={this.props.gamer.looking_gameImagePath === "" ? "UserNeverPostedSendMessage.png": this.props.gamer.looking_gameImagePath}
                                                width="250"
                                                alt="Video Game Cover Art"
                                                style={{"width":"400px"}}
                                                className="img-fluid ask-a-gamer-explore-image-tablet"
                                            />
                                            { this.props.gamer.looking_gameImagePath !== "" &&
                                                <h5 className="my-2">
                                                    {this.props.gamer.looking_platform === "" ? "Any": this.props.gamer.looking_platform}
                                                </h5>
                                            }
                                        </div>
                                        <div
                                            className="d-flex flex-column flex-grow align-items-center justify-content-center ask-a-gamer-profile-margins-game-spotlight-mobile desktop-increase-posted-time-demo-gamer-profile"
                                            style={{"flex-grow":"1"}}
                                        >
                                            { this.props.gamer.looking_gameImagePath !== "" &&
                                                <span
                                                    className="my-2"
                                                    style={{"font-size":".9em", "text-align":"center", "text-wrap":"balance"}}
                                                >
                                                    Last Post: {formattedDate + ` (${timeAgo})`}
                                                </span>
                                            }
                                            { this.props.gamer.looking_gameImagePath !== "" &&
                                                <span
                                                    className="list-of-stats"
                                                    style={{"color":"#75f7fff0", "font-weight":"600"}}
                                                >
                                                    Needed: {this.props.gamer.looking_needed === "" ? "1 Gamer": this.props.gamer.looking_needed}
                                                </span>
                                            }
                                            { this.props.gamer.looking_gameImagePath !== "" &&
                                                <span
                                                    className="list-of-stats"
                                                    style={{"color":"#75f7fff0", "font-weight":"600"}}
                                                >
                                                    Start Time: {this.props.gamer.looking_time === "" ? "Now": this.props.gamer.looking_time}
                                                </span>
                                            }
                                        </div>
                                        { this.props.gamer.looking_gameImagePath !== "" &&
                                            <>
                                                <hr
                                                    className="ask-a-gamer-answer-spotlight-hr"
                                                    style={{"width":"100%"}}
                                                />
                                                <div
                                                    className="d-flex flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                                    style={{"flex-grow":"1"}}
                                                >
                                                    <p style={{"width":"100%"}}>
                                                        <div className="d-flex flex-row flex-wrap justify-content-between overflow-auto" >
                                                            <small
                                                                style={{"font-size":"1.2em", "color":"#ffea99", "text-align":"center", "text-shadow":"0px -3px 2px #000000", "width":"100%", "text-wrap":"pretty"}}
                                                                className="desktop-increase-demo-gamer-profile-text-size desktop-change-width-lfgposts-lookingMessage-900"
                                                            >
                                                               "{this.props.gamer.looking_message === "" ? `${this.props.gamer.username} did not specify what they wanted to.` : this.renderFormattedText(this.props.gamer.looking_message)}"
                                                             </small>
                                                        </div>
                                                    </p>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                                { this.props.gamer.looking_gameImagePath !== "" &&
                                    <>
                                        <hr className="ask-a-gamer-answer-spotlight-hr"/>
                                        <div
                                            className="d-flex flex-row"
                                            style={{"flex-wrap":"wrap", "border":"none"}}
                                        >
                                            <div className="navuserchatInfo d-flex align-item-center ask-a-gamer-profile-mastered-games-tablet change-demo-gamer-profile-objective-comms-commitment">
                                                <div className="desktop-display-flex-column desktop-width-200">
                                                    <h5 style={{"font-size":"1.1em"}}>Objective - {this.props.gamer.looking_objective_flexRequire === "" ? "Flexible": this.props.gamer.looking_objective_flexRequire}</h5>
                                                    <div className="d-flex flex-row align-items-center justify-content-between desktop-margin-top-1">
                                                        <span style={{"padding": ".75em", "width":"100%"}}>
                                                            <div
                                                                className="tab-textbox-buttons justify-content-around px-3 py-2 text-center"
                                                                style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                                            >
                                                                {this.props.gamer.looking_objective === "" ? "Recruiting": this.props.gamer.looking_objective}
                                                                <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="desktop-display-flex-column desktop-width-200">
                                                    <h5 style={{"font-size":"1.1em"}}>Skill & Comms</h5>
                                                    <div className="d-flex flex-row align-items-center justify-content-between desktop-margin-top-1">
                                                        <span style={{"padding": ".75em"}}>
                                                            <div
                                                                className="tab-textbox-buttons justify-content-around px-2 py-2 text-center"
                                                                style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em", "width":"105px"}}
                                                            >
                                                                {this.props.gamer.looking_skill === "" ? "Any": this.props.gamer.looking_skill}
                                                                <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                            </div>
                                                        </span>
                                                        <span style={{"padding": ".75em"}}>
                                                            <div
                                                                className="tab-textbox-buttons justify-content-around px-2 py-2 text-center"
                                                                style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em", "width":"105px"}}
                                                            >
                                                                {this.props.gamer.looking_comms === "" ? "Any": this.props.gamer.looking_comms}
                                                                <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="desktop-display-flex-column desktop-width-200">
                                                    <h5
                                                        className="desktop-width-max-content"
                                                        style={{"font-size":"1.1em"}}
                                                    >
                                                        Session Length - {this.props.gamer.looking_session_flexRequire === "" ? "Flexible": this.props.gamer.looking_session_flexRequire}
                                                    </h5>
                                                    <div className="d-flex flex-row align-items-center justify-content-between desktop-margin-top-1">
                                                        <span style={{"padding": ".75em", "width":"100%"}}>
                                                            <div
                                                                className="tab-textbox-buttons justify-content-around px-3 py-2 text-center"
                                                                style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                                            >
                                                                {this.props.gamer.looking_session === "" ? "Any": this.props.gamer.looking_session}
                                                                <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    }
                    {/* END of LFG Post */}

                    {/* START of Game Stats */}
                    <div className="p-2 ask-a-gamer-profile-containers-mobile">
                        <div className="new-home-profile-flex-between">
                            <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%", "text-wrap":"pretty"}}>
                                {this.props.gamer.looking_gameName === "" ? "No Game": this.props.gamer.looking_gameName} Highlights
                            </span>
                        </div>
                        { this.props.gamer.looking_gameName === ""
                          ?
                            <div
                                style={{"margin-bottom":"1em"}}
                                className="d-flex flex-column tab-textbox-hover-variation py-2 px-4 ask-a-gamer-profile-padding-right-1-mobile"
                            >
                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                    <div
                                        className="d-inline-flex flex-row align-items-center flex-wrap"
                                        style={{"width":"100%"}}
                                    >
                                        <div
                                            className="d-flex flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                            style={{"flex-grow":"1"}}
                                        >
                                            <p
                                                className="mt-1"
                                                style={{"width":"100%"}}
                                            >
                                                <div
                                                    style={{"font-size":"1.4em"}}
                                                    className="d-flex flex-row flex-wrap justify-content-center overflow-auto"
                                                >
                                                    <small
                                                        style={{"color":"#75f7fff0", "font-size":"1.2em", "font-weight":"600", "text-wrap":"pretty"}}
                                                        className="desktop-increase-demo-gamer-profile-text-size desktop-demo-gamer-profile-change-stats-gamertags"
                                                    >
                                                        &#128532;
                                                    </small>
                                                </div>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          :
                            <div
                                style={{"margin-bottom":"1em"}}
                                className="d-flex flex-column tab-textbox-hover-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile"
                            >
                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                    <div
                                        className="d-inline-flex flex-row align-items-center flex-wrap"
                                        style={{"width":"100%"}}
                                    >
                                        <div
                                            className="d-flex flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                            style={{"flex-grow":"1"}}
                                        >
                                            <p
                                                className="mt-1"
                                                style={{"width":"100%"}}
                                            >
                                                <div className="d-flex flex-row flex-wrap justify-content-between overflow-auto" >
                                                    <small
                                                        style={{"color":"#75f7fff0", "font-size":"1.2em", "font-weight":"600", "text-wrap":"pretty", "text-align":"center"}}
                                                        className="desktop-increase-demo-gamer-profile-text-size desktop-demo-gamer-profile-change-stats-gamertags"
                                                    >
                                                        👑 {this.props.gamer.looking_stat_one === "" ? `${this.props.gamer.username} didn't highlight anything.` : this.renderFormattedText(this.props.gamer.looking_stat_one)}
                                                    </small>
                                                </div>
                                                { this.props.gamer.looking_stat_two !== "" &&
                                                    <>
                                                        <hr/>
                                                        <div className="d-flex flex-row flex-wrap justify-content-between overflow-auto" >
                                                            <small
                                                                style={{"color":"#75f7fff0", "font-size":"1.2em", "font-weight":"600", "text-wrap":"pretty"}}
                                                                className="desktop-increase-demo-gamer-profile-text-size desktop-demo-gamer-profile-change-stats-gamertags"
                                                            >
                                                                👑 {this.props.gamer.looking_stat_two === "" ?  `${this.props.gamer.username}'s #2 highlight.` : this.renderFormattedText(this.props.gamer.looking_stat_two)}
                                                            </small>
                                                        </div>
                                                    </>
                                                }
                                                { this.props.gamer.looking_stat_three !== "" &&
                                                    <>
                                                        <hr/>
                                                        <div className="d-flex flex-row flex-wrap justify-content-between overflow-auto" >
                                                            <small
                                                                style={{"color":"#75f7fff0", "font-size":"1.2em", "font-weight":"600", "text-wrap":"pretty"}}
                                                                className="desktop-increase-demo-gamer-profile-text-size desktop-demo-gamer-profile-change-stats-gamertags"
                                                            >
                                                                👑 {this.props.gamer.looking_stat_three === "" ?  `${this.props.gamer.username}'s #3 highlight.` : this.renderFormattedText(this.props.gamer.looking_stat_three)}
                                                            </small>
                                                        </div>
                                                    </>
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {/* END of Game Stats */}
                    
                    {/* START of Ideal Teammate */}
                    <div className="p-2 ask-a-gamer-profile-containers-mobile mt-3">
                        <div className="new-home-profile-flex-between">
                            <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%"}}>
                                Ideal Teammate
                            </span>
                        </div>
                        <div
                            style={{"margin-bottom":"1em"}}
                            className="d-flex flex-column tab-textbox-hover-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile"
                        >
                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                <div
                                    className="d-inline-flex flex-row align-items-center flex-wrap"
                                    style={{"width":"100%"}}
                                >
                                    <div
                                        className="d-flex flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                        style={{"flex-grow":"1"}}
                                    >
                                        <p style={{"width":"100%", "margin-top":"1em"}}>
                                            <div className="d-flex flex-row flex-wrap justify-content-center overflow-auto" >
                                                <small
                                                    style={{"font-size":"1.2em", "color":"#ffea99", "text-align":"center", "text-shadow":"0px -3px 2px #000000", "text-wrap":"pretty", "width":"100%"}}
                                                    className="desktop-increase-demo-gamer-profile-text-size desktop-change-width-lfgposts-lookingMessage-900"
                                                >
                                                    "{this.props.gamer.looking_teammate_message === "" ? `${this.props.gamer.username} has not written about their ideal teammate.` : this.renderFormattedText(this.props.gamer.looking_teammate_message)}"
                                                </small>
                                            </div>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr className="ask-a-gamer-answer-spotlight-hr"/>
                            <div
                                className="d-flex flex-row"
                                style={{"flex-wrap":"wrap", "border":"none"}}
                            >
                                <div
                                    className="navuserchatInfo d-flex flex-column align-item-center ask-a-gamer-profile-mastered-games-tablet desktop-increase-font-1-quarter"
                                    style={{"width":"100%", "text-align":"center"}}
                                >
                                    <h5 style={{"font-size":"1.1em"}}>Playstyle - {this.props.gamer.looking_teammate_playstyle_flexRequire === "" ? "Flexible": this.props.gamer.looking_teammate_playstyle_flexRequire}</h5>
                                    <div className="d-flex flex-row align-items-center justify-content-between">
                                        <span style={{"padding": ".75em", "width":"100%"}}>
                                            <div
                                                className="tab-textbox-buttons justify-content-around px-3 py-2"
                                                style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                            >
                                                {this.props.gamer.looking_teammate_playstyle === "" ? "Any": this.props.gamer.looking_teammate_playstyle}
                                                <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                            </div>
                                        </span>
                                    </div>
                                    {/* TODO: This is different on Demo where "Personality - Require or Flexible", will need to update this somehow later */}
                                    <h5 style={{"font-size":"1.1em"}}>Personality - {this.props.gamer.looking_teammate_personality_flexRequire === "" ? "Flexible": this.props.gamer.looking_teammate_personality_flexRequire}</h5>
                                    <div className="d-flex flex-row align-items-center justify-content-between">
                                        <span style={{"padding": ".75em", "width":"100%"}}>
                                            <div
                                                className="tab-textbox-buttons justify-content-around px-3 py-2"
                                                style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                            >
                                                {this.props.gamer.looking_teammate_personality === "" ? "Any": this.props.gamer.looking_teammate_personality}
                                                <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                            </div>
                                        </span>
                                    </div>
                                    <h5 style={{"font-size":"1.1em"}}>Long-term Teammate?</h5>
                                    <div className="d-flex flex-row align-items-center justify-content-between">
                                        <span style={{"padding": ".75em", "width":"100%"}}>
                                            <div
                                                className="tab-textbox-buttons justify-content-around px-3 py-2"
                                                style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                            >
                                                {this.props.gamer.looking_teammate_longterm === "" ? "Maybe": this.props.gamer.looking_teammate_longterm}
                                                <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                            </div>
                                        </span>
                                    </div>
                                    <h5 style={{"font-size":"1.1em"}}>Gender</h5>
                                    <div className="d-flex flex-row align-items-center justify-content-between">
                                        <span style={{"padding": ".75em", "width":"100%"}}>
                                            <div
                                                className="tab-textbox-buttons justify-content-around px-3 py-2"
                                                style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                            >
                                                {this.props.gamer.looking_teammate_gender === "" ? "Any": this.props.gamer.looking_teammate_gender}
                                                <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                            </div>
                                        </span>
                                    </div>
                                    <h5 style={{"font-size":"1.1em"}}>Age</h5>
                                    <div className="d-flex flex-row align-items-center justify-content-between">
                                        <span style={{"padding": ".75em", "width":"100%"}}>
                                            <div
                                                className="tab-textbox-buttons justify-content-around px-3 py-2"
                                                style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                            >
                                                {this.props.gamer.looking_teammate_age === "" ? "Any": this.props.gamer.looking_teammate_age}
                                                <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* END of Ideal Teammate */}

                    {/* START of About/Bio */}
                    <div className="p-2 ask-a-gamer-profile-containers-mobile">
                        <div className="new-home-profile-flex-between">
                            <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%", "text-wrap":"pretty"}}>
                                About Me
                            </span>
                        </div>
                        <div
                            className={`d-flex flex-column align-items-center justify-content-center tab-textbox-hover-variation py-3 px-4  ${this.state.isEditing ? 'editing' : ''}`}
                            style={{"font-weight":"600", "text-align":"center"}}
                        >
                            <span
                                style={{"font-size":"1.2em", "color":"#ffea99", "text-align":"center", "text-shadow":"0px -3px 2px #000000", "text-wrap":"pretty"}}
                                className="desktop-increase-demo-gamer-profile-text-size desktop-change-width-lfgposts-lookingMessage-900"
                            >
                                "{this.props.gamer.bio === "" ? `${this.props.gamer.username} has not written about themselves yet.` :  this.renderFormattedText(this.props.gamer.bio)}"
                            </span>
                        </div>
                    </div>
                    {/* END of About/Bio */}

                    {/* START of Favorite Games*/}
                    <div className="p-2 ask-a-gamer-profile-containers-mobile">
                        <div className="new-home-profile-flex-between">
                            <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%", "text-wrap":"pretty"}}>
                                Favorite Games
                            </span>
                        </div>
                        <div
                            className="d-flex flex-row tab-textbox-hover-variation py-3 px-3 desktop-width-100-align-justify-center"
                            style={{"flex-wrap":"wrap", "border":"none"}}
                        >
                            { this.props.gamer.favoriteGamesOne === "" && this.props.gamer.favoriteGamesTwo === "" && this.props.gamer.favoriteGamesThree === ""
                              ?
                                <div
                                    className="navuserchatInfo d-flex align-item-center ask-a-gamer-profile-mastered-games-tablet desktop-width-100-align-justify-center"
                                >
                                    <span style={{"padding": "1em"}}>
                                        <div
                                            className="tab-textbox-buttons justify-content-around px-4 py-2"
                                            style={{"text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":"1.2em", "background-color":"inherit", "text-wrap":"pretty"}}
                                        >
                                              {this.props.gamer.username} has no favorite games.
                                        </div>
                                    </span>
                                </div>
                              :
                                <div
                                    className="navuserchatInfo d-flex align-item-center ask-a-gamer-profile-mastered-games-tablet desktop-width-100-align-justify-center"
                                >
                                    { this.props.gamer.favoriteGamesOne !== "" &&
                                        <>
                                            <span style={{"padding": "1em"}}>
                                                <div
                                                    className="tab-textbox-buttons justify-content-around px-4 py-2"
                                                    style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":"1.1em", "text-wrap":"pretty"}}
                                                >
                                                    {this.props.gamer.favoriteGamesOne === "" ? `${this.props.gamer.username}'s #1 favorite game.` :  this.renderFormattedText(this.props.gamer.favoriteGamesOne)}
                                                    <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                </div>
                                            </span>
                                        </>
                                    }
                                    { this.props.gamer.favoriteGamesTwo !== "" &&
                                        <>
                                            <span style={{"padding": "1em"}}>
                                                <div
                                                    className="tab-textbox-buttons justify-content-around px-4 py-2"
                                                    style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":"1.1em", "text-wrap":"pretty"}}
                                                >
                                                    {this.props.gamer.favoriteGamesTwo === "" ? `${this.props.gamer.username}'s #2 favorite game.` :  this.renderFormattedText(this.props.gamer.favoriteGamesTwo)}
                                                    <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                </div>
                                            </span>
                                        </>
                                    }
                                    { this.props.gamer.favoriteGamesThree !== "" &&
                                        <>
                                            <span style={{"padding": "1em"}}>
                                                <div
                                                    className="tab-textbox-buttons justify-content-around px-4 py-2"
                                                    style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":"1.1em", "text-wrap":"pretty"}}
                                                >
                                                    {this.props.gamer.favoriteGamesThree === "" ? `${this.props.gamer.username}'s #3 favorite game.` :  this.renderFormattedText(this.props.gamer.favoriteGamesThree)}
                                                    <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                </div>
                                            </span>
                                        </>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    {/* END of Favorite Games*/}
                    
                    {/* START of Platforms & Interests*/}
                    <div className="p-2 ask-a-gamer-profile-containers-mobile">
                        <div className="new-home-profile-flex-between">
                            <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%", "text-wrap":"pretty"}}>
                                Platforms & Interests
                            </span>
                        </div>
                        <div className="d-flex flex-column tab-textbox-hover-variation py-3 px-4 increase-home-profile-stats">
                            <div style={{"font-size":"1.1em", "font-weight":"600"}}>
                                { this.props.gamer.interestOne === "" && this.props.gamer.interestTwo === ""
                                  ?
                                    <div
                                        className="d-flex my-1 justify-content-around align-items-center desktop-increase-font-1-quarter"
                                        style={{"flex-wrap":"wrap"}}
                                    >
                                        { this.props.gamer.favoritePlatform === ""
                                          ?
                                            <span className="list-of-stats desktop-increase-minwidth-300">
                                                {this.props.gamer.username} Doesn't Have a Favorite Gaming Platform.
                                            </span>
                                          :
                                            <span className="list-of-stats desktop-increase-minwidth-300">
                                                {this.props.gamer.favoritePlatform === "" ? `${this.props.gamer.username}'s Main Gaming Platform.` : this.props.gamer.favoritePlatform}
                                            </span>
                                        }
                                            <span className="list-of-stats desktop-increase-minwidth-300">
                                                {this.props.gamer.username} Hasn't Added Their Interests.
                                            </span>
                                    </div>
                                  :
                                    <div
                                        className="d-flex my-1 justify-content-around align-items-center desktop-increase-font-1-quarter"
                                        style={{"flex-wrap":"wrap"}}
                                    >
                                        { this.props.gamer.favoritePlatform === ""
                                          ?
                                            <span className="list-of-stats desktop-increase-minwidth-300">
                                                {this.props.gamer.username} Doesn't Have a Favorite Gaming Platform.
                                            </span>
                                          :
                                            <span className="list-of-stats desktop-increase-minwidth-300">
                                                {this.props.gamer.favoritePlatform === "" ? `${this.props.gamer.username}'s Main Gaming Platform.` : this.props.gamer.favoritePlatform}
                                            </span>
                                        }
                                        {this.props.gamer.interestOne !== "" &&
                                            <span className="list-of-stats desktop-increase-minwidth-300">
                                                {this.props.gamer.interestOne === "" ? `${this.props.gamer.username}'s #1 Interest.` : this.props.gamer.interestOne}
                                            </span>
                                        }
                                        {this.props.gamer.interestTwo !== "" &&
                                            <span className="list-of-stats desktop-increase-minwidth-300">
                                                {this.props.gamer.interestTwo === "" ? `${this.props.gamer.username}'s #2 Interest.` : this.props.gamer.interestTwo}
                                            </span>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    {/* END of Platforms & Interests*/}
                    
                    {/* START of Gamertags & Socials*/}
                    <div className="p-2 ask-a-gamer-profile-containers-mobile">
                            <div className="new-home-profile-flex-between">
                                <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%", "text-wrap":"pretty"}}>
                                    Gamertags & Social Usernames
                                </span>
                            </div>
                        <div
                            style={{"margin-bottom":"1em"}}
                            className="d-flex flex-column tab-textbox-hover-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile"
                        >
                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                <div
                                    className="d-inline-flex flex-row align-items-center flex-wrap"
                                    style={{"width":"100%"}}
                                >
                                    { this.props.gamer.socialPlatformOne === "" && this.props.gamer.socialPlatformTwo === "" && this.props.gamer.socialPlatformThree === ""
                                      ?
                                        <div
                                            className="d-flex flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                            style={{"flex-grow":"1"}}
                                        >
                                            <p
                                                className="mt-1"
                                                style={{"width":"100%"}}
                                            >
                                                <div className="d-flex flex-row flex-wrap justify-content-between overflow-auto" >
                                                    <small
                                                        style={{"color":"#75f7fff0", "font-size":"1.2em", "font-weight":"600", "text-wrap":"pretty", "text-align":"center"}}
                                                        className="desktop-increase-demo-gamer-profile-text-size desktop-demo-gamer-profile-change-stats-gamertags"
                                                    >
                                                        {this.props.gamer.username} has not added and Gamertags or Socials
                                                    </small>
                                                </div>   
                                            </p>
                                        </div>
                                      :
                                        <div
                                            className="d-flex flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                            style={{"flex-grow":"1"}}
                                        >
                                            <p
                                                className="mt-1"
                                                style={{"width":"100%"}}
                                            >
                                                { this.props.gamer.socialPlatformOne !== "" &&
                                                    <>
                                                        <div className="d-flex flex-row flex-wrap justify-content-between overflow-auto" >
                                                            <small
                                                                style={{"color":"#75f7fff0", "font-size":"1.2em", "font-weight":"600", "text-wrap":"pretty"}}
                                                                className="desktop-increase-demo-gamer-profile-text-size desktop-demo-gamer-profile-change-stats-gamertags"
                                                            >
                                                                👑 {this.props.gamer.socialPlatformOne === "" ? "N/A" : this.props.gamer.socialPlatformOne} : {this.props.gamer.socialUsernameOne === "" ? `${this.props.gamer.username} has not added this Gamertag/Social.` : this.renderFormattedText(this.props.gamer.socialUsernameOne)}
                                                            </small>
                                                        </div>
                                                        <hr/>
                                                    </>
                                                }
                                                { this.props.gamer.socialPlatformTwo !== "" &&
                                                    <>
                                                        <div className="d-flex flex-row flex-wrap justify-content-between overflow-auto" >
                                                            <small
                                                                style={{"color":"#75f7fff0", "font-size":"1.2em", "font-weight":"600", "text-wrap":"pretty"}}
                                                                className="desktop-increase-demo-gamer-profile-text-size desktop-demo-gamer-profile-change-stats-gamertags"
                                                            >
                                                                👑 {this.props.gamer.socialPlatformTwo === "" ? "N/A" : this.props.gamer.socialPlatformTwo}: {this.props.gamer.socialUsernameTwo === "" ? `${this.props.gamer.username} has not added this Gamertag/Social.` : this.renderFormattedText(this.props.gamer.socialUsernameTwo)}
                                                            </small>
                                                        </div>
                                                        <hr/>
                                                    </>
                                                }
                                                { this.props.gamer.socialPlatformThree !== "" &&
                                                    <>
                                                        <div className="d-flex flex-row flex-wrap justify-content-between overflow-auto" >
                                                            <small
                                                                style={{"color":"#75f7fff0", "font-size":"1.2em", "font-weight":"600", "text-wrap":"pretty"}}
                                                                className="desktop-increase-demo-gamer-profile-text-size desktop-demo-gamer-profile-change-stats-gamertags"
                                                            >
                                                                👑 {this.props.gamer.socialPlatformThree === "" ? "N/A" : this.props.gamer.socialPlatformThree}: {this.props.gamer.socialUsernameThree === "" ? `${this.props.gamer.username} has not added this Gamertag/Social.` : this.renderFormattedText(this.props.gamer.socialUsernameThree)}
                                                            </small>
                                                        </div>
                                                    </>
                                                }
                                            </p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* END of Gamertags & Socials*/}

                    {/* START of Background*/}
                    <div className="p-2 ask-a-gamer-profile-containers-mobile">
                        <div className="new-home-profile-flex-between">
                            <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%", "text-wrap":"pretty"}}>
                                Background
                            </span>
                        </div>
                        <div
                            className="d-flex flex-row tab-textbox-hover-variation py-3 px-3"
                            style={{"flex-wrap":"wrap", "border":"none"}}
                        >
                            <div
                                className="navuserchatInfo d-flex flex-column align-item-center ask-a-gamer-profile-mastered-games-tablet desktop-increase-font-1-quarter"
                                style={{"width":"100%", "text-align":"center"}}
                            >
                                <h5 style={{"font-size":"1.1em"}}>Age</h5>
                                <div className="d-flex flex-row align-items-center justify-content-between">
                                    <span style={{"padding": ".75em", "width":"100%"}}>
                                        <div
                                            className="tab-textbox-buttons justify-content-around px-3 py-2"
                                            style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                        >
                                            {this.props.gamer.age === "" ? "Private": this.props.gamer.age}
                                            <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                        </div>
                                    </span>
                                </div>
                                <h5 style={{"font-size":"1.1em"}}>Gender</h5>
                                <div className="d-flex flex-row align-items-center justify-content-between">
                                    <span style={{"padding": ".75em", "width":"100%"}}>
                                        <div
                                            className="tab-textbox-buttons justify-content-around px-3 py-2"
                                            style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                        >
                                            {this.props.gamer.gender === "" ? "Private": this.props.gamer.gender}
                                            <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                        </div>
                                    </span>
                                </div>
                                <h5 style={{"font-size":"1.1em"}}>Occupation</h5>
                                <div className="d-flex flex-row align-items-center justify-content-between">
                                    <span style={{"padding": ".75em", "width":"100%"}}>
                                        <div
                                            className="tab-textbox-buttons justify-content-around px-3 py-2"
                                            style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                        >
                                            {this.props.gamer.occupation === "" ? "Private": this.props.gamer.occupation}
                                            <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                        </div>
                                    </span>
                                </div>
                                <h5 style={{"font-size":"1.1em"}}>Education</h5>
                                <div className="d-flex flex-row align-items-center justify-content-between">
                                    <span style={{"padding": ".75em", "width":"100%"}}>
                                        <div
                                            className="tab-textbox-buttons justify-content-around px-3 py-2"
                                            style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                        >
                                            {this.props.gamer.education === "" ? "Private": this.props.gamer.education}
                                            <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                        </div>
                                    </span>
                                </div>
                                <h5 style={{"font-size":"1.1em"}}>Location</h5>
                                <div className="d-flex flex-row align-items-center justify-content-between">
                                    <span style={{"padding": ".75em", "width":"100%"}}>
                                        <div
                                            className="tab-textbox-buttons justify-content-around px-3 py-2"
                                            style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                        >
                                            {this.props.gamer.location === "" ? "Private": this.props.gamer.location}
                                            <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                        </div>
                                    </span>
                                </div>
                                <h5 style={{"font-size":"1.1em"}}>Language</h5>
                                <div className="d-flex flex-row align-items-center justify-content-between">
                                    <span style={{"padding": ".75em", "width":"100%"}}>
                                        <div
                                            className="tab-textbox-buttons justify-content-around px-3 py-2"
                                            style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                        >
                                            {this.props.gamer.language === "" ? "Private": this.props.gamer.language}
                                            <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                        </div>
                                    </span>
                                </div>
                                <h5 style={{"font-size":"1.1em"}}>Weekly Playtime</h5>
                                <div className="d-flex flex-row align-items-center justify-content-between">
                                    <span style={{"padding": ".75em", "width":"100%"}}>
                                        <div
                                            className="tab-textbox-buttons justify-content-around px-3 py-2"
                                            style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                        >
                                            {this.props.gamer.weeklyPlaytime === "" ? "Private": this.props.gamer.weeklyPlaytime}
                                            <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                        </div>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* END of Background*/}

                </Stack>
            </>
        );
    }
}

export default VisitAccountProfile;
