import {
    collection,
    collectionGroup,
    doc,
    documentId,
    getCountFromServer,
    getDoc,
    getDocs,
    onSnapshot,
    query,
    runTransaction,
    setDoc,
    Timestamp,
    updateDoc,
    where,
    arrayUnion,
    serverTimestamp,
} from "firebase/firestore";

import { signOut } from "firebase/auth";

import { trackEvent } from "./mixpanelUtil";

import allGamesJSON from "./pages/playtoearngames.json"

export function validAddressString(addressString) {
    return typeof addressString === "string"
        && addressString.length === 42
        && addressString.startsWith("0x")
}

// RETURNS PROMISE
async function smartContractVisited(firestore, address) {
    const collectionRef = collection(firestore, "GameAssets")
    const snapshot = await getCountFromServer(query(
        collectionRef,
        where(documentId(), "==", address)
    ))
    return !!snapshot.data().count
}

// NOTE: THIS IS THE FUNCTION THAT DOES DATABASE WRITES
export async function visitSmartContract(firestore, moralis, evmChain, address) {
    if (!validAddressString(address)) return;

    // Skip already-visited smart contracts
    const visited = await smartContractVisited(firestore, address)
    if (visited) {
        console.log(`Smart contract ${address} already visited, using database...`)
        return;
    } else {
        console.log("Not visited---visiting...")
    }

    // For any smart contract not yet visited, populate database
    // with ALL da datums.

    // Setup database structure
    const gameRef = doc(firestore, "GameAssets", address)
    const gameAssetsRef = collection(gameRef, "Assets")

    // Moralis returns a maximum of 100 NFTs at a time; we must loop over each
    // page until we are done.
    let pageNumber = 0
    let cursor = null
    do {
        const response = await moralis.EvmApi.nft.getContractNFTs({
            "chain": evmChain.ETHEREUM,
            "format": "decimal",
            "mediaItems": false,
            "address": address,
            "cursor": cursor,
        })
        // TODO: Validate response further
        if (!response) return;

        //console.log(response)


        // Update API pagination cursor
        cursor = response.raw.cursor

        // Add data for next page of assets to database
        let games = {}

        for (const asset of response.result) {
            // Basic info
            games[asset.tokenId] = {}
            games[asset.tokenId].tokenId = asset.tokenId;
            games[asset.tokenId].topic = asset.name;
            games[asset.tokenId].symbol = asset.symbol;
            games[asset.tokenId].contractType = asset.contractType;
            games[asset.tokenId].uri = asset.tokenUri;

            // Blockchain
            if (asset.chain !== "undefined") {
                games[asset.tokenId].chain = {
                    hex: asset.chain.hex,
                    decimal: asset.chain.decimal,
                    name: asset.chain.name,
                    explorer: {
                        standard: asset.chain.explorer.standard,
                        name: asset.chain.explorer.name,
                        url: asset.chain.explorer.url,
                    }
                };
            }

            // Metadata
            if (typeof asset.metadata === "object" && typeof asset.metadata.attributes !== "undefined") {
                games[asset.tokenId].name = asset.metadata.name;
                games[asset.tokenId].description = asset.metadata.description;
                games[asset.tokenId].externalUrl = asset.metadata.external_url;
                games[asset.tokenId].imageUrl = asset.metadata.image;
            }
            games[asset.tokenId].lastMetadataSync = Timestamp.fromDate(asset.lastMetadataSync);

            // Game-specific Attributes
            games[asset.tokenId].attributes = [];
            for (const attribute of asset.metadata.attributes) {
                games[asset.tokenId].attributes.push(attribute)
            }
        }

        const gameAssetsCurrentRef = doc(gameAssetsRef, (pageNumber).toString())
        setDoc(gameAssetsCurrentRef, games)

        pageNumber += 1;

    } while (cursor !== "" && cursor !== null)

    // NOTE: For Firebase to actually return a document through queries and
    // stuff, it must have at least one field.
    setDoc(gameRef, {
        total: pageNumber * 100
    })
}

export async function ensureGameExistsInDatabase(fbsFirestore, gameName) {
    if (typeof gameName !== "string" || !gameName.length) return false;
    const reference = doc(fbsFirestore, "GameData", gameName)
    const snapshot = await getDoc(reference)
    if (!(await snapshot.exists())) {
        // FIXME: Pass `...game` in document object, recording vital information about the game.
        //        To do this, we must first fetch the game (by name) from the JSON list of games.
        const game = allGamesJSON.find(game => game.name === gameName)
        // `<array>.find()` returns undefined if element wasn't found. Don't
        // add games that aren't in the JSON!
        if (!game) return false;
        await setDoc(reference, {
            ...game,
            associatedAddresses: [], // Associated smart contracts
            voteUp: 0, // Total "up" votes
            voteDown: 0, // Total "down" votes
            ratingAggregate: 0, // Total sum of all ratings
            ratingCount: 0, // Total count of all ratings (FIXME: we may not need this; it may be easier to use getCountFromServer on Reviews subcollection)
        })
    }
    return true;
}

export async function callAuthorisedPostCloudFunction(fbsAuth, functionName, body) {
    if (typeof functionName !== "string" || !functionName.length) return undefined;
    const idToken = await fbsAuth.currentUser.getIdToken(/* forceRefresh */ true)
    const response = await fetch(`https://${functionName.toLowerCase()}-cuw3q2hq5q-uc.a.run.app`, {
        method: "post",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            // Custom auth header required to authorize call.
            "Authorization": `ID ${idToken}`,
        },

        // Serialize request body as JSON, as specified by Content-Type header.
        body: JSON.stringify(body)
    })
    return response;
}

// Meant to be used like "logout = logout;" as a member function of a class, pretty much.
/// @see setUser
export function logout() {
    // If not logged in, don't try to log out.
    if (this.state.user === undefined) return;

    signOut(this.props.fbsAuth)
        .then(() => {
            this.setState({
                user: undefined,
                readonlyUserdata: undefined,
                writableUserdata: undefined,
                hiddenWritableUserdata: undefined
            }, () => {
                console.log("Logged out!")
            })
        })
        .catch((error) => {
            // TODO: What do we do if signing out fails?
            console.error("Could not sign out!")
            console.error(`FIREBASEAUTH (${error.code}): ${error.message}`)
            console.error(error)
        })
}

// Meant to be used like "setUser = setUser;" as a member function of a class, pretty much.
/// @see logout
export async function setUser(newUser) {
    return new Promise(async resolve => {
        // Somewhat validate new user
        if (typeof newUser !== "object" || typeof newUser.uid === "undefined") resolve(false);

        const userReadonlyQuery = query(
            collectionGroup(this.props.fbsFirestore, "Readonly"),
            where("uid", "==", newUser.uid)
        );

        // * In here we detect the false sign-up sign in thing, where the user is authed, but
        // they do not have an account.
        // TODO: add logic here and make sure it works!
        const userReadonlySnapshots = await getDocs(userReadonlyQuery);
        if (userReadonlySnapshots.empty) {
            console.error(`Login failed: Could not find user in database with uid == ${newUser.uid}`)
            resolve(false)
            window.location.href = '/#sign-up';
            return;
        }
        const userReadonlySnapshot = userReadonlySnapshots.docs[0]
        if (!await userReadonlySnapshot.exists()) {
            console.error(`Login failed: Could not find user in database with uid == ${newUser.uid}`)
            resolve(false)
            return;
        }

        const userReadonlyData = userReadonlySnapshot.data()

        const userNumber = userReadonlyData.userNumber
        const userNumberString = `${userNumber}`

        const userWritableSnapshot = await getDoc(doc(this.props.fbsFirestore, "Users", userNumberString, "PublicReadonlyPrivateWritable", "0"));
        const userWritableData = userWritableSnapshot.data();

        const userHiddenSnapshot = await getDoc(doc(this.props.fbsFirestore, "Users", userNumberString, "PublicHiddenPrivateWritable", "0", "Inbox", "0"));
        const userHiddenData = userHiddenSnapshot.data();

        this.setState({
            user: newUser,                          // Firebase Authentication user object
            readonlyUserdata: userReadonlyData,     // /Users/<userNumber>/Readonly/0
            writableUserdata: userWritableData,     // /Users/<userNumber>/PublicReadonlyPrivateWritable/0
            hiddenWritableUserdata: userHiddenData, // /Users/<userNumber>/PublicHiddenPrivateWritable/0
        }, () => {
            resolve(true)
        })
    })
}

// Listen to changes in the Firestore collection where messages are
// Snapshots Inbox, then filters out messages that the current user sent to not be displayed,
// ... then we save filteredOutMessages to be pushed back in markMessageAsRead so the array is updated properly in Firestore,
// ... then we sort by newest message on top, check for amount of unread messages, and finally set states to track these variables.
// To be used like, "setUpMessageListener = setUpMessageListener;" as a member function of a class.
// ToDo (post-launch): Update listener to actually handle unreadCount for the "Just Friends" privacy setting
// ... ("receiveMessagesOnlyFromFriends: true" is the db field in Inbox subcollection). It's a pain to do, so currently we just have
// ... notifications off if "receiveMessage: false" i.e., they don't all All Messages.
export function setUpMessageListener() {
    const currentUser = this.state.readonlyUserdata.username;
    const InboxDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0");
    const unsubscribe = onSnapshot(InboxDocRef, (doc) => {
        if (doc.exists()) {
            const messages = doc.data().messages || [];
            // Applies the filter to messages where toName matches the current user's name
            const userMessages = messages.filter((message) => message.toName === currentUser);
            // Saves filtered out messages in state to be updated in markMessageAsRead
            const filteredOutMessages = messages.filter((message) => message.toName !== currentUser);
            this.setState({ filteredOutMessages });
            userMessages.sort((a, b) => b.timeSent - a.timeSent);
            // Counts the number of unread messages.
            const unreadCount = userMessages.filter((message) => !message.read).length;
            // Checks privacy settings & removes unreadCount if the user has any
            if (doc.data().receiveMessages === false) {
                this.setState({unreadCount: null, messages: userMessages, hideMessages: true })
            }
            // If no privacy settings - sets default unreadCount
            else {
                this.setState({ unreadCount, messages: userMessages  })
            }
            console.log("Sorted Message Data, and, Unread Count", userMessages, unreadCount);
        }
    });
    this.unsubscribeMessageListener = unsubscribe;
}

// Function to handle marking a message as read
// FIXME: I don't know what the following means
// onClick of an unread message - checks if read before, then changes unread message to read:true, then
// ... adds back filtered out messaages from setUpMessageListener so array is completed when updated and not overwritten
// ... with only filtered messages.
// FIXME: Doesn't have to be in utils.js, probably should be in something new like utils_messages.js then reexported in here
export async function markMessageAsRead(index) {
    const { messages, filteredOutMessages } = this.state;

    // Handles invalid index
    if (index < 0 || index >= messages.length) return;
    const message = messages[index];

    // Only update if the message is unread
    if (!message.read) {
        const messageDoc = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0");
        const messageDocRef = await getDoc(messageDoc)
        try {
            // Update the onClicked message as read by modifying only the "read" field
            const updatedMessages = [...messages];
            updatedMessages[index].read = true;
            console.log("Before update:", updatedMessages);

            // Add the filtered out messages to updatedMessages
            updatedMessages.push(...filteredOutMessages);
            console.log("Filtered Messages:", filteredOutMessages);
            await updateDoc(messageDoc, {
                messages: updatedMessages
            });
            console.log("After update:", updatedMessages);
        } catch (error) {
            console.error("Error marking message as read:", error);
        }
    }
}

// Function that checks dates, updates firebase accordingly if it is a new date for the user
// when they sign in, and passes a return back to to the component that called it for state management.
// TODO: This will need to be updated as months change and logic needed changes.
export async function updateSignInDate(firestore, userNumber) {
    console.log("Sign In Updates Initiated");
    const userRef = doc(firestore, "Users", `${userNumber}`, "PublicReadonlyPrivateWritable", "0");

    const transactionResult = await runTransaction(firestore, async (transaction) => {
        const userDoc = await transaction.get(userRef);
        if (!userDoc.exists()) {
            throw "Document does not exist!";
        }

        const userData = userDoc.data();
        const lastSignInDate = userData.lastSignInDate ? new Date(userData.lastSignInDate.seconds * 1000) : null;
        const now = new Date();
        const isNewDay = !lastSignInDate || lastSignInDate.getDate() !== now.getDate() ||
                         lastSignInDate.getMonth() !== now.getMonth() || lastSignInDate.getFullYear() !== now.getFullYear();
        const isNewMonth = !lastSignInDate || lastSignInDate.getMonth() !== now.getMonth();

        const updates = {};

        // Update lastSignInDate only if it's a new day or doesn't exist
        if (isNewDay) {
            updates.lastSignInDate = serverTimestamp();
            updates.activeDays = (userData.activeDays || 0) + 1;
            
            if (isNewMonth) {
                updates.activeMonths = (userData.activeMonths || 0) + 1;
            }
            
            // Handling specific month activity
            if (now.getFullYear() === 2024) {
                if (now.getMonth() === 5) { // June 2024
                    updates.activeJune24 = (userData.activeJune24 || 0) + 1;
                }
                if (now.getMonth() === 6) { // July 2024
                    updates.activeJuly24 = (userData.activeJuly24 || 0) + 1;
                }
            }

            // Track event in Mixpanel
            trackEvent("User Sign-In", {
                "User Number": userNumber,
                "Active Days": updates.activeDays,
                "Active Months": updates.activeMonths,
                "Active June 24": updates.activeJune24 || 0,  // Include these only if applicable
                // "Active July 24": updates.activeJuly24 || 0,
                "Is New Month": isNewMonth,
                "Date": now.toISOString()  // This logs the precise time of the event
            });
        }

        // Apply updates if there are any
        if (Object.keys(updates).length > 0) {
            transaction.update(userRef, updates);
        }

        console.log("Sign In Updates Completed");
    });

    return transactionResult;
}

// This is very similar to the above function, but is currently only called in AccountProfile when a user posts.
// We increment totalPosts to track how often a user posts, and we update lasPostDate and daysPosted if it's a
// a new day when the user posts.
export async function updatePostDateAndCount(firestore, userNumber) {
    console.log("Post Updates Initiated")
    const userRef = doc(firestore, "Users", `${userNumber}`, "PublicReadonlyPrivateWritable", "0");
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
        throw new Error("Document does not exist!");
    }

    const userData = userDoc.data();
    const lastPostDate = userData.lastPostDate ? new Date(userData.lastPostDate.seconds * 1000) : null;
    const now = new Date();

    // Check if it's a new day for posting
    const isNewDayForPost = !lastPostDate || lastPostDate.getDate() !== now.getDate() ||
        lastPostDate.getMonth() !== now.getMonth() || lastPostDate.getFullYear() !== now.getFullYear();

    const updates = {
        totalPosts: (userData.totalPosts || 0) + 1 // Always increment totalPosts
    };

    if (isNewDayForPost) {
        updates.lastPostDate = serverTimestamp(); // Update or initialize lastPostDate
        updates.daysPosted = (userData.daysPosted || 0) + 1; // Initialize if not exist, increment if new day
    }

    // Perform the update with the assembled updates object
    await updateDoc(userRef, updates);

    // Track post update event in Mixpanel
    trackEvent("Post Updated", {
        "User Number": userNumber,
        "Total Posts": updates.totalPosts,
        "Days Posted": updates.daysPosted || 0,
        "New Day": isNewDayForPost
    });
    console.log("Post Updates Completed")
}

// This is very similar to the above function, but is currently only called in SocialPage & children when a user sends a message.
export async function updateMessagesDateAndCount(fbsAuth, firestore, userNumber, recipientUser) {
    console.log("Message Updates Initiated")
    const userRef = doc(firestore, "Users", `${userNumber}`, "PublicReadonlyPrivateWritable", "0");
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
        throw new Error("Document does not exist!");
    }

    const userData = userDoc.data();
    const lastMessageDate = userData.lastMessageDate ? new Date(userData.lastMessageDate.seconds * 1000) : null;
    const now = new Date();

    // Check if it's a new day for messaging
    const isNewDayForMessage = !lastMessageDate || lastMessageDate.getDate() !== now.getDate() ||
        lastMessageDate.getMonth() !== now.getMonth() || lastMessageDate.getFullYear() !== now.getFullYear();

    const updates = {
        totalMessages: (userData.totalMessages || 0) + 1 // Always increment totalMessages
    };

    if (isNewDayForMessage) {
        updates.lastMessageDate = serverTimestamp(); // Update or initialize lastMessageDate
        updates.daysSentMessages = (userData.daysSentMessages || 0) + 1; // Initialize if not exist, increment if new day
    }

    // Perform the update with the assembled updates object
    await updateDoc(userRef, updates);

    // Track message update event in Mixpanel
    trackEvent("Message Updated", {
        "User Number": userNumber,
        "Total Messages": updates.totalMessages,
        "Days Sent Messages": updates.daysSentMessages || 0,
        "New Day": isNewDayForMessage
    });
    console.log("Message Updates Completed")

    // Finally call the cloud function to notify the other user that someone has sent them a message.
    // Calls sendNotificationEmail cloud function in index.js in the functions folder.
    // Logic and context is within that file - be sure to reference to know what to pass here.
    const idToken = await fbsAuth.currentUser.getIdToken(/* forceRefresh */ true);
    const response = await fetch("https://sendnotificationemail-cuw3q2hq5q-uc.a.run.app", {
        method: "post",
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            // Custom auth header required to authorize call.
            "Authorization": `ID ${idToken}`,
        },
        // Serialize request body as JSON, as specified by Content-Type header.
        body: JSON.stringify({
            userToNumber: undefined,
            userJoinedUsername: undefined,
            totalGroupMembers: undefined,
            groupJoined: false,
            messageSent: true,
            userSubject: "Someone Just Sent You a Message!",
            toUsername: recipientUser,
        })
    })
    if (response.ok) {
        console.log("User Notified you sent them a message.", response.status);
    } else {
        console.error(`Error ${response.status}: ${response.statusText}`);
    }
}

// This is very similar to the above function, but is currently only called in GamePage, VisitAccountPage (& children),
// and GamerMatchmakerPage (& children) when a user joins a group.
export async function updateJoinedGroupsDateAndCount(firestore, userNumber) {
    console.log("Joined Groups Updates Initiated")
    const userRef = doc(firestore, "Users", `${userNumber}`, "PublicReadonlyPrivateWritable", "0");
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
        throw new Error("Document does not exist!");
    }

    const userData = userDoc.data();
    const lastJoinedGroupDate = userData.lastJoinedGroupDate ? new Date(userData.lastJoinedGroupDate.seconds * 1000) : null;
    const now = new Date();

    // Check if it's a new day for joining groups
    const isNewDayForJoinGroup = !lastJoinedGroupDate || lastJoinedGroupDate.getDate() !== now.getDate() ||
        lastJoinedGroupDate.getMonth() !== now.getMonth() || lastJoinedGroupDate.getFullYear() !== now.getFullYear();

    const updates = {
        totalJoinedGroups: (userData.totalJoinedGroups || 0) + 1 // Always increment totalJoinedGroups
    };

    if (isNewDayForJoinGroup) {
        updates.lastJoinedGroupDate = serverTimestamp(); // Update or initialize lastJoinedGroupDate
        updates.daysJoinedGroups = (userData.daysJoinedGroups || 0) + 1; // Initialize if not exist, increment if new day
    }

    // Perform the update with the assembled updates object
    await updateDoc(userRef, updates);

    // Track joined group event in Mixpanel
    trackEvent("Group Joined", {
        "User Number": userNumber,
        "Total Joined Groups": updates.totalJoinedGroups,
        "Days Joined Groups": updates.daysJoinedGroups || 0,
        "New Day": isNewDayForJoinGroup
    });
    console.log("Joined Groups Updates Completed")
}

// TODO: There has to be a better way to do this lol, this is a lot of grunt
// ... work and risky.
// Call this to add new Games to the Games collection and AskAGamer collection.
// Make sure to pass in this.props.fbsFirestore and gamedata which is an array
// containing objects with the new gameName and gameImagePath for each game.
// *** Also, be sure to update the gameNumber to be the next doc in the sequence
// e.g., if the last game in the Games collection is a doc with the name 102, then
// update the gameNumber to be 102 + i (not 103!).
// *** Also, be sure to add the png images to public first and push that live to
// the database!
// Currently called in the function addGamesToDatabase() inside NewHomePage in onAuthStateChanged
// on local host to be done offline and never pushed to the Live site.
// *** BE SURE to update the gameNumber value, and comment out the function NewHomePage
// and not push it live or poof gone game database ...
// ***** gameNumber = 133 is correct for the next update of games, no need to change it next time.
export async function addGames(fbsFirestore, gamesData) {
    const gamesCollectionRef = "Games";
    const askAGamerDocRef = doc(fbsFirestore, "AskAGamer", "Games");

    try {
        await runTransaction(fbsFirestore, async (transaction) => {
            const gameDataUpdates = [];

            for (let i = 0; i < gamesData.length; i++) {
                const gameNumber = 133 + i;
                const gameDocRef = doc(fbsFirestore, gamesCollectionRef, `${gameNumber}`);
                const gameData = {
                    gameImagePath: gamesData[i].gameImagePath,
                    gameName: gamesData[i].gameName,
                    gameNumber: gameNumber,
                    people: []
                };

                transaction.set(gameDocRef, gameData, { merge: true });
                gameDataUpdates.push({
                    gameName: gamesData[i].gameName,
                    gameImagePath: gamesData[i].gameImagePath,
                    gameNumber: gameNumber
                });
            }

            transaction.update(askAGamerDocRef, {
                gameData: arrayUnion(...gameDataUpdates)
            });
        });

        console.log("Games added successfully and AskAGamer collection updated.");
    } catch (error) {
        console.error("Error adding games and updating AskAGamer collection:", error);
    }
}

// @Rylan - couldn't get ahold of you and needed alot of changes to this function, so i created a new version below
// that works well. Probably could have made it cleaner, but I wasn't 100% certain on the functionality this was trying
// to acheive given all the data we needed to pass in and change. Happy to revert back to a version similar to this if
// I just didn't understand the right way to pass the data.
// export async function alterPostData(fbsFirestore, userNumber, gameNumber, fieldName, newValue) {
//     console.log("Function Started")

//     // Update Users/<USER_ID>/PublicReadonlyPrivateWritable/0.<fieldName>
//     const userDocRef = doc(fbsFirestore, "Users", `${userNumber}`, "PublicReadonlyPrivateWritable", "0");
//     await updateDoc(userDocRef, {
//         [fieldName]: newValue
//     })

//     // Get <GAME_ID> from user's looking game id or something like that

//     // Update Games/<GAME_ID>/people.<USER_ID>.<fieldName>
//     const gameDocRef = doc(fbsFirestore, "Games", `${gameNumber}`);
//     await runTransaction(fbsFirestore, async (transaction) => {
//         const gameDoc = await transaction.get(gameDocRef);
//         // TODO: handle !gameDoc.exists()
//         console.log("game doc", gameDoc.data())
//         let newPeople = gameDoc.data().people;
//         newPeople[userNumber][fieldName] = newValue;
//         transaction.update(gameDocRef, { people: newPeople })
//     })
// }

// Currently only imported and called in AccountPage.
// Updates Firestore Users & Games collections when a user
// edits their Profile and/or LFG Post.
// Params are passed from various functions inside the AccountPage.
// * updatePeopleArray is a boolean - if false, the Games collection
// is not updated, since those fields are not currently needed in the people array.
// See the Account Page for more context.
// export async function alterPostData(fbsFirestore, userNumber, username, gameNumber, updatedFields, previousFields, previousGameNumber, updatePeopleArray) {
//     console.log("Profile & Post Function Started");

//     // Constructs the Firestore update object dynamically based on object fields passed.
//     const firestoreUpdateObject = {};
//     Object.keys(updatedFields).forEach(key => {
//         // A second check if the new value is different from the previous value to prevent unnecessary writes.
//         // if (updatedFields[key] !== previousFields[key]) {
//             firestoreUpdateObject[key] = updatedFields[key];
//         // }
//     });

//     // Updates Users/<USER_ID>/PublicReadonlyPrivateWritable/0.<fieldName>
//     const userDocRef = doc(fbsFirestore, "Users", `${userNumber}`, "PublicReadonlyPrivateWritable", "0");
//     await updateDoc(userDocRef, firestoreUpdateObject);
//     console.log("Profile Updated");

//     const newGroupMember = {
//         joinedAt: new Date(),
//         userNumber,
//         username
//     };

//     // Checks if previousGameNumber is different from gameNumber.
//     // Removes LFG Post from the other game doc if gameNumber has changed (e.g., the user changed which game they are LFG)
//     // and if updatePeople array is true.
//     if (previousGameNumber !== gameNumber && updatePeopleArray) {
//         // Remove the user object from the previous game's array
//         const prevGameDocRef = doc(fbsFirestore, "Games", `${previousGameNumber}`);
//         await runTransaction(fbsFirestore, async (transaction) => {
//             const prevGameDoc = await transaction.get(prevGameDocRef);
//             let prevGamePeople = prevGameDoc.data().people;
//             const userIndex = prevGamePeople.findIndex(person => person.userNumber === userNumber);
//             if (userIndex !== -1) {
//                 prevGamePeople.splice(userIndex, 1); // Remove the user object
//                 transaction.update(prevGameDocRef, { people: prevGamePeople });
//                 console.log("Previous LFG Post Removed From", previousGameNumber);
//             } else {
//                 console.log("Could not find or update previous LFG Post");
//             }
//         });
//     }

//     // Updates Games/<GAME_ID>/people.<USER_ID>.<fieldName> with new LFG Post.
//     if (updatePeopleArray) {
//         const gameDocRef = doc(fbsFirestore, "Games", `${gameNumber}`);
//         await runTransaction(fbsFirestore, async (transaction) => {
//             console.log("LFG Post Update Started");
//             const gameDoc = await transaction.get(gameDocRef);
//             let newPeople = gameDoc.data().people;

//             // Finds the index of the object corresponding to the userNumber
//             const userIndex = newPeople.findIndex(person => person.userNumber === userNumber);
//             if (userIndex !== -1) {
//                 // Updates the fields within the found object
//                 console.log("Post Found ... Updating Post.");
//                 Object.keys(firestoreUpdateObject).forEach(key => {
//                     newPeople[userIndex][key] = firestoreUpdateObject[key];
//                 });
//             } else {
//                 // If the user hasn't already posted in this game, push
//                 // the needed user data object, but replace the fields
//                 // that match the newly edited fields passed in updatedFields.
//                 console.log("Could Not Locate Post ... Adding New Post.")
//                 const userDocSnapshot = await getDoc(userDocRef);
//                 if (userDocSnapshot.exists()) {
//                     const userData = userDocSnapshot.data();
//                     // Create a new object with fields from the user doc
//                     const newUserObject = {
//                         groupMembers: [newGroupMember], // Ensure groupMembers is an array with the new member
//                         username,
//                         username,
//                         userNumber,
//                         postTime: userData.postTime,
//                         weeklyPlaytime: userData.weeklyPlaytime,
//                         occupation: userData.occupation,
//                         looking_time: userData.looking_time,
//                         language: userData.language,
//                         location: userData.location,
//                         looking_age: userData.looking_age,
//                         looking_comms: userData.looking_comms,
//                         looking_education: userData.looking_education,
//                         looking_gameImagePath: userData.looking_gameImagePath,
//                         looking_gameName: userData.looking_gameName,
//                         looking_game_id: userData.looking_game_id,
//                         looking_gender: userData.looking_gender,
//                         looking_language: userData.looking_language,
//                         looking_message: userData.looking_message,
//                         looking_needed: userData.looking_needed,
//                         looking_objective: userData.looking_objective,
//                         looking_objective_flexRequire: userData.looking_objective_flexRequire,
//                         looking_platform: userData.looking_platform,
//                         looking_session: userData.looking_session,
//                         looking_session_flexRequire: userData.looking_session_flexRequire,
//                         looking_skill: userData.looking_skill,
//                         looking_start: userData.looking_start,
//                         noobTotal: userData.noobTotal,
//                         ggTotal: userData.ggTotal,
//                         ggNoobTotal: userData.ggNoobTotal,
//                         gender: userData.gender,
//                         education: userData.education,
//                         age: userData.age,
//                         ...firestoreUpdateObject // Replaces fields with new values passed in updatedFields.
//                     };
//                     newPeople.push(newUserObject);
//                 }
//             }

//             // Update the people array in Firestore
//             transaction.update(gameDocRef, { people: newPeople });
//             console.log("LFG Post Updated!");
//         });
//     }

//     console.log("Profile & Post Function Finished!");
// }

// Currently only imported and called in AccountPage.
// Updates Firestore Users & Games collections when a user
// edits their Profile and/or LFG Post.
// Params are passed from various functions inside the AccountPage.
// * updatePeopleArray is a boolean - if false, the Games collection
// is not updated, since those fields are not currently needed in the people array.
// See the Account Page for more context.
// *** Updated Version, keeping the old on for context for now.
// export async function alterPostData(inTheUniveristyProgram, fbsFirestore, userNumber, username, gameNumber, updatedFields, previousFields, previousGameNumber, updatePeopleArray, newPost, joinGroupDiscord, joinGroupPlatform, joinGroupPlatformUsername, updateInterestsAndBackground) {
//     console.log("Profile & Post Function Started");

//     // Constructs the Firestore update object dynamically based on object fields passed.
//     const firestoreUpdateObject = {};
//     Object.keys(updatedFields).forEach(key => {
//         firestoreUpdateObject[key] = updatedFields[key];
//     });

//     // If updateInterestsAndBackground is true, update only the user document and return
//     if (updateInterestsAndBackground) {
//         const userDocRef = doc(fbsFirestore, "Users", `${userNumber}`, "PublicReadonlyPrivateWritable", "0");
//         await updateDoc(userDocRef, firestoreUpdateObject);
//         console.log("Interests and Background Updated");
//         return;
//     }

//     // Updates Users/<USER_ID>/PublicReadonlyPrivateWritable/0.<fieldName>
//     const userDocRef = doc(fbsFirestore, "Users", `${userNumber}`, "PublicReadonlyPrivateWritable", "0");
//     await updateDoc(userDocRef, firestoreUpdateObject);
//     console.log("Profile Updated");

//     const newGroupMember = {
//         joinedAt: new Date(),
//         userNumber,
//         username,
//         joinGroupDiscord,
//         joinGroupPlatform,
//         joinGroupPlatformUsername,
//     };

//     // Checks if previousGameNumber is different from gameNumber.
//     // Removes LFG Post from the other game doc if gameNumber has changed (e.g., the user changed which game they are LFG)
//     // and if updatePeople array is true.
//     if (previousGameNumber !== gameNumber && updatePeopleArray) {
//         // Remove the user object from the previous game's array
//         const prevGameDocRef = doc(fbsFirestore, "Games", `${previousGameNumber}`);
//         await runTransaction(fbsFirestore, async (transaction) => {
//             const prevGameDoc = await transaction.get(prevGameDocRef);
//             let prevGamePeople = prevGameDoc.data().people;
//             const userIndex = prevGamePeople.findIndex(person => person.userNumber === userNumber);
//             if (userIndex !== -1) {
//                 prevGamePeople.splice(userIndex, 1); // Remove the user object
//                 transaction.update(prevGameDocRef, { people: prevGamePeople });
//                 console.log("Previous LFG Post Removed From", previousGameNumber);
//             } else {
//                 console.log("Could not find or update previous LFG Post");
//             }
//         });
//     }

//     // Updates Games/<GAME_ID>/people.<USER_ID>.<fieldName> with new LFG Post.
//     if (updatePeopleArray || newPost) {
//         const gameDocRef = doc(fbsFirestore, "Games", `${gameNumber}`);
//         await runTransaction(fbsFirestore, async (transaction) => {
//             console.log("LFG Post Update Started");
//             const gameDoc = await transaction.get(gameDocRef);
//             let newPeople = gameDoc.data().people;

//             // Finds the index of the object corresponding to the userNumber
//             const userIndex = newPeople.findIndex(person => person.userNumber === userNumber);
//             if (userIndex !== -1) {
//                 // Updates the fields within the found object
//                 console.log("Post Found ... Updating Post.");
//                 Object.keys(firestoreUpdateObject).forEach(key => {
//                     newPeople[userIndex][key] = firestoreUpdateObject[key];
//                 });
//             } else {
//                 // If the user hasn't already posted in this game, push
//                 // the needed user data object, but replace the fields
//                 // that match the newly edited fields passed in updatedFields.
//                 console.log("Could Not Locate Post ... Adding New Post.");
//                 const userDocSnapshot = await getDoc(userDocRef);
//                 if (userDocSnapshot.exists()) {
//                     const userData = userDocSnapshot.data();
//                     // Create a new object with fields from the user doc
//                     const newUserObject = {
//                         groupMembers: [newGroupMember], // Ensure groupMembers is an array with the new member
//                         username,
//                         userNumber,
//                         isLFG: true,
//                         postTime: userData.postTime,
//                         weeklyPlaytime: userData.weeklyPlaytime,
//                         occupation: userData.occupation,
//                         looking_time: userData.looking_time,
//                         language: userData.language,
//                         location: userData.location,
//                         looking_age: userData.looking_age,
//                         looking_comms: userData.looking_comms,
//                         looking_education: userData.looking_education,
//                         looking_gameImagePath: userData.looking_gameImagePath,
//                         looking_gameName: userData.looking_gameName,
//                         looking_game_id: userData.looking_game_id,
//                         looking_gender: userData.looking_gender,
//                         looking_language: userData.looking_language,
//                         looking_message: userData.looking_message,
//                         looking_needed: userData.looking_needed,
//                         looking_needed_number: userData.looking_needed_number,
//                         looking_objective: userData.looking_objective,
//                         looking_objective_flexRequire: userData.looking_objective_flexRequire,
//                         looking_platform: userData.looking_platform,
//                         looking_session: userData.looking_session,
//                         looking_session_flexRequire: userData.looking_session_flexRequire,
//                         looking_skill: userData.looking_skill,
//                         looking_start: userData.looking_start,
//                         noobTotal: userData.noobTotal,
//                         ggTotal: userData.ggTotal,
//                         ggNoobTotal: userData.ggNoobTotal,
//                         gender: userData.gender,
//                         education: userData.education,
//                         age: userData.age,
//                         ...firestoreUpdateObject // Replaces fields with new values passed in updatedFields.
//                     };
//                     newPeople.push(newUserObject);

//                     // Update GroupChats collection
//                     const groupChatRef = doc(fbsFirestore, "GroupChats", `${userNumber}`);
//                     await updateDoc(groupChatRef, {
//                         gameName: userData.looking_gameName,
//                         gameNumber: userData.looking_game_id,
//                         createdAt: userData.postTime,
//                         isLFG: true,
//                         messages: [],
//                         groupMembers: [newGroupMember]
//                     });
//                     console.log("Group Chat Updated!");
//                 }
//             }

//             // Update the people array in Firestore
//             transaction.update(gameDocRef, { people: newPeople });
//             console.log("LFG Post Updated!");
//         });
//     }

//     console.log("Profile & Post Function Finished!");
// }

export async function alterPostData(
    inTheUniversityProgram,
    fbsFirestore,
    userNumber,
    username,
    gameNumber,
    updatedFields,
    previousFields,
    previousGameNumber,
    updatePeopleArray,
    newPost,
    joinGroupDiscord,
    joinGroupPlatform,
    joinGroupPlatformUsername,
    updateInterestsAndBackground
) {
    console.log("Profile & Post Function Started");

    // Constructs the Firestore update object dynamically based on object fields passed.
    const firestoreUpdateObject = {};
    Object.keys(updatedFields).forEach(key => {
        firestoreUpdateObject[key] = updatedFields[key];
    });

    // If updateInterestsAndBackground is true, update only the user document and return
    if (updateInterestsAndBackground) {
        const userDocRef = doc(fbsFirestore, "Users", `${userNumber}`, "PublicReadonlyPrivateWritable", "0");
        await updateDoc(userDocRef, firestoreUpdateObject);
        console.log("Interests and Background Updated");
        return;
    }

    // Updates Users/<USER_ID>/PublicReadonlyPrivateWritable/0.<fieldName>
    const userDocRef = doc(fbsFirestore, "Users", `${userNumber}`, "PublicReadonlyPrivateWritable", "0");
    await updateDoc(userDocRef, firestoreUpdateObject);
    console.log("Profile Updated");

    const newGroupMember = {
        joinedAt: new Date(),
        userNumber,
        username,
        joinGroupDiscord,
        joinGroupPlatform,
        joinGroupPlatformUsername,
    };

    // Function to remove LFG Post from the previous game's people array
    const removeLFGPostFromPreviousGame = async () => {
        const prevGameDocRef = doc(fbsFirestore, "Games", `${previousGameNumber}`);
        await runTransaction(fbsFirestore, async (transaction) => {
            const prevGameDoc = await transaction.get(prevGameDocRef);
            let prevGamePeople = prevGameDoc.data().people;
            const userIndex = prevGamePeople.findIndex(person => person.userNumber === userNumber);
            if (userIndex !== -1) {
                prevGamePeople.splice(userIndex, 1); // Remove the user object
                transaction.update(prevGameDocRef, { people: prevGamePeople });
                console.log("Previous LFG Post Removed From", previousGameNumber);
            } else {
                console.log("Could not find or update previous LFG Post");
            }
        });
    };

    // Function to update LFG Post in the current game's people array
    const updateLFGPostInCurrentGame = async () => {
        const gameDocRef = doc(fbsFirestore, "Games", `${gameNumber}`);
        await runTransaction(fbsFirestore, async (transaction) => {
            console.log("LFG Post Update Started");
            const gameDoc = await transaction.get(gameDocRef);
            let newPeople = gameDoc.data().people;

            // Finds the index of the object corresponding to the userNumber
            const userIndex = newPeople.findIndex(person => person.userNumber === userNumber);
            if (userIndex !== -1) {
                // Updates the fields within the found object
                console.log("Post Found ... Updating Post.");
                Object.keys(firestoreUpdateObject).forEach(key => {
                    newPeople[userIndex][key] = firestoreUpdateObject[key];
                });
            } else {
                // If the user hasn't already posted in this game, push
                // the needed user data object, but replace the fields
                // that match the newly edited fields passed in updatedFields.
                console.log("Could Not Locate Post ... Adding New Post.");
                const userDocSnapshot = await getDoc(userDocRef);
                if (userDocSnapshot.exists()) {
                    const userData = userDocSnapshot.data();
                    // Create a new object with fields from the user doc
                    const newUserObject = {
                        groupMembers: [newGroupMember], // Ensure groupMembers is an array with the new member
                        username,
                        userNumber,
                        isLFG: true,
                        postTime: userData.postTime,
                        weeklyPlaytime: userData.weeklyPlaytime,
                        occupation: userData.occupation,
                        looking_time: userData.looking_time,
                        language: userData.language,
                        location: userData.location,
                        looking_age: userData.looking_age,
                        looking_comms: userData.looking_comms,
                        looking_education: userData.looking_education,
                        looking_gameImagePath: userData.looking_gameImagePath,
                        looking_gameName: userData.looking_gameName,
                        looking_game_id: userData.looking_game_id,
                        looking_gender: userData.looking_gender,
                        looking_language: userData.looking_language,
                        looking_message: userData.looking_message,
                        looking_needed: userData.looking_needed,
                        looking_needed_number: userData.looking_needed_number,
                        looking_objective: userData.looking_objective,
                        looking_objective_flexRequire: userData.looking_objective_flexRequire,
                        looking_platform: userData.looking_platform,
                        looking_session: userData.looking_session,
                        looking_session_flexRequire: userData.looking_session_flexRequire,
                        looking_skill: userData.looking_skill,
                        looking_start: userData.looking_start,
                        noobTotal: userData.noobTotal,
                        ggTotal: userData.ggTotal,
                        ggNoobTotal: userData.ggNoobTotal,
                        gender: userData.gender,
                        education: userData.education,
                        age: userData.age,
                        ...firestoreUpdateObject // Replaces fields with new values passed in updatedFields.
                    };
                    newPeople.push(newUserObject);

                    // Update GroupChats collection
                    const groupChatRef = doc(fbsFirestore, "GroupChats", `${userNumber}`);
                    await updateDoc(groupChatRef, {
                        gameName: userData.looking_gameName,
                        gameNumber: userData.looking_game_id,
                        createdAt: userData.postTime,
                        isLFG: true,
                        messages: [],
                        groupMembers: [newGroupMember]
                    });
                    console.log("Group Chat Updated!");
                }
            }

            // Update the people array in Firestore
            transaction.update(gameDocRef, { people: newPeople });
            console.log("LFG Post Updated!");
        });
    };

    // If inTheUniversityProgram is true, also update the UniversityProgram collection
    const updateUniversityProgram = async () => {
        const universityDocRef = doc(fbsFirestore, "UniversityProgram", "Notre Dame Video Game Club", "UniversityLFG", "0");

        await runTransaction(fbsFirestore, async (transaction) => {
            const universityDoc = await transaction.get(universityDocRef);
            let newUniversityPeople = universityDoc.data().people || [];

            // Remove LFG Post from the previous game's people array in UniversityProgram
            if (previousGameNumber !== gameNumber && updatePeopleArray) {
                const prevUniversityPeople = universityDoc.data().people || [];
                const userIndex = prevUniversityPeople.findIndex(person => person.userNumber === userNumber);
                if (userIndex !== -1) {
                    prevUniversityPeople.splice(userIndex, 1); // Remove the user object
                    transaction.update(universityDocRef, { people: prevUniversityPeople });
                    console.log("Previous University LFG Post Removed");
                } else {
                    console.log("Could not find or update previous University LFG Post");
                }
            }

            // Finds the index of the object corresponding to the userNumber
            const userIndex = newUniversityPeople.findIndex(person => person.userNumber === userNumber);
            if (userIndex !== -1) {
                // Updates the fields within the found object
                console.log("University Post Found ... Updating Post.");
                Object.keys(firestoreUpdateObject).forEach(key => {
                    newUniversityPeople[userIndex][key] = firestoreUpdateObject[key];
                });
            } else {
                // If the user hasn't already posted in this game, push
                // the needed user data object, but replace the fields
                // that match the newly edited fields passed in updatedFields.
                console.log("Could Not Locate University Post ... Adding New Post.");
                const userDocSnapshot = await getDoc(userDocRef);
                if (userDocSnapshot.exists()) {
                    const userData = userDocSnapshot.data();
                    // Create a new object with fields from the user doc
                    const newUserObject = {
                        groupMembers: [newGroupMember], // Ensure groupMembers is an array with the new member
                        username,
                        userNumber,
                        isLFG: true,
                        realName: userData.realName ? userData.realName : "",
                        postTime: userData.postTime,
                        weeklyPlaytime: userData.weeklyPlaytime,
                        occupation: userData.occupation,
                        looking_time: userData.looking_time,
                        language: userData.language,
                        location: userData.location,
                        looking_age: userData.looking_age,
                        looking_comms: userData.looking_comms,
                        looking_education: userData.looking_education,
                        looking_gameImagePath: userData.looking_gameImagePath,
                        looking_gameName: userData.looking_gameName,
                        looking_game_id: userData.looking_game_id,
                        looking_gender: userData.looking_gender,
                        looking_language: userData.looking_language,
                        looking_message: userData.looking_message,
                        looking_needed: userData.looking_needed,
                        looking_needed_number: userData.looking_needed_number,
                        looking_objective: userData.looking_objective,
                        looking_objective_flexRequire: userData.looking_objective_flexRequire,
                        looking_platform: userData.looking_platform,
                        looking_session: userData.looking_session,
                        looking_session_flexRequire: userData.looking_session_flexRequire,
                        looking_skill: userData.looking_skill,
                        looking_start: userData.looking_start,
                        noobTotal: userData.noobTotal,
                        ggTotal: userData.ggTotal,
                        ggNoobTotal: userData.ggNoobTotal,
                        gender: userData.gender,
                        education: userData.education,
                        age: userData.age,
                        ...firestoreUpdateObject // Replaces fields with new values passed in updatedFields.
                    };
                    newUniversityPeople.push(newUserObject);

                    // Update GroupChats collection
                    const groupChatRef = doc(fbsFirestore, "GroupChats", `${userNumber}`);
                    await updateDoc(groupChatRef, {
                        gameName: userData.looking_gameName,
                        gameNumber: userData.looking_game_id,
                        createdAt: userData.postTime,
                        isLFG: true,
                        messages: [],
                        groupMembers: [newGroupMember]
                    });
                    console.log("University Group Chat Updated!");
                }
            }

            // Update the people array in Firestore
            transaction.update(universityDocRef, { people: newUniversityPeople });
            console.log("University LFG Post Updated!");
        });
    };

    // Execute the removal from the previous game's people array
    if (previousGameNumber !== gameNumber && updatePeopleArray) {
        await removeLFGPostFromPreviousGame();
        if (inTheUniversityProgram === true) {
            await updateUniversityProgram();
        }
    }

    // Execute the update to the current game's people array
    if (updatePeopleArray || newPost) {
        await updateLFGPostInCurrentGame();
        if (inTheUniversityProgram === true) {
            await updateUniversityProgram();
        }
    }

    console.log("Profile & Post Function Finished!");
}

// Called in SocialLFGPost.js inside SocialPage.jsx, and in AccountProfile.js
// inside AcountPage.jsx.
// Removes the LFG Post of the user from the people array for the game they
// they are posted in, and updates their user doc, and removes LFG Post/Chat/Member
// data from the users GroupChats doc.
// export async function removePostData(inTheUniversityProgram, fbsFirestore, userNumber, gameNumber) {
//     console.log("Remove Post Function Started");

//     // Remove the user object from the game's people array
//     const gameDocRef = doc(fbsFirestore, "Games", `${gameNumber}`);
//     const userDocRef = doc(fbsFirestore, "Users", `${userNumber}`, "PublicReadonlyPrivateWritable", "0");
//     const groupChatRef = doc(fbsFirestore, "GroupChats", `${userNumber}`);

//     await runTransaction(fbsFirestore, async (transaction) => {
//         console.log("Transaction started to remove post from game");

//         const gameDoc = await transaction.get(gameDocRef);
//         let newPeople = gameDoc.data().people;
//         // Find the index of the object corresponding to the userNumber
//         const userIndex = newPeople.findIndex(person => person.userNumber === userNumber);
//         if (userIndex !== -1) {
//             // Remove the user object
//             newPeople.splice(userIndex, 1);
//             transaction.update(gameDocRef, { people: newPeople });
//             console.log("User's LFG Post Removed From Game:", gameNumber);
//         } else {
//             console.log("Could not find or remove user's LFG Post from game:", gameNumber);
//         }

//         // Update the user's doc field isLFG: false
//         transaction.update(userDocRef, { isLFG: false });

//         // Reset the group chat fields
//         transaction.update(groupChatRef, {
//             groupMembers: [],
//             messages: [],
//             isLFG: false
//         });
//     });

//     console.log("User's isLFG field and Group Chat updated");
//     console.log("Remove Post Function Finished!");
// }

export async function removePostData(inTheUniversityProgram, fbsFirestore, userNumber, gameNumber) {
    console.log("Remove Post Function Started");

    const gameDocRef = doc(fbsFirestore, "Games", `${gameNumber}`);
    const userDocRef = doc(fbsFirestore, "Users", `${userNumber}`, "PublicReadonlyPrivateWritable", "0");
    const groupChatRef = doc(fbsFirestore, "GroupChats", `${userNumber}`);
    const universityDocRef = doc(fbsFirestore, "UniversityProgram", "Notre Dame Video Game Club", "UniversityLFG", "0");

    await runTransaction(fbsFirestore, async (transaction) => {
        console.log("Transaction started to remove post from game");

        const gameDoc = await transaction.get(gameDocRef);
        let newPeople = gameDoc.data().people;
        const userIndex = newPeople.findIndex(person => person.userNumber === userNumber);

        let universityPeople = [];
        if (inTheUniversityProgram) {
            const universityDoc = await transaction.get(universityDocRef);
            if (universityDoc.exists()) {
                universityPeople = universityDoc.data().people || [];
            }
        }

        if (userIndex !== -1) {
            newPeople.splice(userIndex, 1);
            transaction.update(gameDocRef, { people: newPeople });
            console.log("User's LFG Post Removed From Game:", gameNumber);
        } else {
            console.log("Could not find or remove user's LFG Post from game:", gameNumber);
        }

        transaction.update(userDocRef, { isLFG: false });

        transaction.update(groupChatRef, {
            groupMembers: [],
            messages: [],
            isLFG: false
        });

        if (inTheUniversityProgram) {
            const universityUserIndex = universityPeople.findIndex(person => person.userNumber === userNumber);
            if (universityUserIndex !== -1) {
                universityPeople.splice(universityUserIndex, 1);
                transaction.update(universityDocRef, { people: universityPeople });
                console.log("User's LFG Post Removed From University Program:", gameNumber);
            } else {
                console.log("Could not find or remove user's LFG Post from University Program:", gameNumber);
            }
        }
    });

    console.log("User's isLFG field and Group Chat updated");
    console.log("Remove Post Function Finished!");
}

// Called in SocialGroups.js inside SocialPage.jsx.
// Removes the the user from the people array for the LFG Post Group they
// joined and updates their user doc, and removes their object from the
// groupMembers array from the joined group's GroupChats doc.
// export async function leaveGroupAlterData(inTheUniversityProgram, fbsFirestore, userNumber, gameNumber, groupNumber) {
//     console.log("Leave Group Function Started");

//     // References to necessary documents
//     const gameDocRef = doc(fbsFirestore, "Games", `${gameNumber}`);
//     const userDocRef = doc(fbsFirestore, "Users", `${userNumber}`, "PublicReadonlyPrivateWritable", "0");
//     const groupChatRef = doc(fbsFirestore, "GroupChats", `${groupNumber}`);

//     await runTransaction(fbsFirestore, async (transaction) => {
//         console.log("Transaction started for leaving group");

//         // Fetch game and group chat documents
//         const gameDoc = await transaction.get(gameDocRef);
//         const groupChatDoc = await transaction.get(groupChatRef);

//         let gamePeople = gameDoc.data().people;
//         let chatMembers = groupChatDoc.data().groupMembers;

//         // Remove user from group's groupMembers array inside the game's people array
//         gamePeople = gamePeople.map(person => {
//             if (person.groupMembers.some(member => member.userNumber === userNumber)) {
//                 // Remove the user from the groupMembers array within this person object
//                 const updatedGroupMembers = person.groupMembers.filter(member => member.userNumber !== userNumber);
//                 return { ...person, groupMembers: updatedGroupMembers };
//             }
//             return person;
//         });

//         // Update the people array in the game document
//         transaction.update(gameDocRef, { people: gamePeople });
//         console.log("User removed from game group members:", gameNumber);

//         // Remove user from group chat members array
//         const chatMemberIndex = chatMembers.findIndex(member => member.userNumber === userNumber);
//         if (chatMemberIndex !== -1) {
//             chatMembers.splice(chatMemberIndex, 1);
//             transaction.update(groupChatRef, { groupMembers: chatMembers });
//             console.log("User removed from group chat members:", groupNumber);
//         } else {
//             console.log("User not found in group chat members");
//         }

//         // Update user's document
//         transaction.update(userDocRef, {
//             isInOtherGroups: false,
//             joinedGroupOne: -1,
//             joinedGroupOneGameNumber: -1,
//         });
//     });

//     console.log("User's group status updated");
//     console.log("Leave Group Function Finished!");
// }

export async function leaveGroupAlterData(inTheUniversityProgram, fbsFirestore, userNumber, gameNumber, groupNumber) {
    console.log("Leave Group Function Started");

    const gameDocRef = doc(fbsFirestore, "Games", `${gameNumber}`);
    const userDocRef = doc(fbsFirestore, "Users", `${userNumber}`, "PublicReadonlyPrivateWritable", "0");
    const groupChatRef = doc(fbsFirestore, "GroupChats", `${groupNumber}`);
    const universityDocRef = doc(fbsFirestore, "UniversityProgram", "Notre Dame Video Game Club", "UniversityLFG", "0");

    await runTransaction(fbsFirestore, async (transaction) => {
        console.log("Transaction started for leaving group");

        const gameDoc = await transaction.get(gameDocRef);
        const groupChatDoc = await transaction.get(groupChatRef);

        let gamePeople = gameDoc.data().people;
        let chatMembers = groupChatDoc.data().groupMembers;

        let universityPeople = [];
        if (inTheUniversityProgram) {
            const universityDoc = await transaction.get(universityDocRef);
            if (universityDoc.exists()) {
                universityPeople = universityDoc.data().people || [];
            }
        }

        gamePeople = gamePeople.map(person => {
            if (person.groupMembers.some(member => member.userNumber === userNumber)) {
                const updatedGroupMembers = person.groupMembers.filter(member => member.userNumber !== userNumber);
                return { ...person, groupMembers: updatedGroupMembers };
            }
            return person;
        });

        transaction.update(gameDocRef, { people: gamePeople });
        console.log("User removed from game group members:", gameNumber);

        const chatMemberIndex = chatMembers.findIndex(member => member.userNumber === userNumber);
        if (chatMemberIndex !== -1) {
            chatMembers.splice(chatMemberIndex, 1);
            transaction.update(groupChatRef, { groupMembers: chatMembers });
            console.log("User removed from group chat members:", groupNumber);
        } else {
            console.log("User not found in group chat members");
        }

        transaction.update(userDocRef, {
            isInOtherGroups: false,
            joinedGroupOne: -1,
            joinedGroupOneGameNumber: -1,
        });

        if (inTheUniversityProgram) {
            universityPeople = universityPeople.map(person => {
                if (person.groupMembers.some(member => member.userNumber === userNumber)) {
                    const updatedGroupMembers = person.groupMembers.filter(member => member.userNumber !== userNumber);
                    return { ...person, groupMembers: updatedGroupMembers };
                }
                return person;
            });

            transaction.update(universityDocRef, { people: universityPeople });
            console.log("User removed from University Program group members:", gameNumber);
        }
    });

    console.log("User's group status updated");
    console.log("Leave Group Function Finished!");
}

// Currently only called inside SocialLFGPost.js (inside SocialPage.jsx) with the
// function removeUser(), which then calls this utils.js function and then
// ... calls this cloud function. See the original function call for more context.
// export async function removeUserFromGroup(inTheUniversityProgram, fbsFirestore, fbsAuth, currentUserNumber, groupMember, gameNumber) {
//     console.log("Remove User from Group Function Started");

//     const groupChatRef = doc(fbsFirestore, "GroupChats", `${currentUserNumber}`);
//     const gameDocRef = doc(fbsFirestore, "Games", `${gameNumber}`);

//     try {
//         // Remove user from the current user's group chat document
//         const groupChatDoc = await getDoc(groupChatRef);
//         if (groupChatDoc.exists()) {
//             const groupChatData = groupChatDoc.data();
//             const updatedGroupMembers = groupChatData.groupMembers.filter(member => member.userNumber !== groupMember.userNumber);
//             await updateDoc(groupChatRef, { groupMembers: updatedGroupMembers });
//             console.log("User removed from current user's group chat document.");
//         } else {
//             console.log("No such document in group chat!");
//         }

//         // Remove the user from the game's people array
//         await runTransaction(fbsFirestore, async (transaction) => {
//             const gameDoc = await transaction.get(gameDocRef);
//             if (!gameDoc.exists()) {
//                 throw new Error("Game document does not exist!");
//             }

//             let newPeople = gameDoc.data().people;

//             // Find and remove the user from the groupMembers array within the game's people array
//             newPeople = newPeople.map(person => {
//                 if (person.groupMembers.some(member => member.userNumber === groupMember.userNumber)) {
//                     const updatedGroupMembers = person.groupMembers.filter(member => member.userNumber !== groupMember.userNumber);
//                     return { ...person, groupMembers: updatedGroupMembers };
//                 }
//                 return person;
//             });

//             // Remove the person if their groupMembers array is now empty
//             newPeople = newPeople.filter(person => person.groupMembers.length > 0);

//             // Update the game document
//             transaction.update(gameDocRef, { people: newPeople });
//             console.log("User removed from game's groupMembers array.");
//         });

//         // Call the cloud function removeUserFromLFG to update the other user's document
//         const idToken = await fbsAuth.currentUser.getIdToken(/* forceRefresh */ true);
//         const response = await fetch("https://removeUserFromLFG-cuw3q2hq5q-uc.a.run.app", {
//             method: "post",
//             headers: {
//                 "Accept": "application/json",
//                 "Content-Type": "application/json",
//                 // Custom auth header required to authorize call.
//                 "Authorization": `ID ${idToken}`,
//             },
//             // Serialize request body as JSON, as specified by Content-Type header.
//             body: JSON.stringify({
//                 otherUserNumber: groupMember.userNumber,
//             })
//         });

//         if (response.ok) {
//             console.log("Other User's Doc Updated", response.status);
//         } else {
//             window.alert(`Error ${response.status}: ${response.statusText}`);
//         }

//     } catch (error) {
//         console.error("Error removing user: ", error);
//     }
// }

export async function removeUserFromGroup(inTheUniversityProgram, fbsFirestore, fbsAuth, currentUserNumber, groupMember, gameNumber) {
    console.log("Remove User from Group Function Started");

    const groupChatRef = doc(fbsFirestore, "GroupChats", `${currentUserNumber}`);
    const gameDocRef = doc(fbsFirestore, "Games", `${gameNumber}`);
    const universityDocRef = doc(fbsFirestore, "UniversityProgram", "Notre Dame Video Game Club", "UniversityLFG", "0");

    try {
        const groupChatDoc = await getDoc(groupChatRef);
        if (groupChatDoc.exists()) {
            const groupChatData = groupChatDoc.data();
            const updatedGroupMembers = groupChatData.groupMembers.filter(member => member.userNumber !== groupMember.userNumber);
            await updateDoc(groupChatRef, { groupMembers: updatedGroupMembers });
            console.log("User removed from current user's group chat document.");
        } else {
            console.log("No such document in group chat!");
        }

        await runTransaction(fbsFirestore, async (transaction) => {
            const gameDoc = await transaction.get(gameDocRef);
            if (!gameDoc.exists()) {
                throw new Error("Game document does not exist!");
            }

            let newPeople = gameDoc.data().people;
            newPeople = newPeople.map(person => {
                if (person.groupMembers.some(member => member.userNumber === groupMember.userNumber)) {
                    const updatedGroupMembers = person.groupMembers.filter(member => member.userNumber !== groupMember.userNumber);
                    return { ...person, groupMembers: updatedGroupMembers };
                }
                return person;
            });

            newPeople = newPeople.filter(person => person.groupMembers.length > 0);
            transaction.update(gameDocRef, { people: newPeople });
            console.log("User removed from game's groupMembers array.");

            if (inTheUniversityProgram) {
                const universityDoc = await transaction.get(universityDocRef);
                if (!universityDoc.exists()) {
                    throw new Error("University document does not exist!");
                }

                let universityPeople = universityDoc.data().people;
                universityPeople = universityPeople.map(person => {
                    if (person.groupMembers.some(member => member.userNumber === groupMember.userNumber)) {
                        const updatedGroupMembers = person.groupMembers.filter(member => member.userNumber !== groupMember.userNumber);
                        return { ...person, groupMembers: updatedGroupMembers };
                    }
                    return person;
                });

                universityPeople = universityPeople.filter(person => person.groupMembers.length > 0);
                transaction.update(universityDocRef, { people: universityPeople });
                console.log("User removed from university's groupMembers array.");
            }
        });

        const idToken = await fbsAuth.currentUser.getIdToken(true);
        const response = await fetch("https://removeUserFromLFG-cuw3q2hq5q-uc.a.run.app", {
            method: "post",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `ID ${idToken}`,
            },
            body: JSON.stringify({
                otherUserNumber: groupMember.userNumber,
            })
        });

        if (response.ok) {
            console.log("Other User's Doc Updated", response.status);
        } else {
            window.alert(`Error ${response.status}: ${response.statusText}`);
        }

    } catch (error) {
        console.error("Error removing user: ", error);
    }
}

// async removeUser() {

//     const idToken = await this.props.fbsAuth.currentUser.getIdToken(/* forceRefresh */ true)
//     // Send token to backend via HTTPS
//     const response = await fetch("https://removeUserFromLFG-cuw3q2hq5q-uc.a.run.app", {
//         method: "post",
//         headers: {
//             "Accept": "application/json",
//             "Content-Type": "application/json",
//              // Custom auth header required to authorize call.
//             "Authorization": `ID ${idToken}`,
//         },

//         // Serialize request body as JSON, as specified by Content-Type header.
//         body: JSON.stringify({
//             otherUserNumber: this.state.answererData.groupMember.userNumber,
//         })
//     })
//     if (response.ok) console.log("Other User's Doc Updated", response.status)
//     else window.alert(`Error ${response.status}: ${response.statusText}`)

// }

// Function called when a user joins another users LFG Post/Group.
// Updates the Games Collection, the GroupChats collection for the
// user who post the group, and updates the current User's User Collection.
// Currently called in GamePage.jsx and VisitingAccountProfile.js (inside VisitingAccountPage.jsx).
// TODO: Pass in looking_needed or something so we can notify the user that their group is full or not.
// export async function joinGroup(inTheUniversityProgram, fbsAuth, fbsFirestore, gameNumber, otherUserNumber, currentUserNumber, currentUsername, discordName, platformName, platformUsername, updateGroupOne) {
//     console.log("Join Group Function Started");

//     // Construct the new group member object
//     const newMember = {
//         username: currentUsername,
//         userNumber: currentUserNumber,
//         joinedAt: new Date(),
//         joinGroupDiscord: discordName,
//         joinGroupPlatform: platformName,
//         joinGroupPlatformUsername: platformUsername
//     };

//     // Reference to the Games document
//     const gameDocRef = doc(fbsFirestore, "Games", `${gameNumber}`);
//     const groupChatRef = doc(fbsFirestore, "GroupChats", `${otherUserNumber}`);
//     const userDocRef = doc(fbsFirestore, "Users", `${currentUserNumber}`, "PublicReadonlyPrivateWritable", "0");

//     try {
//         await runTransaction(fbsFirestore, async (transaction) => {
//             // Fetch game document
//             const gameDoc = await transaction.get(gameDocRef);
//             if (!gameDoc.exists()) {
//                 throw new Error("Game document does not exist!");
//             }

//             // Fetch group chat document
//             const groupChatDoc = await transaction.get(groupChatRef);
//             if (!groupChatDoc.exists()) {
//                 throw new Error("GroupChat document does not exist!");
//             }

//             const gameData = gameDoc.data();
//             const groupChatData = groupChatDoc.data();

//             // Update game document
//             const newPeople = gameData.people.map(person => {
//                 if (person.userNumber === otherUserNumber) {
//                     return {
//                         ...person,
//                         groupMembers: [...new Set([...person.groupMembers, newMember])]
//                     };
//                 }
//                 return person;
//             });

//             transaction.update(gameDocRef, { people: newPeople });
//             console.log("Updated groupMembers in Games collection");

//             // Update group chat document
//             const newGroupMembers = [...new Set([...groupChatData.groupMembers, newMember])];
//             transaction.update(groupChatRef, { groupMembers: newGroupMembers });
//             console.log("Updated groupMembers in GroupChats collection");

//             // Update user's document if updateGroupOne is true
//             // TODO: figure this part out lol - and check if its passed correctly everywhere or if it's needed at all.
//             if (updateGroupOne) {
//                 transaction.update(userDocRef, {
//                     joinedGroupOne: otherUserNumber,
//                     joinedGroupOneGameNumber: gameNumber,
//                     isInOtherGroups: true
//                 });
//                 console.log("Updated joinedGroupOne in Users collection");
//             }
//             console.log("Join Group Function Finished");

//             // Finally call the cloud function to notify the other user that someone has joined their group.
//             // Calls sendNotificationEmail cloud function in index.js in the functions folder.
//             // Logic and context is within that file - be sure to reference to know what to pass here.
//             const idToken = await fbsAuth.currentUser.getIdToken(/* forceRefresh */ true);
//             const response = await fetch("https://sendnotificationemail-cuw3q2hq5q-uc.a.run.app", {
//                 method: "post",
//                 headers: {
//                     "Accept": "application/json",
//                     "Content-Type": "application/json",
//                     // Custom auth header required to authorize call.
//                     "Authorization": `ID ${idToken}`,
//                 },
//                 // Serialize request body as JSON, as specified by Content-Type header.
//                 body: JSON.stringify({
//                     userToNumber: otherUserNumber,
//                     toUsername: currentUsername,
//                     totalGroupMembers: newPeople.length,
//                     groupJoined: true,
//                     messageSent: false,
//                     userSubject: "Someone Just Joined Your Posted Group!",
//                 })
//             });

//             if (response.ok) {
//                 console.log("Group Leader Notified you joined the group.", response.status);
//                 window.location.href = '/gamerlounge#joined-groups';
//             } else {
//                 console.error(`Error ${response.status}: ${response.statusText}`);
//             }
//         });

//         // console.log("Join Group Function Finished");
//         // window.location.href = '/gamerlounge#joined-groups';

//     } catch (error) {
//         console.error("Error joining group:", error);
//     }
// }

export async function joinGroup(inTheUniversityProgram, fbsAuth, fbsFirestore, gameNumber, otherUserNumber, otherUserUsername, currentUserNumber, currentUsername, discordName, platformName, platformUsername, updateGroupOne) {
    console.log("Join Group Function Started");


    const newMember = {
        username: currentUsername,
        userNumber: currentUserNumber,
        joinedAt: new Date(),
        joinGroupDiscord: discordName,
        joinGroupPlatform: platformName,
        joinGroupPlatformUsername: platformUsername,
    };

    const gameDocRef = doc(fbsFirestore, "Games", `${gameNumber}`);
    const groupChatRef = doc(fbsFirestore, "GroupChats", `${otherUserNumber}`);
    const userDocRef = doc(fbsFirestore, "Users", `${currentUserNumber}`, "PublicReadonlyPrivateWritable", "0");
    const universityDocRef = doc(fbsFirestore, "UniversityProgram", "Notre Dame Video Game Club", "UniversityLFG", "0");

    try {
        // Read the documents first
        const gameDoc = await getDoc(gameDocRef);
        if (!gameDoc.exists()) {
            throw new Error("Game document does not exist!");
        }

        const groupChatDoc = await getDoc(groupChatRef);
        if (!groupChatDoc.exists()) {
            throw new Error("GroupChat document does not exist!");
        }

        let universityDoc;
        let universityPeople = [];
        if (inTheUniversityProgram) {
            universityDoc = await getDoc(universityDocRef);
            if (!universityDoc.exists()) {
                throw new Error("University document does not exist!");
            }
            universityPeople = universityDoc.data().people || [];
        }

        const gameData = gameDoc.data();
        const groupChatData = groupChatDoc.data();

        let totalGroupMembers = 0;

        await runTransaction(fbsFirestore, async (transaction) => {
            // Update game document
            const newPeople = gameData.people.map((person) => {
                if (person.userNumber === otherUserNumber) {
                    const updatedGroupMembers = [...new Set([...person.groupMembers, newMember])];
                    totalGroupMembers = updatedGroupMembers.length;  // Count the total members
                    return {
                        ...person,
                        groupMembers: updatedGroupMembers,
                    };
                }
                return person;
            });

            transaction.update(gameDocRef, { people: newPeople });
            console.log("Updated groupMembers in Games collection");

            // Update group chat document
            const newGroupMembers = [...new Set([...groupChatData.groupMembers, newMember])];
            transaction.update(groupChatRef, { groupMembers: newGroupMembers });
            console.log("Updated groupMembers in GroupChats collection");

            // Update user document
            if (updateGroupOne) {
                transaction.update(userDocRef, {
                    joinedGroupOne: otherUserNumber,
                    joinedGroupOneGameNumber: gameNumber,
                    isInOtherGroups: true,
                });
                console.log("Updated joinedGroupOne in Users collection");
            }

            // Update university document if applicable
            if (inTheUniversityProgram) {
                const updatedUniversityPeople = universityPeople.map((person) => {
                    if (person.userNumber === otherUserNumber) {
                        const updatedGroupMembers = [...new Set([...person.groupMembers, newMember])];
                        return {
                            ...person,
                            groupMembers: updatedGroupMembers,
                        };
                    }
                    return person;
                });

                transaction.update(universityDocRef, { people: updatedUniversityPeople });
                console.log("Updated groupMembers in University Program collection");
            }
        });

        // Send notification email
        const idToken = await fbsAuth.currentUser.getIdToken(true);
        const response = await fetch("https://sendnotificationemail-cuw3q2hq5q-uc.a.run.app", {
            method: "post",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `ID ${idToken}`,
            },
            body: JSON.stringify({
                userToNumber: otherUserNumber,
                toUsername: otherUserUsername,
                totalGroupMembers: totalGroupMembers,
                groupJoined: true,
                messageSent: false,
                userSubject: "Someone Just Joined Your Posted Group!",
            }),
        });

        if (response.ok) {
            console.log("Group Leader Notified you joined the group.", response.status);
            window.location.href = '/gamerlounge#joined-groups';
        } else {
            console.error(`Error ${response.status}: ${response.statusText}`);
        }
    } catch (error) {
        console.error("Error joining group:", error);
    }
}

// Called in GamerMatchmakerPage.jsx for finding gamers and matching to search/filters Results.
export async function findGamers(fbsFirestore, state, currentUserNumber) {
    const q = query(
        collectionGroup(fbsFirestore, "PublicReadonlyPrivateWritable"),
        where("gamerCanBeFound", "==", true)
    );

    try {
        const querySnapshot = await getDocs(q);
        let gamers = [];
        querySnapshot.forEach((doc) => {
            const data = doc.data();
            if (data.userNumber !== currentUserNumber && (!state.mustMatchGame || data.looking_gameName === state.gameChoice)) {
                const matchCount = calculateMatchCount(data, state);
                const { matchDetails, nonMatchDetails } = calculateMatchDetails(data, state);
                gamers.push({ ...data, matchCount, matchDetails, nonMatchDetails });
            }
        });

        // Group by match count and then shuffle each group
        const groupedGamers = gamers.reduce((acc, gamer) => {
            if (!acc[gamer.matchCount]) {
                acc[gamer.matchCount] = [];
            }
            acc[gamer.matchCount].push(gamer);
            return acc;
        }, {});

        // Flatten the grouped gamers, shuffling each group
        let sortedAndShuffledGamers = [];
        Object.keys(groupedGamers)
            .sort((a, b) => b - a)
            .forEach(matchCount => {
                const shuffledGroup = groupedGamers[matchCount].sort(() => 0.5 - Math.random());
                sortedAndShuffledGamers = sortedAndShuffledGamers.concat(shuffledGroup);
            });

        return sortedAndShuffledGamers;
    } catch (error) {
        console.error("Error gamer docs:", error);
        return [];
    }
}

function calculateMatchCount(gamer, state) {
    let matchCount = 0;

    const fields = [
        "looking_gameName",
        "looking_needed",
        "looking_start",
        "looking_age",
        "looking_platform",
        "looking_objective",
        "looking_comms",
        "looking_session",
        "looking_skill",
        "age",
        "gender",
        "location",
        "language",
        "looking_teammate_personality",
        "occupation",
        "education",
        "looking_teammate_longterm"
    ];

    const stateFields = [
        "gameChoice",
        "neededChoice",
        "startChoice",
        "ageChoice",
        "platformChoice",
        "objectiveChoice",
        "commsChoice",
        "sessionChoice",
        "skillChoice",
        "ageChoice",
        "genderChoice",
        "locationChoice",
        "languageChoice",
        "personalityChoice",
        "occupationChoice",
        "educationChoice",
        "longtermChoice"
    ];

    fields.forEach((field, index) => {
        if (state[stateFields[index]] === "Any" || gamer[field] === state[stateFields[index]]) {
            matchCount++;
        }
    });

    if (state.interestChoice === "Any" || gamer.interestOne === state.interestChoice || gamer.interestTwo === state.interestChoice) {
        matchCount++;
    }

    return matchCount;
}

function calculateMatchDetails(gamer, state) {
    let matchDetails = {};

    const fields = [
        "looking_needed",
        "looking_start",
        "looking_age",
        "looking_platform",
        "looking_objective",
        "looking_comms",
        "looking_session",
        "looking_skill",
        "age",
        "gender",
        "location",
        "language",
        "looking_teammate_personality",
        "occupation",
        "education",
        "looking_teammate_longterm"
    ];

    const stateFields = [
        "neededChoice",
        "startChoice",
        "ageChoice",
        "platformChoice",
        "objectiveChoice",
        "commsChoice",
        "sessionChoice",
        "skillChoice",
        "ageChoice",
        "genderChoice",
        "locationChoice",
        "languageChoice",
        "personalityChoice",
        "occupationChoice",
        "educationChoice",
        "longtermChoice"
    ];

    fields.forEach((field, index) => {
        matchDetails[`match${stateFields[index]}`] = state[stateFields[index]] === "Any" || gamer[field] === state[stateFields[index]];
    });

    matchDetails.matchInterestChoice = state.interestChoice === "Any" || gamer.interestOne === state.interestChoice || gamer.interestTwo === state.interestChoice;

    return matchDetails;
}