import React, { Component } from "react"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AccountProfile from "./AccountProfile";

// The Account Tabs
class AccountTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: undefined,
            userdata: undefined,
        }

        // These are the eventKeys of the tabs for non-new users.
        const tabs = [
            "gamer-profile",
            "chat",
        ]
        this.state.activeKey = tabs[0]

        // If there is a hash in the URL specifying which tab, go to that tab.
        if (!window.location.hash.empty) {
            const removedHash = window.location.hash.substring(1)
            const index = tabs.findIndex(x => x === removedHash)
            if (index >= 0)
                this.state.activeKey = tabs[index]
        }

        window.addEventListener(
            "hashchange",
            () => {
                const removedHash = window.location.hash.substring(1)
                const index = tabs.findIndex(x => x === removedHash)
                if (index >= 0)
                    this.setState({ activeKey: tabs[index] })
            },
            false,
        );
    }

    render() {
        return (
            <>
                <>
                    <Tabs
                        activeKey={this.state.activeKey}
                        onSelect={(k) => window.location.hash = `#${k}`}
                        transition={false}
                        id="account-tabs"
                        className="mb-2 pb-2"
                        justify
                    >
                        <Tab eventKey="gamer-profile" title="Profile" tabClassName="tab-color">
                            <AccountProfile
                                fbsAuth={this.props.fbsAuth}
                                fbsFirestore={this.props.fbsFirestore}
                                user={this.props.user}
                                username={this.props.username}
                                userdata={this.props.userdata}
                                answererData={this.props.answererData}
                                profile={this.props.profile}
                                editingModeEnabled={this.props.editingModeEnabled}
                                turnOnEditingMode={this.props.turnOnEditingMode}
                                turnOffEditingMode={this.props.turnOffEditingMode}
                                completeAccountOnboarding={this.props.completeAccountOnboarding}
                                onboardingNotFinsihed={this.props.onboardingNotFinsihed}
                                updatePostCounts={this.props.updatePostCounts}
                                isNewDay={this.props.isNewDay}
                            />
                        </Tab>
                    </Tabs>
                </>
            </>
        );
    }
}

export default AccountTabs;
