// I started to make the functional version of Gamer Profile here, but soon realized
// it wasn't necessary for the demo, so this is old code, but useful for seeing how we 
// created old functions, accessed the DB, altered state, etc.
// I will be updating this page to be GamerProfilePage and import UI and logic from the
// the Demo in "../components/Demo" to start building everything once HomePage and
// GamePage are completed.

import React, { Component } from "react"
import { onAuthStateChanged } from "firebase/auth";
import {
    logout,
    setUpMessageListener,
    setUser,
    updateSignInDate
} from "../utils.js"
import {
    doc,
    getDoc,
    query,
    where,
    collectionGroup,
    getDocs,
    updateDoc,
    onSnapshot,
} from "firebase/firestore"
import { ShieldCheck } from "react-bootstrap-icons";
import Footerfixed from "../components/Footer/Footer.js"
import { Button, Container, Row, Col, Stack, Image} from "react-bootstrap"
import BasicNavbar from "../components/Navbar/Navbar.js"
import SocialLFGPostTabs from "../components/Social/SocialLFGPostTabs.js";
import SocialGroupsTabs from "../components/Social/SocialGroupsTabs.js";
import SocialPersonalTabs from "../components/Social/SocialPersonalTabs.js";
import SocialSearchNotificationsTabs from "../components/Social/SocialSearchNotificationsTabs.js";
import SocialNotPressedTabs from "../components/Social/SocialNotPressedTabs.js";
import { ref, getDownloadURL } from "firebase/storage"

class SocialPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: undefined,
            userdata: undefined,
            messages: [], // Existing messages
            unreadCount: 0, // Tracks the unread message count
            generatedPfp: "", // Checks if the user is new and hasn't generated their PFP yet - conditional for render
            fadeOutAnimation: false, // set in handleTimeDelayOfPfp during onboarding new users.
            gamerIsLive: false, // if true, the gamer is live and can answer questions.
            queue: [], // set in setUpQueueListener in onAuthStateChanged- is an array of currently queued askers.
            queueLength: undefined, // set in setUpQueueListener when "Go Live" button is clicked - is a number which is the current length of the queue array.
            chatStarted: false, // set in AskAGamerAnswerChat when clicking "Start Chat" or "End Chat"
            cooldown: false, // Conditional to set timeout of 1.5s to prevent spamming of messages and allow cloud function to finish.
            games: [], // the array of games set in componentDidMount.

            pfp: "",
            
            // TODO: these should be moved to VisitAccountPage logic and removed from here later.
            gameName: "", // gameName is fetched and set from the url in componentDidMount if a user clicked on an LFG Post, else its an empty string
            visitingFromLFG: true, // most users will visit profiles from LFG posts, so this is set to true by default
            vistiingFromSocialTab: false, // if a user is visiting a profile from their Social Tab, this is set to true for conditional render purposes.

            pressedButtonIndex: null, // Becomes a number once a tab is pressed or checkUrl === true. Determines which tabs are displayed.
            checkUrl: false, // Set to true in componentDidUpdate after profile state is set to check url to open tabs for user.

            allMessages: [], // all existing messages again - the duplicate eliminates state complexity to make it simple to remove unread messages
            myGroupMessages: [], // all existing messages again - the duplicate eliminates state complexity to make it simple to remove unread messages
            couldNotFindGroup: false, // a secondary boolean that gets called as props from disableGroupChat() inside SocialGroups.js when we can not find the LFG Post.

            notUser: false,

            onboardingNotFinsihed: undefined,
        }

        this.logout = this.logout.bind(this)
        this.setUser = this.setUser.bind(this)

        this.setUpMessageListener = this.setUpMessageListener.bind(this);
        this.markMessageAsRead = this.markMessageAsRead.bind(this);
        
        // this.answererToggleLive = this.answererToggleLive.bind(this);
        this.playAudio = this.playAudio.bind(this);

        this.handleTabDisplayed = this.handleTabDisplayed.bind(this)
        this.handleHashChange = this.handleHashChange.bind(this)

        this.setUpMyGroupChatListener = this.setUpMyGroupChatListener.bind(this);
        this.setUpJoinedGroupChatOneListener = this.setUpJoinedGroupChatOneListener.bind(this);
        this.setUpJoinedGroupChatTwoListener = this.setUpJoinedGroupChatTwoListener.bind(this);
        this.removeOldestMessages = this.removeOldestMessages.bind(this);
        
        this.disableGroupChat = this.disableGroupChat.bind(this);

        this.completeGamerLoungeOnboarding = this.completeGamerLoungeOnboarding.bind(this);

        this.checkNewDayAndSetDate = this.checkNewDayAndSetDate.bind(this);

        onAuthStateChanged(this.props.fbsAuth, async user => {
            if (user) {
                //console.log(`User ${user.uid} logging in (email: ${user.email})`)
                await this.setUser(user)
                    .then(async () => {
                        await this.setUpProfileListener();

                        // One-time call, or atleast until the user clicks "Got it - Thanks!"
                        // This will call completeGamerLoungeOnboarding(), which will add the field
                        // gamerLoungeOnboardingCompleted: true.
                        // gamerLoungeOnboardingComplete does not exist as a field by default, so it will
                        // return false until the user clicks that button to add the field.
                        if(!this.state.writableUserdata.gamerLoungeOnboardingCompleted) {
                            this.setState({ onboardingNotFinsihed: true })
                        }

                        // This is a function check used in multiple components to see if today (based on their user's timezone)
                        // is a new day (aka after Midnight), for when the user last logged in (or was on Indemni).
                        // We pass in user data and check the field timestamp lastSignInDate and perform state and doc updates accordingly.
                        // As of right now, this will be called on most pages for a better user experience. We are also using it to
                        // determine counts of activity (daily, monthly, and total) to be uploaded to firebase and tracked in Mixedpanel.
                        // Overtime this will be much much more robost and better...
                        // The function calls the utilis function updateSignInDate() to handle doc updates if its a new date.
                        // TODO: In the future, when we want to make sure this can never be manipulated, we should be checking and updating,
                        // the Readonly collection and using a cloud function for this.
                        await this.checkNewDayAndSetDate(this.state.writableUserdata);

                        // GroupChats "Collection" has a doc for each user with a docId of their userNumber.
                        // These docs store the group memebers and messages for the LFG Post they created.
                        // User's Group Chat Snapshot
                        // Called only if the user isLFG(boolean), else there will not be a chat available.
                        if (this.state.writableUserdata.isLFG) {
                            await this.setUpMyGroupChatListener();
                        }

                        // Joined Group Chats Snapshot
                        // Called only if the user has isInOtherGroups(boolean), else there will not be a chat(s) available.
                        if (this.state.writableUserdata.isInOtherGroups) {
                            await this.setUpJoinedGroupChatOneListener();
                            // joinedGroupOne and joinedGroupTwo are set to -1 if not in one or two groups, else is
                            // the groups number aka the user's who created the groups number
                            if (this.state.writableUserdata.joinedGroupTwo !== -1) {
                                await this.setUpJoinedGroupChatTwoListener();
                            }

                        }

                        // Gets User prp from firebase storage if it exists
                        if (this.state.writableUserdata.pfp !== "") {
                            // Create a reference from a Google Cloud Storage URI
                            const gsReference = ref(this.props.fbsStorage, `gs://justtesting-c24bc.appspot.com/pfpOne/${this.state.readonlyUserdata.userNumber}.png`);
                            // `url` is the download URL for 'images/stars.jpg'
                            const url = await getDownloadURL(gsReference)
                            if (url) {
                                this.setState({pfp: url})
                            }
                        }
                    })

                // Inbox Snapshot
                await this.setUpMessageListener();
            }
            else {
                this.logout();
                this.setState({notUser: true});
            }
        })
    }

    // // We first fetch the games data from the "AskAGamer" collection.
    // async componentDidMount() {
    //     // Use URLSearchParams to get the game "gameName" parameter from the URL.
    //     // The states set are for conditional renders based on if the visiting user
    //     // ... is coming from an LFG post or their Social Tab.
    //     const searchParameters = new URLSearchParams(window.location.search);
    //     const gameName = searchParameters.get("gameName");
    //     // Determines state basesd on if gameName is present which will only be the
    //     // the case if the user clicked on a profile in the LFG posts in GamePage.
    //     if (gameName) {
    //         this.setState({
    //             postGameName: gameName,
    //             visitingFromLFG: true,
    //             vistiingFromSocialTab: false,
    //         })
    //     }
    //     else this.setState({
    //         postGameName: "",
    //         visitingFromLFG: false,
    //         vistiingFromSocialTab: true,
    //     })
    // }

    // Used to find #'s in the url
    componentDidMount() {
        this.handleHashChange();
        window.addEventListener("hashchange", this.handleHashChange, false);
    }

    // Use URLSearchParams to get the "lounge" parameter from the URL
    // Once profile state is set.
    // TODO: Add lounge?0-3 number to end of urls where this is warranted.
    componentDidUpdate(prevProps, prevState) {
        // Check if the checkUrl state has been updated
        if (prevState.checkUrl !== this.state.checkUrl) {
            // Use URLSearchParams to get the "gamerlounge" parameter from the URL
            const queryParameters = new URLSearchParams(window.location.search);
            const gamerLounge = queryParameters.get("gamerlounge");
            if (gamerLounge) {
                this.setState({ pressedButtonIndex: gamerLounge });
            }
        }
    }

    componentWillUnmount() {
        // Window listener for if a hash is in the url
        window.removeEventListener("hashchange", this.handleHashChange, false);
        if (this.unsubscribeMessageListener) {
            this.unsubscribeMessageListener()
            this.unsubscribeMessageListener = undefined
        };
        if (this.unsubscribeProfileListener) {
            this.unsubscribeProfileListener()
            this.unsubscribeProfileListener = undefined
        };
        if (this.unsubscribeMyGroupChatListener) {
            this.unsubscribeMyGroupChatListener()
            this.unsubscribeMyGroupChatListener = undefined
        };
        if (this.unsubscribeJoinedGroupOneChatListener) {
            this.unsubscribeJoinedGroupOneChatListener()
            this.unsubscribeJoinedGroupOneChatListener = undefined
        };
        if (this.unsubscribeJoinedGroupTwoChatListener) {
            this.unsubscribeJoinedGroupTwoChatListener()
            this.unsubscribeJoinedGroupTwoChatListener = undefined
        };
    }

    setUser = setUser;
    logout = logout;

    setUpMessageListener = setUpMessageListener;

    // Called in onAuthStateChanged(), to determine if it's a new day.
    // It calls the utils function below and updates sign in dates/counts for days and months.
    // We are currently utilzing timestamps and having new day's based on the
    // the user's timezone, so that a "reset" of a new day is midnight for everyone.
    // We may update this overtime to just be based on a specific UTC timezone.
    // TODO: Handle State updates after
    async checkNewDayAndSetDate(writableData) {
        // If it's a new day for the user, call the utils function to update the fields
         await updateSignInDate(this.props.fbsFirestore, this.state.readonlyUserdata.userNumber);
    }

    // Passed as props and called onClick of "Got it - Thanks" in SocialNotPressed.js.
    // Updates user doc with a onboarding completed field
    // See context in onAuthStateChanged.
    async completeGamerLoungeOnboarding() {
        const userDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
        await updateDoc (userDocRef, { gamerLoungeOnboardingCompleted: true });
        this.setState({ onboardingNotFinsihed: false })
    }

    // TODO: Finish logic here and check componentDidUpdate for what I was doing there, lol.
    handleHashChange() {
        const hash = window.location.hash.substring(1);
        console.log("Current hash:", hash); // Debug log
        if (hash === "my-group-chat" || hash === "my-group") {
            this.setState({ pressedButtonIndex: 0 }, () => {
                console.log("LFG Group"); // Debug log
            });
        } else if (hash === "joined-groups" || hash === "joined-group-chat") {
            this.setState({ pressedButtonIndex: 1 }, () => {
                console.log("Join Groups"); // Debug log
            });
        } else if (hash === "personal-contacts" || hash === "personal-messages") {
            this.setState({ pressedButtonIndex: 2 }, () => {
                console.log("Personal Messages"); // Debug log
            });
        } else if (hash === "friends" || hash === "notifications") {
            this.setState({ pressedButtonIndex: 3 }, () => {
                console.log("Friends & Notifications"); // Debug log
            });
        } else {
            this.setState({ pressedButtonIndex: null }, () => {
                console.log("Waiting Room"); // Debug log
            });
        }
    }

    // Function to handle marking a message as read
    // onClick of an unread message - checks if read before, then changes unread message to read:true, then
    // ... adds back filtered out messaages from setUpMessageListener so array is completed when updated and not overwritten
    // ... with only filtered messages.
    async markMessageAsRead(index) {
        const { messages, filteredOutMessages } = this.state;
            if (index < 0 || index >= messages.length) {
                // Handles invalid index
                return;
            }
          
            const message = messages[index];
            if (!message.read) {
                // Only update if the message is unread
                const messageDoc = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0");
                const messageDocRef = await getDoc(messageDoc)
                try {
                    // Update the onClicked message as read by modifying only the "read" field
                    const updatedMessages = [...messages];
                    updatedMessages[index].read = true;
                    console.log("Before update:", updatedMessages);
                
                    // Add the filtered out messages to updatedMessages
                    updatedMessages.push(...filteredOutMessages);
                    console.log("Filtered Messages:", filteredOutMessages);
                    await updateDoc(messageDoc, {
                      messages: updatedMessages
                    });
                    console.log("After update:", updatedMessages);
                } catch (error) {
                    console.error("Error marking message as read:", error);
                }
            }
    }

    setUpProfileListener() {
        const userDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
        const unsubscribe = onSnapshot(userDocRef, (doc) => {
            if (doc.exists()) {
                const profile = doc.data();
                this.setState({
                    profile,
                    checkUrl: true,
                })
            }
        });
        this.unsubscribeProfileListener = unsubscribe;
    }

    // Listener for "GroupChats" Collection in the user's doc.
    setUpMyGroupChatListener() {
        console.log("My Group Chat Listener started");
        const groupChatDocRef = doc(this.props.fbsFirestore, "GroupChats", `${this.state.readonlyUserdata.userNumber}`);
        const unsubscribe = onSnapshot(groupChatDocRef, (doc) => {
            if (doc.exists()) {
                const data = doc.data();
                const allMessages = doc.data().messages || [];
                allMessages.sort((a, b) => a.timeSent - b.timeSent);
                const groupMembers = data.groupMembers;
                const isLFG = data.isLFG;
                const groupNumber = data.groupNumber;
                const groupName = data.groupName;
                this.setState({
                    myGroup: data,
                    myGroupMessages: allMessages,
                    myGroupMembers: groupMembers,
                    myGroupActive: isLFG,
                    myGroupNumber: groupNumber,
                    myGroupName: groupName,
                })
                console.log("My Group Messages", this.state.myGroupMessages);
            };
        });

        // Check if the messages array is above 500 (well 501 i guess lol),
        // delete the 10 oldest messages if true.
        if (this.state.messages.length >= 500) {
           this.removeOldestMessages();
        }

        this.unsubscribeMyGroupChatListener = unsubscribe;
    }

    // Listener for "GroupChats" Collection in other user's docs.
    async setUpJoinedGroupChatOneListener() {
        console.log("Joined Group Chat #1 Listener started", this.state.writableUserdata.joinedGroupOne);
        const groupChatDocRef = doc(this.props.fbsFirestore, "GroupChats", `${this.state.writableUserdata.joinedGroupOne}`);
        const unsubscribe = onSnapshot(groupChatDocRef, (doc) => {
            if (doc.exists()) {
                const data = doc.data();
                const allMessages = doc.data().messages || [];
                allMessages.sort((a, b) => a.timeSent - b.timeSent);
                const groupMembers = data.groupMembers;
                const isLFG = data.isLFG;
                const groupNumber = data.groupNumber;
                const groupName = data.groupName;
                this.setState({
                    joinedGroupOne: data,
                    joinedGroupOneMessages: allMessages,
                    joinedGroupOneMembers: groupMembers,
                    joinedGroupOneActive: isLFG,
                    joinedGroupOneNumber: groupNumber,
                    joinedGroupOneGroupName: groupName,
                })
                console.log("Joined Group Messages State", this.state.joinedGroupOneMessages);
                console.log("Joined Group Messages Const", allMessages);
            };
        });

        // // Check if the messages array is above 500 (well 501 i guess lol),
        // // delete the 10 oldest messages if true.
        // if (this.state.joinedGroupOneMessages.length >= 501) {
        //     window.alert("Group Chat is full (or almost full!) - what now? You can either wait until the group creator is back online to send more messages, create a new group, chat with each other in your Social Hub Messages, or take the chat over to the Indemni discord. Sorry for the inconvenience, we are working on solutions for increasing group chat messaging capacity!")
        // }

        // if (this.state.joinedGroupOneMembers.some((member) => member.userNumber !== this.state.readonlyUserdata.userNumber)) {
        //     const userDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
        //     const updateIsInOtherGroup = this.state.writableUserdata.joinedGroupTwo === -1 ? false : true;
        //     await updateDoc(userDocRef, {
        //         joinedGroupOne: -1,
        //         isInOtherGroup: updateIsInOtherGroup,
        //     });
        //     console.log("User Left or Removed From Group");
        // }

        this.unsubscribeJoinedGroupOneChatListener = unsubscribe;
    }

    // Listener for "GroupChats" Collection in other user's docs.
    async setUpJoinedGroupChatTwoListener() {
        console.log("Joined Group Chat #2 Listener started");
        const groupChatDocRef = doc(this.props.fbsFirestore, "GroupChats", `${this.state.writableUserdata.joinedGroupTwo}`);
        const unsubscribe = onSnapshot(groupChatDocRef, (doc) => {
            if (doc.exists()) {
                const data = doc.data();
                const allMessages = doc.data().messages || [];
                allMessages.sort((a, b) => a.timeSent - b.timeSent);
                const groupMembers = data.groupMembers;
                const isLFG = data.isLFG;
                const groupNumber = data.groupNumber;
                const groupName = data.groupName;
                this.setState({
                    joinedGroupTwoMessages: allMessages,
                    joinedGroupTwoMembers: groupMembers,
                    joinedGroupTwoActive: isLFG,
                    joinedGroupTwoNumber: groupNumber,
                    joinedGroupTwoGroupName: groupName,
                })
                console.log("Joined Group Two Messages", this.state.joinedGroupTwoMessages);
            };
        });

        // Check if the messages array is above 500 (well 501 i guess lol),
        // delete the 10 oldest messages if true.
        if (this.state.joinedGroupTwoMessages.length >= 501) {
           window.alert("Group Chat is full (or almost full!) - what now? You can either wait until the group creator is back online to send more messages, create a new group, chat with each other in your Social Hub Messages, or take the chat over to the Indemni discord. Sorry for the inconvenience, we are working on solutions for increasing group chat messaging capacity!")
        }
        if (this.state.joinedGroupTwoMembers.some((member) => member.userNumber !== this.state.readonlyUserdata.userNumber)) {
            const userDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
            const updateIsInOtherGroup = this.state.writableUserdata.joinedGroupOne === -1 ? false : true;
            await updateDoc(userDocRef, {
                joinedGroupTwo: -1,
                isInOtherGroup: updateIsInOtherGroup,
            });
            console.log("User Left or Removed From Group");
        }

        this.unsubscribeJoinedGroupTwoChatListener = unsubscribe;
    }

    async removeOldestMessages() {
        try {
            // Sort / Remove oldest messages - then update db.
            const allMessages = this.state.messages;
            allMessages.sort((a, b) => b.timeSent - a.timeSent);
            const updatedChatMessages =  allMessages.slice(0, 400);
            const chatDocRef = doc(this.props.fbsFirestore, "AnswererData", `${this.props.userdata.userNumber}`, "Chats", `${this.props.queue[0].askerNumber}`);
            await updateDoc(chatDocRef, {messages: updatedChatMessages} );

            // Then do standard filter by user and time sort.
            // const userMessages = updatedUserMessages.filter((message) => message.fromName === recipientUser || message.toName === recipientUser);
            updatedChatMessages.sort((a, b) => a.timeSent - b.timeSent);
            console.log("Message Data:", updatedChatMessages);
            this.setState({ messages: updatedChatMessages })
        }
        catch (error) {
            console.error(error);
        }
    }

    handleTabDisplayed(index) {
        this.setState(prevState => ({
            pressedButtonIndex: prevState.pressedButtonIndex === index ? null : index
        }));
    }

    disableGroupChat() {
        this.setState({ couldNotFindGroup: true })
    }

    // Sets state of chat started to true - called onClick of "Start Chat" in AskAGamerAnswerGoLive.
    // Updates the AnswererData doc to make the selected user in AskAGamerAnswerGoLive the
    // current asker in the field currentAskerName.
    async startChat() {
        this.setState({chatStarted: true});
        const AnswererDocRef = doc(this.props.fbsFirestore, "AnswererData", `${this.state.readonlyUserdata.userNumber}`);
        try {
            await updateDoc(AnswererDocRef, {
                currentAskerName: this.state.queue[0].askerName
            });
        } catch (error) {
            console.error("Error Updating Current Asker Name:", error);
        }
        console.log("Current Asker Name", this.state.answererData.currentAskerName)
    }

    // Calls cloud function answererAnswerer onClick of "End Chat" in
    // AskAGamerAnswerGoLive and rewards INDE for answerer as well as
    // ends the chat for the current user.
    // TODO (pre-launch / now): Check if this works.
    async endChat() {
         // Return early if user is spamming.
         if (this.state.cooldown) return
         // Set the cooldown to true so the user's can't send another message.
         this.setState({ cooldown: true, chatStarted:false });

         const idToken = await this.props.fbsAuth.currentUser.getIdToken(/* forceRefresh */ true)
         // Send token to backend via HTTPS
         const response = await fetch("https://answereranswer-cuw3q2hq5q-uc.a.run.app", {
             method: "post",
             headers: {
                 "Accept": "application/json",
                 "Content-Type": "application/json",
                  // Custom auth header required to authorize call.
                 "Authorization": `ID ${idToken}`,
             },

             // Serialize request body as JSON, as specified by Content-Type header.
             body: JSON.stringify({
                 answererNumber: this.state.readonlyUserdata.userNumber,
                 answererName: this.state.readonlyUserdata.username,
             })
         })
         if (response.ok) console.log("Answerer Live Data Updated", response.status)
         else window.alert(`Error ${response.status}: ${response.statusText}`)


         // Set a timeout 1.5 seconds
         setTimeout(() => {
             this.setState({ cooldown: false });
         }, 1.5);

    }

    // Calls cloud function answererToggleLive.
    // Called onClick of "Go Live" or "Go Offline" button. Either adds answerer to the array
    // ... liveData in the "AskAGamer", "Live" doc or removes the answerer from the array.
    // TODO: throw error that transfer didn't happen if !response.ok
    async answererToggleLive() {
        // Return early if user is spamming.
        if (this.state.cooldown) return
        // Set the cooldown to true so the user's can't send another message.
        this.setState({ cooldown: true });

        const idToken = await this.props.fbsAuth.currentUser.getIdToken(/* forceRefresh */ true)
        // Send token to backend via HTTPS
        const response = await fetch("https://answerertogglelive-cuw3q2hq5q-uc.a.run.app", {
            method: "post",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                 // Custom auth header required to authorize call.
                "Authorization": `ID ${idToken}`,
            },

            // Serialize request body as JSON, as specified by Content-Type header.
            body: JSON.stringify({
                answererGGNumber: this.state.answererData.answererGGNumber,
                answererTotal: this.state.answererData.answererTotal,
            })
        })
        if (response.ok) console.log("Answerer Live Data Updated", response.status)
        else window.alert(`Error ${response.status}: ${response.statusText}`)


        // Set a timeout 1.5 seconds
        setTimeout(() => {
            this.setState({ cooldown: false });
        }, 1.5);
    }

    // Called onCLick of certain actions that plays an mp3 file from the 'Public' folder.
    // Currently set to 30% volume for all files, but can be adjusted to be higher/per file accordingly.
    // Passed down as props to children components as well.
    // TODO (eventually - not a priority): Add a "Adjust Sound Effect Volume" option in Settings,
    // so that users can set audio.volume globally however they'd like.
    playAudio(source) {
        const audio = new Audio(source);
        audio.volume = 0.3;
        audio.play();
    };

    render() {
        const out = [];

        // Navbar
        out.push(
            <BasicNavbar
                user={this.state.user}
                userdata={{...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata}}
                unreadCount={this.state.unreadCount}
                universityName={(this.state.readonlyUserdata && this.state.readonlyUserdata.inTheUniveristyProgram) ? this.state.readonlyUserdata.universityName : "University"}
            />
        )

        if (this.state.notUser && !this.state.readonlyUserdata)
            out.push(
                <div
                    className="vh-100 fluid d-flex flex-row flex-grow-1 justify-content-center align-items-center glow visting-home-page-fade-animation"
                    style={{"overflow-y":"hidden"}}
                >
                    <h2
                        className="d-flex flex-column align-items-center justify-content-center"
                        style={{"font-weight":"700", "font-size":"1.5em", "font-style":"italic", "text-align":"center", "margin-bottom":"7em"}}
                    >
                        <span
                            className="desktop-increase-demo-gamer-profile-text-size"
                            style={{"margin-bottom":"2em"}}
                        >
                            You need to create an account to access your Gamer Profile. Create one for free in seconds below.
                        </span>
                        <span
                            className="d-flex flex-row align-items-center justify-content-center"
                            style={{ "width": "100%", "font-size": ".9em" }}
                        >
                            <Button
                                className="d-flex flex-column mx-2 p-2 fs-5 align-items-center change-padding-tablet font-size-75-tablet width-auto-tablet"
                                style={{ "background-color": "#1d77ff", "font-weight": "600", "width": "245px" }}
                                href="/"
                            >
                                Sign Up
                            </Button>
                        </span>
                    </h2>
                </div>
            );

        // Conditional when loading the user's profile to make sure userdata is set.
        if (!this.state.notUser && !this.state.readonlyUserdata)
            out.push(
                <div
                    className="vh-100 fluid d-flex flex-row flex-grow-1 justify-content-center align-items-center glow visting-home-page-fade-animation"
                    style={{"overflow-y":"hidden"}}
                >
                    <h2
                        className="d-flex flex-column align-items-center justify-content-center"
                        style={{"font-weight":"700", "font-size":"2em", "font-style":"italic", "text-align":"center", "margin-bottom":"7em"}}
                    >
                        <span
                            className="desktop-increase-demo-gamer-profile-text-size"
                            style={{"margin-bottom":"2em"}}
                        >
                            Loading Profile
                        </span>
                        <div className="big-circle-inline-loading-animation-demo big-circle-inline-loading-animation-demo-desktop"/>
                    </h2>
                </div>
            );
            
        
        // Standard Page Body Render
        if (this.state.readonlyUserdata)
            out.push(
                <>
                    <div className="fluid overflow-hidden home-parent-container-mobile">
                        <>
                            <Container
                                className={this.state.fadeOutAnimation ? "d-flex flex-column fluid m-1 p-5 page-fade-out-animation" : "d-flex flex-column fluid m-1 p-5 page-fade-animation remove-padding-medium-container remove-padding-mobile padding-1ish-tablet"}
                                style={{"padding-bottom":"1em","padding-top":"1em", "overflow-x":"hidden"}}
                            >
                                <Row className="pl-1 justify-content-center fluid home-assets-margin-left-mobile home-parent-container-center-mobile">
                                    <Col
                                        className="d-flex fluid flex-column align-items-center shadow col-3 fs-5 fw-bold pb-4 sidebar-width-large home-sidebar-row-mobile"
                                        style={{ "height":"1025px" }}
                                    >
                                        <Stack
                                            gap={3}
                                            className="d-flex fluid flex-column align-items-center"
                                            style={{ "justify-content":"flex-start" }}
                                        >
                                            <div className="p-2 mt-2">
                                                <Image
                                                    className="shadow home-sidebar-image-mobile page-fade-animation"
                                                    src={this.state.pfp === "" ? "/ProfilePicturePlaceholder.png" : this.state.pfp}
                                                    alt="Profile Picture"
                                                    rounded
                                                    fluid
                                                />
                                            </div>
                                            <div className="d-flex flex-column align-items-center font-size-75-tablet">
                                                <div
                                                    style={{"color":"#f3df92"}}
                                                    className="p-2"
                                                >
                                                    {this.state.readonlyUserdata.username}
                                                </div>
                                                { this.state.readonlyUserdata.inTheUniveristyProgram && this.state.writableUserdata.realName && this.state.writableUserdata.realName !== "" &&
                                                    <div
                                                        style={{"color":"#ffffff", "font-size":".85em", "font-style":"italic", "font-weight":"500", "margin-top":"-.5em", "margin-bottom":"1em"}}
                                                        className="px-2 pb-2"
                                                    >
                                                        ({this.state.writableUserdata.realName})
                                                    </div>
                                                }
                                                { this.state.readonlyUserdata.inTheUniveristyProgram &&
                                                    <div
                                                        style={{"color":"#ffb865", "font-size":".9em", "text-wrap":"balance", "margin-top":"-.5em", "margin-left":".85em"}}
                                                        className="px-2 py-1"
                                                    >
                                                        {this.state.readonlyUserdata.universityName} <ShieldCheck style={{"color":"white", "margin-left":".25em", "margin-bottom":".2em", "margin-right":".5em", "align-items":"center", "height":"15px", "width":"auto"}}/>
                                                    </div>
                                                }
                                                <div
                                                    style={{"font-size":".9em", "text-shadow":"0 -1px 2px #2c2626", "color":"#c3c2c2"}}
                                                    className="px-2"
                                                >
                                                    <span>User - # </span>
                                                        <span style={{"font-weight":"800"}}>
                                                            {this.state.readonlyUserdata.userNumber}
                                                     </span>
                                                </div>
                                                <hr
                                                    className="ask-a-gamer-answer-spotlight-hr"
                                                    style={{"width":"100%"}}
                                                />
                                                <div
                                                    style={{"font-size":".9em"}}
                                                    className="p-2"
                                                >
                                                    {/* TODO: Status stuff! */}
                                                    <span>Status: </span>
                                                    { !this.state.writableUserdata.status
                                                      ?
                                                        <span style={{"font-style":"oblique"}}>
                                                            Not Set
                                                        </span>
                                                      :
                                                        <span className="animate-ask-a-gamer-status">
                                                            {this.state.writableUserdata.status}
                                                        </span>
                                                    }
                                                </div>
                                                {/* TODO: Clan stuff! Same as Status stuff above. */}
                                                <div
                                                    className="p-2"
                                                    style={{"font-size":".9em"}}
                                                >
                                                    <span>Clan: </span>
                                                    { !this.state.writableUserdata.clan
                                                      ?
                                                        <span style={{"font-style":"oblique"}}>
                                                            Not set
                                                        </span>
                                                      :
                                                        <span style={{"font-style":"oblique"}}>
                                                            {this.state.writableUserdata.clan}
                                                        </span>
                                                    }
                                                </div>
                                            </div>
                                            <hr
                                                className="ask-a-gamer-answer-spotlight-hr"
                                                style={{"width":"100%"}}
                                            />
                                            <div className="d-flex flex-column align-items-center font-size-75-tablet">
                                                <div
                                                    style={{"font-size":".9em"}}
                                                    className="d-flex flex-column align-items-center justify-content-between"
                                                >
                                                    <h4
                                                        className="social-page-titles-styling"
                                                        style={{"font-size":"1.5em", "text-align":"center", "width":"100%", "font-weight":"700", "margin-bottom":"1em"}}
                                                    >
                                                        My Groups
                                                    </h4>
                                                    <div className="d-flex flex-column align-items-center justify-content-center me-3 font-size-75-tablet">
                                                        <div
                                                            style={{"margin-left":".5em"}}
                                                            className="d-flex flex-row align-items-center justify-content-center font-size-75-tablet"
                                                        >
                                                            <div className="mx-3 animated-press-div">
                                                                <button
                                                                    className={`animated-press-button learn-more ${this.state.pressedButtonIndex === 0 ? 'pressed' : ''}`}
                                                                    style={{"font-size":".65em"}}
                                                                    onClick={() => this.handleTabDisplayed(0)}
                                                                >
                                                                    Posted Group
                                                                </button>
                                                            </div>
                                                            <div className="mx-3 animated-press-div">
                                                                <button
                                                                    className={`animated-press-button learn-more ${this.state.pressedButtonIndex === 1 ? 'pressed' : ''}`}
                                                                    style={{"font-size":".65em"}}
                                                                    onClick={() => this.handleTabDisplayed(1)}
                                                                >
                                                                    Joined Groups
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div
                                                    style={{"font-size":".9em"}}
                                                    className="d-flex flex-column align-items-center justify-content-between"
                                                >
                                                    <h4
                                                        className="social-page-titles-styling"
                                                        style={{"font-size":"1.5em", "text-align":"center", "width":"100%", "font-weight":"700", "margin-top":"1.5em", "margin-bottom":"1em"}}
                                                    >
                                                        My Social Hub
                                                    </h4>
                                                    <div className="d-flex flex-column align-items-center justify-content-center me-3 font-size-75-tablet">
                                                        <div
                                                            style={{"margin-left":".5em", "margin-bottom":"1.5em"}}
                                                            className="d-flex flex-row align-items-center justify-content-center font-size-75-tablet"
                                                        >
                                                            <div className="mx-3 animated-press-div">
                                                                <button
                                                                    className={`animated-press-button learn-more ${this.state.pressedButtonIndex === 2 ? 'pressed' : ''}`}
                                                                    style={{"font-size":".65em"}}
                                                                    onClick={() => this.handleTabDisplayed(2)}
                                                                >
                                                                    {this.state.unreadCount > 0 ? `Personal Messages (${this.state.unreadCount})` : "Personal Messages"}
                                                                </button>
                                                            </div>
                                                            <div className="mx-3 animated-press-div">
                                                                <button
                                                                    className={`animated-press-button learn-more ${this.state.pressedButtonIndex === 3 ? 'pressed' : ''}`}
                                                                    style={{"font-size":".65em"}}
                                                                    onClick={() => this.handleTabDisplayed(3)}
                                                                >
                                                                    {this.state.unreadCount > 0 ? `Friends & Notifications (${this.state.unreadCount})` : "Friends & Notifications"}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Stack>
                                    </Col>
                                    <Col
                                        className="d-flex fluid flex-column col-9 fs-6 fw-bold mb-2 pb-4 pe-3 home-content-width-mobile"
                                        style={{ "padding-left":"2em", "max-width":"1300px" }}
                                    >
                                        { this.state.profile && this.state.pressedButtonIndex === null &&
                                            <SocialNotPressedTabs
                                                fbsAuth={this.props.fbsAuth}
                                                fbsFirestore={this.props.fbsFirestore}
                                                fbsStorage={this.props.fbsStorage}
                                                user={this.state.user}
                                                username={this.state.username}
                                                userdata={{...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata}}
                                                startChat={this.startChat}
                                                endChat={this.endChat}
                                                playAudio={this.playAudio}
                                                chatStarted={this.state.chatStarted}
                                                gamerIsLive={this.state.gamerIsLive}
                                                queue={this.state.queue}
                                                queueLength={this.state.queueLength}
                                                updatedQueueLength={this.state.updatedQueueLength}
                                                answererData={this.state.answererData}
                                                messages={this.state.messages}
                                                unreadCount={this.state.unreadCount}
                                                handleTimeDelayOfPfp={this.handleTimeDelayOfPfp}
                                                profile={this.state.profile}
                                                myGroupMessages={this.state.myGroupMessages}
                                                myGroupMembers={this.state.myGroupMembers}
                                                onboardingNotFinsihed={this.state.onboardingNotFinsihed}
                                                completeGamerLoungeOnboarding={this.completeGamerLoungeOnboarding}
                                            />
                                        }
                                        { this.state.profile && this.state.pressedButtonIndex === 0 &&
                                            <SocialLFGPostTabs
                                                fbsAuth={this.props.fbsAuth}
                                                fbsFirestore={this.props.fbsFirestore}
                                                fbsStorage={this.props.fbsStorage}
                                                user={this.state.user}
                                                username={this.state.username}
                                                userdata={{...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata}}
                                                startChat={this.startChat}
                                                endChat={this.endChat}
                                                playAudio={this.playAudio}
                                                chatStarted={this.state.chatStarted}
                                                gamerIsLive={this.state.gamerIsLive}
                                                queue={this.state.queue}
                                                queueLength={this.state.queueLength}
                                                updatedQueueLength={this.state.updatedQueueLength}
                                                answererData={this.state.answererData}
                                                messages={this.state.messages}
                                                unreadCount={this.state.unreadCount}
                                                handleTimeDelayOfPfp={this.handleTimeDelayOfPfp}
                                                profile={this.state.profile}
                                                myGroupMessages={this.state.myGroupMessages}
                                                myGroupMembers={this.state.myGroupMembers}
                                            />
                                        }
                                        { this.state.profile && this.state.pressedButtonIndex === 1 &&
                                            <SocialGroupsTabs
                                                fbsAuth={this.props.fbsAuth}
                                                fbsFirestore={this.props.fbsFirestore}
                                                fbsStorage={this.props.fbsStorage}
                                                user={this.state.user}
                                                username={this.state.username}
                                                userdata={{...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata}}
                                                startChat={this.startChat}
                                                endChat={this.endChat}
                                                playAudio={this.playAudio}
                                                chatStarted={this.state.chatStarted}
                                                gamerIsLive={this.state.gamerIsLive}
                                                queue={this.state.queue}
                                                queueLength={this.state.queueLength}
                                                updatedQueueLength={this.state.updatedQueueLength}
                                                answererData={this.state.answererData}
                                                messages={this.state.messages}
                                                unreadCount={this.state.unreadCount}
                                                handleTimeDelayOfPfp={this.handleTimeDelayOfPfp}
                                                profile={this.state.profile}
                                                joinedGroupOne={this.state.joinedGroupOne}
                                                joinedGroupOneNumber={this.state.joinedGroupOneNumber}
                                                joinedGroupOneMessages={this.state.joinedGroupOneMessages}
                                                joinedGroupOneMembers={this.state.joinedGroupOneMembers}
                                                disableGroupChat={this.disableGroupChat}
                                                couldNotFindGroup={this.state.couldNotFindGroup}
                                            />
                                        }
                                        { this.state.profile && this.state.pressedButtonIndex === 2 &&
                                            <SocialPersonalTabs
                                                fbsAuth={this.props.fbsAuth}
                                                fbsFirestore={this.props.fbsFirestore}
                                                fbsStorage={this.props.fbsStorage}
                                                user={this.state.user}
                                                username={this.state.username}
                                                userdata={{...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata}}
                                                startChat={this.startChat}
                                                endChat={this.endChat}
                                                playAudio={this.playAudio}
                                                chatStarted={this.state.chatStarted}
                                                gamerIsLive={this.state.gamerIsLive}
                                                queue={this.state.queue}
                                                queueLength={this.state.queueLength}
                                                updatedQueueLength={this.state.updatedQueueLength}
                                                answererData={this.state.answererData}
                                                messages={this.state.messages}
                                                unreadCount={this.state.unreadCount}
                                                handleTimeDelayOfPfp={this.handleTimeDelayOfPfp}
                                                profile={this.state.profile}
                                            />
                                        }
                                        { this.state.profile && this.state.pressedButtonIndex === 3 &&
                                            <SocialSearchNotificationsTabs
                                                fbsAuth={this.props.fbsAuth}
                                                fbsFirestore={this.props.fbsFirestore}
                                                fbsStorage={this.props.fbsStorage}
                                                user={this.state.user}
                                                username={this.state.username}
                                                userdata={{...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata}}
                                                startChat={this.startChat}
                                                endChat={this.endChat}
                                                playAudio={this.playAudio}
                                                chatStarted={this.state.chatStarted}
                                                gamerIsLive={this.state.gamerIsLive}
                                                queue={this.state.queue}
                                                queueLength={this.state.queueLength}
                                                updatedQueueLength={this.state.updatedQueueLength}
                                                answererData={this.state.answererData}
                                                messages={this.state.messages}
                                                unreadCount={this.state.unreadCount}
                                                handleTimeDelayOfPfp={this.handleTimeDelayOfPfp}
                                                profile={this.state.profile}
                                                markMessageAsRead={this.markMessageAsRead}
                                            />
                                        }
                                    </Col>
                                </Row>
                                <Footerfixed></Footerfixed>
                            </Container>
                        </>
                    </div>
                </>
            )

            return out;
    }
}

export default SocialPage;
