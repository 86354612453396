// The LFG Post Group Page.
// TODO: Write Comments
import React, { Component } from "react"
import {
    Button,
    Modal,
    Dropdown,
    Stack,
    Pagination,
} from "react-bootstrap"

class SocialNotPressed extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {

        return (
            <>
                <Stack
                    gap={2}
                    className="col-12 fluid mx-auto"
                >
                    <div className="me-2 px-2 ask-a-gamer-explore-margin-padding-right-left-0-mobile">
                        { this.props.onboardingNotFinsihed &&
                            <div className="p-2 game-page-post-containers-mobile mt-2">
                                <div
                                    style={{ "margin-bottom": "1em", "margin-top": "1em" }}
                                    className="d-flex flex-column tab-textbox-hover-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile"
                                >
                                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                                        <div
                                            className="d-inline-flex flex-row align-items-center flex-wrap"
                                            style={{ "width": "100%" }}
                                        >
                                            <div
                                                className="d-flex flex-column align-items-center justify-content-center flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile increase-demo-instruction-font-desktop"
                                                style={{ "flex-grow": "1" }}
                                            >
                                                <span
                                                    style={{"font-size":"1.1em"}}
                                                    className="mb-2"
                                                >
                                                    Hey again {this.props.userdata.username}!
                                                </span>
                                                <span
                                                    style={{"font-size":"1.1em", "text-decoration":"underline", "font-style":"oblique"}}
                                                    className="mt-2 mb-1"
                                                >
                                                    How Gamer Lounge Works
                                                </span>
                                                <p style={{"color":"#fff1c5", "font-weight":"450", "padding-right":".5em", "padding-left":".25em"}}>
                                                    Your Gamer Lounge is where all your groups, chats, messages, and friends are located. Click on "Posted Group" to see the group members and group chat for your current LFG post. Click on "Joined Groups" to see the LFG posts you have joined, as well as the group chat and members (* currently you can only join one group - not including your own LFG post). "Personal Messages" is where you can chat with other Indemni users directly. 
                                                </p>
                                                <p style={{"color":"#fff1c5", "font-weight":"450", "padding-right":".5em", "padding-left":".25em"}}>
                                                    "Friends & Notifications" is where you can see your current friends, search for users on Indemni and visit their profiles, and where all your notifications are stored. Currently, you can search for users by typing in their exact username in the search bar, and then add them as friends or message them when you are on their profile. This section will be updated soon to make it more clear and user friendly. We plan to move notifications to either your Gamer Profile or just as a seperate page all together.
                                                </p>
                                                <p style={{"color":"#fff1c5", "font-weight":"450", "padding-right":".5em", "padding-left":".25em"}}>
                                                    If you see a blinking border in the navigation menu with a number, that means you have a personal message you have not read yet. You will be able to see how many new messages you have by looking at the number next to the "Personal Messages" button. Currently, notifications and unread messages is only working for personal messages. We will be updating this soon as well, so that you know when you have no group chat messages, and when someone joins or leaves your group!
                                                </p>
                                                <span
                                                    style={{"color":"#fff1c5", "font-weight":"450", "padding-right":".5em", "padding-left":".25em"}}
                                                    className="mb-2"
                                                >
                                                    As noted before, if you need this information again, or have any other questions on how things work, everything you need to know will be located in the FAQ page. You can also join our <a href="https://discord.gg/9EZmV5qBY5" target="_blank" className="change-link-color-chat">Discord</a> to get your questions answered faster, give feedback, chat with the team, participate in events, and meet more members of the Indemni community.
                                                </span>
                                                <Button
                                                    className="d-flex flex-column mx-1 p-1 align-items-center chat-with-gamer-button glow mt-3"
                                                    style={{"width":"200px", "box-shadow":"#fffzfff82 0px 0px 10px 3px", "font-size":"1em", "font-weight":"550", "text-shadow":"black 0px 2px 2px", "background-color":"#39ff6ba1", "border-radius":"20px"}}
                                                    onClick={async () => {
                                                        await this.props.completeGamerLoungeOnboarding();
                                                    }}
                                                >
                                                    Got it - Thanks!
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="p-2 game-page-post-containers-mobile mt-2">
                            <div
                                style={{ "margin-bottom": "1em", "margin-top": "1em" }}
                                className="d-flex flex-column tab-textbox-hover-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile"
                            >
                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                    <div
                                        className="d-inline-flex flex-row align-items-center flex-wrap"
                                        style={{ "width": "100%" }}
                                    >
                                        <div
                                            className="d-flex flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                            style={{ "flex-grow": "1" }}
                                        >
                                            <p style={{ "width": "100%", "margin-bottom": "0" }}>
                                                <div className="d-flex flex-column flex-wrap justify-content-center align-items-center overflow-auto text-center" >
                                                    <small
                                                        style={{ "font-size": "1.5em", "font-weight": "600", "text-align": "center", "text-shadow": "0px -3px 2px #000000" }}
                                                        className="d-flex flex-column desktop-increase-demo-gamer-profile-text-size"
                                                    >
                                                        <span style={{ "margin-bottom": "1em", "font-size": ".9em"}}>
                                                            Click on a button in your Gamer Display (sidebar on desktop - top of the page on mobile) to see your groups, chats, and friends.
                                                        </span>
                                                    </small>
                                                </div>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Stack>
            </>
        );
    }
}

export default SocialNotPressed;
