// Settings Page for Users to update there privacy settings and/or delete their account.
import React, { Component } from "react"
import { Button, Form, Modal, Offcanvas, Row, Col, Alert, ButtonGroup } from "react-bootstrap"

import BasicNavbar from "../components/Navbar/Navbar"
import Footerfixed from "../components/Footer/Footer";

import {
    deleteUser,
    onAuthStateChanged,
    signOut,
    reauthenticateWithCredential,
    signInWithPopup,
    EmailAuthProvider, 
    GoogleAuthProvider,
    TwitterAuthProvider,
    sendPasswordResetEmail,
} from "firebase/auth";

import { ref, getDownloadURL } from "firebase/storage"

import {
    doc,
    getDoc,
    setDoc,
    query,
    where,
    collectionGroup,
    getDocs,
    updateDoc,
} from "firebase/firestore";

class SettingsPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: undefined,
            userdata: undefined,
            providerData: undefined,
            show: false, // shows modal for deleting account
            showSidebar: true, // shows sidebar - set to true initially so its open when page loads
            showResetPasswordAlert: false, // shows success alert & instructions for resetting pasword
            showAuthModal: false, // shows reAuth Modal for Email & password.
            error: null, // used to pass an alert for incorrect password to user when showAuthModal is true & re-signing in.
            disabledButton: false,
            deleteAccountCheck: "",
            copyButtonText: "Copy",
            
            // booleans for updating "Privacy" settings
            friendRequestsOn: false,
            friendRequestsOff: false,
            messagesFromAnyone: false,
            messagesFromAnyone: false,
            messagesNever: false,
            profilePublic: false,
            profilePrivate: false,
            hidden: undefined,

            groupNotificationsOff: false,
            personalMessageNotificationsOff: false,
            turnOnPersonalMessageNotifications: false,
            turnOnGroupNotifications: false,
            turnOffPersonalMessageNotifications: false,
            turnOffGroupNotifications: false,

            
        }

        this.logout = this.logout.bind(this)
        this.setUser = this.setUser.bind(this)
        this.deleteAccount = this.deleteAccount.bind(this)
        this.reAuthUser = this.reAuthUser.bind(this)
        this.handleAuthClose = this.handleAuthClose.bind(this)
        this.resetPassowrd = this.resetPassowrd.bind(this)

        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);
        this.handleKey = this.handleKey.bind(this);

        this.handleSidebarClose = this.handleSidebarClose.bind(this)
        this.handleSidebarShow = this.handleSidebarShow.bind(this)

        // this.clearAssetCache = this.clearAssetCache.bind(this)

        // this.privacyFriendRequests = this.privacyFriendRequests.bind(this)
        this.updatePersonalMessageNotifications = this.updatePersonalMessageNotifications.bind(this);
        this.updateGroupNotifications = this.updateGroupNotifications.bind(this);
        // this.privacyMessages = this.privacyMessages.bind(this)
        // this.privacyProfile = this.privacyProfile.bind(this)

        // Called in reAuthUser function for email/password reauth to resolve Promise
        this.passwordConfirmationPromise = {
            resolve: null,
        };
        
        // Web3Modal unsubscribe from subscribeProvider on componentUnmount
        // this.walletSubscription = null;

        
        onAuthStateChanged(this.props.fbsAuth, async (user) => {
            if (user) {
                this.setUser(user)
                .then(async () => {
                    // Get User Docs: Used for state rendering of Privacy Buttons and updating Privacy Settings.
                    // Profile Privacy of "hidden" field boolean can be accessed from the same doc - so only one read is required
                    // False booleans (except receiveMessages & receiveMessagesOnlyFromFriends, where true means messages are allowed) mean there are no privacy 
                    // ... restrictions for that variable i.e., what it's referencing is allowed. Check db, functions/index.js, or comments in code for clarification.
                    // ToDo: Maybe update the booleans to make sense given the variable lol, e.g., receiveFriendRequests: true - means the user allows it.
                    if (this.state.readonlyUserdata) {
                        // console.log(this.state.readonlyUserdata, user)
                        // Profile Privacy & Friend Privacy Doc
                        // TODO: This is Email notifications now, update variables and logic.
                            const personalMessageNotifications = this.state.hiddenWritableUserdata.personalMessageEmailNotificationsOff ? this.state.hiddenWritableUserdata.personalMessageEmailNotificationsOff : false;
                            const groupNotifications = this.state.hiddenWritableUserdata.groupEmailNotificationsOff ? this.state.hiddenWritableUserdata.groupEmailNotificationsOff : false;
                            this.setState({ groupNotificationsOff: groupNotifications, personalMessageNotificationsOff: personalMessageNotifications }); // currentFriendPrivacy & currentProfilePrivacy are booleans
                
                        // // Message Privacy Doc 
                        // const messageDocSnapshot = await getDoc(doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0"));
                        // if (messageDocSnapshot.exists()) {
                        //     const receiveAllMessages = messageDocSnapshot.data().receiveMessages; // is default true
                        //     const receiveOnlyFriendMessages = messageDocSnapshot.data().receiveMessagesOnlyFromFriends; // is default false
                        //     this.setState({ currentAllMessagePrivacy: receiveAllMessages, currentMessageFriendPrivacy: receiveOnlyFriendMessages }); // Both are booleans
                        // } 
                         // Gets User Pfp from firebase storage if it exists
                         if (this.state.readonlyUserdata.generatedPFP !== "") {
                            // Create a reference from a Google Cloud Storage URI
                            const gsReference = ref(this.props.fbsStorage, `gs://justtesting-c24bc.appspot.com/1155TokenMetadata/${this.state.readonlyUserdata.userNumber.toString(16)}.png`);
                            // `url` is the download URL for 'images/stars.jpg'
                            const url = await getDownloadURL(gsReference)
                            this.setState({pfp: url})
                        }
                    } 
                });

                // providerData lets you access an array of firebase auth sign-in information for currentUser
                this.setState({ providerData: user.providerData})
                
                // // Web3Modal/Wallet/Provider Info - might be a better way to do this, but i am just following the docs.
                // const address = this.props.walletModal.getAddress();
                // const isConnected = this.props.walletModal.getIsConnected();
                // const walletProvider = this.props.walletModal.getWalletProvider();
                // const walletProviderType = this.props.walletModal.getWalletProviderType();
                // const signer = this.props.walletModal.getSigner();
                
                // console.log("Web3Modal Account Info", address, isConnected, walletProvider, walletProviderType, signer);
                // this.setState({address, isConnected, walletProvider, walletProviderType, signer});
                
                // // Subscribes to provider changes - needed to asses state change in current user session.
                // this.walletSubscription = this.props.walletModal.subscribeProvider(({
                //     provider, providerType, address, chainId, isConnected
                // }) => {
                //     // set state of provider changes
                //     this.setState({ providerChange: provider, addressChange: address, isConnectedChange: isConnected });
                //     console.log("Provider change:", { provider, providerType, address, chainId, isConnected });
                // });
            }
            else {
                this.logout();
                // Sends the user back to LandingPage immediately if they aren't logged in
                window.location.href = '/';
            }
        })
    }

    // On logout - send user back to landing/login page.
    logout() {
        /* If not logged in, don't try to log out. */
        if (this.state.user === undefined) return;

        signOut(this.props.fbsAuth)
            .then(() => {
                this.setState({
                    user: undefined,
                    readonlyUserdata: undefined,
                    writableUserdata: undefined,
                    hiddenWritableUserdata: undefined
                }, () => {
                    console.log("Logged out!")
                    window.location.href = '/';
                })
            })
            .catch((error) => {
                // TODO: What do we do if signing out fails?
                console.error("Could not sign out!")
                console.error(`FIREBASEAUTH (${error.code}): ${error.message}`)
                console.error(error)
            })
    }

    async setUser(newUser) {
        return new Promise(async resolve => {
            // Somewhat validate new user
            if (typeof newUser !== "object" || typeof newUser.uid === "undefined") resolve(false);

            const userReadonlyQuery = query(
                collectionGroup(this.props.fbsFirestore, "Readonly"),
                where("uid", "==", newUser.uid)
            );

            const userReadonlySnapshots = await getDocs(userReadonlyQuery);
            if (userReadonlySnapshots.empty) {
                console.error(`Login failed: Could not find user in database with uid == ${newUser.uid}`)
                resolve(false)
                return;
            }

            const userReadonlySnapshot = userReadonlySnapshots.docs[0]
            if (!await userReadonlySnapshot.exists()) {
                console.error(`Login failed: Could not find user in database with uid == ${newUser.uid}`)
                resolve(false)
                return;
            }

            const userReadonlyData = userReadonlySnapshot.data()

            const userNumber = userReadonlyData.userNumber
            const userNumberString = `${userNumber}`

            const userWritableSnapshot = await getDoc(doc(this.props.fbsFirestore, "Users", userNumberString, "PublicReadonlyPrivateWritable", "0"));
            const userWritableData = userWritableSnapshot.data();

            const userHiddenSnapshot = await getDoc(doc(this.props.fbsFirestore, "Users", userNumberString, "PublicHiddenPrivateWritable", "0", "Inbox", "0"));
            const userHiddenData = userHiddenSnapshot.data();

            this.setState({
                user: newUser,                          // Firebase Authentication user object
                readonlyUserdata: userReadonlyData,     // /Users/<userNumber>/Readonly/0
                writableUserdata: userWritableData,     // /Users/<userNumber>/PublicReadonlyPrivateWritable/0
                hiddenWritableUserdata: userHiddenData, // /Users/<userNumber>/PublicHiddenPrivateWritable/0
            }, () => {
                resolve(true)
            })
        })
    }

    // Needed for state management as Web3Modal getters can't be updated, 
    // ... and subscribeProvider variables start as undefined until a change in state.
    // componentDidUpdate(prevProps, prevState) {
    //     if (this.state.isConnectedChange !== prevState.isConnectedChange) {
    //       this.setState({ isConnected: this.state.isConnectedChange });
    //     }
    //     if (this.state.isConnectedChange !== prevState.isConnectedChange) {
    //       this.setState({ address: this.state.addressChange });
    //     }
    // }

    // Unsubscribes from Web3Modal changes when the component is unmounted
    // componentWillUnmount() {
    //     if (this.walletSubscription) {
    //         this.walletSubscription();
    //     }
    // }

    // Calls deleteUser cloud function (see functions folder/ file index.js for more context) to delete User docs from db.
    async deleteAccount() {
        this.setState({show: false});
        
        // First Reauthenticate user - then procced to deleting data & auth.
        await this.reAuthUser();
        // Then calls cloud function
        if (this.props.fbsAuth.currentUser) {
        const idToken = await this.props.fbsAuth.currentUser.getIdToken(/* forceRefresh */ true)
        const response = await fetch("https://deleteuser-cuw3q2hq5q-uc.a.run.app", {
            method: "post",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                // Custom auth header required to authorize call.
                "Authorization": `ID ${idToken}`,
            },

            // Serialize request body as JSON, as specified by Content-Type header.
            body: JSON.stringify({
                deletesNumbers: this.state.readonlyUserdata.userNumber,
                })
            })
            if (response.ok) {
                console.log("User Data Deleted", response.status)
                await deleteUser(this.props.fbsAuth.currentUser)
                .then(() => {
                window.alert("Account & Data Succesfuly Deleted - Rejoin the Indemni family anytime")
                })
            }
            else {
            console.log(`Error ${response.status}: ${response.statusText}`)// `response. status` and `response.statusText`, respectively.
            window.alert("Unable to delete account - Please try refreshing the page and/or logging out and then back in to try again. If the problem persists, reach out to us at contact@indemni.io")
            } 
        }
    }

    // Closes modal explained in handleShow
    handleClose() {
        this.setState({show: false});
    }
    
    // Closes Sidebar 
    handleSidebarClose() {
        this.setState({showSidebar: false});
    }
    
    // Closes Email / Password modal 
    handleAuthClose() {
        this.setState({ showAuthModal: false, error: null });
    }

    // Shows modal in onClick of Delete Account.
    // Requires use to type there username, which then triggers handleKey function check,
    // ... which allows them to click "Delete Account" Button, which calls deleteAccount function,
    // .. which proceeds to delete auth, docs, and account for user.
    handleShow() {
        this.setState({show: true});
    }
    
    // Opens Sidebar
    handleSidebarShow() {
        this.setState({showSidebar: true});
    }
    
    // On enter, check if they entered their username to delete account - See comment above handleShow for more context.
    handleKey(e) {
        if (e.code === "Enter") {
            e.preventDefault();
            if (this.state.deleteAccountCheck === this.state.readonlyUserdata.username)
                this.setState({ disabledButton: true })
        }
    }

    // Maybe a better way to do this lol (it was a pain), but we need it for various changes to settings that would require users
    // ... to log out and then re-log in. The only tough one was e-mail/password as it requires alot of steps.
    // Google and Email/Password works great now though - ToDo: Test Twitter
    // Fix Me (later - maybe): Currently, the Modal for comfirming password requires a user to exit the modal
    // ... to try again if they input the wrong password. Not a big deal and the error message tells them to do that.
    // Called onClick of various tasks that would require a reauth to take place e.g., deleteAccount & resetPassword.
    async reAuthUser() {   
        // Defines credential based on the users login credential
        let credential;
       
        // If the user signs in with email/password
        if (this.props.fbsAuth.currentUser.providerData[0].providerId === "password") {
            this.setState({ showAuthModal: true });
            const passwordConfirmationPromise = new Promise((resolve) => {
                this.passwordConfirmationPromise.resolve = resolve; 
            });
            await passwordConfirmationPromise;
            const result = EmailAuthProvider.credential(this.props.fbsAuth.currentUser.providerData[0].email, this.state.password);
            credential = result;
        }

        // If the user signs in with Google
        if (this.props.fbsAuth.currentUser.providerData[0].providerId === "google.com") {
            const result = await signInWithPopup(this.props.fbsAuth, this.props.fbsAuthGoogle);
            // Gets Google credentials
            credential = GoogleAuthProvider.credentialFromResult(result);
        }
        
        // if the user signs in with Twitter
        if (this.props.fbsAuth.currentUser.providerData[0].providerId === "twitter.com") {
            const result = await signInWithPopup(this.props.fbsAuth, this.props.fbsAuthTwitter);
            // Gets Twitter credentials
            credential = TwitterAuthProvider.credentialFromResult(result);
        }

        // Finally, reauthenticates User to allow for other actions to take place.
        await reauthenticateWithCredential(this.props.fbsAuth.currentUser, credential)
            .then(() => {
                console.log("User Reauthenticated!");
                this.setState({ showAuthModal: false, error: null })
            })
            .catch((error) => {
                // Display error to users re-auth'ing with email / password 
                console.error(`Reauthentication error: ${error.code} - ${error.message}`);
                this.setState({ error: "Incorrect Login Input. Please close the modal and try again - Or try resetting passowrd" }); 
            });
    }

    // Sends a password reset email to the user after re-authenticating - called onClick in Button "Reset"
    // ToDo & FIX ME: This will currently send a password reset alert message even if the user types in the wrong password,
    // ... which might be fine since they don't know it, but something to test and debg. Also, this "works" for Google & Twitter
    // ... providers, which isn't true, as you can't reset your password with them through this, so it throws an error - we may
    // ... want to only have this sent (else throw an alert) if they are using email/password.
    async resetPassowrd() {
        // First reAuthUser to verify & get new auth token
        await this.reAuthUser();
        await sendPasswordResetEmail(this.props.fbsAuth, this.props.fbsAuth.currentUser.providerData[0].email)
            .then(() => {
                console.log("Password Reset Email Sent!");
                // Displays Success alert for resseting password
                this.setState({showResetPasswordAlert: true})
            })
            .catch((error) => {
                console.error(`Reauthentication error: ${error.code} - ${error.message}`);
                window.alert("Something went wrong, try again or reach out to our help staff at contact@indemni.io")
            });
    }

    // Called onClick of "Clear Asset Cache" Button to delete the user's "Asset" subcollection,
    // ... so they can connect a new wallet/account and store those assets in the db instead.
    // ToDo: add some sort of pre-warning or check to tell users this deletes all their assets,
    // not really required since they can easily add them back, but prevents spamming and explains the process more.
    // ToDo (some day): Make it so users can connect another wallet/account to store all their assets if needed.
    // async clearAssetCache() {
    //     if (this.state.isConnected) {
    //         try {
    //             await setDoc(doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0", "Assets", "0"), 
    //                 { assets: []}, { merge: true});
    //                 console.log("Cleared Asset Cache!");
    //                 window.alert("Asset Cache Cleared! Proceed to disconnecting your wallet and then connect a new wallet or account of your choice.")
    //                 // ToDo: add a conditional after like this to remove button or signify next steps for user - this.setState({clearedAssetCache: true}).
    //                 // ToDo (cont.): add a timeout potenitally to prevent spamming.
    //         }
    //         catch (error) {
    //             console.error("Error updating asset categories in Firestore: ", error);
    //             window.alert("Unable to clear asset cache. Try disconnecting and then reconnecting your wallet, or logging out and then logging in again")
    //         }
    //     }   
    // }

    // Personal Message Email notifications
    // TODO: UPDATE!
    async updatePersonalMessageNotifications() {
        try {
            if (this.state.turnOnPersonalMessageNotifications) { 
                    await updateDoc(doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0"),
                    { personalMessageEmailNotificationsOff: false });
                    this.setState({personalMessageNotificationsOff: false, turnOnPersonalMessageNotifications: false})
            }
            if (this.state.turnOffPersonalMessageNotifications) {
                    await updateDoc(doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0"),
                    { personalMessageEmailNotificationsOff: true });
                    this.setState({personalMessageNotificationsOff: true, turnOffPersonalMessageNotifications: false})
            }
        }
        catch (error) {
            console.error("Error updating Friend Request Privacy in Firestore: ", error);
        } 
    }

    // Group Email notifications
    // TODO: UPDATE!
    async updateGroupNotifications() {
        try {
            if (this.state.turnOnGroupNotifications) { 
                    await updateDoc(doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0"),
                    { groupEmailNotificationsOff: false });
                    this.setState({groupNotificationsOff: false, turnOnGroupNotifications: false})
            }
            if (this.state.turnOffGroupNotifications) {
                    await updateDoc(doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0"),
                    { groupEmailNotificationsOff: true });
                    this.setState({groupNotificationsOff: true, turnOffGroupNotifications: false})
            }
        }
        catch (error) {
            console.error("Error updating Friend Request Privacy in Firestore: ", error);
        } 
    }
    
    // ToDo @Brian & @Rylan: SetDoc {merge:true} works well here, not sure if we would rather do updateDoc though?
    //  And should we set a timeout for like 30 seconds for something for these so people don't spanm db writes? Same for the rest of privacy functions below.
    // onClick of Buttons within "Receive Friend Requests" checks boolen set from click event and boolean from the docs referenced
    // ... to see if hidden: false (Friend Requests allowed) or hidden: true (opposite) - then updates db doc accordingly.
    // async privacyFriendRequests() {
    //     try {
    //         if (this.state.friendRequestsOff) { 
    //             if (!this.state.currentFriendPrivacy) {
    //                 await setDoc(doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0"), 
    //                 { receiveFriendRequests: true }, { merge: true });
    //                 console.log("Friends Requests Turned Off");
    //                 this.setState({currentFriendPrivacy: true, friendRequestsOff: false})
    //             }
    //         }
    //         if (this.state.friendRequestsOn) {
    //             if (this.state.currentFriendPrivacy) {
    //                 await setDoc(doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0"), 
    //                 { receiveFriendRequests: false }, { merge: true });
    //                 console.log("Friends Requests Turned On");
    //                 this.setState({currentFriendPrivacy: false, friendRequestsOn: false})
    //             }
    //         }
    //     }
    //     catch (error) {
    //         console.error("Error updating Friend Request Privacy in Firestore: ", error);
    //     } 
    // }
    
    // onClick of Buttons within "Receive Messages" checks boolen set from click event and boolean from the docs referenced
    // ... to see if hidden: false (Friend Requests allowed) or hidden: true (opposite) - then updates db doc accordingly.
    // async privacyMessages() {
    //     try {
    //         if (this.state.messagesFromAnyone) { 
    //             if (!this.state.currentAllMessagePrivacy) {
    //                 await setDoc(doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0"), 
    //                 { receiveMessages: true, receiveMessagesOnlyFromFriends: false }, { merge: true });
    //                 console.log("All Messages Allowed");
    //                 this.setState({currentAllMessagePrivacy: true, currentMessageFriendPrivacy: false, messagesFromAnyone: false})
    //             }
    //         }
    //         if (this.state.messagesFromFriends) {
    //             if (!this.state.currentMessageFriendPrivacy) {
    //                 await setDoc(doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0"), 
    //                 { receiveMessages: false , receiveMessagesOnlyFromFriends: true }, { merge: true });
    //                 console.log("Friend Messages Allowed");
    //                 this.setState({currentAllMessagePrivacy: false, currentMessageFriendPrivacy: true, messagesFromFriends: false})
    //             }
    //         }
    //         if (this.state.messagesNever) {
    //             await setDoc(doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0"), 
    //             { receiveMessages: false, receiveMessagesOnlyFromFriends: false }, { merge: true });
    //             console.log("No Messages Allowed");
    //             this.setState({currentAllMessagePrivacy: false, currentMessageFriendPrivacy: false, messagesNever: false})
    //         }
    //     }
    //     catch (error) {
    //         console.error("Error updating Messages Privacy in Firestore: ", error);
    //     } 
    // }
    
    // onClick of Buttons within "Profile View " checks boolen set from click event and the db hidden field boolean to see
    // ... if hidden: false (Profile Public) or hidden: true (Profile Private) - then calls cloud function to change hidden field
    // ToDo (optional): Not a big deal at all, so only address if nothing else to do, there is a bit of a delay when this function
    // ... gets called where visual state changes take 1-3 seconds sometimes to show. Perhaps add a timeout so user's don't keep 
    // ... pressing the button (not a bad idea in general for privacy settings to limit writes), or add a conditional loading 
    // ... render on the button(s) to show the function is happening during the delay.
    // async privacyProfile() {
    //     if (this.state.profilePublic) {
    //         if (!this.state.currentProfilePrivacy) {
    //             this.setState({ hidden: undefined, profilePublic: false });
    //             return; // Profile already set to private, do nothing
    //         }
    //     }
    //     if (this.state.profilePrivate) {
    //         if (this.state.currentProfilePrivacy) {
    //             this.setState({ hidden: undefined, profilePrivate: false });
    //             return; // Profile already set to public, do nothing
    //         }
    //     }
    //     // Cloud function setprofileHidden called to change hidden field in db for public read docs - see functions folder / index.js for more context.
    //     console.log("Hidden Boolean", this.state.hidden)
    //     const idToken = await this.props.fbsAuth.currentUser.getIdToken(/* forceRefresh */ true)
    //     const response = await fetch("https://setprofilehidden-cuw3q2hq5q-uc.a.run.app", {
    //         method: "post",
    //         headers: {
    //             "Accept": "application/json",
    //             "Content-Type": "application/json",
    //             // Custom auth header required to authorize call.
    //             "Authorization": `ID ${idToken}`,
    //         },
      
    //         // Serialize request body as JSON, as specified by Content-Type header.
    //         body: JSON.stringify({
    //             hidden: this.state.hidden
    //         })
    //     })
    //     // If succesful, update state accordingly
    //     if (response.ok) {
    //         console.log("Profile Privacy Changed")
    //         // Set state of profile privacy for render purposes of button choice selected.
    //         try {
    //             if (this.state.profilePublic) { 
    //                 this.setState({currentProfilePrivacy: false, profilePublic: false, hidden: undefined })
    //             }
    //             if (this.state.profilePrivate) { 
    //                 this.setState({currentProfilePrivacy: true, profilePrivate: false, hidden: undefined})
    //             }
    //         }
    //         catch (error) {
    //             console.error("Error updating Profile Privacy in Firestore: ", error);
    //         } 
    //     }
    //     else window.alert(`Error ${response.status}: ${response.statusText}`)// `response. status` and `response.statusText`, respectively.
    // }

    // ToDo (post-launch): Add ability to change E-mail, add logic to handle friend requests privacy (see HomeProfile for context).
    // ToDo (now): Ad INDE link when LP is finished, 
    // ... finish placeholder variables in Wallet & Privacy, make dynamic breakpoints for different screen sizes.
    // ToDo (optional): Change some of the variable names as they aren't all great lol, some styling, and add everything deconstructed from this.state or not.
    // This page is for handling all account and privacy settings for the user.
    // * Currently Friend Privacy settings are not implemented at all, just updated visually and on the back-end, but we have no logic to handle friend requests at all.
    render() {
        const {show, showAuthModal, showSidebar, disabledButton, deleteAccountCheck, showResetPasswordAlert } = this.state;
        return (
            <>  
                {/* Modal shown for Email / Password reAuth process */}
                <Modal show={showAuthModal} onHide={this.handleAuthClose}>
                    <Modal.Header closeButton>
                        <Modal.Title> Confirm Settings Change </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form  onSubmit={e => e.preventDefault()}>
                            <Form.Group className="mb-3" controlId="confirm-password-check-form">
                                <Form.Label>Type Your Login Password & Click "Confirm" to Proceed</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Enter Your Password to confirm..."
                                    autoFocus
                                    onChange={(e) => this.setState({ password: e.target.value })}
                                    value={this.state.password}
                                />
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                        <p className="text-danger p-3 text-center fw-bold">{this.state.error}</p> {/* Displays error message for wrong password */}
                        <Button 
                        className="game-details-information"
                        onClick={() => this.passwordConfirmationPromise.resolve()}>
                            Confirm
                        </Button>
                    <Modal.Footer>
                        <Button className="game-details-information" onClick={this.handleAuthClose}>
                            Changed My Mind
                        </Button>
                    </Modal.Footer>
                </Modal>

                <BasicNavbar 
                    user={this.state.user} userdata={{...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata}}
                    unreadCount={this.state.unreadCount}
                    universityName={(this.state.readonlyUserdata && this.state.readonlyUserdata.inTheUniveristyProgram) ? this.state.readonlyUserdata.universityName : "University"}
                />
                <div className="body-content page-fade-animation change-settings-body-content-small-screens" >
                    { this.state.user
                        ? (<div>
                                <div className="d-flex justify-content-between align-items-center w-100 mx-2 px-2">
                                    <h1>Settings</h1>
                                    <Button
                                    className="game-details-button" 
                                    onClick={this.logout}>
                                            Logout
                                    </Button>
                                </div>
                               <hr />
                               { this.state.readonlyUserdata
                                   ? (<>
                                        {/* Sidebar for displaying data and navigating the page */}
                                        <div className="d-flex w-100">
                                            <Button className="game-details-information me-2" onClick={this.handleSidebarShow} >
                                                Open Settings Menu
                                            </Button>
                                        </div>
                                        <Offcanvas 
                                        show={showSidebar} 
                                        onHide={this.handleSidebarClose} 
                                        scroll={true} backdrop={false}
                                        style={{"margin-top":"4em", "width":"320px"}}>
                                            <Offcanvas.Header style={{"border-bottom": "1px solid #ffffff57"}}>
                                                <Offcanvas.Title className="d-flex justify-content-between align-items-center w-100"> 
                                                <span style={{"font-size":"1.5em"}}> Settings </span> 
                                                <Button 
                                                className="mx-1 px-1 game-details-information"
                                                onClick={this.handleSidebarClose}> 
                                                    Close Menu
                                                </Button> 
                                            </Offcanvas.Title>
                                            </Offcanvas.Header>
                                            <Offcanvas.Body 
                                            className="d-flex flex-column align-items-center justify-content-center gap-5"
                                            style={{"margin-top":"2em","margin-bottom":"2em"}}>
                                                <Button className="game-details-information w-100">
                                                    <a style={{"color":"#f8f8f8"}} href="#account">
                                                        <span style={{"font-weight":"600", "font-size":"1.3em"}}> Account </span>
                                                    </a>
                                                </Button>
                                                {/* <Button className="game-details-information w-100">
                                                    <a style={{"color":"#f8f8f8"}} href="#wallet">
                                                        <span style={{"font-weight":"600", "font-size":"1.3em"}}> Wallet </span>
                                                    </a>
                                                </Button>
                                                <Button className="game-details-information w-100">
                                                    <a style={{"color":"#f8f8f8"}} href="#privacy">
                                                     <span style={{"font-weight":"600", "font-size":"1.3em"}}> Privacy </span>
                                                    </a>
                                                </Button> */}
                                                <Button 
                                                className="w-100" 
                                                onClick={this.logout}>
                                                    <span style={{"font-weight":"600", "font-size":"1.3em"}}> Logout </span>
                                                </Button>
                                                <Button className="game-details-information w-100" style={{"margin-top":"auto", "margin-bottom": "3em"}}>
                                                    <a style={{"color":"#f8f8f8"}} href="#delete-account">
                                                        <span style={{"font-weight":"600", "font-size":"1.3em"}}> Delete Account </span>
                                                    </a>
                                                </Button>
                                            </Offcanvas.Body>
                                        </Offcanvas>
                                         </>)
                                   : null
                                }
                                <Row
                                    className="fluid flex-grow-1 change-faq-settings-margins-small-screens increase-settings-size"
                                    style={{"margin-left":"7em", "margin-right":"7em"}}
                                >
                                    <Col
                                        className="col-2 fluid my-1 px-2 py-1 w-100 justify-content-center change-faq-settings-margins-small-screens" 
                                        style={{ "display": "flex", "flex-direction": "column", "margin-left":"6em", "margin-right":"1em" }}
                                    >
                                    <div
                                        className=" d-flex flex-column gap-4 p-1 change-faq-settings-margins-small-screens increase-settings-size"
                                        style={{"margin-right":"7em"}}
                                    >
                                        <div className=" d-flex flex-column px-3 py-1 w-100" id="account"> 
                                            <h2 style={{"font-weight":"700", "text-decoration":"underline", "margin-bottom":".5em"}}> Account </h2>
                                            <div
                                                className="change-settings-div-small-screens"
                                                style={{"margin-left":"2em", "font-weight":"600", "font-size":"1.3em"}}
                                            >
                                                <ul className="d-flex flex-column my-1 justify-content-between">
                                                    <li> 
                                                        <div className="d-flex flex-row justify-content-between align-items-center change-settings-justify-small-screens"> 
                                                            <span className="p-1"> Username: </span>
                                                            <span className="settings-list-items change-settings-list-items-small-screens">
                                                                {this.state.readonlyUserdata.username}
                                                            </span> 
                                                        </div>
                                                    </li>
                                                    <hr/>                          
                                                    <li>
                                                        <div className="d-flex flex-row justify-content-between align-items-center change-settings-justify-small-screens"> 
                                                            <span className="p-1 change-settings-width-pixel-small-screens"> Login Option{"(s)"} Registered: </span>
                                                            <div className="settings-list-items change-settings-list-items-small-screens">
                                                                {this.state.providerData.map((provider, index) => (
                                                                <span key={index}> {provider.providerId} </span> 
                                                                ))}
                                                            </div>
                                                        </div>                                     
                                                    </li>
                                                    <hr/>                          
                                                    <li> 
                                                        <div className="d-flex flex-row justify-content-between align-items-center change-settings-justify-small-screens"> 
                                                            <span className="p-1"> Primary Email:</span>
                                                            <span className="settings-list-items change-settings-list-items-small-screens">
                                                                {this.props.fbsAuth.currentUser.providerData[0].email}
                                                            </span> 
                                                        </div>
                                                    </li>
                                                    <hr/>                          
                                                    <li>
                                                        <div className="d-flex flex-row justify-content-between align-items-center change-settings-justify-small-screens"> 
                                                            {!showResetPasswordAlert ? (
                                                                <>
                                                                    <span className="p-1"> Password:  </span>
                                                                    <Button
                                                                        className="game-details-information fw-bold px-1 me-3 change-settings-width-percentage-small-screens change-settings-add-margin-left-small-screens"
                                                                        style={{"width":"15%", "border": "1px solid"}}
                                                                        onClick={this.resetPassowrd}
                                                                    >
                                                                        Reset
                                                                    </Button> 
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {/* Shows success alert message telling user what to do next to finish resetting password */}
                                                                    <Alert 
                                                                    variant="success" 
                                                                    style={{"width":"100%"}}
                                                                    onClose={() => this.setState({showResetPasswordAlert: false})} 
                                                                    dismissible>
                                                                        <Alert.Heading> Password Reset Email sent to {this.props.fbsAuth.currentUser.providerData[0].email} </Alert.Heading>
                                                                        <p>
                                                                            Head over to your email to finish resetting your password!
                                                                        </p>
                                                                        <hr />
                                                                        <p className="mb-0" style={{"font-style":"italic"}}>
                                                                            * Be sure to check your spam folder, or wait a minute, if you cant find the email.
                                                                        </p>
                                                                        </Alert>
                                                                </>
                                                            )}                                                          
                                                        </div>
                                                    </li>
                                                    <hr/>                          
                                                    <li> 
                                                        <div 
                                                            className="d-flex flex-row justify-content-between align-items-center change-settings-justify-small-screens"
                                                            style={{"width":"95%"}}
                                                        > 
                                                            <span className="p-1"> Profile Picture: </span>
                                                            <img
                                                                className="navuserchat visting-home-page-fade-animation"
                                                                src={this.state.pfp ? this.state.pfp : "/logo-color.png"}
                                                                style={{"width":"100px", "height":"100px", "border-radius":"0%"}}
                                                            />
                                                        </div>
                                                    </li>
                                                    <hr/>                                              
                                                </ul>
                                            </div>
                                        </div>
                                        {/* <div className=" d-flex flex-column p-3 w-100" id="wallet"> 
                                            <h2 style={{"font-weight":"700", "text-decoration":"underline", "margin-bottom":".5em"}}> Wallet </h2>
                                            <div
                                                className="change-settings-div-small-screens"
                                                style={{"margin-left":"2em", "font-weight":"600", "font-size":"1.3em"}}
                                            >
                                                <ul className="d-flex flex-column my-1 justify-content-between ">
                                                    <li> 
                                                        <div className="d-flex flex-row justify-content-between align-items-center change-settings-justify-small-screens"> 
                                                            <span className="p-1 remove-from-settings-small-screens"> Wallet Address: </span>
                                                            <span
                                                                className="settings-list-items change-settings-list-items-small-screens change-settings-address-small-screens"
                                                                style={{"color":"#417cedf0", "width":"35%", "overflow-wrap":"break-word", "text-wrap":"balance"}}
                                                            >
                                                                {this.state.address || this.state.addressChange
                                                                    ? this.state.address || this.state.adressChange
                                                                    : "No Wallet Detected"
                                                                }
                                                            </span> 
                                                        </div>
                                                    </li>
                                                    <hr/>                                              
                                                    <li> 
                                                        <div className="d-flex flex-row justify-content-between align-items-center change-settings-justify-small-screens"> 
                                                            <span className="p-1 remove-from-settings-small-screens"> Connect / Disconnect Wallet: </span>
                                                            <w3m-button
                                                                className="px-1 change-settings-add-margin-left-small-screens"
                                                                style={{"margin-right":"1em"}}
                                                            />
                                                        </div>
                                                    </li>
                                                    <hr/>                                              
                                                    <li> 
                                                        <div> 
                                                            <div  className="d-flex flex-row justify-content-between align-items-center change-settings-justify-small-screens">
                                                                <span className="p-1"> Change Wallets: </span>
                                                                { this.state.isConnected || this.state.isConnectedChange ? (
                                                                    <Button
                                                                        className="game-details-information fw-bold px-1 me-3 change-settings-width-percentage-small-screens"
                                                                        onClick={this.clearAssetCache}
                                                                        style={{"width":"15%", "border": "1px solid"}}
                                                                    >
                                                                        Clear Asset Cache
                                                                    </Button>
                                                                ) : (
                                                                    <span className="settings-list-items change-settings-list-items-small-screens">
                                                                        No Wallet Detected
                                                                    </span>
                                                                )}
                                                            </div>
                                                            <div 
                                                                className="d-flex flex-row justify-content-between align-items-center"
                                                                style={{"margin-right":"1em"}}
                                                            >
                                                                <ul 
                                                                className="my-1 py-1"
                                                                style={{"width":"100%"}}
                                                                >                         
                                                                    <li className="settings-clear-asset-cache"> 
                                                                        <div className="d-flex flex-row justify-content-between align-items-center change-settings-justify-small-screens">
                                                                            *If Changing Accounts or Wallets: Be sure to click on "Clear Asset Cache" first!
                                                                            <span
                                                                                className="px-1" 
                                                                                style={{"font-size":"2em","color": "#f8f8f8", "margin-right":"1.5em"}}>
                                                                                    &#8670;
                                                                            </span> 
                                                                        </div>                                                             
                                                                    </li>                          
                                                                    <li style={{"font-weight":"lighter", "font-size":".8em", "color":"#ff274dcf", "margin-bottom":".5em"}}> 
                                                                        Then - proceed to "Disconnect" your wallet, by clicking on the modal displaying your wallet,
                                                                        in the "Connect / Disconnect Wallet" section.                                                               
                                                                    </li>                          
                                                                    <li style={{"font-weight":"lighter", "font-size":".8em", "font-style":"italic"}}> 
                                                                        In future updates we will support the option of merging & displaying assets on your profile
                                                                        from multiple wallet addresses & accounts.                                                              
                                                                    </li>                          
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <hr/>                                              
                                                    <li> 
                                                        <div className="d-flex flex-row justify-content-between align-items-center change-settings-justify-small-screens"> 
                                                            <span className="p-1"> Get / View INDE: </span>
                                                            <Button
                                                                className="game-details-information fw-bold px-1 me-3 change-settings-width-percentage-small-screens"
                                                                style={{"width":"15%", "border": "1px solid"}}
                                                                href="https://app.uniswap.org/tokens/polygon/0xabcdb656ba1135eeab16d804039ba02918fc6700"
                                                                target="_blank"
                                                            >
                                                                Go To Token 
                                                            </Button>
                                                        </div>
                                                    </li>
                                                    <hr/>                                                                          
                                                </ul>
                                            </div> 
                                        </div> */}
                                        <div className=" d-flex flex-column p-3 w-100" id="privacy"> 
                                            <h2 style={{"font-weight":"700", "text-decoration":"underline", "margin-bottom":".5em"}}> Email Notifications </h2>
                                            <div
                                                className="change-settings-div-small-screens"
                                                style={{"margin-left":"2em", "font-weight":"600", "font-size":"1.3em"}}
                                            >
                                                <ul className="d-flex flex-column my-1 justify-content-between">
                                                    <li> 
                                                        <div className="d-flex flex-row justify-content-between align-items-center change-settings-justify-small-screens"> 
                                                            <span className="p-1"> Receive Emails Notifications For Personal Messaages: </span>
                                                            <ButtonGroup
                                                                className="change-settings-width-percentage-small-screens"
                                                                type="checkbox"
                                                                style={{"width":"40%"}}
                                                            >
                                                                <Button
                                                                className={`fw-bold px-1 ${this.state.personalMessageNotificationsOff ? 'game-details-information' : 'notifications-turned-on'}`}
                                                                style={{"width":"50%","border": "1px solid"}} 
                                                                id="friend-btn-1"
                                                                onClick={async () => { 
                                                                    await this.setState({turnOnPersonalMessageNotifications: true});
                                                                    await this.updatePersonalMessageNotifications()
                                                                }}
                                                                >
                                                                    On
                                                                </Button>
                                                                <Button
                                                                className={`fw-bold px-1 me-3" ${this.state.personalMessageNotificationsOff ? 'notifications-turned-off' : 'game-details-information'}`}
                                                                style={{"width":"50%", "border": "1px solid"}} 
                                                                id="friend-btn-2" 
                                                                onClick={async () => { 
                                                                    await this.setState({turnOffPersonalMessageNotifications: true});
                                                                    await this.updatePersonalMessageNotifications()
                                                                }}
                                                                >
                                                                    Off
                                                                </Button>
                                                            </ButtonGroup>
                                                        </div>
                                                    </li>
                                                    <hr/>                                              
                                                    <li> 
                                                        <div className="d-flex flex-row justify-content-between align-items-center change-settings-justify-small-screens"> 
                                                            <span className="p-1"> Receive Emails Notifications When Someone Joins Your Group: </span>
                                                            <ButtonGroup
                                                                className="change-settings-width-percentage-small-screens"
                                                                type="checkbox"
                                                                style={{"width":"40%"}}
                                                            >
                                                                <Button
                                                                className={`fw-bold px-1 ${this.state.groupNotificationsOff ? 'game-details-information' : 'notifications-turned-on'}`}
                                                                style={{"width":"50%","border": "1px solid"}} 
                                                                id="friend-btn-1"
                                                                onClick={async () => { 
                                                                    await this.setState({turnOnGroupNotifications: true});
                                                                    await this.updateGroupNotifications()
                                                                }}
                                                                >
                                                                    On
                                                                </Button>
                                                                <Button
                                                                className={`fw-bold px-1 me-3" ${this.state.groupNotificationsOff ? 'notifications-turned-off' : 'game-details-information'}`}
                                                                style={{"width":"50%", "border": "1px solid"}} 
                                                                id="friend-btn-2" 
                                                                onClick={async () => { 
                                                                    await this.setState({turnOffGroupNotifications: true});
                                                                    await this.updateGroupNotifications()
                                                                }}
                                                                >
                                                                    Off
                                                                </Button>
                                                            </ButtonGroup>
                                                        </div>
                                                    </li>                                           
                                                    {/* <li> 
                                                        <div className="d-flex flex-row justify-content-between align-items-center change-settings-justify-small-screens"> 
                                                            <span className="p-1"> Receive Messages:  </span>
                                                            <ButtonGroup
                                                                className="change-settings-width-percentage-small-screens"
                                                                style={{"width":"40%"}}
                                                            >
                                                                <Button
                                                                className={`fw-bold px-1 ${this.state.currentAllMessagePrivacy ? 'settings-active-privacy' : 'game-details-information'}`}
                                                                style={{"width":"32%", "border": "1px solid"}} 
                                                                id="message-btn-1"
                                                                onClick={async () => { 
                                                                    await this.setState({messagesFromAnyone: true});
                                                                    await this.privacyMessages()
                                                                }}
                                                                >
                                                                    From Anyone
                                                                </Button>
                                                                <Button 
                                                                className={`fw-bold px-1 ${!this.state.currentAllMessagePrivacy && this.state.currentMessageFriendPrivacy ? 'settings-active-privacy' : 'game-details-information'}`}
                                                                style={{"width":"36%", "border": "1px solid"}}
                                                                id="message-btn-2"
                                                                onClick={async () => { 
                                                                    await this.setState({messagesFromFriends: true});
                                                                    await this.privacyMessages()
                                                                }} 
                                                                >
                                                                    Just Friends
                                                                </Button>
                                                                <Button
                                                                className={`fw-bold px-1 me-3" ${!this.state.currentAllMessagePrivacy && !this.state.currentMessageFriendPrivacy ? 'settings-active-privacy' : 'game-details-information'}`}
                                                                style={{"width":"32%", "border": "1px solid"}} 
                                                                id="message-btn-3"
                                                                onClick={async () => { 
                                                                    await this.setState({messagesNever: true});
                                                                    await this.privacyMessages()
                                                                }} 
                                                                >
                                                                    Never
                                                                </Button>
                                                            </ButtonGroup>
                                                        </div>
                                                    </li>
                                                    <hr/>                                              
                                                    <li> 
                                                        <div className="d-flex flex-row justify-content-between align-items-center change-settings-justify-small-screens"> 
                                                            <span className="p-1"> Profile View: </span>
                                                            <ButtonGroup
                                                                className="change-settings-width-percentage-small-screens"
                                                                style={{"width":"40%"}}
                                                            >
                                                                <Button
                                                                className={`fw-bold px-1 ${this.state.currentProfilePrivacy ? 'game-details-information' : 'settings-active-privacy'}`}
                                                                style={{"width":"50%", "border": "1px solid"}} 
                                                                id="profile-btn-1"
                                                                onClick={async () => { 
                                                                    await this.setState({profilePublic: true, hidden: false});
                                                                    await this.privacyProfile()
                                                                }} 
                                                                >
                                                                    Public
                                                                </Button>
                                                                <Button
                                                                className={`fw-bold px-1 me-3" ${this.state.currentProfilePrivacy ? 'settings-active-privacy' : 'game-details-information'}`}
                                                                style={{"width":"50%", "border": "1px solid"}}
                                                                id="profile-btn-2"
                                                                onClick={async () => { 
                                                                    await this.setState({profilePrivate: true, hidden: true});
                                                                    await this.privacyProfile()
                                                                }} 
                                                                >
                                                                    Private
                                                                </Button>
                                                            </ButtonGroup>
                                                        </div>
                                                    </li>
                                                    <hr/>                                                                                                                      */}
                                                </ul>
                                            </div> 
                                        </div>
                                        <div className=" d-flex flex-column p-3 w-100" id="delete-account"> 
                                            <h2 style={{"font-weight":"700", "text-decoration":"underline", "margin-bottom":".5em"}}> Delete Account & Data </h2>
                                            <div
                                                className="change-settings-div-small-screens"
                                                style={{"margin-left":"2em", "font-weight":"600", "font-size":"1.3em"}}
                                            >
                                                <ul className="d-flex flex-column my-1 justify-content-between">
                                                    <li style={{"font-weight":"lighter", "font-size":".8em"}}> 
                                                        Click the button below to delete all of your data & Indemni Account
                                                    </li>                                                   
                                                </ul>
                                                <div className="d-flex justify-content-end my-4 p-2 me-3">
                                                    <Button
                                                        className="change-settings-width-percentage-small-screens"
                                                        variant="outline-secondary"
                                                        style={{"width":"15%"}} 
                                                        onClick={this.handleShow}
                                                    >
                                                        Delete Account
                                                    </Button>
                                                </div>
                                                    <Modal show={show} onHide={this.handleClose}>
                                                        <Modal.Header closeButton>
                                                            <Modal.Title>Delete Account</Modal.Title>
                                                        </Modal.Header>
                                                        <Modal.Body>
                                                            <Form  onSubmit={e => e.preventDefault()}>
                                                                <Form.Group className="mb-3" controlId="delete-account-check-form">
                                                                    <Form.Label>Type Your Username to Delete Account & Press Enter</Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        placeholder="Your Exact Username Here & Press Enter..."
                                                                        autoFocus
                                                                        onKeyDown={this.handleKey}
                                                                        onChange={(e) => this.setState({deleteAccountCheck: e.target.value})}
                                                                        value={deleteAccountCheck}
                                                                    />
                                                                </Form.Group>
                                                            </Form>
                                                        </Modal.Body>
                                                        {disabledButton && (
                                                            <Button className="game-details-information" onClick={this.deleteAccount}>
                                                                Delete Account
                                                            </Button>
                                                                )}
                                                        <Modal.Footer>
                                                            <Button className="game-details-information" onClick={this.handleClose}>
                                                                Changed My Mind
                                                            </Button>
                                                        </Modal.Footer>
                                                    </Modal>
                                            </div>
                                        </div>
                                    </div>
                                    </Col>
                               </Row>
                           </div>)
                        : <a style={{"color":"#f8f8f8"}} href="/"> Go Back to Login </a>
                    }
                     <Footerfixed></Footerfixed>
                </div>
            </>
        )
    }
}

export default SettingsPage;
