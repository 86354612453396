import React, { Component } from "react"
import { Button, Image, Col, Row, Container, ToggleButton, Pagination } from "react-bootstrap"
import { ShieldCheck } from "react-bootstrap-icons";

class UniversityMembers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: undefined,
            userdata: undefined,

            nameIncludeFilter: "", // Search input for searching for games.

            displayedGames: [],  // New display array for filtered and sliced this.state.games
            gamesPerPage: 10,    // Games displayed per page - can be altered.
            currentPage: 1,      // The current page as a refference check.
            games: [],
            liveData: [],
            liveGamers: undefined,

            messages: [], // Existing messages
            unreadCount: 0, // Tracks the unread message count

        }

        this.passesFilters = this.passesFilters.bind(this)
        this.displayGame = this.displayGame.bind(this)

        this.handlePageChange = this.handlePageChange.bind(this)
        this.handleSearchChange = this.handleSearchChange.bind(this)
        this.calculatePercentage = this.calculatePercentage.bind(this)
        this.percentageColor = this.percentageColor.bind(this)
    }

    // Return true if game matches the current filter(s) or nameIncludeFilter === ""
    passesFilters(student) {
        if (!student) return false;

        if (this.state.nameIncludeFilter === "") return true;
        if (this.state.nameIncludeFilter !== "" && student.username.toLowerCase().includes(this.state.nameIncludeFilter.toLowerCase()) ) return true;
    }

    // Changes the page
    handlePageChange(newPage) {
        this.setState({ currentPage: newPage });
    }

    // Checks for search input and updates page.
    handleSearchChange(e) {
        const searchValue = e.target.value;
        this.setState({ nameIncludeFilter: searchValue, currentPage: 1 });
    };


    // Display based on passesFilters/handlePageChange/handleSearchChange functions,
    // and Pagination logic set at top of main render before the return statement.
    // The parameter "game" is mapped in the render from this.state.games.
    // this.state.liveData is an array of live gamers/answerers.
    // These are set in componentDidMount, and we check the gameNumber and gameNumbers
    // ... fields to map the gamer/answerer live for their mastered games.
    displayGame(student) {
        if (this.passesFilters(student)) {
            return (
                    <>
                        <div className="me-2 px-2 explore-games-background ask-a-gamer-explore-margin-right-0-mobile" >
                            <div className="d-flex flex-column py-3 px-4 ask-a-gamer-game-list-border ask-a-gamer-explore-padding-left-right-halfem-mobile">
                                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                                        <div
                                            className="d-inline-flex flex-row align-items-center flex-wrap ask-a-gamer-explore-flex-column-mobile"
                                            style={{"width":"100%"}}
                                        >
                                            <div
                                                className="d-flex flex-column flex-grow ms-3 pt-3 ask-a-gamer-explore-margin-right-left-0-tablet ask-a-gamer-explore-width-100-tablet"
                                                style={{"flex-grow":"1"}}
                                            >
                                                <p
                                                    style={{"width":"100%"}}
                                                >
                                                    <div
                                                        style={{"width":"100%"}}
                                                        className="d-inline-flex flex-row flex-wrap"
                                                    >
                                                        <small
                                                            style={{"width":"100%"}}
                                                            className="ms-2 p-1 fs-5 mb-2 ask-a-gamer-explore-margin-right-left-0-tablet"
                                                        >
                                                            <div className="d-flex flex-row align-items-center justify-content-between px-2 mx-3 ask-a-gamer-explore-font-8-mobile ask-a-gamer-explore-margin-right-left-0-tablet mobile-display-flex-column">
                                                                <div className="d-flex flex-column align-items-start justify-content-center ask-a-gamer-explore-width-100-mobile mobile-align-items-center">
                                                                    <span
                                                                        style={{"color":"#8ebbff", "text-wrap":"balance"}}
                                                                    >
                                                                        {student.username}&nbsp;
                                                                         { student.universityAdmin
                                                                          ?
                                                                            <span style={{"color":"#ffffff", "font-size":".75em", "font-style":"italic"}}>
                                                                                (Club Leader) <ShieldCheck style={{"color":"white", "margin-left":".25em", "margin-bottom":".2em", "margin-right":".5em", "align-items":"center", "height":"15px", "width":"auto"}}/>
                                                                            </span>
                                                                          :
                                                                            <span style={{"color":"#ffffff", "font-size":".75em", "font-style":"italic"}}>
                                                                                (Student)
                                                                            </span>
                                                                        }
                                                                    </span>
                                                                    { student.realName && student.realName !== "" &&
                                                                        <span
                                                                            style={{"margin-top":".25em", "color":"#a5a5a5", "font-size":".7em", "font-weight":"700", "font-style":"italic", "text-wrap":"balance"}}
                                                                        >
                                                                            - {student.realName} -
                                                                        </span>
                                                                    }
                                                                    <span
                                                                        style={{"margin-top":".25em", "color":"#ffb865", "font-size":".7em", "text-wrap":"balance"}}
                                                                    >
                                                                        {student.universityName}
                                                                    </span>
                                                                </div>
                                                                <div className="d-flex flex-column align-items-center justify-content-center ask-a-gamer-explore-width-70-mobile mobile-margin-top-1">
                                                                    <Button
                                                                        href={`/visitaccount?gamer=${student.username}&universityName=${student.universityName}`}
                                                                        className="d-flex flex-column mx-2 p-2 fs-5 align-items-center change-padding-tablet font-size-75-tablet width-auto-tablet standard-action-button"
                                                                        style={{ "font-weight": "600", "width": "100px" }}
                                                                    >
                                                                        <span style={{ "width": "100%", "font-size":".75em" }}>Visit Profile</span>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </small>
                                                    </div>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                            </div> 
                        </div>
                        <hr className="ask-a-gamer-answer-spotlight-hr"/>
                    </>
            )
        } else return null;
    }

    // Used in render for displaying GG percentage, passed to percentageColor function below.
    calculatePercentage(GG, Total) {
        if (Total === 0) {
            return null; // If there are no votes, return null
        }
        // Calculates percentage and rounds to a whole number
        const percentage = (GG / Total) * 100;
        return Math.round(percentage);
    }
        // Return a CSS class that sets the color based on the GG percentage.
    percentageColor(GG, Total) {
        const percentage = this.calculatePercentage(GG, Total);
        if (percentage === null) {
            return '';
        } else if (percentage > 79) {
            return 'game-percentage-green';
        } else if (percentage >= 50) {
            return 'game-percentage-white';
        } else {
            return 'game-percentage-red';
        }
    }

    render() {
        
        //Pagination Logic - Can be moved outside render if we want.
        
        const startIndex = (this.state.currentPage - 1) * this.state.gamesPerPage;
        const endIndex = startIndex + this.state.gamesPerPage;
        // Sorts games alphabetically
        const sortedGames =  [...this.props.collab.allStudents].sort((a, b) => a.username.localeCompare(b.username));
        // Filters games based on search term.
        const filteredGames = sortedGames.filter((game) => this.passesFilters(game));
        // Displays games on the current page for pagination.
        const displayedGames = filteredGames.slice(startIndex, endIndex);
        // Calculates the total number of pages needed to display all the search results.
        const totalPages = Math.ceil(filteredGames.length / this.state.gamesPerPage);
        // Updates pagination.item (i.e. Total Page amount) to be dynamic based on search results.
        // *Update the second param, which is currently 5, to whatever when we have more games.
        // TODO: This works, but check logic to make sure it works under various circumstances.
        const pageNumbersToShow = Math.min(totalPages, 5);
        const middlePageNumber = Math.floor(pageNumbersToShow / 2);
        let firstPageNumber = Math.max(1, this.state.currentPage - middlePageNumber);
        const lastPageNumber = Math.min(totalPages, firstPageNumber + pageNumbersToShow - 1);
        // Adjusts firstPageNumber - it was disappearing when the last page didn't have 10 games for some reason.
        if (lastPageNumber === totalPages) {
          firstPageNumber = Math.max(1, lastPageNumber - pageNumbersToShow + 1);
        }

        //
        return (
            <>
                <div
                    className="body-content visting-home-page-fade-animation change-explore-body-content-small-screen explore-body-content-padding-mobile
                    explore-body-content-padding-large ask-a-gamer-explore-padding-left-right-1-mobile"
                >
                    <div className="mt-2" >
                        <div
                            className="my-2 pe-3 w-100 ask-a-gamer-explore-margin-padding-right-left-0-mobile"
                            style={{"height":"95%"}}
                        >
                            <div className="h-100 w-95 p-3 ask-a-gamer-explore-margin-padding-right-left-0-mobile">
                                {/* List of games */}
                                <div className="between-and-flex-important ask-indemni-reduce-font-size-mobile me-3 px-2 ask-a-gamer-explore-flex-column-page-input-mobile">
                                    <span
                                        className="mb-2 ask-a-gamer-explore-display-none-mobile"
                                        style={{"text-decoration":"underline", "font-size":"2em", "font-weight":"600", "padding-bottom":".3em"}}
                                    >
                                        All Members
                                    </span>
                                    {totalPages > 0 && (
                                        <div
                                            style={{"font-size":".85em"}}
                                            className="center-and-flex-important mb-2"
                                        >
                                            <span
                                                style={{"font-size":"1.7em", "font-weight":"600", "margin-bottom":".2em"}}
                                                className="me-2"
                                            >
                                                Page
                                            </span>
                                        <Pagination>
                                            {[...Array(lastPageNumber - firstPageNumber + 1).keys()].map((index) => {
                                                const pageNumber = firstPageNumber + index;
                                                return (
                                                    <Pagination.Item
                                                        key={pageNumber}
                                                        active={pageNumber === this.state.currentPage}
                                                        onClick={() => this.handlePageChange(pageNumber)}
                                                        linkStyle={{"color":"white", "background-color":"#312e2e", "width":"50px","font-size":"1.1em", "font-weight":"700", "text-align":"center"}}
                                                    >
                                                        {pageNumber}
                                                    </Pagination.Item>
                                                );
                                            })}
                                        </Pagination>
                                      </div>
                                    )}
                                <div
                                    style={{"width":"40%"}}
                                    className="mb-2 d-flex flex-row justify-content-between align-items-center ask-a-gamer-explore-width-auto-mobile"
                                >
                                    <div
                                        className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile"
                                        style={{"width":"100%"}}
                                    >
                                        <input
                                            type="search"
                                            className="ms-3 change-width-for-mobile ask-a-gamer-explore-input-mobile"
                                            style={{"vertical-align":"middle", "width":"100%", "outline":"none"}}
                                            value={this.state.nameIncludeFilter}
                                            onChange={this.handleSearchChange}
                                            placeholder="Search usernames..."
                                        />
                                        <Button
                                            className="ms-3 w-30 ask-a-gamer-explore-clear-button-mobile"
                                            onClick={() => this.setState({ nameIncludeFilter: "", currentPage: 1 })}
                                        >
                                            Clear
                                        </Button>
                                    </div>
                                </div>
                                </div>
                                <hr className="hr-ask-indemni-title-hr" />
                                { displayedGames.map((student) => (
                                    <div key={student.username} className="me-2 px-2 ask-a-gamer-explore-margin-padding-right-left-0-mobile">
                                      {this.displayGame(student)}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default UniversityMembers;