import React, { Component } from 'react';
import { Button, ButtonGroup, Col } from 'react-bootstrap';

class SocialPersonal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: ''
        };

        this.handleUserSearch = this.handleUserSearch.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
    }

    handleUserSearch() {
        this.props.onSearch(this.state.searchTerm);
    }

    handleClearSearch() {
        this.setState({ searchTerm: '' });
        this.props.onClearSearch();
    }

    render() {
        const { contacts, onContactSelect, friendsFilterSelected, onFilterChange, unreadCounts, allMessages } = this.props;
        const { searchTerm } = this.state;

        return (
            <Col
                className= 'home flex-column chat-inbox-width-max'
                style={{"margin-bottom":"3em", "height":"900px"}}
            >
                <div className= 'homecontainer style-for-mobile sidebar-medium-width remove-margin-left-medium'>
                    <div className="sidebarmessage sidebar-message-mobile-width">
                        <div className="navmessage px-2 pb-2">
                            <span style={{"padding-top": "1px", "padding-left":"5px"}}>
                                Filter Messages
                            </span>
                            <span style={{"padding-bottom": ".3em", "margin-left":".3em", "font-size":"2.5em", "font-weight":"100"}}> | </span>
                            <div className="navuser">
                                <ButtonGroup type="checkbox" style={{"width":"10em", "padding-left":"1em"}}>
                                    <Button
                                        className={`fw-bold px-1 ${!friendsFilterSelected ? 'settings-active-privacy' : 'game-details-information'}`}
                                        style={{"width":"50%","border": "1px solid", "font-size":".9em"}}
                                        id="friend-btn-1"
                                        onClick={() => onFilterChange(false)}
                                    >
                                        All
                                    </Button>
                                    <Button
                                        className={`fw-bold px-1 me-3" ${friendsFilterSelected ? 'settings-active-privacy' : 'game-details-information'}`}
                                        style={{"width":"50%", "border": "1px solid", "font-size":".9em"}}
                                        id="friend-btn-2"
                                        onClick={() => onFilterChange(true)}
                                    >
                                        Friends
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </div>
                        <div className="navmessage flex-column px-2 pb-2" style={{"height":"100px"}}>
                            <div className="d-flex flex-row justify-content-between align-items-center py-1" style={{"width":"100%"}}>
                                <span style={{"padding-top": "1px", "padding-left":"5px"}}>
                                    Find a User
                                </span>
                                <span style={{"font-style":"italic", "font-size":".8em","font-weight":"400", "margin-right":".75em"}}>
                                    {"(Based on current filter)"}
                                </span>
                            </div>
                            <div className="d-flex flex-row flex-grow-1 justify-content-between" style={{"width":"100%"}}>
                                <div className="navuser justify-content-between" style={{"width":"100%"}}>
                                    <div className="d-flex justify-content-between align-items-center" style={{"width":"100%"}}>
                                        <div>
                                            <input
                                                className="navsearchform p-2 sidebar-search-medium-width"
                                                style={{"width":"150px", "height":"35px"}}
                                                type="text"
                                                onChange={(e) => this.setState({ searchTerm: e.target.value })}
                                                value={searchTerm}
                                                placeholder="Type a Username..."
                                            />
                                        </div>
                                        <div>
                                            <Button className="ms-3 me-2 text-muted nav-search-users-button" onClick={this.handleUserSearch}>
                                                Search
                                            </Button>
                                            <Button className="ms-2 text-muted nav-search-users-button" onClick={this.handleClearSearch}>
                                                Reset
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{"height":"570px"}} className="chatsmessage asset-tab-scroll inbox-tab-scrollbar pe-1">
                            {contacts.map((contact, index) => (
                                <div
                                    className={`navsearchbutton ${unreadCounts && unreadCounts[contact] > 0 ? "chat-inbox-highlighted-contact" : ""}`}
                                    key={index}
                                    onClick={async () => {
                                        onContactSelect(contact);
                                        await this.props.markPersonalMessagesAsRead(contact);
                                        await this.props.moveUserToMessages();
                                    }
                                    }
                                >
                                    <img className="navuserchat" src="/logo-color.png" />
                                    <div className="navuserchatInfo d-flex flex-row flex-grow-1 justify-content-between">
                                        <span style={{"font-size": "16px", "font-weight": "500"}}>
                                            {contact}
                                        </span>
                                        {unreadCounts && unreadCounts[contact] > 0 && (
                                            <span className="pb-1 ps-2 me-3 chat-inbox-new-message">
                                                *{unreadCounts[contact]} new messages
                                            </span>
                                        )}
                                        {!allMessages.some((message) => message.fromName === contact || message.toName === contact) && (
                                            <span className="pb-1 ps-2 me-3 chat-inbox-new-message">
                                                no message history
                                            </span>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </Col>
        );
    }
}

export default SocialPersonal;
