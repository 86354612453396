import React, { Component } from "react";
import { Col, Button, Dropdown } from "react-bootstrap"
import { doc, getDoc, updateDoc, onSnapshot, arrayRemove, query, where, collection, arrayUnion } from "firebase/firestore";
import SocialGroupsChatInput from "./SocialGroupsChatInput";
import { PersonVcard, Trash3, EmojiHeartEyes, EmojiAngry } from "react-bootstrap-icons";
import { updateMessagesDateAndCount } from "../../utils";

class SocialGroupsChat extends Component {
    constructor(props) {
        super(props);

        this.state = {
            messageText: '', // the display of text for textarea
            uploadText: '', // the modified text that uploads line breaks to firebase correctly for answererSendMessage
            unreadCounts: {},
            unreadCount: 0, // Tracks the unread message count
            cooldown: false, // Conditional to set timeout of 1.5s to prevent spamming of messages and allow cloud function to finish.
            placeholderMessageInput: true,
            trustAsker: false,
            distrustAsker: false, // called onClick of dis/trusting an asker in the dropdown - onClick it is set to true and updates state/firebase in handleAskerTrust.

            ownedGroupChat: null,
            joinedGroupChats: [],
        };

        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.chatSendMessage = this.chatSendMessage.bind(this);
        this.formatDate = this.formatDate.bind(this);
        this.renderFormattedText = this.renderFormattedText.bind(this);
    };

    // Loads contacts and sets up listener for changes in messages in "Inbox"
    // doc to provide real-time updates.
    // async componentDidMount() {
    //     this.setUpChatListener();
    // }

    // Checks if the current asker in queue is new/different from the previous asker.
    // Scrolls chat to the bottom to display the most recent message.
    componentDidUpdate(prevProps, prevState) {
    // Check if queue has a user
    // const isNewQueueNotEmpty = prevProps.queue.length === 0 && this.props.queue.length > 0;

    // // Check if the first asker's name has changed and the queue is not empty.
    // const isFirstAskerNameChanged = prevProps.queue.length > 0 && this.props.queue.length > 0 && prevProps.queue[0].askerName !== this.props.queue[0].askerName;

    // // Play sound only if the queue has received its first entry or the first asker's name has changed,
    // // and ensure the current queue is not empty to avoid playing sound when queue transitions to empty.
    // if ((isNewQueueNotEmpty || isFirstAskerNameChanged) && this.props.queue.length > 0) {
    //     console.log("Queue updated");
    //     this.props.playAudio('/EnterQueueSound.mp3');
    // }

        this.scrollToBottom();
    }

    // Automatically scrolls the window to the bottom and most recent message.
    scrollToBottom() {
        const chatBox = document.getElementById('chat-box');
        if (chatBox) chatBox.scrollTop = chatBox.scrollHeight;
    };

    // Sends messages to the users GroupChat
    async chatSendMessage() {
        // Return early if user is spamming.
        if (this.state.cooldown) return;

        // Set the cooldown to true so the user's can't send another message.
        this.setState({ cooldown: true });

        try {
            const message = {
                from: this.props.userdata.userNumber,
                fromName: this.props.userdata.username,
                timeSent: new Date(),
                body: this.state.uploadText,
                read: false,
            }
            const chatDocRef = doc(this.props.fbsFirestore, "GroupChats", `${this.props.joinedGroupOneNumber}`)
            await updateDoc (chatDocRef, {
                messages: arrayUnion(message)
            });
            this.setState({ messageText: "", uploadText: "" })

            // Calls utils function to update messages sent counts and dates.
            try {
                await updateMessagesDateAndCount(this.props.fbsFirestore, this.props.userdata.userNumber)
                console.log("New Message Count/Date Completed!")
            } catch (error) {
                console.error("New Message Count/Date Failed!", error);
            }
        } catch (error) {console.log(error)};

        setTimeout(() => {
            this.setState({ cooldown: false });
        }, 1.5);
    };


    // Changes the timestamp.now to a real js object and transformed to an
    // actual number and date.
    formatDate(date) {
        const options = { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return date.toLocaleDateString('default', options);
    }

    // Looks for %0A URL encoded line breaks and links.
    // Handles reformatting text to wrap links in <a> tags, and
    // shows the user visual spaces with <br /> if they pressed enter or
    // shift+enter as represented by "\n".
    renderFormattedText = (text) => {
        const linkRegex = /(?:https?|ftp):\/\/[^\s]+(?:\s|$)/g;
        const lines = text.split(/(?:\n|%0A)/);
      
        const elements = [];
      
        lines.forEach((line, lineIndex) => {
            const linkMatches = line.match(linkRegex);
            if (linkMatches) {
                let lastIndex = 0;

                linkMatches.forEach((linkMatch, index) => {
                    const linkStart = line.indexOf(linkMatch, lastIndex);
                    const linkEnd = linkStart + linkMatch.length;

                    // Adds the preceding text as regular text
                    elements.push(<span key={`text-${lineIndex}-${index}`}>{line.substring(lastIndex, linkStart)}</span>);

                    // Wraps the link in an anchor
                    elements.push(
                        <a
                            key={`link-${lineIndex}-${index}`}
                            className="change-link-color-chat"
                            style={{
                                wordWrap: 'break-word',
                                wordBreak: 'break-all',
                                whiteSpace: 'normal',
                            }}
                            href={decodeURIComponent(linkMatch)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                        {decodeURIComponent(linkMatch)}
                        </a>
                    );

                    // Updates the lastIndex to the end of the current link
                    lastIndex = linkEnd;
                });
            
                // Adds the remaining text after the last link
                elements.push(<span key={`text-${lineIndex}-last`}>{line.substring(lastIndex)}</span>);
            } else {
                // If no links, add the entire line as regular text
                elements.push(<span key={`text-${lineIndex}`}>{line}</span>);
            }
          
            // Add the line break after each line (except the last line)
            if (lineIndex < lines.length - 1) {
                elements.push(<br key={`br-${lineIndex}`} />);
            }
        });
      
        return elements;
    };


    // Most all classNames for formatting can be found within _messagebox.scss.
    // TODO: Update this whole thing.
    render() {
        // const { messages, joinedGroupOneMessages } = this.props;
        return (
            <>
                <Col
                    className= 'home flex-column chat-inbox-width-max'
                    style={{"margin-bottom":"3em", "height":"900px"}}
                >
                    {/* <AskAGamerAnswerGoLive
                        fbsAuth={this.props.fbsAuth}
                        fbsFirestore={this.props.fbsFirestore}
                        user={this.props.user}
                        userdata={this.props.userdata}
                        currentQuestionTime={this.props.currentQuestionTime}
                        startQuestionTime={this.props.startQuestionTime}
                        resetQuestionTime={this.props.resetQuestionTime}
                        gamerIsLive={this.props.gamerIsLive}
                        selectRecipientUser={this.selectRecipientUser}
                        selectedRecipientUser={this.state.selectedRecipientUser}
                        queue={this.props.queue}
                        queueLength={this.props.queueLength}
                        updatedQueueLength={this.props.updatedQueueLength}
                        startChat={this.props.startChat}
                        endChat={this.props.endChat}
                        chatStarted={this.props.chatStarted}
                        resetSelectedRecipientUser={this.resetSelectedRecipientUser}
                        askerTrusted={this.state.askerTrusted}
                        askerDistrusted={this.state.askerNeutral}
                        askerNeutral={this.state.askerNeutral}
                        unsubscribeChatListener={this.unsubscribeChatListener}
                        answererData={this.props.answererData}
                        playAudio={this.props.playAudio}
                    /> */}
                    <div className= 'homecontainer style-for-mobile sidebar-medium-width remove-margin-left-medium'>
                        <div className="chatmessage">
                            <div className="chatinfo">
                            { !this.props.joinedGroupOne ?
                                (
                                    <div
                                        className="d-flex flex-row justify-content-between align-items-center"
                                        style={{"margin-left":"1em", "padding-top":"2px", "width":"100%"}}
                                    >
                                        <span>
                                            My LFG Group Chat
                                        </span>
                                        <Dropdown>
                                            <Dropdown.Toggle style={{"background-color": "#3735350d", "color":"#ed6058f5", "margin-right":"2.75em"}}>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu style={{"inset":"5px -5px auto auto", "font-size":".9em", "text-align":"center", "background":"#212121ed", "text-wrap":"balance", "max-width":"200px"}}>
                                                <Dropdown.Item
                                                    style={{"text-wrap":"balance"}}
                                                    href="https://discord.gg/9EZmV5qBY5"
                                                    target="_blank"
                                                >
                                                    <span style={{"color":"#8afeff", "font-weight":"550"}}> Indemni Discord <EmojiHeartEyes className="ms-2"/></span>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                )
                                : undefined
                                }
                                { this.props.joinedGroupOne ?
                                    <div className="chaticons">
                                        <span style={{"font-size":"1.2em", "font-weight":"bolder","padding-right":"5px", "padding-top":"2px", "text-decoration":"underline", "margin-left":"1.5em"}}>
                                            My LFG Group Chat
                                        </span>
                                    </div>
                                  : undefined
                                }
                                { this.props.joinedGroupOne ?
                                  (
                                      <div
                                        style={{"margin-right":"-1em"}}
                                        className="chaticons"
                                      >
                                            { this.props.joinedGroupOneMessages
                                              ? this.props.joinedGroupOneMessages&& this.props.joinedGroupOneMessages.map((message, index) => (
                                                <>
                                                    { index === 0 && (
                                                        <Dropdown>
                                                            <Dropdown.Toggle style={{"background-color": "#3735350d", "color":"#ed6058f5", "margin-right":"2.75em"}}>
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu style={{"inset":"5px -5px auto auto", "font-size":".9em", "text-align":"center", "background":"#212121ed", "text-wrap":"balance", "max-width":"200px"}}>
                                                                <Dropdown.Item
                                                                    style={{"text-wrap":"balance"}}
                                                                    href="https://discord.gg/9EZmV5qBY5"
                                                                    target="_blank"
                                                                >
                                                                    <span style={{"color":"#8afeff", "font-weight":"550"}}> Indemni Discord <EmojiHeartEyes className="ms-2"/></span>
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    )}
                                                </>)
                                              )
                                            :
                                                <Dropdown>
                                                    <Dropdown.Toggle style={{"background-color": "#3735350d", "color":"#ed6058f5", "margin-right":"2.75em"}}>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu style={{"inset":"5px -5px auto auto", "font-size":".9em", "text-align":"center", "background":"#212121ed", "text-wrap":"balance", "max-width":"200px"}}>
                                                        <Dropdown.Item
                                                            style={{"text-wrap":"balance"}}
                                                            href="https://discord.gg/9EZmV5qBY5"
                                                            target="_blank"
                                                        >
                                                            <span style={{"color":"#8afeff", "font-weight":"550"}}> Indemni Discord <EmojiHeartEyes className="ms-2"/></span>
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            }
                                      </div>
                                  )
                                  : undefined
                                }
                            </div>
                            {/* This is where messages or chats are displayed */}
                            <div className="messageboxchat" id="chat-box" >
                                { this.props.joinedGroupOne
                                  ? (
                                        <>
                                            { this.props.joinedGroupOneMessages
                                              ? this.props.joinedGroupOneMessages && this.props.joinedGroupOneMessages.length !== 0 && this.props.joinedGroupOneMessages.map((message, index) => (
                                                <div className={`message chat-inbox-fade-animation ${message.from === this.props.userdata.userNumber ? "ownerchat align-items-center" : "align-items-center"}`} >
                                                    <div key={index} className="message1 messages-info-width-mobile" >
                                                        <span
                                                            className={`shorten-chat-usernames ${message.from === this.props.userdata.userNumber ? "currentUsernameChatColor " : "otherUsernameChatColor"}`}
                                                            style={{"font-size":"1em", "padding-bottom":"2px", "font-weight":"500", "text-align":"center"}}
                                                        >
                                                            { message.from === this.props.userdata.userNumber
                                                              ? "Me"
                                                              : message.fromName
                                                            }
                                                        </span>
                                                        <img
                                                            className="navuserchat"
                                                            src={message.from === this.props.userdata.userNumber ?  "/ProfilePicturePlaceholder.png" : "/logo-color.png"}
                                                        />
                                                        <span style={{"font-size":"10px", "padding-top":"2px", "color":"#b5b2b2", "font-weight":"400", "text-align":"center"}} >
                                                            { message.timeSent && message.timeSent.toDate()
                                                              ? this.formatDate(message.timeSent.toDate())
                                                              : undefined
                                                            }
                                                        </span>
                                                    </div>
                                                    <div style={{"padding-top":"5px"}}key={index} className="message2" >
                                                        <p
                                                            // TODO: check to see if this breaks things, currently without this,
                                                            // a long word or link can utterly break the div render. Maybe there is a
                                                            // a better way to wrap long words.
                                                            style={{"overflow-wrap":"break-word"}}
                                                            className="message-width-mobile max-width-chat-messages-tablet max-width-chat-messages-laptop"
                                                        >
                                                            {this.renderFormattedText(message.body)}
                                                        </p>
                                                    </div>
                                                </div>))
                                              :
                                                <div 
                                                    className="d-flex justify-content-center align-items-center chat-inbox-fade-animation"
                                                    style={{"font-size":"1.5em", "font-weight":"600"}}
                                                >
                                                    { !this.state.cooldown &&
                                                        <span 
                                                            className="text-center"
                                                            style={{"margin-top":"5em"}}
                                                        >
                                                            Send a message to start the conversation.
                                                        </span>
                                                    }
                                                </div>
                                            }
                                            { this.state.cooldown
                                              ? <div className="message ownerchat" >
                                                    <div className="message1" >
                                                        <img className="navuserchat" src="/ProfilePicturePlaceholder.png"/>
                                                        <span style={{"font-size":"10px", "padding-top":"2px"}} >
                                                            Now
                                                        </span>
                                                    </div>
                                                    <div
                                                        style={{"padding-top":"5px"}}
                                                        className="message2"
                                                    >
                                                        <p>
                                                            <div className="circle-inline-loading-animation " />
                                                        </p>
                                                    </div>
                                                </div>
                                              : null
                                            }
                                        </>
                                    )
                                  : <>
                                        <div 
                                            className="d-flex flex-column justify-content-center align-items-center chat-inbox-fade-animation chat-alt-div-size-mobile margin-left-2half-mobile"
                                            style={{"font-size":"1.3em", "margin-left":"1.5em", "font-weight":"600"}}
                                        >
                                            { this.props.couldNotFindGroup
                                              ?
                                                <span 
                                                    className="text-center px-3"
                                                    style={{"margin-top":"4em", "margin-bottom":"2em"}}
                                                >
                                                    Sorry, we could not locate your group or their group chat!
                                                </span>
                                              :
                                                <span 
                                                    className="text-center"
                                                    style={{"margin-top":"4em", "margin-left":"1.5em", "margin-bottom":"2em"}}
                                                >
                                                    You aren't currently looking for a group! Go post for one in your Gamer Profile, and you will be able to talk to your teammates here!
                                                    <br />
                                                    <br />
                                                    * After posting - deleting or changing your post will reset this group chats messages and remove all group members.
                                                </span>
                                            }
                                        </div>
                                  </>
                                }
                            </div>
                            {/*This is the input bar for typing and sending messages to users */}
                            { this.props.joinedGroupOne && !this.props.couldNotFindGroup
                              ? <SocialGroupsChatInput
                                    messageText={this.state.messageText}
                                    uploadText={this.state.uploadText}
                                    onMessageTextChange={(inputText, modifiedText) => this.setState({ messageText: inputText, uploadText: modifiedText })}
                                    onSendMessage={() => this.chatSendMessage()}
                                    cooldown={this.state.cooldown}
                                />
                              : <SocialGroupsChatInput
                                    messageText={this.state.messageText}
                                    uploadText={this.state.uploadText}
                                    onMessageTextChange={(inputText, modifiedText) => this.setState({ messageText: inputText, uploadText: modifiedText })}
                                    onSendMessage={() => this.chatSendMessage()}
                                    placeholderMessageInput={this.state.placeholderMessageInput}
                                />
                            }
                        </div>
                    </div>
                </Col>
            </>
        )
    }

}

export default SocialGroupsChat;
