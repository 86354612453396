// I started to make the functional version of Gamer Profile here, but soon realized
// it wasn't necessary for the demo, so this is old code, but useful for seeing how we 
// created old functions, accessed the DB, altered state, etc.
// I will be updating this page to be GamerProfilePage and import UI and logic from the
// the Demo in "../components/Demo" to start building everything once HomePage and
// GamePage are completed.

import React, { Component } from "react"
import { onAuthStateChanged } from "firebase/auth";
import {
    logout,
    markMessageAsRead,
    setUpMessageListener,
    setUser,
    updateSignInDate,
    updatePostDateAndCount
} from "../utils.js"
import {
    doc,
    getDoc,
    query,
    where,
    collectionGroup,
    getDocs,
    updateDoc,
    onSnapshot,
    arrayUnion,
    deleteField,
    runTransaction
} from "firebase/firestore"
import { Pencil, ShieldCheck } from "react-bootstrap-icons";
import Footerfixed from "../components/Footer/Footer"
import { Button, Container, Row, Col, Stack, Image, Dropdown } from "react-bootstrap"
import BasicNavbar from "../components/Navbar/Navbar"
import AccountTabs from "../components/Account/AccountTabs";
import { ref, getDownloadURL } from "firebase/storage"
import createProfilePicture from "../components/ProfilePictures/CreateProfilePicture.js";

class AccountPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: undefined,
            userdata: undefined,
            messages: [], // Existing messages
            unreadCount: 0, // Tracks the unread message count
            generatedPfp: "", // Checks if the user is new and hasn't generated their PFP yet - conditional for render
            fadeOutAnimation: false, // set in handleTimeDelayOfPfp during onboarding new users.
            gamerIsLive: false, // if true, the gamer is live and can answer questions.
            queue: [], // set in setUpQueueListener in onAuthStateChanged- is an array of currently queued askers.
            queueLength: undefined, // set in setUpQueueListener when "Go Live" button is clicked - is a number which is the current length of the queue array.
            chatStarted: false, // set in AskAGamerAnswerChat when clicking "Start Chat" or "End Chat"
            cooldown: false, // Conditional to set timeout of 1.5s to prevent spamming of messages and allow cloud function to finish.
            games: [], // the array of games set in componentDidMount.
            notUser: false,

            pfp: "",

            // TODO: these should be moved to VisitAccountPage logic and removed from here later.
            gameName: "", // gameName is fetched and set from the url in componentDidMount if a user clicked on an LFG Post, else its an empty string
            visitingFromLFG: true, // most users will visit profiles from LFG posts, so this is set to true by default
            vistiingFromSocialTab: false, // if a user is visiting a profile from their Social Tab, this is set to true for conditional render purposes.

            isEditingClanStatus: false, // if true the user is editing their sidebar
            editingModeEnabled: false, // if true the user sees all edit buttons.
            isEditingRealName: false, // Only available to University students for now.

            statusList: ["New User", "True Believer", "Gamer"],

            editingStatus: "",
            editingRealName: "",
            clanUploadText: "",
            clanDisplayText: "",
            editingClanText: "",

            onboardingNotFinsihed: undefined,

            isNewDay: false,
        }

        this.logout = this.logout.bind(this)
        this.setUser = this.setUser.bind(this)

        this.setUpMessageListener = this.setUpMessageListener.bind(this);
        this.markMessageAsRead = this.markMessageAsRead.bind(this);

        this.greetNewUser = this.greetNewUser.bind(this);
        this.completeAccountOnboarding = this.completeAccountOnboarding.bind(this);

        this.calculatePercentage = this.calculatePercentage.bind(this);
        this.percentageColor = this.percentageColor.bind(this);
        this.playAudio = this.playAudio.bind(this);
        this.updateClanAndStatus = this.updateClanAndStatus.bind(this);
        this.updateRealName = this.updateRealName.bind(this);

        this.turnOnEditingMode = this.turnOnEditingMode.bind(this);
        this.turnOffEditingMode = this.turnOffEditingMode.bind(this);

        this.renderFormattedText = this.renderFormattedText.bind(this);

        this.checkNewDayAndSetDate = this.checkNewDayAndSetDate.bind(this);
        this.updatePostCounts = this.updatePostCounts.bind(this);

        onAuthStateChanged(this.props.fbsAuth, async user => {
            if (user) {
                //console.log(`User ${user.uid} logging in (email: ${user.email})`)
                await this.setUser(user)
                    .then(async () => {
                        await this.setUpQueueListener();

                        // One-time call to send them a message.
                        // newUser field deleted after so it will always return falsy.
                        if (this.state.writableUserdata.newUser) {
                            await this.greetNewUser();
                        }

                        // One-time call, or atleast until the user clicks "Got it - Thanks!"
                        // This will call completeAccountOnboarding(), which will add the field
                        // accountOnboardingCompleted: true.
                        // accountOnboardingCompleted does not exist as a field by default, so it will
                        // return false until the user clicks that button to add the field.
                        if (!this.state.writableUserdata.accountOnboardingCompleted) {
                            this.setState({ onboardingNotFinsihed: true })
                        }

                        // This is a function check used in multiple components to see if today (based on their user's timezone)
                        // is a new day (aka after Midnight), for when the user last logged in (or was on Indemni).
                        // We pass in user data and check the field timestamp lastSignInDate and perform state and doc updates accordingly.
                        // As of right now, this will be called on most pages for a better user experience. We are also using it to
                        // determine counts of activity (daily, monthly, and total) to be uploaded to firebase and tracked in Mixedpanel.
                        // Overtime this will be much much more robost and better...
                        // The function calls the utilis function updateSignInDate() to handle doc updates if its a new date.
                        // TODO: In the future, when we want to make sure this can never be manipulated, we should be checking and updating,
                        // the Readonly collection and using a cloud function for this.
                        await this.checkNewDayAndSetDate(this.state.writableUserdata);

                        // Gets User prp from firebase storage if it exists
                        if (this.state.writableUserdata.pfp !== "") {
                            // Create a reference from a Google Cloud Storage URI
                            const gsReference = ref(this.props.fbsStorage, `gs://justtesting-c24bc.appspot.com/pfpOne/${this.state.readonlyUserdata.userNumber}.png`);
                            // `url` is the download URL for 'images/stars.jpg'
                            const url = await getDownloadURL(gsReference)
                            if (url) {
                                this.setState({ pfp: url })
                            }
                        }

                        // One time call to set create the users profile picture
                        if (this.state.writableUserdata.pfp === "") {
                            await createProfilePicture(this.state.readonlyUserdata, this.props.fbsFirestore, this.props.fbsStorage);
                            await updateDoc(doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0"), { pfp: `gs://justtesting-c24bc.appspot.com/pfpOne/${this.state.readonlyUserdata.userNumber.toString(16)}.png` });
                        }
                    })

                // Inbox Snapshot
                await this.setUpMessageListener();
            }
            else {
                this.logout();
                this.setState({ notUser: true })
            }
        })
    }

    // We first fetch the games data from the "AskAGamer" collection.
    async componentDidMount() {
        // Use URLSearchParams to get the game "gameName" parameter from the URL.
        // The states set are for conditional renders based on if the visiting user
        // ... is coming from an LFG post or their Social Tab.
        const searchParameters = new URLSearchParams(window.location.search);
        const gameName = searchParameters.get("gameName");
        // Determines state basesd on if gameName is present which will only be the
        // the case if the user clicked on a profile in the LFG posts in GamePage.
        if (gameName) {
            this.setState({
                postGameName: gameName,
                visitingFromLFG: true,
                vistiingFromSocialTab: false,
            })
        }
        else this.setState({
            postGameName: "",
            visitingFromLFG: false,
            vistiingFromSocialTab: true,
        })
    }

    componentWillUnmount() {
        if (this.unsubscribeMessageListener) {
            this.unsubscribeMessageListener()
            this.unsubscribeMessageListener = undefined
        };
        if (this.unsubscribeQueueListener) {
            this.unsubscribeQueueListener()
            this.unsubscribeQueueListener = undefined
        };
    }

    setUser = setUser;
    logout = logout;

    markMessageAsRead = markMessageAsRead;
    setUpMessageListener = setUpMessageListener;


    // Called in onAuthStateChanged(), to determine if it's a new day.
    // It calls the utils function below and updates sign in dates/counts for days and months.
    // We are currently utilzing timestamps and having new day's based on the
    // the user's timezone, so that a "reset" of a new day is midnight for everyone.
    // We may update this overtime to just be based on a specific UTC timezone.
    // TODO: Handle State updates after
    async checkNewDayAndSetDate(writableData) {
        // If it's a new day for the user, call the utils function to update the fields
        await updateSignInDate(this.props.fbsFirestore, this.state.readonlyUserdata.userNumber);
    }

    // Calls a utils function to update daily and total Post counts and dates.
    // Called as props from AccountProfile.js
    async updatePostCounts() {
        try {
            await updatePostDateAndCount(this.props.fbsFirestore, this.state.readonlyUserdata.userNumber);
            console.log("New Post Count/Date Completed!")
        } catch (error) {
            console.error("New Post Count/Date Failed!", error);
        }
    }

    async greetNewUser() {
        const newUserDoc = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
        // Deletes db field newUser from "PublicReadonlyPrivateWritable" collection since onboarding process is completed.
        await updateDoc(newUserDoc, { newUser: deleteField() });

        // Sends newUser a message from Indemni (Indemni userNumber is always -1) - Welcoming them to the platform.
        const newUserInboxDoc = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0");
        const message = {
            from: 1,
            to: this.state.readonlyUserdata.userNumber,
            fromName: "MrIndemni",
            toName: this.state.readonlyUserdata.username,
            timeSent: new Date(),
            body: `
            Hey ${this.state.readonlyUserdata.username}!
            
            Let me be the first to officially welcome you to Indemni! I am extremely grateful that you decided to join the family here for early access. Make sure to set up your Gamer Profile first thing, and then go find some teammates and check out all other features and tools.
            
            As this is early access, we have some minor bugs, a lot of polishing to do, and an insane of things left to build. I appreciate you being here for the journey, your understanding as we continue to build and improve, and your feedback.
            
            Let me know if you need anything at all. Head over to the FAQ page (or Discord) if your lost. Looking forward to seeing you in our Discord or in game in the future.

            Sincerely, *cough* (your overlord) MrIndemni.`,

            read: false,
        }
        await updateDoc(newUserInboxDoc, { messages: arrayUnion(message) });
        await updateDoc(newUserInboxDoc, { contacts: arrayUnion(message.fromName) });
    }

    // Passed as props and called onClick of "Got it - Thanks" in AccountProfile.
    // Updates user doc with a onboarding completed field
    // See context in onAuthStateChanged.
    async completeAccountOnboarding() {
        const userDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
        await updateDoc(userDocRef, { accountOnboardingCompleted: true });
        this.setState({ onboardingNotFinsihed: false })
    }

    setUpQueueListener() {
        const userDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
        const unsubscribe = onSnapshot(userDocRef, (doc) => {
            if (doc.exists()) {
                const profile = doc.data();
                this.setState({
                    profile,
                    clanDisplayText: profile.clan.replace(/%0A/g, '\n'),
                    editingStatus: profile.status,
                    editingRealName: profile.realName ? profile.realName : "",
                    totalMonthlyPoints: (profile.activeDays ? profile.activeDays : 0) + (profile.daysPosted ? profile.daysPosted : 0) + (profile.daysSentMessages ? profile.daysSentMessages : 0)
                })

                // // TODO: Make sure on final testing this works under must conditions.
                // const checkAndToggleLiveStatus = async () => {
                //     if (!this.state.gamerIsLive && answererData.live) {
                //         this.setState({ gamerIsLive: true})
                //         window.alert("We detected you did not properly log off from being Live by clicking Go Offline in your last session. Please be sure to do so next time as otherwise you will remain online and users will queue to chat with you. If this happens often, we will reach out to discuss this with you. Do note that in the future we will provide more flexibility on this. Disregard this message if it was just a quick internet outage, or a brief amount of time since last going Live. Reach out if you need more clarity on this. Thanks!")
                //         // TODO: Check if we still need this doc update. currentAskerName is used quite often
                //         // ... as a logic check on the asker/user side and in /AskAGamerAnswerGoLive. So, it
                //         // ... may make sense to clear this, but it will check the asker out of chat if we do.
                //         // Honestly, we just need to check if not setting this to an empty string causes bugs.
                //         // if (answererData.currentAskerName !== "") {
                //         //     await updateDoc(queueDocRef, {
                //         //         currentAskerName: "",
                //         //     });
                //         // }
                //     }
                // }
                // checkAndToggleLiveStatus().catch(console.error);

                // console.log("Queue Data:", queue, queueLength);
            }
        });
        this.unsubscribeQueueListener = unsubscribe;
    }

    // Used in render for displaying GG percentage, passed to percentageColor function below.
    calculatePercentage(GG, Noob) {
        if (GG + Noob === 0) {
            return null; // If there are no votes, return null
        }
        // Calculates percentage and rounds to a whole number
        const percentage = (GG / (GG + Noob)) * 100;
        return Math.round(percentage);
    }

    // Return a CSS class that sets the color based on the GG percentage.
    percentageColor(GG, Noob) {
        const percentage = this.calculatePercentage(GG, Noob);
        if (percentage === null) {
            return '';
        } else if (percentage > 79) {
            return 'game-percentage-green';
        } else if (percentage >= 50) {
            return 'game-percentage-white';
        } else {
            return 'game-percentage-red';
        }
    }

    // Called on click of "Update Clan & Stats" - updates the user doc clan and status fields with the user edits.
    async updateClanAndStatus() {
        // Check if the edit states changed from the original user doc data and update the fields if so.
        if (this.state.editingClanText !== "" && this.state.clanUploadText !== "") {
            const userDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
            await updateDoc(userDocRef, { clan: this.state.clanUploadText });
            console.log("Status Updated", this.state.clanUploadText)
        }
        if (this.state.editingStatus !== "") {
            const userDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
            await updateDoc(userDocRef, { status: this.state.editingStatus });
            console.log("Status Updated", this.state.editingStatus)
        }
    }

    // Called on click of "Update Real name" - updates the user doc realName field with user edits and the user's info in their University Program Doc.
    // Only available to students in the University Program for now.
    // TODO: Update for Dynamic universityDocRef and to handle universities vs. Collabs.
    async updateRealName() {
        const userDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
        const universityDocRef = doc(this.props.fbsFirestore, "UniversityProgram", "Notre Dame Video Game Club");

        try {
            // Read the university document first
            const universityDocSnapshot = await getDoc(universityDocRef);

            if (universityDocSnapshot.exists()) {
                const universityData = universityDocSnapshot.data();
                const allStudents = universityData.allStudents || [];

                // Find the index of the object in allStudents array
                const studentIndex = allStudents.findIndex(student => student.userNumber === this.state.readonlyUserdata.userNumber);

                if (studentIndex !== -1) {
                    // Update the realName field in the found object
                    allStudents[studentIndex] = {
                        ...allStudents[studentIndex],
                        realName: this.state.editingRealName
                    };

                    await runTransaction(this.props.fbsFirestore, async (transaction) => {
                        // Update user document
                        transaction.update(userDocRef, { realName: this.state.editingRealName });
                        console.log("Real Name Updated", this.state.editingRealName);

                        // Update the university document with the modified allStudents array
                        transaction.update(universityDocRef, { allStudents: allStudents });
                        console.log("University Program Student Real Name Updated");
                    });
                } else {
                    console.log("Student not found in University Program");
                }
            } else {
                console.log("University Program document not found");
            }
        } catch (error) {
            console.error("Transaction failed: ", error);
        }
    }

    async turnOnEditingMode() {
        this.setState({ editingModeEnabled: true })
    }

    async turnOffEditingMode() {
        this.setState({
            editingModeEnabled: false,
            isEditingClanStatus: false,
            isEditingRealName: false,

        })
    }

    // Called onCLick of certain actions that plays an mp3 file from the 'Public' folder.
    // Currently set to 30% volume for all files, but can be adjusted to be higher/per file accordingly.
    // Passed down as props to children components as well.
    // TODO (eventually - not a priority): Add a "Adjust Sound Effect Volume" option in Settings,
    // so that users can set audio.volume globally however they'd like.
    playAudio(source) {
        const audio = new Audio(source);
        audio.volume = 0.3;
        audio.play();
    };

    // Looks for %0A URL encoded line breaks and links.
    // Handles reformatting text to wrap links in <a> tags, and
    // shows the user visual spaces with <br /> if they pressed enter or
    // shift+enter as represented by "\n".
    renderFormattedText = (text) => {
        const linkRegex = /(?:https?|ftp):\/\/[^\s]+(?:\s|$)/g;
        const lines = text.split(/(?:\n|%0A)/);

        const elements = [];

        lines.forEach((line, lineIndex) => {
            const linkMatches = line.match(linkRegex);
            if (linkMatches) {
                let lastIndex = 0;

                linkMatches.forEach((linkMatch, index) => {
                    const linkStart = line.indexOf(linkMatch, lastIndex);
                    const linkEnd = linkStart + linkMatch.length;

                    // Adds the preceding text as regular text
                    elements.push(<span key={`text-${lineIndex}-${index}`}>{line.substring(lastIndex, linkStart)}</span>);

                    // Wraps the link in an anchor
                    elements.push(
                        <a
                            key={`link-${lineIndex}-${index}`}
                            className="change-link-color-chat"
                            style={{
                                wordWrap: 'break-word',
                                wordBreak: 'break-all',
                                whiteSpace: 'normal',
                            }}
                            href={decodeURIComponent(linkMatch)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {decodeURIComponent(linkMatch)}
                        </a>
                    );

                    // Updates the lastIndex to the end of the current link
                    lastIndex = linkEnd;
                });

                // Adds the remaining text after the last link
                elements.push(<span key={`text-${lineIndex}-last`}>{line.substring(lastIndex)}</span>);
            } else {
                // If no links, add the entire line as regular text
                elements.push(<span key={`text-${lineIndex}`}>{line}</span>);
            }

            // Add the line break after each line (except the last line)
            if (lineIndex < lines.length - 1) {
                elements.push(<br key={`br-${lineIndex}`} />);
            }
        });

        return elements;
    };

    render() {
        const out = [];

        // Navbar
        out.push(
            <BasicNavbar
                user={this.state.user}
                userdata={{ ...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata }}
                unreadCount={this.state.unreadCount}
                universityName={(this.state.readonlyUserdata && this.state.readonlyUserdata.inTheUniveristyProgram) ? this.state.readonlyUserdata.universityName : "University"}
            />
        )

        if (this.state.notUser && !this.state.readonlyUserdata && !this.state.profile)
            out.push(
                <div
                    className="vh-100 fluid d-flex flex-row flex-grow-1 justify-content-center align-items-center glow visting-home-page-fade-animation"
                    style={{ "overflow-y": "hidden" }}
                >
                    <h2
                        className="d-flex flex-column align-items-center justify-content-center"
                        style={{ "font-weight": "700", "font-size": "1.5em", "font-style": "italic", "text-align": "center", "margin-bottom": "7em" }}
                    >
                        <span
                            className="desktop-increase-demo-gamer-profile-text-size"
                            style={{ "margin-bottom": "2em" }}
                        >
                            You need to create an account to access your Gamer Profile. Create one for free in seconds below.
                        </span>
                        <span
                            className="d-flex flex-row align-items-center justify-content-center"
                            style={{ "width": "100%", "font-size": ".9em" }}
                        >
                            <Button
                                className="d-flex flex-column mx-2 p-2 fs-5 align-items-center change-padding-tablet font-size-75-tablet width-auto-tablet"
                                style={{ "background-color": "#1d77ff", "font-weight": "600", "width": "245px" }}
                                href="/"
                            >
                                Sign Up
                            </Button>
                        </span>
                    </h2>
                </div>
            );

        // Conditional when loading the user's profile to make sure userdata is set.
        if (!this.state.readonlyUserdata && !this.state.profile && !this.state.notUser)
            out.push(
                <div
                    className="vh-100 fluid d-flex flex-row flex-grow-1 justify-content-center align-items-center glow visting-home-page-fade-animation"
                    style={{ "overflow-y": "hidden" }}
                >
                    <h2
                        className="d-flex flex-column align-items-center justify-content-center"
                        style={{ "font-weight": "700", "font-size": "2em", "font-style": "italic", "text-align": "center", "margin-bottom": "7em" }}
                    >
                        <span
                            className="desktop-increase-demo-gamer-profile-text-size"
                            style={{ "margin-bottom": "2em" }}
                        >
                            Loading Profile
                        </span>
                        <div className="big-circle-inline-loading-animation-demo big-circle-inline-loading-animation-demo-desktop" />
                    </h2>
                </div>
            );

        if (this.state.readonlyUserdata && this.state.profile)
            // Standard Page Body Render
            out.push(
                <>
                    <div className="fluid overflow-hidden home-parent-container-mobile">
                        <>
                            <Container
                                className={this.state.fadeOutAnimation ? "d-flex flex-column fluid m-1 p-5 page-fade-out-animation" : "d-flex flex-column fluid m-1 p-5 page-fade-animation remove-padding-medium-container remove-padding-mobile padding-1ish-tablet"}
                                style={{ "padding-bottom": "1em", "padding-top": "1em", "overflow-x": "hidden" }}
                            >
                                <Row className="pl-1 justify-content-center fluid home-assets-margin-left-mobile home-parent-container-center-mobile">
                                    <Col
                                        className="d-flex fluid flex-column align-items-center shadow col-3 fs-5 fw-bold pb-4 sidebar-width-large home-sidebar-row-mobile"
                                        style={{ "height": "1100px" }}
                                    >
                                        <Stack
                                            gap={3}
                                            className="d-flex fluid flex-column align-items-center"
                                            style={{ "justify-content": "flex-start" }}
                                        >
                                            <div className="p-2 mt-2">
                                                <Image
                                                    className="shadow home-sidebar-image-mobile page-fade-animation"
                                                    src={this.state.pfp === "" ? "/ProfilePicturePlaceholder.png" : this.state.pfp}
                                                    alt="Profile Picture"
                                                    rounded
                                                    fluid
                                                />
                                            </div>
                                            {!this.state.isEditingClanStatus
                                                ?
                                                // Standard Sidebar Dispaly
                                                <div className="d-flex flex-column align-items-center font-size-75-tablet">
                                                    <div
                                                        style={{ "color": "#f3df92", "font-size": "1.1em" }}
                                                        className="px-2 pb-4"
                                                    >
                                                        {this.state.readonlyUserdata.username}
                                                    </div>
                                                    {this.state.editingModeEnabled && this.state.readonlyUserdata.inTheUniveristyProgram && !this.state.isEditingRealName &&
                                                        <div className="d-flex flex-column align-item-center justify-content-center mt-3 mb-3">
                                                            <Button
                                                                style={{ "font-size": ".9em", "font-weight": "600", "margin-top": "-1.5em", "margin-bottom": "1em" }}
                                                                onClick={() => this.setState({ isEditingRealName: true })}
                                                                className="account-profile-edit"
                                                            >
                                                                Add/Display Real Name <Pencil className={"edit-pencil"} />
                                                                <br />
                                                                (Only Shown to Other Students)
                                                            </Button>
                                                        </div>
                                                    }
                                                    {this.state.editingModeEnabled && this.state.readonlyUserdata.inTheUniveristyProgram && this.state.isEditingRealName &&
                                                        <div
                                                            style={{ "flex-wrap": "wrap", "margin-bottom": ".75em", "font-size": ".9em", "margin-top": "-.75em" }}
                                                            className="d-flex flex-row align-items-center justify-content-center"
                                                        >
                                                            <span style={{ "font-size": "1.5em", "text-align": "center", "width": "100%" }}>
                                                                Editing
                                                            </span>
                                                            <span style={{ "font-size": "1.5em", "text-decoration": "underline", "text-align": "center", "width": "100%" }}>
                                                                Real Name
                                                            </span>
                                                            <span
                                                                className="my-2"
                                                                style={{ "font-size": ".75em", "font-weight": "500", "font-style": "italic", "text-align": "center", "width": "100%" }}
                                                            >
                                                                (If left blank, your name will not be displayed)
                                                            </span>
                                                            <Button
                                                                style={{ "font-size": ".9em", "font-weight": "600" }}
                                                                onClick={() => this.setState({
                                                                    isEditingRealName: false,
                                                                    editingRealName: this.state.profile.realName,
                                                                })}
                                                                className="account-profile-cancel"
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </div>
                                                    }
                                                    {this.state.editingModeEnabled && this.state.readonlyUserdata.inTheUniveristyProgram && this.state.isEditingRealName &&
                                                        <div className="d-flex flex-column align-item-center justify-content-center mt-3">
                                                            <p
                                                                className="mt-1"
                                                                style={{ "width": "100%" }}
                                                            >
                                                                <h5 style={{ "font-size": "1.3em", "margin-bottom": ".75em", "text-decoration": "underline", "color": "#97ff7d", "width": "100%", "text-align": "center" }}>
                                                                    Update Real Name
                                                                </h5>
                                                                <div
                                                                    style={{ "width": "330px", "font-size": ".9em" }}
                                                                    className="d-flex flex-row flex-wrap justify-content-between overflow-auto"
                                                                >
                                                                    {/* TODO: Clan character length set to 60 - maybe to long or to short. Check Later. */}
                                                                    <input type="text" className="form-control"
                                                                        value={this.state.editingRealName}
                                                                        onChange={(x) => this.setState({ editingRealName: x.target.value })}
                                                                        placeholder="Your name is only shown to other students"
                                                                    />
                                                                </div>
                                                            </p>
                                                            <div
                                                                style={{ "width": "100%" }}
                                                                className="d-flex flex-row align-items-center justify-content-center font-size-75-tablet"
                                                            >
                                                                <Button
                                                                    className="d-flex flex-column mx-1 p-2 mt-4 mb-3 align-items-center chat-with-gamer-button game-details-play"
                                                                    style={{ "box-shadow": "#fffzfff82 0px 0px 10px 3px", "font-size": "1.2em", "font-weight": "600", "text-shadow": "black 0px 2px 2px", "text-wrap": "balance" }}
                                                                    onClick={async () => {
                                                                        this.playAudio('/UpvoteSound.mp3');
                                                                        await this.updateRealName();
                                                                        this.setState({
                                                                            isEditingRealName: false,
                                                                            cooldown: true,
                                                                        });
                                                                    }}
                                                                >
                                                                    Update Real Name
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.readonlyUserdata.inTheUniveristyProgram && this.state.profile.realName && this.state.profile.realName !== "" && !this.state.isEditingRealName &&
                                                        <div
                                                            style={{ "color": "#ffffff", "font-size": ".85em", "font-style": "italic", "font-weight": "500", "margin-top": "-1.25em", "margin-bottom": "1em" }}
                                                            className="px-2 pb-2"
                                                        >
                                                            ({this.state.profile.realName})
                                                        </div>
                                                    }
                                                    {this.state.readonlyUserdata.inTheUniveristyProgram && !this.state.isEditingRealName &&
                                                        <div
                                                            style={{ "color": "#ffb865", "font-size": ".9em", "text-wrap": "balance", "margin-top": "-.5em", "margin-left": ".85em" }}
                                                            className="px-2 py-1"
                                                        >
                                                            {this.state.readonlyUserdata.universityName} <ShieldCheck style={{ "color": "white", "margin-left": ".25em", "margin-bottom": ".2em", "margin-right": ".5em", "align-items": "center", "height": "15px", "width": "auto" }} />
                                                        </div>
                                                    }
                                                    {!this.state.isEditingRealName &&
                                                        <div
                                                            style={{ "font-size": ".9em", "text-shadow": "0 -1px 2px #2c2626", "color": "#c3c2c2" }}
                                                            className="px-2"
                                                        >
                                                            <span>User - # </span>
                                                            <span style={{ "font-weight": "800" }}>
                                                                {this.state.readonlyUserdata.userNumber}
                                                            </span>
                                                        </div>
                                                    }
                                                    <hr
                                                        className="ask-a-gamer-answer-spotlight-hr"
                                                        style={{ "width": "100%" }}
                                                    />
                                                    {this.state.editingModeEnabled && !this.state.isEditingRealName &&
                                                        <div className="new-home-profile-flex-between mt-3">
                                                            <Button
                                                                style={{ "font-size": ".9em", "font-weight": "600" }}
                                                                onClick={() => this.setState({ isEditingClanStatus: true })}
                                                                className="account-profile-edit"
                                                            >
                                                                Edit Clan & Status <Pencil className={"edit-pencil"} />
                                                            </Button>
                                                        </div>
                                                    }
                                                    {!this.state.editingModeEnabled &&
                                                        <>
                                                            <div
                                                                style={{ "font-size": ".85em" }}
                                                                className="p-2 indemni-monthly-points-display glow"
                                                            >
                                                                {/* TODO: Status stuff! */}
                                                                <span>Activity Rewards Coming Soon</span>
                                                                {/* <span>Indemni Points Earned (June): </span> */}
                                                                {/* <span style={{"font-style":"oblique"}}>
                                                            {this.state.totalMonthlyPoints ? this.state.totalMonthlyPoints : 0}
                                                        </span> */}
                                                            </div>
                                                            <hr
                                                                className="ask-a-gamer-answer-spotlight-hr"
                                                                style={{ "width": "100%" }}
                                                            />
                                                        </>
                                                    }
                                                    {!this.state.isEditingRealName &&
                                                        <>
                                                            <div
                                                                style={{ "font-size": ".9em" }}
                                                                className="p-2"
                                                            >
                                                                {/* TODO: Status stuff! */}
                                                                <span>Status: </span>
                                                                {!this.state.profile.status
                                                                    ?
                                                                    <span style={{ "font-style": "oblique" }}>
                                                                        Not Set
                                                                    </span>
                                                                    :
                                                                    <span className="animate-ask-a-gamer-status">
                                                                        {this.state.profile.status}
                                                                    </span>
                                                                }
                                                            </div>
                                                            {/* TODO: Clan stuff! Same as Status stuff above. */}
                                                            <div
                                                                className="pb-3"
                                                                style={{ "font-size": ".9em" }}
                                                            >
                                                                <span>Clan: </span>
                                                                {!this.state.profile.clan
                                                                    ?
                                                                    <span style={{ "font-style": "oblique" }}>
                                                                        Not set
                                                                    </span>
                                                                    :
                                                                    <span style={{ "font-style": "oblique" }}>
                                                                        {this.renderFormattedText(this.state.profile.clan)}
                                                                    </span>
                                                                }
                                                            </div>
                                                            <div className="d-flex flex-column align-items-center justify-content-between p-2 gg-noob-button-column-tablet account-page-sidebar-ggNoob-new-style">
                                                                <div className="d-flex remove-margin-left-tablet">
                                                                    <p
                                                                        style={{ "color": "#5ded5ce8", "margin": "0", "font-size": ".9em" }}
                                                                        className="mx-2 pb-2 text-center"
                                                                    >
                                                                        GG's: {this.state.writableUserdata.ggTotal}
                                                                    </p>
                                                                    <p
                                                                        style={{ "color": "#ed5c5ce8", "margin": "0", "font-size": ".9em" }}
                                                                        className="mx-2 text-center"
                                                                    >
                                                                        Noob's: {this.state.writableUserdata.noobTotal}
                                                                    </p>
                                                                </div>
                                                                <div
                                                                    style={{ "margin-top": "-.25em" }}
                                                                    className="d-flex remove-margin-left-tablet"
                                                                >
                                                                    <p
                                                                        style={{ "color": "#d9d7d7e8", "margin": "0", "font-size": ".65em" }}
                                                                        className="ms-2 me-2 text-start"
                                                                    >
                                                                        GG %
                                                                        {this.state.writableUserdata.ggTotal && this.state.writableUserdata.ggTotal > 0
                                                                            ?
                                                                            <span className={`${this.percentageColor(this.state.writableUserdata.ggTotal, this.state.writableUserdata.noobTotal)}`}>
                                                                                &nbsp;({this.calculatePercentage(this.state.writableUserdata.ggTotal, this.state.writableUserdata.noobTotal)})%
                                                                            </span>
                                                                            : null
                                                                        }
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div
                                                                style={{ "font-size": ".9em" }}
                                                                className="d-flex flex-row align-items-center justify-content-between mt-5"
                                                            >
                                                                <div
                                                                    style={{ "font-size": ".8em" }}
                                                                    className="d-flex flex-row align-items-center justify-content-between"
                                                                >
                                                                    <Button
                                                                        className="d-flex flex-column mx-2 p-2 align-items-center change-padding-tablet font-size-75-tablet width-auto-tablet standard-action-button"
                                                                        style={{ "width": "145px", "text-wrap": "balance", "font-weight": "550", "font-size": "1.2em" }}
                                                                        href="/gamerlounge#my-group"
                                                                    >
                                                                        My Group
                                                                    </Button>
                                                                </div>
                                                                <div
                                                                    style={{ "font-size": ".8em", "margin-left": ".5em" }}
                                                                    className="d-flex flex-row align-items-center justify-content-center font-size-75-tablet"
                                                                >
                                                                    <Button
                                                                        className="d-flex flex-column mx-2 p-2 align-items-center change-padding-tablet font-size-75-tablet width-auto-tablet standard-action-button"
                                                                        style={{ "width": "145px", "text-wrap": "balance", "font-weight": "550", "font-size": "1.2em" }}
                                                                        href="/gamerlounge#joined-groups"
                                                                    >
                                                                        Joined Groups
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                :
                                                // Editing Clan & Status
                                                <div className="d-flex flex-column align-items-center font-size-75-tablet">
                                                    <div
                                                        style={{ "color": "#f3df92", "font-size": "1.1em" }}
                                                        className="px-2 pb-4"
                                                    >
                                                        {this.state.readonlyUserdata.username}
                                                    </div>
                                                    <div
                                                        style={{ "font-size": ".9em", "text-shadow": "0 -1px 2px #2c2626", "color": "#c3c2c2" }}
                                                        className="px-2"
                                                    >
                                                        <span>User - # </span>
                                                        <span style={{ "font-weight": "800" }}>
                                                            {this.state.readonlyUserdata.userNumber}
                                                        </span>
                                                    </div>
                                                    {this.state.editingModeEnabled &&
                                                        <div
                                                            style={{ "flex-wrap": "wrap", "margin-bottom": ".75em", "font-size": ".9em" }}
                                                            className="d-flex flex-row align-items-center justify-content-center mt-3"
                                                        >
                                                            <span style={{ "font-size": "1.5em", "text-align": "center", "width": "100%" }}>
                                                                Editing
                                                            </span>
                                                            <span style={{ "font-size": "1.5em", "text-decoration": "underline", "text-align": "center", "width": "100%" }}>
                                                                Clan & Status
                                                            </span>
                                                            <Button
                                                                style={{ "font-size": ".9em", "font-weight": "600" }}
                                                                onClick={() => this.setState({
                                                                    isEditingClanStatus: false,
                                                                    editingClanText: this.state.profile.clan,
                                                                    editingStatus: this.state.profile.status
                                                                })}
                                                                className="account-profile-cancel"
                                                            >
                                                                Cancel
                                                            </Button>
                                                        </div>
                                                    }
                                                    <div
                                                        style={{ "font-size": ".9em" }}
                                                        className="p-2"
                                                    >
                                                        <div className="desktop-display-flex-column">
                                                            <h5 style={{ "font-size": "1.3em", "margin-top": ".75em", "margin-bottom": "1em", "text-decoration": "underline", "color": "#97ff7d", "text-align": "center" }}>
                                                                Select Status
                                                            </h5>
                                                            <Dropdown
                                                                data-toggle="tooltip"
                                                                data-placement="top"
                                                                title="You can select the time you wish start playing a game in the dropdown menu."
                                                                style={{ "margin-top": ".5em", "margin-bottom": "1em" }}
                                                            >
                                                                <Dropdown.Toggle className="ms-3 w-30 account-profile-lfg-dropdown-button-mobile">
                                                                    {this.state.editingStatus === "" ? "Status" : this.state.editingStatus}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu style={{ "font-size": "1em", "text-align": "center", "min-width": "150px", "background": "#212121ed" }}>
                                                                    {this.state.statusList.map((status) => (
                                                                        <Dropdown.Item
                                                                            key={status}
                                                                            eventKey={status}
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    editingStatus: status,
                                                                                });
                                                                            }}
                                                                        >
                                                                            {status}
                                                                        </Dropdown.Item>
                                                                    ))}
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="pb-3 d-flex"
                                                        style={{ "font-size": ".9em" }}
                                                    >
                                                        <p
                                                            className="mt-1"
                                                            style={{ "width": "100%" }}
                                                        >
                                                            <h5 style={{ "font-size": "1.3em", "margin-bottom": ".75em", "text-decoration": "underline", "color": "#97ff7d", "width": "100%", "text-align": "center" }}>
                                                                Update Clan
                                                            </h5>
                                                            <div
                                                                style={{ "width": "300px", "font-size": ".9em" }}
                                                                className="d-flex flex-row flex-wrap justify-content-between overflow-auto"
                                                            >
                                                                {/* TODO: Clan character length set to 60 - maybe to long or to short. Check Later. */}
                                                                <textarea
                                                                    value={this.state.clanDisplayText}
                                                                    maxLength={60}
                                                                    placeholder={"Update Your Clan Name"}
                                                                    style={{ "font-size": "1.2em", "color": "#000000", "border-radius": "10px", "height": "50px", "width": "100%" }}
                                                                    className="desktop-increase-demo-gamer-profile-text-size desktop-increase-height-70"
                                                                    onChange={(e) => {
                                                                        const clanDisplayText = e.target.value;
                                                                        const modifiedText = clanDisplayText.replace(/\n/g, '%0A'); // Uses %0A for URL-encoded newline
                                                                        this.setState({
                                                                            clanUploadText: modifiedText,
                                                                            editingClanText: modifiedText,
                                                                            clanDisplayText,
                                                                        });
                                                                    }}

                                                                    onKeyDown={(e) => {
                                                                        if (e.key === 'Enter' || e.shiftKey) {
                                                                            const modifiedText = `${this.state.clanUploadText}%0A`;
                                                                            this.setState({
                                                                                clanUploadText: modifiedText,
                                                                                editingClanText: modifiedText,
                                                                            });
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </p>
                                                    </div>
                                                    <div
                                                        style={{ "width": "100%" }}
                                                        className="d-flex flex-row align-items-center justify-content-center font-size-75-tablet"
                                                    >
                                                        <Button
                                                            className="d-flex flex-column mx-1 p-2 mt-4 align-items-center chat-with-gamer-button game-details-play"
                                                            style={{ "box-shadow": "#fffzfff82 0px 0px 10px 3px", "font-size": "1.2em", "font-weight": "600", "text-shadow": "black 0px 2px 2px", "text-wrap": "balance" }}
                                                            onClick={async () => {
                                                                this.playAudio('/UpvoteSound.mp3');
                                                                await this.updateClanAndStatus();
                                                                this.setState({
                                                                    isEditingClanStatus: false,
                                                                    cooldown: true,
                                                                });
                                                            }}
                                                        >
                                                            Update Clan & Status
                                                        </Button>
                                                    </div>
                                                </div>
                                            }
                                        </Stack>
                                    </Col>
                                    <Col
                                        className="d-flex fluid flex-column col-9 fs-6 fw-bold mb-2 pb-4 pe-3 home-content-width-mobile"
                                        style={{ "padding-left": "2em", "max-width": "1300px" }}
                                    >
                                        {this.state.profile
                                            ?
                                            <AccountTabs
                                                fbsAuth={this.props.fbsAuth}
                                                fbsFirestore={this.props.fbsFirestore}
                                                fbsStorage={this.props.fbsStorage}
                                                user={this.state.user}
                                                username={this.state.username}
                                                userdata={{ ...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata }}
                                                startChat={this.startChat}
                                                endChat={this.endChat}
                                                playAudio={this.playAudio}
                                                chatStarted={this.state.chatStarted}
                                                gamerIsLive={this.state.gamerIsLive}
                                                queue={this.state.queue}
                                                queueLength={this.state.queueLength}
                                                updatedQueueLength={this.state.updatedQueueLength}
                                                answererData={this.state.answererData}
                                                messages={this.state.messages}
                                                unreadCount={this.state.unreadCount}
                                                handleTimeDelayOfPfp={this.handleTimeDelayOfPfp}
                                                profile={this.state.profile}
                                                editingModeEnabled={this.state.editingModeEnabled}
                                                turnOnEditingMode={this.turnOnEditingMode}
                                                turnOffEditingMode={this.turnOffEditingMode}
                                                completeAccountOnboarding={this.completeAccountOnboarding}
                                                onboardingNotFinsihed={this.state.onboardingNotFinsihed}
                                                updatePostCounts={this.updatePostCounts}
                                                isNewDay={this.state.isNewDay}
                                                totalMonthlyPoints={this.state.totalMonthlyPoints}
                                            />
                                            : null
                                        }
                                    </Col>
                                </Row>
                                <Footerfixed></Footerfixed>
                            </Container>
                        </>
                    </div>
                </>
            )

        return out;
    }
}

export default AccountPage;
