import { Discord, Twitter, Youtube, Instagram } from "react-bootstrap-icons";

// Footer that shows Version of the platform and Social Links.
// Manually imported and put on the bottom of each page.
const Footerfixed = () => {
    return (
        <footer 
            className='fluid fixed-bottom mt-5 text-center text-lg-start text-muted remove-at-low-height footer-width-auto-tablet'
            style={{"width":"98%", "margin-left":"4em", "padding-right":".5em", "margin-bottom":".2em"}}
        >
            <section className='d-flex justify-content-center justify-content-lg-between'>
                <div className='me-5 opacity-60 ps-5 d-none d-lg-block'>
                    <span style={{"font-weight":"bold", "font-size":".8em", "text-shadow":"0 -1px 3px black"}}>
                        Version 0.71 : 
                    </span>
                    <span style={{"padding-left":".5em","font-weight":"bold", "font-style":"italic", "font-size":".8em", "text-shadow":"0 -1px 3px black"}}>
                        The LFG Social Gaming Platform (Early Access)
                    </span>
                </div>
                <div className="pe-2">
                    <a
                        href='https://discord.gg/9EZmV5qBY5'
                        target="_blank"
                        className='me-4 text-reset'
                    >
                        <Discord style={{"height": "23px", "width":"auto"}}/>
                    </a>
                    <a
                        href='https://www.youtube.com/@Indemnigaming'
                        target="_blank"
                        className='me-4 text-reset'
                    >
                        <Youtube style={{"height": "23px", "width":"auto"}}/>
                    </a>
                    <a
                        href='https://twitter.com/Indemnigaming'
                        target="_blank"
                        className='me-4 text-reset'
                    >
                        <Twitter style={{"height": "20px", "width":"auto"}}/>
                    </a>
                    <a
                        href='https://www.instagram.com/indemnigaming'
                        target="_blank"
                        className='me-4 text-reset'
                    >
                        <Instagram style={{"height": "19px", "width":"auto"}}/>
                    </a>
                </div>
            </section>
        </footer>
    );
}

export default Footerfixed;
