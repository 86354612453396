// The New Home Page - Will be changed to Home Page later
// This is where we have a small display at the top
// explaining what we are, with a CTA, and Live Gamers.
// At the bottom, people select a game to go to GamePage
// where they can see LFG Posts for the selected game.
import React, { Component } from "react"
import { onAuthStateChanged } from "firebase/auth";
import {
    logout,
    markMessageAsRead,
    setUpMessageListener,
    setUser,
    updateSignInDate,
    addGames
} from "../utils.js"
import {
    doc,
    getDoc,
    query,
    where,
    collectionGroup,
    getDocs,
    updateDoc,
    onSnapshot,
} from "firebase/firestore"
import BasicNavbar from "../components/Navbar/Navbar"
import Footerfixed from "../components/Footer/Footer"
import {
    Button,
    Image,
    Pagination,
    Dropdown,
} from "react-bootstrap"

class NewHomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: undefined,
            userdata: undefined,
            messages: [], // Existing messages
            unreadCount: 0, // Tracks the unread message count

            nameIncludeFilter: "", // Search input for searching for games.
            displayedGames: [], // New display array for filtered and sliced this.state.games
            gamesPerPage: 10, // Games displayed per page - can be altered.
            currentPage: 1, // The current page as a refference check.
            games: [],
            gamersLFG: undefined,

            onboardingNotFinsihed: undefined,
        }

        this.logout = this.logout.bind(this)
        this.setUser = this.setUser.bind(this)

        this.setUpMessageListener = this.setUpMessageListener.bind(this);
        this.markMessageAsRead = this.markMessageAsRead.bind(this);

        this.completeHomeLFGOnboarding = this.completeHomeLFGOnboarding.bind(this);

        this.passesFilters = this.passesFilters.bind(this)
        this.displayGame = this.displayGame.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.handleSearchChange = this.handleSearchChange.bind(this)

        this.checkNewDayAndSetDate = this.checkNewDayAndSetDate.bind(this);

        // ***Comment this out when finished uploading new games!
        // this.addGamesToDatabase = this.addGamesToDatabase.bind(this);

        onAuthStateChanged(this.props.fbsAuth, async user => {
            if (user) {
                //console.log(`User ${user.uid} logging in (email: ${user.email})`)
                await this.setUser(user)
                .then(async () => {

                    // ***Comment this out when finished uploading new games!
                    // await this.addGamesToDatabase();

                    // One-time call, or atleast until the user clicks "Got it - Thanks!"
                    // This will call completeHomeLFGOnboarding(), which will add the field
                    // lfgHomeOnboardingCompleted: true.
                    // lfgHomeOnboardingCompleted does not exist as a field by default, so it will
                    // return false until the user clicks that button to add the field.
                    if(!this.state.writableUserdata.lfgHomeOnboardingCompleted) {
                        this.setState({ onboardingNotFinsihed: true })
                    }

                        // This is a function check used in multiple components to see if today (based on their user's timezone)
                        // is a new day (aka after Midnight), for when the user last logged in (or was on Indemni).
                        // We pass in user data and check the field timestamp lastSignInDate and perform state and doc updates accordingly.
                        // As of right now, this will be called on most pages for a better user experience. We are also using it to
                        // determine counts of activity (daily, monthly, and total) to be uploaded to firebase and tracked in Mixedpanel.
                        // Overtime this will be much much more robost and better...
                        // The function calls the utilis function updateSignInDate() to handle doc updates if its a new date.
                        // TODO: In the future, when we want to make sure this can never be manipulated, we should be checking and updating,
                        // the Readonly collection and using a cloud function for this.
                        await this.checkNewDayAndSetDate(this.state.writableUserdata);
                })

                // Inbox Snapshot
                await this.setUpMessageListener();
            }
            else {
                this.logout();
            }
        })
    }

    async componentDidMount() {
        // Prepare first page of games
        const gamesDocRef = doc(this.props.fbsFirestore, "AskAGamer", "Games");
        const gamesSnapshot = await getDoc( gamesDocRef)
        if (gamesSnapshot.exists()) {

            // Populate games
            const gamesData = gamesSnapshot.data();
            const games = gamesData.gameData;

            // TODO (later): Figure out a way to pass people array from the "Games" colletcion
            // ... instead so we can display user LFG per game and in total.
            // // Extract people arrays from each game
            // const peopleArrays = games.map(game => game.people || []);

            // // Calculate total number of gamers
            // const totalCount = peopleArrays.reduce((accumulator, peopleArray) => accumulator + peopleArray.length, 0);
        
            // // Update the state variable gamersLFG with the total count
            // this.setState({
            //     games,
            //     gamersLFG: totalCount
            // });
            // console.log("states", this.state.gamersLFG, totalCount, games)

            // Generate a random number between 103 and 127 for LFG gamer display
            // TODO: Actually add logic to people LFG instead of this soon.
            const randomNumber = Math.random();

            // Scale the random number to be between 0 and (127 - 103 = 24)
            const scaledNumber = randomNumber * (201 - 200 + 1);

            // Shift the scaled number up to start from 103
            const shiftedNumber = scaledNumber + 200;

            // Use Math.floor to get the nearest integer (in case you want an integer result)
            const lfgNumber = Math.floor(shiftedNumber);

            this.setState({
                games,
                lfgNumber
            })
        }
    }

    componentWillUnmount() {
        if (this.unsubscribeMessageListener) {
            this.unsubscribeMessageListener()
            this.unsubscribeMessageListener = undefined
        };
    }

    setUser = setUser;
    logout = logout;

    markMessageAsRead = markMessageAsRead;
    setUpMessageListener = setUpMessageListener;

    // Called in onAuthStateChanged(), to determine if it's a new day.
    // It calls the utils function below and updates sign in dates/counts for days and months.
    // We are currently utilzing timestamps and having new day's based on the
    // the user's timezone, so that a "reset" of a new day is midnight for everyone.
    // We may update this overtime to just be based on a specific UTC timezone.
    // TODO: Handle State updates after
    async checkNewDayAndSetDate(writableData) {
        // If it's a new day for the user, call the utils function to update the fields
         await updateSignInDate(this.props.fbsFirestore, this.state.readonlyUserdata.userNumber);
    }


    // Calls a utils.js function to add games to the database at the Games and AskAGamer collections.
    // See addGames() inside utils.js for more context.
    // Be Sure to read the utils commment and push png images live first before uncommenting and calling this function!
    // BE SURE to comment this out here and in onAuthStateChanged, and the constructors. DO Not push this function live.
    // async addGamesToDatabase() {
    //     // An exmaple of how to set up gamesData - Add as many games as needed as a new object in the array.
    //     // const gamesData = [
    //     //     { gameName: "Game 64", gameImagePath: "/Game64.png" },
    //     //     { gameName: "Game 65", gameImagePath: "/Game65.png" },
    //     //     // Add more games as needed
    //     // ];

    //     const gamesData = [
    //         { gameName: "War Thunder", gameImagePath: "/WarThunder.png" },
    //         { gameName: "Hades 2", gameImagePath: "/Hades2.png" },
    //         { gameName: "Call Of Duty Warzone", gameImagePath: "/CallOfDutyWarzone.png" },
    //         { gameName: "Crab Game", gameImagePath: "/CrabGame.png" },
    //         { gameName: "Fallout 4", gameImagePath: "/Fallout4.png" },
    //         { gameName: "Fallout 4 VR", gameImagePath: "/Fallout4VR.png" },
    //         { gameName: "Fallout 76", gameImagePath: "/Fallout76.png" },
    //         { gameName: "Fallout New Vegas", gameImagePath: "/FalloutNewVegas.png" },
    //         { gameName: "Fallout Shelter", gameImagePath: "/FalloutShelter.png" },
    //         { gameName: "Fallout 3", gameImagePath: "/Fallout3.png" },
    //         { gameName: "Valheim", gameImagePath: "/Valheim.png" },
    //         { gameName: "Stardew Valley", gameImagePath: "/StardewValley.png" },
    //         { gameName: "ARK: Survival Evolved", gameImagePath: "/ARKSurvivalEvolved.png" },
    //         { gameName: "ARK: Survival Ascended", gameImagePath: "/ARKSurvivalAscended.png" },
    //         { gameName: "Red Dead Redemption 2", gameImagePath: "/RedDeadRedemption2.png" },
    //         { gameName: "RimWorld", gameImagePath: "/RimWorld.png" },
    //         { gameName: "The Elder Scrolls V: Skyrim", gameImagePath: "/TheElderScrollsVSkyrim.png" },
    //         { gameName: "The Elder Scrolls Online", gameImagePath: "/TheElderScrollsOnline.png" },
    //         { gameName: "Guild Wars 2", gameImagePath: "/GuildWars2.png" },
    //         { gameName: "Age of Empires II", gameImagePath: "/AgeOfEmpires2.png" },
    //         { gameName: "Age of Empires IV", gameImagePath: "/AgeOfEmpires4.png" },
    //         { gameName: "Mount & Blade II: Bannerlord", gameImagePath: "/MountAndBlade2Bannerlord.png" },
    //         { gameName: "Starcraft II", gameImagePath: "/Starcraft2.png" },
    //         { gameName: "Football Manager 2024", gameImagePath: "/FootballManager2024.png" },
    //         { gameName: "Sid Meier's Civilization VI", gameImagePath: "/SidMeiersCivilizationVI.png" },
    //         { gameName: "Dark and Darker", gameImagePath: "/DarkAndDarker.png" },
    //         { gameName: "EAS PORTS FC 24", gameImagePath: "/EASPORTSFC24.png" },
    //         { gameName: "DayZ", gameImagePath: "/DayZ.png" },
    //         { gameName: "Hearts of Iron IV", gameImagePath: "/HeartsOfIronIV.png" },
    //         { gameName: "The Sims 4", gameImagePath: "/TheSims4.png" },
    //         { gameName: "Deep Rock Galactic", gameImagePath: "/DeepRockGalactic.png" },
    //         { gameName: "VRChat", gameImagePath: "/VRChat.png" },
    //         { gameName: "It Takes Two", gameImagePath: "/ItTakesTwo.png" },
    //         { gameName: "Battlefield V", gameImagePath: "/BattlefieldV.png" },
    //         { gameName: "Total War: WARHAMMER III", gameImagePath: "/TotalWarWARHAMMERIII.png" },
    //         { gameName: "Unturned", gameImagePath: "/Unturned.png" },
    //         { gameName: "Soulmask", gameImagePath: "/Soulmask.png" },
    //         { gameName: "7 Days To Die", gameImagePath: "/7DaysToDie.png" },
    //         { gameName: "MIR4", gameImagePath: "/MIR4.png" },
    //         { gameName: "V Rising", gameImagePath: "/VRising.png" },
    //         { gameName: "Phasmophobia", gameImagePath: "/Phasmophobia.png" },
    //         { gameName: "Ghost of Tsushima", gameImagePath: "/GhostOfTsushima.png" },
    //         { gameName: "Stellaris", gameImagePath: "/Stellaris.png" },
    //         { gameName: "Albion Online", gameImagePath: "/AlbionOnline.png" },
    //         { gameName: "SCUM", gameImagePath: "/SCUM.png" },
    //         { gameName: "Yu-Gi-Oh! Master Duel", gameImagePath: "/YuGiOhMasterDuel.png" },
    //         { gameName: "Forza Horizon 5", gameImagePath: "/ForzaHorizon5.png" },
    //         { gameName: "Arma 3", gameImagePath: "/Arma3.png" },
    //         { gameName: "Dark Souls III", gameImagePath: "/DarkSoulsIII.png" },
    //         { gameName: "New World", gameImagePath: "/NewWorld.png" },
    //         { gameName: "No Man's Sky", gameImagePath: "/NoMansSky.png" },
    //         { gameName: "Call of Duty: Black Ops III", gameImagePath: "/CallOfDutyBlackOpsIII.png" },
    //         { gameName: "Among Us", gameImagePath: "/AmongUs.png" },
    //         { gameName: "Manor Lords", gameImagePath: "/ManorLords.png" },
    //         { gameName: "Kingdom Come: Deliverance", gameImagePath: "/KingdomComeDeliverance.png" },
    //         { gameName: "Raft", gameImagePath: "/Raft.png" },
    //         { gameName: "The Witcher 3", gameImagePath: "/TheWitcher3.png" },
    //         { gameName: "Horizon Forbidden West", gameImagePath: "/HorizonForbiddenWest.png" },
    //         { gameName: "PAYDAY 2", gameImagePath: "/PAYDAY2.png" },
    //         { gameName: "EVE Online", gameImagePath: "/EVEOnline.png" },
    //         { gameName: "Lies Of P", gameImagePath: "/LiesOfP.png" },
    //         { gameName: "Dragon's Dogma II", gameImagePath: "/DragonsDogma2.png" },
    //         { gameName: "Stellar Blade", gameImagePath: "/StellarBlade.png" },
    //         { gameName: "The Lords Of The Fallen", gameImagePath: "/TheLordsOfTheFallen.png" },
    //         { gameName: "Nier: Automata", gameImagePath: "/NierAutomata.png" },
    //         { gameName: "Rise of The Ronin", gameImagePath: "/RiseOfTheRonin.png" },
    //         { gameName: "Madden NFL 24", gameImagePath: "/MaddenNFL24.png" },
    //         { gameName: "NBA 2K24", gameImagePath: "/NBA2K24.png" },
    //         { gameName: "Spider-man 2", gameImagePath: "/Spiderman2.png" },
    //         // Add more games as needed
    //     ];
        
    //     await addGames(this.props.fbsFirestore, gamesData);
    // }

    // Called onClick of "Got it - Thanks".
    // Updates user doc with a onboarding completed field
    // See context in onAuthStateChanged.
    async completeHomeLFGOnboarding() {
        const userDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
        await updateDoc (userDocRef, { lfgHomeOnboardingCompleted: true });
        this.setState({ onboardingNotFinsihed: false })
    }

    // Return true if game matches the current filter(s) or nameIncludeFilter === ""
    passesFilters(game) {
        if (!game) return false;
        if (this.state.nameIncludeFilter === "") return true;
        if (this.state.nameIncludeFilter !== "" && game.gameName.toLowerCase().includes(this.state.nameIncludeFilter.toLowerCase()) ) return true;
    }

    // Changes the page
    handlePageChange(newPage) {
        this.setState({ currentPage: newPage });
    }

    // Checks for search input and updates page.
    handleSearchChange(e) {
        const searchValue = e.target.value;
        this.setState({ nameIncludeFilter: searchValue, currentPage: 1 });
    };

    // Display based on passesFilters/handlePageChange/handleSearchChange functions,
    // and Pagination logic set at top of main render before the return statement.
    // The parameter "game" is mapped in the render from this.state.games.
    // These are set in componentDidMount, and we check the gameNumber and gameNumbers.
    displayGame(game) {
        if (this.passesFilters(game)) {
            const gameSearchParams = (new URLSearchParams({ game: game.gameName })).toString();
            return (
                    <>
                        <div className="me-2 px-2 explore-games-background ask-a-gamer-explore-margin-right-0-mobile" >
                            <a
                                href={`/game?${gameSearchParams}&id=${game.gameNumber}`}
                                className="d-block"
                                style={{
                                    "color": "inherit",
                                    "text-decoration": "inherit",
                                }}
                            >
                                <div
                                    className="d-flex flex-column py-3 px-4 ask-a-gamer-game-list-border ask-a-gamer-explore-padding-left-right-halfem-mobile"
                                    onClick={() => {
                                        this.setState({
                                            gameChoice: game.gameName,
                                            gameChoiceImage: game.gameImagePath,
                                        });
                                    }}
                                >
                                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                                        <div
                                            className="d-inline-flex flex-row align-items-center justify-content-center flex-wrap ask-a-gamer-explore-flex-column-mobile"
                                            style={{"width":"100%"}}
                                        >
                                            <div className="d-flex flex-column align-items-center justify-content-center me-2 glow ask-a-gamer-explore-margin-right-left-0-tablet" >
                                                <h5 className="my-2"> {game.gameName} </h5>
                                                <img
                                                    src={game.gameImagePath}
                                                    width="250"
                                                    alt="Video Game Cover Art"
                                                    style={{"width":"600px"}}
                                                    className="img-fluid ask-a-gamer-explore-image-tablet"
                                                />
                                                {/* TODO: Update this with People Array for the specific game, so it shows total current LFG posts*/}
                                                <h5 className="my-2">LFG</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <hr className="ask-a-gamer-answer-spotlight-hr"/>
                    </>
            )
        } else return null;
    }

    // TODO: Add Params to GamePage onClick of Game.
    render() {
        const out = [];

        // Navbar
        out.push(
            <BasicNavbar
                user={this.state.user}
                userdata={{...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata}}
                unreadCount={this.state.unreadCount}
                universityName={(this.state.readonlyUserdata && this.state.readonlyUserdata.inTheUniveristyProgram) ? this.state.readonlyUserdata.universityName : "University"}
            />
        )

        //Pagination Logic - Can be moved outside render if we want.

        const startIndex = (this.state.currentPage - 1) * this.state.gamesPerPage;
        const endIndex = startIndex + this.state.gamesPerPage;
        // Sorts games alphabetically
        const sortedGames =  [...this.state.games].sort((a, b) => a.gameName.localeCompare(b.gameName));
        // Filters games based on search term.
        const filteredGames = sortedGames.filter((game) => this.passesFilters(game));
        // Displays games on the current page for pagination.
        const displayedGames = filteredGames.slice(startIndex, endIndex);
        // Calculates the total number of pages needed to display all the search results.
        const totalPages = Math.ceil(filteredGames.length / this.state.gamesPerPage);
        // Updates pagination.item (i.e. Total Page amount) to be dynamic based on search results.
        // *Update the second param, which is currently 5, to whatever when we have more games.
        // TODO: This works, but check logic to make sure it works under various circumstances.
        const pageNumbersToShow = Math.min(totalPages, 5);
        const middlePageNumber = Math.floor(pageNumbersToShow / 2);
        let firstPageNumber = Math.max(1, this.state.currentPage - middlePageNumber);
        const lastPageNumber = Math.min(totalPages, firstPageNumber + pageNumbersToShow - 1);
        // Adjusts firstPageNumber - it was disappearing when the last page didn't have 10 games for some reason.
        if (lastPageNumber === totalPages) {
            firstPageNumber = Math.max(1, lastPageNumber - pageNumbersToShow + 1);
        }

        // Page Body
        out.push(
            <>
                <div
                    className="body-content visting-home-page-fade-animation change-explore-body-content-small-screen explore-body-content-padding-mobile
                    explore-body-content-padding-large ask-a-gamer-explore-padding-left-right-1-mobile"
                >
                    <div className="mt-2">
                            <div className="d-flex flex-column justify-content-between align-items-center game-details-flex-column-mobile pb-1">
                                <div className="d-flex flex-column mt-3 justify-content-center align-items-center increases-matchmake-title-font-desktop">
                                    <div className="d-flex justify-content-center align-items-center game-details-play">
                                        <div
                                            className="p-2"
                                            style={{"height":"auto", "max-width":"850px"}}
                                        >
                                            <Image
                                                className="shadow"
                                                src="/IndemniHomePageImage.png"
                                                alt="Indemni LFG Social Gaming Platform Image"
                                                rounded
                                                fluid
                                            />
                                        </div>
                                    </div>
                                    {/* TODO: Make this something else - CTA/Messaging/etc.*/}
                                    <h1 style={{"margin-top":"1em", "font-style":"oblique", "text-align":"center", "text-shadow":"0 -1px 3px #000000eb"}}>
                                        Gamers LFG:
                                        <span style={{"color":"#faff97", "font-weight":"600"}}> {this.state.lfgNumber}</span>
                                    </h1>
                                    <span style={{"font-size":"1em", "margin-bottom":".25em", "font-style":"normal", "font-weight":"600", "color":"#a4f2ff", "max-width":"960px", "text-align":"center"}}>
                                        Unite with gamers to find perfect teammates, make new friends, and discover people with similar gaming goals & passions.
                                    </span>
                                    <span style={{"font-size":".85em", "font-style":"normal", "margin-bottom":"1em", "font-weight":"400"}}>
                                        *Giveaway results are listed in the FAQ
                                    </span>
                                </div>

                                {/* Onboarding render shown when a user first creates an account. */}
                                { this.state.onboardingNotFinsihed && this.state.user &&
                                    <div
                                        style={{"margin-bottom":"2em"}}
                                        className={!this.state.isEditingLFGPost ? "d-flex flex-column tab-textbox-hover-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile" : "d-flex flex-column tab-textbox-edit-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile"}
                                    >
                                        <div className="d-flex justify-content-between align-items-center flex-wrap">
                                            <div
                                                className="d-inline-flex flex-row align-items-center flex-wrap"
                                                style={{"width":"100%"}}
                                            >
                                                <div
                                                    style={{"width":"100%"}}
                                                    className="d-flex flex-column align-items-center justify-content-center increase-demo-instruction-font-desktop"
                                                >
                                                    <span
                                                        style={{"font-size":"1.1em"}}
                                                        className="mb-2"
                                                    >
                                                        Hey {this.state.readonlyUserdata.username}!
                                                    </span>
                                                    <span
                                                        style={{"font-size":"1.2em", "text-decoration":"underline", "font-style":"oblique"}}
                                                        className="mt-2 mb-1"
                                                    >
                                                            How Looking For Group Works
                                                    </span>
                                                    <p style={{"color":"#fff1c5", "font-weight":"450"}}>
                                                        This page is your standard directory games. Simply search for the game you wish to find teammates for and click on the click to see the LFG posts from other users. After selecting a game, you will be able to search through posts by using the search bar or by setting filters. Each post displays information about what the other gamer is trying to do, and shows their current groups members. You can join groups by clicking on the "Join Group" button on a post, or vist their profile to see more information, message them, or decide to join group there.
                                                    </p>
                                                    <p style={{"color":"#fff1c5", "font-weight":"450"}}>
                                                        Two things to note: when you post an LFG, this is where your posts will be. Also, the LFG page focuses more on a specific game where you can browse, whereas Gamer Matchmaker has very detailed matchmaking options with an emphasis on finding a specific gamer.
                                                    </p>
                                                    <span
                                                        style={{"color":"#fff1c5", "font-weight":"450"}}
                                                        className="mb-2"
                                                    >
                                                        If you need this information again, or have any other questions on how things work, everything you need to know will be located in the FAQ page. You can also join our <a href="https://discord.gg/9EZmV5qBY5" target="_blank" className="change-link-color-chat">Discord</a> to get your questions answered faster, give feedback, chat with the team, participate in events, and meet more members of the Indemni community.
                                                    </span>
                                                    <Button
                                                        className="d-flex flex-column mx-1 p-1 align-items-center chat-with-gamer-button glow mt-3"
                                                        style={{"width":"250px", "box-shadow":"#fffzfff82 0px 0px 10px 3px", "font-size":"1em", "font-weight":"550", "text-shadow":"black 0px 2px 2px", "background-color":"#39ff6ba1", "border-radius":"20px"}}
                                                        onClick={async () => {
                                                            await this.completeHomeLFGOnboarding();
                                                        }}
                                                    >
                                                        Got it - Thanks!
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {/* Onboarding render finished */}

                            </div>

                        {/* Game Display */}
                        <div
                            className="mb-2 pe-3 w-100 ask-a-gamer-explore-margin-padding-right-left-0-mobile"
                            style={{"height":"95%"}}
                        >
                            <div className="h-100 w-95 p-2 ask-a-gamer-explore-margin-padding-right-left-0-mobile">
                                {/* List of games */}
                                <div className="between-and-flex-important flex-column ask-indemni-reduce-font-size-mobile ask-a-gamer-explore-flex-column-page-input-mobile">
                                    <h5
                                        className="demo-page-increase-font-section-titles"
                                        style={{"width":"100%", "text-decoration":"underline","margin-bottom":".25em"}}
                                    >
                                        Find Teammates
                                    </h5>
                                    <div
                                        style={{"width":"100%"}}
                                        className="mb-2 d-flex flex-row justify-content-between align-items-center ask-a-gamer-explore-width-auto-mobile"
                                    >
                                        <div
                                            className="d-flex flex-row align-items-center justify-content-start ask-a-gamer-explore-margin-top-input-mobile hange-demo-input-div-container-align"
                                            style={{"width":"100%"}}
                                        >
                                            <input
                                                type="search"
                                                className="ms-3 change-width-for-mobile ask-a-gamer-explore-input-mobile demo-search-bar-desktop"
                                                style={{"vertical-align":"middle", "width":"100%", "outline":"none"}}
                                                value={this.state.nameIncludeFilter}
                                                onChange={this.handleSearchChange}
                                                placeholder="Search by game name..."
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="You can type in a games name here and click the game picture from the list below or use the dropdown menu on the right."
                                            />
                                            <Button
                                                className="ms-3 w-30 ask-a-gamer-explore-clear-button-mobile"
                                                onClick={() => this.setState({
                                                    nameIncludeFilter: "",
                                                    currentPage: 1,
                                                    gameChoice:"Games",
                                                })}
                                            >
                                                Reset
                                            </Button>
                                        </div>
                                        <Dropdown
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="You can select a game with this dropdown or use the search bar on the left and click the game picture from the list below."
                                            style={{"margin-top":".25em"}}
                                        >
                                            <Dropdown.Toggle className="ms-3 w-30 demo-game-select-dropdown-button-mobile">
                                                Games
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu style={{"font-size":"1em", "text-align":"center", "min-width":"100px", "background":"#212121ed", "height":"360px", "text-wrap":"balance", "overflow-y":"scroll"}}>
                                            { this.state.games.map((game) => {
                                                const gameSearchParams = (new URLSearchParams({ game: game.gameName })).toString();
                                                return (
                                                <Dropdown.Item
                                                    style={{"text-wrap":"balance", "border-top":"solid 1px black","border-bottom":"solid 1px black"}}
                                                    key={game.id}
                                                    eventKey={game.id}
                                                    href={`/game?${gameSearchParams}&id=${game.gameNumber}`}
                                                    onClick={() => {
                                                        this.setState({
                                                            gameChoice: game.gameName,
                                                            gameChoiceImage: game.gameImagePath,
                                                        });
                                                    }}
                                                >
                                                    {game.gameName}
                                                </Dropdown.Item>
                                                );
                                            })}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <hr className="hr-ask-indemni-title-hr" />
                                { displayedGames.map((game) => (
                                    <div key={game.id} className="me-2 px-2 ask-a-gamer-explore-margin-padding-right-left-0-mobile">
                                      {this.displayGame(game)}
                                    </div>
                                ))}
                                { totalPages > 0 && (
                                    <div
                                        style={{"font-size":".85em"}}
                                        className="center-and-flex-important mb-2"
                                    >
                                        <span
                                            style={{"font-size":"1.7em", "font-weight":"600", "margin-bottom":".2em"}}
                                            className="me-2"
                                        >
                                            Page
                                        </span>
                                    <Pagination>
                                        {[...Array(lastPageNumber - firstPageNumber + 1).keys()].map((index) => {
                                            const pageNumber = firstPageNumber + index;
                                            return (
                                                <Pagination.Item
                                                    key={pageNumber}
                                                    active={pageNumber === this.state.currentPage}
                                                    onClick={() => this.handlePageChange(pageNumber)}
                                                    linkStyle={{"color":"white", "background-color":"#312e2e", "width":"50px","font-size":"1.1em", "font-weight":"700", "text-align":"center"}}
                                                >
                                                    {pageNumber}
                                                </Pagination.Item>
                                            );
                                        })}
                                    </Pagination>
                                  </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <Footerfixed></Footerfixed>
                </div>
            </>
        )

        return out;
    }
}


export default NewHomePage;
