import React, { Component } from 'react';
import { Button, Dropdown, Col } from 'react-bootstrap';
import { PersonVcard, Trash3, PersonSlash } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import SocialPersonalChatInput from './SocialPersonalChatInput';

class SocialPersonalChat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchTerm: ''
        };

        this.formatDate = this.formatDate.bind(this);
        this.renderFormattedText = this.renderFormattedText.bind(this);
    }

    // Looks for %0A URL encoded line breaks and links.
    // Handles reformatting text to wrap links in <a> tags, and
    // shows the user visual spaces with <br /> if they pressed enter or
    // shift+enter as represented by "\n".
    renderFormattedText = (text) => {
        const linkRegex = /(?:https?|ftp):\/\/[^\s]+(?:\s|$)/g;
        const lines = text.split(/(?:\n|%0A)/);
      
        const elements = [];
      
        lines.forEach((line, lineIndex) => {
            const linkMatches = line.match(linkRegex);
            if (linkMatches) {
                let lastIndex = 0;

                linkMatches.forEach((linkMatch, index) => {
                    const linkStart = line.indexOf(linkMatch, lastIndex);
                    const linkEnd = linkStart + linkMatch.length;

                    // Adds the preceding text as regular text
                    elements.push(<span key={`text-${lineIndex}-${index}`}>{line.substring(lastIndex, linkStart)}</span>);

                    // Wraps the link in an anchor
                    elements.push(
                        <a
                            key={`link-${lineIndex}-${index}`}
                            className="change-link-color-chat"
                            style={{
                                wordWrap: 'break-word',
                                wordBreak: 'break-all',
                                whiteSpace: 'normal',
                            }}
                            href={decodeURIComponent(linkMatch)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                        {decodeURIComponent(linkMatch)}
                        </a>
                    );

                    // Updates the lastIndex to the end of the current link
                    lastIndex = linkEnd;
                });
            
                // Adds the remaining text after the last link
                elements.push(<span key={`text-${lineIndex}-last`}>{line.substring(lastIndex)}</span>);
            } else {
                // If no links, add the entire line as regular text
                elements.push(<span key={`text-${lineIndex}`}>{line}</span>);
            }
          
            // Add the line break after each line (except the last line)
            if (lineIndex < lines.length - 1) {
                elements.push(<br key={`br-${lineIndex}`} />);
            }
        });
      
        return elements;
    };

    // Changes the timestamp.now to a real js object and transformed to an
    // actual number and date.
    formatDate(date) {
        const options = { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return date.toLocaleDateString('default', options);
    }


    render() {
        const { messages, selectedRecipientUser, sendMessage, messageText, onMessageTextChange, removeUserFromContacts, cooldown, userdata, pfp } = this.props;

        return (
            <Col
                className= 'home flex-column chat-inbox-width-max'
                style={{"margin-bottom":"3em", "height":"900px"}}
            >
                <div className= 'homecontainer style-for-mobile sidebar-medium-width remove-margin-left-medium'>
                    <div className="chatmessage">
                        <div className="chatinfo">
                            {!selectedRecipientUser ? (
                                    <div
                                        className="d-flex flex-row justify-content-between align-items-center"
                                        style={{"margin-left":"1em", "padding-top":"2px", "width":"100%"}}
                                    >
                                    <span>Select User</span>
                                    <Button className="tab-textbox-buttons me-3 px-3 social-personal-visit-profile-button-mobile visit-profile-button-medium" style={{"box-shadow":"0px -5px 20px 5px #040404", "color":"#d5d5d5e8", "text-shadow":"0 0 2px #000000", "font-weight":"800"}}>
                                        Visit a Profile Here!
                                        <PersonVcard className="remove-for-mobile" style={{"color":"white", "margin-left":".25em", "margin-bottom":".2em", "align-items":"center"}} />
                                    </Button>
                                    <Dropdown>
                                        <Dropdown.Toggle className="social-personal-chat-dropdown">
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{"inset":"5px -5px auto auto", "font-size":".9em", "text-align":"center", "min-width":"200px", "background":"#212121ed", "text-wrap":"balance"}}>
                                                <Dropdown.Item
                                                     style={{"text-wrap":"balance", "border-top":"solid 1px black","border-bottom":"solid 1px black"}}
                                                     disabled
                                                >
                                                    Here - you can remove a user from your messages <Trash3 className="ms-2"/>
                                                </Dropdown.Item>                                                                                   
                                                <Dropdown.Item
                                                     style={{"text-wrap":"balance", "border-top":"solid 1px black","border-bottom":"solid 1px black"}}
                                                     disabled
                                                >
                                                     Here - you can block a user <Trash3 className="ms-2"/>
                                                </Dropdown.Item>                                                                                   
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            ) : (
                                    <>
                                    {messages.some((message) => message.fromName === selectedRecipientUser || message.toName === selectedRecipientUser)
                                      ? messages.map((message, index) => (
                                            <React.Fragment key={index}>
                                                {index === 0 && (
                                                    <div className="chaticons">
                                                        <span
                                                            className="shorten-chat-usernames"
                                                            style={{"font-size":"1.2em", "font-weight":"bolder","padding-right":"5px", "padding-top":"2px", "text-decoration":"underline", "margin-left":"1em"}}
                                                        >
                                                            {selectedRecipientUser}
                                                        </span>
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        ))
                                      : 
                                        <div className="chaticons">
                                            <span
                                                className="shorten-chat-usernames"
                                                style={{"font-size":"1.2em", "font-weight":"bolder","padding-right":"5px", "padding-top":"2px", "text-decoration":"underline", "margin-left":"1em"}}
                                            >
                                                {selectedRecipientUser}
                                            </span>
                                        </div>
                                    }
                                    <div className="chaticons">
                                        <Link to={`/visitaccount?gamer=${selectedRecipientUser}`}>
                                            <Button className="tab-textbox-buttons px-4 social-personal-visit-profile-button-mobile visit-profile-button-medium" style={{"box-shadow":"0px -5px 20px 5px #040404", "color":"#d5d5d5e8", "text-shadow":"0 0 2px #000000", "font-weight":"800"}}>
                                                <PersonVcard
                                                    style={{"color":"white", "margin-left":".25em", "margin-bottom":".2em", "margin-right":".5em", "align-items":"center", "height":"15px", "width":"auto"}}
                                                />
                                                Visit Profile
                                            </Button>
                                        </Link>
                                    </div>
                                    <div className="chaticons">
                                        <Dropdown>
                                            <Dropdown.Toggle className="social-personal-chat-dropdown">
                                            </Dropdown.Toggle>
                                            {/* TODO: Add block function */}
                                            <Dropdown.Menu style={{"inset":"5px -5px auto auto", "font-size":".9em", "text-align":"center", "min-width":"200px", "background":"#212121ed", "text-wrap":"balance"}}>
                                                <Dropdown.Item
                                                style={{"text-wrap":"balance", "border-top":"solid 1px black","border-bottom":"solid 1px black", "color":"#e0e0e0d9"}}
                                                onClick={() => removeUserFromContacts(selectedRecipientUser)}>
                                                    <span style={{"color":"#e0e0e0d9", "font-weight":"550"}}>Remove User From Messages <Trash3 className="ms-2"/></span>
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                    style={{"text-wrap":"balance", "border-top":"solid 1px black","border-bottom":"solid 1px black", "color":"#e0e0e0d9"}}
                                                >
                                                    <span style={{"color":"#e0e0e0d9", "font-weight":"550"}}> Block User <PersonSlash className="ms-2"/></span>
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="messageboxchat" id="chat-box">
                            { selectedRecipientUser
                              ? 
                                (
                                    <>
                                        {messages.some((message) => message.fromName === selectedRecipientUser || message.toName === selectedRecipientUser)
                                          ? messages.map((message, index) => (
                                                <div className={`message chat-inbox-fade-animation ${message.fromName === userdata.username ? "ownerchat align-items-center" : "align-items-center"}`} key={index}>
                                                    <div className="message1 messages-info-width-mobile">
                                                        <span
                                                            className={`shorten-chat-usernames ${message.from === this.props.userdata.userNumber ? "currentUsernameChatColor " : "otherUsernameChatColor"}`}
                                                            style={{"font-size":"1em", "padding-bottom":"2px", "font-weight":"500", "text-align":"center"}}
                                                        >
                                                            { message.from === this.props.userdata.userNumber
                                                              ? "Me"
                                                              : message.fromName
                                                            }
                                                        </span>
                                                        <img
                                                            className="navuserchat"
                                                            src={message.from === this.props.userdata.userNumber ?  "/ProfilePicturePlaceholder.png" : "/logo-color.png"}
                                                        />
                                                        <span style={{"font-size":"10px", "padding-top":"2px", "color":"#b5b2b2", "font-weight":"400", "text-align":"center"}} >
                                                            { message.timeSent && message.timeSent.toDate()
                                                              ? this.formatDate(message.timeSent.toDate())
                                                              : undefined
                                                            }
                                                        </span>
                                                    </div>
                                                    <div style={{"padding-top":"5px"}}key={index} className="message2" >
                                                        <p
                                                            // TODO: check to see if this breaks things, currently without this,
                                                            // a long word or link can utterly break the div render. Maybe there is a
                                                            // a better way to wrap long words.
                                                            style={{"overflow-wrap":"break-word"}}
                                                            className="message-width-mobile max-width-chat-messages-tablet max-width-chat-messages-laptop"
                                                        >
                                                            {this.renderFormattedText(message.body)}
                                                        </p>
                                                    </div>
                                                </div>
                                            ))
                                          :
                                            <div 
                                                className="d-flex justify-content-center align-items-center chat-inbox-fade-animation"
                                                style={{"font-size":"1.5em", "font-weight":"600"}}
                                            >
                                                { !cooldown &&
                                                    <span 
                                                        className="text-center"
                                                        style={{"margin-top":"5em"}}
                                                    >
                                                        Send a message to {selectedRecipientUser} to start your conversation.
                                                    </span>
                                                }
                                            </div>
                                        }
                                        { this.state.cooldown
                                          ? <div className="message ownerchat" >
                                                <div className="message1" >
                                                    <img className="navuserchat" src={this.props.pfp} />
                                                    <span style={{"font-size":"10px", "padding-top":"2px"}} >
                                                        Now
                                                    </span>
                                                </div>
                                                <div
                                                    style={{"padding-top":"5px"}}
                                                    className="message2"
                                                >
                                                    <p>
                                                        <div className="circle-inline-loading-animation " />
                                                    </p>
                                                </div>
                                            </div>
                                          : null
                                        }
                                    </>
                                )
                              :
                                <>
                                    <div 
                                        className="d-flex justify-content-center align-items-center chat-inbox-fade-animation chat-alt-div-size-mobile"
                                        style={{"font-size":"1.3em", "margin-left":"1.5em", "font-weight":"600"}}
                                    >
                                        <span 
                                            className="text-center"
                                            style={{"margin-top":"5em", "margin-left":"1.5em"}}
                                        >
                                            To talk to other users, select their name in the Contacts & Friends Tab. Once selected, you can then message them here.
                                        </span>
                                    </div>
                                </>
                            
                            }
                        </div>
                        {selectedRecipientUser ? (
                            <SocialPersonalChatInput
                                messageText={messageText}
                                onMessageTextChange={onMessageTextChange}
                                onSendMessage={() => sendMessage(selectedRecipientUser)}
                                cooldown={cooldown}
                            />
                        ) : (
                            <SocialPersonalChatInput
                                messageText={messageText}
                                onMessageTextChange={onMessageTextChange}
                                onSendMessage={() => sendMessage(selectedRecipientUser)}
                                placeholderMessageInput
                            />
                        )}
                    </div>
                </div>
            </Col>
        );
    }
}

export default SocialPersonalChat;
