// The Demo page is purely a demo showcasing the "Gamer Matchmaker" Button.
// We will be using many of the filters and UI/UX for everything else we
// are building going forward, but it's messy and hardcoded - since it will
// be deleted we go live, not much reason to optimize or change anything
// This page will be used as a template for the Gamer Matchmaker Page as well
// Finally, we have the standard display of the Demo apge when users load in,
// and two conditional renders - one is an animation used when matchmaking,
// and the other is an import of the DemoAccountTabs which basically is the
// DemoAccountProfile render with states of filters and games passed to it.
// *Some variables are not in constructor or not used and simply old code/comments,
// but may be used in the Games Page or Gamer Matchmaker Page.
import React, { Component } from "react"
import { onAuthStateChanged } from "firebase/auth";
import {
    logout,
    setUser,
    findGamers,
    updateSignInDate
} from "../utils.js"
import {
    doc,
    getDoc,
    getDocs,
    query,
    collectionGroup,
    where,
    updateDoc,
    increment,
    arrayUnion,
    arrayRemove,
} from "firebase/firestore"
import { ShieldCheck } from "react-bootstrap-icons";
import GamerMatchmakerTabs from "../components/GamerMatchmaker/GamerMatchmakerTabs.js";
import Footerfixed from "../components/Footer/Footer"
import { Button, Container, Row, Col, Stack, Image, Pagination, Dropdown, Form} from "react-bootstrap"
import BasicNavbar from "../components/Navbar/Navbar"
import UniversitySearch from "../components/UniversitySearch/UniversitySearch.js";
import universityList from '../components/UniversitySearch/world_universities_and_domains.json'; // Adjust the path based on your actual directory structure

class GamerMatchmakerPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: undefined,
            userdata: undefined,
            messages: [], // Existing messages
            unreadCount: 0, // Tracks the unread message count
            fadeOutAnimation: false, // set in handleTimeDelayOfPfp during onboarding new users.
            gamerIsLive: false, // if true, the gamer is live and can answer questions.
            queue: [], // set in setUpQueueListener in onAuthStateChanged- is an array of currently queued askers.
            queueLength: undefined, // set in setUpQueueListener when "Go Live" button is clicked - is a number which is the current length of the queue array.
            chatStarted: false, // set in AskAGamerAnswerChat when clicking "Start Chat" or "End Chat"
            cooldown: false, // Conditional to set timeout of 1.5s to prevent spamming of messages and allow cloud function to finish.
            preMatchmaking: true,
            searchingStarted: false,
            matchmakeStarted: false,
            showUserVisitingProfile: false,

            nameIncludeFilter: "", // Search input for searching for games.

            displayedGames: [],  // New display array for filtered and sliced this.state.games
            gamesPerPage: 10,    // Games displayed per page - can be altered.
            currentPage: 1,      // The current page as a refference check.
            games: [],
            liveData: [],
            liveGamers: undefined,
            flexibleOnGame: false,
            noGameSelected: true,
            showSelectedGame: "No Game Selected",

            isFriend: false,
            displayGG: false, // if true, the user/asker has given the answerer/gamer a GG today (upvote).
            displayNoob: false, // if true, the user/asker has given the answerer/gamer a Noob today (downvote).
            alreadyVoted: false, // set to true or false based on if the user already gave a GG or Noob today.

            gamersNotFound: false,
            noGamersFound: false,
            mustMatchGame: false,
            gamers: [],
            currentGamerIndex: 0, // used for diplaying next user of gamers that.

            // All dropdown item states
            // Definitely a better way to do this - just using this for now.
            platformList: ["Any", "PC", "Console", "PS5", "Xbox", "Switch", "Mobile", "VR", "Other"],
            platformChoice: "Any",
            neededList: ["Any", "1 Gamer", "2 Gamers", "3 Gamers", "4 Gamers", "5 Gamers", "6+ Gamers"],
            neededChoice: "Any",
            startList: ["Any", "Now", "Later"],
            startChoice: "Any",
            objectiveList: ["Any", "Casual", "Ranked", "Practice", "Recruiting", "Social"],
            objectiveChoice: "Any",
            commsList: ["Any", "Mic", "Chat", "None"],
            commsChoice: "Any",
            sessionList: ["Any", "Unknown" ,"1 Hour", "1-2 Hours", "2 Hours", "2-3 Hours", "3 Hours", "3-4 Hours", "4+ Hours"],
            sessionChoice: "Any",
            skillList: ["Any", "Good", "Great", "Pro", "Average", "Noob"],
            skillChoice: "Any",
            personalityList: ["Any", "Relaxed", "Serious", "Funny", "Quiet", "Loud", "Sad :(", "Happy"],
            personalityChoice: "Any",
            ageList: ["Any", "18-24", "24-29", "30-39", "40-49", "50+", "Other"],
            ageChoice: "Any",
            genderList: ["Any", "Male", "Female", "Other"],
            genderChoice: "Any",
            locationList: ["Any", "USA", "Canada", "UK", "AUS", "Europe", "Asia", "Africa", "LATAM", "Other"],
            locationChoice: "Any",
            languageList: ["Any", "English", "French", "Spanish", "Korean", "Mandarin", "Hindi", "Other"],
            languageChoice: "Any",
            weeklyPlaytimeList: [
                "Any",
                "1 Hour",
                "2 Hours",
                "2-3 Hours",
                "3 Hours",
                "4 Hours",
                "4-5 Hours",
                "5 Hours",
                "6-7 Hours",
                "8 Hours",
                "10 Hours",
                "10-15 Hours",
                "15-20 Hours",
                "20-30 Hours",
                "30-40 Hours",
                "40+ Hours",
                "Too Much",
            ],
            occupationList: [
                "Any",
                "Accountant",
                "Actor",
                "Administrative Assistant",
                "Anthropologist",
                "Archaeologist",
                "Architect",
                "Artist",
                "Astronomer",
                "Athlete",
                "Audiologist",
                "Baker",
                "Banker",
                "Barista",
                "Bartender",
                "Biologist",
                "Body Builder",
                "Botanist",
                "Brewer",
                "Bus Driver",
                "Business Analyst",
                "Carpenter",
                "Cashier",
                "Chef",
                "Chemist",
                "Chiropractor",
                "Civil Engineer",
                "Cleaner",
                "Construction Worker",
                "Consultant",
                "Content Creator",
                "Counselor",
                "Customer Service Representative",
                "Data Analyst",
                "Data Scientist",
                "Delivery Driver",
                "Dentist",
                "Dermatologist",
                "Dietitian",
                "Director",
                "Editor",
                "Educator",
                "Electrician",
                "Engineer",
                "Entrepreneur",
                "Event Planner",
                "Factory Worker",
                "Farmer",
                "Fashion Designer",
                "Film Director",
                "Financial Advisor",
                "Financial Analyst",
                "Firefighter",
                "Fitness Trainer",
                "Flight Attendant",
                "Florist",
                "Game Artist",
                "Game Designer",
                "Game Developer",
                "Game Director",
                "Game Producer",
                "Gamer",
                "Geologist",
                "Graphic Designer",
                "Grocery Store Worker",
                "Guidance Counselor",
                "HR Manager",
                "Historian",
                "Host/Hostess",
                "Hotel Manager",
                "Human Resources Specialist",
                "IT Technician",
                "Influencer",
                "Insurance Agent",
                "Interior Designer",
                "Janitor",
                "Journalist",
                "Judge",
                "Lawyer",
                "Librarian",
                "Loan Officer",
                "Locksmith",
                "Logistician",
                "Marketer",
                "Mechanic",
                "Mechanical Engineer",
                "Medical Assistant",
                "Midwife",
                "Musician",
                "Network Administrator",
                "Neurologist",
                "Nurse",
                "Nurse Practitioner",
                "Occupational Therapist",
                "Optometrist",
                "Paralegal",
                "Paramedic",
                "Park Ranger",
                "Pediatrician",
                "Personal Trainer",
                "Pharmacist",
                "Phlebotomist",
                "Photographer",
                "Physical Therapist",
                "Physician",
                "Physiotherapist",
                "Pilot",
                "Plumber",
                "Podcaster",
                "Poet",
                "Police Officer",
                "Politician",
                "Pro Gamer",
                "Product Manager",
                "Professor",
                "Programmer",
                "Project Manager",
                "Psychiatrist",
                "Psychologist",
                "Public Relations Specialist",
                "Radiologic Technologist",
                "Real Estate Agent",
                "Realtor",
                "Receptionist",
                "Registered Nurse",
                "Research Scientist",
                "Respiratory Therapist",
                "Retail Store Manager",
                "Retired",
                "Salesperson",
                "Scientist",
                "Secretary",
                "Security Guard",
                "Server",
                "Social Media Manager",
                "Social Worker",
                "Software Developer",
                "Sound Engineer",
                "Speech Therapist",
                "Statistician",
                "Streamer",
                "Student",
                "Surgeon",
                "Surveyor",
                "Systems Analyst",
                "Tailor",
                "Teacher",
                "Technical Writer",
                "Translator",
                "Travel Agent",
                "Unemployed",
                "Urban Planner",
                "Urologist",
                "Veterinarian",
                "Video Editor",
                "Web Developer",
                "Welder",
                "Writer",
                "Zoologist"
            ],
            // TODO: Import universitylist.json and implement logic for this instead.
            educationList: [
                "Any",
                "Private",
                "High School",
                "College",
            ],
            educationChoice: "Any",
            occupationChoice: "Any",
            interestList: [
                "Adventure Games",
                "Anime",
                "Any",
                "ARPGS",
                "Augmented Reality",
                "Automotive Technology",
                "Backpacking",
                "Baking",
                "Basketball",
                "Blogging",
                "Board Games",
                "Builder Games",
                "Camping",
                "Card Games",
                "Chess",
                "Content Creation",
                "Cosplay",
                "Cycling",
                "Dancing",
                "Drawing",
                "E-sports",
                "Fantasy Sports",
                "Fighting Games",
                "Fishing",
                "Fitness",
                "FPS",
                "Game Development",
                "Game News",
                "Gaming",
                "Gardening",
                "Golf",
                "Hiking",
                "Home Brewing",
                "Journalism",
                "Kayaking",
                "Knitting",
                "Live Streaming",
                "Magic Tricks",
                "Martial Arts",
                "Memes",
                "MMOs",
                "Mountain Biking",
                "Mr. Beast",
                "Mr. Indemni",
                "Music Production",
                "Netflix & Chill",
                "Origami",
                "Painting",
                "Partying",
                "Photography",
                "Podcasting",
                "Puzzles",
                "Reading",
                "Roleplaying",
                "RPGs",
                "RTS Games",
                "Running",
                "Sailing",
                "Sci-Fi",
                "Sherpa'ing",
                "Skiing",
                "Snowboarding",
                "Socializing",
                "Soccer",
                "Sports Games",
                "Streaming",
                "Surfing",
                "Swimming",
                "Tabletop RPGs",
                "TCG",
                "Technology Reviews",
                "TikTok",
                "Traveling",
                "Twitch",
                "Video Editing",
                "Video Game Design",
                "Video Games",
                "Vlogging",
                "Wakeboarding",
                "Watching Movies",
                "Watching TV Shows",
                "Weightlifting",
                "Woodworking",
                "Writing",
                "Yoga"
            ],
            interestChoice: "Any",
            longtermList: ["Any", "Yes", "No", "Maybe"],
            longtermChoice: "Any",

            gameChoice: "Any",
            gameChoiceImage: "Any",
            randomPostTime: "8PM PST",
            randomLFGTextOne: "Just lookin to play",
            randomLFGTextTwo: "Down for whatever - just send a message or join my group",
            randomBio: "Im a Gamer ... I game hard bro. Idk what else to say lol.",
            randomIdealTeammate: "Just looking for a someone that's chill and wants to play some shooters or FIFA on the weekend.",
            randomStats: "👑 Been playing since day 1",
            randomSocialsOne: "👑 Twitch: ChronoCaster",
            randomSocialsTwo: "👑 Steam: SilentSage89",
            randomSocialsThree: "👑 PS5: XxSlay3rKingxX",
            randomFlexRequire: "Flexible",
            randomYesNo: "Yes",
            randomInterestOne: "FPS",
            randomInterestTwo: "Anime",
            randomOccupation: "Streamer",
            randomEducation: "Private",
            randomWeeklyPlaytime: "Private",

            universities: universityList,
            countryFilter: "",
            universityNameIncludeFilter: "",
            universityCurrentPage: 1,
            universitiesPerPage: 10,
            universityChoice: "", // New state to store the selected university
            educationSelected: false, // New state to track if education is selected

        }

        this.logout = this.logout.bind(this)
        this.setUser = this.setUser.bind(this)

        this.passesFilters = this.passesFilters.bind(this)
        this.displayGame = this.displayGame.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.handleSearchChange = this.handleSearchChange.bind(this)
        this.calculatePercentage = this.calculatePercentage.bind(this)
        this.percentageColor = this.percentageColor.bind(this)

        this.playAudio = this.playAudio.bind(this);
        this.updateGGScore = this.updateGGScore.bind(this);
        this.updateNoobScore = this.updateNoobScore.bind(this);
        this.checkDate = this.checkDate.bind(this);

        this.addUserToContacts = this.addUserToContacts.bind(this);
        this.addOrRemoveAsFriend = this.addOrRemoveAsFriend.bind(this);

        this.gamerFoundAnimation = this.gamerFoundAnimation.bind(this)

        this.fetchGamers = this.fetchGamers.bind(this);
        this.showNextGamer = this.showNextGamer.bind(this);
        this.showPreviousGamer = this.showPreviousGamer.bind(this);

        this.renderFormattedText = this.renderFormattedText.bind(this);

        this.checkNewDayAndSetDate = this.checkNewDayAndSetDate.bind(this);

        // this.handleCountryChange = this.handleCountryChange.bind(this);
        // this.handleUniversitySearchChange = this.handleUniversitySearchChange.bind(this);
        // this.handleCountryChange = this.handleCountryChange.bind(this);
        // this.handlePageChange = this.handlePageChange.bind(this);
        // this.handleUniversityClick = this.handleUniversityClick.bind(this);
        // this.handleChangeEducation = this.handleChangeEducation.bind(this); // Bind the handler for changing education

        onAuthStateChanged(this.props.fbsAuth, async user => {
            if (user) {
                //console.log(`User ${user.uid} logging in (email: ${user.email})`)
                await this.setUser(user)
                .then(async () => {

                    // This is a function check used in multiple components to see if today (based on their user's timezone)
                    // is a new day (aka after Midnight), for when the user last logged in (or was on Indemni).
                    // We pass in user data and check the field timestamp lastSignInDate and perform state and doc updates accordingly.
                    // As of right now, this will be called on most pages for a better user experience. We are also using it to
                    // determine counts of activity (daily, monthly, and total) to be uploaded to firebase and tracked in Mixedpanel.
                    // Overtime this will be much much more robost and better...
                    // The function calls the utilis function updateSignInDate() to handle doc updates if its a new date.
                    // TODO: In the future, when we want to make sure this can never be manipulated, we should be checking and updating,
                    // the Readonly collection and using a cloud function for this.
                    await this.checkNewDayAndSetDate(this.state.writableUserdata);
                })
            }
            else {
                this.logout();
            }
        })
    }

    async componentDidMount() {

        // Prepare first page of games
        const askAGamerDocsRef = doc(this.props.fbsFirestore, "AskAGamer", "Games");
        console.log ("Gamer Docs",  askAGamerDocsRef)
        const AskAGamerSnap = await getDoc( askAGamerDocsRef)
        if (AskAGamerSnap.exists()) {
            // Populate reviews, upvotes, etc.
            const gamesData = AskAGamerSnap.data();
            const games = gamesData.gameData;
            this.setState({ games })
        }

        // this.generateRandomQuestion();

        // *** Below is a loop that should only be uncommented if we want to add an Answerer.
        // *** Can be altered/used to Add an Answerer to the AnswererData Collection
        // *** Currently, this was just created to add some dummy accounts for the demo video.

        // for (let i = 2; i < 23; i++) {
        //     const addNewAnswerer = {
        //         answererBio: "I game hard",
        //         answererDateJoined: new Date(),
        //         answererGGNumber: i,
        //         answererName: "Jon",
        //         answererNoobNumber: 1,
        //         answererNumber: i,
        //         answererStatus: "God Gamer",
        //         answererTotal: i + 1,
        //         completedStripeOnboarding: false,
        //         currentAskerName: "",
        //         gameSpotlightTidbit1: "",
        //         gameSpotlightTidbit2: "",
        //         gameSpotlightTidbit3: "",
        //         gamesSpotlighted: {},
        //         knowledgeableIn: [],
        //         live: true,
        //         masteredGames:[],
        //         queue: [],
        //         totalChats: 156,
        //         venmoLastFour: 5191,
        //         venmoUsername: "BrianRomain",
        //     };

        //     const answererDocRef = doc(this.props.fbsFirestore, "AnswererData", `${i}`);

        //     try {
        //         // Attempt to update the document
        //         await setDoc(answererDocRef, addNewAnswerer, { merge: true });
        //         console.log(`Document ${i} set successfully!`);
        //     } catch (error) {
        //         // Handle any errors that may occur during the update
        //         console.error(`Error updating document ${i}:`, error);
        //     }
        // }

        // console.log("All documents updated successfully!");
    }

    setUser = setUser;
    logout = logout;

    // Called in onAuthStateChanged(), to determine if it's a new day.
    // It calls the utils function below and updates sign in dates/counts for days and months.
    // We are currently utilzing timestamps and having new day's based on the
    // the user's timezone, so that a "reset" of a new day is midnight for everyone.
    // We may update this overtime to just be based on a specific UTC timezone.
    // TODO: Handle State updates after
    async checkNewDayAndSetDate(writableData) {
        // If it's a new day for the user, call the utils function to update the fields
         await updateSignInDate(this.props.fbsFirestore, this.state.readonlyUserdata.userNumber);
    }

    // Return true if game matches the current filter(s) or nameIncludeFilter === ""
    passesFilters(game) {
       if (!game) return false;

       if (this.state.nameIncludeFilter === "") return true;
       if (this.state.nameIncludeFilter !== "" && game.gameName.toLowerCase().includes(this.state.nameIncludeFilter.toLowerCase()) ) return true;
    }

    // Changes the page
    handlePageChange(newPage) {
        this.setState({ currentPage: newPage });
    }

    // Checks for search input and updates page.
    handleSearchChange(e) {
        const searchValue = e.target.value;
        this.setState({ nameIncludeFilter: searchValue, currentPage: 1 });
    };

    // Animation after clicking Matchmake button - generates a random visiting profile render when finished.
    async gamerFoundAnimation() {
        this.setState({ preMatchmaking: false, searchingStarted: true, matchmakeStarted: false, showUserVisitingProfile: false});
        await this.fetchGamers();


        try {
            await new Promise((resolve) => {
                setTimeout(resolve, 3000);
            });
        } catch (error) {
            console.error(error);
        } finally {
            // After 3 seconds, show the Found Gamer animation.
            this.playAudio('/NotificationSound.mp3');
            this.setState({ preMatchmaking: false, searchingStarted: false, matchmakeStarted: true, showUserVisitingProfile: false });
        }

        try {
            await new Promise((resolve) => {
                setTimeout(resolve, 6000);
            });
        } catch (error) {
            console.error(error);
        } finally {
            // After 6 seconds, show the generated visiting profile and play a sound.
            // this.playAudio('/NotificationSound.mp3');
            this.setState({ preMatchmaking: false, searchingStarted: false, matchmakeStarted: false, showUserVisitingProfile: true });
        }

    }

    // Display based on passesFilters/handlePageChange/handleSearchChange functions,
    // and Pagination logic set at top of main render before the return statement.
    // The parameter "game" is mapped in the render from this.state.games.
    // this.state.liveData is an array of live gamers/answerers.
    // These are set in componentDidMount, and we check the gameNumber and gameNumbers
    // ... fields to map the gamer/answerer live for their mastered games.
    displayGame(game) {
        if (this.passesFilters(game)) {
            return (
                    <>
                        <div className="me-2 px-2 explore-games-background ask-a-gamer-explore-margin-right-0-mobile" >
                            <div
                            className="d-flex flex-column py-3 px-4 ask-a-gamer-game-list-border ask-a-gamer-explore-padding-left-right-halfem-mobile"
                            // disabled={this.state.flexibleOnGame}
                            onClick={() => {
                                this.setState({
                                    gameChoice: game.gameName,
                                    gameChoiceImage: game.gameImagePath,
                                    noGameSelected: false,
                                });
                            }}>
                                        <div className="d-flex justify-content-between align-items-center flex-wrap">
                                            <div
                                                className="d-inline-flex flex-row align-items-center justify-content-center flex-wrap ask-a-gamer-explore-flex-column-mobile"
                                                style={{"width":"100%"}}
                                            >
                                                <div className="d-flex flex-column align-items-center justify-content-center me-2 glow ask-a-gamer-explore-margin-right-left-0-tablet" >
                                                    <h5 className="my-2"> {game.gameName} </h5>
                                                    <img
                                                        src={game.gameImagePath}
                                                        width="250"
                                                        alt="Video Game Cover Art"
                                                        style={{"width":"600px"}}
                                                        className="img-fluid ask-a-gamer-explore-image-tablet"
                                                    />
                                                    <h5 className="my-2">LFG</h5>
                                                </div>
                                            </div>
                                        </div>
                            </div> 
                        </div>
                        <hr className="ask-a-gamer-answer-spotlight-hr"/>
                    </>
            )
        } else return null;
    }

    // Checks date/time to see if the user already voted for answerer today
    checkDate(voteDate) {
        const firebaseDate = (firebaseDateValue) => {
            if (firebaseDateValue)
                return firebaseDateValue.toDate();
            return undefined;
        }
        const isNowSameDayAsDate = (givenDate) => {
            const currentDate = new Date();
            return givenDate &&
                (givenDate.getUTCDate() === currentDate.getUTCDate()
                 && givenDate.getUTCMonth() === currentDate.getUTCMonth()
                 && givenDate.getUTCFullYear() === currentDate.getUTCFullYear());
        }
        const dateData = voteDate
        const lastDate = firebaseDate(dateData);
        const isToday = isNowSameDayAsDate(lastDate);
        this.setState({ alreadyVoted: isToday})
    }
    
    // Updates gg/noob vote in the askers PublicReadonlyPrivateWritable doc,
    // and updates the gg/noob amount for the answerer. Asker can
    // increment GG/Noob once a day per answerer - this is determined by
    // this.state.alreadyVoted which is set in onAuthStateChanged() via the checkDate() function.
    // Currently, a user can only change their vote (gg to noob or noob to gg), they can not
    // fully "un-do" a vote as if they never voted that day.
    async updateGGScore(gamer) {
        console.log("Already Voted at start:", this.state.alreadyVoted);
    
        // If the asker has never voted/given a gg or noob ever then we
        // create the ggOrNoobGiven field array for the first time with
        // the answerer data.
        if (this.state.writableUserdata.ggOrNoobGiven === undefined) {
            const ggOrNoobGivenUpdateField = [{
                userNumber: this.state.gamer.userNumber,
                date: new Date(),
                gg: true,
                noob: false,
            }];
            const ggorNoobDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
            await updateDoc(ggorNoobDocRef, {
                ggOrNoobGiven: ggOrNoobGivenUpdateField,
            });
    
            this.setState(prevState => ({
                writableUserdata: {
                    ...prevState.writableUserdata,
                    ggOrNoobGiven: ggOrNoobGivenUpdateField
                },
                displayGG: true,
                displayNoob: false,
                alreadyVoted: true,
            }));
            return;
        }
    
        if (this.state.displayGG) {
            // Add logic here later if we want for users to undo their vote
            // without having to vote the opposite.
            return;
        }
    
        try {
            if (this.state.alreadyVoted) {
                // Do not increment total votes, just reverse the current vote for today
                const ggOrNoobGivenField = this.state.writableUserdata.ggOrNoobGiven
                const updateGG = ggOrNoobGivenField.find(v => v.userNumber === this.state.gamer.userNumber);
                if (updateGG) {
                    // Filter and update only the unread messages from the recipientUser
                    const ggOrNoobGivenUpdateField = ggOrNoobGivenField.map((answerer) => {
                        if (answerer.userNumber === this.state.gamer.userNumber) {
                            return { ...answerer,
                                gg: true,
                                noob: false,
                            };
                        }
                        return answerer;
                    });
                    const ggOrNoobDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
                    await updateDoc(ggOrNoobDocRef, {
                        ggOrNoobGiven: ggOrNoobGivenUpdateField,
                    });
                    const answererDocRef = doc(this.props.fbsFirestore, "AnswererData", `${this.state.gamer.userNumber}`);
                    await updateDoc(answererDocRef, {
                        answererGGNumber: increment(1),
                        answererNoobNumber: increment(-1),
                    });
    
                    this.setState(prevState => ({
                        writableUserdata: {
                            ...prevState.writableUserdata,
                            ggOrNoobGiven: ggOrNoobGivenUpdateField
                        },
                        displayGG: true,
                        displayNoob: false,
                        alreadyVoted: true,
                    }));
                }
            } else {
                const ggOrNoobGivenField = this.state.writableUserdata.ggOrNoobGiven
                const updateGG = ggOrNoobGivenField.find(v => v.userNumber === this.state.gamer.userNumber);
                if (updateGG) {
                    // Filter to update only the object for the specific answerer
                    const ggOrNoobGivenUpdateField = ggOrNoobGivenField.map((answerer) => {
                        if (answerer.userNumber === this.state.gamer.userNumber) {
                            return { ...answerer,
                                date: new Date(),
                                gg: true,
                                noob: false,
                            };
                        }
                        return answerer;
                    });
                    const ggorNoobDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
                    await updateDoc(ggorNoobDocRef, {
                        ggOrNoobGiven: ggOrNoobGivenUpdateField,
                    });
                    const answererDocRef = doc(this.props.fbsFirestore, "AnswererData", `${this.state.gamer.userNumber}`);
                    await updateDoc(answererDocRef, {
                        answererGGNumber: increment(1),
                        answererTotal: increment(1),
                    });
    
                    this.setState(prevState => ({
                        writableUserdata: {
                            ...prevState.writableUserdata,
                            ggOrNoobGiven: ggOrNoobGivenUpdateField
                        },
                        displayGG: true,
                        displayNoob: false,
                        alreadyVoted: true,
                    }));
                } else {
                    const ggOrNoobDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
                    const addAnswerer = {
                        userNumber: this.state.gamer.userNumber,
                        date: new Date(),
                        gg: true,
                        noob: false,
                    }
                    await updateDoc (ggOrNoobDocRef, {
                        ggOrNoobGiven: arrayUnion(addAnswerer)
                    });
                    const answererDocRef = doc(this.props.fbsFirestore, "AnswererData", `${this.state.gamer.userNumber}`);
                    await updateDoc(answererDocRef, {
                        answererGGNumber: increment(1),
                        answererTotal: increment(1),
                    });
    
                    this.setState(prevState => ({
                        writableUserdata: {
                            ...prevState.writableUserdata,
                            ggOrNoobGiven: addAnswerer
                        },
                        displayGG: true,
                        displayNoob: false,
                        alreadyVoted: true,
                    }));
                }
            }
        } catch (error) {console.log(error)};
    }
    
    // Updates gg/noob vote in the askers PublicReadonlyPrivateWritable doc,
    // and updates the gg/noob amount for the answerer. Asker can
    // increment GG/Noob once a day per answerer - this is determined by
    // this.state.alreadyVoted which is set in onAuthStateChanged() via the checkDate() function.
    // See comments above updateGGScore for the TODO for this function and updateGGScore.
    async updateNoobScore(gamer) {
        console.log("Already Voted at start:", this.state.alreadyVoted);
    
        // If the asker has never voted/given a gg or noob ever then we
        // create the ggOrNoobGiven field array for the first time with
        // the answerer data.
        if (this.state.writableUserdata.ggOrNoobGiven === undefined) {
            const ggOrNoobGivenUpdateField = [{
                userNumber: this.state.gamer.userNumber,
                date: new Date(),
                gg: false,
                noob: true,
            }];
            const ggorNoobDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
            await updateDoc(ggorNoobDocRef, {
                ggOrNoobGiven: ggOrNoobGivenUpdateField,
            });
    
            this.setState(prevState => ({
                writableUserdata: {
                    ...prevState.writableUserdata,
                    ggOrNoobGiven: ggOrNoobGivenUpdateField
                },
                displayGG: false,
                displayNoob: true,
                alreadyVoted: true,
            }));
            return;
        }
    
        if (this.state.displayNoob) {
            // Add logic here later if we want for users to undo their vote
            // without having to vote the opposite.
            return;
        }
    
        try {
            if (this.state.alreadyVoted) {
                // Do not increment total votes, just reverse the current vote for today
                const ggOrNoobGivenField = this.state.writableUserdata.ggOrNoobGiven
                const updateNoob = ggOrNoobGivenField.find(v => v.userNumber === this.state.gamer.userNumber);
                if (updateNoob) {
                    // Filter and update only the unread messages from the recipientUser
                    const ggOrNoobGivenUpdateField = ggOrNoobGivenField.map((answerer) => {
                        if (answerer.userNumber === this.state.gamer.userNumber) {
                            return { ...answerer,
                                gg: false,
                                noob: true,
                            };
                        }
                        return answerer;
                    });
                    const ggOrNoobDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
                    await updateDoc(ggOrNoobDocRef, {
                        ggOrNoobGiven: ggOrNoobGivenUpdateField,
                    });
                    const answererDocRef = doc(this.props.fbsFirestore, "AnswererData", `${this.state.gamer.userNumber}`);
                    await updateDoc(answererDocRef, {
                        answererNoobNumber: increment(1),
                        answererGGNumber: increment(-1),
                    });
    
                    this.setState(prevState => ({
                        writableUserdata: {
                            ...prevState.writableUserdata,
                            ggOrNoobGiven: ggOrNoobGivenUpdateField
                        },
                        displayNoob: true,
                        displayGG: false,
                        alreadyVoted: true,
                    }));
                }
            } else {
                const ggOrNoobGivenField = this.state.writableUserdata.ggOrNoobGiven
                const updateNoob = ggOrNoobGivenField.find(v => v.userNumber === this.state.gamer.userNumber);
                if (updateNoob) {
                    // Filter to update only the object for the specific answerer
                    const ggOrNoobGivenUpdateField = ggOrNoobGivenField.map((answerer) => {
                        if (answerer.userNumber === this.state.gamer.userNumber) {
                            return { ...answerer,
                                date: new Date(),
                                gg: false,
                                noob: true,
                            };
                        }
                        return answerer;
                    });
                    const ggOrNoobDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
                    await updateDoc(ggOrNoobDocRef, {
                        ggOrNoobGiven: ggOrNoobGivenUpdateField,
                    });
                    const answererDocRef = doc(this.props.fbsFirestore, "AnswererData", `${this.state.gamer.userNumber}`);
                    await updateDoc(answererDocRef, {
                        answererNoobNumber: increment(1),
                        answererTotal: increment(1),
                    });
    
                    this.setState(prevState => ({
                        writableUserdata: {
                            ...prevState.writableUserdata,
                            ggOrNoobGiven: ggOrNoobGivenUpdateField
                        },
                        displayNoob: true,
                        displayGG: false,
                        alreadyVoted: true,
                    }));
                } else {
                    const ggOrNoobDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
                    const addAnswerer = {
                        userNumber: this.state.gamer.userNumber,
                        date: new Date(),
                        gg: false,
                        noob: true,
                    }
                    await updateDoc (ggOrNoobDocRef, {
                        ggOrNoobGiven: arrayUnion(addAnswerer)
                    });
                    const answererDocRef = doc(this.props.fbsFirestore, "AnswererData", `${this.state.gamer.userNumber}`);
                    await updateDoc(answererDocRef, {
                        answererNoobNumber: increment(1),
                        answererTotal: increment(1),
                    });
    
                    this.setState(prevState => ({
                        writableUserdata: {
                            ...prevState.writableUserdata,
                            ggOrNoobGiven: addAnswerer
                        },
                        displayNoob: true,
                        displayGG: false,
                        alreadyVoted: true,
                    }));
                }
            }
        } catch (error) {console.log(error)};
    }
    
    // Adds the user to the visiting user's contacts (if they aren't already added) so they can message them
    // Then sends the user to InboxPage so that they can message them..
    // TODO: update url param to actually open the message for the user.
    async addUserToContacts(gamer) {
        try {
            if(this.state.hiddenWritableUserdata.contacts.includes(gamer.username)) {
                window.location.href = `/gamerlounge#personal-contacts`;
            }
            else {
                const contactsDocRef =  doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0");
                await updateDoc(contactsDocRef, { contacts: arrayUnion(gamer.username) });
                window.location.href = `/gamerlounge#personal-contacts`;
            }
        } catch (error) {
            console.error("Error Navigating to Personal Messages:", error);
        }
    }
    
    // Adds or removes the user from the visiting user's friends, depending on if they are already friends.
    async addOrRemoveAsFriend(gamer) {
        try {
            const friendssDocRef =  doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
            // Remove targetd user from visting user friend's if they are already friends
            if(this.state.writableUserdata.friends.includes(gamer.username)) {
                await updateDoc(friendssDocRef, { friends: arrayRemove(gamer.username) });
                // Update friends state
                const updatedFriends = this.state.writableUserdata.friends.filter((friend) => friend !== gamer.username);
                this.setState({
                    writableUserdata: {
                        ...this.state.writableUserdata,
                        friends: updatedFriends
                    },
                    isFriend: false,
                });
            }
            // Else add the targetd user to visting user friend's and contacts.
            else {
                await updateDoc(friendssDocRef, { friends: arrayUnion(gamer.username) });
                // Update friends state
                const addedFriend = this.state.writableUserdata.friends.concat(gamer.username);
                this.setState({
                    writableUserdata: {
                        ...this.state.writableUserdata,
                        friends: addedFriend
                    },
                    isFriend: true,
                });
                const contactsDocRef =  doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0");
                await updateDoc(contactsDocRef, { contacts: arrayUnion(gamer.username) });
            }
        } catch (error) {
            console.error("Error Adding or Removing Friend:", error);
        }  
    }

    // Used in render for displaying GG percentage, passed to percentageColor function below.
    calculatePercentage(GG, Noob) {
        if (GG + Noob === 0) {
            return null; // If there are no votes, return null
        }
        // Calculates percentage and rounds to a whole number
        const percentage = (GG / (GG + Noob)) * 100;
        return Math.round(percentage);
    }

    // Return a CSS class that sets the color based on the GG percentage.
    percentageColor(GG, Noob) {
        const percentage = this.calculatePercentage(GG, Noob);
        if (percentage === null) {
            return '';
        } else if (percentage > 79) {
            return 'game-percentage-green';
        } else if (percentage >= 50) {
            return 'game-percentage-white';
        } else {
            return 'game-percentage-red';
        }
    }

    // Called onCLick of certain actions that plays an mp3 file from the 'Public' folder.
    // Currently set to 30% volume for all files, but can be adjusted to be higher/per file accordingly.
    // Passed down as props to children components as well.
    // TODO (eventually - not a priority): Add a "Adjust Sound Effect Volume" option in Settings,
    // so that users can set audio.volume globally however they'd like.
    playAudio(source) {
        const audio = new Audio(source);
        audio.volume = 0.3;
        audio.play();
    };

    // Looks for %0A URL encoded line breaks and links.
    // Handles reformatting text to wrap links in <a> tags, and
    // shows the user visual spaces with <br /> if they pressed enter or
    // shift+enter as represented by "\n".
    renderFormattedText = (text) => {
        const linkRegex = /(?:https?|ftp):\/\/[^\s]+(?:\s|$)/g;
        const lines = text.split(/(?:\n|%0A)/);
      
        const elements = [];
      
        lines.forEach((line, lineIndex) => {
            const linkMatches = line.match(linkRegex);
            if (linkMatches) {
                let lastIndex = 0;

                linkMatches.forEach((linkMatch, index) => {
                    const linkStart = line.indexOf(linkMatch, lastIndex);
                    const linkEnd = linkStart + linkMatch.length;

                    // Adds the preceding text as regular text
                    elements.push(<span key={`text-${lineIndex}-${index}`}>{line.substring(lastIndex, linkStart)}</span>);

                    // Wraps the link in an anchor
                    elements.push(
                        <a
                            key={`link-${lineIndex}-${index}`}
                            className="change-link-color-chat"
                            style={{
                                wordWrap: 'break-word',
                                wordBreak: 'break-all',
                                whiteSpace: 'normal',
                            }}
                            href={decodeURIComponent(linkMatch)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                        {decodeURIComponent(linkMatch)}
                        </a>
                    );

                    // Updates the lastIndex to the end of the current link
                    lastIndex = linkEnd;
                });
            
                // Adds the remaining text after the last link
                elements.push(<span key={`text-${lineIndex}-last`}>{line.substring(lastIndex)}</span>);
            } else {
                // If no links, add the entire line as regular text
                elements.push(<span key={`text-${lineIndex}`}>{line}</span>);
            }

            // Add the line break after each line (except the last line)
            if (lineIndex < lines.length - 1) {
                elements.push(<br key={`br-${lineIndex}`} />);
            }
        });
      
        return elements;
    };

    async fetchGamers() {
        const gamers = await findGamers(this.props.fbsFirestore, this.state, this.state.readonlyUserdata.userNumber);
        if (gamers.length === 0) {
            this.setState({ noGamersFound: true });
        } else {
            this.setState({
                gamers: gamers.map(gamer => {
                    let nonMatchProfile = {};
    
                    if (gamer.nonMatchDetails) {
                        Object.keys(gamer.matchDetails).forEach(key => {
                            if (!gamer.matchDetails[key]) {
                                nonMatchProfile[`noMatch${key.charAt(0).toUpperCase() + key.slice(1)}`] = true;
                            }
                        });
                    }
    
                    return { ...gamer, nonMatchProfile };
                }), 
                noGamersFound: false
            });
        }
    }

    showNextGamer() {
        this.setState((prevState) => ({
            currentGamerIndex: (prevState.currentGamerIndex + 1) % prevState.gamers.length
        }));
    }

    showPreviousGamer() {
        this.setState((prevState) => ({
            currentGamerIndex: (prevState.currentGamerIndex - 1 + prevState.gamers.length) % prevState.gamers.length
        }));
    }

    // displayGamer(gamer) {
    //     return (
    //         <div key={gamer.userNumber}>
    //             <h3>{gamer.username}</h3>
    //             <p>Matches: {gamer.matchCount}</p>
    //             {Object.keys(gamer.matchDetails).map((key) => (
    //                 <p key={key} style={{ color: gamer.matchDetails[key] ? 'lightgreen' : 'lightcoral' }}>
    //                     {key}: {gamer.matchDetails[key] ? 'Matched' : `Not Matched (${gamer.nonMatchDetails[key]})`}
    //                 </p>
    //             ))}
    //         </div>
    //     );
    // }
    
    displayGamer() {
        const { currentGamerIndex } = this.state;
        // const matchDetails = gamer.matchDetails || {};
        // const nonMatchDetails = gamer.nonMatchDetails || {};

        console.log("needed, platform, game", this.state.neededChoice, this.state.platformChoice, this.state.gameChoice)
        // TODO: WIP - not sure what to do with GG and Noobs currently.
        // Check if the user has given a Noob or GG to the answerer today
        // if (this.state.writableUserdata.ggOrNoobGiven) {
        //     const vote = this.state.writableUserdata.ggOrNoobGiven.find(v => v.userNumber === gamer.userNumber);
        //     if (vote) {
        //         this.checkDate(vote.date);
        //         this.setState(prevState => {
        //             if (prevState.alreadyVoted) {
        //                 console.log("Already Voted", prevState.alreadyVoted);
        //                 if (vote.noob) {
        //                     console.log("Voted Noob");
        //                     return { displayNoob: true, displayGG: false };
        //                 } else if (vote.gg) {
        //                     console.log("Voted GG");
        //                     return { displayGG: true, displayNoob: false };
        //                 }
        //             }
        //             return null;
        //         });
        //     }
        // }
        const gamer = this.state.gamers[currentGamerIndex]
        if(gamer) {
        return (
            <Container
                key={gamer.userNumber}
                className={this.state.fadeOutAnimation ? "d-flex flex-column fluid m-1 p-5 page-fade-out-animation" : "d-flex flex-column fluid m-1 p-5 page-fade-animation remove-padding-medium-container remove-padding-mobile padding-1ish-tablet"}
                style={{"padding-bottom":"1em","padding-top":"1em", "overflow-x":"hidden"}}
            >
                <Row className="pl-1 justify-content-center fluid home-assets-margin-left-mobile home-parent-container-center-mobile">
                    <Col
                        className="d-flex fluid flex-column align-items-center shadow col-3 fs-5 fw-bold pb-4 sidebar-width-large home-sidebar-row-mobile"
                        style={{ "height":"1100px" }}
                    >
                        <Stack
                            gap={3}
                            className="d-flex fluid flex-column align-items-center"
                            style={{ "justify-content":"flex-start" }}
                        >
                            <div className="p-2 mt-2">
                                <Image
                                    className="shadow home-sidebar-image-mobile page-fade-animation"
                                    src="/ProfilePicturePlaceholder.png"
                                    alt="Profile Picture"
                                    rounded
                                    fluid
                                />
                            </div>
                            <div className="d-flex flex-column align-items-center font-size-75-tablet">
                                <div
                                    style={{"font-size":"1.2em", "text-align":"center"}}
                                    className="px-2 pb-4"
                                >
                                        Filters Matched
                                        <br/>
                                        <span style={{"color":"green"}}>{gamer.matchCount}</span> out of 18
                                    </div>
                                </div>
                            { this.state.writableUserdata.friends.includes(gamer.username) &&
                                <div
                                    style={{"margin-bottom":"-.75em", "margin-top":"-1.5em", "font-size":".8em"}}
                                    className="d-flex flex-column align-items-center font-size-75-tablet"
                                >
                                    <div
                                        style={{"color":"green", "font-size":"1.1em"}}
                                        className="px-2 pb-4"
                                    >
                                        My friend 😊
                                    </div>
                                </div>
                            }
                            <div className="d-flex flex-column align-items-center font-size-75-tablet">
                                <div
                                    style={{"color":"#f3df92", "font-size":"1.1em", "margin-top":"-1em"}}
                                    className="px-2 pb-4"
                                >
                                    {gamer.username}
                                </div>
                                { gamer.inTheUniversityProgram && this.state.readonlyUserdata.inTheUniversityProgram && gamer.realName && gamer.realName !== "" &&
                                    <div
                                        style={{"color":"#ffffff", "font-size":".85em", "font-style":"italic", "font-weight":"500", "margin-top":"-1.25em", "margin-bottom":"1em"}}
                                        className="px-2 pb-2"
                                    >
                                        ({gamer.realName})
                                    </div>
                                }
                                { gamer.inTheUniversityProgram &&
                                    <div
                                        style={{"color":"#ffb865", "font-size":".9em", "text-wrap":"balance", "margin-top":"-.5em", "margin-left":".85em"}}
                                        className="px-2 py-1"
                                    >
                                        {gamer.universityName} <ShieldCheck style={{"color":"white", "margin-left":".25em", "margin-bottom":".2em", "margin-right":".5em", "align-items":"center", "height":"15px", "width":"auto"}}/>
                                    </div>
                                }
                                <div
                                    style={{"font-size":".9em", "text-shadow":"0 -1px 2px #2c2626", "color":"#c3c2c2"}}
                                    className="px-2"
                                >
                                    <span>User - # </span>
                                        <span style={{"font-weight":"800"}}>
                                            {gamer.userNumber}
                                     </span>
                                </div>
                                <hr
                                    className="ask-a-gamer-answer-spotlight-hr"
                                    style={{"width":"100%"}}
                                />
                                <div
                                    style={{"font-size":".9em"}}
                                    className="p-2"
                                >
                                    <span>Status: </span>
                                    { !gamer.status
                                      ?
                                        <span style={{"font-style":"oblique"}}>
                                            Not Set
                                        </span>
                                      :
                                        <span className="animate-ask-a-gamer-status">
                                            {gamer.status}
                                        </span>
                                    }
                                </div>
                                <div
                                    className="pb-3"
                                    style={{"font-size":".9em"}}
                                >
                                    <span>Clan: </span>
                                    { !gamer.clan
                                      ?
                                        <span style={{"font-style":"oblique"}}>
                                            Not set
                                        </span>
                                      :
                                        <span style={{"font-style":"oblique"}}>
                                            {this.renderFormattedText(gamer.clan)}
                                        </span>
                                    }
                                </div>
                                <div
                                    style={{"margin-top":".75em"}}
                                    className="d-flex flex-row align-items-center justify-content-between p-2 gg-noob-button-column-tablet"
                                >
                                    <div className="ask-a-gamer-gamepad margin-bottom-1-tablet">
                                        <div className="gamepad-up center-and-flex-important">
                                            <Button
                                                className={this.state.displayGG ? "gamepad-button gamepad-border-up-voted" : "gamepad-button gamepad-border-up"}
                                                disabled={this.state.displayGG}
                                                onClick={async () => {
                                                    await this.updateGGScore();
                                                }}
                                            />
                                        </div>
                                        <div className="gamepad-down center-and-flex-important">
                                            <Button
                                                className={this.state.displayNoob ? "gamepad-button gamepad-border-down-voted" :  "gamepad-button gamepad-border-down"}
                                                disabled={this.state.displayNoob}
                                                onClick={async () => {
                                                    await this.updateNoobScore();
                                                }}
                                            />
                                        </div>
                                        <div className="gamepad-left center-and-flex-important">
                                            <Button
                                                disabled
                                                className="gamepad-button gamepad-border-left"
                                            />
                                        </div>
                                        <div className="gamepad-right center-and-flex-important">
                                            <Button
                                                disabled
                                                className="gamepad-button gamepad-border-right"
                                            />
                                        </div>
                                    </div>
                                    { gamer
                                      ?
                                        <div className="ms-3 remove-margin-left-tablet">
                                            <p
                                                style={{"color":"#5ded5ce8", "margin":"0", "font-size":".9em"}}
                                                className="mx-2 pb-2 text-center"
                                            >
                                                GG's: {gamer.ggTotal ? gamer.ggTotal : 0}
                                            </p>
                                            { this.state.alreadyVoted
                                              ?
                                                <>
                                                    { this.state.displayGG
                                                      ?
                                                        <p
                                                            style={{"color":"#9af199e6", "margin":"0", "font-size":".7em"}}
                                                            className="gg-or-noob-voted-display"
                                                        >
                                                            GG Given <span style={{"font-size":"1.3em"}}>&#128525;</span>
                                                        </p>
                                                      :
                                                        <p
                                                            style={{"color":"#ffbcbcd9", "margin":"0", "font-size":".7em"}}
                                                            className="gg-or-noob-voted-display"
                                                        >
                                                            Noob Given <span style={{"font-size":"1.3em"}}>&#128546;</span>
                                                        </p>
                                                    }
                                                </>
                                              :
                                                <p
                                                    style={{"color":"#d9d7d7e8", "margin":"0", "font-size":".7em"}}
                                                    className="ms-2 me-2 pb-2 text-start"
                                                >
                                                    <span className="display-remove-tablet">&larr;</span> Vote!
                                                    { gamer.ggTotal && gamer.ggTotal > 0
                                                      ?
                                                        <span className={`${this.percentageColor(gamer.ggTotal, gamer.noobTotal)}`}>
                                                             &nbsp;({this.calculatePercentage(gamer.ggTotal, gamer.noobTotal)})%
                                                        </span>
                                                      : null
                                                    }
                                                </p>
                                            }
                                            <p
                                                style={{"color":"#ed5c5ce8", "margin":"0", "font-size":".9em"}}
                                                className="mx-2 text-center"
                                            >
                                                Noob's: {gamer.noobTotal ? gamer.noobTotal : 0}
                                            </p>
                                        </div>
                                      : null
                                    }
                                </div>
                            </div>
                                <div
                                    className="d-flex flex-row align-items-center justify-content-center font-size-75-tablet"
                                    style={{"margin-right":".3em"}}
                                >
                                    <Button
                                        className="d-flex flex-column mx-1 p-1 align-items-center chat-with-gamer-button"
                                        style={{"width":"300px", "box-shadow":"#fffzfff82 0px 0px 10px 3px", "font-size":"1em", "font-weight":"550", "text-shadow":"black 0px 2px 2px", "background-color":"#979797c4", "text-wrap":"balance"}}
                                        onClick={() => {
                                            window.location.href = '/gamermatchmaker';
                                        }}
                                    >
                                        Change Matchmaking
                                    </Button>
                                </div>
                                <div
                                    style={{"font-size":".9em"}}
                                    className="d-flex flex-column align-items-center justify-content-center mt-3"
                                >
                                    <div
                                        style={{"font-size":".9em"}}
                                        className="d-flex flex-row align-items-center justify-content-between mt-1"
                                    >
                                    {/* Display different options if user is visiting from LFG or their Social Page */}
                                        { this.state.writableUserdata.friends.includes(gamer.username)
                                          ?
                                            <>
                                                <div
                                                    style={{"font-size":".8em"}}
                                                    className="d-flex flex-row align-items-center justify-content-between"
                                                >
                                                    <Button
                                                        className="d-flex flex-column mx-2 p-2 align-items-center change-padding-tablet font-size-75-tablet width-auto-tablet"
                                                        style={{"background-color":"#ff50509e", "width":"145px", "text-wrap":"balance", "font-weight":"550", "font-size":"1.2em"}}
                                                        disabled={this.state.cooldown}
                                                        onClick={async () => {
                                                            await this.addOrRemoveAsFriend(gamer)
                                                        }}
                                                    >
                                                        Remove Friend
                                                    </Button>
                                                </div>
                                                <div
                                                    style={{"font-size":".8em", "margin-left":".5em"}}
                                                    className="d-flex flex-row align-items-center justify-content-center font-size-75-tablet"
                                                >
                                                    <Button
                                                        className="d-flex flex-column mx-2 p-2 align-items-center change-padding-tablet font-size-75-tablet width-auto-tablet"
                                                        style={{"background-color":"#1616169e", "width":"145px", "text-wrap":"balance", "font-weight":"550", "font-size":"1.2em"}}
                                                        onClick={async () => {
                                                            await this.addUserToContacts(gamer);
                                                        }}
                                                    >
                                                        Send Message
                                                    </Button>
                                                </div>
                                            </>
                                          :
                                            <>
                                                <div
                                                    style={{"font-size":".8em"}}
                                                    className="d-flex flex-row align-items-center justify-content-between"
                                                >
                                                    <Button
                                                        className="d-flex flex-column mx-2 p-2 align-items-center change-padding-tablet font-size-75-tablet width-auto-tablet"
                                                        style={{"background-color":"#1616169e", "width":"145px", "text-wrap":"balance", "font-weight":"550", "font-size":"1.2em"}}
                                                        disabled={this.state.cooldown}
                                                        onClick={async () => {
                                                            await this.addOrRemoveAsFriend(gamer)
                                                        }}
                                                    >
                                                        Add Friend
                                                    </Button>
                                                </div>
                                                <div
                                                    style={{"font-size":".8em", "margin-left":".5em"}}
                                                    className="d-flex flex-row align-items-center justify-content-center font-size-75-tablet"
                                                >
                                                    <Button
                                                        className="d-flex flex-column mx-2 p-2 align-items-center change-padding-tablet font-size-75-tablet width-auto-tablet"
                                                        style={{"background-color":"#1616169e", "width":"145px", "text-wrap":"balance", "font-weight":"550", "font-size":"1.2em"}}
                                                        onClick={async () => {
                                                            await this.addUserToContacts(gamer);
                                                        }}
                                                    >
                                                        Send Message
                                                    </Button>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div
                                        style={{"font-size":".9em"}}
                                        className="d-flex flex-row align-items-center justify-content-between mt-3"
                                    >
                                        <div
                                            className="d-flex flex-row align-items-center justify-content-center font-size-75-tablet"
                                            style={{"margin-right":".3em"}}
                                        >
                                            <Button
                                                className={ gamer[currentGamerIndex] === 0 ? "display-remove" : "d-flex flex-column mx-1 p-1 align-items-center chat-with-gamer-button"}
                                                style={{"width":"150px", "box-shadow":"#fffzfff82 0px 0px 10px 3px", "font-size":"1em", "font-weight":"550", "text-shadow":"black 0px 2px 2px", "background-color":"#979797c4"}}
                                                disabled={this.state.gamers.length <= 1}
                                                onClick={async () => {
                                                    this.showPreviousGamer(gamer)
                                                }}
                                            >
                                                {this.state.gamers.length <= 1 ? "One Gamer Found" : "See Previous Gamer"}
                                            </Button>
                    
                                        </div>
                                        <div
                                            style={{"margin-left":".5em"}}
                                            className="d-flex flex-row align-items-center justify-content-center font-size-75-tablet"
                                        >
                                            <Button
                                                className={ (gamer[currentGamerIndex] === this.state.gamers.length || this.state.gamers.length <= 1) ? "display-remove" : "d-flex flex-column mx-1 p-1 align-items-center chat-with-gamer-button game-details-play"}
                                                style={{"width":"150px", "box-shadow":"#fffzfff82 0px 0px 10px 3px", "font-size":"1em", "font-weight":"550", "text-shadow":"black 0px 2px 2px"}}
                                                disabled={this.state.gamers.length <= 1}
                                                onClick={async () => {
                                                    this.playAudio('/FoundGamerSound.mp3');
                                                    this.showNextGamer(gamer)
                                                }}
                                            >
                                                Find Another Gamer
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                        </Stack>
                    </Col>
                    <Col
                        className="d-flex fluid flex-column col-9 fs-6 fw-bold mb-2 pb-4 pe-3 home-content-width-mobile"
                        style={{ "padding-left":"2em", "max-width":"1300px" }}
                    >
                        <GamerMatchmakerTabs
                            fbsAuth={this.props.fbsAuth}
                            fbsFirestore={this.props.fbsFirestore}
                            fbsStorage={this.props.fbsStorage}
                            user={this.state.user}
                            username={this.state.username}
                            userdata={{...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata}}
                            startChat={this.startChat}
                            endChat={this.endChat}
                            playAudio={this.playAudio}
                            chatStarted={this.state.chatStarted}
                            gamerIsLive={this.state.gamerIsLive}
                            queue={this.state.queue}
                            queueLength={this.state.queueLength}
                            updatedQueueLength={this.state.updatedQueueLength}
                            answererData={this.state.answererData}
                            messages={this.state.messages}
                            unreadCount={this.state.unreadCount}
                            handleTimeDelayOfPfp={this.handleTimeDelayOfPfp}
                            gamer={gamer}
                            currentGamerIndex={currentGamerIndex}
                            platformChoice={this.state.platformChoice}
                            neededChoice={this.state.neededChoice}
                            startChoice={this.state.startChoice}
                            objectiveChoice={this.state.objectiveChoice}
                            commsChoice={this.state.commsChoice}
                            sessionChoice={this.state.sessionChoice}
                            skillChoice={this.state.skillChoice}
                            ageChoice={this.state.ageChoice}
                            genderChoice={this.state.genderChoice}
                            locationChoice={this.state.locationChoice}
                            languageChoice={this.state.languageChoice}
                            personalityChoice={this.state.personalityChoice}
                            occupationChoice={this.state.occupationChoice}
                            educationChoice={this.state.educationChoice}
                            longtermChoice={this.state.longtermChoice}
                            gameChoice={this.state.gameChoice}
                            interestChoice={this.state.interestChoice}
                        />
                    </Col>
                </Row>
                <Footerfixed></Footerfixed>
            </Container>
        );
        }
    }


    render() {
        const out = [];

        // const { gamers, currentGamerIndex } = this.state;
        // const currentGamer = gamers[currentGamerIndex];

        //Pagination Logic - Can be moved outside render if we want.
        const startIndex = (this.state.currentPage - 1) * this.state.gamesPerPage;
        const endIndex = startIndex + this.state.gamesPerPage;
        // Sorts games alphabetically
        const sortedGames =  [...this.state.games].sort((a, b) => a.gameName.localeCompare(b.gameName));
        // Filters games based on search term.
        const filteredGames = sortedGames.filter((game) => this.passesFilters(game));
        // Displays games on the current page for pagination.
        const displayedGames = filteredGames.slice(startIndex, endIndex);
        // Calculates the total number of pages needed to display all the search results.
        const totalPages = Math.ceil(filteredGames.length / this.state.gamesPerPage);
        // Updates pagination.item (i.e. Total Page amount) to be dynamic based on search results.
        // *Update the second param, which is currently 5, to whatever when we have more games.
        // TODO: This works, but check logic to make sure it works under various circumstances.
        const pageNumbersToShow = Math.min(totalPages, 5);
        const middlePageNumber = Math.floor(pageNumbersToShow / 2);
        let firstPageNumber = Math.max(1, this.state.currentPage - middlePageNumber);
        const lastPageNumber = Math.min(totalPages, firstPageNumber + pageNumbersToShow - 1);
        // Adjusts firstPageNumber - it was disappearing when the last page didn't have 10 games for some reason.
        if (lastPageNumber === totalPages) {
          firstPageNumber = Math.max(1, lastPageNumber - pageNumbersToShow + 1);
        }

        // Navbar
        out.push(
            <BasicNavbar
                user={this.state.user}
                userdata={{...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata}}
                unreadCount={this.state.unreadCount}
                universityName={(this.state.readonlyUserdata && this.state.readonlyUserdata.inTheUniveristyProgram) ? this.state.readonlyUserdata.universityName : "University"}
            />
        )

        // Conditional Page Body - rendered after clicking "Matchmake" Button.
        if (this.state.searchingStarted)
            out.push(
                <div
                    className="vh-100 fluid d-flex flex-row flex-grow-1 justify-content-center align-items-center glow visting-home-page-fade-animation"
                    style={{"overflow-y":"hidden"}}
                >
                    <h2
                        className="d-flex flex-column align-items-center justify-content-center"
                        style={{"font-weight":"700", "font-size":"2em", "font-style":"italic", "text-align":"center", "margin-bottom":"7em"}}
                    >
                        <span
                            className="desktop-increase-demo-gamer-profile-text-size"
                            style={{"margin-bottom":"2em"}}
                        >
                            Searching
                        </span>
                        <div className="big-circle-inline-loading-animation-demo big-circle-inline-loading-animation-demo-desktop"/>
                    </h2>
                </div>
            );

        // Conditional Page Body - rendered after clicking "Matchmake" Button.
        if (this.state.noGamersFound)
            out.push(
                <div
                    className="vh-100 fluid d-flex flex-row flex-grow-1 justify-content-center align-items-center glow visting-home-page-fade-animation"
                    style={{"overflow-y":"hidden"}}
                >
                    <h2
                        className="d-flex flex-column align-items-center justify-content-center"
                        style={{"font-weight":"700", "font-size":"2em", "font-style":"italic", "text-align":"center", "margin-bottom":"7em"}}
                    >
                        <span
                            className="desktop-increase-demo-gamer-profile-text-size mx-1"
                            style={{"margin-bottom":"2em", "font-size":".8em", "color":"#ff8686"}}
                        >
                            No Gamers Found Matching Your Specifications.
                        </span>
                        <div
                            style={{"font-size":".9em"}}
                            className="d-flex mx-1 flex-column align-items-center justify-content-center"
                        >
                            <span
                                className="desktop-increase-demo-gamer-profile-text-size"
                                style={{"margin-bottom":"1em"}}
                            >
                                Try changing your filters and trying again.
                            </span>
                            <span
                                className="d-flex flex-row align-items-center justify-content-center desktop-increase-demo-gamer-profile-text-size"
                                style={{ "width": "100%", "font-size": ".9em" }}
                            >
                                <Button
                                    className="d-flex flex-column mx-2 p-2 fs-5 align-items-center change-padding-tablet font-size-75-tablet width-auto-tablet"
                                    style={{ "background-color": "#1d77ff", "font-weight": "600", "width": "245px" }}
                                    href="/gamermatchmaker"
                                >
                                    Change Filters
                                </Button>
                            </span>
                        </div>
                    </h2>
                </div>
            );

        // Conditional Page Body - rendered after clicking "Matchmake" Button.
        if (this.state.matchmakeStarted)
            out.push(
                <div className="home-page-onboarding-animation">
                    <span>G</span><span>A</span><span>M</span><span>E</span><span>R</span>
                    <span>&nbsp;</span>
                    <span>F</span><span>O</span><span>U</span><span>N</span><span>D</span>
                </div>
            );

        if (this.state.gamers && this.state.showUserVisitingProfile)
        // Display of matched Gamers.
        out.push(
            <>
                <div className="fluid overflow-hidden home-parent-container-mobile">
                    {this.displayGamer()}
                </div>
            </>
        )

        // Add logic for game required boolean and dispaly.
        // Standard Page Body
        if (this.state.preMatchmaking)
            out.push(
                <>
                    <div
                        className="body-content visting-home-page-fade-animation change-explore-body-content-small-screen explore-body-content-padding-mobile
                        explore-body-content-padding-large ask-a-gamer-explore-padding-left-right-1-mobile"
                    >
                        <div className="mt-2">
                                <div className="d-flex flex-column justify-content-between align-items-center game-details-flex-column-mobile pb-1">
                                    <div className="d-flex flex-column mt-3 justify-content-center align-items-center increases-matchmake-title-font-desktop">
                                        <h1 style={{"margin-bottom":"1em", "font-style":"oblique", "text-align":"center", "text-decoration":"underline", "text-shadow":"0 -1px 3px #000000eb"}}>
                                            Gamer Matchmaker
                                        </h1>
                                        <button
                                            disabled={this.state.matchmakeStarted}
                                            style={{"font-size":"1.5em"}}
                                            class='glowing-btn'
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Matchmake based on selected game and filters."
                                            onClick={async () => {
                                                this.playAudio('/FoundGamerSound.mp3');
                                                await this.gamerFoundAnimation();
                                                
                                            }}
                                        >
                                            <span class='glowing-txt'>
                                                FIND GAMERS
                                            </span>
                                        </button>
                                    </div>
                                    <h3
                                        className="px-2 pb-1 pt-3 text-center increase-demo-instruction-font-desktop make-width-auto-desktop desktop-increase-margin-top-6em"
                                        style={{"width":"350px", "margin-top":"4em", "font-size":".87em", "color":"#fdffd7"}}
                                    >
                                        <ul style={{"max-width":"920px", "text-align":"start", "padding-left":"1em"}}>
                                            <li style={{"margin-bottom":".5em"}}>
                                                Find and match with gamers on Indemni by utilizing the optional filters and searching for games via the search bar/dropdown menu. Any choice not explicity selected (including the game), will open up matchmaking to display all results for that specific filter i.e., if you left Location as "Any", you can be matched with gamers in the USA, Asia, Europe, etc.
                                            </li>
                                            <li style={{"margin-bottom":".5em"}}>
                                                By default, matchmaking priortizes the filters over the game you select. If you want to only find matches for a specific game, click "The Game Must Match."
                                            </li>
                                        </ul>
                                    </h3>
                                </div>
                            <div
                                className="mb-2 pe-3 w-100 ask-a-gamer-explore-margin-padding-right-left-0-mobile"
                                style={{"height":"95%"}}
                            >
                                <div className="h-100 w-95 p-2 ask-a-gamer-explore-margin-padding-right-left-0-mobile">
                                    {/* List of games */}
                                    <div className="between-and-flex-important flex-column ask-indemni-reduce-font-size-mobile ask-a-gamer-explore-flex-column-page-input-mobile">
                                        <div
                                            style={{"width":"100%"}}
                                            className="d-flex flex-row justify-content-start align-items-center"
                                        >
                                            <div
                                                className="d-flex mb-3 flex-row align-items-center justify-content-start ask-a-gamer-explore-margin-top-input-mobile hange-demo-input-div-container-align desktop-increse-font-1point1"
                                                style={{"width":"100%"}}
                                            >
                                                <h5
                                                    style={{"width":"100%", "text-decoration":"underline"}}
                                                    className="demo-page-increase-font-section-titles make-width-auto-desktop"
                                                >
                                                    Optional Filters
                                                </h5>
                                                <Button
                                                    style={{"background-color":"#7e7e7e1c"}}
                                                    className="ms-3 w-30 game-page-reset-filters-button-mobile reset-filters-button-display-desktop"
                                                    onClick={() => this.setState({
                                                        gameChoice: "Any",
                                                        neededChoice: "Any",
                                                        startChoice: "Any",
                                                        ageChoice: "Any",
                                                        platformChoice: "Any",
                                                        objectiveChoice: "Any",
                                                        commsChoice: "Any",
                                                        sessionChoice: "Any",
                                                        skillChoice: "Any",
                                                        ageChoice: "Any",
                                                        genderChoice: "Any",
                                                        locationChoice: "Any",
                                                        languageChoice: "Any",
                                                        personalityChoice: "Any",
                                                        occupationChoice: "Any",
                                                        educationChoice: "Any",
                                                        longtermChoice: "Any",
                                                        currentPage: 1,
                                                    })}
                                                >
                                                    Reset Filters
                                                </Button>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="flex-filters-demo-desktop">
                                        <div
                                            style={{"width":"40%", "font-size":".9em", "font-weight":"550"}}
                                            className="mb-2 d-flex flex-row justify-content-between align-items-center ask-a-gamer-explore-width-auto-mobile change-filters-div-width-desktop"
                                        >
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile"
                                                style={{"width":"50%"}}
                                            >
                                                <span
                                                    style={{"width":"100px"}}
                                                    className="ms-3"
                                                >
                                                    Platform
                                                </span>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Console means PS5 & Xbox, Other means anything not listed like older generation consoles"
                                                    style={{"font-size":".8em", "padding-left":".2em","padding-right":".2em"}}
                                                >
                                                    <Dropdown.Toggle 
                                                        style={{"width":"76px"}}
                                                        className="ms-3 w-30 demo-game-dropdown-button-mobile change-filter-button-width-desktop"
                                                    >
                                                        {this.state.platformChoice}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="change-filter-dropdown-menu-width-desktop" style={{"font-size":"1em", "text-align":"center", "min-width":"76px", "background":"#212121ed"}}>
                                                    { this.state.platformList.map((platform) => (
                                                        <Dropdown.Item
                                                            key={platform}
                                                            eventKey={platform}
                                                            onClick={() => {
                                                                this.setState({
                                                                    platformChoice: platform
                                                                });
                                                            }}
                                                        >
                                                            {platform}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile"
                                                style={{"width":"50%"}}
                                            >
                                                <span
                                                    style={{"width":"100px"}}
                                                    className="ms-3"
                                                >
                                                    Start Time
                                                </span>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="The 'Later' option means you'd like to game later, like in a few hours, or the weekend, etc."
                                                    style={{"font-size":".8em", "padding-left":".2em","padding-right":".2em"}}
                                                >
                                                    <Dropdown.Toggle 
                                                        style={{"width":"76px"}}
                                                        className="ms-3 w-30 demo-game-dropdown-button-mobile change-filter-button-width-desktop"
                                                    >
                                                        {this.state.startChoice}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="change-filter-dropdown-menu-width-desktop" style={{"font-size":"1em", "text-align":"center", "min-width":"76px", "background":"#212121ed"}}>
                                                    { this.state.startList.map((start) => (
                                                        <Dropdown.Item
                                                            key={start}
                                                            eventKey={start}
                                                            onClick={() => {
                                                                this.setState({
                                                                    startChoice: start
                                                                });
                                                            }}
                                                        >
                                                            {start}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div
                                            style={{"width":"40%", "font-size":".9em", "font-weight":"550"}}
                                            className="mb-2 d-flex flex-row justify-content-between align-items-center ask-a-gamer-explore-width-auto-mobile change-filters-div-width-desktop"
                                        >
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile"
                                                style={{"width":"50%"}}
                                            >
                                                <span
                                                    style={{"width":"100px"}}
                                                    className="ms-3"
                                                >
                                                    Needed
                                                </span>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="This is how people the gamer is looking to have join their team."
                                                    style={{"font-size":".8em", "padding-left":".2em","padding-right":".2em"}}
                                                >
                                                    <Dropdown.Toggle 
                                                        style={{"width":"76px"}}
                                                        className="ms-3 w-30 demo-game-dropdown-button-mobile change-filter-button-width-desktop"
                                                    >
                                                        {this.state.neededChoice}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="change-filter-dropdown-menu-width-desktop" style={{"font-size":"1em", "text-align":"center", "min-width":"76px", "background":"#212121ed"}}>
                                                    { this.state.neededList.map((needed) => (
                                                        <Dropdown.Item
                                                            key={needed}
                                                            eventKey={needed}
                                                            onClick={() => {
                                                                this.setState({
                                                                    neededChoice: needed
                                                                });
                                                            }}
                                                        >
                                                            {needed}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile"
                                                style={{"width":"50%"}}
                                            >
                                                <span
                                                    style={{"width":"100px"}}
                                                    className="ms-3"
                                                >
                                                    Objective
                                                </span>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="This filter is the objective of teaming up - casual means non-competitive modes, social means your looking to chat/find friends, etc."
                                                    style={{"font-size":".8em", "padding-left":".2em","padding-right":".2em"}}
                                                >
                                                    <Dropdown.Toggle 
                                                        style={{"width":"76px"}}
                                                        className="ms-3 w-30 demo-game-dropdown-button-mobile change-filter-button-width-desktop"
                                                    >
                                                        {this.state.objectiveChoice}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="change-filter-dropdown-menu-width-desktop" style={{"font-size":"1em", "text-align":"center", "min-width":"76px", "background":"#212121ed"}}>
                                                    { this.state.objectiveList.map((objective) => (
                                                        <Dropdown.Item
                                                            key={objective}
                                                            eventKey={objective}
                                                            onClick={() => {
                                                                this.setState({
                                                                    objectiveChoice: objective
                                                                });
                                                            }}
                                                        >
                                                            {objective}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-filters-demo-desktop">
                                        <div
                                            style={{"width":"40%", "font-size":".9em", "font-weight":"550"}}
                                            className="mb-2 d-flex flex-row justify-content-between align-items-center ask-a-gamer-explore-width-auto-mobile change-filters-div-width-desktop"
                                        >
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile"
                                                style={{"width":"50%"}}
                                            >
                                                <span
                                                    style={{"width":"100px"}}
                                                    className="ms-3"
                                                >
                                                    Comms
                                                </span>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="This filter implies what the required form of communication will be. We will add more options/functionality when we are live."
                                                    style={{"font-size":".8em", "padding-left":".2em","padding-right":".2em"}}
                                                >
                                                    <Dropdown.Toggle 
                                                        style={{"width":"76px"}}
                                                        className="ms-3 w-30 demo-game-dropdown-button-mobile change-filter-button-width-desktop"
                                                    >
                                                        {this.state.commsChoice}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="change-filter-dropdown-menu-width-desktop" style={{"font-size":"1em", "text-align":"center", "min-width":"76px", "background":"#212121ed"}}>
                                                    { this.state.commsList.map((comms) => (
                                                        <Dropdown.Item
                                                            key={comms}
                                                            eventKey={comms}
                                                            onClick={() => {
                                                                this.setState({
                                                                    commsChoice: comms
                                                                });
                                                            }}
                                                        >
                                                            {comms}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile"
                                                style={{"width":"50%"}}
                                            >
                                                <span
                                                    style={{"width":"100px"}}
                                                    className="ms-3"
                                                >
                                                    Session
                                                </span>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="This is how long you plan on gaming for when teaming up."
                                                    style={{"font-size":".8em", "padding-left":".2em","padding-right":".2em"}}
                                                >
                                                    <Dropdown.Toggle 
                                                        style={{"width":"76px"}}
                                                        className="ms-3 w-30 demo-game-dropdown-button-mobile change-filter-button-width-desktop"
                                                    >
                                                        {this.state.sessionChoice}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="change-filter-dropdown-menu-width-desktop" style={{"font-size":"1em", "text-align":"center", "min-width":"76px", "background":"#212121ed"}}>
                                                    { this.state.sessionList.map((session) => (
                                                        <Dropdown.Item
                                                            key={session}
                                                            eventKey={session}
                                                            onClick={() => {
                                                                this.setState({
                                                                    sessionChoice: session
                                                                });
                                                            }}
                                                        >
                                                            {session}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div
                                            style={{"width":"40%", "font-size":".9em", "font-weight":"550"}}
                                            className="mb-2 d-flex flex-row justify-content-between align-items-center ask-a-gamer-explore-width-auto-mobile change-filters-div-width-desktop"
                                        >
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile"
                                                style={{"width":"50%"}}
                                            >
                                                <span
                                                    style={{"width":"100px"}}
                                                    className="ms-3"
                                                >
                                                    Skill
                                                </span>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Many people rate their skill inaccurately, but this helps to narrow your search for someone closer to your own skill level."
                                                    style={{"font-size":".8em", "padding-left":".2em","padding-right":".2em"}}
                                                >
                                                    <Dropdown.Toggle 
                                                        style={{"width":"76px"}}
                                                        className="ms-3 w-30 demo-game-dropdown-button-mobile change-filter-button-width-desktop"
                                                    >
                                                        {this.state.skillChoice}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="change-filter-dropdown-menu-width-desktop" style={{"font-size":"1em", "text-align":"center", "min-width":"76px", "background":"#212121ed"}}>
                                                    { this.state.skillList.map((skill) => (
                                                        <Dropdown.Item
                                                            key={skill}
                                                            eventKey={skill}
                                                            onClick={() => {
                                                                this.setState({
                                                                    skillChoice: skill
                                                                });
                                                            }}
                                                        >
                                                            {skill}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile"
                                                style={{"width":"50%"}}
                                            >
                                                <span
                                                    style={{"width":"100px"}}
                                                    className="ms-3"
                                                >
                                                    Personality
                                                </span>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="This helps you find people with similar personalities to yourself or what you'd want in a teammate. We will allow more options in the future."
                                                    style={{"font-size":".8em", "padding-left":".2em","padding-right":".2em"}}
                                                >
                                                    <Dropdown.Toggle 
                                                        style={{"width":"76px"}}
                                                        className="ms-3 w-30 demo-game-dropdown-button-mobile change-filter-button-width-desktop"
                                                    >
                                                        {this.state.personalityChoice}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="change-filter-dropdown-menu-width-desktop" style={{"font-size":"1em", "text-align":"center", "min-width":"76px", "background":"#212121ed"}}>
                                                    { this.state.personalityList.map((personality) => (
                                                        <Dropdown.Item
                                                            key={personality}
                                                            eventKey={personality}
                                                            onClick={() => {
                                                                this.setState({
                                                                    personalityChoice: personality
                                                                });
                                                            }}
                                                        >
                                                            {personality}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-filters-demo-desktop">
                                        <div
                                            style={{"width":"40%", "font-size":".9em", "font-weight":"550"}}
                                            className="mb-2 d-flex flex-row justify-content-between align-items-center ask-a-gamer-explore-width-auto-mobile change-filters-div-width-desktop"
                                        >
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile"
                                                style={{"width":"50%"}}
                                            >
                                                <span
                                                    style={{"width":"100px"}}
                                                    className="ms-3"
                                                >
                                                    Age
                                                </span>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="We include this filter so that people can find teammates who are closer to their own age if they'd like."
                                                    style={{"font-size":".8em", "padding-left":".2em","padding-right":".2em"}}
                                                >
                                                    <Dropdown.Toggle 
                                                        style={{"width":"76px"}}
                                                        className="ms-3 w-30 demo-game-dropdown-button-mobile change-filter-button-width-desktop"
                                                    >
                                                        {this.state.ageChoice}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="change-filter-dropdown-menu-width-desktop" style={{"font-size":"1em", "text-align":"center", "min-width":"76px", "background":"#212121ed"}}>
                                                    { this.state.ageList.map((age) => (
                                                        <Dropdown.Item
                                                            key={age}
                                                            eventKey={age}
                                                            onClick={() => {
                                                                this.setState({
                                                                    ageChoice: age
                                                                });
                                                            }}
                                                        >
                                                            {age}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile"
                                                style={{"width":"50%"}}
                                            >
                                                <span
                                                    style={{"width":"100px"}}
                                                    className="ms-3"
                                                >
                                                    Gender
                                                </span>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="We include this filter because some people very much prefer to play with someone of their own gender based on previous poor experiences online."
                                                    style={{"font-size":".8em", "padding-left":".2em","padding-right":".2em"}}
                                                >
                                                    <Dropdown.Toggle 
                                                        style={{"width":"76px"}}
                                                        className="ms-3 w-30 demo-game-dropdown-button-mobile change-filter-button-width-desktop"
                                                    >
                                                        {this.state.genderChoice}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="change-filter-dropdown-menu-width-desktop" style={{"font-size":"1em", "text-align":"center", "min-width":"76px", "background":"#212121ed"}}>
                                                    { this.state.genderList.map((gender) => (
                                                        <Dropdown.Item
                                                            key={gender}
                                                            eventKey={gender}
                                                            onClick={() => {
                                                                this.setState({
                                                                    genderChoice: gender
                                                                });
                                                            }}
                                                        >
                                                            {gender}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div
                                            style={{"width":"40%", "font-size":".9em", "font-weight":"550"}}
                                            className="mb-2 d-flex flex-row justify-content-between align-items-center ask-a-gamer-explore-width-auto-mobile change-filters-div-width-desktop"
                                        >
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile"
                                                style={{"width":"50%"}}
                                            >
                                                <span
                                                    style={{"width":"100px"}}
                                                    className="ms-3"
                                                >
                                                    Location
                                                </span>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="This filter is where the gamer is currently located. We will expand this to more countries and time zones in the future."
                                                    style={{"font-size":".8em", "padding-left":".2em","padding-right":".2em"}}
                                                >
                                                    <Dropdown.Toggle 
                                                        style={{"width":"76px"}}
                                                        className="ms-3 w-30 demo-game-dropdown-button-mobile change-filter-button-width-desktop"
                                                    >
                                                        {this.state.locationChoice}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="change-filter-dropdown-menu-width-desktop" style={{"font-size":"1em", "text-align":"center", "min-width":"76px", "background":"#212121ed"}}>
                                                    { this.state.locationList.map((location) => (
                                                        <Dropdown.Item
                                                            key={location}
                                                            eventKey={location}
                                                            onClick={() => {
                                                                this.setState({
                                                                    locationChoice: location
                                                                });
                                                            }}
                                                        >
                                                            {location}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile"
                                                style={{"width":"50%"}}
                                            >
                                                <span
                                                    style={{"width":"100px"}}
                                                    className="ms-3"
                                                >
                                                    Language
                                                </span>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="This is the gamers preferred communication language. We will add more when we are live."
                                                    style={{"font-size":".8em", "padding-left":".2em","padding-right":".2em"}}
                                                >
                                                    <Dropdown.Toggle 
                                                        style={{"width":"76px"}}
                                                        className="ms-3 w-30 demo-game-dropdown-button-mobile change-filter-button-width-desktop"
                                                    >
                                                        {this.state.languageChoice}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="change-filter-dropdown-menu-width-desktop" style={{"font-size":"1em", "text-align":"center", "min-width":"76px", "background":"#212121ed"}}>
                                                    { this.state.languageList.map((language) => (
                                                        <Dropdown.Item
                                                            key={language}
                                                            eventKey={language}
                                                            onClick={() => {
                                                                this.setState({
                                                                    languageChoice: language
                                                                });
                                                            }}
                                                        >
                                                            {language}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-filters-demo-desktop">
                                        <div
                                            style={{"width":"40%", "font-size":".9em", "font-weight":"550"}}
                                            className="mb-2 d-flex flex-row justify-content-between align-items-center ask-a-gamer-explore-width-auto-mobile change-filters-div-width-desktop"
                                        >
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile"
                                                style={{"width":"50%"}}
                                            >
                                                <span
                                                    style={{"width":"100px"}}
                                                    className="ms-3"
                                                >
                                                    Occupation
                                                </span>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="We include this filter so that people can find teammates who are closer to their own age if they'd like."
                                                    style={{"font-size":".8em", "padding-left":".2em","padding-right":".2em"}}
                                                >
                                                    <Dropdown.Toggle 
                                                        style={{"width":"76px"}}
                                                        className="ms-3 w-30 demo-game-dropdown-button-mobile change-filter-button-width-desktop desktop-increase-height-500"
                                                    >
                                                        {this.state.occupationChoice}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="change-filter-dropdown-menu-width-desktop" style={{"font-size":"1em", "text-align":"center", "min-width":"76px", "background":"#212121ed", "height":"360px", "text-wrap":"balance", "overflow-y":"scroll"}}>
                                                    { this.state.occupationList.map((occupation) => (
                                                        <Dropdown.Item
                                                            style={{"text-wrap":"balance", "border-top":"solid 1px black","border-bottom":"solid 1px black"}}
                                                            key={occupation}
                                                            eventKey={occupation}
                                                            onClick={() => {
                                                                this.setState({
                                                                    occupationChoice: occupation
                                                                });
                                                            }}
                                                        >
                                                            {occupation}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile"
                                                style={{"width":"50%"}}
                                            >
                                                <span
                                                    style={{"width":"100px"}}
                                                    className="ms-3"
                                                >
                                                    Education
                                                </span>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Sorry this is disabled - should be available by June 13th!"
                                                    style={{"font-size":".8em", "padding-left":".2em","padding-right":".2em"}}
                                                >
                                                    <Dropdown.Toggle 
                                                        disabled
                                                        style={{"width":"76px"}}
                                                        className="ms-3 w-30 demo-game-dropdown-button-mobile change-filter-button-width-desktop"
                                                    >
                                                        {this.state.educationChoice}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="change-filter-dropdown-menu-width-desktop" style={{"font-size":"1em", "text-align":"center", "min-width":"76px", "background":"#212121ed"}}>
                                                    { this.state.educationList.map((education) => (
                                                        <Dropdown.Item
                                                            key={education}
                                                            eventKey={education}
                                                            onClick={() => {
                                                                this.setState({
                                                                    educationChoice: education
                                                                });
                                                            }}
                                                        >
                                                            {education}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div
                                            style={{"width":"40%", "font-size":".9em", "font-weight":"550"}}
                                            className="mb-2 d-flex flex-row justify-content-between align-items-center ask-a-gamer-explore-width-auto-mobile change-filters-div-width-desktop"
                                        >
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile"
                                                style={{"width":"50%"}}
                                            >
                                                <span
                                                    style={{"width":"100px"}}
                                                    className="ms-3"
                                                >
                                                    Interests
                                                </span>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="This filter is where the gamer is currently located. We will expand this to more countries and time zones in the future."
                                                    style={{"font-size":".8em", "padding-left":".2em","padding-right":".2em"}}
                                                >
                                                    <Dropdown.Toggle 
                                                        style={{"width":"76px"}}
                                                        className="ms-3 w-30 demo-game-dropdown-button-mobile change-filter-button-width-desktop desktop-increase-height-500"
                                                    >
                                                        {this.state.interestChoice}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="change-filter-dropdown-menu-width-desktop" style={{"font-size":"1em", "text-align":"center", "min-width":"76px", "background":"#212121ed", "height":"360px", "text-wrap":"balance", "overflow-y":"scroll"}}>
                                                    { this.state.interestList.map((interest) => (
                                                        <Dropdown.Item
                                                            style={{"text-wrap":"balance", "border-top":"solid 1px black","border-bottom":"solid 1px black"}}
                                                            key={interest}
                                                            eventKey={interest}
                                                            onClick={() => {
                                                                this.setState({
                                                                    interestChoice: interest
                                                                });
                                                            }}
                                                        >
                                                            {interest}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile"
                                                style={{"width":"50%"}}
                                            >
                                                <span
                                                    style={{"width":"100px"}}
                                                    className="ms-3"
                                                >
                                                    Long-term?
                                                </span>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="This is the gamers preferred communication language. We will add more when we are live."
                                                    style={{"font-size":".8em", "padding-left":".2em","padding-right":".2em"}}
                                                >
                                                    <Dropdown.Toggle 
                                                        style={{"width":"76px"}}
                                                        className="ms-3 w-30 demo-game-dropdown-button-mobile change-filter-button-width-desktop"
                                                    >
                                                        {this.state.longtermChoice}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="change-filter-dropdown-menu-width-desktop" style={{"font-size":"1em", "text-align":"center", "min-width":"76px", "background":"#212121ed"}}>
                                                    { this.state.longtermList.map((longterm) => (
                                                        <Dropdown.Item
                                                            key={longterm}
                                                            eventKey={longterm}
                                                            onClick={() => {
                                                                this.setState({
                                                                    longtermChoice: longterm
                                                                });
                                                            }}
                                                        >
                                                            {longterm}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="between-and-flex-important flex-column ask-indemni-reduce-font-size-mobile ask-a-gamer-explore-flex-column-page-input-mobile desktop-increse-font-1point1">
                                            <h5
                                                className="mt-3 demo-page-increase-font-section-titles"
                                                style={{"width":"100%", "text-decoration":"underline","margin-bottom":".25em"}}
                                            >
                                                Select Game
                                            </h5>
                                        {/* <div
                                            style={{"width":"100%", "height":"30px", "margin-top":".25em"}}
                                            className="d-flex flex-row justify-content-between align-items-center"
                                        >
                                            <div className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile">
                                                <Form.Group as={Col} md style={{"font-size":"1.25em", "font-weight":"600"}} controlId="flexibleOnGame">
                                                    <Form.Check
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                flexibleOnGame: e.target.checked,
                                                                mustMatchGame: false,
                                                            });
                                                        }}
                                                        label="Flexible on Game - The Gamer Matters More"
                                                        optional
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="By selecting this option, choosing a game is disabled and you are choosing to matchmake purely based on the gamer set by your filters."
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div> */}
                                        <div
                                            style={{"width":"100%", "height":"30px", "margin-bottom":"1em","margin-top":".25em"}}
                                            className="d-flex flex-row justify-content-between align-items-center desktop-increse-font-1point1"
                                        >
                                            <div className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile">
                                                <Form.Group as={Col} md className="mb-3" style={{"margin-top":"1.75em", "font-size":"1.25em", "font-weight":"600", "color":"#8ebbff"}} controlId="mustMatchGame">
                                                    <Form.Check
                                                        type="checkbox"
                                                        onChange={(e) => {
                                                            this.setState({
                                                                mustMatchGame: e.target.checked,
                                                                flexibleOnGame: false,
                                                            });
                                                        }}
                                                        label="The Game Must Match"
                                                        optional
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="By selecting this option, you will only match will people looking to play the game you select."
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                        <div
                                            style={{"width":"100%"}}
                                            className="mb-2 d-flex flex-row justify-content-between align-items-center ask-a-gamer-explore-width-auto-mobile"
                                        >
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-start ask-a-gamer-explore-margin-top-input-mobile hange-demo-input-div-container-align"
                                                style={{"width":"100%"}}
                                            >
                                                <input
                                                    type="search"
                                                    className="ms-3 change-width-for-mobile ask-a-gamer-explore-input-mobile demo-search-bar-desktop"
                                                    style={{"vertical-align":"middle", "width":"100%", "outline":"none"}}
                                                    value={this.state.nameIncludeFilter}
                                                    onChange={this.handleSearchChange}
                                                    placeholder="Search by game name..."
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="You can type in a games name here and click the game picture from the list below or use the dropdown menu on the right."
                                                    // disabled={this.state.flexibleOnGame}
                                                />
                                                <Button
                                                    style={{"background-color":"#7e7e7e1c"}}
                                                    className="ms-3 w-30 reset-filters-button-display-desktop game-page-reset-search-button-mobile"
                                                    onClick={() => this.setState({
                                                        nameIncludeFilter: "",
                                                        currentPage: 1,
                                                        gameChoice:"Any",
                                                        noGameSelected: true
                                                    })}
                                                    // disabled={this.state.flexibleOnGame}
                                                >
                                                    Reset
                                                </Button>
                                            </div>
                                            <Dropdown
                                                data-toggle="tooltip"
                                                data-placement="top"
                                                title="You can select a game with this dropdown or use the search bar on the left and click the game picture from the list below."
                                                style={{"margin-top":".25em"}}
                                            >
                                                <Dropdown.Toggle
                                                    // disabled={this.state.flexibleOnGame}
                                                    className="ms-3 w-30 demo-game-select-dropdown-button-mobile desktop-increase-minwidth-300"
                                                >
                                                    {this.state.flexibleOnGame ? "Any" : this.state.gameChoice}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu
                                                    className="desktop-increase-height-500 desktop-increase-minwidth-300"
                                                    style={{"font-size":"1em", "text-align":"center", "min-width":"100px", "background":"#212121ed", "height":"360px", "text-wrap":"balance", "overflow-y":"scroll"}}
                                                >
                                                { this.state.games.map((game) => (
                                                    <Dropdown.Item
                                                        style={{"text-wrap":"balance", "border-top":"solid 1px black","border-bottom":"solid 1px black"}}
                                                        key={game.id}
                                                        eventKey={game.id}
                                                        onClick={() => {
                                                            this.setState({
                                                                gameChoice: game.gameName,
                                                                gameChoiceImage: game.gameImagePath,
                                                                noGameSelected: false,
                                                            });
                                                        }}
                                                    >
                                                        {game.gameName}
                                                    </Dropdown.Item>
                                                ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    <hr className="hr-ask-indemni-title-hr" />
                                    { displayedGames.map((game) => (
                                        <div key={game.id} className="me-2 px-2 ask-a-gamer-explore-margin-padding-right-left-0-mobile">
                                          {this.displayGame(game)}
                                        </div>
                                    ))}
                                    { totalPages > 0 && (
                                        <div
                                            style={{"font-size":".85em"}}
                                            className="center-and-flex-important mb-2"
                                        >
                                            <span
                                                style={{"font-size":"1.7em", "font-weight":"600", "margin-bottom":".2em"}}
                                                className="me-2"
                                            >
                                                Page
                                            </span>
                                        <Pagination>
                                            {[...Array(lastPageNumber - firstPageNumber + 1).keys()].map((index) => {
                                                const pageNumber = firstPageNumber + index;
                                                return (
                                                    <Pagination.Item
                                                        key={pageNumber}
                                                        active={pageNumber === this.state.currentPage}
                                                        onClick={() => this.handlePageChange(pageNumber)}
                                                        linkStyle={{"color":"white", "background-color":"#312e2e", "width":"50px","font-size":"1.1em", "font-weight":"700", "text-align":"center"}}
                                                    >
                                                        {pageNumber}
                                                    </Pagination.Item>
                                                );
                                            })}
                                        </Pagination>
                                      </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <Footerfixed></Footerfixed>
                    </div>
                </>
            )

            return out;
    }
}

export default GamerMatchmakerPage;
