/* This will be used for verifying user actions like email, password changes, etc.
    ... Currently, this is just a placeholder we needed for users to "confirm" their email after
    ... clicking the email verification link. We can add additional steps, such as them entering 
    ... a code we sent to their email, and add a boolean in the db to check that they verified 
    ... their email, etc. */

import { Button } from "react-bootstrap";
import React, { Component } from "react"
import { onAuthStateChanged, signOut } from "firebase/auth"
import { doc, where, query, getDoc, getDocs, collectionGroup } from "firebase/firestore";
import BasicNavbar from "../components/Navbar/Navbar";

class VerificationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: undefined,
            userdata: undefined,
        }

        this.logout = this.logout.bind(this)
        this.setUser = this.setUser.bind(this)

    
        onAuthStateChanged(this.props.fbsAuth, async user => {
            if (user) {
                await this.setUser(user); 
            }
            else {
                this.logout();
            }
        })
    }

    logout() {
        /* If not logged in, don't try to log out. */
        if (this.state.user === undefined) return;

        signOut(this.props.fbsAuth)
            .then(() => {
                this.setState({
                    user: undefined,
                    readonlyUserdata: undefined,
                    writableUserdata: undefined,
                    hiddenWritableUserdata: undefined
                }, () => {
                    console.log("Logged out!")
                })
            })
            .catch((error) => {
                // TODO: What do we do if signing out fails?
                console.error("Could not sign out!")
                console.error(`FIREBASEAUTH (${error.code}): ${error.message}`)
                console.error(error)
            })
    }

    async setUser(newUser) {
        return new Promise(async resolve => {
            // Somewhat validate new user
            if (typeof newUser !== "object" || typeof newUser.uid === "undefined") resolve(false);

            const userReadonlyQuery = query(
                collectionGroup(this.props.fbsFirestore, "Readonly"),
                where("uid", "==", newUser.uid)
            );

            const userReadonlySnapshots = await getDocs(userReadonlyQuery);
            if (userReadonlySnapshots.empty) {
                console.error(`Login failed: Could not find user in database with uid == ${newUser.uid}`)
                resolve(false)
                return;
            }

            const userReadonlySnapshot = userReadonlySnapshots.docs[0]
            if (!await userReadonlySnapshot.exists()) {
                console.error(`Login failed: Could not find user in database with uid == ${newUser.uid}`)
                resolve(false)
                return;
            }

            const userReadonlyData = userReadonlySnapshot.data()

            const userNumber = userReadonlyData.userNumber
            const userNumberString = `${userNumber}`

            const userWritableSnapshot = await getDoc(doc(this.props.fbsFirestore, "Users", userNumberString, "PublicReadonlyPrivateWritable", "0"));
            const userWritableData = userWritableSnapshot.data();

            const userHiddenSnapshot = await getDoc(doc(this.props.fbsFirestore, "Users", userNumberString, "PublicHiddenPrivateWritable", "0", "Inbox", "0"));
            const userHiddenData = userHiddenSnapshot.data();

            this.setState({
                user: newUser,                          // Firebase Authentication user object
                readonlyUserdata: userReadonlyData,     // /Users/<userNumber>/Readonly/0
                writableUserdata: userWritableData,     // /Users/<userNumber>/PublicReadonlyPrivateWritable/0
                hiddenWritableUserdata: userHiddenData, // /Users/<userNumber>/PublicHiddenPrivateWritable/0
            }, () => {
                resolve(true)
            })
        })
    }

    // ToDo (post-launch): Make this probably do something beyond a display - check line 1 comments.
    render() {
        return (
            <>  
                <BasicNavbar
                    user={this.state.user}
                    userdata={{...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata}}
                    universityName={(this.state.readonlyUserdata && this.state.readonlyUserdata.inTheUniveristyProgram) ? this.state.readonlyUserdata.universityName : "University"}
                />
                <div className="d-flex fluid vh-100 w-100 justify-content-center page-fade-animation">
                    <div
                    style={{"margin-top":"5em", "max-width":"1000px"}}
                    className="text-center">
                        <h1 className="p-3">
                            Thanks for Verifying Your Email and/or Password!
                        </h1>
                        <hr />
                        <h5 className="p-3">
                            If you haven't already, be sure to customize your Gamer Profile and post your first LFG!
                        </h5>
                        <h5 className="p-3">
                            Also, head on over to our <a href="https://discord.gg/9EZmV5qBY5" target="_blank" className="change-link-color-chat">Discord</a> to meet more members of the Indemni community, chat with the team, ask questions/give feedback, and participate in our weekly events!
                        </h5>
                        <Button 
                            className="game-details-information p-2"
                            style={{"margin-top":"3em", "font-size":"1.5em"}}
                            href="/account"
                        >
                            My Gamer Profile
                        </Button>
                    </div>
                </div>
            </>
        )
    }
}

export default VerificationPage;
