import React, { Component } from "react";
import { Col, Button, Dropdown } from "react-bootstrap"
import { doc, getDoc, updateDoc, onSnapshot, arrayRemove, query, where, collection, arrayUnion } from "firebase/firestore";
import SocialGroupsChatInput from "./SocialGroupsChatInput";
import { PersonVcard, Trash3, EmojiHeartEyes, EmojiAngry } from "react-bootstrap-icons";

class SocialGroupsChatPlaceHolder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            messageText: '', // the display of text for textarea
            uploadText: '', // the modified text that uploads line breaks to firebase correctly for answererSendMessage
            unreadCounts: {},
            unreadCount: 0, // Tracks the unread message count
            cooldown: false, // Conditional to set timeout of 1.5s to prevent spamming of messages and allow cloud function to finish.
            placeholderMessageInput: true,
            trustAsker: false,
            distrustAsker: false, // called onClick of dis/trusting an asker in the dropdown - onClick it is set to true and updates state/firebase in handleAskerTrust.
        };

        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.chatSendMessage = this.chatSendMessage.bind(this);
    };

    // Loads contacts and sets up listener for changes in messages in "Inbox"
    // doc to provide real-time updates.
    // async componentDidMount() {
    //     this.setUpChatListener();
    // }

    // Checks if the current asker in queue is new/different from the previous asker.
    // Scrolls chat to the bottom to display the most recent message.
    componentDidUpdate(prevProps, prevState) {
        this.scrollToBottom();
    }

    // Automatically scrolls the window to the bottom and most recent message.
    scrollToBottom() {
        const chatBox = document.getElementById('chat-box');
        if (chatBox) chatBox.scrollTop = chatBox.scrollHeight;
    };

    // Sends messages to the users GroupChat
    async chatSendMessage() {
        // Return early if user is spamming.
        if (this.state.cooldown) return;

        // Set the cooldown to true so the user's can't send another message.
        this.setState({ cooldown: true });

        try {
            const message = {
                from: this.props.userdata.userNumber,
                fromName: this.props.userdata.username,
                timeSent: new Date(),
                body: this.state.uploadText,
                read: false,
            }
            const chatDocRef = doc(this.props.fbsFirestore, "GroupChats", `${this.props.userdata.userNumber}`)
            await updateDoc (chatDocRef, {
                messages: arrayUnion(message)
            });
            this.setState({ messageText: "", uploadText: "" })
        } catch (error) {console.log(error)};

        setTimeout(() => {
            this.setState({ cooldown: false });
        }, 1.5);
    };

    // Most all classNames for formatting can be found within _messagebox.scss.
    render() {
        // const { messages, myGroupMessages } = this.props;
        return (
            <>
                <Col
                    className= 'home flex-column chat-inbox-width-max'
                    style={{"margin-bottom":"3em", "height":"900px"}}
                >
                    <div className= 'homecontainer style-for-mobile sidebar-medium-width remove-margin-left-medium'>
                        <div className="chatmessage">
                            <div className="chatinfo">
                                <div className="chaticons">
                                    <span style={{"font-size":"1.2em", "font-weight":"bolder","padding-right":"5px", "padding-top":"2px", "text-decoration":"underline", "margin-left":"1.5em"}}>
                                        Group Chat
                                    </span>
                                </div>
                                  <div
                                    style={{"margin-right":"-1em"}}
                                    className="chaticons"
                                  >
                                    <Dropdown>
                                        <Dropdown.Toggle style={{"background-color": "#3735350d", "color":"#ed6058f5", "margin-right":"2.75em"}}>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu style={{"inset":"5px -5px auto auto", "font-size":".9em", "text-align":"center", "background":"#212121ed", "text-wrap":"balance", "max-width":"200px"}}>
                                            <Dropdown.Item
                                                style={{"text-wrap":"balance"}}
                                                href="https://discord.gg/9EZmV5qBY5"
                                                target="_blank"
                                            >
                                                <span style={{"color":"#8afeff", "font-weight":"550"}}> Indemni Discord <EmojiHeartEyes className="ms-2"/></span>
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                            </div>
                            {/* This is where messages or chats are displayed */}
                            <div className="messageboxchat" id="chat-box" >
                                <div 
                                    className="d-flex flex-column justify-content-center align-items-center chat-inbox-fade-animation chat-alt-div-size-mobile"
                                    style={{"font-size":"1.3em", "margin-left":"1.5em", "font-weight":"600"}}
                                >
                                    <span 
                                        className="text-center"
                                        style={{"margin-top":"4em", "margin-left":"1.5em", "margin-bottom":"2em"}}
                                    >
                                        You aren't currently in a group, so there's no one to talk to here! Join groups, post a group, or click on the Personal Messages buttop to chat with some people.
                                        <br />
                                        <br />
                                        * You can also head over to the Indemni Discord to chat with other users by clicking the red drop-down arrow above and clicking Indemni Discord.
                                    </span>
                                </div>
                            </div>
                            <SocialGroupsChatInput
                                messageText={this.state.messageText}
                                uploadText={this.state.uploadText}
                                onMessageTextChange={(inputText, modifiedText) => this.setState({ messageText: inputText, uploadText: modifiedText })}
                                onSendMessage={() => this.chatSendMessage()}
                                placeholderMessageInput={this.state.placeholderMessageInput}
                            />
                        </div>
                    </div>
                </Col>
            </>
        )
    }

}

export default SocialGroupsChatPlaceHolder;
