import React, { Component } from "react";

import { onAuthStateChanged, signOut } from "firebase/auth";
import { doc, where, query, getDoc, getDocs, collectionGroup } from "firebase/firestore";

import BasicNavbar from "../components/Navbar/Navbar"

class NoPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            user: undefined,
            userdata: undefined,
        }

        this.logout = this.logout.bind(this)
        this.setUser = this.setUser.bind(this)

        onAuthStateChanged(this.props.fbsAuth, user => {
            if (user) this.setUser(user)
            else this.logout()
        })
    }

    logout() {
        /* If not logged in, don't try to log out. */
        if (this.state.user === undefined) return;

        signOut(this.props.fbsAuth)
            .then(() => {
                this.setState({
                    user: undefined,
                    readonlyUserdata: undefined,
                    writableUserdata: undefined,
                    hiddenWritableUserdata: undefined
                }, () => {
                    console.log("Logged out!")
                })
            })
            .catch((error) => {
                // TODO: What do we do if signing out fails?
                console.error("Could not sign out!")
                console.error(`FIREBASEAUTH (${error.code}): ${error.message}`)
                console.error(error)
            })
    }

    async setUser(newUser) {
        return new Promise(async resolve => {
            // Somewhat validate new user
            if (typeof newUser !== "object" || typeof newUser.uid === "undefined") resolve(false);

            const userReadonlyQuery = query(
                collectionGroup(this.props.fbsFirestore, "Readonly"),
                where("uid", "==", newUser.uid)
            );

            const userReadonlySnapshots = await getDocs(userReadonlyQuery);
            if (userReadonlySnapshots.empty) {
                console.error(`Login failed: Could not find user in database with uid == ${newUser.uid}`)
                resolve(false)
                return;
            }

            const userReadonlySnapshot = userReadonlySnapshots.docs[0]
            if (!await userReadonlySnapshot.exists()) {
                console.error(`Login failed: Could not find user in database with uid == ${newUser.uid}`)
                resolve(false)
                return;
            }

            const userReadonlyData = userReadonlySnapshot.data()

            const userNumber = userReadonlyData.userNumber
            const userNumberString = `${userNumber}`

            const userWritableSnapshot = await getDoc(doc(this.props.fbsFirestore, "Users", userNumberString, "PublicReadonlyPrivateWritable", "0"));
            const userWritableData = userWritableSnapshot.data();

            const userHiddenSnapshot = await getDoc(doc(this.props.fbsFirestore, "Users", userNumberString, "PublicHiddenPrivateWritable", "0", "Inbox", "0"));
            const userHiddenData = userHiddenSnapshot.data();

            this.setState({
                user: newUser,                          // Firebase Authentication user object
                readonlyUserdata: userReadonlyData,     // /Users/<userNumber>/Readonly/0
                writableUserdata: userWritableData,     // /Users/<userNumber>/PublicReadonlyPrivateWritable/0
                hiddenWritableUserdata: userHiddenData, // /Users/<userNumber>/PublicHiddenPrivateWritable/0
            }, () => {
                resolve(true)
            })
        })
    }

    render() {
        return (
            <>
                <BasicNavbar
                    user={this.state.user} userdata={{...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata}}
                    universityName={(this.state.readonlyUserdata && this.state.readonlyUserdata.inTheUniveristyProgram) ? this.state.readonlyUserdata.universityName : "University"}
                />
                <div className="text-center">
                    <h1>404 NOT FOUND</h1>
                    This page does not exist!
                </div>
            </>
        );
    }
}

export default NoPage;
