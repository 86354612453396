// The Account Page for users aka Gamer Profile.
// In this page, new users are onboarded, users can set all
// their personal/gaming information, user can post LFG, and
// users can chat and perform other social activity.
// TODO (later): Streamline repeated code with functional components
// and seperate certain sections into seperate components to be imported
// so that this page is much cleaner and everything is easier to edit/update.
import React, { Component } from "react"
import {
    Button,
    Dropdown,
    Stack,
    Pagination,
} from "react-bootstrap"
import {
    updateDoc,
    doc,
    getDoc,
    arrayRemove,
    arrayUnion,
} from "@firebase/firestore";
import { Pencil, Check2Square } from "react-bootstrap-icons";
import { alterPostData, removePostData } from "../../utils.js";
import UniversitySearch from "../UniversitySearch/UniversitySearch.js";
import universityList from '../UniversitySearch/world_universities_and_domains.json'; // Adjust the path based on your actual directory structure

class AccountProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newUser: false, // If true, then it's a new user. Set in componentDidMount to see if the user just created an account which then dictates some other conditionals.
            cooldown: false, // Conditional to set timeout of .5s to allow DB to update and prevent user activity before it ends.
            
            // Textarea inputs - "Upload" is what we use to update the DB and "Display" is what we show user as the value displayed in the Textarea.
            // We are letting users write in their favoriteGames for now since we have limited games uploaded - we will change this later to a dropdown.
            // TODO: Do we need a 3rd state for editing the message? I'll check this later.
            lookingMessageUploadText: "",
            lookingMessageDisplayText: this.props.profile.looking_message.replace(/%0A/g, '\n'),
            editingLookingMessage: "",
            statOneUploadText: "",
            statOneDisplayText: this.props.profile.looking_stat_one.replace(/%0A/g, '\n'),
            editingStatOne: "",
            statTwoUploadText: "",
            statTwoDisplayText: this.props.profile.looking_stat_two.replace(/%0A/g, '\n'),
            editingStatTwo: "",
            statThreeUploadText: "",
            statThreeDisplayText: this.props.profile.looking_stat_three.replace(/%0A/g, '\n'),
            editingStatThree: "",
            lookingTeammateMessageUploadText: "",
            lookingTeammateMessageDisplayText: this.props.profile.looking_teammate_message.replace(/%0A/g, '\n'),
            editinglookingTeammateMessage: "",
            bioUploadText: "",
            bioDisplayText: this.props.profile.bio.replace(/%0A/g, '\n'),
            editingBioText: "",
            favoriteGamesOneUploadText: "",
            favoriteGamesOneDisplayText: this.props.profile.favoriteGamesOne.replace(/%0A/g, '\n'),
            editingfavoriteGamesOne: "",
            favoriteGamesTwoUploadText: "",
            favoriteGamesTwoDisplayText: this.props.profile.favoriteGamesTwo.replace(/%0A/g, '\n'),
            editingfavoriteGamesTwo: "",
            favoriteGamesThreeUploadText: "",
            favoriteGamesThreeDisplayText: this.props.profile.favoriteGamesThree.replace(/%0A/g, '\n'),
            editingfavoriteGamesThree: "",
            socialUsernameOneUploadText: "",
            socialUsernameOneDisplayText: this.props.profile.socialUsernameOne.replace(/%0A/g, '\n'),
            editingSocialUsernameOne: "",
            socialUsernameTwoUploadText: "",
            socialUsernameTwoDisplayText: this.props.profile.socialUsernameTwo.replace(/%0A/g, '\n'),
            editingSocialUsernameTwo: "",
            socialUsernameThreeUploadText: "",
            socialUsernameThreeDisplayText: this.props.profile.socialUsernameThree.replace(/%0A/g, '\n'),
            editingSocialUsernameThree: "",

            games: [],
            gamesPerPage: 5, // Games displayed per page - can be altered.
            currentPage: 1, // The current page as a refference check.
            nameIncludeFilter: "", // Search input for searching for games.
            isEditingLFGPost: false, // Enables editing for user's LFG Post data
            isEditingStats: false, // Enables editing for user's Game Stats data
            isEditingIdealTeammate: false, // Enables editing for user's Ideal Teammate data
            isEditingAbout: false, // Enables editing for user's About/Bio data
            isEditingFavoriteGames: false, // Enables editing for user's Favorite Game data
            isEditingPlatformsInterests: false, // Enables editing for user's Platforms & Interests data
            isEditingGamertagsSocials: false, // Enables editing for user's Gamertags & Socials data
            isEditingBackground: false, // Enables editing for user's Background data

            flexRequireList : ["Flexible", "Required"],
            gamePlatformList: ["Any", "PC", "Console", "PS5", "Xbox", "Switch", "Mobile", "VR", "Other"],
            neededList: ["Any", "1 Gamer", "2 Gamers", "3 Gamers", "4 Gamers", "5 Gamers", "6+ Gamers"],
            startList: ["Any", "Now", "Later"],
            objectiveList: ["Any", "Casual", "Ranked", "Practice", "Recruiting", "Social"],
            skillList: ["Any", "Good", "Great", "Pro", "Average", "Noob"],
            commsList: ["Any", "Mic", "Chat", "None"],
            sessionList: ["Any", "Unknown" ,"1 Hour", "1-2 Hours", "2 Hours", "2-3 Hours", "3 Hours", "3-4 Hours", "4+ Hours"],
            playstyleList: ["Any", "Casual", "Ranked", "Practice", "Recruiting", "Social"],
            personalityList: ["Any", "Relaxed", "Serious", "Funny", "Quiet", "Loud", "Sad :(", "Happy"],
            longtermList: ["Maybe", "Yes", "No"],
            idealTeammateGenderList: ["Any", "Male", "Female", "Other"],
            idealTeammateAgeList: ["Any", "18-24", "24-29", "30-39", "40-49", "50+", "Other"],
            // TODO: Add more to lists and update lists!
            interestList: [
                "Adventure Games",
                "Anime",
                "Any",
                "ARPGS",
                "Augmented Reality",
                "Automotive Technology",
                "Backpacking",
                "Baking",
                "Basketball",
                "Blogging",
                "Board Games",
                "Builder Games",
                "Camping",
                "Card Games",
                "Chess",
                "Content Creation",
                "Cosplay",
                "Cycling",
                "Dancing",
                "Drawing",
                "E-sports",
                "Fantasy Sports",
                "Fighting Games",
                "Fishing",
                "Fitness",
                "FPS",
                "Game Development",
                "Game News",
                "Gaming",
                "Gardening",
                "Golf",
                "Hiking",
                "Home Brewing",
                "Journalism",
                "Kayaking",
                "Knitting",
                "Live Streaming",
                "Magic Tricks",
                "Martial Arts",
                "Memes",
                "MMOs",
                "Mountain Biking",
                "Mr. Beast",
                "Mr. Indemni",
                "Music Production",
                "Netflix & Chill",
                "Origami",
                "Painting",
                "Partying",
                "Photography",
                "Podcasting",
                "Puzzles",
                "Reading",
                "Roleplaying",
                "RPGs",
                "RTS Games",
                "Running",
                "Sailing",
                "Sci-Fi",
                "Sherpa'ing",
                "Skiing",
                "Snowboarding",
                "Socializing",
                "Soccer",
                "Sports Games",
                "Streaming",
                "Surfing",
                "Swimming",
                "Tabletop RPGs",
                "TCG",
                "Technology Reviews",
                "TikTok",
                "Traveling",
                "Twitch",
                "Video Editing",
                "Video Game Design",
                "Video Games",
                "Vlogging",
                "Wakeboarding",
                "Watching Movies",
                "Watching TV Shows",
                "Weightlifting",
                "Woodworking",
                "Writing",
                "Yoga"
            ],
            gamingSocialsPlatformList: [
                "Battlenet (Blizzard)",
                "Discord",
                "Epic",
                "Facebook",
                "Instagram",
                "Kick",
                "Origin (EA)",
                "PlayStation",
                "Snapchat",
                "Steam",
                "TikTok",
                "Twitch",
                "X (Twitter)",
                "Xbox",
                "YouTube",
            ],
            ageList: ["Private", "18-24", "24-29", "30-39", "40-49", "50+", "Other"],
            genderList: ["Private", "Male", "Female", "Other"],
            occupationList: [
                "Private",
                "Accountant",
                "Actor",
                "Administrative Assistant",
                "Anthropologist",
                "Archaeologist",
                "Architect",
                "Artist",
                "Astronomer",
                "Athlete",
                "Audiologist",
                "Baker",
                "Banker",
                "Barista",
                "Bartender",
                "Biologist",
                "Body Builder",
                "Botanist",
                "Brewer",
                "Bus Driver",
                "Business Analyst",
                "Carpenter",
                "Cashier",
                "Chef",
                "Chemist",
                "Chiropractor",
                "Civil Engineer",
                "Cleaner",
                "Construction Worker",
                "Consultant",
                "Content Creator",
                "Counselor",
                "Customer Service Representative",
                "Data Analyst",
                "Data Scientist",
                "Delivery Driver",
                "Dentist",
                "Dermatologist",
                "Dietitian",
                "Director",
                "Editor",
                "Educator",
                "Electrician",
                "Engineer",
                "Entrepreneur",
                "Event Planner",
                "Factory Worker",
                "Farmer",
                "Fashion Designer",
                "Film Director",
                "Financial Advisor",
                "Financial Analyst",
                "Firefighter",
                "Fitness Trainer",
                "Flight Attendant",
                "Florist",
                "Game Artist",
                "Game Designer",
                "Game Developer",
                "Game Director",
                "Game Producer",
                "Gamer",
                "Geologist",
                "Graphic Designer",
                "Grocery Store Worker",
                "Guidance Counselor",
                "HR Manager",
                "Historian",
                "Host/Hostess",
                "Hotel Manager",
                "Human Resources Specialist",
                "IT Technician",
                "Influencer",
                "Insurance Agent",
                "Interior Designer",
                "Janitor",
                "Journalist",
                "Judge",
                "Lawyer",
                "Librarian",
                "Loan Officer",
                "Locksmith",
                "Logistician",
                "Marketer",
                "Mechanic",
                "Mechanical Engineer",
                "Medical Assistant",
                "Midwife",
                "Musician",
                "Network Administrator",
                "Neurologist",
                "Nurse",
                "Nurse Practitioner",
                "Occupational Therapist",
                "Optometrist",
                "Paralegal",
                "Paramedic",
                "Park Ranger",
                "Pediatrician",
                "Personal Trainer",
                "Pharmacist",
                "Phlebotomist",
                "Photographer",
                "Physical Therapist",
                "Physician",
                "Physiotherapist",
                "Pilot",
                "Plumber",
                "Podcaster",
                "Poet",
                "Police Officer",
                "Politician",
                "Pro Gamer",
                "Product Manager",
                "Professor",
                "Programmer",
                "Project Manager",
                "Psychiatrist",
                "Psychologist",
                "Public Relations Specialist",
                "Radiologic Technologist",
                "Real Estate Agent",
                "Realtor",
                "Receptionist",
                "Registered Nurse",
                "Research Scientist",
                "Respiratory Therapist",
                "Retail Store Manager",
                "Retired",
                "Salesperson",
                "Scientist",
                "Secretary",
                "Security Guard",
                "Server",
                "Social Media Manager",
                "Social Worker",
                "Software Developer",
                "Sound Engineer",
                "Speech Therapist",
                "Statistician",
                "Streamer",
                "Student",
                "Surgeon",
                "Surveyor",
                "Systems Analyst",
                "Tailor",
                "Teacher",
                "Technical Writer",
                "Translator",
                "Travel Agent",
                "Unemployed",
                "Urban Planner",
                "Urologist",
                "Veterinarian",
                "Video Editor",
                "Web Developer",
                "Welder",
                "Writer",
                "Zoologist"
            ],
            // TODO: Import universitylist.json and implement logic for this instead.
            educationList: [
                "Private",
                "High School",
                "College",
            ],
            locationList: ["Private", "USA", "Canada", "UK", "AUS", "Europe", "Asia", "Africa", "LATAM", "Other"],
            languageList: ["Private", "English", "French", "Spanish", "Korean", "Mandarin", "Hindi", "Other"],
            weeklyPlaytimeList: [
                "Private",
                "1 Hour",
                "2 Hours",
                "2-3 Hours",
                "3 Hours",
                "4 Hours",
                "4-5 Hours",
                "5 Hours",
                "6-7 Hours",
                "8 Hours",
                "10 Hours",
                "10-15 Hours",
                "15-20 Hours",
                "20-30 Hours",
                "30-40 Hours",
                "40+ Hours",
                "Too Much",
            ],

            editingGameName: this.props.profile.looking_gameName,
            editingGameImage: this.props.profile.looking_gameImagePath,
            editingGameId: this.props.profile.looking_game_id,
            editingPlatform: this.props.profile.looking_platform,
            editingNeeded: this.props.profile.looking_needed,
            editingStart: this.props.profile.looking_time,
            editingObjective: this.props.profile.looking_objective,
            editingObejectiveFlexRequire: this.props.profile.looking_objective_flexRequire,
            editingSkill: this.props.profile.looking_skill,
            editingComms: this.props.profile.looking_comms,
            editingSession: this.props.profile.looking_session,
            editingSessionFlexRequire: this.props.profile.looking_session_flexRequire,
            editingTeammatePlaystyle: this.props.profile.looking_teammate_playstyle,
            editingTeammatePlaystyleFlexRequire: this.props.profile.looking_teammate_playstyle_flexRequire,
            editingTeammatePersonality: this.props.profile.looking_teammate_personality,
            editingTeammatePersonalityFlexRequire: this.props.profile.looking_teammate_personality_flexRequire,
            editingTeammateLongterm: this.props.profile.looking_teammate_longterm,
            editingTeammateGender: this.props.profile.looking_teammate_gender,
            editingTeammateAge: this.props.profile.looking_teammate_age,
            editingFavoritePlatform: this.props.profile.favoritePlatform,
            editingInterestOne: this.props.profile.interestOne,
            editingInterestTwo: this.props.profile.interestTwo,
            editingSocialPlatformOne: this.props.profile.socialPlatformOne,
            editingSocialPlatformTwo: this.props.profile.socialPlatformTwo,
            editingSocialPlatformThree: this.props.profile.socialPlatformThree,
            editingAge: this.props.profile.age,
            editingGender: this.props.profile.gender,
            editingOccupation: this.props.profile.occupation,
            editingEducation: this.props.profile.education,
            editingLocation: this.props.profile.location,
            editingLanguage: this.props.profile.language,
            editingweeklyPlaytime: this.props.profile.weeklyPlaytime,
            
            universities: universityList,
            countryFilter: "",
            universityNameIncludeFilter: "",
            universityCurrentPage: 1,
            universitiesPerPage: 10,
            universityChoice: "", // New state to store the selected university
            educationSelected: false, // New state to track if education is selected

        }

        this.removeLFGPost = this.removeLFGPost.bind(this);
        this.updateLFGPost = this.updateLFGPost.bind(this);
        this.updateStats = this.updateStats.bind(this);
        this.updateIdealTeammate = this.updateIdealTeammate.bind(this);
        this.updateAbout = this.updateAbout.bind(this);
        this.updateFavoriteGames = this.updateFavoriteGames.bind(this);
        this.updatePlatformsInterests = this.updatePlatformsInterests.bind(this);
        this.updateGamertagsSocials = this.updateGamertagsSocials.bind(this);
        this.updateBackground = this.updateBackground.bind(this);

        this.mapLookingNeededToNumber = this.mapLookingNeededToNumber.bind(this);

        this.formatDate = this.formatDate.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.passesFilters = this.passesFilters.bind(this);
        this.displayGame = this.displayGame.bind(this);
        this.renderFormattedText = this.renderFormattedText.bind(this);
        this.playAudio = this.playAudio.bind(this);

        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handleUniversitySearchChange = this.handleUniversitySearchChange.bind(this);
        this.handleCountryChange = this.handleCountryChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleUniversityClick = this.handleUniversityClick.bind(this);
        this.handleChangeEducation = this.handleChangeEducation.bind(this); // Bind the handler for changing education
    }

    // We first check if the user is new.
    // Then we fetch the games data from the "AskAGamer" collection.
    async componentDidMount() {
        // A check to see if the user just created an account - which dictates
        // certain conditionals to onboard the user to set up their profile and
        // make their first post.
        if (this.props.userdata.newUser) {
            this.setState({ newUser: true })
        } else this.setState({ newUser: false }) // newUser state set to false here and in the constructor just in case.

        // Prepares the list of games
        const gamesDocRef = doc(this.props.fbsFirestore, "AskAGamer", "Games");
        const gamesSnapshot = await getDoc( gamesDocRef)
        if (gamesSnapshot.exists()) {
            // Populate games
            const gamesData = gamesSnapshot.data();
            const games = gamesData.gameData;
            const sortedGames =  [...games].sort((a, b) => a.gameName.localeCompare(b.gameName));
            this.setState({ games: sortedGames })
        }
    }

    // Removes the LFG Post from being listed in the people array in the Games Collection.
    async removeLFGPost(gameNumber) {
        // Calls removePosttData with appropriate parameters
        await removePostData(
            this.props.userdata.inTheUniveristyProgram,
            this.props.fbsFirestore,
            this.props.userdata.userNumber,  // User number
            this.props.profile.looking_game_id // Game Number
        );
    }

    handleCountryChange(e) {
        const selectedCountry = e.target.value;
        this.setState({ countryFilter: selectedCountry, universityCurrentPage: 1 });
    }

    handleUniversitySearchChange(e) {
        const searchValue = e.target.value;
        this.setState({ universityNameIncludeFilter: searchValue, universityCurrentPage: 1 });
    }

    handlePageChange(pageNumber) {
        this.setState({ universityCurrentPage: pageNumber });
    }

    handleUniversityClick(universityName) {
        this.setState({
            universityChoice: universityName,
            universityNameIncludeFilter: universityName,
            educationSelected: true
        });
    }

    handleChangeEducation() {
        this.setState({
            universityChoice: "",
            universityNameIncludeFilter: "",
            countryFilter: "",
            universityCurrentPage: 1,
            educationSelected: false
        });
    }

    // Helper function to map 'looking_needed' string to number
    mapLookingNeededToNumber(lookingNeeded) {
        const map = {
            "Any": 5,
            "1 Gamer": 1,
            "2 Gamers": 2,
            "3 Gamers": 3,
            "4 Gamers": 4,
            "5 Gamers": 5,
            "6+ Gamers": 11,
        };
        return map[lookingNeeded] || 0; // Default to 0 if unexpected value
    }
    


    // Called onClick of "Update LFG Post" in the LFG Post Section
    // when isEditingLFGPost === true.
    // Checks which data was updated by looking at state variables to 
    // see if they have changed - then passes the edited fields as params
    // to the alterPostData function imported from utils.js.
    // Updates Firestore the User collection for the current user & updates
    // the Games collection people array with their new LFG Post.
    // TODO: Update states that need to be updated at the end of this function
    // and make sure variables that should always be on LFG post in people array
    // get added even if they are an empty string.
    async updateLFGPost(gameNumber) {
        // updatePeopleArray is a boolean to prevent Games collection from being 
        // updated under scenarious where the data isn't needed for the people array.
        const updatePeopleArray = true;
        // Updates the postTime field in both collections with the current time.
        const postTime = new Date();

        // Convert the string of needed, to a number
        const neededNumber = this.mapLookingNeededToNumber(this.state.editingNeeded)

        // Constructs updatedFields object with new values if they aren't equal to the user's current field values.
        const updatedFields = {};
        if (this.state.editingGameName !== "" && this.state.editingGameName !== this.props.profile.looking_gameName) {
            updatedFields["looking_gameName"] = this.state.editingGameName;
        }
        if (this.state.editingGameImage !== "" && this.state.editingGameImage !== this.props.profile.looking_gameImagePath) {
            updatedFields["looking_gameImagePath"] = this.state.editingGameImage;
        }
        if (this.state.editingGameId !== this.props.profile.looking_game_id) {
            updatedFields["looking_game_id"] = this.state.editingGameId;
        }
        if (this.state.editingPlatform !== "" && this.state.editingPlatform !== this.props.profile.looking_platform) {
            updatedFields["looking_platform"] = this.state.editingPlatform;
        }
        if (this.state.editingNeeded !== "" && this.state.editingNeeded!== this.props.profile.looking_needed) {
            updatedFields["looking_needed"] = this.state.editingNeeded;
            updatedFields["looking_needed_number"] = neededNumber;
        }
        if (this.state.editingObjective !== "" && this.state.editingObjective !== this.props.profile.looking_objective) {
            updatedFields["looking_objective"] = this.state.editingObjective;
        }
        if (this.state.editingObejectiveFlexRequire !== "" && this.state.editingObejectiveFlexRequire !== this.props.profile.looking_objective_flexRequire) {
            updatedFields["looking_objective_flexRequire"] = this.state.editingObejectiveFlexRequire;
        }
        if (this.state.editingSkill !== "" && this.state.editingSkill !== this.props.profile.looking_skill) {
            updatedFields["looking_skill"] = this.state.editingSkill;
        }
        if (this.state.editingComms !== "" && this.state.editingComms !== this.props.profile.looking_comms) {
            updatedFields["looking_comms"] = this.state.editingComms;
        }
        if (this.state.editingSession !== "" && this.state.editingSession!== this.props.profile.looking_session) {
            updatedFields["looking_session"] = this.state.editingSession;
        }
        if (this.state.editingSessionFlexRequire !== "" && this.state.editingSessionFlexRequire !== this.props.profile.looking_session_flexRequire) {
            updatedFields["looking_session_flexRequire"] = this.state.editingSessionFlexRequire;
        }
        // TODO: maybe just disallow function if looking_message is an empty string.
        updatedFields["looking_message"] = this.state.lookingMessageUploadText === "" ? this.props.profile.looking_message : this.state.lookingMessageUploadText;
        updatedFields["postTime"] = postTime;
        updatedFields["isLFG"] = true;

       
        // Constructs previousFields object with previous values to compare for updating docs.
        const previousFields = {
            looking_gameName: this.props.profile.looking_gameName,
            looking_gameImagePath: this.props.profile.looking_gameImagePath,
            looking_game_id: this.props.profile.looking_game_id,
            looking_platform: this.props.profile.looking_platform,
            looking_needed: this.props.profile.looking_needed,
            looking_needed_number: this.props.profile.looking_needed_number,
            looking_objective: this.props.profile.looking_objective,
            looking_objective_flexRequire: this.props.profile.looking_objective_flexRequire,
            looking_skill: this.props.profile.looking_skill,
            looking_comms: this.props.profile.looking_comms,
            looking_session: this.props.profile.looking_session,
            looking_session_flexRequire: this.props.profile.looking_session_flexRequire,
            looking_message: this.props.profile.looking_message,
            postTime: this.props.profile.postTime,
            isLFG: this.props.profile.isLFG,
        };
    
        // Calls alterPostData with appropriate parameters
        await alterPostData(
            this.props.userdata.inTheUniveristyProgram,
            this.props.fbsFirestore,
            this.props.userdata.userNumber,  // User number
            this.props.userdata.username,  // Username
            this.state.editingGameId, // Game Number
            updatedFields,  // Updated fields and their new values
            previousFields,  // Previous values of the fields
            this.props.profile.looking_game_id , // Previous game number
            updatePeopleArray, // Boolean used to check if the User doc should be updated.
            !this.props.profile.isLFG, // Boolean used to check if Games & GroupChats Collections should be updated.
            this.props.profile.joinGroupDiscord,
            this.props.profile. joinGroupPlatform,
            this.props.profile.joinGroupPlatformUsername,
        );

        // Calls a function in AccountPage that handles updating Post counts and Dates.
        await this.props.updatePostCounts()
    }

    // Called onClick of "Update Stats" in the Game Stats Section
    // when isEditingStats === true.
    // See comments above updateLFGPost for more context.
    // TODO: Update states that need to be updated at the end of this function.
    async updateStats(gameNumber) {
        // updatePeopleArray is a boolean to prevent Games collection from being 
        // updated under scenarious where the data isn't needed for the people array.
        // Stats do not need to be displayed in the GamePage so updatePeopleArray === false.
        const updatePeopleArray = false;

        // Constructs updatedFields object with new values if they aren't equal to the user's current field values.
        const updatedFields = {};
        if (this.state.statOneUploadText !== "" && this.state.statOneUploadText !== this.props.profile.looking_stat_one) {
            updatedFields["looking_stat_one"] = this.state.statOneUploadText;
        }
        if (this.state.statTwoUploadText !== "" && this.state.statTwoUploadText !== this.props.profile.looking_stat_two) {
            updatedFields["looking_stat_two"] = this.state.statTwoUploadText;
        }
        if (this.state.statThreeUploadText !== "" && this.state.statThreeUploadText !== this.props.profile.looking_stat_three) {
            updatedFields["looking_stat_three"] = this.state.statThreeUploadText;
        }
    
        // Constructs previousFields object with previous values to compare for updating docs.
        const previousFields = {
            looking_stat_one: this.props.profile.looking_stat_one,
            looking_stat_two: this.props.profile.looking_stat_two,
            looking_stat_three: this.props.profile.looking_stat_three,
        };
    
        // Calls alterPostData with appropriate parameters
        await alterPostData(
            this.props.userdata.inTheUniveristyProgram,
            this.props.fbsFirestore,
            this.props.userdata.userNumber,  // User number
            this.props.userdata.username,  // Username
            this.state.editingGameId, // Game Number
            updatedFields,  // Updated fields and their new values
            previousFields,  // Previous values of the fields
            this.props.profile.looking_game_id , // Previous game number
            updatePeopleArray, // Boolean used to check if the User doc should be updated.
        );
    }

    // Called onClick of "Update Ideal Teammate" in the Ideal Teammate Section
    // when isEditingIdealTeammate === true.
    // See comments above updateLFGPost for more context.
    // Updates Firestore User collection for the current user.
    async updateIdealTeammate(gameNumber) {
        // updatePeopleArray is a boolean to prevent Games collection from being 
        // updated under scenarious where the data isn't needed for the people array.
        // Ideam Teammate does not need to be displayed in the GamePage so updatePeopleArray === false.
        const updatePeopleArray = false;

        // Constructs updatedFields object with new values if they aren't equal to the user's current field values.
        const updatedFields = {};
        if (this.state.editingTeammatePlaystyle !== "" && this.state.editingTeammatePlaystyle !== this.props.profile.looking_teammate_playstyle) {
            updatedFields["looking_teammate_playstyle"] = this.state.editingTeammatePlaystyle;
        }
        if (this.state.editingTeammatePlaystyleFlexRequire !== "" && this.state.editingTeammatePlaystyleFlexRequire !== this.props.profile.looking_teammate_playstyle_flexRequire) {
            updatedFields["looking_teammate_playstyle_flexRequire"] = this.state.editingTeammatePlaystyleFlexRequire;
        }
        if (this.state.editingTeammatePersonality !== "" && this.state.editingTeammatePersonality !== this.props.profile.looking_teammate_personality) {
            updatedFields["looking_teammate_personality"] = this.state.editingTeammatePersonality;
        }
        if (this.state.editingTeammatePersonalityFlexRequire !== "" && this.state.editingTeammatePersonalityFlexRequire !== this.props.profile.looking_teammate_personality_flexRequire) {
            updatedFields["looking_teammate_personality_flexRequire"] = this.state.editingTeammatePersonalityFlexRequire;
        }
        if (this.state.editingTeammateLongterm !== "" && this.state.editingTeammateLongterm !== this.props.profile.looking_teammate_longterm) {
            updatedFields["looking_teammate_longterm"] = this.state.editingTeammateLongterm;
        }
        if (this.state.editingTeammateGender !== "" && this.state.editingTeammateGender !== this.props.profile.looking_teammate_gender) {
            updatedFields["looking_teammate_gender"] = this.state.editingTeammateGender;
        }
        if (this.state.editingTeammateAge !== "" && this.state.editingTeammateAge !== this.props.profile.looking_teammate_age) {
            updatedFields["looking_teammate_age"] = this.state.editingTeammateAge;
        }
        // TODO: maybe just disallow function if looking_teammate_message is an empty string.
        updatedFields["looking_teammate_message"] = this.state.lookingTeammateMessageUploadText === "" ? this.props.profile.looking_teammate_message : this.state.lookingTeammateMessageUploadText;
       
        // Constructs previousFields object with previous values to compare for updating docs.
        const previousFields = {
            looking_teammate_playstyle: this.props.profile.looking_teammate_playstyle,
            looking_teammate_playstyle_flexRequire: this.props.profile.looking_teammate_playstyle_flexRequire,
            looking_teammate_personality: this.props.profile.looking_teammate_personality,
            looking_teammate_personality_flexRequire: this.props.profile.looking_teammate_personality_flexRequire,
            looking_teammate_longterm: this.props.profile.looking_teammate_longterm,
            looking_teammate_gender: this.props.profile.looking_teammate_gender,
            looking_teammate_age: this.props.profile.looking_teammate_age,
            looking_teammate_message: this.props.profile.looking_teammate_message,
        };
    
        // Calls alterPostData with appropriate parameters
        await alterPostData(
            this.props.userdata.inTheUniveristyProgram,
            this.props.fbsFirestore,
            this.props.userdata.userNumber,  // User number
            this.props.userdata.username,  // Username
            this.state.editingGameId, // Game Number
            updatedFields,  // Updated fields and their new values
            previousFields,  // Previous values of the fields
            this.props.profile.looking_game_id , // Previous game number
            updatePeopleArray, // Boolean used to check if the User doc should be updated.
        );
    }

    // Called onClick of "Update About Me" in the About/Bio Section
    // when isEditingAbout === true.
    // See comments above updateLFGPost for more context.
    // Updates Firestore User collection for the current user.
    async updateAbout(gameNumber) {
        // updatePeopleArray is a boolean to prevent Games collection from being 
        // updated under scenarious where the data isn't needed for the people array.
        // About/Bio does not need to be displayed in the GamePage so updatePeopleArray === false.
        const updatePeopleArray = false;

        // Constructs updatedFields object with new values if they aren't equal to the user's current field values.
        const updatedFields = {};
        if (this.state.bioUploadText !== "" && this.state.bioUploadText !== this.props.bio) {
            updatedFields["bio"] = this.state.bioUploadText;
        }

        // Constructs previousFields object with previous values to compare for updating docs.
        const previousFields = {
            bio: this.props.bio,
        };

        // Calls alterPostData with appropriate parameters
        await alterPostData(
            this.props.userdata.inTheUniveristyProgram,
            this.props.fbsFirestore,
            this.props.userdata.userNumber,  // User number
            this.props.userdata.username,  // Username
            this.state.editingGameId, // Game Number
            updatedFields,  // Updated fields and their new values
            previousFields,  // Previous values of the fields
            this.props.profile.looking_game_id , // Previous game number
            updatePeopleArray, // Boolean used to check if the User doc should be updated.
        );
    }

    // Called onClick of "Update Favorite Games" in the Gavorite Games Section
    // when isEditingAbout === true.
    // See comments above updateLFGPost for more context.
    async updateFavoriteGames(gameNumber) {
        // updatePeopleArray is a boolean to prevent Games collection from being 
        // updated under scenarious where the data isn't needed for the people array.
        // Favorite Games does not need to be displayed in the GamePage so updatePeopleArray === false.
        const updatePeopleArray = false;

        // Constructs updatedFields object with new values
        const updatedFields = {};
        if (this.state.favoriteGamesOneUploadText !== "" && this.state.favoriteGamesOneUploadText !== this.props.profile.favoriteGamesOne) {
            updatedFields["favoriteGamesOne"] = this.state.favoriteGamesOneUploadText;
        }
        if (this.state.favoriteGamesTwoUploadText !== "" && this.state.favoriteGamesTwoUploadText !== this.props.profile.favoriteGamesTwo) {
            updatedFields["favoriteGamesTwo"] = this.state.favoriteGamesTwoUploadText;
        }
        if (this.state.favoriteGamesThreeUploadText !== "" && this.state.favoriteGamesThreeUploadText!== this.props.profile.favoriteGamesThree) {
            updatedFields["favoriteGamesThree"] = this.state.favoriteGamesThreeUploadText;
        }

        // Constructs previousFields object with previous values to compare for updating docs.
        const previousFields = {
            favoriteGamesOne: this.props.profile.favoriteGamesOne,
            favoriteGamesTwo: this.props.profile.favoriteGamesTwo,
            favoriteGamesThree: this.props.profile.favoriteGamesThree,
        };

        // Calls alterPostData with appropriate parameters
        await alterPostData(
            this.props.userdata.inTheUniveristyProgram,
            this.props.fbsFirestore,
            this.props.userdata.userNumber,  // User number
            this.props.userdata.username,  // Username
            this.state.editingGameId, // Game Number
            updatedFields,  // Updated fields and their new values
            previousFields,  // Previous values of the fields
            this.props.profile.looking_game_id , // Previous game number
            updatePeopleArray, // Boolean used to check if the User doc should be updated.
        );
    }

    // Called onClick of "Update Platforms & Interests" in the Platforms & Interests Section
    // when isEditingPlatformsInterests === true.
    // See comments above updateLFGPost for more context.
    // TODO: Might want to eventually add interests as searchable in GamePage.
    async updatePlatformsInterests(gameNumber) {
        // updatePeopleArray is a boolean to prevent Games collection from being 
        // updated under scenarious where the data isn't needed for the people array.
        const updatePeopleArray = true;

        // Constructs updatedFields object with new values if they aren't equal to the user's current field values.
        const updatedFields = {};
        if (this.state.editingFavoritePlatform !== "" && this.state.editingFavoritePlatform !== this.props.profile.favoritePlatform) {
            updatedFields["favoritePlatform"] = this.state.editingFavoritePlatform;
        }
        if (this.state.editingInterestOne !== "" && this.state.editingInterestOne !== this.props.profile.interestOne) {
            updatedFields["interestOne"] = this.state.editingInterestOne;
        }
        if (this.state.editingInterestTwo !== "" && this.state.editingInterestTwo !== this.props.profile.interestTwo) {
            updatedFields["interestTwo"] = this.state.editingInterestTwo;
        }

        // Constructs previousFields object with previous values to compare for updating docs.
        const previousFields = {
            favoritePlatform: this.props.profile.favoritePlatform,
            interestOne: this.props.profile.interestOne,
            interestTwo: this.props.profile.interestTwo,
        };

        // Calls alterPostData with appropriate parameters
        await alterPostData(
            this.props.userdata.inTheUniveristyProgram,
            this.props.fbsFirestore,
            this.props.userdata.userNumber,  // User number
            this.props.userdata.username,  // Username
            this.state.editingGameId, // Game Number
            updatedFields,  // Updated fields and their new values
            previousFields,  // Previous values of the fields
            this.props.profile.looking_game_id , // Previous game number
            updatePeopleArray, // Boolean used to check if the User doc should be updated.
            false, // newPost
            "", // joinGroupDiscord
            "", // joinGroupPlatform
            "", // joinGroupPlatformUsername
            true // updateInterestsAndBackground
        );
    }

    // Called onClick of "Update Gamertags & Socials" in the Gamertags & Socials Section
    // when isEditingGamertagsSocials === true.
    // See comments above updateLFGPost for more context.
    // TODO: Update logic in render to handle "" on editingSocialPlatforms
    async updateGamertagsSocials(gameNumber) {
        // updatePeopleArray is a boolean to prevent Games collection from being 
        // updated under scenarious where the data isn't needed for the people array.
        // Gamertags & Socials does not need to be displayed in the GamePage so updatePeopleArray === false.
        const updatePeopleArray = false;

        // Constructs updatedFields object with new values if they aren't equal to the user's current field values.
        const updatedFields = {};
        if (this.state.editingSocialPlatformOne !== "" && this.state.editingSocialPlatformOne !== this.props.profile.socialPlatformOne) {
            updatedFields["socialPlatformOne"] = this.state.editingSocialPlatformOne;
        }
        if (this.state.socialUsernameOneUploadText !== "" && this.state.socialUsernameOneUploadText !== this.props.profile.socialUsernameOne) {
            updatedFields["socialUsernameOne"] = this.state.socialUsernameOneUploadText;
        }
        if (this.state.editingSocialPlatformTwo !== "" && this.state.editingSocialPlatformTwo !== this.props.profile.socialPlatformTwo) {
            updatedFields["socialPlatformTwo"] = this.state.editingSocialPlatformTwo;
        }
        if (this.state.socialUsernameTwoUploadText !== "" && this.state.socialUsernameTwoUploadText !== this.props.profile.socialUsernameTwo) {
            updatedFields["socialUsernameTwo"] = this.state.socialUsernameTwoUploadText;
        }
        if (this.state.editingSocialPlatformThree !== "" && this.state.editingSocialPlatformThree !== this.props.profile.socialPlatformThree) {
            updatedFields["socialPlatformThree"] = this.state.editingSocialPlatformThree;
        }
        if (this.state.socialUsernameThreeUploadText !== "" && this.state.socialUsernameThreeUploadText !== this.props.profile.socialUsernameThree) {
            updatedFields["socialUsernameThree"] = this.state.socialUsernameThreeUploadText;
        }

        // Constructs previousFields object with previous values to compare for updating docs.
        const previousFields = {
            socialPlatformOne: this.props.profile.socialPlatformOne,
            socialUsernameOne: this.props.profile.socialUsernameOne,
            socialPlatformTwo: this.props.profile.socialPlatformTwo,
            socialUsernameTwo: this.props.profile.socialUsernameTwo,
            socialPlatformThree: this.props.profile.socialPlatformThree,
            socialUsernameThree: this.props.profile.socialUsernameThree,
        };

        // Calls alterPostData with appropriate parameters
        await alterPostData(
            this.props.userdata.inTheUniveristyProgram,
            this.props.fbsFirestore,
            this.props.userdata.userNumber,  // User number
            this.props.userdata.username,  // Username
            this.state.editingGameId, // Game Number
            updatedFields,  // Updated fields and their new values
            previousFields,  // Previous values of the fields
            this.props.profile.looking_game_id , // Previous game number
            updatePeopleArray, // Boolean used to check if the User doc should be updated.
        );
    }

    // Called onClick of "Update Background" in the Background Section
    // when isEditingBackground === true.
    // See comments above updateLFGPost for more context.
    async updateBackground(gameNumber) {
        // updatePeopleArray is a boolean to prevent Games collection from being 
        // updated under scenarious where the data isn't needed for the people array.
        const updatePeopleArray = true;

        // Constructs updatedFields object with new values if they aren't equal to the user's current field values.
        const updatedFields = {};
        if (this.state.editingAge !== "" && this.state.editingAge !== this.props.profile.age) {
            updatedFields["age"] = this.state.editingAge;
        }
        if (this.state.editingGender !== "" && this.state.editingGender !== this.props.profile.gender) {
            updatedFields["gender"] = this.state.editingGender;
        }
        if (this.state.editingOccupation !== "" && this.state.editingOccupation !== this.props.profile.occupation) {
            updatedFields["occupation"] = this.state.editingOccupation;
        }
        if (this.state.universityChoice !== "" && this.state.universityChoice !== this.props.profile.education) {
            updatedFields["education"] = this.state.universityChoice;
        }
        if (this.state.editingLocation !== "" && this.state.editingLocation !== this.props.profile.location) {
            updatedFields["location"] = this.state.editingLocation;
        }
        if (this.state.editingLanguage !== "" && this.state.editingLanguage !== this.props.profile.language) {
            updatedFields["language"] = this.state.editingLanguage;
        }
        if (this.state.editingweeklyPlaytime !== "" && this.state.editingweeklyPlaytime !== this.props.profile.weeklyPlaytime) {
            updatedFields["weeklyPlaytime"] = this.state.editingweeklyPlaytime;
        }

        // Constructs previousFields object with previous values to compare for updating docs.
        const previousFields = {
            age: this.props.profile.age,
            gender: this.props.profile.gender,
            occupation: this.props.profile.occupation,
            education: this.props.profile.education,
            location: this.props.profile.location,
            language: this.props.profile.language,
            weeklyPlaytime: this.props.profile.weeklyPlaytime,
        };

        // Calls alterPostData with appropriate parameters
        await alterPostData(
            this.props.userdata.inTheUniveristyProgram,
            this.props.fbsFirestore,
            this.props.userdata.userNumber,  // User number
            this.props.userdata.username,  // Username
            this.state.editingGameId, // Game Number
            updatedFields,  // Updated fields and their new values
            previousFields,  // Previous values of the fields
            this.props.profile.looking_game_id , // Previous game number
            updatePeopleArray, // Boolean used to check if the User doc should be updated.
            false, // newPost
            "", // joinGroupDiscord
            "", // joinGroupPlatform
            "", // joinGroupPlatformUsername
            true // updateInterestsAndBackground
        );
    }
    
    // Called onCLick of certain actions that plays an mp3 file from the 'Public' folder.
    // Currently set to 20% volume for all files, but can be adjusted to be higher/per file accordingly.
    // Passed down as props to children components as well.
    // TODO (eventually - not a priority): Add a "Adjust Sound Effect Volume" option in Settings,
    // so that users can set audio.volume globally however they'd like.
    playAudio(source) {
        const audio = new Audio(source);
        audio.volume = 0.2;
        audio.play();
    };

    // Changes the firestore timestamp to a real js object date.
    // Called in displayPosts() to show when/how long ago a post was made.
    formatDate(date) {
        const now = new Date();
        const pastTime = new Date(date.seconds * 1000); // Converts Firestore timestamp to Date object
        const diff = now - pastTime; // Difference in milliseconds
        const diffMinutes = Math.floor(diff / 60000); // Converts to minutes
        const diffHours = Math.floor(diffMinutes / 60); // Converts to hours
        const diffDays = Math.floor(diffHours / 24); // Converts to days
        
        // Display render of date with user's timezone
        const options = { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        const formattedDate = pastTime.toLocaleDateString('default', options);
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
        // Shows how long ago the post was in minutes, hours, or days
        let timeAgo = '';
        if (diffMinutes < 60) {
            timeAgo = `${diffMinutes} min ago`;
        } else if (diffHours < 24) {
            timeAgo = `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
        } else {
            timeAgo = `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
        }
    
        return {
            formattedDate,
            timezone,
            timeAgo
        };
    }

    // Looks for %0A URL encoded line breaks and links.
    // Handles reformatting text to wrap links in <a> tags, and
    // shows the user visual spaces with <br /> if they pressed enter or
    // shift+enter as represented by "\n".
    renderFormattedText = (text) => {
        const linkRegex = /(?:https?|ftp):\/\/[^\s]+(?:\s|$)/g;
        const lines = text.split(/(?:\n|%0A)/);
      
        const elements = [];
      
        lines.forEach((line, lineIndex) => {
            const linkMatches = line.match(linkRegex);
            if (linkMatches) {
                let lastIndex = 0;

                linkMatches.forEach((linkMatch, index) => {
                    const linkStart = line.indexOf(linkMatch, lastIndex);
                    const linkEnd = linkStart + linkMatch.length;

                    // Adds the preceding text as regular text
                    elements.push(<span key={`text-${lineIndex}-${index}`}>{line.substring(lastIndex, linkStart)}</span>);

                    // Wraps the link in an anchor
                    elements.push(
                        <a
                            key={`link-${lineIndex}-${index}`}
                            className="change-link-color-chat"
                            style={{
                                wordWrap: 'break-word',
                                wordBreak: 'break-all',
                                whiteSpace: 'normal',
                            }}
                            href={decodeURIComponent(linkMatch)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                        {decodeURIComponent(linkMatch)}
                        </a>
                    );

                    // Updates the lastIndex to the end of the current link
                    lastIndex = linkEnd;
                });
            
                // Adds the remaining text after the last link
                elements.push(<span key={`text-${lineIndex}-last`}>{line.substring(lastIndex)}</span>);
            } else {
                // If no links, add the entire line as regular text
                elements.push(<span key={`text-${lineIndex}`}>{line}</span>);
            }

            // Add the line break after each line (except the last line)
            if (lineIndex < lines.length - 1) {
                elements.push(<br key={`br-${lineIndex}`} />);
            }
        });
      
        return elements;
    };

    // Return true if game matches the current filter(s) or nameIncludeFilter === ""
    passesFilters(game) {
        if (!game) return false;
    
        if (this.state.nameIncludeFilter !== "" && game.gameName.toLowerCase().includes(this.state.nameIncludeFilter.toLowerCase()) ) return true;
    }

    // Checks for search input and updates page.
    handleSearchChange(e) {
        const searchValue = e.target.value;
        this.setState({ nameIncludeFilter: searchValue, currentPage: 1 });
    };

    // Display of games when editing and choosing a game for LFG Post.
    displayGame(game) {
        if (this.passesFilters(game)) {
            return (
                    <>
                        <div className="me-2 px-2 explore-games-background ask-a-gamer-explore-margin-right-0-mobile">
                            <div
                                className="d-flex flex-column py-3 px-4 ask-a-gamer-game-list-border ask-a-gamer-explore-padding-left-right-halfem-mobile"
                                onClick={() => {
                                    this.setState({
                                        editingGameName: game.gameName,
                                        editingGameImage: game.gameImagePath,
                                        editingGameId: game.gameNumber,
                                        nameIncludeFilter: "",
                                    });
                                }}
                            >
                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                    <div
                                        className="d-inline-flex flex-row align-items-center justify-content-center flex-wrap ask-a-gamer-explore-flex-column-mobile"
                                        style={{"width":"100%"}}
                                    >
                                        <div
                                            className="d-flex flex-row align-items-center justify-content-center me-2 ask-a-gamer-explore-margin-right-left-0-tablet"
                                            style={{"width":"200px"}}
                                        >
                                            <img
                                                src={game.gameImagePath}
                                                width="250"
                                                alt="Video Game Cover Art"
                                                style={{"width":"100px"}}
                                                className="img-fluid ask-a-gamer-explore-image-tablet"
                                            />
                                            <h5
                                                style={{"font-size":"1em", "text-align":"center"}}
                                                className="mx-3"
                                            >
                                                {game.gameName}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <hr className="ask-a-gamer-answer-spotlight-hr"/>
                    </>
            )
        } else return null;
    }

    // We have multiple large conditional renders depending on state of "isEditing ..."
    // This changes the display of sections from a standard display to one where users
    // can edit their profile and update the DB in the User & Games Collections.
    // The prop this.props.editingModeEnabled is passed from AccountPage and determines
    // if the user can edit their data.
    // * editingModeEnabled is now in here and this.state
    // TODO: Finish Editing Updates for About/Bio, Favorite Games, Platforms/Interests,
    // Gamertags/Socials, and Background sections.
    // TODO: Add and update remainder of update...() functions to pass edits to alterPostData.
    // TODO: Finish Chat component, Add Join Group Logic, Bug Testing, Styling for mobile,
    // TODO: All other TODO's in here.
    render() {
        const { formattedDate, timezone, timeAgo } = this.formatDate(this.props.profile.postTime);

        //Pagination Logic - Can be moved outside render if we want
        const startIndex = (this.state.currentPage - 1) * this.state.gamesPerPage;
        const endIndex = startIndex + this.state.gamesPerPage;
        // Sorts games alphabetically
        const sortedGames =  [...this.state.games].sort((a, b) => a.gameName.localeCompare(b.gameName));
        // Filters games based on search term.
        const filteredGames = sortedGames.filter((game) => this.passesFilters(game));
        // Displays games on the current page for pagination.
        const displayedGames = filteredGames.slice(startIndex, endIndex);
        // Calculates the total number of pages needed to display all the search results.
        const totalPages = Math.ceil(filteredGames.length / this.state.gamesPerPage);
        // Updates pagination.item (i.e. Total Page amount) to be dynamic based on search results.
        // *Update the second param, which is currently 5, to whatever when we have more games.
        // TODO: This works, but check logic to make sure it works under various circumstances.
        const pageNumbersToShow = Math.min(totalPages, 5);
        const middlePageNumber = Math.floor(pageNumbersToShow / 2);
        let firstPageNumber = Math.max(1, this.state.currentPage - middlePageNumber);
        const lastPageNumber = Math.min(totalPages, firstPageNumber + pageNumbersToShow - 1);
        // Adjusts firstPageNumber - it was disappearing when the last page didn't have 10 games for some reason.
        if (lastPageNumber === totalPages) {
          firstPageNumber = Math.max(1, lastPageNumber - pageNumbersToShow + 1);
        }

        return (
            <>
                <Stack
                    gap={2}
                    className="col-12 fluid mx-auto"
                >
                    <div className="p-2 ask-a-gamer-profile-containers-mobile mt-3">

                        {/* Only shown when a user first creates an account. */}
                        { this.props.onboardingNotFinsihed &&
                            <div
                                style={{"margin-bottom":"2em"}}
                                className={!this.state.isEditingLFGPost ? "d-flex flex-column tab-textbox-hover-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile" : "d-flex flex-column tab-textbox-edit-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile"}
                            >
                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                    <div
                                        className="d-inline-flex flex-row align-items-center flex-wrap desktop-display-flex-column"
                                        style={{"width":"100%"}}
                                    >
                                        <div
                                            style={{"width":"100%"}}
                                            className="d-flex flex-column align-items-center justify-content-center increase-demo-instruction-font-desktop"
                                        >
                                            <span
                                                style={{"font-size":"1.1em"}}
                                                className="mb-2"
                                            >
                                                Hey {this.props.userdata.username}!
                                            </span>
                                            <span
                                                style={{"font-size":"1.2em", "text-decoration":"underline", "font-style":"oblique"}}
                                                className="mt-2 mb-1"
                                            >
                                                    How Gamer Profile Works
                                            </span>
                                            <p style={{"color":"#fff1c5", "font-weight":"450"}}>
                                                Welcome to Indemni! Your Gamer Profile is where you post to find teammates and show off who you are as a gamer. Click on "Edit Your Profile & Post" to enable Edit Mode and select "Edit" above any section you wish to update. If you want to save your edits then click the "Update" button at the bottom of the sections. Otherwise, just click the red "Cancel" button.
                                            </p>
                                            <p style={{"color":"#fff1c5", "font-weight":"450"}}>
                                                To Post a group, edit your LFG Post at the top of the profile. When you update your LFG Post, it will be posted in the LFG page within the game you selected for other users to see/join. Groups you post and join are located in the Gamer Lounge page, so head over there after posting talk to group memebers. Be sure to customize as much as your profile as possible to get the best matches and teammates. This is profile though, so do you, and have fun! 
                                            </p>
                                            <span
                                                style={{"color":"#fff1c5", "font-weight":"450"}}
                                                className="mb-2"
                                            >
                                                If you need this information again, or have any other questions on how things work, everything you need to know will be located in the FAQ page. You can also join our <a href="https://discord.gg/9EZmV5qBY5" target="_blank" className="change-link-color-chat">Discord</a> to get your questions answered faster, give feedback, chat with the team, participate in events, and meet more members of the Indemni community.
                                            </span>
                                            <Button
                                                className="d-flex flex-column mx-1 p-1 align-items-center chat-with-gamer-button glow mt-3"
                                                style={{"width":"250px", "box-shadow":"#fffzfff82 0px 0px 10px 3px", "font-size":"1em", "font-weight":"550", "text-shadow":"black 0px 2px 2px", "background-color":"#39ff6ba1", "border-radius":"20px"}}
                                                onClick={async () => {
                                                    await this.props.completeAccountOnboarding();
                                                }}
                                            >
                                                Got it - Thanks!
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        {/* Beginning of normal render */}
                        { !this.props.editingModeEnabled
                          ?
                                <div
                                    style={{"font-size":"1.2em", "margin-top":"-.25em", "margin-bottom":"1.5em"}}
                                    className="d-flex flex-row align-items-center justify-content-between"
                                >
                                    <div
                                        className="d-flex flex-row align-items-center justify-content-center font-size-75-tablet"
                                        style={{"width":"100%"}}
                                    >
                                        <Button
                                            className="d-flex flex-column mx-1 p-1 align-items-center chat-with-gamer-button glow"
                                            style={{"width":"350px", "box-shadow":"#fffzfff82 0px 0px 10px 3px", "font-size":"1em", "font-weight":"550", "text-shadow":"black 0px 2px 2px", "background-color":"#42b6ffa1", "border-radius":"20px"}}
                                            onClick={async () => {
                                                await this.props.turnOnEditingMode();
                                            }}
                                        >
                                            Edit Your Profile & Post
                                        </Button>
                                    </div>
                                </div>
                              :
                                <div
                                    style={{"font-size":"1.2em", "margin-top":"-.25em", "margin-bottom":"1.5em"}}
                                    className="d-flex flex-row align-items-center justify-content-between"
                                >
                                    <div
                                        className="d-flex flex-row align-items-center justify-content-center font-size-75-tablet"
                                        style={{"width":"100%"}}
                                    >
                                        <Button
                                            className="d-flex flex-column mx-1 p-1 align-items-center chat-with-gamer-button"
                                            style={{"width":"350px", "box-shadow":"#fffzfff82 0px 0px 10px 3px", "font-size":"1em", "font-weight":"550", "text-shadow":"black 0px 2px 2px", "background-color":"#ff4242a1", "border-radius":"20px"}}
                                            onClick={async () => {
                                                await this.props.turnOffEditingMode();
                                                this.setState({
                                                    isEditingLFGPost: false,
                                                    isEditingStats: false,
                                                    isEditingIdealTeammate: false,
                                                    isEditingAbout: false,
                                                    isEditingFavoriteGames: false,
                                                    isEditingPlatformsInterests: false,
                                                    isEditingGamertagsSocials: false,
                                                    isEditingBackground: false,
                                                });
                                            }}
                                        >
                                            Turn Off Edit Mode
                                        </Button>
                                    </div>
                                </div>
                        }

                        {/* START of LFG Post */}
                        { !this.state.isEditingLFGPost
                          ?
                            <div className="new-home-profile-flex-between mt-3">
                                <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%", "text-wrap":"pretty"}}>
                                    LFG - {this.props.profile.looking_gameName === "" ? "Select a Game": this.props.profile.looking_gameName}
                                </span>
                                { this.props.editingModeEnabled &&
                                    <Button
                                        style={{"font-size":".9em", "font-weight":"600", "width":"100px"}}
                                        onClick={() => this.setState({ isEditingLFGPost: true })}
                                        className="account-profile-edit desktop-margin-left-negative6"
                                    >
                                        Edit <Pencil className={"edit-pencil"}/>
                                    </Button>
                                }
                            </div>
                          :
                            <div
                                style={{"flex-wrap":"wrap", "margin-bottom":".75em"}}
                                className="d-flex flex-row align-items-center justify-content-center"
                            >
                                <span style={{"font-size":"1.5em", "text-align":"center", "width":"100%"}}>
                                    Editing
                                </span>
                                <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%"}}>
                                    LFG  Post
                                </span>
                                { this.props.editingModeEnabled &&
                                    <Button
                                        style={{"font-size":".9em", "font-weight":"600", "width":"100px"}}
                                        onClick={() => this.setState({ isEditingLFGPost: false })}
                                        className="account-profile-cancel"
                                    >
                                        Cancel
                                    </Button>
                                }
                            </div>
                        }
                        <div
                            style={{"margin-bottom":"1em"}}
                            className={!this.state.isEditingLFGPost ? "d-flex flex-column tab-textbox-hover-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile" : "d-flex flex-column tab-textbox-edit-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile"}
                        >
                            { !this.state.isEditingLFGPost
                              ?
                                // LFG Post Standard Render
                                <>
                                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                                        <div
                                            className="d-inline-flex flex-row align-items-center flex-wrap desktop-display-flex-column"
                                            style={{"width":"100%"}}
                                        >
                                            { this.props.profile.isLFG
                                              ?
                                                <>
                                                    <div
                                                        style={{"width":"100%"}}
                                                        className="d-flex flex-column align-items-center justify-content-center"
                                                    >
                                                        <h5
                                                            style={{"text-wrap":"balance", "color":"#5bff87", "font-size":"1.5em", "font-weight":"625"}}
                                                            className="mb-3"
                                                        >
                                                            Group is Posted!
                                                        </h5>
                                                    </div>
                                                    <div
                                                        style={{"width":"100%"}}
                                                        className="d-flex flex-column align-items-center justify-content-center"
                                                    >
                                                        <div
                                                            style={{"width":"100%"}}
                                                            className="d-flex flex-column align-items-center justify-content-center mb-4"
                                                        >
                                                            <Button
                                                                className="d-flex flex-column mx-1 p-1 align-items-center standard-action-button desktop-increase-demo-gamer-profile-text-size"
                                                                style={{"width":"260px", "font-size":"1em", "font-weight":"550", "text-shadow":"black 0px 2px 2px", "text-wrap":"balance"}}
                                                                href={`/game?${this.props.profile.looking_gameName}&id=${this.props.profile.looking_game_id}`}
                                                            >
                                                                See Post in Public LFG
                                                            </Button>
                                                            { this.props.userdata.inTheUniveristyProgram &&
                                                                <Button
                                                                    className="d-flex flex-column mx-1 mt-3 p-1 align-items-center standard-action-button desktop-increase-demo-gamer-profile-text-size"
                                                                    style={{"width":"260px", "font-size":"1em", "font-weight":"550", "text-shadow":"black 0px 2px 2px", "text-wrap":"balance"}}
                                                                    href="/myuniversity#notredame-lfg"
                                                                >
                                                                    See Post in University LFG
                                                                </Button>
                                                            }
                                                        </div>
                                                    </div>
                                                </>
                                            :
                                                <div
                                                    style={{"width":"100%"}}
                                                    className="d-flex flex-column align-items-center justify-content-center"
                                                >
                                                    <h5
                                                        style={{"text-wrap":"balance", "color":"#ff9d75", "font-size":"1.5em", "font-weight":"625"}}
                                                        className="mb-3"
                                                    >
                                                        Not Posted
                                                    </h5>
                                                </div>
                                            }
                                            <div className="d-flex flex-column align-items-center justify-content-center me-2 glow ask-a-gamer-explore-margin-right-left-0-tablet desktop-no-margin-x" >
                                                <h5
                                                    style={{"text-wrap":"balance", "text-align":"center"}}
                                                    className="my-2"
                                                >
                                                    {this.props.profile.looking_gameName === "" ? "Select a Game": this.props.profile.looking_gameName}
                                                </h5>
                                                <img
                                                    src={this.props.profile.looking_gameImagePath === "" ? "/LfgGameImagePlaceholder.png": this.props.profile.looking_gameImagePath}
                                                    width="250"
                                                    alt="Video Game Cover Art"
                                                    style={{"width":"400px"}}
                                                    className="img-fluid ask-a-gamer-explore-image-tablet"
                                                />
                                                <h5 className="my-2">
                                                    {this.props.profile.looking_platform === "" ? "Any": this.props.profile.looking_platform}
                                                </h5>
                                            </div>
                                            <div
                                                className="d-flex flex-column flex-grow align-items-center justify-content-center ask-a-gamer-profile-margins-game-spotlight-mobile desktop-increase-posted-time-demo-gamer-profile"
                                                style={{"flex-grow":"1"}}
                                            >
                                                { this.props.profile.isLFG
                                                  ?
                                                <span
                                                    className="my-2"
                                                    style={{"font-size":".9em", "text-align":"center", "text-wrap":"balance"}}
                                                >
                                                    Posted on: {formattedDate + ` (${timeAgo})`}
                                                </span>
                                                 :
                                                <span
                                                    className="my-2"
                                                    style={{"font-size":".9em", "text-align":"center", "text-wrap":"balance"}}
                                                >
                                                    {(this.props.profile.looking_gameName === "") ? "Post your first LFG!": "Last Post: " + formattedDate + ` (${timeAgo})`}
                                                </span>
                                                }
                                                <span
                                                    className="list-of-stats desktop-increase-width-250"
                                                    style={{"color":"#75f7fff0", "font-weight":"600"}}
                                                >
                                                    Needed: {this.props.profile.looking_needed === "" ? "1 Gamer": this.props.profile.looking_needed}
                                                </span>
                                                <span
                                                    className="list-of-stats desktop-increase-width-250"
                                                    style={{"color":"#75f7fff0", "font-weight":"600"}}
                                                >
                                                    Start Time: {this.props.profile.looking_time === "" ? "Now": this.props.profile.looking_time}
                                                </span>
                                            </div>
                                            <hr
                                                className="ask-a-gamer-answer-spotlight-hr"
                                                style={{"width":"100%"}}
                                            />
                                            <div
                                                className="d-flex flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                                style={{"flex-grow":"1"}}
                                            >
                                                <p style={{"width":"100%"}}>
                                                    <div className="d-flex flex-row flex-wrap justify-content-between overflow-auto" >
                                                        <small
                                                            style={{"font-size":"1.2em", "color":"#ffea99", "text-align":"center", "text-shadow":"0px -3px 2px #000000", "width":"100%", "text-wrap":"pretty"}}
                                                            className="desktop-increase-demo-gamer-profile-text-size desktop-change-width-lfgposts-lookingMessage-900"
                                                        >
                                                            "{this.props.profile.looking_message === "" ? "Write about what you are looking to do, what kind of teammate you want, etc. Basically, put any information here that you feel is important for people to know that isn't already displayed in your LFG Post.": this.renderFormattedText(this.props.profile.looking_message)}"
                                                         </small>
                                                    </div>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="ask-a-gamer-answer-spotlight-hr"/>
                                    <div
                                        className="d-flex flex-row"
                                        style={{"flex-wrap":"wrap", "border":"none"}}
                                    >
                                        <div className="navuserchatInfo d-flex align-item-center ask-a-gamer-profile-mastered-games-tablet change-demo-gamer-profile-objective-comms-commitment">
                                            <div className="desktop-display-flex-column desktop-width-200">
                                                <h5 style={{"font-size":"1.1em"}}>Objective - {this.props.profile.looking_objective_flexRequire === "" ? "Flexible": this.props.profile.looking_objective_flexRequire}</h5>
                                                <div className="d-flex flex-row align-items-center justify-content-between desktop-margin-top-1">
                                                    <span style={{"padding": ".75em", "width":"100%"}}>
                                                        <div
                                                            className="tab-textbox-buttons justify-content-around px-3 py-2 text-center"
                                                            style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                                        >
                                                            {this.props.profile.looking_objective === "" ? "Any" : this.props.profile.looking_objective}
                                                            <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="desktop-display-flex-column desktop-width-200">
                                                <h5 style={{"font-size":"1.1em"}}>Skill & Comms</h5>
                                                <div className="d-flex flex-row align-items-center justify-content-between desktop-margin-top-1">
                                                    <span style={{"padding": ".75em"}}>
                                                        <div
                                                            className="tab-textbox-buttons justify-content-around px-2 py-2 text-center"
                                                            style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em", "width":"105px"}}
                                                        >
                                                            {this.props.profile.looking_skill === "" ? "Any": this.props.profile.looking_skill}
                                                            <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                        </div>
                                                    </span>
                                                    <span style={{"padding": ".75em"}}>
                                                        <div
                                                            className="tab-textbox-buttons justify-content-around px-2 py-2 text-center"
                                                            style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em", "width":"105px"}}
                                                        >
                                                            {this.props.profile.looking_comms === "" ? "Any": this.props.profile.looking_comms}
                                                            <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="desktop-display-flex-column desktop-width-200">
                                                <h5
                                                    className="desktop-width-max-content"
                                                    style={{"font-size":"1.1em"}}
                                                >
                                                    Session Length - {this.props.profile.looking_session_flexRequire === "" ? "Flexible": this.props.profile.looking_session_flexRequire}
                                                </h5>
                                                <div className="d-flex flex-row align-items-center justify-content-between desktop-margin-top-1">
                                                    <span style={{"padding": ".75em", "width":"100%"}}>
                                                        <div
                                                            className="tab-textbox-buttons justify-content-around px-3 py-2 text-center"
                                                            style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                                        >
                                                            {this.props.profile.looking_session === "" ? "Any": this.props.profile.looking_session}
                                                            <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                              :
                                // LFG Post Editing Render
                                <>
                                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                                        <div
                                            className="d-inline-flex flex-row align-items-center flex-wrap desktop-display-flex-column"
                                            style={{"width":"100%"}}
                                        >
                                            <div
                                                className="d-flex flex-column align-items-center justify-content-center me-2 glow ask-a-gamer-explore-margin-right-left-0-tablet desktop-no-margin-x desktop-increase-max-width-650"
                                                style={{"max-width":"260px"}}
                                            >
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d"}}>
                                                    Select a Game
                                                </h5>
                                                <div
                                                    style={{"width":"100%"}}
                                                    className="mb-2 d-flex flex-row justify-content-between align-items-center ask-a-gamer-explore-width-auto-mobile"
                                                >
                                                    <div
                                                        className="d-flex flex-row align-items-center justify-content-start ask-a-gamer-explore-margin-top-input-mobile hange-demo-input-div-container-align"
                                                        style={{"width":"100%"}}
                                                    >
                                                        <input
                                                            type="search"
                                                            className="ms-3 change-width-for-mobile account-profile-edit-input-mobile demo-search-bar-desktop"
                                                            style={{"vertical-align":"middle", "width":"100%", "outline":"none"}}
                                                            value={this.state.nameIncludeFilter}
                                                            onChange={this.handleSearchChange}
                                                            placeholder="Search ..."
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="You can type in a games name here and click the game picture from the list below or use the dropdown menu on the right."
                                                        />
                                                    </div>
                                                    <Dropdown
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="You can select a game with this dropdown or use the search bar on the left and click the game picture from the list below."
                                                        style={{"margin-top":".25em"}}
                                                    >
                                                        <Dropdown.Toggle className="ms-3 w-30 demo-game-select-dropdown-button-mobile">
                                                            Games
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu style={{"font-size":"1em", "text-align":"center", "min-width":"100px", "height":"300px", "overflow-y":"scroll", "background":"#212121ed"}}>
                                                        { this.state.games.map((game) => (
                                                            <Dropdown.Item
                                                                key={game.id}
                                                                eventKey={game.id}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        editingGameName: game.gameName,
                                                                        editingGameImage: game.gameImagePath,
                                                                        editingGameId: game.gameNumber,
                                                                        nameIncludeFilter: "",
                                                                    });
                                                                }}
                                                            >
                                                                {game.gameName}
                                                            </Dropdown.Item>
                                                        ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                                { this.state.editingGameName !== "" &&
                                                    <h5 style={{"font-size":"1.4em", "margin-top":".5em", "margin-bottom":".5em"}}>
                                                        {this.state.editingGameName}
                                                    </h5>
                                                }
                                                { displayedGames.map((game) => (
                                                    <div key={game.id} className="me-2 px-2 ask-a-gamer-explore-margin-padding-right-left-0-mobile">
                                                      {this.displayGame(game)}
                                                    </div>
                                                ))}
                                                <img
                                                    src={this.state.editingGameImage === "" ? "/LfgGameImagePlaceholder.png": this.state.editingGameImage}
                                                    width="250"
                                                    alt="Video Game Cover Art"
                                                    style={{"width":"400px"}}
                                                    className="img-fluid ask-a-gamer-explore-image-tablet"
                                                />
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d"}}>
                                                    Select Platform
                                                </h5>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="You can select a game with this dropdown or use the search bar on the left and click the game picture from the list below."
                                                    style={{"margin":".5em"}}
                                                >
                                                    <Dropdown.Toggle className="ms-3 w-30 account-profile-lfg-dropdown-button-mobile">
                                                        {this.state.editingPlatform === "" ? "Platform": this.state.editingPlatform}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu style={{"font-size":"1em", "text-align":"center", "min-width":"150px", "background":"#212121ed"}}>
                                                    { this.state.gamePlatformList.map((platform) => (
                                                        <Dropdown.Item
                                                            key={platform}
                                                            eventKey={platform}
                                                            onClick={() => {
                                                                this.setState({
                                                                    editingPlatform: platform,
                                                                });
                                                            }}
                                                        >
                                                            {platform}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div
                                                className="d-flex flex-column flex-grow align-items-center justify-content-center ask-a-gamer-profile-margins-game-spotlight-mobile desktop-increase-posted-time-demo-gamer-profile"
                                                style={{"flex-grow":"1"}}
                                            >
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d"}}>
                                                    Select Gamers Needed
                                                </h5>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="You can select how many teammates you need in the dropdown menu."
                                                    style={{"margin-right":".5em", "margin-top":"1em"}}
                                                >
                                                    <Dropdown.Toggle className="ms-3 w-30 account-profile-lfg-dropdown-button-mobile">
                                                        {this.state.editingNeeded === "" ? "Needed": this.state.editingNeeded}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu style={{"font-size":"1em", "text-align":"center", "min-width":"150px", "background":"#212121ed"}}>
                                                    { this.state.neededList.map((needed) => (
                                                        <Dropdown.Item
                                                            key={needed}
                                                            eventKey={needed}
                                                            onClick={() => {
                                                                this.setState({
                                                                    editingNeeded: needed,
                                                                });
                                                            }}
                                                        >
                                                            {needed}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d"}}>
                                                    Select Start Time
                                                </h5>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="You can select the time you wish start playing a game in the dropdown menu."
                                                    style={{"margin-right":".5em", "margin-top":"1em"}}
                                                >
                                                    <Dropdown.Toggle className="ms-3 w-30 account-profile-lfg-dropdown-button-mobile">
                                                        {this.state.editingStart === "" ? "Start Time": this.state.editingStart}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu style={{"font-size":"1em", "text-align":"center", "min-width":"150px", "background":"#212121ed"}}>
                                                    { this.state.startList.map((start) => (
                                                        <Dropdown.Item
                                                            key={start}
                                                            eventKey={start}
                                                            onClick={() => {
                                                                this.setState({
                                                                    editingStart: start,
                                                                });
                                                            }}
                                                        >
                                                            {start}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <hr
                                                className="ask-a-gamer-answer-spotlight-hr"
                                                style={{"width":"100%"}}
                                            />
                                            <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d", "width":"100%", "text-align":"center"}}>
                                                Update LFG Message
                                            </h5>
                                            <div
                                                className="d-flex flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                                style={{"flex-grow":"1"}}
                                            >
                                                <p style={{"width":"100%"}}>
                                                    <div className="d-flex flex-row flex-wrap justify-content-between overflow-auto" >
                                                        <textarea
                                                            value={this.state.lookingMessageDisplayText}
                                                            placeholder="Write about what you are looking to do, what kind of teammate you want, etc. Basically, put any information here that you feel is important for people to know that isn't already displayed in your LFG Post."
                                                            style={{"font-size":"1.2em", "color":"#000000", "border-radius":"10px", "height":"250px", "width":"100%"}}
                                                            className="desktop-increase-demo-gamer-profile-text-size desktop-increase-width-650"
                                                            onChange={(e) => {
                                                                const lookingMessageDisplayText = e.target.value;
                                                                const modifiedText = lookingMessageDisplayText.replace(/\n/g, '%0A'); // Uses %0A for URL-encoded newline
                                                                this.setState({
                                                                    lookingMessageUploadText: modifiedText,
                                                                    editingLookingMessage: modifiedText,
                                                                    lookingMessageDisplayText,
                                                                });
                                                            }}

                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter' || e.shiftKey) {
                                                                    const modifiedText = `${this.state.lookingMessageUploadText}%0A`;
                                                                    this.setState({
                                                                        lookingMessageUploadText: modifiedText,
                                                                        editingLookingMessage: modifiedText,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="ask-a-gamer-answer-spotlight-hr"/>
                                    <div
                                        className="d-flex flex-row"
                                        style={{"flex-wrap":"wrap", "border":"none"}}
                                    >
                                        <div className="navuserchatInfo d-flex align-item-center ask-a-gamer-profile-mastered-games-tablet change-demo-gamer-profile-objective-comms-commitment">
                                            <div className="desktop-display-flex-column">
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d"}}>
                                                    Select Objective Options
                                                </h5>
                                                <div
                                                    style={{"padding-bottom":".5em", "padding-top":".5em"}}
                                                    className="d-flex flex-row align-items-center justify-content-between desktop-margin-top-1"
                                                >
                                                    <Dropdown
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="You can select the time you wish start playing a game in the dropdown menu."
                                                        style={{"margin-bottom":".5em", "width":"100%"}}
                                                    >
                                                        <Dropdown.Toggle className="ms-3 w-30 demo-game-select-dropdown-button-mobile">
                                                            {this.state.editingObjective === "" ? "Any": this.state.editingObjective}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu style={{"font-size":"1em", "text-align":"center", "min-width":"100px", "background":"#212121ed"}}>
                                                        { this.state.objectiveList.map((objective) => (
                                                            <Dropdown.Item
                                                                key={objective}
                                                                eventKey={objective}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        editingObjective: objective,
                                                                    });
                                                                }}
                                                            >
                                                                {objective}
                                                            </Dropdown.Item>
                                                        ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <Dropdown
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="You can select the time you wish start playing a game in the dropdown menu."
                                                        style={{"margin-bottom":".5em"}}
                                                    >
                                                        <Dropdown.Toggle className="ms-3 w-30 demo-game-select-dropdown-button-mobile desktop-increase-minwidth-150">
                                                            {this.state.editingObejectiveFlexRequire === "" ? "Flexible?": this.state.editingObejectiveFlexRequire}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu
                                                            className="desktop-increase-minwidth-150"
                                                            style={{"font-size":"1em", "text-align":"center", "min-width":"100px", "background":"#212121ed"}}
                                                        >
                                                        { this.state.flexRequireList.map((flexRequire) => (
                                                            <Dropdown.Item
                                                                key={flexRequire}
                                                                eventKey={flexRequire}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        editingObejectiveFlexRequire: flexRequire,
                                                                    });
                                                                }}
                                                            >
                                                                {flexRequire}
                                                            </Dropdown.Item>
                                                        ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <div className="desktop-display-flex-column">
                                                <div className="d-flex flex-column align-items-center justify-content-between desktop-margin-top-1">
                                                    <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d"}}>
                                                        Select Skill
                                                    </h5>
                                                    <Dropdown
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="You can select the time you wish start playing a game in the dropdown menu."
                                                        style={{"margin-top":".5em"}}
                                                    >
                                                        <Dropdown.Toggle className="ms-3 w-30 account-profile-lfg-dropdown-button-mobile">
                                                            {this.state.editingSkill === "" ? "Skill": this.state.editingSkill}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu style={{"font-size":"1em", "text-align":"center", "min-width":"150px", "background":"#212121ed"}}>
                                                        { this.state.skillList.map((skill) => (
                                                            <Dropdown.Item
                                                                key={skill}
                                                                eventKey={skill}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        editingSkill: skill,
                                                                    });
                                                                }}
                                                            >
                                                                {skill}
                                                            </Dropdown.Item>
                                                        ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d"}}>
                                                        Select Comms
                                                    </h5>
                                                    <Dropdown
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="You can select the time you wish start playing a game in the dropdown menu."
                                                        style={{"margin-top":".5em", "margin-bottom":"1em"}}
                                                    >
                                                        <Dropdown.Toggle className="ms-3 w-30 account-profile-lfg-dropdown-button-mobile">
                                                            {this.state.editingComms === "" ? "Comms": this.state.editingComms}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu style={{"font-size":"1em", "text-align":"center", "min-width":"150px", "background":"#212121ed"}}>
                                                        { this.state.commsList.map((comms) => (
                                                            <Dropdown.Item
                                                                key={comms}
                                                                eventKey={comms}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        editingComms: comms,
                                                                    });
                                                                }}
                                                            >
                                                                {comms}
                                                            </Dropdown.Item>
                                                        ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <div className="desktop-display-flex-column">
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d"}}>
                                                    Select Session Options
                                                </h5>
                                                <div
                                                    style={{"padding-bottom":".5em", "padding-top":".5em"}}
                                                    className="d-flex flex-row align-items-center justify-content-between desktop-margin-top-1"
                                                >
                                                    <Dropdown
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="You can select the time you wish start playing a game in the dropdown menu."
                                                        style={{"margin-bottom":".5em", "width":"100%"}}
                                                    >
                                                        <Dropdown.Toggle className="ms-3 w-30 demo-game-select-dropdown-button-mobile">
                                                            {this.state.editingSession === "" ? "Any": this.state.editingSession}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu style={{"font-size":"1em", "text-align":"center", "min-width":"100px", "background":"#212121ed"}}>
                                                        { this.state.sessionList.map((session) => (
                                                            <Dropdown.Item
                                                                key={session}
                                                                eventKey={session}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        editingSession: session,
                                                                    });
                                                                }}
                                                            >
                                                                {session}
                                                            </Dropdown.Item>
                                                        ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <Dropdown
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="You can select the time you wish start playing a game in the dropdown menu."
                                                        style={{"margin-bottom":".5em", "width":"100%"}}
                                                    >
                                                        <Dropdown.Toggle className="ms-3 w-30 demo-game-select-dropdown-button-mobile desktop-increase-minwidth-150">
                                                            {this.state.editingSessionFlexRequire === "" ? "Flexible?": this.state.editingSessionFlexRequire}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu
                                                            className="desktop-increase-minwidth-150"
                                                            style={{"font-size":"1em", "text-align":"center", "min-width":"100px", "background":"#212121ed"}}
                                                        >
                                                        { this.state.flexRequireList.map((flexRequire) => (
                                                            <Dropdown.Item
                                                                key={flexRequire}
                                                                eventKey={flexRequire}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        editingSessionFlexRequire: flexRequire,
                                                                    });
                                                                }}
                                                            >
                                                                {flexRequire}
                                                            </Dropdown.Item>
                                                        ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            style={{"width":"100%"}}
                                            className="d-flex flex-row align-items-center justify-content-center font-size-75-tablet"
                                        >
                                            <div
                                                style={{"width":"100%"}}
                                                className="d-flex flex-column align-items-center justify-content-center font-size-75-tablet"
                                            >
                                                { this.state.editingGameName === "" && this.props.profile.looking_gameName === ""
                                                  ?
                                                    <Button
                                                        className="d-flex flex-row mx-1 p-2 mt-4 align-items-center chat-with-gamer-button desktop-increase-width-500"
                                                        style={{"width":"100%", "box-shadow":"#fffzfff82 0px 0px 10px 3px", "font-size":"1.3em", "font-weight":"600", "text-shadow":"black 0px 2px 2px", "background-color":"#ff4949e6"}}
                                                        disabled
                                                        onClick={async () => {
                                                            this.playAudio('/UpvoteSound.mp3');
                                                            await this.updateLFGPost(this.state.editingGameId !== undefined ? this.state.editingGameId : this.props.profile.looking_game_id);
                                                            this.setState({
                                                                isEditingLFGPost: false,
                                                                cooldown: true,
                                                            });
                                                        }}
                                                    >
                                                        <span style={{"width":"100%"}}>
                                                            Must Select a Game First
                                                        </span>
                                                    </Button>
                                                  :
                                                    <Button
                                                        className="d-flex flex-row mx-1 p-2 mt-4 align-items-center chat-with-gamer-button game-details-play desktop-increase-width-500"
                                                        style={{"width":"100%", "box-shadow":"#fffzfff82 0px 0px 10px 3px", "font-size":"1.3em", "font-weight":"600", "text-shadow":"black 0px 2px 2px"}}
                                                        disabled={this.state.editingGameName === "" && this.this.props.profile.looking_gameName === ""}
                                                        onClick={async () => {
                                                            this.playAudio('/UpvoteSound.mp3');
                                                            await this.updateLFGPost(this.state.editingGameId !== undefined ? this.state.editingGameId : this.props.profile.looking_game_id);
                                                            this.setState({
                                                                isEditingLFGPost: false,
                                                                cooldown: true,
                                                            });
                                                        }}
                                                    >
                                                        <span style={{"width":"100%"}}>
                                                            {this.props.profile.isLFG ? "Update LFG Post" : "Post Group"}
                                                        </span>
                                                    </Button>
                                                }   
                                                { this.props.profile.isLFG &&
                                                <Button
                                                    className="d-flex flex-row mx-1 p-2 align-items-center account-profile-cancel"
                                                    style={{"font-size":"1em", "margin-top":"1.2em", "margin-bottom":"1.2em", "font-weight":"550", "width":"200px", "margin-top":"2em"}}
                                                    onClick={ async () => {
                                                        await this.removeLFGPost();
                                                        this.setState({
                                                            isEditingLFGPost: false,
                                                            cooldown: true,
                                                        });
                                                    }}
                                                >
                                                    <span style={{"width":"100%"}}>
                                                        Remove Post From LFG
                                                    </span>
                                                </Button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    {/* END of LFG Post */}

                    {/* START of Game Stats */}
                    <div className="p-2 ask-a-gamer-profile-containers-mobile">
                        { !this.state.isEditingStats
                          ?
                            <div className="new-home-profile-flex-between">
                                { this.props.profile.looking_gameName === "" && this.state.editingGameName === ""
                                  ?
                                    <span style={{"font-size":"1.3em", "text-align":"center", "width":"100%", "text-wrap":"pretty"}}>
                                        *Post an LFG to add highlights for that game.
                                    </span>
                                  :
                                    <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%", "text-wrap":"pretty"}}>
                                        {this.state.editingGameName === "" ? this.props.profile.looking_gameName : this.state.editingGameName } Highlights
                                    </span>
                                }
                                { this.props.editingModeEnabled &&
                                
                                    <Button
                                        style={{"font-size":".9em", "font-weight":"600", "width":"100px"}}
                                        onClick={() => this.setState({ isEditingStats: true })}
                                        className="account-profile-edit desktop-margin-left-negative6"
                                        disabled={this.props.profile.looking_gameName === "" && this.state.editingGameName === ""}
                                    >
                                        Edit <Pencil className={"edit-pencil"}/>
                                    </Button>
                                }
                            </div>
                          :
                            <div
                                style={{"flex-wrap":"wrap", "margin-bottom":".75em"}}
                                className="d-flex flex-row align-items-center justify-content-center"
                            >
                                <span style={{"font-size":"1.5em", "text-align":"center", "width":"100%"}}>
                                    Editing
                                </span>
                                <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%"}}>
                                    {this.state.editingGameName === "" ? "*Select a Game First": this.state.editingGameName + " Stats"}
                                </span>
                                { this.props.editingModeEnabled &&
                                    <Button
                                        style={{"font-size":".9em", "font-weight":"600", "width":"100px"}}
                                        onClick={() => this.setState({ isEditingStats: false })}
                                        className="account-profile-cancel"
                                    >
                                        Cancel
                                    </Button>
                                }
                            </div>
                        }
                        <div
                            style={{"margin-bottom":"1em"}}
                            className="d-flex flex-column tab-textbox-hover-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile"
                        >
                            { !this.state.isEditingStats
                              ?
                                // Game Stats Standard Render
                                <>
                                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                                        <div
                                            className="d-inline-flex flex-row align-items-center flex-wrap"
                                            style={{"width":"100%"}}
                                        >
                                            <div
                                                className="d-flex flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                                style={{"flex-grow":"1"}}
                                            >
                                                <p
                                                    className="mt-1"
                                                    style={{"width":"100%"}}
                                                >
                                                    <div className="d-flex flex-row flex-wrap justify-content-between overflow-auto" >
                                                        <small
                                                            style={{"color":"#75f7fff0", "font-size":"1.2em", "font-weight":"600", "text-wrap":"pretty"}}
                                                            className="desktop-increase-demo-gamer-profile-text-size desktop-demo-gamer-profile-change-stats-gamertags"
                                                        >
                                                            👑 {this.props.profile.looking_stat_one === "" ? "Add your first highlight": this.renderFormattedText(this.props.profile.looking_stat_one)}
                                                        </small>
                                                    </div>
                                                    <hr/>
                                                    <div className="d-flex flex-row flex-wrap justify-content-between overflow-auto" >
                                                        <small
                                                            style={{"color":"#75f7fff0", "font-size":"1.2em", "font-weight":"600", "text-wrap":"pretty"}}
                                                            className="desktop-increase-demo-gamer-profile-text-size desktop-demo-gamer-profile-change-stats-gamertags"
                                                        >
                                                            👑 {this.props.profile.looking_stat_two === "" ? "Add your second highlight": this.renderFormattedText(this.props.profile.looking_stat_two)}
                                                        </small>
                                                    </div>
                                                    <hr/>
                                                    <div className="d-flex flex-row flex-wrap justify-content-between overflow-auto" >
                                                        <small
                                                            style={{"color":"#75f7fff0", "font-size":"1.2em", "font-weight":"600", "text-wrap":"pretty"}}
                                                            className="desktop-increase-demo-gamer-profile-text-size desktop-demo-gamer-profile-change-stats-gamertags"
                                                        >
                                                            👑 {this.props.profile.looking_stat_three === "" ? "Add a third highlight": this.renderFormattedText(this.props.profile.looking_stat_three)}
                                                        </small>
                                                    </div>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                              :
                                // Game Stats Editing Render
                                <>
                                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                                        <div
                                            className="d-inline-flex flex-row align-items-center flex-wrap"
                                            style={{"width":"100%"}}
                                        >
                                            <div
                                                className="d-flex flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                                style={{"flex-grow":"1"}}
                                            >
                                                <p
                                                    className="mt-1"
                                                    style={{"width":"100%"}}
                                                >
                                                    <h5 style={{"font-size":"1.3em", "margin-bottom":".75em", "text-decoration":"underline", "color":"#97ff7d", "width":"100%", "text-align":"center"}}>
                                                        Update Stat #1
                                                    </h5>
                                                    <div className="d-flex flex-row flex-wrap justify-content-center overflow-auto" >
                                                        <textarea
                                                            value={this.state.statOneDisplayText}
                                                            placeholder={"Add your first highlight"}
                                                            style={{"font-size":"1.2em", "color":"#000000", "border-radius":"10px", "height":"50px", "width":"100%"}}
                                                            className="desktop-increase-demo-gamer-profile-text-size desktop-increase-width-500 desktop-increase-height-70"
                                                            onChange={(e) => {
                                                                const statOneDisplayText = e.target.value;
                                                                const modifiedText = statOneDisplayText.replace(/\n/g, '%0A'); // Uses %0A for URL-encoded newline
                                                                this.setState({
                                                                    statOneUploadText: modifiedText,
                                                                    editingStatOne: modifiedText,
                                                                    statOneDisplayText,
                                                                });
                                                            }}

                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter' || e.shiftKey) {
                                                                    const modifiedText = `${this.state.statOneUploadText}%0A`;
                                                                    this.setState({
                                                                        statOneUploadText: modifiedText,
                                                                        editingStatOne: modifiedText,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <hr/>
                                                    <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".75em", "text-decoration":"underline", "color":"#97ff7d", "width":"100%", "text-align":"center"}}>
                                                        Update Stat #2
                                                    </h5>
                                                    <div className="d-flex flex-row flex-wrap justify-content-center overflow-auto" >
                                                        <textarea
                                                            value={this.state.statTwoDisplayText}
                                                            placeholder="Add a second highlight"
                                                            style={{"font-size":"1.2em", "color":"#000000", "border-radius":"10px", "height":"50px", "width":"100%"}}
                                                            className="desktop-increase-demo-gamer-profile-text-size desktop-increase-width-500 desktop-increase-height-70"
                                                            onChange={(e) => {
                                                                const statTwoDisplayText = e.target.value;
                                                                const modifiedText = statTwoDisplayText.replace(/\n/g, '%0A'); // Uses %0A for URL-encoded newline
                                                                this.setState({
                                                                    statTwoUploadText: modifiedText,
                                                                    editingStatTwo: modifiedText,
                                                                    statTwoDisplayText,
                                                                });
                                                            }}

                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter' || e.shiftKey) {
                                                                    const modifiedText = `${this.state.statTwoUploadText}%0A`;
                                                                    this.setState({
                                                                        statTwoUploadText: modifiedText,
                                                                        editingStatTwo: modifiedText,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                    <hr/>
                                                    <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".75em", "text-decoration":"underline", "color":"#97ff7d", "width":"100%", "text-align":"center"}}>
                                                        Update Stat #3
                                                    </h5>
                                                    <div className="d-flex flex-row flex-wrap justify-content-center overflow-auto" >
                                                        <textarea
                                                            value={this.state.statThreeDisplayText}
                                                            placeholder="Add a third highlight"
                                                            style={{"font-size":"1.2em", "color":"#000000", "border-radius":"10px", "height":"50px", "width":"100%"}}
                                                            className="desktop-increase-demo-gamer-profile-text-size desktop-increase-width-500 desktop-increase-height-70"
                                                            onChange={(e) => {
                                                                const statThreeDisplayText = e.target.value;
                                                                const modifiedText = statThreeDisplayText.replace(/\n/g, '%0A'); // Uses %0A for URL-encoded newline
                                                                this.setState({
                                                                    statThreeUploadText: modifiedText,
                                                                    editingStatThree: modifiedText,
                                                                    statThreeDisplayText,
                                                                });
                                                            }}

                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter' || e.shiftKey) {
                                                                    const modifiedText = `${this.state.statThreeUploadText}%0A`;
                                                                    this.setState({
                                                                        statThreeUploadText: modifiedText,
                                                                        editingStatThree: modifiedText,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{"width":"100%"}}
                                            className="d-flex flex-row align-items-center justify-content-center font-size-75-tablet"
                                        >
                                            <Button
                                                className="d-flex flex-column mx-1 p-2 mt-4 align-items-center chat-with-gamer-button game-details-play desktop-increase-width-500"
                                                style={{"width":"100%", "box-shadow":"#fffzfff82 0px 0px 10px 3px", "font-size":"1.3em", "font-weight":"600", "text-shadow":"black 0px 2px 2px"}}
                                                onClick={async () => {
                                                    this.playAudio('/UpvoteSound.mp3');
                                                    await this.updateStats(this.state.editingGameId);
                                                    this.setState({
                                                        isEditingStats: false,
                                                        cooldown: true,
                                                    });
                                                }}
                                            >
                                                Update Stats
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    {/* END of Game Stats */}
                    
                    {/* START of Ideal Teammate */}
                    <div className="p-2 ask-a-gamer-profile-containers-mobile mt-3">
                        { !this.state.isEditingIdealTeammate
                          ?
                            <div className="new-home-profile-flex-between">
                                <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%"}}>
                                    Ideal Teammate
                                </span>
                                { this.props.editingModeEnabled &&
                                    <Button
                                        style={{"font-size":".9em", "font-weight":"600", "width":"100px"}}
                                        onClick={() => this.setState({ isEditingIdealTeammate: true })}
                                        className="account-profile-edit desktop-margin-left-negative6"
                                    >
                                        Edit <Pencil className={"edit-pencil"}/>
                                    </Button>
                                }
                            </div>
                          :
                            <div
                                style={{"flex-wrap":"wrap", "margin-bottom":".75em"}}
                                className="d-flex flex-row align-items-center justify-content-center"
                            >
                                <span style={{"font-size":"1.5em", "text-align":"center", "width":"100%"}}>
                                    Editing
                                </span>
                                <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%"}}>
                                    Ideal Teammate
                                </span>
                                { this.props.editingModeEnabled &&
                                    <Button
                                        style={{"font-size":".9em", "font-weight":"600", "width":"100px"}}
                                        onClick={() => this.setState({ isEditingIdealTeammate: false })}
                                        className="account-profile-cancel"
                                    >
                                        Cancel
                                    </Button>
                                }
                            </div>
                        }
                        <div
                            style={{"margin-bottom":"1em"}}
                            className="d-flex flex-column tab-textbox-hover-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile"
                        >
                            { !this.state.isEditingIdealTeammate
                              ?
                                // Ideal Teammate Standard Render
                                <>
                                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                                        <div
                                            className="d-inline-flex flex-row align-items-center flex-wrap"
                                            style={{"width":"100%"}}
                                        >
                                            <div
                                                className="d-flex flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                                style={{"flex-grow":"1"}}
                                            >
                                                <p style={{"width":"100%"}}>
                                                    <div className="d-flex flex-row flex-wrap justify-content-center overflow-auto" >
                                                        <small
                                                            style={{"font-size":"1.2em", "color":"#ffea99", "text-align":"center", "text-shadow":"0px -3px 2px #000000", "text-wrap":"pretty", "width":"100%"}}
                                                            className="desktop-increase-demo-gamer-profile-text-size desktop-change-width-lfgposts-lookingMessage-900"
                                                        >
                                                            "{this.props.profile.looking_teammate_message === "" ? "You can choose to write about what your ideal teammate is here for other users to see. This can be a description of the type of person you like to play with in general, who you would want to play with for your current LFG Post, etc.": this.renderFormattedText(this.props.profile.looking_teammate_message)}"
                                                        </small>
                                                    </div>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="ask-a-gamer-answer-spotlight-hr"/>
                                    <div
                                        className="d-flex flex-row"
                                        style={{"flex-wrap":"wrap", "border":"none"}}
                                    >
                                        <div
                                            className="navuserchatInfo d-flex flex-column align-item-center ask-a-gamer-profile-mastered-games-tablet desktop-increase-font-1-quarter"
                                            style={{"width":"100%", "text-align":"center"}}
                                        >
                                            <h5 style={{"font-size":"1.1em"}}>Playstyle - {this.props.profile.looking_teammate_playstyle_flexRequire === "" ? "Flexible": this.props.profile.looking_teammate_playstyle_flexRequire}</h5>
                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                <span style={{"padding": ".75em", "width":"100%"}}>
                                                    <div
                                                        className="tab-textbox-buttons justify-content-around px-3 py-2"
                                                        style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                                    >
                                                        {this.props.profile.looking_teammate_playstyle === "" ? "Any": this.props.profile.looking_teammate_playstyle}
                                                        <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                    </div>
                                                </span>
                                            </div>
                                            {/* TODO: This is different on Demo where "Personality - Require or Flexible", will need to update this somehow later */}
                                            <h5 style={{"font-size":"1.1em"}}>Personality - {this.props.profile.looking_teammate_personality_flexRequire === "" ? "Flexible": this.props.profile.looking_teammate_personality_flexRequire}</h5>
                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                <span style={{"padding": ".75em", "width":"100%"}}>
                                                    <div
                                                        className="tab-textbox-buttons justify-content-around px-3 py-2"
                                                        style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                                    >
                                                        {this.props.profile.looking_teammate_personality === "" ? "Any": this.props.profile.looking_teammate_personality}
                                                        <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                    </div>
                                                </span>
                                            </div>
                                            <h5 style={{"font-size":"1.1em"}}>Long-term Teammate?</h5>
                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                <span style={{"padding": ".75em", "width":"100%"}}>
                                                    <div
                                                        className="tab-textbox-buttons justify-content-around px-3 py-2"
                                                        style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                                    >
                                                        {this.props.profile.looking_teammate_longterm === "" ? "Maybe": this.props.profile.looking_teammate_longterm}
                                                        <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                    </div>
                                                </span>
                                            </div>
                                            <h5 style={{"font-size":"1.1em"}}>Gender</h5>
                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                <span style={{"padding": ".75em", "width":"100%"}}>
                                                    <div
                                                        className="tab-textbox-buttons justify-content-around px-3 py-2"
                                                        style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                                    >
                                                        {this.props.profile.looking_teammate_gender === "" ? "Any": this.props.profile.looking_teammate_gender}
                                                        <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                    </div>
                                                </span>
                                            </div>
                                            <h5 style={{"font-size":"1.1em"}}>Age</h5>
                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                <span style={{"padding": ".75em", "width":"100%"}}>
                                                    <div
                                                        className="tab-textbox-buttons justify-content-around px-3 py-2"
                                                        style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                                    >
                                                        {this.props.profile.looking_teammate_age === "" ? "Any": this.props.profile.looking_teammate_age}
                                                        <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </>
                              :
                                // Ideal Teammate Editing Render
                                <>
                                    <div className="d-flex justify-content-between align-items-center flex-wrap">
                                        <div
                                            className="d-inline-flex flex-row align-items-center flex-wrap"
                                            style={{"width":"100%"}}
                                        >
                                            <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d", "width":"100%", "text-align":"center"}}>
                                                Update Ideal
                                                <br/>
                                                Teammate Message
                                            </h5>
                                            <div
                                                className="d-flex flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                                style={{"flex-grow":"1"}}
                                            >
                                                <p style={{"width":"100%"}}>
                                                    <div className="d-flex flex-row flex-wrap justify-content-between overflow-auto" >
                                                        <textarea
                                                            value={this.state.lookingTeammateMessageDisplayText}
                                                            placeholder="You can choose to write about what your ideal teammate is here for other users to see. This can be a description of the type of person you like to play with in general, who you would want to play with for your current LFG Post, etc."
                                                            style={{"font-size":"1.2em", "color":"#000000", "border-radius":"10px", "height":"250px", "width":"100%"}}
                                                            className="desktop-increase-demo-gamer-profile-text-size"
                                                            onChange={(e) => {
                                                                const lookingTeammateMessageDisplayText = e.target.value;
                                                                const modifiedText = lookingTeammateMessageDisplayText.replace(/\n/g, '%0A'); // Uses %0A for URL-encoded newline
                                                                this.setState({
                                                                    lookingTeammateMessageUploadText: modifiedText,
                                                                    editinglookingTeammateMessage: modifiedText,
                                                                    lookingTeammateMessageDisplayText,
                                                                });
                                                            }}

                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter' || e.shiftKey) {
                                                                    const modifiedText = `${this.state.lookingTeammateMessageUploadText}%0A`;
                                                                    this.setState({
                                                                        lookingTeammateMessageUploadText: modifiedText,
                                                                        editinglookingTeammateMessage: modifiedText,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr className="ask-a-gamer-answer-spotlight-hr"/>
                                    <div
                                        className="d-flex flex-row"
                                        style={{"flex-wrap":"wrap", "border":"none"}}
                                    >
                                        <div
                                            className="navuserchatInfo d-flex flex-column align-item-center ask-a-gamer-profile-mastered-games-tablet desktop-increase-font-1-quarter"
                                            style={{"width":"100%", "text-align":"center"}}
                                        >
                                            <div className="d-flex flex-column align-items-center justify-content-between">
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d"}}>
                                                    Update Ideal Teammate's Playstyle Options
                                                </h5>
                                                <div
                                                    style={{"padding-bottom":".5em", "padding-top":".5em"}}
                                                    className="d-flex flex-row align-items-center justify-content-between desktop-margin-top-1"
                                                >
                                                    <Dropdown
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="You can select the time you wish start playing a game in the dropdown menu."
                                                        style={{"margin-bottom":".5em", "width":"100%"}}
                                                    >
                                                        <Dropdown.Toggle className="ms-3 w-30 demo-game-select-dropdown-button-mobile">
                                                            {this.state.editingTeammatePlaystyle === "" ? "Any": this.state.editingTeammatePlaystyle}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu style={{"font-size":"1em", "text-align":"center", "min-width":"100px", "background":"#212121ed"}}>
                                                        { this.state.playstyleList.map((playstyle) => (
                                                            <Dropdown.Item
                                                                key={playstyle}
                                                                eventKey={playstyle}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        editingTeammatePlaystyle: playstyle,
                                                                    });
                                                                }}
                                                            >
                                                                {playstyle}
                                                            </Dropdown.Item>
                                                        ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <Dropdown
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="You can select the time you wish start playing a game in the dropdown menu."
                                                        style={{"margin-bottom":".5em", "width":"100%"}}
                                                    >
                                                        <Dropdown.Toggle className="ms-3 w-30 demo-game-select-dropdown-button-mobile desktop-increase-minwidth-150">
                                                            {this.state.editingTeammatePlaystyleFlexRequire === "" ? "Flexible?": this.state.editingTeammatePlaystyleFlexRequire}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu
                                                            className="desktop-increase-minwidth-150"
                                                            style={{"font-size":"1em", "text-align":"center", "min-width":"100px", "background":"#212121ed"}}
                                                        >
                                                        { this.state.flexRequireList.map((flexRequire) => (
                                                            <Dropdown.Item
                                                                key={flexRequire}
                                                                eventKey={flexRequire}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        editingTeammatePlaystyleFlexRequire: flexRequire,
                                                                    });
                                                                }}
                                                            >
                                                                {flexRequire}
                                                            </Dropdown.Item>
                                                        ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column align-items-center justify-content-between">
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d"}}>
                                                    Update Ideal Teammate's Personality Options
                                                </h5>
                                                <div
                                                    style={{"padding-bottom":".5em", "padding-top":".5em"}}
                                                    className="d-flex flex-row align-items-center justify-content-between desktop-margin-top-1"
                                                >
                                                    <Dropdown
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="You can select the time you wish start playing a game in the dropdown menu."
                                                        style={{"margin-bottom":".5em", "width":"100%"}}
                                                    >
                                                        <Dropdown.Toggle className="ms-3 w-30 demo-game-select-dropdown-button-mobile">
                                                            {this.state.editingTeammatePersonality === "" ? "Any": this.state.editingTeammatePersonality}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu style={{"font-size":"1em", "text-align":"center", "min-width":"100px", "background":"#212121ed"}}>
                                                        { this.state.personalityList.map((personality) => (
                                                            <Dropdown.Item
                                                                key={personality}
                                                                eventKey={personality}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        editingTeammatePersonality: personality,
                                                                    });
                                                                }}
                                                            >
                                                                {personality}
                                                            </Dropdown.Item>
                                                        ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <Dropdown
                                                        data-toggle="tooltip"
                                                        data-placement="top"
                                                        title="You can select the time you wish start playing a game in the dropdown menu."
                                                        style={{"margin-bottom":".5em", "width":"100%"}}
                                                    >
                                                        <Dropdown.Toggle className="ms-3 w-30 demo-game-select-dropdown-button-mobile desktop-increase-minwidth-150">
                                                            {this.state.editingTeammatePersonalityFlexRequire === "" ? "Flexible?": this.state.editingTeammatePersonalityFlexRequire}
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu
                                                            className="desktop-increase-minwidth-150"
                                                            style={{"font-size":"1em", "text-align":"center", "min-width":"100px", "background":"#212121ed"}}
                                                        >
                                                        { this.state.flexRequireList.map((flexRequire) => (
                                                            <Dropdown.Item
                                                                key={flexRequire}
                                                                eventKey={flexRequire}
                                                                onClick={() => {
                                                                    this.setState({
                                                                        editingTeammatePersonality: flexRequire,
                                                                    });
                                                                }}
                                                            >
                                                                {flexRequire}
                                                            </Dropdown.Item>
                                                        ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                            <div className="d-flex flex-column align-items-center justify-content-between desktop-margin-top-1">
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d"}}>
                                                    Looking For a Long-term Teammate?
                                                </h5>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="You can select the time you wish start playing a game in the dropdown menu."
                                                    style={{"margin-top":".5em"}}
                                                >
                                                    <Dropdown.Toggle className="ms-3 w-30 account-profile-lfg-dropdown-button-mobile">
                                                        {this.state.editingTeammateLongterm === "" ? "Maybe": this.state.editingTeammateLongterm}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu style={{"font-size":"1em", "text-align":"center", "min-width":"150px", "background":"#212121ed"}}>
                                                    { this.state.longtermList.map((longterm) => (
                                                        <Dropdown.Item
                                                            key={longterm}
                                                            eventKey={longterm}
                                                            onClick={() => {
                                                                this.setState({
                                                                    editingTeammateLongterm: longterm,
                                                                });
                                                            }}
                                                        >
                                                            {longterm}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d"}}>
                                                    Select Ideal Teammate's Gender
                                                </h5>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="You can select the time you wish start playing a game in the dropdown menu."
                                                    style={{"margin-top":".5em"}}
                                                >
                                                    <Dropdown.Toggle className="ms-3 w-30 account-profile-lfg-dropdown-button-mobile">
                                                        {this.state.editingTeammateGender === "" ? "Any": this.state.editingTeammateGender}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu style={{"font-size":"1em", "text-align":"center", "min-width":"150px", "background":"#212121ed"}}>
                                                    { this.state.idealTeammateGenderList.map((gender) => (
                                                        <Dropdown.Item
                                                            key={gender}
                                                            eventKey={gender}
                                                            onClick={() => {
                                                                this.setState({
                                                                    editingTeammateGender: gender,
                                                                });
                                                            }}
                                                        >
                                                            {gender}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d"}}>
                                                    Select Ideal Teammate's Age
                                                </h5>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="You can select the time you wish start playing a game in the dropdown menu."
                                                    style={{"margin-top":".5em"}}
                                                >
                                                    <Dropdown.Toggle className="ms-3 w-30 account-profile-lfg-dropdown-button-mobile">
                                                        {this.state.editingTeammateAge === "" ? "Any": this.state.editingTeammateAge}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu style={{"font-size":"1em", "text-align":"center", "min-width":"150px", "background":"#212121ed"}}>
                                                    { this.state.idealTeammateAgeList.map((age) => (
                                                        <Dropdown.Item
                                                            key={age}
                                                            eventKey={age}
                                                            onClick={() => {
                                                                this.setState({
                                                                    editingTeammateAge: age,
                                                                });
                                                            }}
                                                        >
                                                            {age}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div
                                            style={{"width":"100%"}}
                                            className="d-flex flex-row align-items-center justify-content-center font-size-75-tablet"
                                        >
                                            <Button
                                                className="d-flex flex-column mx-1 p-2 mt-4 align-items-center chat-with-gamer-button game-details-play desktop-increase-width-500"
                                                style={{"width":"100%", "box-shadow":"#fffzfff82 0px 0px 10px 3px", "font-size":"1.3em", "font-weight":"600", "text-shadow":"black 0px 2px 2px"}}
                                                onClick={async () => {
                                                    this.playAudio('/UpvoteSound.mp3');
                                                    await this.updateIdealTeammate(this.state.editingGameId !== undefined ? this.state.editingGameId : this.props.profile.looking_game_id);
                                                    this.setState({
                                                        isEditingIdealTeammate: false,
                                                        cooldown: true,
                                                    });
                                                }}
                                            >
                                                Update Ideal Teammate
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    {/* END of Ideal Teammate */}

                    {/* START of About/Bio */}
                    <div className="p-2 ask-a-gamer-profile-containers-mobile">
                        { !this.state.isEditingAbout
                          ?
                            <div className="new-home-profile-flex-between">
                                <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%", "text-wrap":"pretty"}}>
                                    About Me
                                </span>
                                { this.props.editingModeEnabled &&
                                    <Button
                                        style={{"font-size":".9em", "font-weight":"600", "width":"100px"}}
                                        onClick={() => this.setState({ isEditingAbout: true })}
                                        className="account-profile-edit desktop-margin-left-negative6"
                                    >
                                        Edit <Pencil className={"edit-pencil"}/>
                                    </Button>
                                }
                            </div>
                          :
                            <div
                                style={{"flex-wrap":"wrap", "margin-bottom":".75em"}}
                                className="d-flex flex-row align-items-center justify-content-center"
                            >
                                <span style={{"font-size":"1.5em", "text-align":"center", "width":"100%"}}>
                                    Editing
                                </span>
                                <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%"}}>
                                    About Me
                                </span>
                                { this.props.editingModeEnabled &&
                                    <Button
                                        style={{"font-size":".9em", "font-weight":"600", "width":"100px"}}
                                        onClick={() => this.setState({ isEditingAbout: false })}
                                        className="account-profile-cancel"
                                    >
                                        Cancel
                                    </Button>
                                }
                            </div>
                        }
                        <div
                            className={`d-flex flex-column align-items-center justify-content-center tab-textbox-hover-variation py-3 px-4  ${this.state.isEditing ? 'editing' : ''}`}
                            style={{"font-weight":"600", "text-align":"center"}}
                        >
                            { !this.state.isEditingAbout
                              ?
                                // About/Bio Standard Render
                                <>
                                <span
                                    style={{"font-size":"1.2em", "color":"#ffea99", "text-align":"center", "text-shadow":"0px -3px 2px #000000", "text-wrap":"pretty"}}
                                    className="desktop-increase-demo-gamer-profile-text-size desktop-change-width-lfgposts-lookingMessage-900"
                                >
                                    "{this.props.profile.bio === "" ? "Write about yourself here. You can write whatever you want (within reason...): who you are, what games you like, promote yourself, etc." : this.renderFormattedText(this.props.profile.bio)}"
                                </span>
                                </>
                              :
                                // About/Bio Editing Render
                                <>
                                    <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d", "width":"100%", "text-align":"center"}}>
                                        Update About Me
                                    </h5>
                                    <div
                                        className="d-flex flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                        style={{"flex-grow":"1", "width":"100%"}}
                                    >
                                        <p style={{"width":"100%"}}>
                                            <div className="d-flex flex-row flex-wrap justify-content-between overflow-auto" >
                                                <textarea
                                                    value={this.state.bioDisplayText}
                                                    placeholder="Write about yourself here. You can write whatever you want (within reason...): who you are, what games you like, promote yourself, etc."
                                                    style={{"font-size":"1.2em", "color":"#000000", "border-radius":"10px", "height":"250px", "width":"100%"}}
                                                    className="desktop-increase-demo-gamer-profile-text-size"
                                                    onChange={(e) => {
                                                        const bioDisplayText = e.target.value;
                                                        const modifiedText = bioDisplayText.replace(/\n/g, '%0A'); // Uses %0A for URL-encoded newline
                                                        this.setState({
                                                            bioUploadText: modifiedText,
                                                            editingBioText: modifiedText,
                                                            bioDisplayText,
                                                        });
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter' || e.shiftKey) {
                                                            const modifiedText = `${this.state.bioUploadText}%0A`;
                                                            this.setState({
                                                                bioUploadText: modifiedText,
                                                                editingBioText: modifiedText,
                                                            });
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </p>
                                    </div>
                                    <div
                                        style={{"width":"100%"}}
                                        className="d-flex flex-row align-items-center justify-content-center font-size-75-tablet"
                                    >
                                        <Button
                                            className="d-flex flex-column mx-1 p-2 mt-4 align-items-center chat-with-gamer-button game-details-play desktop-increase-width-500"
                                            style={{"width":"100%", "box-shadow":"#fffzfff82 0px 0px 10px 3px", "font-size":"1.3em", "font-weight":"600", "text-shadow":"black 0px 2px 2px"}}
                                            onClick={async () => {
                                                this.playAudio('/UpvoteSound.mp3');
                                                await this.updateAbout(this.state.editingGameId);
                                                this.setState({
                                                    isEditingAbout: false,
                                                    cooldown: true,
                                                });
                                            }}
                                        >
                                            Update About Me
                                        </Button>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    {/* END of About/Bio */}

                    {/* START of Favorite Games*/}
                    <div className="p-2 ask-a-gamer-profile-containers-mobile">
                        { !this.state.isEditingFavoriteGames
                          ?
                            <div className="new-home-profile-flex-between">
                                <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%", "text-wrap":"pretty"}}>
                                    Favorite Games
                                </span>
                                { this.props.editingModeEnabled &&
                                    <Button
                                        style={{"font-size":".9em", "font-weight":"600", "width":"100px"}}
                                        onClick={() => this.setState({ isEditingFavoriteGames: true })}
                                        className="account-profile-edit desktop-margin-left-negative6"
                                    >
                                        Edit <Pencil className={"edit-pencil"}/>
                                    </Button>
                                }
                            </div>
                          :
                            <div
                                style={{"flex-wrap":"wrap", "margin-bottom":".75em"}}
                                className="d-flex flex-row align-items-center justify-content-center"
                            >
                                <span style={{"font-size":"1.5em", "text-align":"center", "width":"100%"}}>
                                    Editing
                                </span>
                                <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%"}}>
                                    Favorite Games
                                </span>
                                <span style={{"font-size":".8em", "margin-top":".25em", "text-align":"center", "width":"100%"}}>
                                    *Until we have added all the games back to our database, you may write in your favorite games
                                </span>
                                { this.props.editingModeEnabled &&
                                    <Button
                                        style={{"font-size":".9em", "font-weight":"600", "width":"100px"}}
                                        onClick={() => this.setState({ isEditingFavoriteGames: false })}
                                        className="account-profile-cancel"
                                    >
                                        Cancel
                                    </Button>
                                }
                            </div>
                        }
                        { !this.state.isEditingFavoriteGames
                          ?
                            // Favorite Games Standard Render
                            <>
                                <div
                                    className="d-flex flex-row tab-textbox-hover-variation py-3 px-3 desktop-width-100-align-justify-center"
                                    style={{"flex-wrap":"wrap", "border":"none"}}
                                >
                                    <div
                                        className="navuserchatInfo d-flex align-item-center ask-a-gamer-profile-mastered-games-tablet desktop-width-100-align-justify-center"
                                    >
                                        <span style={{"padding": "1em"}}>
                                            <div
                                                className="tab-textbox-buttons justify-content-around px-4 py-2"
                                                style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":"1.1em", "text-wrap":"pretty"}}
                                            >
                                                {this.props.profile.favoriteGamesOne === "" ? "Add a game" : this.renderFormattedText(this.props.profile.favoriteGamesOne)}
                                                <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                            </div>
                                        </span>
                                        <span style={{"padding": "1em"}}>
                                            <div
                                                className="tab-textbox-buttons justify-content-around px-4 py-2"
                                                style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":"1.1em", "text-wrap":"pretty"}}
                                            >
                                                {this.props.profile.favoriteGamesTwo === "" ? "Add a game" : this.renderFormattedText(this.props.profile.favoriteGamesTwo)}
                                                <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                            </div>
                                        </span>
                                        <span style={{"padding": "1em"}}>
                                            <div
                                                className="tab-textbox-buttons justify-content-around px-4 py-2"
                                                style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":"1.1em", "text-wrap":"pretty"}}
                                            >
                                                {this.props.profile.favoriteGamesThree === "" ? "Add a game" : this.renderFormattedText(this.props.profile.favoriteGamesThree)}
                                                <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </>
                          :
                            // Favorite Games Editing Render
                            <>
                            <div
                                style={{"margin-bottom":"1em"}}
                                className="d-flex flex-column tab-textbox-hover-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile"
                            >
                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                    <div
                                        className="d-inline-flex flex-row align-items-center flex-wrap"
                                        style={{"width":"100%"}}
                                    >
                                        <div
                                            className="d-flex flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                            style={{"flex-grow":"1"}}
                                        >
                                            <p
                                                className="mt-1"
                                                style={{"width":"100%"}}
                                            >
                                                <h5 style={{"font-size":"1.3em", "margin-bottom":".75em", "text-decoration":"underline", "color":"#97ff7d", "width":"100%", "text-align":"center"}}>
                                                    Update Favorite Game #1
                                                </h5>
                                                <div className="d-flex flex-row flex-wrap justify-content-center overflow-auto" >
                                                    <textarea
                                                        value={this.state.favoriteGamesOneDisplayText}
                                                        placeholder="Add a favorite game!"
                                                        style={{"font-size":"1.2em", "color":"#000000", "border-radius":"10px", "height":"50px", "width":"100%"}}
                                                        className="desktop-increase-demo-gamer-profile-text-size desktop-increase-height-70 desktop-increase-width-500"
                                                        onChange={(e) => {
                                                            const favoriteGamesOneDisplayText = e.target.value;
                                                            const modifiedText = favoriteGamesOneDisplayText.replace(/\n/g, '%0A'); // Uses %0A for URL-encoded newline
                                                            this.setState({
                                                                favoriteGamesOneUploadText: modifiedText,
                                                                editingfavoriteGamesOne: modifiedText,
                                                                favoriteGamesOneDisplayText,
                                                            });
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter' || e.shiftKey) {
                                                                const modifiedText = `${this.state.favoriteGamesOneUploadText}%0A`;
                                                                this.setState({
                                                                    favoriteGamesOneUploadText: modifiedText,
                                                                    editingfavoriteGamesOne: modifiedText,
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <hr/>
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".75em", "text-decoration":"underline", "color":"#97ff7d", "width":"100%", "text-align":"center"}}>
                                                    Update Favorite Game #2
                                                </h5>
                                                <div className="d-flex flex-row flex-wrap justify-content-center overflow-auto" >
                                                    <textarea
                                                        value={this.state.favoriteGamesTwoDisplayText}
                                                        placeholder="Add a favorite game!"
                                                        style={{"font-size":"1.2em", "color":"#000000", "border-radius":"10px", "height":"50px", "width":"100%"}}
                                                        className="desktop-increase-demo-gamer-profile-text-size desktop-increase-height-70 desktop-increase-width-500"
                                                        onChange={(e) => {
                                                            const favoriteGamesTwoDisplayText = e.target.value;
                                                            const modifiedText = favoriteGamesTwoDisplayText.replace(/\n/g, '%0A'); // Uses %0A for URL-encoded newline
                                                            this.setState({
                                                                favoriteGamesTwoUploadText: modifiedText,
                                                                editingfavoriteGamesTwo: modifiedText,
                                                                favoriteGamesTwoDisplayText,
                                                            });
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter' || e.shiftKey) {
                                                                const modifiedText = `${this.state.favoriteGamesTwoUploadText}%0A`;
                                                                this.setState({
                                                                    favoriteGamesTwoUploadText: modifiedText,
                                                                    editingfavoriteGamesTwo: modifiedText,
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <hr/>
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".75em", "text-decoration":"underline", "color":"#97ff7d", "width":"100%", "text-align":"center"}}>
                                                    Update Favorite Game #3
                                                </h5>
                                                <div className="d-flex flex-row flex-wrap justify-content-center overflow-auto" >
                                                    <textarea
                                                        value={this.state.favoriteGamesThreeDisplayText}
                                                        placeholder="Add a favorite game!"
                                                        style={{"font-size":"1.2em", "color":"#000000", "border-radius":"10px", "height":"50px", "width":"100%"}}
                                                        className="desktop-increase-demo-gamer-profile-text-size desktop-increase-height-70 desktop-increase-width-500"
                                                        onChange={(e) => {
                                                            const favoriteGamesThreeDisplayText = e.target.value;
                                                            const modifiedText = favoriteGamesThreeDisplayText.replace(/\n/g, '%0A'); // Uses %0A for URL-encoded newline
                                                            this.setState({
                                                                favoriteGamesThreeUploadText: modifiedText,
                                                                editingfavoriteGamesThree: modifiedText,
                                                                favoriteGamesThreeDisplayText,
                                                            });
                                                        }}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'Enter' || e.shiftKey) {
                                                                const modifiedText = `${this.state.favoriteGamesThreeUploadText}%0A`;
                                                                this.setState({
                                                                    favoriteGamesThreeUploadText: modifiedText,
                                                                    editingfavoriteGamesThree: modifiedText,
                                                                });
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        style={{"width":"100%"}}
                                        className="d-flex flex-row align-items-center justify-content-center font-size-75-tablet"
                                    >
                                        <Button
                                            className="d-flex flex-column mx-1 p-2 mt-4 align-items-center chat-with-gamer-button game-details-play desktop-increase-width-500"
                                            style={{"width":"100%", "box-shadow":"#fffzfff82 0px 0px 10px 3px", "font-size":"1.3em", "font-weight":"600", "text-shadow":"black 0px 2px 2px"}}
                                            onClick={async () => {
                                                this.playAudio('/UpvoteSound.mp3');
                                                await this.updateFavoriteGames(this.state.editingGameId);
                                                this.setState({
                                                    isEditingFavoriteGames: false,
                                                    cooldown: true,
                                                });
                                            }}
                                        >
                                            Update Favorite Games
                                        </Button>
                                    </div>
                                </div>
                            </div>
                            </>
                        }
                    </div>
                    {/* END of Favorite Games*/}
                    
                    {/* START of Platforms & Interests*/}
                    <div className="p-2 ask-a-gamer-profile-containers-mobile">
                        { !this.state.isEditingPlatformsInterests
                          ?
                            <div className="new-home-profile-flex-between">
                                <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%", "text-wrap":"pretty"}}>
                                    Platforms & Interests
                                </span>
                                { this.props.editingModeEnabled &&
                                    <Button
                                        style={{"font-size":".9em", "font-weight":"600", "width":"100px"}}
                                        onClick={() => this.setState({ isEditingPlatformsInterests: true })}
                                        className="account-profile-edit desktop-margin-left-negative6"
                                    >
                                        Edit <Pencil className={"edit-pencil"}/>
                                    </Button>
                                }
                            </div>
                          :
                            <div
                                style={{"flex-wrap":"wrap", "margin-bottom":".75em"}}
                                className="d-flex flex-row align-items-center justify-content-center"
                            >
                                <span style={{"font-size":"1.5em", "text-align":"center", "width":"100%"}}>
                                    Editing
                                </span>
                                <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%"}}>
                                    Platforms & Interests
                                </span>
                                { this.props.editingModeEnabled &&
                                    <Button
                                        style={{"font-size":".9em", "font-weight":"600", "width":"100px"}}
                                        onClick={() => this.setState({ isEditingPlatformsInterests: false })}
                                        className="account-profile-cancel"
                                    >
                                        Cancel
                                    </Button>
                                }
                            </div>
                        }
                        <div className="d-flex flex-column tab-textbox-hover-variation py-3 px-4 increase-home-profile-stats">
                            <div style={{"font-size":"1.1em", "font-weight":"600"}}>
                                <div
                                    className="d-flex my-1 justify-content-around align-items-center desktop-increase-font-1-quarter"
                                    style={{"flex-wrap":"wrap"}}
                                >
                                    { !this.state.isEditingPlatformsInterests
                                      ?
                                        // Platforms & Interests Standard Render
                                        <>
                                            <span className="list-of-stats desktop-increase-width-250">
                                                {this.props.profile.favoritePlatform === "" ? "Add your favorite platform": this.props.profile.favoritePlatform}
                                            </span>
                                            <span className="list-of-stats desktop-increase-width-250">
                                                {this.props.profile.interestOne === "" ? "Add your first Interest": this.props.profile.interestOne}
                                            </span>
                                            <span className="list-of-stats desktop-increase-width-250">
                                                {this.props.profile.interestTwo === "" ? "Add a second Interest": this.props.profile.interestTwo}
                                            </span>
                                        </>
                                      :
                                        // Platforms & Interests Editing Render
                                        <>
                                            <div className="d-flex justify-content-center align-items-center flex-wrap desktop-display-flex-column">
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d"}}>
                                                    Select Favorite Platform
                                                </h5>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="You can select the time you wish start playing a game in the dropdown menu."
                                                    style={{"margin-top":".5em"}}
                                                >
                                                    <Dropdown.Toggle className="ms-3 w-30 account-profile-large-width-button-mobile">
                                                        {this.state.editingFavoritePlatform === "" ? "Platform": this.state.editingFavoritePlatform}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu style={{"font-size":"1em", "text-align":"center", "min-width":"200px", "background":"#212121ed"}}>
                                                    { this.state.gamePlatformList.map((platform) => (
                                                        <Dropdown.Item
                                                            key={platform}
                                                            eventKey={platform}
                                                            onClick={() => {
                                                                this.setState({
                                                                    editingFavoritePlatform: platform,
                                                                });
                                                            }}
                                                        >
                                                            {platform}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d"}}>
                                                    Highlight First Interest
                                                </h5>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="You can select the time you wish start playing a game in the dropdown menu."
                                                    style={{"margin-top":".5em", "margin-bottom":"1em"}}
                                                >
                                                    <Dropdown.Toggle className="ms-3 w-30 account-profile-large-width-button-mobile">
                                                        {this.state.editingInterestOne === "" ? "First Interest": this.state.editingInterestOne}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu
                                                        className="desktop-increase-height-500"
                                                        style={{"font-size":"1em", "text-align":"center", "min-width":"200px", "background":"#212121ed", "height":"200px", "overflow-y":"scroll"}}
                                                    >
                                                    { this.state.interestList.map((interestOne) => (
                                                        <Dropdown.Item
                                                            key={interestOne}
                                                            eventKey={interestOne}
                                                            onClick={() => {
                                                                this.setState({
                                                                    editingInterestOne: interestOne,
                                                                });
                                                            }}
                                                        >
                                                            {interestOne}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d"}}>
                                                    Highlight Second Interest
                                                </h5>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="You can select the time you wish start playing a game in the dropdown menu."
                                                    style={{"margin-top":".5em", "margin-bottom":"1em"}}
                                                >
                                                    <Dropdown.Toggle className="ms-3 w-30 account-profile-large-width-button-mobile">
                                                        {this.state.editingInterestTwo === "" ? "Second Interest": this.state.editingInterestTwo}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu
                                                        className="desktop-increase-height-500"
                                                        style={{"font-size":"1em", "text-align":"center", "min-width":"200px", "background":"#212121ed", "height":"200px", "overflow-y":"scroll"}}
                                                    >
                                                    { this.state.interestList.map((interestTwo) => (
                                                        <Dropdown.Item
                                                            key={interestTwo}
                                                            eventKey={interestTwo}
                                                            onClick={() => {
                                                                this.setState({
                                                                    editingInterestTwo: interestTwo,
                                                                });
                                                            }}
                                                        >
                                                            {interestTwo}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div
                                                style={{"width":"100%"}}
                                                className="d-flex flex-row align-items-center justify-content-center font-size-75-tablet"
                                            >
                                                <Button
                                                    className="d-flex flex-column mx-1 p-2 mt-4 align-items-center chat-with-gamer-button game-details-play desktop-increase-width-500"
                                                    style={{"width":"100%", "box-shadow":"#fffzfff82 0px 0px 10px 3px", "font-size":"1.3em", "font-weight":"600", "text-shadow":"black 0px 2px 2px"}}
                                                    onClick={async () => {
                                                        this.playAudio('/UpvoteSound.mp3');
                                                        await this.updatePlatformsInterests(this.state.editingGameId);
                                                        this.setState({
                                                            isEditingPlatformsInterests: false,
                                                            cooldown: true,
                                                        });
                                                    }}
                                                >
                                                    Update Platforms & Interests
                                                </Button>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* END of Platforms & Interests*/}
                    
                    {/* START of Gamertags & Socials*/}
                    <div className="p-2 ask-a-gamer-profile-containers-mobile">
                        { !this.state.isEditingGamertagsSocials
                          ?
                            <div className="new-home-profile-flex-between">
                                <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%", "text-wrap":"pretty"}}>
                                    Gamertags & Social Usernames
                                </span>
                                { this.props.editingModeEnabled &&
                                    <Button
                                        style={{"font-size":".9em", "font-weight":"600", "width":"100px"}}
                                        onClick={() => this.setState({ isEditingGamertagsSocials: true })}
                                        className="account-profile-edit desktop-margin-left-negative6"
                                    >
                                        Edit <Pencil className={"edit-pencil"}/>
                                    </Button>
                                }
                            </div>
                          :
                            <div
                                style={{"flex-wrap":"wrap", "margin-bottom":".75em"}}
                                className="d-flex flex-row align-items-center justify-content-center"
                            >
                                <span style={{"font-size":"1.5em", "text-align":"center", "width":"100%"}}>
                                    Editing
                                </span>
                                <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%"}}>
                                    Gamertags & Social Usernames
                                </span>
                                { this.props.editingModeEnabled &&
                                    <Button
                                        style={{"font-size":".9em", "font-weight":"600", "width":"100px"}}
                                        onClick={() => this.setState({ isEditingGamertagsSocials: false })}
                                        className="account-profile-cancel"
                                    >
                                        Cancel
                                    </Button>
                                }
                            </div>
                        }
                        <div
                            style={{"margin-bottom":"1em"}}
                            className="d-flex flex-column tab-textbox-hover-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile"
                        >
                            { !this.state.isEditingGamertagsSocials
                              ?
                                // Gamertags & Socials Standard Render
                                <>
                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                    <div
                                        className="d-inline-flex flex-row align-items-center flex-wrap"
                                        style={{"width":"100%"}}
                                    >
                                        <div
                                            className="d-flex flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                            style={{"flex-grow":"1"}}
                                        >
                                            <p
                                                className="mt-1"
                                                style={{"width":"100%"}}
                                            >
                                                <div className="d-flex flex-row flex-wrap justify-content-between overflow-auto" >
                                                    <small
                                                        style={{"color":"#75f7fff0", "font-size":"1.2em", "font-weight":"600", "text-wrap":"pretty"}}
                                                        className="desktop-increase-demo-gamer-profile-text-size desktop-demo-gamer-profile-change-stats-gamertags"
                                                    >
                                                        👑 {this.props.profile.socialPlatformOne === "" ? "Add platform": this.props.profile.socialPlatformOne}: {this.props.profile.socialUsernameOne === "" ? "Add username": this.renderFormattedText(this.props.profile.socialUsernameOne)}
                                                    </small>
                                                </div>
                                                <hr/>
                                                <div className="d-flex flex-row flex-wrap justify-content-between overflow-auto" >
                                                    <small
                                                        style={{"color":"#75f7fff0", "font-size":"1.2em", "font-weight":"600", "text-wrap":"pretty"}}
                                                        className="desktop-increase-demo-gamer-profile-text-size desktop-demo-gamer-profile-change-stats-gamertags"
                                                    >
                                                        👑 {this.props.profile.socialPlatformTwo === "" ? "Add platform": this.props.profile.socialPlatformTwo}: {this.props.profile.socialUsernameTwo === "" ? "Add username": this.renderFormattedText(this.props.profile.socialUsernameTwo)}
                                                    </small>
                                                </div>
                                                <hr/>
                                                <div className="d-flex flex-row flex-wrap justify-content-between overflow-auto" >
                                                    <small
                                                        style={{"color":"#75f7fff0", "font-size":"1.2em", "font-weight":"600", "text-wrap":"pretty"}}
                                                        className="desktop-increase-demo-gamer-profile-text-size desktop-demo-gamer-profile-change-stats-gamertags"
                                                    >
                                                        👑 {this.props.profile.socialPlatformThree === "" ? "Add platform": this.props.profile.socialPlatformThree}: {this.props.profile.socialUsernameThree === "" ? "Add username": this.renderFormattedText(this.props.profile.socialUsernameThree)}
                                                    </small>
                                                </div>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                </>
                              :
                                // Gamertags & Socials Editing Render
                                <>
                                    <div
                                        className="d-flex flex-row"
                                        style={{"flex-wrap":"wrap", "border":"none"}}
                                    >
                                        <div
                                            className="navuserchatInfo d-flex flex-column align-item-center ask-a-gamer-profile-mastered-games-tablet desktop-increase-font-1-quarter"
                                            style={{"width":"100%", "text-align":"center"}}
                                        >
                                            <div className="d-flex flex-column align-items-center justify-content-between">
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d"}}>
                                                    Update Gamertag or
                                                    <br/>
                                                    Social Username #1
                                                </h5>
                                                <div
                                                    style={{"padding-bottom":".5em", "padding-top":".5em"}}
                                                    className="d-flex flex-row align-items-center justify-content-between desktop-margin-top-1"
                                                >
                                                    <div className="d-flex flex-column flex-wrap justify-content-between">
                                                        <Dropdown
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="You can select the time you wish start playing a game in the dropdown menu."
                                                            style={{"margin-bottom":"1.5em", "width":"100%"}}
                                                        >
                                                            <Dropdown.Toggle className="ms-3 w-30 account-profile-large-width-button-mobile desktop-increase-minwidth-400">
                                                                {this.state.editingSocialPlatformOne === "" ? "Gaming/Social Platform #1": this.state.editingSocialPlatformOne}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu
                                                                className="desktop-increase-minwidth-400 desktop-increase-height-300"
                                                                style={{"font-size":"1em", "text-align":"center", "min-width":"200px", "background":"#212121ed", "height":"150px", "overflow-y":"scroll"}}
                                                            >
                                                            { this.state.gamingSocialsPlatformList.map((gamingSocialOne) => (
                                                                <Dropdown.Item
                                                                    key={gamingSocialOne}
                                                                    eventKey={gamingSocialOne}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            editingSocialPlatformOne: gamingSocialOne,
                                                                        });
                                                                    }}
                                                                >
                                                                    {gamingSocialOne}
                                                                </Dropdown.Item>
                                                            ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        <div className="d-flex flex-row flex-wrap justify-content-between overflow-auto" >
                                                            <textarea
                                                                value={this.state.socialUsernameOneDisplayText}
                                                                placeholder={"Add your username"}
                                                                style={{"font-size":"1.2em", "color":"#000000", "border-radius":"10px", "height":"50px", "width":"100%"}}
                                                                className="desktop-increase-demo-gamer-profile-text-size desktop-increase-height-70"
                                                                onChange={(e) => {
                                                                    const socialUsernameOneDisplayText = e.target.value;
                                                                    const modifiedText = socialUsernameOneDisplayText.replace(/\n/g, '%0A'); // Uses %0A for URL-encoded newline
                                                                    this.setState({
                                                                        socialUsernameOneUploadText: modifiedText,
                                                                        editingSocialUsernameOne: modifiedText,
                                                                        socialUsernameOneDisplayText,
                                                                    });
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter' || e.shiftKey) {
                                                                        const modifiedText = `${this.state.socialUsernameOneUploadText}%0A`;
                                                                        this.setState({
                                                                            socialUsernameOneUploadText: modifiedText,
                                                                            editingSocialUsernameOne: modifiedText,
                                                                        });
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className="d-flex flex-column align-items-center justify-content-between">
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d"}}>
                                                    Update Gamertag or
                                                    <br/>
                                                    Social Username #2
                                                </h5>
                                                <div
                                                    style={{"padding-bottom":".5em", "padding-top":".5em"}}
                                                    className="d-flex flex-row align-items-center justify-content-between desktop-margin-top-1"
                                                >
                                                    <div className="d-flex flex-column flex-wrap justify-content-between">
                                                        <Dropdown
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="You can select the time you wish start playing a game in the dropdown menu."
                                                            style={{"margin-bottom":"1.5em", "width":"100%"}}
                                                        >
                                                            <Dropdown.Toggle className="ms-3 w-30 account-profile-large-width-button-mobile desktop-increase-minwidth-400">
                                                                {this.state.editingSocialPlatformTwo === "" ? "Gaming/Social Platform #2": this.state.editingSocialPlatformTwo}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu
                                                                className="desktop-increase-minwidth-400 desktop-increase-height-300"
                                                                style={{"font-size":"1em", "text-align":"center", "min-width":"200px", "background":"#212121ed", "height":"150px", "overflow-y":"scroll"}}
                                                            >
                                                            { this.state.gamingSocialsPlatformList.map((gamingSocialTwo) => (
                                                                <Dropdown.Item
                                                                    key={gamingSocialTwo}
                                                                    eventKey={gamingSocialTwo}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            editingSocialPlatformTwo: gamingSocialTwo,
                                                                        });
                                                                    }}
                                                                >
                                                                    {gamingSocialTwo}
                                                                </Dropdown.Item>
                                                            ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        <div className="d-flex flex-row flex-wrap justify-content-between overflow-auto">
                                                            <textarea
                                                                value={this.state.socialUsernameTwoDisplayText}
                                                                placeholder={"Add your username"}
                                                                style={{"font-size":"1.2em", "color":"#000000", "border-radius":"10px", "height":"50px", "width":"100%"}}
                                                                className="desktop-increase-demo-gamer-profile-text-size desktop-increase-height-70"
                                                                onChange={(e) => {
                                                                    const socialUsernameTwoDisplayText = e.target.value;
                                                                    const modifiedText = socialUsernameTwoDisplayText.replace(/\n/g, '%0A'); // Uses %0A for URL-encoded newline
                                                                    this.setState({
                                                                        socialUsernameTwoUploadText: modifiedText,
                                                                        editingSocialUsernameTwo: modifiedText,
                                                                        socialUsernameTwoDisplayText,
                                                                    });
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter' || e.shiftKey) {
                                                                        const modifiedText = `${this.state.socialUsernameTwoUploadText}%0A`;
                                                                        this.setState({
                                                                            socialUsernameTwoUploadText: modifiedText,
                                                                            editingSocialUsernameTwo: modifiedText,
                                                                        });
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div className="d-flex flex-column align-items-center justify-content-between">
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d"}}>
                                                    Update Gamertag or
                                                    <br/>
                                                    Social Username #3
                                                </h5>
                                                <div
                                                    style={{"padding-bottom":".5em", "padding-top":".5em"}}
                                                    className="d-flex flex-row align-items-center justify-content-between desktop-margin-top-1"
                                                >
                                                    <div className="d-flex flex-column flex-wrap justify-content-between">
                                                        <Dropdown
                                                            data-toggle="tooltip"
                                                            data-placement="top"
                                                            title="You can select the time you wish start playing a game in the dropdown menu."
                                                            style={{"margin-bottom":"1.5em", "width":"100%"}}
                                                        >
                                                            <Dropdown.Toggle className="ms-3 w-30 account-profile-large-width-button-mobile desktop-increase-minwidth-400">
                                                                {this.state.editingSocialPlatformThree === "" ? "Gaming/Social Platform #3": this.state.editingSocialPlatformThree}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu
                                                                className="desktop-increase-minwidth-400 desktop-increase-height-300"
                                                                style={{"font-size":"1em", "text-align":"center", "min-width":"200px", "background":"#212121ed", "height":"150px", "overflow-y":"scroll"}}
                                                            >
                                                            { this.state.gamingSocialsPlatformList.map((gamingSocialThree) => (
                                                                <Dropdown.Item
                                                                    key={gamingSocialThree}
                                                                    eventKey={gamingSocialThree}
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            editingSocialPlatformThree: gamingSocialThree,
                                                                        });
                                                                    }}
                                                                >
                                                                    {gamingSocialThree}
                                                                </Dropdown.Item>
                                                            ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        <div className="d-flex flex-row flex-wrap justify-content-between overflow-auto" >
                                                            <textarea
                                                                value={this.state.socialUsernameThreeDisplayText}
                                                                placeholder={"Add your username"}
                                                                style={{"font-size":"1.2em", "color":"#000000", "border-radius":"10px", "height":"50px", "width":"100%"}}
                                                                className="desktop-increase-demo-gamer-profile-text-size desktop-increase-height-70"
                                                                onChange={(e) => {
                                                                    const socialUsernameThreeDisplayText = e.target.value;
                                                                    const modifiedText = socialUsernameThreeDisplayText.replace(/\n/g, '%0A'); // Uses %0A for URL-encoded newline
                                                                    this.setState({
                                                                        socialUsernameThreeUploadText: modifiedText,
                                                                        editingSocialUsernameThree: modifiedText,
                                                                        socialUsernameThreeDisplayText,
                                                                    });
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    if (e.key === 'Enter' || e.shiftKey) {
                                                                        const modifiedText = `${this.state.socialUsernameThreeUploadText}%0A`;
                                                                        this.setState({
                                                                            socialUsernameThreeUploadText: modifiedText,
                                                                            editingSocialUsernameThree: modifiedText,
                                                                        });
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            style={{"width":"100%"}}
                                            className="d-flex flex-row align-items-center justify-content-center font-size-75-tablet"
                                        >
                                            <Button
                                                className="d-flex flex-column mx-1 p-2 mt-4 align-items-center chat-with-gamer-button game-details-play desktop-increase-width-500"
                                                style={{"width":"100%", "box-shadow":"#fffzfff82 0px 0px 10px 3px", "font-size":"1.3em", "font-weight":"600", "text-shadow":"black 0px 2px 2px", "text-wrap":"balance"}}
                                                onClick={async () => {
                                                    this.playAudio('/UpvoteSound.mp3');
                                                    await this.updateGamertagsSocials(this.state.editingGameId !== undefined ? this.state.editingGameId : this.props.profile.looking_game_id);
                                                    this.setState({
                                                        isEditingGamertagsSocials: false,
                                                        cooldown: true,
                                                    });
                                                }}
                                            >
                                                Update Gamertags & Social Usernames
                                            </Button>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    {/* END of Gamertags & Socials*/}

                    {/* START of Background*/}
                    <div className="p-2 ask-a-gamer-profile-containers-mobile">
                        { !this.state.isEditingBackground
                          ?
                            <div className="new-home-profile-flex-between">
                                <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%", "text-wrap":"pretty"}}>
                                    Background
                                </span>
                                { this.props.editingModeEnabled &&
                                    <Button
                                        style={{"font-size":".9em", "font-weight":"600", "width":"100px"}}
                                        onClick={() => this.setState({ isEditingBackground: true })}
                                        className="account-profile-edit desktop-margin-left-negative6"
                                    >
                                        Edit <Pencil className={"edit-pencil"}/>
                                    </Button>
                                }
                            </div>
                          :
                            <div
                                style={{"flex-wrap":"wrap", "margin-bottom":".75em"}}
                                className="d-flex flex-row align-items-center justify-content-center"
                            >
                                <span style={{"font-size":"1.5em", "text-align":"center", "width":"100%"}}>
                                    Editing
                                </span>
                                <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%"}}>
                                    Background
                                </span>
                                { this.props.editingModeEnabled &&
                                    <Button
                                        style={{"font-size":".9em", "font-weight":"600", "width":"100px"}}
                                        onClick={() => this.setState({ isEditingBackground: false })}
                                        className="account-profile-cancel"
                                    >
                                        Cancel
                                    </Button>
                                }
                            </div>
                        }
                        { !this.state.isEditingBackground
                          ?
                            // Background Standard Render
                            <>
                                <div
                                    className="d-flex flex-row tab-textbox-hover-variation py-3 px-3"
                                    style={{"flex-wrap":"wrap", "border":"none"}}
                                >
                                    <div
                                        className="navuserchatInfo d-flex flex-column align-item-center ask-a-gamer-profile-mastered-games-tablet desktop-increase-font-1-quarter"
                                        style={{"width":"100%", "text-align":"center"}}
                                    >
                                        <h5 style={{"font-size":"1.1em"}}>Age</h5>
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <span style={{"padding": ".75em", "width":"100%"}}>
                                                <div
                                                    className="tab-textbox-buttons justify-content-around px-3 py-2"
                                                    style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                                >
                                                    {this.props.profile.age === "" ? "Private" : this.props.profile.age}
                                                    <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                </div>
                                            </span>
                                        </div>
                                        <h5 style={{"font-size":"1.1em"}}>Gender</h5>
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <span style={{"padding": ".75em", "width":"100%"}}>
                                                <div
                                                    className="tab-textbox-buttons justify-content-around px-3 py-2"
                                                    style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                                >
                                                    {this.props.profile.gender === "" ? "Private" : this.props.profile.gender}
                                                    <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                </div>
                                            </span>
                                        </div>
                                        <h5 style={{"font-size":"1.1em"}}>Occupation</h5>
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <span style={{"padding": ".75em", "width":"100%"}}>
                                                <div
                                                    className="tab-textbox-buttons justify-content-around px-3 py-2"
                                                    style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                                >
                                                    {this.props.profile.occupation === "" ? "Private" : this.props.profile.occupation}
                                                    <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                </div>
                                            </span>
                                        </div>
                                        <h5 style={{"font-size":"1.1em"}}>Education</h5>
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <span style={{"padding": ".75em", "width":"100%"}}>
                                                <div
                                                    className="tab-textbox-buttons justify-content-around px-3 py-2"
                                                    style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                                >
                                                    {this.props.profile.education === "" ? "Private" : this.props.profile.education}
                                                    <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                </div>
                                            </span>
                                        </div>
                                        <h5 style={{"font-size":"1.1em"}}>Location</h5>
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <span style={{"padding": ".75em", "width":"100%"}}>
                                                <div
                                                    className="tab-textbox-buttons justify-content-around px-3 py-2"
                                                    style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                                >
                                                    {this.props.profile.location === "" ? "Must be set!": this.props.profile.location}
                                                    <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                </div>
                                            </span>
                                        </div>
                                        <h5 style={{"font-size":"1.1em"}}>Language</h5>
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <span style={{"padding": ".75em", "width":"100%"}}>
                                                <div
                                                    className="tab-textbox-buttons justify-content-around px-3 py-2"
                                                    style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                                >
                                                    {this.props.profile.language === "" ? "Private" : this.props.profile.language}
                                                    <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                </div>
                                            </span>
                                        </div>
                                        <h5 style={{"font-size":"1.1em"}}>Weekly Playtime</h5>
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <span style={{"padding": ".75em", "width":"100%"}}>
                                                <div
                                                    className="tab-textbox-buttons justify-content-around px-3 py-2"
                                                    style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                                >
                                                    {this.props.profile.weeklyPlaytime === "" ? "Private" : this.props.profile.weeklyPlaytime}
                                                    <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </>
                          :
                            // Background Editing Render
                            <>
                                <div className="d-flex flex-column tab-textbox-hover-variation py-3 px-4 increase-home-profile-stats">
                                    <div style={{"font-size":"1.1em", "font-weight":"600"}}>
                                        <div
                                            className="d-flex my-1 justify-content-around align-items-center desktop-increase-font-1-quarter"
                                            style={{"flex-wrap":"wrap"}}
                                        >
                                            <div className="d-flex justify-content-center align-items-center flex-wrap">
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d", "width":"100%", "text-align":"center", "width":"100%"}}>
                                                    Update Your Age
                                                </h5>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="You can select the time you wish start playing a game in the dropdown menu."
                                                    style={{"margin-top":".5em"}}
                                                >
                                                    <Dropdown.Toggle className="ms-3 w-30 account-profile-large-width-button-mobile">
                                                        {this.state.editingAge === "" ? "Private": this.state.editingAge}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu style={{"font-size":"1em", "text-align":"center", "min-width":"200px", "background":"#212121ed"}}>
                                                    { this.state.ageList.map((age) => (
                                                        <Dropdown.Item
                                                            key={age}
                                                            eventKey={age}
                                                            onClick={() => {
                                                                this.setState({
                                                                    editingAge: age,
                                                                });
                                                            }}
                                                        >
                                                            {age}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d", "width":"100%", "text-align":"center"}}>
                                                    Update Your Gender
                                                </h5>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="You can select the time you wish start playing a game in the dropdown menu."
                                                    style={{"margin-top":".5em", "margin-bottom":"1em"}}
                                                >
                                                    <Dropdown.Toggle className="ms-3 w-30 account-profile-large-width-button-mobile">
                                                        {this.state.editingGender === "" ? "Private": this.state.editingGender}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu style={{"font-size":"1em", "text-align":"center", "min-width":"200px", "background":"#212121ed", "height":"200px", "overflow-y":"scroll"}}>
                                                    { this.state.genderList.map((gender) => (
                                                        <Dropdown.Item
                                                            key={gender}
                                                            eventKey={gender}
                                                            onClick={() => {
                                                                this.setState({
                                                                    editingGender: gender,
                                                                });
                                                            }}
                                                        >
                                                            {gender}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d", "width":"100%", "text-align":"center"}}>
                                                    Update Your Occupation
                                                </h5>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="You can select the time you wish start playing a game in the dropdown menu."
                                                    style={{"margin-top":".5em", "margin-bottom":"1em"}}
                                                >
                                                    <Dropdown.Toggle className="ms-3 w-30 account-profile-large-width-button-mobile">
                                                        {this.state.editingOccupation === "" ? "Private": this.state.editingOccupation}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu
                                                        className="desktop-increase-height-500"
                                                        style={{"font-size":"1em", "text-align":"center", "min-width":"200px", "background":"#212121ed", "height":"360px", "text-wrap":"balance", "overflow-y":"scroll"}}
                                                    >
                                                    { this.state.occupationList.map((occupation) => (
                                                        <Dropdown.Item
                                                            style={{"text-wrap":"balance", "border-top":"solid 1px black","border-bottom":"solid 1px black"}}
                                                            key={occupation}
                                                            eventKey={occupation}
                                                            onClick={() => {
                                                                this.setState({
                                                                    editingOccupation: occupation,
                                                                });
                                                            }}
                                                        >
                                                            {occupation}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d", "width":"100%", "text-align":"center"}}>
                                                    Update Your Education
                                                </h5>
                                                {this.state.educationSelected ? (
                                                    <div>
                                                        <h5 style={{ "color":"#ffe4a1", "text-shadow":"1px 1px 2px black", "text-wrap":"pretty", "font-size":"1.1em", "margin-bottom":"1em", "margin-top":".75em", "width":"100%", "text-align":"center" }}>
                                                        <span style={{"text-decoration":"underline"}}>
                                                            Selected
                                                        </span>
                                                        <br/>
                                                        <br/>
                                                        {this.state.universityChoice}
                                                        </h5>
                                                        <Button
                                                        style={{"width":"100%"}}
                                                        className="game-details-information"
                                                        onClick={this.handleChangeEducation}>
                                                            Change Education Again
                                                        </Button>
                                                    </div>
                                                ) : (
                                                <UniversitySearch
                                                    universities={this.state.universities}
                                                    countryFilter={this.state.countryFilter}
                                                    universityNameIncludeFilter={this.state.universityNameIncludeFilter}
                                                    universityCurrentPage={this.state.universityCurrentPage}
                                                    universitiesPerPage={this.state.universitiesPerPage}
                                                    handleCountryChange={this.handleCountryChange}
                                                    handleUniversitySearchChange={this.handleUniversitySearchChange}
                                                    handlePageChange={this.handlePageChange}
                                                    handleUniversityClick={this.handleUniversityClick} // Pass the new handler
                                                />
                                                )}
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d", "width":"100%", "text-align":"center"}}>
                                                    Update Your Location
                                                </h5>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="You can select the time you wish start playing a game in the dropdown menu."
                                                    style={{"margin-top":".5em", "margin-bottom":"1em"}}
                                                >
                                                    <Dropdown.Toggle className="ms-3 w-30 account-profile-large-width-button-mobile">
                                                        {this.state.editingLocation === "" ? "Private": this.state.editingLocation}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu style={{"font-size":"1em", "text-align":"center", "min-width":"200px", "background":"#212121ed", "height":"200px", "overflow-y":"scroll"}}>
                                                    { this.state.locationList.map((location) => (
                                                        <Dropdown.Item
                                                            key={location}
                                                            eventKey={location}
                                                            onClick={() => {
                                                                this.setState({
                                                                    editingLocation: location,
                                                                });
                                                            }}
                                                        >
                                                            {location}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d", "width":"100%", "text-align":"center"}}>
                                                    Update Your Language
                                                </h5>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="You can select the time you wish start playing a game in the dropdown menu."
                                                    style={{"margin-top":".5em", "margin-bottom":"1em"}}
                                                >
                                                    <Dropdown.Toggle className="ms-3 w-30 account-profile-large-width-button-mobile">
                                                        {this.state.editingLanguage === "" ? "Private": this.state.editingLanguage}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu style={{"font-size":"1em", "text-align":"center", "min-width":"200px", "background":"#212121ed", "height":"200px", "overflow-y":"scroll"}}>
                                                    { this.state.languageList.map((language) => (
                                                        <Dropdown.Item
                                                            key={language}
                                                            eventKey={language}
                                                            onClick={() => {
                                                                this.setState({
                                                                    editingLanguage: language,
                                                                });
                                                            }}
                                                        >
                                                            {language}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <h5 style={{"font-size":"1.3em", "margin-top":".75em", "margin-bottom":".5em", "text-decoration":"underline", "color":"#97ff7d", "width":"100%", "text-align":"center"}}>
                                                    Update Weekly Playtime
                                                </h5>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="You can select the time you wish start playing a game in the dropdown menu."
                                                    style={{"margin-top":".5em", "margin-bottom":"1em"}}
                                                >
                                                    <Dropdown.Toggle className="ms-3 w-30 account-profile-large-width-button-mobile">
                                                        {this.state.editingweeklyPlaytime === "" ? "Private": this.state.editingweeklyPlaytime}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu style={{"font-size":"1em", "text-align":"center", "min-width":"200px", "background":"#212121ed", "height":"200px", "overflow-y":"scroll"}}>
                                                    { this.state.weeklyPlaytimeList.map((playtime) => (
                                                        <Dropdown.Item
                                                            key={playtime}
                                                            eventKey={playtime}
                                                            onClick={() => {
                                                                this.setState({
                                                                    editingweeklyPlaytime: playtime,
                                                                });
                                                            }}
                                                        >
                                                            {playtime}
                                                        </Dropdown.Item>
                                                    ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div
                                                style={{"width":"100%"}}
                                                className="d-flex flex-row align-items-center justify-content-center font-size-75-tablet"
                                            >
                                                <Button
                                                    className="d-flex flex-column mx-1 p-2 mt-4 align-items-center chat-with-gamer-button game-details-play desktop-increase-width-500"
                                                    style={{"width":"100%", "box-shadow":"#fffzfff82 0px 0px 10px 3px", "font-size":"1.3em", "font-weight":"600", "text-shadow":"black 0px 2px 2px"}}
                                                    onClick={async () => {
                                                        this.playAudio('/UpvoteSound.mp3');
                                                        await this.updateBackground(this.state.editingGameId);
                                                        this.setState({
                                                            isEditingBackground: false,
                                                            cooldown: true,
                                                        });
                                                    }}
                                                >
                                                    Update Background
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    {/* END of Background*/}

                </Stack>
            </>
        );
    }
}

export default AccountProfile;
