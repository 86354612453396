/* The FAQ Page (Frequently Asked Questions)
 *
 * This page is where people should go to find answers to any questions
 * that may commonly arise.
 */

import React, { Component } from "react"
import { Accordion, Button, Offcanvas, Row, Col } from "react-bootstrap"
import { Discord, Twitter, Youtube, Instagram } from "react-bootstrap-icons";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { doc, getDoc, collectionGroup, where, getDocs, query } from "firebase/firestore";
import BasicNavbar from "../components/Navbar/Navbar"


class FAQPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            user: undefined,
            userdata: undefined,
            showSidebar: true, // shows sidebar - set to true initially so its open when page loads
        }

        this.logout = this.logout.bind(this)
        this.setUser = this.setUser.bind(this)

        this.handleSidebarClose = this.handleSidebarClose.bind(this)
        this.handleSidebarShow = this.handleSidebarShow.bind(this)

        onAuthStateChanged(this.props.fbsAuth, async user => {
            if (user) {
                await this.setUser(user)
            }
            else {
                this.logout();
            }
        })
    }

    logout() {
        /* If not logged in, don't try to log out. */
        if (this.state.user === undefined) return;

        signOut(this.props.fbsAuth)
            .then(() => {
                this.setState({
                    user: undefined,
                    readonlyUserdata: undefined,
                    writableUserdata: undefined,
                    hiddenWritableUserdata: undefined
                }, () => {
                    console.log("Logged out!")
                })
            })
            .catch((error) => {
                // TODO: What do we do if signing out fails?
                console.error("Could not sign out!")
                console.error(`FIREBASEAUTH (${error.code}): ${error.message}`)
                console.error(error)
            })
    }

    async setUser(newUser) {
        return new Promise(async resolve => {
            // Somewhat validate new user
            if (typeof newUser !== "object" || typeof newUser.uid === "undefined") resolve(false);

            const userReadonlyQuery = query(
                collectionGroup(this.props.fbsFirestore, "Readonly"),
                where("uid", "==", newUser.uid)
            );

            const userReadonlySnapshots = await getDocs(userReadonlyQuery);
            if (userReadonlySnapshots.empty) {
                console.error(`Login failed: Could not find user in database with uid == ${newUser.uid}`)
                resolve(false)
                return;
            }

            const userReadonlySnapshot = userReadonlySnapshots.docs[0]
            if (!await userReadonlySnapshot.exists()) {
                console.error(`Login failed: Could not find user in database with uid == ${newUser.uid}`)
                resolve(false)
                return;
            }

            const userReadonlyData = userReadonlySnapshot.data()

            const userNumber = userReadonlyData.userNumber
            const userNumberString = `${userNumber}`

            const userWritableSnapshot = await getDoc(doc(this.props.fbsFirestore, "Users", userNumberString, "PublicReadonlyPrivateWritable", "0"));
            const userWritableData = userWritableSnapshot.data();

            const userHiddenSnapshot = await getDoc(doc(this.props.fbsFirestore, "Users", userNumberString, "PublicHiddenPrivateWritable", "0", "Inbox", "0"));
            const userHiddenData = userHiddenSnapshot.data();

            this.setState({
                user: newUser,                          // Firebase Authentication user object
                readonlyUserdata: userReadonlyData,     // /Users/<userNumber>/Readonly/0
                writableUserdata: userWritableData,     // /Users/<userNumber>/PublicReadonlyPrivateWritable/0
                hiddenWritableUserdata: userHiddenData, // /Users/<userNumber>/PublicHiddenPrivateWritable/0
            }, () => {
                resolve(true)
            })
        })
    }

    // Closes Sidebar 
    handleSidebarClose() {
        this.setState({showSidebar: false});
    }
    
    // Opens Sidebar
    handleSidebarShow() {
        this.setState({showSidebar: true});
    }

    // ToDo (pre-launch):Finish important stuff, can always write more later. Update styling and links. Dynamic breakpoints. Cut old info.
    render() {
        return (
            <>
                <BasicNavbar 
                        user={this.state.user}
                        userdata={{...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata}}
                        universityName={(this.state.readonlyUserdata && this.state.readonlyUserdata.inTheUniveristyProgram) ? this.state.readonlyUserdata.universityName : "University"}
                />
                <div className="body-content page-fade-animation">
                    <div className="d-flex justify-content-between align-items-center w-100 mx-2 px-2">
                        <h1>F.A.Q.</h1>
                        <div className="pe-2">
                            <a
                                href='https://discord.gg/9EZmV5qBY5'
                                target="_blank"
                                className='me-4 text-reset'
                            >
                                <Discord style={{"height": "23px", "width":"auto"}}/>
                            </a>
                            <a
                                href='https://www.youtube.com/@Indemnigaming'
                                target="_blank"
                                className='me-4 text-reset'
                            >
                                <Youtube style={{"height": "23px", "width":"auto"}}/>
                            </a>
                            <a
                                href='https://twitter.com/Indemnigaming'
                                target="_blank"
                                className='me-4 text-reset'
                            >
                                <Twitter style={{"height": "20px", "width":"auto"}}/>
                            </a>
                            <a
                                href='https://www.instagram.com/indemnigaming'
                                target="_blank"
                                className='me-4 text-reset'
                            >
                                <Instagram style={{"height": "19px", "width":"auto"}}/>
                            </a>
                        </div>
                    </div>
                    <hr />
                    {/* Sidebar for displaying data and navigating the page */}
                    {/* <div className="d-flex w-100">
                        <Button
                            className="game-details-information me-2"
                            onClick={this.handleSidebarShow}
                        >
                            Open FAQ Menu
                        </Button>
                    </div>
                    <Offcanvas
                        show={this.state.showSidebar}
                        onHide={this.handleSidebarClose}
                        scroll={true} backdrop={false}
                        style={{"margin-top":"4em", "width":"320px"}}
                    >
                        <Offcanvas.Header style={{"border-bottom": "1px solid #ffffff57"}}>
                            <Offcanvas.Title className="d-flex justify-content-between align-items-center w-100">
                            <span style={{"font-size":"1.5em", "margin-left":"2.2em"}}>
                                F.A.Q.
                            </span>
                            <Button
                            className="mx-1 px-1 game-details-information"
                            onClick={this.handleSidebarClose}> 
                                Close Menu
                            </Button> 
                        </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body 
                        className="d-flex flex-column align-items-center justify-content-center gap-5 asset-tab-scroll asset-tab-scrollbar"
                        style={{"margin-top":"2em","margin-bottom":"2em",  "height": "600px"}}> */}
                            {/* <Button className="game-details-information w-100">
                                <a style={{"color":"#f8f8f8"}} href="#tldr">
                                    <span style={{"font-weight":"600", "font-size":"1.3em"}}> TL;DR & Tutorial Videos </span>
                                </a>
                            </Button>
                            <Button className="game-details-information w-100">
                                <a style={{"color":"#f8f8f8"}} href="#account">
                                    <span style={{"font-weight":"600", "font-size":"1.3em"}}> Account </span>
                                </a>
                            </Button>
                            <Button className="game-details-information w-100">
                                <a style={{"color":"#f8f8f8"}} href="#pages-and-features">
                                    <span style={{"font-weight":"600", "font-size":"1.3em"}}> Pages & Features </span>
                                </a>
                            </Button>
                            <Button className="game-details-information w-100">
                                <a style={{"color":"#f8f8f8"}} href="#rewards">
                                    <span style={{"font-weight":"600", "font-size":"1.3em"}}> Rewards, Tokens, & NFTs </span>
                                </a>
                            </Button>
                            <Button className="game-details-information w-100">
                                <a style={{"color":"#f8f8f8"}} href="#new-users">
                                    <span style={{"font-weight":"600", "font-size":"1.3em"}}> New Users </span>
                                </a>
                            </Button>
                            <Button className="game-details-information w-100">
                                <a style={{"color":"#f8f8f8"}} href="#about">
                                    <span style={{"font-weight":"600", "font-size":"1.3em"}}> About Indemni </span>
                                </a>
                            </Button> */}
                            {/* <Button className="game-details-information w-100" style={{"margin-top":"auto", "margin-bottom": "3em"}}>
                                <a style={{"color":"#f8f8f8"}} href="#contact">
                                    <span style={{"font-weight":"600", "font-size":"1.3em"}}> Contact the Team </span>
                                </a>
                            </Button>
                        </Offcanvas.Body>
                    </Offcanvas> */}
                    
                    {/* Actual FAQ */}
                    <Row
                        className="fluid flex-grow-1 change-faq-settings-margins-small-screens increase-faq-height"
                        style={{"margin-left":"8em", "margin-right":"9em"}}
                    >
                        <Col
                            className="col-2 fluid my-1 px-2 py-1 w-100 change-faq-settings-margins-small-screens"
                            style={{ "display": "flex", "flex-direction": "column", "margin-left":"7em", "margin-right":"1em"}}
                        >
                            <p style={{"font-style":"italic"}}>“DaFAQ is this for?”</p>

                            <h3 style={{"text-decoration":"underline"}}>FAQ For the FAQ</h3>
                            {/* <p>Look, we thought long and hard about how we could explain to our users everything we believed they needed to know ... unfortunately, the best solution we came up with was a girthy FAQ that covers most topics that our users could struggle with or have questions about. We also added in rationality & commentary when warranted for context, but strived to strike the perfect balance between excessively thorough detail and pithy one-liners {`(who says reading can't be fun?)`}. For even more info, feedback, or questions check out <a target="_blank" href="https://discord.gg/9EZmV5qBY5">our Discord</a>. For people that prefer video or visual walkthroughs, check out <a target="_blank" href="https://www.youtube.com/@indemnigaming">our YouTube</a> - links will also be embedded in certain sections below. Thank you all for joining Indemni, being understanding as we improve the platform through future updates, and helping us improve!</p> */}
                            <p> We apologize for not having the FAQ fully completed at this time. We will have it finished by June 19th. In the meantime, check out the "June 17th Update" below for an overview on the state of early access. Also, if you missed the giveaway annoucement on our homepage, the results of who won are listed in the "Giveaway Winnners" section.</p>
                        <p
                            className="fw-bold"
                            id="askagamer"
                            style={{"color":"#fbfbfb"}}
                        >
                            <span style={{"color":"#38b6ff"}}>June 17th Update :</span>
                            <ul style={{"font-weight":"400"}}>
                                <li> <span style={{"color":"#90ffa9f5"}}> Below is the informal patch notes for everything we have fixed, as well as list of issues we are still working on:</span>
                                    <li>Display and functionality on desktop is now mostly completed - Hooray! Sorry again PC users, but now most everything should look decent and work as intended. We still need to polish up the UI/UX a bit, especially in the Gamer Lounge page - this should be completed within a day or two.</li>
                                    <li>Fixed issues where when attempting to sign up with Google, if the user clicked "Sign in" instead, they were unable to finish account creation. If this happened to you, simply go to the LFG page or Login page and it should prompt you to finish creating your account.</li>
                                    <li>Updated the Login and Sign Up pages with better UI/UX.</li>
                                    <li>Added some onboarding messages inside the pages to better explain how everything works for new users.</li>
                                    <li>Notification should now properly be displayed and go away when you click on the messages you missed/haven't read.</li>
                                    <li>Display and functionality when visting another user's profile now should work as intended.</li>
                                    <li>Profile editing, including adding spacing or url links should now display properly and save.</li>
                                    <li>Joining, leaving, posting in groups, and all chats should work as intended now and have a better display across mobile and desktop (one notable expection is explained below).</li>
                                    <li>*** We have noticed an issue where under certain conditions clicking "Join group" on a post in the LFG page will sometimes have the user join a different posted group if there is more than one post. We have only been able to recreate this effect with posts made pre the June-13th update, but we will continue investigating this issues and sorry to anyone that experiences this bug. Please let us know if this happened to and in which in game, so we can fix it ASAP.</li>
                                    <li>We are still working on getting the games updated ... sorry. We should have 50 more uploaded tomorrow on June 18th.</li>
                                    <li>We will also be sure to have the GG upvote system done within the next few days, update notifications to works beyond just personal messages (i.e., for groups chats, and when people join or leave groups), and game-specific activities added.</li>
                                    <li>We know we are still patching things up and are sorry for the rough first week, but we really appreciate the support from everyone as we get the platform in a good state to start taking feedback and builing out/improving more features and tools.</li>
                                </li>
                                <li>As a final note - we will have the FAQ updated soon lol, and we are sending out one final email to all the waitlisters as it seems most did not receive the notification or instructions on early access (oops!). This means we will have many more users coming in the next few days, so be sure to continue to log in, post, and check your messages! Anddd, we are working on some daily rewards for users that login and post/join groups daily, as well as some Discord events. Stay tuned, ggs everyone, and talk soon!</li>
                                <li style={{"color":"#90ffa9f5"}}>Thank you all again for being here and making the Indemni community awesome. Reach out to us on Discord, or any Social (@indemnigaming), or Email us if you have any questions! (see "Contact the Team" Below)</li>
                            </ul>
                        </p>
                        <p
                            className="fw-bold"
                            id="askagamer"
                            style={{"color":"#fbfbfb"}}
                        >
                            <span style={{"color":"#38b6ff"}}>June 13th Update :</span>
                            <ul style={{"font-weight":"400"}}>
                                <li> <span style={{"color":"#90ffa9f5"}}> Below is the informal patch notes for everything we have fixed, as well as list of issues we are still working on:</span>
                                    <li>All parts of your Gamer Profile are now editable and will update properly. The "Edit Your Profile" Button was move to somewhere more intuitive and properly updates all edit states.</li>
                                    <li>Posting and joining groups now works consistently. Big yikes that this was broken ... again, sorry.</li>
                                    <li>Group chats now *should* all work as intended, so you can talk to your group members after joining. This is located in the Gamer Lounge in "Joined Groups". We will be updating Gamer Lounge soon so it is more clear where things are and what they do.</li>
                                    <li>Personal Messages, adding friends, and going to profiles now works as intended. You should not have many more bugs using this anymore!</li>
                                    <li>Notifications for messages now properly displays and is removed when you read the message or click on it inside the Gamer Lounge in the "Friends & Notifications" tab in "My Notifications". *** Notifications still only works for personal messages, but we will be adding it for LFG Groups and Group Chats here shortly!</li>
                                    <li>Gamer Matchmaker was updated to match under more conditions, to have more utility, and display matched filters properly. Matching for "Education" is still a WIP, but will be done by June 20th.</li>
                                    <li>Minor changes and updates to styling to fix up display. We will make sure this is finished by June 17th ... especially for the "Friends & Notifications" Tab and Desktop in general.</li>
                                    <li>This list is not entirely exhaustive and we will have more updates coming tomorrow such as: GG Voting, Desktop Display Updated, Enhanced Notifications For Messages, 30+ Games added, and more!</li>
                                    <li>We will also be adding these quality of life updates over the weekend: Displaying current members in a group on individual posts in the LFG page, Profile Pictures, and adding game specific activities to certain games e.g., Salvation's Edge Raid for Destiny 2.</li>
                                </li>
                                <li>Forgive the grammar/spelling, we were in rush getting this all out, and sorry again for this being such a mess and going dark with comms until everything was updated. This was a one-fiasco, and we appreciate your patience as we continue to fix everything. We will continue to inform you all of updates to the platform immediately after we push changes to the live site. We will list those updates and brief patch notes here in the FAQ and on our Discord.</li>
                                <li style={{"color":"#90ffa9f5"}}>Thank you all again for being here and making the Indemni community awesome. Reach out to us on Discord, or any Social (@indemnigaming), or Email us if you have any questions! (see "Contact the Team" Below)</li>
                            </ul>
                        </p>

                            {/* Start of Section Titles & Accordions */}
                            <h3
                                id="tldr"
                                style={{"text-decoration":"underline"}}
                                className="my-3"
                            >
                                Early Access Information
                            </h3>
                            <Accordion>
                                <Accordion.Item eventKey="0" >
                                    <Accordion.Header className="styling-for-accordions-header">
                                        Early Access TL;DR
                                    </Accordion.Header>
                                    <Accordion.Body className="color-for-accordion-body">
                                    <ul style={{"font-weight":"400"}}>
                                        <li>Coming Soon!</li>
                                        <li style={{"color":"#90ffa9f5"}}>Reach out to us on any Social (@indemnigaming) or Email us if you have any questions! (see "Contact the Team" Below)</li>
                                    </ul>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {/* <Accordion.Item eventKey="1" >
                                    <Accordion.Header className="styling-for-accordions-header" >
                                        Gamer Matchmaker Demo
                                    </Accordion.Header>
                                    <Accordion.Body className="color-for-accordion-body">
                                        <p>
                                            While we could do a lengthy explanation of the demo, we found it would be simpler to just make a video walkthrough for people to watch below if they are interested. In early access, we will have lengthy text and video walkthroughs of the platform and its features. Since this is a demo, and we will be updating features over the next few weeks, we figured we'd save time and just do a video walkthrough here.
                                        </p>
                                        <h4>Demo Walkthrough</h4>
                                        <p>
                                            Below is a video walkthrough of our demo by our founder Brian. In this, he goes over what the demo is, how it works, why we made certain decisions, and how it will work when we are live in early access.
                                        </p>
                                        <div className="d-flex p-3 align-items-center justify-content-center">
                                            <iframe 
                                                width="560" 
                                                height="315" 
                                                src="https://www.youtube.com/embed/MOFiTcdqN1A?si=NNOPcsURgnpfUeAe"
                                                title="Indemni Gamer Matchmaker Demo Walkthrough" 
                                                frameborder="0" 
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                                allowfullscreen>
                                            </iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item> */}
                                <Accordion.Item eventKey="1" >
                                    <Accordion.Header className="styling-for-accordions-header" >
                                        How Everything Works
                                    </Accordion.Header>
                                    <Accordion.Body className="color-for-accordion-body">
                                        <p>
                                            We will have this completed by June 19th. Sorry for the inconvenience. Please reach out on our Discord if you have any questions or issues before then.
                                        </p>
                                        <p>
                                            More Coming Soon!
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <h3
                                id="tldr"
                                style={{"text-decoration":"underline"}}
                                className="my-3"
                            >
                                Giveaway Results
                            </h3>
                            <Accordion>
                                <Accordion.Item eventKey="2" >
                                    <Accordion.Header className="styling-for-accordions-header">
                                        List of Winners
                                    </Accordion.Header>
                                    <Accordion.Body className="color-for-accordion-body">
                                        <p style={{"font-size":".9em", "padding":".5em"}}>
                                            First off, thank you, everyone, for your support and belief in Indemni. It means the world to us that you are here and we can't wait to show you all the things we have in store for the future.We wish everyone could have won the big prizes in this giveaway, but unfortunately, we aren't big hotshots like Steam (yet!), so this is as much as we could do as thanks for being along for the journey and helping make Indemni better. To all the losers ... I mean non-winners of the giveaway ... do know that we will have many more giveaways in the future and will be holding tons of events on our Discord, among other things. So, there's still hope!
                                        </p>
                                        <p style={{"font-size":".9em", "padding":".5em"}}>
                                            Okay, now here are the lucky winners (we will email you soon from contact@indemni.io to follow up!) - Congratulations.
                                            <ul>
                                                The winner of the PS5 or Logitech Bundle giveaway is:
                                                <li style={{"color":"#f6ffc7", "font-weight":"600", "text-shadow":"1px 0px 1px black"}}>
                                                    Sara Kover! 👀
                                                </li>
                                            </ul>
                                            <ul>
                                                The two winners for choosing the next features/tools on Indemni are:
                                                <li style={{"color":"#f6ffc7", "font-weight":"600", "text-shadow":"1px 0px 1px black"}}>
                                                    Inno Moo and Tavern Barnes 😲
                                                </li>
                                            </ul>
                                            <ul>
                                                The twenty winners of the Indemni t-shirt giveaway are:
                                                <li style={{"color":"#f6ffc7", "font-weight":"600", "text-shadow":"1px 0px 1px black"}}>
                                                    Aaron Simmmons, Kristine Chuidian, Lucii  Lemmon, Lorelei Kerouac, Joseph Saki, Anthony Barnes, Mac Carter, Ame Rodriguez-Johnson, Cara Leppard, Kéondre Matthew
                                                    Kebaesuul West, Sophie Paradis, Hamish Pompey, Kylie Beaudoin, Antoinette Arnott, Tavian Cooks, Xavier Upton, Pyerangello Silva, Carter Chamberlain, and Isaiah  Charles. ❤️‍🔥
                                                </li>
                                            </ul>
                                            <ul>
                                                And finally, the winner of having their username made as a Permanent status is:
                                                <li style={{"color":"#f6ffc7", "font-weight":"600", "text-shadow":"1px 0px 1px black"}}>
                                                    Alexander Owen 🤩
                                                </li>
                                            </ul>
                                        </p>
                                        <p style={{"font-size":".9em", "padding":".5em"}}>
                                            We are still trying to figure out the Discord VC/Stream winners, but will reach out to them soon once we square it away.
                                            Again thanks everyone and have fun with the platform. Remember we will fix up all those bugs here in the next day or two, apologies again for that ... and the delay!
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3" >
                                    <Accordion.Header className="styling-for-accordions-header" >
                                        How Everything Works
                                    </Accordion.Header>
                                    <Accordion.Body className="color-for-accordion-body">
                                        <p>
                                            We will have this completed by June 18th. Sorry for the inconvenience. Please reach out on our Discord if you have any questions or issues before then.
                                        </p>
                                        <p>
                                            More Coming Soon!
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <h3
                                id="tldr"
                                style={{"text-decoration":"underline"}}
                                className="my-3"
                            >
                                About Indemni
                            </h3>
                            <Accordion>
                                <Accordion.Item eventKey="4" >
                                    <Accordion.Header className="styling-for-accordions-header">
                                        What is an LFG Social Gaming Platform?
                                    </Accordion.Header>
                                    <Accordion.Body className="color-for-accordion-body">
                                        <p>
                                            We will be writing more about this soon, but here is a very brief description of what Indemni is, and what we mean when we call ourselves an LFG Social Gaming Platform.
                                        </p>
                                        <h4>LFG</h4>
                                        <p>
                                            By LFG, we mean "Looking For Group," this is a common term used within gaming that people write/post to find other people to play with. Other common terms are "Party-Finder," and "Matchmaker." Essentially, this means we are a platform where people can find other gamers to play games with.
                                        </p>
                                        <h4>Social Gaming</h4>
                                        <p>
                                            By Social Gaming, we mean a platform where gamers can connect and find others with similar gaming goals or passions. We want to provide tools and customization options for you to show off who you are as a gamer and person. That way, you can promote yourself, find friends, engage in forums, chats, discussions, etc. We think it's a shame that there isn't a platform built just for gamers and that people need to use 10 different products or sites to meet people, matchmake, and consume gaming content. We are attempting to change that with Indemni.
                                        </p>
                                        <h4>LFG Social Gaming Platform</h4>
                                        <p>
                                            So, you put that all together and you get a platform where people can connect with and play games with people. It really is that simple. We've seen so many platforms, games, and companies do this partially are at a very small scale. We want this platform to be your home for everything outside of the game itself ... and we are fairly certain that we can achieve that goal over the coming months and years. We are excited to have you here and get your feedback on this journey. Btw, if you think LFG Social Gaming Platform is a dumb description lol, let us know - we are open to different names.
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {/* <Accordion.Item eventKey="1" >
                                    <Accordion.Header className="styling-for-accordions-header" >
                                        Gamer Matchmaker Demo
                                    </Accordion.Header>
                                    <Accordion.Body className="color-for-accordion-body">
                                        <p>
                                            While we could do a lengthy explanation of the demo, we found it would be simpler to just make a video walkthrough for people to watch below if they are interested. In early access, we will have lengthy text and video walkthroughs of the platform and its features. Since this is a demo, and we will be updating features over the next few weeks, we figured we'd save time and just do a video walkthrough here.
                                        </p>
                                        <h4>Demo Walkthrough</h4>
                                        <p>
                                            Below is a video walkthrough of our demo by our founder Brian. In this, he goes over what the demo is, how it works, why we made certain decisions, and how it will work when we are live in early access.
                                        </p>
                                        <div className="d-flex p-3 align-items-center justify-content-center">
                                            <iframe 
                                                width="560" 
                                                height="315" 
                                                src="https://www.youtube.com/embed/MOFiTcdqN1A?si=NNOPcsURgnpfUeAe"
                                                title="Indemni Gamer Matchmaker Demo Walkthrough" 
                                                frameborder="0" 
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                                allowfullscreen>
                                            </iframe>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item> */}
                                <Accordion.Item eventKey="5" >
                                    <Accordion.Header className="styling-for-accordions-header" >
                                        Who We Are
                                    </Accordion.Header>
                                    <Accordion.Body className="color-for-accordion-body">
                                        <p>
                                            Again, we will be brief here, but you can always ping us on Discord if you just need to know everything about us right meow. We are a team of two gamers with the goal of building a platform that makes gaming more fun and connects people. Our founder is Brian Romain, a former competitive gamer and business dude. Our Lead Engineer is Rylan, a gamer and all-around BA. We don't take ourselves too seriously (though we take the platform very seriously!), and want to build the best possible product for you all. Hit us up via our contact info in the "Contact" tab below, we'd love to get to know you and game together sometime.
                                        </p>
                                        <p>
                                            More Coming Soon!
                                        </p>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                            
                            <h3
                                style={{"text-decoration":"underline"}}
                                id="contact"
                                className="mt-5 mb-3"
                            >
                                Contact the Team
                            </h3>
                            <Accordion>
                                <Accordion.Item eventKey="6" >
                                    <Accordion.Header className="styling-for-accordions-header">Links & Contact Information</Accordion.Header>
                                    <Accordion.Body className="color-for-accordion-body">
                                        <p>
                                            There are a few ways to contact us: Discord, Email, & Socials.
                                        </p>
                                        <p>
                                            <ul>
                                                <li>For general concerns, tech support, feedback, etc. - send us an email at: contact@indemni.io</li>
                                                <li>For important issues and questions, or if you are a developer, pro gamer, content creator, or investor - send an email to our Founder at: brian@indemni.io</li>
                                            </ul> 
                                        </p>
                                        <p>
                                            Our Socials are on the bottom right of every page on desktop and tablet in the footer. They are also at the top of the FAQ in the upper right-hand corner. Click on the icon for the specific Social to be directed there to contact us. We respond the fastest & prefer Discord. Not all socials are listed, we are on everything though - @indemnigaming.
                                        </p>
                                        {/* <p>
                                            For current users, you can also just send a message in Inbox to "Indemni" or "MrIndemni" to reach the team or our founder! Don't hesitate to do so, we are always on and frequently check the messages. Do note, however, that this will be a little slower than email or socials. Also, you may need to send the message twice as we occasionally have to read through a lot of memes, rude, and spam messages lol.
                                        </p> */}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>

                            <h5
                                style={{"text-align":"center"}}
                                id="contact"
                                className="mt-4"
                            >
                                Thanks for reading and visiting Indemni!
                            </h5>
                        </Col>
                    </Row>

                </div>
            </>
        )
    }
}

export default FAQPage;
