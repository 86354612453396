import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap"

import {
    collectionGroup,
    getDocs,
    limit,
    orderBy,
    query
} from "firebase/firestore"

class LeaderboardActivity extends Component {
    constructor(props) {
        super(props);

        this.state = {}

        this.generateRandomUsername = this.generateRandomUsername.bind(this)
    }

    // Lol just used for random username in case people can see them through the blur.
    // This can definitely be deleted once we create this page for real.
    generateRandomUsername() {
        const adjectives = ['Confused', 'Dumb', 'Lucky', 'Chubby', 'Clever', 'Gentle', 'Angry', 'Sexy', 'Quiet', 'Sneaky'];
        const nouns = ['Pediatrist', 'Butt', 'Lamp', 'Shadow', 'Peacock', 'Gamer', 'Texan', 'Mom', 'Boy', 'Fart'];
        const randomAdjective = adjectives[Math.floor(Math.random() * adjectives.length)];
        const randomNoun = nouns[Math.floor(Math.random() * nouns.length)];

        return `${randomAdjective}${randomNoun}${Math.floor(Math.random() * 100)}`;
    }

    // Will eventually map/filter from a query of all user total xp/lvl (need the distinction to rank user's of the same level but higher xp)
    // Will show user their rank, allow to click on a top 100 user to see their profile, and will have a timer for reset (not here but the monthly ones)
    // Will also be directly used as the list (or the db metric itself), for rewarding users INDE and other rewards each month.
    // Mapping & function currently also a placeholder to determine styling.
    // UPDATED: Commenting out the placeholder structure to create a fake-leaderboard of sorts to hide this for December. This can
    // ... and will be updated during December when we actual build this all out.
    // ToDo: @Rylan cloud functions finish & workshop where we put everything and the data we need, and paginate to 25 probably,
    // so 4 pages.
    // ToDo (later): Determine wallet token distribution based on this/db top 100's and the reset to display these leaderboards.
    render() {
        return (
            <>
                <div style={{"margin-left":"2em", "margin-right":".2em"}}>
                    <div className="d-flex flex-row flex-grow-1 p-2 my-1 mx-5 justify-content-between align-items-center flex-wrap">
                        <div style={{"font-size":"1.5em", "margin-left":"1em"}}>
                            Top 100:
                        </div>
                        <span style={{"font-size":"1em", "margin-right":"5em", "font-weight":"700", "color":"#417cedf0"}}>
                            Your Rank: REDACTED
                        </span>
                    </div>
                    {/* This is the div/text displayed over the blurred row */}
                    <Row className="fluid leaderboard-level-placeholder-message page-fade-animation">
                        <h1 style={{"overflow":"hidden", "font-weight":"700", "color":"#ffffff"}}>
                            Coming Soon
                        </h1>
                        <h3 className="leaderboard-level-first-month-title">
                            <span
                                className="leaderboard-level-first-month-description"
                                style={{"margin-bottom":"1em"}}
                            >
                                Leaderboards are disabled for now as we optimize the daily/monthly point system.
                            </span>
                            <span className="leaderboard-level-first-month-description">
                                Log on July 19th to see your ranking and climb the ranks to earn monthly rewards!
                            </span>
                        </h3>
                    </Row>
                    {/* This is what leaderboard will look like - currently blurred out as it isn't done and doesn't have accurate data */}
                    <Row className="fluid leaderboard-level-blur">
                        <Col
                            className="asset-tab-align pe-3 mx-5"
                            style={{"margin-top":"0", "padding-top":"0"}}
                        >
                            { Array.from({ length: 10 }, (_, index) => (
                                <>
                                    {/* Styling is good here, but array mapping is just for render and show only obviously, */}
                                    <hr />
                                    <div className="d-flex flex-row flex-grow-1 p-2 m-1 justify-content-between align-items-center flex-wrap">
                                        <Button
                                            className="d-flex flex-row flex-grow-1 align-items-center flex-wrap notifications-tab-button"
                                            key={index}
                                            disabled
                                        >
                                            <div
                                                className="d-flex flex-column"
                                                style={{
                                                    "font-weight":"lighter", "font-size":"1.2em", "border-right":"1px solid #dee2e6", "padding-right":"1em",
                                                    "box-shadow":"1px 1px 20px 2px #f5f5f526", "text-align":"start"
                                                }}>
                                                <span className="pe-2 me-2">
                                                    # {index + 1}
                                                </span>
                                            </div>
                                            <div
                                                className="d-flex flex-row flex-grow-1 flex-wrap align-items-center justify-content-between"
                                                style={{"margin-left":"1em", "padding-left":"1em", "padding-right":"1em"}}
                                            >
                                                <span
                                                    className="ps-2 ms-2"
                                                    style={{"font-weight":"700", "font-size":"1.5em"}}
                                                >
                                                    {this.generateRandomUsername()}
                                                </span>
                                                <span
                                                    className="pe-2"
                                                    style={{"font-weight":"500", "font-size":"1.2em"}}
                                                >
                                                    Level {100 - index * 10}
                                                    <span
                                                        style={{"font-size":".8em", "font-style":"italic", "font-weight":"400", "margin-left":"1em"}}
                                                        className="pe-2"
                                                    >
                                                        ({(100 - index * 10) * 1000} Xp)
                                                    </span>
                                                </span>
                                            </div>
                                        </Button>
                                    </div>
                                </>
                            ))
                            }
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default LeaderboardActivity;