// Called in AccountPage.jsx the first time a user gets there after creating an account.
import ProfilePictureOneSource from "./ProfilePictureOneSource.js";
import { ref, uploadString } from "firebase/storage";
import { doc, updateDoc} from "firebase/firestore";

const createProfilePicture = async (readonlyUserdata, fbsFirestore, fbsStorage) => {
    var canvas = document.createElement('canvas');
    canvas.width = 500;
    canvas.height = 500;
    const ctx = canvas.getContext("2d");
    const img = new Image(500, 500);

    let usernameText = readonlyUserdata.username;
    if (usernameText.length > 15) usernameText = usernameText.substring(0, 12) + "...";

    img.onload = async () => {
        ctx.fillStyle = "#e1e1e1";
        ctx.font = "bold 15px Arial";

        ctx.drawImage(img, 0, 0);
        ctx.fillText(usernameText, 16, 32);

        ctx.fillStyle = "#bdbdbd";
        ctx.textAlign = "center";
        ctx.font = "14px Arial";

        let userNumber = readonlyUserdata.userNumber;
        if (userNumber) {
            // Update user number
            
        }

        ctx.fillText("USER", 172, 443);
        ctx.fillText(`#${String(userNumber).padStart(6, '0')}`, 172, 457);

        const userProfilePictureData = canvas.toDataURL('image/png');

        var domImg = document.createElement("img");
        domImg.src = userProfilePictureData;
        domImg.width = 500;
        domImg.height = 500;
        // This next line will just add it to the <body> tag
        document.body.appendChild(domImg);

        // Store image to cloud storage.
        const imageLink = `pfpOne/${userNumber}.png`;
        uploadString(ref(fbsStorage, imageLink), userProfilePictureData, 'data_url').then((snapshot) => {
            console.log('Uploaded image with username and user number on it');
        });

        // Store json metadata containing link to image in cloud storage, username, and user number.
        const jsonToStore = {
            username: readonlyUserdata.username,
            userNumber: userNumber,
            imageLink: imageLink,
        }
        uploadString(
            ref(fbsStorage, `pfpOne/${userNumber}.json`),
            JSON.stringify(jsonToStore),
            undefined,
            { contentType: "application/json" }
        ).then((snapshot) => {
            console.log('Uploaded pfpOne JSON metadata');
        });
    }
    img.src = ProfilePictureOneSource;
    // await updateDoc(doc(fbsFirestore, "Users", readonlyUserdata.userNumber, "PublicReadonlyPrivateWritable", "0"), { pfp: `gs://justtesting-c24bc.appspot.com/pfpOne/${this.state.readonlyUserdata.userNumber.toString(16)}.png` });
}

export default createProfilePicture;
