// The LFG Post Group Page.
// TODO: Write Comments
import React, { Component } from "react"
import {
    Button,
    Modal,
    Dropdown,
    Stack,
    Pagination,
} from "react-bootstrap"

class SocialGroupsPlaceHolder extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {

        return (
            <>
                <Stack
                    gap={2}
                    className="col-12 fluid mx-auto"
                >
                    <div className="me-2 px-2 ask-a-gamer-explore-margin-padding-right-left-0-mobile">
                        <div className="p-2 game-page-post-containers-mobile mt-2">
                            <div
                                style={{ "margin-bottom": "1em", "margin-top": "1em" }}
                                className="d-flex flex-column tab-textbox-hover-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile"
                            >
                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                    <div
                                        className="d-inline-flex flex-row align-items-center flex-wrap"
                                        style={{ "width": "100%" }}
                                    >
                                        <div
                                            className="d-flex flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                            style={{ "flex-grow": "1" }}
                                        >
                                            <p style={{ "width": "100%", "margin-bottom": "0" }}>
                                                <div className="d-flex flex-column flex-wrap justify-content-center align-items-center overflow-auto text-center" >
                                                    <small
                                                        style={{ "font-size": "1.5em", "font-weight": "600", "text-align": "center", "text-shadow": "0px -3px 2px #000000" }}
                                                        className="d-flex flex-column desktop-increase-demo-gamer-profile-text-size"
                                                    >
                                                        <span style={{ "margin-bottom": "1em", "color": "#ff7b68", "font-size": "1.1em", "text-decoration-style": "double", "text-decoration-color": "#fdb4b4cf" }}>
                                                            You are not in anyone else's group.
                                                        </span>
                                                        <span style={{ "font-size": ".8em", "margin-bottom": "1em" }}>
                                                            You can join one group, beyond your own posted group (the number of groups you can be in at one time will be increased soon). If you want to join some groups, head over to the Standard LFG or Gamer Matchmaker pages.
                                                        </span>
                                                    </small>
                                                </div>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Stack>
            </>
        );
    }
}

export default SocialGroupsPlaceHolder;
