import React, { Component } from "react"
import BasicNavbar from "../components/Navbar/Navbar";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { onAuthStateChanged } from "firebase/auth"
import { doc, where, query, getDoc, getDocs, collectionGroup, onSnapshot } from "firebase/firestore";
import LeaderboardActivity from "../components/Leaderboard/LeaderboardActivity";
import LeaderboardTourny from "../components/Leaderboard/LeaderboardTourny";
import LeaderboardUniversity from "../components/Leaderboard/LeaderboardUniversity";
import { setUser, logout } from "../utils";

class LeaderboardPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: undefined,
            userdata: undefined,
        }

        this.logout = this.logout.bind(this)
        this.setUser = this.setUser.bind(this)

        // this.setUpMessageListener = this.setUpMessageListener.bind(this)

        onAuthStateChanged(this.props.fbsAuth, async user => {
            if (user) {
                await this.setUser(user);
                // await this.setUpMessageListener();
            } else this.logout();
        })
    }

    // Unsubscribe from Firestore listener.
    componentWillUnmount() {
        if (this.unsubscribeMessageListener)
            this.unsubscribeMessageListener();
    }

    setUser = setUser;
    logout = logout;

    // Listen to changes in the Firestore collection where messages are and passed to Navbar
    // Meh we don't need until the page is active.
    // setUpMessageListener() {
    //     const currentUser = this.state.readonlyUserdata.username;
    //     const InboxDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0");
    //     const unsubscribe = onSnapshot(InboxDocRef, (doc) => {
    //         if (doc.exists()) {
    //             const messages = doc.data().messages || [];
    //             // Applies the filter to messages where toName matches the current user's name
    //             const userMessages = messages.filter((message) => message.toName === currentUser);
    //             // Saves filtered out messages in state to be updated in markMessageAsRead
    //             const filteredOutMessages = messages.filter((message) => message.toName !== currentUser);
    //             this.setState({ filteredOutMessages });
    //             userMessages.sort((a, b) => b.timeSent - a.timeSent);
    //             // Counts the number of unread messages.
    //             const unreadCount = userMessages.filter((message) => !message.read).length;
    //             // Checks privacy settings & removes unreadCount if the user has any
    //             if (doc.data().receiveMessages === false) {
    //                 this.setState({unreadCount: null, messages: userMessages, hideMessages: true })
    //             }
    //             // If no privacy settings - sets default unreadCount
    //             else {
    //                 this.setState({ unreadCount, messages: userMessages  })
    //             }
    //             console.log("Sorted Message Data:", userMessages);
    //         }
    //     });
    //     this.unsubscribeMessageListener = unsubscribe;
    // }

    // Just a layout placeholder for different top 100 tabs.
    // LeaderboardLevel will be the default and base layout.
    // Disabled LeaderboardXP & LeaderboardTrust, as well as created a "hidden" version of LeaderboardLevel which
    // ... is just for the first month while we finish the logic and styling for it. This placeholder variant can
    // ... be deleted once we are ready to push a final workable version. Similiary, LeaderboardXp/Trust will become
    // ... enabled once they are completed as well.
    // ToDo (post-launch): This should be a high-priority to finish once we are ready for launch / in to post-launch.
    // ... We need another spot to create a cloud function not run in the front-end that checks all user docs for
    // ... values such as totalExperience & trustScore. As well, we need to create logic within current cloud functions
    // ... to track xp and trust earned overtime & update the db accordingly with these variables. Finally, beyond finishing
    // ... the actual render for these tabs/page, we need to determine a proper way of giving out rewards at the beginning of
    // ... each month for user's that land in this top 100 (this can be manually done early on, but we should automate it).
    // ... Just another thought, for the cloud function to check and update top 100, we can have it just run once a day at
    // ... reset to limit reads or twice a week, etc.(depending on the data load) - otherwise we will go broke immediately
    // ... if we query all docs via the front-end and there isn't a time limit on these queries.
    render() {
        return (
            <>
                <BasicNavbar
                    user={this.state.user}
                    userdata={{...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata}}
                    unreadCount={this.state.unreadCount}
                />
                    <Tabs
          	            defaultActiveKey="activity"
			            transition={false}
          	            id="homepage-tabs"
          	            className="mb-3 pb-2 mt-2 mx-4 page-fade-animation"
          	            justify
                    >
          	          	<Tab
                            eventKey="activity"
                            title="Activity (July)"
                            tabClassName="tab-color"
                        >
          	          		<LeaderboardActivity
                                fbsAuth= {this.props.fbsAuth}
                                fbsFirestore={this.props.fbsFirestore}
                                user={this.state.user}
                                username={this.state.username}
                                userdata={{...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata}}
                            />
          	          	</Tab>
          	          	<Tab
                            eventKey="tournaments"
                            title="Tournaments"
                            tabClassName="tab-color"
                            disabled
                        >
			        		{/* <LeaderboardTourny
                                fbsAuth= {this.props.fbsAuth}
                                fbsFirestore={this.props.fbsFirestore}
                                user={this.state.user}
                                username={this.state.username}
                                userdata={{...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata}}
                            /> */}
          	          	</Tab>
          	          	<Tab
                            eventKey="university"
                            title="University - Coming Soon"
                            tabClassName="tab-color"
                            disabled
                        >
			        		{/* <LeaderboardUniversity
                                fbsAuth= {this.props.fbsAuth}
                                fbsFirestore={this.props.fbsFirestore}
                                user={this.state.user}
                                username={this.state.username}
                                userdata={{...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata}}
                            /> */}
          	          	</Tab>
          	        </Tabs>
            </>
        )
    }
}

export default LeaderboardPage;