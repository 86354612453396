import React, { Component } from "react"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import SocialPersonal from "./SocialPersonal";
import SocialPersonalChat from "./SocialPersonalChat";
import { doc, getDoc, updateDoc, onSnapshot, arrayRemove } from "firebase/firestore";
import { updateMessagesDateAndCount } from "../../utils";

// As mentioned elsewhere, this is currently just a placeholder.
// All tabs will be updated using the DemoPage as a reference,
// and we will implement the agreed upon logic, DB paths, etc.
// This will also be renamed to GamerProfileTabs.
class SocialPersonalTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKey: "personal-contacts",
            contacts: [],
            friends: [],
            messages: [],
            selectedRecipientUser: null,
            messageText: "",
            uploadText: "", // the modified text that uploads line breaks to firebase
            friendsFilterSelected: false,
            unreadCounts: {},
            allMessages: [],
            cooldown: false
        }

        this.handleSearch = this.handleSearch.bind(this);
        this.handleClearSearch = this.handleClearSearch.bind(this);
        this.handleContactSelect = this.handleContactSelect.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleMessageTextChange = this.handleMessageTextChange.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.removeUserFromContacts = this.removeUserFromContacts.bind(this);
        this.setupMessageListener = this.setupMessageListener.bind(this);
        this.loadContacts = this.loadContacts.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.fetchMessages = this.fetchMessages.bind(this);
        this.moveUserToMessages = this.moveUserToMessages.bind(this);

        this.markPersonalMessagesAsRead = this.markPersonalMessagesAsRead.bind(this);

        this.handleHashChange = this.handleHashChange.bind(this);

        const tabs = [
            "personal-contacts",
            "personal-messages",
        ];
        this.state.activeKey = tabs[0];

        const initialHash = window.location.hash.substring(1);
        if (tabs.includes(initialHash)) {
            this.state.activeKey = initialHash;
        }
    }

    async componentDidMount() {
        window.addEventListener("hashchange", this.handleHashChange, false);
        await this.loadContacts();
        await this.setupMessageListener();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.activeKey !== this.state.activeKey) {
            this.scrollToBottom();
            if (this.state.activeKey === "personal-contacts" || this.state.activeKey === "personal-messages") {
                this.loadContacts();
            }
        }
    }

    componentWillUnmount() {
        window.removeEventListener("hashchange", this.handleHashChange, false);
        if (this.unsubscribeMessageListener) {
            this.unsubscribeMessageListener();
            this.unsubscribeMessageListener = undefined;
        }
    }

    handleHashChange() {
        const hash = window.location.hash.substring(1);
        const tabs = ["personal-contacts", "personal-messages"];
        if (tabs.includes(hash)) {
            this.setState({ activeKey: hash });
        } else {
            this.setState({ activeKey: "personal-contacts" });
        }
    }

    async loadContacts() {
        const inboxDocRef = doc(this.props.fbsFirestore, "Users", `${this.props.userdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0");
        const inboxDocSnapshot = await getDoc(inboxDocRef);
        if (!inboxDocSnapshot.exists()) return false;

        const data = inboxDocSnapshot.data();
        const allMessages = data.messages || [];
        const contacts = data.contacts || [];

        if (data.receiveMessages === false && data.receiveMessagesOnlyFromFriends === false) {
            this.setState({ contacts: null, friends: null, originalContacts: null, originalFriends: null });
            return;
        }

        contacts.sort((a, b) => {
            const aTimestamp = this.mostRecentMessage(a, allMessages);
            const bTimestamp = this.mostRecentMessage(b, allMessages);
            return bTimestamp - aTimestamp;
        });

        const friendDocRef = doc(this.props.fbsFirestore, "Users", `${this.props.userdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
        const friendDocSnapshot = await getDoc(friendDocRef);
        if (!friendDocSnapshot.exists()) return false;

        const friendData = friendDocSnapshot.data();
        const friends = friendData.friends || [];

        friends.sort((c, d) => {
            const cTimestamp = this.mostRecentMessage(c, allMessages);
            const dTimestamp = this.mostRecentMessage(d, allMessages);
            return dTimestamp - cTimestamp;
        });

        if (data.receiveMessages === false && data.receiveMessagesOnlyFromFriends === true) {
            this.setState({ contacts: null, allMessages, friends, originalContacts: null, originalFriends: friends });
            return;
        }

        this.setState({ contacts, allMessages, friends, originalContacts: contacts, originalFriends: friends });
        return true;
    }

    setupMessageListener() {
        const inboxDocRef = doc(this.props.fbsFirestore, "Users", `${this.props.userdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0");
        const unsubscribe = onSnapshot(inboxDocRef, (doc) => {
            if (doc.exists()) {
                const data = doc.data();
                const messages = data.messages;
                const updatedUnreadCounts = {};
                const allMessages = doc.data().messages || [];
                const contactListener = doc.data().contacts;

                this.state.contacts.forEach((contact) => {
                    const unreadCount = messages.filter((message) => !message.read && (message.fromName === contact)).length;
                    updatedUnreadCounts[contact] = unreadCount;
                });
                this.setState({ unreadCounts: updatedUnreadCounts, allMessages, contactListener });
                if (this.state.selectedRecipientUser) {
                    this.fetchMessages(this.state.selectedRecipientUser);
                }
            }
        });
        this.unsubscribeMessageListener = unsubscribe;
    }

    scrollToBottom() {
        const chatBox = document.getElementById('chat-box');
        if (chatBox) chatBox.scrollTop = chatBox.scrollHeight;
    }

    mostRecentMessage(contact, allMessages) {
        const recentMessages = allMessages.filter(message => message.fromName === contact || message.toName === contact);

        if (recentMessages.length > 0) {
            const mostRecent = recentMessages.reduce((prev, current) => (prev.timeSent > current.timeSent ? prev : current));
            return mostRecent.timeSent || 0;
        }
        return 0;
    }

    async fetchMessages(recipientUser) {
        const inboxDocRef = doc(this.props.fbsFirestore, "Users", `${this.props.userdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0");
        const inboxDocSnapshot = await getDoc(inboxDocRef);

        if (!await inboxDocSnapshot.exists()) return false;

        const data = inboxDocSnapshot.data();
        const newMessages = data.messages;

        if (newMessages.length >= 500) {
            try {
                newMessages.sort((a, b) => b.timeSent - a.timeSent);
                const updatedUserMessages = newMessages.slice(0, 400);
                await updateDoc(inboxDocRef, { messages: updatedUserMessages });

                const userMessages = updatedUserMessages.filter((message) => message.fromName === recipientUser || message.toName === recipientUser);
                userMessages.sort((a, b) => a.timeSent - b.timeSent);
                this.setState({ messages: userMessages });
            } catch (error) {
                console.error(error);
            }
        } else {
            const userMessages = newMessages.filter((message) => message.fromName === recipientUser || message.toName === recipientUser);
            userMessages.sort((a, b) => a.timeSent - b.timeSent);
            this.setState({ messages: userMessages });
        }
    }

    // Currently calls the sendMessage cloud function then the utilis.js function updateMessagesDateAndCount.
    // The utils function also calls the cloud function sendNotificationEmail to alert users that we received a new message.
    // TODO: Update the updateMessagesDateAndCount and/or this function to handle logic for notifications, how many and when
    // users receive notifications, etc.
    async sendMessage(recipientUser) {
        if (this.state.cooldown) return;

        this.setState({ cooldown: true });

        // Calls sendMessage cloud function in index.js in the functions folder
        const idToken = await this.props.fbsAuth.currentUser.getIdToken(/* forceRefresh */ true);
        const response = await fetch("https://sendmessage-cuw3q2hq5q-uc.a.run.app", {
            method: "post",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `ID ${idToken}`,
            },
            body: JSON.stringify({
                to: recipientUser,
                body: this.state.messageText,
            })
        });

        if (response.ok) this.setState({ messageText: "", uploadText: "" })
        else window.alert(`Error ${response.status}: ${response.statusText}`);

        setTimeout(() => {
            this.setState({ cooldown: false });
        }, 1500);

        // Calls utils function to update messages sent counts and dates which then calls cloud function sendNotificationEmail. 
        try {
            await updateMessagesDateAndCount(this.props.fbsAuth, this.props.fbsFirestore, this.props.userdata.userNumber, recipientUser)
            console.log("New Message Count/Date Completed!")
        } catch (error) {
            console.error("New Message Count/Date Failed!", error);
        }
    }

    handleSearch(searchTerm) {
        if (searchTerm) {
            if (!this.state.friendsFilterSelected) {
                const filteredContacts = this.state.contacts.filter((user) => user.includes(searchTerm));
                this.setState({ contacts: filteredContacts });
            } else {
                const filteredFriends = this.state.friends.filter((user) => user.includes(searchTerm));
                this.setState({ friends: filteredFriends });
            }
        } else {
            if (this.state.friendsFilterSelected) this.setState({ friends: this.state.originalFriends });
            else this.setState({ contacts: this.state.originalContacts });
        }
    }

    handleClearSearch() {
        this.setState({ searchTerm: '' });
        if (this.state.friendsFilterSelected) this.setState({ friends: this.state.originalFriends });
        else this.setState({ contacts: this.state.originalContacts });
    }

    handleContactSelect(contact) {
        this.setState({ selectedRecipientUser: contact });
        this.fetchMessages(contact);
    }

    handleFilterChange(filterSelected) {
        this.setState({ friendsFilterSelected: filterSelected, selectedRecipientUser: null });
    }

    handleMessageTextChange(text) {
        this.setState({ messageText: text });
    }

    async removeUserFromContacts(contact) {
        if (contact) {
            try {
                const removeContactDocRef = doc(this.props.fbsFirestore, "Users", `${this.props.userdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0");
                await updateDoc(removeContactDocRef, { contacts: arrayRemove(contact) });
                const updatedContacts = this.state.originalContacts.filter((c) => c !== contact);
                this.setState({ contacts: updatedContacts, originalContacts: updatedContacts, selectedRecipientUser: null });
            } catch (error) {
                console.error(error);
            }
        }
    }

    async moveUserToMessages() {
        this.setState({ activeKey: "personal-messages" }, () => {
            window.location.hash = "#personal-messages";
        });
    }

    // Same as in InboxPage, but modified to "clear" all new/unread messages
    // recieved from recipientUser, leaving no new messages/notifications, in
    // Firestore called onClick in selectRecipientUser above, where the user
    // selected is passed and sets change read: false to true in checks if
    // there are unread messages before executing a write - rest of logic can
    // be seen in InboxPage for more clarification.
    async markPersonalMessagesAsRead(recipientUser) {
        const { allMessages } = this.state;

        // Check if there are any unread messages in allMessages
        const hasUnreadMessages = allMessages.some((message) => message.fromName === recipientUser && !message.read);
        console.log("Unread Messages", hasUnreadMessages);

        if (hasUnreadMessages) {

            // Filter and update only the unread messages from the recipientUser
            const updatedMessages = allMessages.map((message) => {
                if (message.fromName === recipientUser && !message.read) {
                    return { ...message, read: true };
                }
                return message;
            });
            const messageDoc = doc(this.props.fbsFirestore, "Users", `${this.props.userdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0");
            await updateDoc(messageDoc, {
                messages: updatedMessages,
            });
            // console.log("markMessagesAsRead Started", updatedMessages);
            // const messageDoc = doc(this.props.fbsFirestore, "Users", `${this.props.userdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0");
            // try {
            //     const filteredOutMessages = allMessages.filter((message) => (
            //         message.fromName !== recipientUser
            //     ));
            //     this.setState({ filteredOutMessages });

            //     // Adds the filtered out messages back to newly updatedMessages
            //     updatedMessages.push(...filteredOutMessages);
            //     console.log("Filtered Messages:", filteredOutMessages);
            //     await updateDoc(messageDoc, {
            //         messages: updatedMessages,
            //     });
            //     console.log("Messages in Firestore updated successfully.");
            // } catch (error) {
            //     console.error("Error marking messages as read:", error);
            // }
        }
    }
    

    render() {
        const { contacts, friends, messages, selectedRecipientUser, messageText, friendsFilterSelected, unreadCounts, allMessages, cooldown } = this.state;
        return (
            <>
                <>
                    <Tabs
                        activeKey={this.state.activeKey}
                        onSelect={(k) => window.location.hash = `#${k}`}
                        transition={false}
                        id="social-personal-tabs"
                        className="mb-2 pb-2"
                        justify
                    >
                        <Tab
                        eventKey="personal-contacts"
                        title={this.props.unreadCount > 0 ? `Contacts & Friends (${this.props.unreadCount})` : "Contacts & Friends"}
                        tabClassName={this.props.unreadCount > 0 ? "notification-new-message" : "tab-color"}
                        >
                            { (contacts && allMessages) &&
                                <SocialPersonal
                                    fbsAuth={this.props.fbsAuth}
                                    fbsFirestore={this.props.fbsFirestore}
                                    user={this.props.user}
                                    username={this.props.username}
                                    userdata={this.props.userdata}
                                    contacts={friendsFilterSelected ? friends : contacts}
                                    onSearch={this.handleSearch}
                                    onClearSearch={this.handleClearSearch}
                                    onContactSelect={this.handleContactSelect}
                                    friendsFilterSelected={friendsFilterSelected}
                                    onFilterChange={this.handleFilterChange}
                                    unreadCounts={unreadCounts}
                                    allMessages={allMessages}
                                    profile={this.props.profile}
                                    activeKey={this.state.activeKey}
                                    moveUserToMessages={this.moveUserToMessages}
                                    markPersonalMessagesAsRead={this.markPersonalMessagesAsRead}
                                />
                            }
                        </Tab>
                        <Tab
                            eventKey="personal-messages"
                            title="Personal Messages"
                            tabClassName="tab-color"
                        >
                            <SocialPersonalChat
                                fbsAuth={this.props.fbsAuth}
                                fbsFirestore={this.props.fbsFirestore}
                                user={this.props.user}
                                username={this.props.username}
                                userdata={this.props.userdata}
                                contacts={friendsFilterSelected ? friends : contacts}
                                messages={messages}
                                selectedRecipientUser={selectedRecipientUser}
                                sendMessage={this.sendMessage}
                                messageText={messageText}
                                onMessageTextChange={this.handleMessageTextChange}
                                removeUserFromContacts={this.removeUserFromContacts}
                                cooldown={cooldown}
                                pfp={this.props.pfp}
                                unreadCounts={unreadCounts}
                                allMessages={allMessages}
                                profile={this.props.profile}
                                
                            />
                        </Tab>
                    </Tabs>
                </>
            </>
        );
    }
}

export default SocialPersonalTabs;
