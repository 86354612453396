// The GamePage is where all "LFG Posts" are displayed for a specific game.
// The posts are objects from the "people" array in the specific game doc
// within the games collection. Users can then search through posts and
// go to another user's profile, which takes them to the VisitAccountPage.
import React, { Component } from "react"
import { onAuthStateChanged } from "firebase/auth";
import {
    logout,
    markMessageAsRead,
    setUpMessageListener,
    setUser
} from "../utils.js"
import {
    doc,
    getDoc,
} from "firebase/firestore"

import BasicNavbar from "../components/Navbar/Navbar"
import Footerfixed from "../components/Footer/Footer"
import {
    Button,
    Image,
    Pagination,
    Dropdown,
    Modal,
    Form,
    Col,
} from "react-bootstrap"
import { Check2Square, PersonFill, Person } from "react-bootstrap-icons";
import { joinGroup, leaveGroupAlterData, updateJoinedGroupsDateAndCount } from "../utils.js";

class GamePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: undefined,
            userdata: undefined,
            messages: [], // Existing messages
            unreadCount: 0, // Tracks the unread message count

            // Page-specific state variables
            game: undefined,
            lfgPosts: [], // The people array within each game doc that holds the user's Gamer Profile and LFG Post data.
            gameExists: true, // Set to false if the game this page is currently visiting does not exist
            gameName: undefined, // set in componentDidMount from URLSearchParams - give us the string for the gameName
            gameId: null, // set in componentDidMount  from URLSearchParams - give us the number for the gameNumber
            nameIncludeFilter: "", // Search input for searching through posts.
            displayedPosts: [], // New display array for filtered and sliced this.state.games
            postsPerPage: 10, // Posts displayed per page - can be altered.
            currentPage: 1, // The current page as a refference check.

            cooldown: false, // Conditional to set timeout of .5s to allow DB to update and prevent user activity before it ends.
            groupIsFull: false, // set to true during joinLFGPost if the group the user is trying to join is full.
            groupJoined: false, // set to true if succesful in joining the group.

            // States for adding gamertag/username for the game the user is joining.
            updatedPlatformUsername: "",
            platformUsernameUploadText: "",
            platformUsernameDisplayText: "", // usually set like this - this.state.writableUserdata.joinGroupPlatformUsername.replace(/%0A/g, '\n'), but state stuff... will come up with a solution later.
            editingPlatformUsername: "",

            showGamertagsSocials: false,
            selectedPost: null, // Initialize selectedPost

            // All dropdown item states
            // Definitely a better way to do this - just using this for now.
            platformList: ["Any", "PC", "Console", "PS5", "Xbox", "Switch", "Mobile", "VR", "Other"],
            platformChoice: "Any",
            neededList: ["Any", "1 Gamer", "2 Gamers", "3 Gamers", "4 Gamers", "5 Gamers", "6+ Gamers"],
            neededChoice: "Any",
            startList: ["Any", "Now", "Later"],
            startChoice: "Any",
            objectiveList: ["Any", "Casual", "Ranked", "Practice", "Recruiting", "Social"],
            objectiveChoice: "Any",
            commsList: ["Any", "Mic", "Chat", "None"],
            commsChoice: "Any",
            sessionList: ["Any", "Unknown", "1 Hour", "1-2 Hours", "2 Hours", "2-3 Hours", "3 Hours", "3-4 Hours", "4+ Hours"],
            sessionChoice: "Any",
            skillList: ["Any", "Good", "Great", "Pro", "Average", "Noob"],
            skillChoice: "Any",
            personalityList: ["Any", "Relaxed", "Serious", "Funny", "Quiet", "Loud", "Sad :(", "Happy"],
            personalityChoice: "Any",
            ageList: ["Any", "18-24", "24-29", "30-39", "40-49", "50+", "Other"],
            ageChoice: "Any",
            genderList: ["Any", "Male", "Female", "Other"],
            genderChoice: "Any",
            locationList: ["Any", "USA", "Canada", "UK", "AUS", "Europe", "Asia", "Africa", "LATAM", "Other"],
            locationChoice: "Any",
            languageList: ["Any", "English", "French", "Spanish", "Korean", "Mandarin", "Hindi", "Other"],
            languageChoice: "Any",
        }

        this.logout = this.logout.bind(this)
        this.setUser = this.setUser.bind(this)

        this.setUpMessageListener = this.setUpMessageListener.bind(this);
        this.markMessageAsRead = this.markMessageAsRead.bind(this);

        this.passesFilters = this.passesFilters.bind(this)
        this.displayPosts = this.displayPosts.bind(this)
        this.handlePageChange = this.handlePageChange.bind(this)
        this.handleSearchChange = this.handleSearchChange.bind(this)
        this.formatDate = this.formatDate.bind(this)
        this.calculatePercentage = this.calculatePercentage.bind(this)
        this.percentageColor = this.percentageColor.bind(this)
        this.displayedPostsLogic = this.displayedPostsLogic.bind(this)
        this.renderFormattedText = this.renderFormattedText.bind(this);

        this.joinLFGPost = this.joinFGPost.bind(this);
        this.leaveGroup = this.leaveGroup.bind(this);
        this.playAudio = this.playAudio.bind(this);

        this.handleClose = this.handleClose.bind();
        this.handleShow = this.handleShow.bind();

        this.displayMembers = this.displayMembers.bind(this);
        this.mapLookingNeededToNumber = this.mapLookingNeededToNumber.bind(this);


        onAuthStateChanged(this.props.fbsAuth, async user => {
            if (user) {
                //console.log(`User ${user.uid} logging in (email: ${user.email})`)
                await this.setUser(user)

                // Inbox Snapshot
                await this.setUpMessageListener();
            }
            else {
                this.logout();
            }
        })
    }

    async componentDidMount() {
        // Use URLSearchParams to get the game "id" parameter from the URL
        // The "id" is the gameNumber field within the "AskAGamer", "Games" Doc for
        // the specific game being searched for. We then use gameNumber, which is
        // the doc id for the game within the "Games" collection, to find the
        // specific game in the "Games" Colleciton.
        const searchParameters = new URLSearchParams(window.location.search);
        const gameIdString = searchParameters.get("id");
        // Converts the number string to a number
        const gameId = parseInt(gameIdString, 10);
        // If gameId is found, then find the doc for the game withing the "Games" Collection.
        // The check for gameId === 0 is for the first doc in the collection, as 0 is treated
        // as falsy. We could technically just check for (!== null && !== undefined) instead.
        if (gameId || gameId === 0) {
            // Fetch game data from database.
            const GameDocRef = doc(this.props.fbsFirestore, "Games", `${gameId}`);
            const GameDocSnap = await getDoc(GameDocRef);
            if (GameDocSnap.exists()) {
                // Populate reviews, upvotes, etc.
                const gamesData = GameDocSnap.data();
                const lfgPostData = gamesData.people;
                this.setState({
                    game: gamesData,
                    gameId: gameId,
                    lfgPosts: lfgPostData,
                }, () => {
                    // Make sure displayedPosts state is set properly initially.
                    this.setState({ displayedPosts: this.displayedPostsLogic() });
                });
            } else {
                console.log(`Document at "Games/${this.state.gameId}" does not exist`)
                this.setState({ gameExists: false });
            }
        } else {
            this.setState({ gameExists: false });
        }
    }

    componentWillUnmount() {
        if (this.unsubscribeMessageListener) {
            this.unsubscribeMessageListener()
            this.unsubscribeMessageListener = undefined
        };
    }

    setUser = setUser;
    logout = logout;

    markMessageAsRead = markMessageAsRead;
    setUpMessageListener = setUpMessageListener;

    // Handles how this.state.lfgPosts are displayed
    // Displays the posts based on the objects in the
    // people array for the game, but firsts sorts by
    // most recent post. Then calls passesFilters() to
    // display only posts that match the search and filters
    // chosen by the user.
    // Finally, if no posts are found, it still shows up to
    // 5 posts, but uses allNoMatchFlags state to tell show
    // the which filters were not met in the post shown - unless
    // the search filter does not much, in which case
    // this.state.anyMatches === false, and a conditional is
    // shown to the user.
    // This & passesFilters() can be improved since the logic
    // can be a bit like a maze when paried with displayPosts().
    // It can also be expanded on alot for more conditionals.
    // See passesFilters() comments for more context.
    // TODO: update logic still - not 100% sure what to show
    // users, but atleast the foundation is in place.
    displayedPostsLogic() {
        const startIndex = (this.state.currentPage - 1) * this.state.postsPerPage;
        const endIndex = startIndex + this.state.postsPerPage;
        const sortedPosts = [...this.state.lfgPosts].sort((a, b) => b.postTime - a.postTime);
        let displayedPosts = [];
        let allNoMatchFlags = {};
        let anyMatches = false;

        sortedPosts.forEach((post) => {
            const { matches, noMatchFlags } = this.passesFilters(post);
            if (matches) {
                anyMatches = true;
            }
            if (matches || displayedPosts.length < 5) {
                displayedPosts.push({ post, noMatchFlags });
            }
            allNoMatchFlags = { ...allNoMatchFlags, ...noMatchFlags };
        });

        this.setState({ anyMatches, allNoMatchFlags });
        return displayedPosts.slice(startIndex, endIndex);
    }


    playAudio(source) {
        const audio = new Audio(source);
        audio.volume = 0.2;
        audio.play();
    };

    // Helper function to map 'looking_needed' string to number
    mapLookingNeededToNumber(lookingNeeded) {
        const map = {
            "Any": 6,
            "1 Gamer": 2,
            "2 Gamers": 3,
            "3 Gamers": 4,
            "4 Gamers": 5,
            "5 Gamers": 6,
            "6+ Gamers": 12,
        };
        return map[lookingNeeded] || 0; // Default to 0 if unexpected value
    }

    displayMembers(post) {
        const elements = [];
        const membersLength = post.groupMembers.length;
        const neededLength = this.mapLookingNeededToNumber(post.looking_needed);

        console.log(`Members length: ${membersLength}, Needed length: ${neededLength}`);

        for (let i = 0; i < neededLength; i++) {
            if (i < membersLength) {
                const member = post.groupMembers[i];
                elements.push(
                    <PersonFill
                        key={member.username} // Assuming username is unique
                        eventKey={i}
                        className="p-1"
                        style={{ "height": "41px", "width": "auto" }}
                    >
                        {member.username}
                    </PersonFill>
                );
            } else {
                elements.push(
                    <Person
                        key={`empty-${i}`}
                        eventKey={i}
                        className="p-1"
                        style={{ "height": "41px", "width": "auto" }}
                    />
                );
            }
        }

        return elements;
    }

    // Calls removePostData() from utils.js.
    // Removes the users LFG Post from the people array for the specific game
    // and updates their user doc with isLFG === false
    async leaveGroup(post) {
        this.setState({ cooldown: true })
        // Check if the user is currently in another group and remove them from that
        // group if they are. Then put them in the new group.
        if (this.state.writableUserdata.joinedGroupOne !== -1) {
            console.log("Removing User from previous group")
            // Calls removePosttData with appropriate parameters
            await leaveGroupAlterData(
                this.state.readonlyUserdata.inTheUniveristyProgram,
                this.props.fbsFirestore,
                this.state.readonlyUserdata.userNumber,  // User number
                this.state.writableUserdata.joinedGroupOneGameNumber,  // Game Number
                this.state.writableUserdata.joinedGroupOne, // Joined Group Number
            );
            console.log("Removed User from previous group");
        } else {
            console.log("No previous group to remove user from");
        }

        console.log("Attempting to add user to new group");
        await this.joinFGPost(post);
    }


    // Calls this util Function joinGroup().
    // Updates the Games Collection, the GroupChats collection for the
    // user who post the group (visited user), and updates the current User's User Collection.
    // * We first check if the group is full, before calling the function and throw
    // an error and conditional if it is full.
    async joinFGPost(post) {
        if (post.userNumber === this.state.writableUserdata.joinedGroupOne) {
            window.alert("Your are already in this group! Try messaging the user to see if they want to play. Or, try leaving/joining a different group. * We will make sure to update the UI soon to prevent this error from occuring.");
            this.setState({ cooldown: false, groupJoined: false });
            console.log("The Group you are trying to join is full.");
            return;
        }

        if (post) {
            const totalGroupMembers = post.groupMembers.length - 1;
            if (post.looking_needed_number <= totalGroupMembers) {
                window.alert("This group is now Full! Try messaging the user to see if you can still make it in or play with them later. Or, try joining a different group.");
                this.setState({ groupIsFull: true, cooldown: false });
                console.log("The Group you are trying to join is full.");
                return;
            } else {
                console.log("Attempting to join group:", post);
                this.setState({ groupIsFull: false });

                // Checks for if the user added/updated their Discord name, gaming platform name
                // or gaming platform username. If they did, we update their user doc and pass in the const
                // value for joinGroup to be updated elsewhere.
                const discordName = this.state.writableUserdata.joinGroupDiscord;
                const platformName = this.state.writableUserdata.joinGroupPlatform;
                const platformUsername = this.state.updatedPlatformUsername !== "" ? this.state.updatedPlatformUsername : this.state.writableUserdata.joinGroupPlatformUsername;
                // We also check if the user is currently in a group by looking at the field joinedGroupOne in their user data.
                // If joinGroupOne equals -1 (which means the aren't in a group), we set update updateGroupOne to true, else false.

                // Calls utils function to update joined groups counts and dates.
                try {
                    await updateJoinedGroupsDateAndCount(this.props.fbsFirestore, this.state.readonlyUserdata.userNumber)
                    console.log("New Joined Groups Count/Date Completed!")
                } catch (error) {
                    console.error("New Joined Groups Count/Date Failed!", error);
                }

                await joinGroup(
                    this.state.readonlyUserdata.inTheUniveristyProgram,
                    this.props.fbsAuth,
                    this.props.fbsFirestore,
                    post.looking_game_id, // Game Number
                    post.userNumber,  // Post Creator's number
                    post.username, // Post Creator's username
                    this.state.readonlyUserdata.userNumber, // Current User's number
                    this.state.readonlyUserdata.username, // Current User's username
                    discordName,
                    platformName,
                    platformUsername,
                    true,
                );
                this.setState({ groupJoined: true, cooldown: false })

            }
        } else {
            console.log("Could not find the LFG Post:", post);
            window.alert("Could not join group. Try messaging the user to see if you can play with them. Or, try joining a different group.");
            this.setState({ groupJoined: false, cooldown: false })
        }
        this.setState({ cooldown: false })
    }

    // *** Previous passesFilters function
    // Return true if the search matches text from post's looking_messages or nameIncludeFilter === ""
    // passesFilters(post) {
    //     if (!post) return false;
    //     if (this.state.nameIncludeFilter === "") return true;
    //     if (this.state.nameIncludeFilter !== "" && post.looking_message.toLowerCase().includes(this.state.nameIncludeFilter.toLowerCase()) ) return true;
    // }

    // This returns true if all filters(state variables "example"Choice) match
    // the field names of posts in the people array, as well as if the search (this.state.nameIncludeFilter)
    // matches the field name looking_message string.
    // *** This is a simple yes or no version of diplaying posts - which we might use.
    // The version of passesFilters(post) below this is more complicated, feel free to
    // uncomment this version to use instead or combine the logic.
    // passesFilters(post) {
    //     if (!post) return false;

    //     // Check if the search text matches the post's looking_message
    //     if (this.state.nameIncludeFilter !== "" && !post.looking_message.toLowerCase().includes(this.state.nameIncludeFilter.toLowerCase())) {
    //         return false;
    //     }

    //     // Initialize a variable to track if all filter conditions are met
    //     let allConditionsMet = true;

    //     // Function to check if a post value matches a specific filter choice
    //     const matchesFilter = (postValue, choice) => choice === "Any" || postValue === choice;

    //     // Define the filter choices and corresponding post values
    //     const filterChoices = [
    //         { choice: this.state.platformChoice, postValue: post.looking_platform },
    //         { choice: this.state.neededChoice, postValue: post.looking_needed },
    //         { choice: this.state.startChoice, postValue: post.looking_start },
    //         { choice: this.state.objectiveChoice, postValue: post.looking_objective },
    //         { choice: this.state.commsChoice, postValue: post.looking_comms },
    //         { choice: this.state.sessionChoice, postValue: post.looking_session },
    //         { choice: this.state.skillChoice, postValue: post.looking_skill },
    //         { choice: this.state.personalityChoice, postValue: post.looking_personality },
    //         { choice: this.state.ageChoice, postValue: post.looking_age },
    //         { choice: this.state.genderChoice, postValue: post.looking_gender },
    //         { choice: this.state.locationChoice, postValue: post.looking_location },
    //         { choice: this.state.languageChoice, postValue: post.looking_language }
    //     ];

    //     // Check each filter choice
    //     filterChoices.forEach(({ choice, postValue }) => {
    //         if (!matchesFilter(postValue, choice)) {
    //             allConditionsMet = false;
    //         }
    //     });

    //     // Return true if all conditions are met, otherwise return false
    //     return allConditionsMet;
    // }

    // Returns true or false with the same logic as the previous version of
    // passesFilters(post) above, but with the requirement that atleast 5
    // posts must be shown if it returns false. We then set state of which
    // filters failed to show to users as coniditionals.
    // The reason for this is that way users still get too see posts very
    // similar to what they are looking for, but with the indiciation of
    // which of their filters weren't met. This is needed while we still
    // have limited users as searches can't be too specific, and probably
    // needed in some capacity always to nudge the user to stay and choose
    // a teammate/group that isn't "exactly" what they are looking for.
    // *** this.state.allFiltersMustMatch forgoes this logic and just returns
    // false if no posts match the filters - a nice way to let users to have
    // autonomy here.
    // passesFilters(post) {
    //     if (!post) return { matches: false, noMatchFlags: {} };

    //     const noMatchFlags = {};

    //     if (this.state.nameIncludeFilter !== "" && !post.looking_message.toLowerCase().includes(this.state.nameIncludeFilter.toLowerCase())) {
    //         return { matches: false, noMatchFlags };
    //     }

    //     const matchesFilter = (postValue, choice) => {
    //         return choice === "Any" || postValue === choice;
    //     };

    //     const filterChoices = [
    //         { choice: this.state.platformChoice, postValue: post.looking_platform, stateKey: 'platformNoMatch' },
    //         { choice: this.state.neededChoice, postValue: post.looking_needed, stateKey: 'neededNoMatch' },
    //         { choice: this.state.startChoice, postValue: post.looking_start, stateKey: 'startNoMatch' },
    //         { choice: this.state.objectiveChoice, postValue: post.looking_objective, stateKey: 'objectiveNoMatch' },
    //         { choice: this.state.commsChoice, postValue: post.looking_comms, stateKey: 'commsNoMatch' },
    //         { choice: this.state.sessionChoice, postValue: post.looking_session, stateKey: 'sessionNoMatch' },
    //         { choice: this.state.skillChoice, postValue: post.looking_skill, stateKey: 'skillNoMatch' },
    //         { choice: this.state.personalityChoice, postValue: post.looking_personality, stateKey: 'personalityNoMatch' },
    //         { choice: this.state.ageChoice, postValue: post.looking_age, stateKey: 'ageNoMatch' },
    //         { choice: this.state.genderChoice, postValue: post.looking_gender, stateKey: 'genderNoMatch' },
    //         { choice: this.state.locationChoice, postValue: post.looking_location, stateKey: 'locationNoMatch' },
    //         { choice: this.state.languageChoice, postValue: post.looking_language, stateKey: 'languageNoMatch' }
    //     ];

    //     let matchesCount = 0;

    //     filterChoices.forEach(({ choice, postValue, stateKey }) => {
    //         if (!matchesFilter(postValue, choice)) {
    //             noMatchFlags[stateKey] = true;
    //         } else {
    //             matchesCount++;
    //         }
    //     });

    //     const matches = this.state.allFiltersMustMatch ? matchesCount === filterChoices.length : matchesCount >= 5;
    //     return { matches, noMatchFlags };
    // }

    passesFilters(post) {
        if (!post) return { matches: false, noMatchFlags: {} };

        const noMatchFlags = {};
        const searchMatch = this.state.nameIncludeFilter === "" || post.looking_message.toLowerCase().includes(this.state.nameIncludeFilter.toLowerCase());

        if (!searchMatch) {
            return { matches: false, noMatchFlags };
        }

        const matchesFilter = (postValue, choice) => {
            return choice === "Any" || postValue === choice;
        };

        const filterChoices = [
            { choice: this.state.platformChoice, postValue: post.looking_platform, stateKey: 'platformNoMatch' },
            { choice: this.state.neededChoice, postValue: post.looking_needed, stateKey: 'neededNoMatch' },
            { choice: this.state.startChoice, postValue: post.looking_start, stateKey: 'startNoMatch' },
            { choice: this.state.objectiveChoice, postValue: post.looking_objective, stateKey: 'objectiveNoMatch' },
            { choice: this.state.commsChoice, postValue: post.looking_comms, stateKey: 'commsNoMatch' },
            { choice: this.state.sessionChoice, postValue: post.looking_session, stateKey: 'sessionNoMatch' },
            { choice: this.state.skillChoice, postValue: post.looking_skill, stateKey: 'skillNoMatch' },
            { choice: this.state.personalityChoice, postValue: post.looking_personality, stateKey: 'personalityNoMatch' },
            { choice: this.state.ageChoice, postValue: post.looking_age, stateKey: 'ageNoMatch' },
            { choice: this.state.genderChoice, postValue: post.looking_gender, stateKey: 'genderNoMatch' },
            { choice: this.state.locationChoice, postValue: post.looking_location, stateKey: 'locationNoMatch' },
            { choice: this.state.languageChoice, postValue: post.looking_language, stateKey: 'languageNoMatch' }
        ];

        let matchesCount = 0;

        filterChoices.forEach(({ choice, postValue, stateKey }) => {
            if (!matchesFilter(postValue, choice)) {
                noMatchFlags[stateKey] = true;
            } else {
                matchesCount++;
            }
        });

        const matches = this.state.allFiltersMustMatch ? matchesCount === filterChoices.length : matchesCount >= 5;
        return { matches, noMatchFlags };
    }

    // Changes the page
    handlePageChange(newPage) {
        this.setState({ currentPage: newPage }, () => {
            this.setState({ displayedPosts: this.displayedPostsLogic() });
        });
    }

    // Checks for search input and updates page.
    handleSearchChange(e) {
        const searchValue = e.target.value;
        this.setState({ nameIncludeFilter: searchValue, currentPage: 1 }, () => {
            this.setState({ displayedPosts: this.displayedPostsLogic() });
        });
    }

    // Changes the firestore timestamp to a real js object date.
    // Called in displayPosts() to show when/how long ago a post was made.
    formatDate(date) {
        const now = new Date();
        const pastTime = new Date(date.seconds * 1000); // Converts Firestore timestamp to Date object
        const diff = now - pastTime; // Difference in milliseconds
        const diffMinutes = Math.floor(diff / 60000); // Converts to minutes
        const diffHours = Math.floor(diffMinutes / 60); // Converts to hours
        const diffDays = Math.floor(diffHours / 24); // Converts to days

        // Display render of date with user's timezone
        const options = { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        const formattedDate = pastTime.toLocaleDateString('default', options);
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        // Shows how long ago the post was in minutes, hours, or days
        let timeAgo = '';
        if (diffMinutes < 60) {
            timeAgo = `${diffMinutes} min ago`;
        } else if (diffHours < 24) {
            timeAgo = `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
        } else {
            timeAgo = `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
        }

        return {
            formattedDate,
            timezone,
            timeAgo
        };
    }

    // Used in render for displaying GG percentage, passed to percentageColor function below.
    calculatePercentage(GG, Total) {
        if (Total === 0) {
            return null; // If there are no votes, return null
        }
        // Calculates percentage and rounds to a whole number
        const percentage = (GG / Total) * 100;
        return Math.round(percentage);
    }

    // Return a CSS class that sets the color based on the GG percentage.
    percentageColor(GG, Total) {
        const percentage = this.calculatePercentage(GG, Total);
        if (percentage === null) {
            return '';
        } else if (percentage > 79) {
            return 'game-percentage-green';
        } else if (percentage >= 50) {
            return 'game-percentage-white';
        } else {
            return 'game-percentage-red';
        }
    }

    // Looks for %0A URL encoded line breaks and links.
    // Handles reformatting text to wrap links in <a> tags, and
    // shows the user visual spaces with <br /> if they pressed enter or
    // shift+enter as represented by "\n".
    renderFormattedText = (text) => {
        const linkRegex = /(?:https?|ftp):\/\/[^\s]+(?:\s|$)/g;
        const lines = text.split(/(?:\n|%0A)/);

        const elements = [];

        lines.forEach((line, lineIndex) => {
            const linkMatches = line.match(linkRegex);
            if (linkMatches) {
                let lastIndex = 0;

                linkMatches.forEach((linkMatch, index) => {
                    const linkStart = line.indexOf(linkMatch, lastIndex);
                    const linkEnd = linkStart + linkMatch.length;

                    // Adds the preceding text as regular text
                    elements.push(<span key={`text-${lineIndex}-${index}`}>{line.substring(lastIndex, linkStart)}</span>);

                    // Wraps the link in an anchor
                    elements.push(
                        <a
                            key={`link-${lineIndex}-${index}`}
                            className="change-link-color-chat"
                            style={{
                                wordWrap: 'break-word',
                                wordBreak: 'break-all',
                                whiteSpace: 'normal',
                            }}
                            href={decodeURIComponent(linkMatch)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {decodeURIComponent(linkMatch)}
                        </a>
                    );

                    // Updates the lastIndex to the end of the current link
                    lastIndex = linkEnd;
                });

                // Adds the remaining text after the last link
                elements.push(<span key={`text-${lineIndex}-last`}>{line.substring(lastIndex)}</span>);
            } else {
                // If no links, add the entire line as regular text
                elements.push(<span key={`text-${lineIndex}`}>{line}</span>);
            }

            // Add the line break after each line (except the last line)
            if (lineIndex < lines.length - 1) {
                elements.push(<br key={`br-${lineIndex}`} />);
            }
        });

        return elements;
    };

    // Hides Modal that displays the group members Gamertags & Social usernames.
    handleClose() {
        this.setState({ showGamertagsSocials: false });
    }

    // Shows Modal that displays the group members Gamertags & Social usernames.
    handleShow() {
        this.setState({ showGamertagsSocials: true });
    }

    // Display based on passesFilters/displayedPostsLogic/handlePageChange/handleSearchChange functions,
    // and Pagination logic set at top of main render before the return statement.
    // The parameter "post" is mapped in the render from this.state.lfgPosts
    // after filtering/sorting/ and conditionals set in displayedPostsLogic().
    displayPosts(post, noMatchFlags) {
        if (!post) {
            return null;
        }

        const { formattedDate, timezone, timeAgo } = this.formatDate(post.postTime);
        return (
            <>
                <div
                    className="p-2 game-page-post-containers-mobile mt-2 desktop-change-lfgposts-container-display-center"
                >
                    <div
                        style={{ "margin-bottom": "1em" }}
                        className="d-flex flex-column tab-textbox-hover-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile desktop-change-width-lfgposts-1000"
                    >
                        <>
                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                <div
                                    className="d-inline-flex flex-row align-items-center flex-wrap desktop-display-flex-column"
                                    style={{ "width": "100%" }}
                                >
                                    <div
                                        className="d-flex flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                        style={{ "flex-grow": "1" }}
                                    >
                                        <p style={{ "width": "100%", "margin-bottom": "0" }}>
                                            <div className="d-flex flex-column flex-wrap justify-content-center align-items-center overflow-auto text-center" >
                                                <small
                                                    style={{ "font-size": "1.5em", "font-weight": "600", "color": "#8ebbff", "text-align": "center", "text-shadow": "0px -3px 2px #000000" }}
                                                    className="desktop-change-lfgposts-username-size-margin"
                                                >
                                                    {post.username}
                                                </small>
                                                <small
                                                    style={{ "font-size": "1em", "text-align": "center", "text-shadow": "0px -3px 2px #000000" }}
                                                    className="desktop-increase-demo-gamer-profile-text-size"
                                                >
                                                    GG's: {post.ggTotal}
                                                    {post.ggNoobTotal && post.ggNoobTotal > 0
                                                        ?
                                                        <span className={`${this.percentageColor(post.ggTotal, post.ggNoobTotal)}`}>
                                                            &nbsp;({this.calculatePercentage(post.ggTotal, post.ggNoobTotal)})%
                                                        </span>
                                                        :
                                                        null
                                                    }
                                                </small>
                                            </div>
                                        </p>
                                    </div>
                                    <div
                                        className="d-flex flex-column flex-grow align-items-center justify-content-center ask-a-gamer-profile-margins-game-spotlight-mobile desktop-increase-posted-time-demo-gamer-profile"
                                        style={{ "flex-grow": "1" }}
                                    >
                                        <span
                                            className="list-of-stats"
                                            style={{ "color": "#75f7fff0", "font-weight": "600" }}
                                        >
                                            Needed: {post.looking_needed === "" ? "Any" : post.looking_needed}
                                        </span>
                                        <span
                                            className="list-of-stats"
                                            style={{ "color": "#75f7fff0", "font-weight": "600" }}
                                        >
                                            Platform: {post.looking_platform === "" ? "Any" : post.looking_platform}
                                        </span>
                                        <span
                                            className="list-of-stats"
                                            style={{ "color": "#75f7fff0", "font-weight": "600" }}
                                        >
                                            Objective: {post.looking_objective === "" ? "Any" : post.looking_objective}
                                        </span>
                                        <span
                                            className="my-2"
                                            style={{ "font-size": ".9em", "text-align": "center" }}
                                        >
                                            Posted at: {formattedDate} {timezone}
                                            <br />
                                            ({timeAgo})
                                        </span>
                                    </div>
                                    <hr
                                        className="ask-a-gamer-answer-spotlight-hr"
                                        style={{ "width": "100%" }}
                                    />
                                    <div
                                        className="d-flex flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                        style={{ "flex-grow": "1" }}
                                    >
                                        <p style={{ "width": "100%" }}>
                                            <div className="d-flex flex-row flex-wrap justify-content-between overflow-auto" >
                                                <small
                                                    style={{ "font-size": "1.2em", "color": "#ffea99", "text-align": "center", "text-shadow": "0px -3px 2px #000000", "width": "100%", "text-wrap": "pretty" }}
                                                    className="desktop-increase-demo-gamer-profile-text-size desktop-change-width-lfgposts-lookingMessage-900"
                                                >
                                                    "{post.looking_message === "" ? `${post.username} did not elaborate on what they wanted to do.` : this.renderFormattedText(post.looking_message)}"
                                                </small>
                                            </div>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <hr className="ask-a-gamer-answer-spotlight-hr" />
                            <div
                                className="d-flex flex-row"
                                style={{ "flex-wrap": "wrap", "border": "none" }}
                            >
                                <div className="navuserchatInfo d-flex align-item-center ask-a-gamer-profile-mastered-games-tablet change-demo-gamer-profile-objective-comms-commitment">
                                    <div className="desktop-display-flex-column desktop-change-margin-left-1">
                                        <h5 style={{ "font-size": "1.2em" }}>Start Time & Comms</h5>
                                        <div className="d-flex flex-row align-items-center justify-content-center desktop-margin-top-1 py-1">
                                            <span style={{ "padding": ".75em" }}>
                                                <div
                                                    className="tab-textbox-buttons justify-content-around px-2 py-2 text-center"
                                                    style={{ "box-shadow": "0px -5px 10px 5px #040404", "text-shadow": "0 0 2px #000000", "font-weight": "650", "font-size": ".9em", "width": "105px" }}
                                                >
                                                    {post.looking_time === "" ? "Any" : post.looking_time}
                                                    <Check2Square style={{ "color": "white", "margin-left": ".4em", "margin-bottom": ".2em", "align-items": "center" }} />
                                                </div>
                                            </span>
                                            <span style={{ "padding": ".75em" }}>
                                                <div
                                                    className="tab-textbox-buttons justify-content-around px-2 py-2 text-center"
                                                    style={{ "box-shadow": "0px -5px 10px 5px #040404", "text-shadow": "0 0 2px #000000", "font-weight": "650", "font-size": ".9em", "width": "105px" }}
                                                >
                                                    {post.looking_comms === "" ? "Any" : post.looking_comms}
                                                    <Check2Square style={{ "color": "white", "margin-left": ".4em", "margin-bottom": ".2em", "align-items": "center" }} />
                                                </div>
                                            </span>
                                        </div>
                                        <hr
                                            style={{ "width": "100%" }}
                                            className="ask-a-gamer-answer-spotlight-hr"
                                        />
                                        <div className="d-flex justify-content-between align-items-center flex-wrap desktop-margin-top-1 py-1 mb-2">
                                            <div
                                                className="d-inline-flex flex-row align-items-center flex-wrap"
                                                style={{ "width": "100%" }}
                                            >
                                                <div
                                                    className="d-flex align-items-center justify-content-center flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                                    style={{ "flexGrow": "1", "flex-wrap": "wrap" }}
                                                >
                                                    {this.displayMembers(post)}
                                                </div>
                                            </div>
                                        </div>
                                        {post.groupMembers.length === this.mapLookingNeededToNumber(post.looking_needed) &&
                                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                                <div
                                                    className="d-inline-flex flex-row align-items-center flex-wrap"
                                                    style={{ "width": "100%" }}
                                                >
                                                    <div
                                                        className="d-flex align-items-center justify-content-center flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                                        style={{ "flexGrow": "1" }}
                                                    >
                                                        <span className="group-full-text-display glow">
                                                            Group Full!
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div className="d-flex flex-row align-items-center justify-content-center desktop-margin-top-1 py-1">
                                            {this.state.cooldown
                                                ?
                                                <Button
                                                    className="d-flex flex-column mb-3 mx-2 p-2 fs-5 align-items-center change-padding-tablet font-size-75-tablet width-auto-tablet"
                                                    style={{ "background-color": "#1d77ff", "font-weight": "600", "width": "240px" }}
                                                    disabled
                                                >
                                                    Joining Group ... <div className="vote-circle-inline-loading-animation" />
                                                </Button>
                                                :
                                                <Button
                                                    className="d-flex flex-column mb-3 mx-2 p-2 fs-5 align-items-center change-padding-tablet font-size-75-tablet width-auto-tablet"
                                                    style={{ "background-color": "#1d77ff", "font-weight": "600", "width": "240px" }}
                                                    disable={!this.state.user || this.state.readonlyUserdata.userNumber === post.userNumber}
                                                    onClick={async () => {
                                                        this.playAudio('/EnterQueueSound.mp3');
                                                        this.setState({ showGamertagsSocials: true, selectedPost: post }); // Store the current post in the state
                                                    }}
                                                >
                                                    Join Group
                                                </Button>
                                            }
                                        </div>
                                        {/* Pop up windown for users to optionally set their discord name and gamertag. */}
                                        <Modal
                                            show={this.state.showGamertagsSocials}
                                            onHide={async () => {
                                                this.setState({
                                                    showGamertagsSocials: false
                                                });
                                                await this.leaveGroup(this.state.selectedPost); // Use the stored post from the state
                                            }}
                                            aria-labelledby="contained-modal-title-vcenter"
                                            centered
                                            className="px-3"
                                        >
                                            <Modal.Header>
                                                <Modal.Title style={{ "text-wrap": "balance", "width": "100%", "text-align": "center" }}>
                                                    Add Your {this.state.game.gameName} Username
                                                    <br />
                                                    <span style={{ "font-size": ".75em" }}>(Optional)</span>
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="d-flex flex-column align-items-center justify-content-between">
                                                    <div className="d-flex flex-row flex-wrap justify-content-center overflow-auto" >
                                                        <textarea
                                                            value={this.state.platformUsernameDisplayText}
                                                            placeholder={"type username and click update"}
                                                            style={{ "font-size": "1.2em", "color": "#000000", "border-radius": "10px", "height": "50px", "width": "100%" }}
                                                            className="desktop-increase-demo-gamer-profile-text-size"
                                                            onChange={(e) => {
                                                                const platformUsernameDisplayText = e.target.value;
                                                                const modifiedText = platformUsernameDisplayText.replace(/\n/g, '%0A'); // Uses %0A for URL-encoded newline
                                                                this.setState({
                                                                    platformUsernameUploadText: modifiedText,
                                                                    editingPlatformUsername: modifiedText,
                                                                    platformUsernameDisplayText,
                                                                });
                                                            }}

                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter' || e.shiftKey) {
                                                                    const modifiedText = `${this.state.platformUsernameUploadText}%0A`;
                                                                    this.setState({
                                                                        platformUsernameUploadText: modifiedText,
                                                                        editingPlatformUsername: modifiedText,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                        <Button
                                                            style={{ "height": "auto", "margin-bottom": ".5em", "margin-top": "1em" }}
                                                            onClick={async () => {
                                                                this.setState({
                                                                    updatedPlatformUsername: this.state.platformUsernameUploadText,
                                                                });
                                                            }}
                                                        >
                                                            {this.state.updatedPlatformUsername === "" ? "Update" : "Updated!"}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button
                                                    variant="secondary"
                                                    onClick={async () => {
                                                        this.setState({
                                                            showGamertagsSocials: false
                                                        });
                                                        await this.leaveGroup(this.state.selectedPost); // Use the stored post from the state
                                                    }}
                                                >
                                                    Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                        {/* Rest of post */}

                                        <div className="d-flex flex-row align-items-center justify-content-center desktop-margin-top-1 py-1">
                                            <Button
                                                className="d-flex flex-column mx-2 p-2 fs-5 align-items-center change-padding-tablet font-size-75-tablet width-auto-tablet"
                                                style={{ "background-color": "#959595cf", "font-weight": "600", "width": "175px" }}
                                                href={`/visitaccount?gamer=${post.username}&gameName=${this.state.game.gameName}&gameNumber=${post.looking_game_id}`}
                                            >
                                                Go to Profile
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ "margin-top": ".5em" }}>
                                {Object.keys(noMatchFlags).length > 0 && (
                                    <div className="no-match-flags">
                                        <h5>Filters not matched:</h5>
                                        <ul
                                            className="desktop-increase-demo-gamer-profile-text-size"
                                            style={{ "color": "#ff8080" }}
                                        >
                                            {Object.keys(noMatchFlags).map(flag => (
                                                <li key={flag}>{flag.replace('NoMatch', '')}</li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        </>
                    </div>
                </div>
                <hr className="ask-a-gamer-answer-spotlight-hr" />
            </>
        );
    }

    // TODO: Create a functional component for dropdown fitlers (post-launch goal), update
    // the search bar to allow for mistyped words slightly, make sure to finish and improve
    // conditional logic when filters don't match (basically show users and tell them things
    // so they know), and update the styling for desktop.
    render() {
        const out = [];

        // Navbar
        out.push(
            <BasicNavbar
                user={this.state.user}
                userdata={{ ...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata }}
                unreadCount={this.state.unreadCount}
                universityName={(this.state.readonlyUserdata && this.state.readonlyUserdata.inTheUniveristyProgram) ? this.state.readonlyUserdata.universityName : "University"}
            />
        )

        // Page Body

        // Alternate page body for no game and/or game doesn't exist in database.
        if (!this.state.gameExists) {
            out.push(
                <>
                    <div
                        className="body-content visting-home-page-fade-animation change-explore-body-content-small-screen explore-body-content-padding-mobile
                        explore-body-content-padding-large ask-a-gamer-explore-padding-left-right-1-mobile"
                    >
                        <div
                            className="d-flex flex-column align-items-center justify-content-center"
                            style={{ "text-align": "center", "width": "100%", "padding": "1em", "font-size": "1.5em", "height": "600px", "font-weight": "700" }}
                        >
                            Sorry, but there was either an error searching for the game on our end or we do not currently support this game yet. Please reach out to us if the problem continues or if you would like us to add this game to the platform.
                        </div>
                    </div>
                </>
            )

            return out;
        }

        // *** Previous logic with simpler passesFilters() function
        // unccoment this comment out below if moving to the simpler
        // version of the function.
        // *** Nvmd, this is a whole thing, just look a previous commit
        // for displayPost logic and render integration, then uncomment,
        // the older passFilters() function if you want to do this.

        // //Pagination Logic - Can be moved outside render if we want
        // const startIndex = (this.state.currentPage - 1) * this.state.postsPerPage;
        // const endIndex = startIndex + this.state.postsPerPage;
        // // Sorts games alphabetically
        // const sortedPosts =  [...this.state.lfgPosts].sort((a, b) => b.looking_time - a.looking_time);
        // // Filters games based on search term.
        // const filteredPosts = sortedPosts.filter((post) => this.passesFilters(post));
        // // Displays games on the current page for pagination.
        // const displayedPosts = filteredPosts.slice(startIndex, endIndex);
        // // Calculates the total number of pages needed to display all the search results.
        // const totalPages = Math.ceil(filteredPosts.length / this.state.postsPerPage);
        // // Updates pagination.item (i.e. Total Page amount) to be dynamic based on search results.
        // // *Update the second param, which is currently 5, to whatever when we have more games.
        // // TODO: This works, but check logic to make sure it works under various circumstances.
        // const pageNumbersToShow = Math.min(totalPages, 5);
        // const middlePageNumber = Math.floor(pageNumbersToShow / 2);
        // let firstPageNumber = Math.max(1, this.state.currentPage - middlePageNumber);
        // const lastPageNumber = Math.min(totalPages, firstPageNumber + pageNumbersToShow - 1);
        // // Adjusts firstPageNumber - it was disappearing when the last page didn't have 10 games for some reason.
        // if (lastPageNumber === totalPages) {
        //     firstPageNumber = Math.max(1, lastPageNumber - pageNumbersToShow + 1);
        // }

        // *** New pagination and display post logic with updated
        // passesFilters() function

        // Pagination Logic - Can be moved outside render if we want
        const filteredPosts = this.state.lfgPosts.filter(post => this.passesFilters(post).matches);
        const totalPages = Math.ceil(filteredPosts.length / this.state.postsPerPage);
        const pageNumbersToShow = Math.min(totalPages, 5);
        const middlePageNumber = Math.floor(pageNumbersToShow / 2);
        let firstPageNumber = Math.max(1, this.state.currentPage - middlePageNumber);
        const lastPageNumber = Math.min(totalPages, firstPageNumber + pageNumbersToShow - 1);

        if (lastPageNumber === totalPages) {
            firstPageNumber = Math.max(1, lastPageNumber - pageNumbersToShow + 1);
        }

        // Standard display if we support the current game the user searched for.
        if (this.state.game) {
            out.push(
                <>
                    <div
                        className="body-content visting-home-page-fade-animation change-explore-body-content-small-screen explore-body-content-padding-mobile
                        explore-body-content-padding-large ask-a-gamer-explore-padding-left-right-1-mobile"
                    >
                        <div className="mt-2">
                            <div className="d-flex flex-column justify-content-between align-items-center game-details-flex-column-mobile pb-1">
                                <div className="d-flex flex-column justify-content-center align-items-center increases-matchmake-title-font-desktop">
                                    <h1 style={{ "margin-bottom": ".5em", "margin-top": ".25em", "font-style": "oblique", "text-align": "center", "text-shadow": "0 -1px 3px #000000eb" }}>
                                        {this.state.game.gameName}
                                    </h1>
                                    <div className="d-flex justify-content-center align-items-center game-details-play">
                                        <div
                                            className="p-2"
                                            style={{ "height": "auto", "max-width": "850px" }}
                                        >
                                            <Image
                                                className="shadow"
                                                src={this.state.game.gameImagePath}
                                                alt="Game Image"
                                                rounded
                                                fluid
                                            />
                                        </div>
                                    </div>
                                    <h1 style={{ "margin-top": "1em", "font-style": "oblique", "text-align": "center", "text-shadow": "0 -1px 3px #000000eb" }}>
                                        Gamers LFG:
                                        <span style={{ "color": "#faff97", "font-weight": "600" }}> {filteredPosts.length}</span>
                                    </h1>
                                    <h3
                                        className="px-2 pb-1 pt-3 text-center make-width-auto-desktop"
                                        style={{ "width": "350px", "font-size": ".87em", "color": "#fdffd7" }}
                                    >
                                        {!this.state.user &&
                                            <ul style={{ "max-width": "920px", "text-align": "start", "padding-left": "1em" }}>
                                                <li style={{ "margin-bottom": ".5em" }}>
                                                    You need to sign in or create an account to join groups and access all social features! Be sure to do so now <a href="/" className="change-link-color-chat">here</a>!
                                                </li>
                                            </ul>
                                        }
                                        <ul style={{ "max-width": "920px", "text-align": "start", "padding-left": "1em" }}>
                                            <li style={{ "margin-bottom": ".5em" }}>
                                                Use the search bar to look for words, phrases, etc. within posts and set filters to sort through posts based on your preferences. Selecting "The Filters Must Match" option will show only the posts that match your exact filter choices.
                                            </li>
                                        </ul>
                                    </h3>
                                </div>
                            </div>
                            <div
                                className="mb-2 pe-3 w-100 ask-a-gamer-explore-margin-padding-right-left-0-mobile"
                                style={{ "height": "95%" }}
                            >
                                <div className="h-100 w-95 p-2 ask-a-gamer-explore-margin-padding-right-left-0-mobile">
                                    <div className="between-and-flex-important flex-column ask-indemni-reduce-font-size-mobile ask-a-gamer-explore-flex-column-page-input-mobile">
                                        <div
                                            style={{ "width": "100%" }}
                                            className="d-flex flex-row justify-content-start align-items-center"
                                        >
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-start ask-a-gamer-explore-margin-top-input-mobile hange-demo-input-div-container-align desktop-increse-font-1point1"
                                                style={{ "width": "100%" }}
                                            >
                                                <h5
                                                    style={{ "width": "100%", "text-decoration": "underline", "margin-bottom": ".5em" }}
                                                    className="demo-page-increase-font-section-titles make-width-auto-desktop"
                                                >
                                                    Optional Filters
                                                </h5>
                                                <Button
                                                    style={{ "background-color": "#7e7e7e1c" }}
                                                    className="ms-3 mb-2 w-30 game-page-reset-filters-button-mobile reset-filters-button-display-desktop"
                                                    onClick={() => this.setState({
                                                        platformChoice: "Any",
                                                        neededChoice: "Any",
                                                        startChoice: "Any",
                                                        objectiveChoice: "Any",
                                                        commsChoice: "Any",
                                                        sessionChoice: "Any",
                                                        skillChoice: "Any",
                                                        personalityChoice: "Any",
                                                        ageChoice: "Any",
                                                        genderChoice: "Any",
                                                        locationChoice: "Any",
                                                        languageChoice: "Any",
                                                        currentPage: 1,
                                                    }, () => {
                                                        this.setState({ displayedPosts: this.displayedPostsLogic() });
                                                    })}
                                                >
                                                    Reset Filters
                                                </Button>
                                            </div>

                                        </div>
                                    </div>
                                    <div
                                        style={{ "width": "100%", "height": "30px", "margin-bottom": ".25em", "margin-top": ".25em" }}
                                        className="d-flex flex-row justify-content-between align-items-center desktop-increse-font-1point1"
                                    >
                                        <div className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile">
                                            <Form.Group as={Col} md className="mb-1" style={{ "font-size": "1em", "font-weight": "600", "color": "#8ebbff" }} controlId="mustMatchGame">
                                                <Form.Check
                                                    type="checkbox"
                                                    onClick={(e) => {
                                                        this.setState({ allFiltersMustMatch: e.target.checked }, () => {
                                                            this.setState({ displayedPosts: this.displayedPostsLogic() });
                                                        });
                                                    }}
                                                    label="The Filters Must Match"
                                                    optional
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="By selecting this option, you will only see exact filter/search match results."
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>

                                    {/* Start of filters
                                        TODO: I'm sure there is a way to make this easier to eliminate reused code
                                        possibily make updating the DB easier, but we can think about it later. */}
                                    <div className="flex-filters-demo-desktop">
                                        <div
                                            style={{ "width": "40%", "font-size": ".9em", "font-weight": "550" }}
                                            className="mb-2 d-flex flex-row justify-content-between align-items-center ask-a-gamer-explore-width-auto-mobile change-filters-div-width-desktop"
                                        >
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile"
                                                style={{ "width": "50%" }}
                                            >
                                                <span
                                                    style={{ "width": "100px" }}
                                                    className="ms-3"
                                                >
                                                    Platform
                                                </span>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="Console means PS5 & Xbox, Other means anything not listed like older generation consoles"
                                                    style={{ "font-size": ".8em", "padding-left": ".2em", "padding-right": ".2em" }}
                                                >
                                                    <Dropdown.Toggle
                                                        style={{ "width": "76px" }}
                                                        className="ms-3 w-30 demo-game-dropdown-button-mobile change-filter-button-width-desktop"
                                                    >
                                                        {this.state.platformChoice}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="change-filter-dropdown-menu-width-desktop" style={{ "font-size": "1em", "text-align": "center", "min-width": "76px", "background": "#212121ed" }}>
                                                        {this.state.platformList.map((platform) => (
                                                            <Dropdown.Item
                                                                key={platform}
                                                                eventKey={platform}
                                                                onClick={() => {
                                                                    this.setState({ platformChoice: platform }, () => {
                                                                        this.setState({ displayedPosts: this.displayedPostsLogic() });
                                                                    });
                                                                }}
                                                            >
                                                                {platform}
                                                            </Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile"
                                                style={{ "width": "50%" }}
                                            >
                                                <span
                                                    style={{ "width": "100px" }}
                                                    className="ms-3"
                                                >
                                                    Start Time
                                                </span>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="The 'Later' option means you'd like to game later, like in a few hours, or the weekend, etc."
                                                    style={{ "font-size": ".8em", "padding-left": ".2em", "padding-right": ".2em" }}
                                                >
                                                    <Dropdown.Toggle
                                                        style={{ "width": "76px" }}
                                                        className="ms-3 w-30 demo-game-dropdown-button-mobile change-filter-button-width-desktop"
                                                    >
                                                        {this.state.startChoice}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="change-filter-dropdown-menu-width-desktop" style={{ "font-size": "1em", "text-align": "center", "min-width": "76px", "background": "#212121ed" }}>
                                                        {this.state.startList.map((start) => (
                                                            <Dropdown.Item
                                                                key={start}
                                                                eventKey={start}
                                                                onClick={() => {
                                                                    this.setState({ startChoice: start }, () => {
                                                                        this.setState({ displayedPosts: this.displayedPostsLogic() });
                                                                    });
                                                                }}
                                                            >
                                                                {start}
                                                            </Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div
                                            style={{ "width": "40%", "font-size": ".9em", "font-weight": "550" }}
                                            className="mb-2 d-flex flex-row justify-content-between align-items-center ask-a-gamer-explore-width-auto-mobile change-filters-div-width-desktop"
                                        >
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile"
                                                style={{ "width": "50%" }}
                                            >
                                                <span
                                                    style={{ "width": "100px" }}
                                                    className="ms-3"
                                                >
                                                    Needed
                                                </span>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="This is how people the gamer is looking to have join their team."
                                                    style={{ "font-size": ".8em", "padding-left": ".2em", "padding-right": ".2em" }}
                                                >
                                                    <Dropdown.Toggle
                                                        style={{ "width": "76px" }}
                                                        className="ms-3 w-30 demo-game-dropdown-button-mobile change-filter-button-width-desktop"
                                                    >
                                                        {this.state.neededChoice}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="change-filter-dropdown-menu-width-desktop" style={{ "font-size": "1em", "text-align": "center", "min-width": "76px", "background": "#212121ed" }}>
                                                        {this.state.neededList.map((needed) => (
                                                            <Dropdown.Item
                                                                key={needed}
                                                                eventKey={needed}
                                                                onClick={() => {
                                                                    this.setState({ neededChoice: needed }, () => {
                                                                        this.setState({ displayedPosts: this.displayedPostsLogic() });
                                                                    });
                                                                }}
                                                            >
                                                                {needed}
                                                            </Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile"
                                                style={{ "width": "50%" }}
                                            >
                                                <span
                                                    style={{ "width": "100px" }}
                                                    className="ms-3"
                                                >
                                                    Objective
                                                </span>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="This filter is the objective of teaming up - casual means non-competitive modes, social means your looking to chat/find friends, etc."
                                                    style={{ "font-size": ".8em", "padding-left": ".2em", "padding-right": ".2em" }}
                                                >
                                                    <Dropdown.Toggle
                                                        style={{ "width": "76px" }}
                                                        className="ms-3 w-30 demo-game-dropdown-button-mobile change-filter-button-width-desktop"
                                                    >
                                                        {this.state.objectiveChoice}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="change-filter-dropdown-menu-width-desktop" style={{ "font-size": "1em", "text-align": "center", "min-width": "76px", "background": "#212121ed" }}>
                                                        {this.state.objectiveList.map((objective) => (
                                                            <Dropdown.Item
                                                                key={objective}
                                                                eventKey={objective}
                                                                onClick={() => {
                                                                    this.setState({ objectiveChoice: objective }, () => {
                                                                        this.setState({ displayedPosts: this.displayedPostsLogic() });
                                                                    });
                                                                }}
                                                            >
                                                                {objective}
                                                            </Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-filters-demo-desktop">
                                        <div
                                            style={{ "width": "40%", "font-size": ".9em", "font-weight": "550" }}
                                            className="mb-2 d-flex flex-row justify-content-between align-items-center ask-a-gamer-explore-width-auto-mobile change-filters-div-width-desktop"
                                        >
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile"
                                                style={{ "width": "50%" }}
                                            >
                                                <span
                                                    style={{ "width": "100px" }}
                                                    className="ms-3"
                                                >
                                                    Comms
                                                </span>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="This filter implies what the required form of communication will be. We will add more options/functionality when we are live."
                                                    style={{ "font-size": ".8em", "padding-left": ".2em", "padding-right": ".2em" }}
                                                >
                                                    <Dropdown.Toggle
                                                        style={{ "width": "76px" }}
                                                        className="ms-3 w-30 demo-game-dropdown-button-mobile change-filter-button-width-desktop"
                                                    >
                                                        {this.state.commsChoice}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="change-filter-dropdown-menu-width-desktop" style={{ "font-size": "1em", "text-align": "center", "min-width": "76px", "background": "#212121ed" }}>
                                                        {this.state.commsList.map((comms) => (
                                                            <Dropdown.Item
                                                                key={comms}
                                                                eventKey={comms}
                                                                onClick={() => {
                                                                    this.setState({ commsChoice: comms }, () => {
                                                                        this.setState({ displayedPosts: this.displayedPostsLogic() });
                                                                    });
                                                                }}
                                                            >
                                                                {comms}
                                                            </Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile"
                                                style={{ "width": "50%" }}
                                            >
                                                <span
                                                    style={{ "width": "100px" }}
                                                    className="ms-3"
                                                >
                                                    Session
                                                </span>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="This is how long you plan on gaming for when teaming up."
                                                    style={{ "font-size": ".8em", "padding-left": ".2em", "padding-right": ".2em" }}
                                                >
                                                    <Dropdown.Toggle
                                                        style={{ "width": "76px" }}
                                                        className="ms-3 w-30 demo-game-dropdown-button-mobile change-filter-button-width-desktop"
                                                    >
                                                        {this.state.sessionChoice}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="change-filter-dropdown-menu-width-desktop" style={{ "font-size": "1em", "text-align": "center", "min-width": "76px", "background": "#212121ed" }}>
                                                        {this.state.sessionList.map((session) => (
                                                            <Dropdown.Item
                                                                key={session}
                                                                eventKey={session}
                                                                onClick={() => {
                                                                    this.setState({ sessionChoice: session }, () => {
                                                                        this.setState({ displayedPosts: this.displayedPostsLogic() });
                                                                    });
                                                                }}
                                                            >
                                                                {session}
                                                            </Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                        <div
                                            style={{ "width": "40%", "font-size": ".9em", "font-weight": "550" }}
                                            className="mb-2 d-flex flex-row justify-content-between align-items-center ask-a-gamer-explore-width-auto-mobile change-filters-div-width-desktop"
                                        >
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile"
                                                style={{ "width": "50%" }}
                                            >
                                                <span
                                                    style={{ "width": "100px" }}
                                                    className="ms-3"
                                                >
                                                    Location
                                                </span>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="This filter is where the gamer is currently located. We will expand this to more countries and time zones in the future."
                                                    style={{ "font-size": ".8em", "padding-left": ".2em", "padding-right": ".2em" }}
                                                >
                                                    <Dropdown.Toggle
                                                        style={{ "width": "76px" }}
                                                        className="ms-3 w-30 demo-game-dropdown-button-mobile change-filter-button-width-desktop"
                                                    >
                                                        {this.state.locationChoice}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="change-filter-dropdown-menu-width-desktop" style={{ "font-size": "1em", "text-align": "center", "min-width": "76px", "background": "#212121ed" }}>
                                                        {this.state.locationList.map((location) => (
                                                            <Dropdown.Item
                                                                key={location}
                                                                eventKey={location}
                                                                onClick={() => {
                                                                    this.setState({ locationChoice: location }, () => {
                                                                        this.setState({ displayedPosts: this.displayedPostsLogic() });
                                                                    });
                                                                }}
                                                            >
                                                                {location}
                                                            </Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center ask-a-gamer-explore-margin-top-input-mobile"
                                                style={{ "width": "50%" }}
                                            >
                                                <span
                                                    style={{ "width": "100px" }}
                                                    className="ms-3"
                                                >
                                                    Language
                                                </span>
                                                <Dropdown
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="This is the gamers preferred communication language. We will add more when we are live."
                                                    style={{ "font-size": ".8em", "padding-left": ".2em", "padding-right": ".2em" }}
                                                >
                                                    <Dropdown.Toggle
                                                        style={{ "width": "76px" }}
                                                        className="ms-3 w-30 demo-game-dropdown-button-mobile change-filter-button-width-desktop"
                                                    >
                                                        {this.state.languageChoice}
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="change-filter-dropdown-menu-width-desktop" style={{ "font-size": "1em", "text-align": "center", "min-width": "76px", "background": "#212121ed" }}>
                                                        {this.state.languageList.map((language) => (
                                                            <Dropdown.Item
                                                                key={language}
                                                                eventKey={language}
                                                                onClick={() => {
                                                                    this.setState({ languageChoice: language }, () => {
                                                                        this.setState({ displayedPosts: this.displayedPostsLogic() });
                                                                    });
                                                                }}
                                                            >
                                                                {language}
                                                            </Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>

                                    {/* End of Filters */}

                                    {/* Search Bar and LFG Post Display */}
                                    <div className="between-and-flex-important flex-column ask-indemni-reduce-font-size-mobile ask-a-gamer-explore-flex-column-page-input-mobile desktop-increse-font-1point1">
                                        <h5
                                            className="demo-page-increase-font-section-titles mt-2"
                                            style={{ "width": "100%", "text-decoration": "underline", "margin-bottom": ".5em" }}
                                        >
                                            Search LFG Posts
                                        </h5>
                                        <div
                                            style={{ "width": "100%" }}
                                            className="mb-2 mt-2 d-flex flex-row justify-content-start align-items-center"
                                        >
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-start ask-a-gamer-explore-margin-top-input-mobile hange-demo-input-div-container-align"
                                                style={{ "width": "100%" }}
                                            >
                                                <input
                                                    type="search"
                                                    className="ms-2 game-page-input-mobile demo-search-bar-desktop"
                                                    style={{ "vertical-align": "middle", "outline": "none" }}
                                                    value={this.state.nameIncludeFilter}
                                                    onChange={this.handleSearchChange}
                                                    placeholder="Search relevant words/phrases..."
                                                    data-toggle="tooltip"
                                                    data-placement="top"
                                                    title="You can type here to look for certain words, phrases, etc. within LFG posts."
                                                />
                                                <Button
                                                    style={{ "background-color": "#7e7e7e1c" }}
                                                    className="ms-3 w-30 game-page-reset-search-button-mobile"
                                                    onClick={() => this.setState({
                                                        nameIncludeFilter: "",
                                                        currentPage: 1,
                                                    }, () => {
                                                        this.setState({ displayedPosts: this.displayedPostsLogic() });
                                                    })}
                                                >
                                                    Reset Search
                                                </Button>
                                            </div>

                                        </div>
                                    </div>
                                    <hr className="hr-ask-indemni-title-hr" />
                                    {this.state.lfgPosts.length !== 0 && this.state.anyMatches
                                        ? this.state.displayedPosts.map(({ post, noMatchFlags }) => (
                                            <div key={post.postTime} className="me-2 px-2 ask-a-gamer-explore-margin-padding-right-left-0-mobile">
                                                {this.displayPosts(post, noMatchFlags)}
                                            </div>
                                        ))
                                        : (
                                            <div className="me-2 px-2 ask-a-gamer-explore-margin-padding-right-left-0-mobile">
                                                <div className="p-2 game-page-post-containers-mobile mt-2">
                                                    <div
                                                        style={{ "margin-bottom": "1em", "margin-top": "1em" }}
                                                        className="d-flex flex-column tab-textbox-hover-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile"
                                                    >
                                                        <div className="d-flex justify-content-between align-items-center flex-wrap">
                                                            <div
                                                                className="d-inline-flex flex-row align-items-center flex-wrap"
                                                                style={{ "width": "100%" }}
                                                            >
                                                                <div
                                                                    className="d-flex flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                                                    style={{ "flex-grow": "1" }}
                                                                >
                                                                    <p style={{ "width": "100%", "margin-bottom": "0" }}>
                                                                        <div className="d-flex flex-column flex-wrap justify-content-center align-items-center overflow-auto text-center" >
                                                                            <small
                                                                                style={{ "font-size": "1.5em", "font-weight": "600", "text-align": "center", "text-shadow": "0px -3px 2px #000000" }}
                                                                                className="d-flex flex-column desktop-increase-demo-gamer-profile-text-size"
                                                                            >
                                                                                <span
                                                                                    className="desktop-increase-demo-gamer-profile-text-size"
                                                                                    style={{ "margin-bottom": "1em", "color": "#ff7b68", "font-size": "1.2em", "text-decoration": "underline", "text-decoration-style": "double", "text-decoration-color": "#fdb4b4cf" }}
                                                                                >
                                                                                    What! No Posts!?
                                                                                </span>
                                                                                <span
                                                                                    className="desktop-increase-demo-gamer-profile-text-size"
                                                                                    style={{ "font-size": ".8em", "margin-bottom": "1em" }}
                                                                                >
                                                                                    {this.state.nameIncludeFilter === "" ? `No one is currently posting for ${this.state.game.gameName}, but there are probably others just like you looking for teammates right now!` : "Your search did not match any posts. Search only looks through messages users wrote in their LFG Post. Check your spelling, or search for something else. If you still can't find what you are looking for..."}
                                                                                </span>
                                                                                <span
                                                                                    className="desktop-increase-demo-gamer-profile-text-size"
                                                                                    style={{ "font-size": ".9em", "margin-bottom": ".5em" }}
                                                                                >
                                                                                    {this.state.nameIncludeFilter === "" ? "Be the first to post! Go to" : "Be the first to post for it! Go to"}
                                                                                </span>
                                                                                <span
                                                                                    className="d-flex flex-row align-items-center justify-content-center desktop-increase-demo-gamer-profile-text-size"
                                                                                    style={{ "width": "100%", "font-size": ".9em" }}
                                                                                >
                                                                                    <Button
                                                                                        className="d-flex flex-column mx-2 p-2 fs-5 align-items-center change-padding-tablet font-size-75-tablet width-auto-tablet"
                                                                                        style={{ "background-color": "#1d77ff", "font-weight": "600", "width": "245px" }}
                                                                                        href="/account"
                                                                                    >
                                                                                        My Gamer Profile
                                                                                    </Button>
                                                                                </span>
                                                                            </small>
                                                                        </div>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {totalPages > 0 && (
                                        <div
                                            style={{ "font-size": ".85em" }}
                                            className="center-and-flex-important mb-2"
                                        >
                                            <span
                                                style={{ "font-size": "1.7em", "font-weight": "600", "margin-bottom": ".2em" }}
                                                className="me-2"
                                            >
                                                Page
                                            </span>
                                            <Pagination>
                                                {[...Array(lastPageNumber - firstPageNumber + 1).keys()].map((index) => {
                                                    const pageNumber = firstPageNumber + index;
                                                    return (
                                                        <Pagination.Item
                                                            key={pageNumber}
                                                            active={pageNumber === this.state.currentPage}
                                                            onClick={() => this.handlePageChange(pageNumber)}
                                                            linkStyle={{ "color": "white", "background-color": "#312e2e", "width": "50px", "font-size": "1.1em", "font-weight": "700", "text-align": "center" }}
                                                        >
                                                            {pageNumber}
                                                        </Pagination.Item>
                                                    );
                                                })}
                                            </Pagination>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <Footerfixed></Footerfixed>
                    </div>
                </>
            )

            return out;
        }
    }
}

export default GamePage;
