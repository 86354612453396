// The LFG Post Group Page.
// TODO: Write Comments
import React, { Component } from "react"
import {
    Button,
    Modal,
    Dropdown,
    Stack,
    Pagination,
} from "react-bootstrap"
import {
    doc,
    getDoc,
    updateDoc,
    arrayUnion,
    arrayRemove,
} from "firebase/firestore"

class UniversityHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            postUploadText: "",
            postDisplayText: "",
            editingPostText: "",
            subjectUploadText: "",
            subjectDisplayText: "",
            editingsubjectText: "",
            postTextCooldown: false,
            editingPost: false,
        }

        this.renderFormattedText = this.renderFormattedText.bind(this);
        this.postTextButton = this.postTextButton.bind(this);
        this.deletePostButton = this.deletePostButton.bind(this);
        this.formatDate = this.formatDate.bind(this);
    }

    // Changes the firestore timestamp to a real js object date.
    // Called in displayPosts() to show when/how long ago a post was made.
    formatDate(date) {
        const now = new Date();
        const pastTime = new Date(date.seconds * 1000); // Converts Firestore timestamp to Date object
        const diff = now - pastTime; // Difference in milliseconds
        const diffMinutes = Math.floor(diff / 60000); // Converts to minutes
        const diffHours = Math.floor(diffMinutes / 60); // Converts to hours
        const diffDays = Math.floor(diffHours / 24); // Converts to days
        
        // Display render of date with user's timezone
        const options = { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        const formattedDate = pastTime.toLocaleDateString('default', options);
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
        // Shows how long ago the post was in minutes, hours, or days
        let timeAgo = '';
        if (diffMinutes < 60) {
            timeAgo = `${diffMinutes} min ago`;
        } else if (diffHours < 24) {
            timeAgo = `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
        } else {
            timeAgo = `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
        }
    
        return {
            formattedDate,
            timezone,
            timeAgo
        };
    }

    // Looks for %0A URL encoded line breaks and links.
    // Handles reformatting text to wrap links in <a> tags, and
    // shows the user visual spaces with <br /> if they pressed enter or
    // shift+enter as represented by "\n".
    renderFormattedText = (text) => {
        const linkRegex = /(?:https?|ftp):\/\/[^\s]+(?:\s|$)/g;
        const lines = text.split(/(?:\n|%0A)/);
      
        const elements = [];
      
        lines.forEach((line, lineIndex) => {
            const linkMatches = line.match(linkRegex);
            if (linkMatches) {
                let lastIndex = 0;

                linkMatches.forEach((linkMatch, index) => {
                    const linkStart = line.indexOf(linkMatch, lastIndex);
                    const linkEnd = linkStart + linkMatch.length;

                    // Adds the preceding text as regular text
                    elements.push(<span key={`text-${lineIndex}-${index}`}>{line.substring(lastIndex, linkStart)}</span>);

                    // Wraps the link in an anchor
                    elements.push(
                        <a
                            key={`link-${lineIndex}-${index}`}
                            className="change-link-color-chat"
                            style={{
                                wordWrap: 'break-word',
                                wordBreak: 'break-all',
                                whiteSpace: 'normal',
                            }}
                            href={decodeURIComponent(linkMatch)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                        {decodeURIComponent(linkMatch)}
                        </a>
                    );

                    // Updates the lastIndex to the end of the current link
                    lastIndex = linkEnd;
                });
            
                // Adds the remaining text after the last link
                elements.push(<span key={`text-${lineIndex}-last`}>{line.substring(lastIndex)}</span>);
            } else {
                // If no links, add the entire line as regular text
                elements.push(<span key={`text-${lineIndex}`}>{line}</span>);
            }

            // Add the line break after each line (except the last line)
            if (lineIndex < lines.length - 1) {
                elements.push(<br key={`br-${lineIndex}`} />);
            }
        });
      
        return elements;
    };

    async postTextButton() {
        // Return early if user is spamming.
        if (this.state.cooldown) return;

        // Set the cooldown to true for loading animation.
        this.setState({ cooldown: true, postTextCooldown: true });

         const post = {
            username: this.props.userdata.username,
            userNumber: this.props.userdata.userNumber,
            uid: this.props.user.uid,
            postText: this.state.postUploadText,
            subjectText: this.state.subjectUploadText === "" ? "General Update" : this.state.subjectUploadText,
            datePosted: new Date(),
            dateEdited: "",
            universityName: this.props.userdata.universityName,
            collabName: this.props.collab.collabName,
        };

        // Updates doc with the question object.
        const postDocRef = doc(this.props.fbsFirestore, "UniversityProgram", `${this.props.collab.collabName}`, "UniversityHome", "0");
        await updateDoc(postDocRef, {
            posts: arrayUnion(post),
        });

        // Set a timeout 1 second allowing loading render & db update to complete
        setTimeout(() => {
            this.setState({
                cooldown: false,
                postTextCooldown: false,
                postDisplayText: "",
                postUploadText: "",
                editingPostText: "",
                subjectDisplayText: "",
                subjectUploadText: "",
                editingsubjectText: "",
                editingPost: false,
             });
        }, 1000);
    }

    async deletePostButton(post) {
        // Return early if user is spamming.
        if (this.state.cooldown) return;

        // Set the cooldown to true for loading animation.
        this.setState({ cooldown: true, postTextCooldown: true });

        // Find the post object that matches the post.datePosted
        const postToRemove = this.props.posts.find(member => member.datePosted === post.datePosted);

        if (!postToRemove) {
            console.error("Post not found.");
            this.setState({ cooldown: false, postTextCooldown: false });
            return;
        }

        // Create a reference to the document
        const postDocRef = doc(this.props.fbsFirestore, "UniversityProgram", `${this.props.collab.collabName}`, "UniversityHome", "0");

        // Perform the arrayRemove operation
        await updateDoc(postDocRef, {
            posts: arrayRemove(postToRemove)
        });

        // Set a timeout 1 second allowing loading render & db update to complete
        setTimeout(() => {
            this.setState({
                cooldown: false,
                postTextCooldown: false,
             });
        }, 1000);
    }

    render() {

        return (
            <>
                <Stack
                    gap={2}
                    className="col-12 fluid mx-auto"
                >
                    <div className="me-2 px-2 ask-a-gamer-explore-margin-padding-right-left-0-mobile">
                        <div className="p-2 game-page-post-containers-mobile mt-2">
                            { this.props.userdata.universityAdmin &&
                                <div
                                    style={{ "margin-bottom": "2em", "margin-top": "-1.5em", "font-size":".9em" }}
                                    className="d-flex flex-column py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile"
                                >
                                    { this.state.editingPost
                                      ?
                                        <>
                                            <div
                                                style={{"width":"100%"}}
                                                className="d-flex flex-row align-items-center justify-content-center mb-3"
                                            >
                                                <Button
                                                    style={{"width":"250px", "box-shadow":"#fffzfff82 0px 0px 10px 3px", "font-size":"1.1em", "font-weight":"600", "text-shadow":"black 0px 2px 2px"}}
                                                    onClick={() => this.setState({
                                                        editingPost: false,
                                                    })}
                                                    className="account-profile-cancel"
                                                >
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div className="d-flex flex-row justify-content-between align-items-center game-details-fade-animation">
                                                <h3
                                                    style={{"text-decoration":"underline", "font-weight":"700", "margin-bottom":"1em", "width":"100%", "text-align":"center"}}
                                                >
                                                    New Post
                                                </h3>
                                            </div>
                                            <div
                                                style={{"width":"100%"}}
                                                className="d-flex flex-column align-items-center justify-content-center game-details-fade-animation pb-3"
                                            >
                                                <p
                                                    className="d-flex flex-row"
                                                    style={{"width":"100%"}}
                                                >
                                                    <div
                                                        style={{"width":"100%"}}
                                                        className="d-flex flex-row flex-wrap justify-content-center overflow-auto"
                                                    >
                                                        <textarea
                                                            value={this.state.subjectDisplayText}
                                                            maxLength={1200}
                                                            placeholder="Subject/Title"
                                                            style={{"font-size":"1.3em", "font-weight":"bolder", "color":"#000000", "border-radius":"10px", "height":"2.93em", "width":"75%", "margin-bottom":".25em"}}
                                                            className="desktop-increase-demo-gamer-profile-text-size"
                                                            onChange={(e) => {
                                                                const subjectDisplayText = e.target.value;
                                                                const modifiedText = subjectDisplayText.replace(/\n/g, '%0A'); // Uses %0A for URL-encoded newline
                                                                this.setState({
                                                                    subjectUploadText: modifiedText,
                                                                    editingsubjectText: modifiedText,
                                                                    subjectDisplayText,
                                                                });
                                                            }}
                                                        
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter' || e.shiftKey) {
                                                                    const modifiedText = `${this.state.subjectUploadText}%0A`;
                                                                    this.setState({
                                                                        subjectUploadText: modifiedText,
                                                                        editingsubjectText: modifiedText,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                        <textarea
                                                            value={this.state.postDisplayText}
                                                            maxLength={1200}
                                                            placeholder="Post something you want members to know about (1200 character limit)."
                                                            style={{"font-size":"1.2em", "color":"#000000", "border-radius":"10px", "height":"250px", "width":"100%"}}
                                                            className="desktop-increase-demo-gamer-profile-text-size"
                                                            onChange={(e) => {
                                                                const postDisplayText = e.target.value;
                                                                const modifiedText = postDisplayText.replace(/\n/g, '%0A'); // Uses %0A for URL-encoded newline
                                                                this.setState({
                                                                    postUploadText: modifiedText,
                                                                    editingPostText: modifiedText,
                                                                    postDisplayText,
                                                                });
                                                            }}
                                                        
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'Enter' || e.shiftKey) {
                                                                    const modifiedText = `${this.state.postUploadText}%0A`;
                                                                    this.setState({
                                                                        postUploadText: modifiedText,
                                                                        editingPostText: modifiedText,
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </p>
                                                <div
                                                    style={{"width":"100%"}}
                                                    className="d-flex flex-row flex-grow-1 justify-content-end"
                                                >
                                                    <Button
                                                        disabled={!this.props.userdata.universityAdmin}
                                                        className="mx-2 d-flex text-center"
                                                        style={{"font-weight":"600", "width":"150px", "box-shadow":"0 0 10px 0 #000000", "text-shadow":"0 -2px 2px #000000",}}
                                                        onClick={async () => {
                                                            await this.postTextButton();
                                                        }}
                                                    >
                                                        { this.state.postTextCooldown
                                                          ? <div
                                                                style={{"width":"100%", "text-align":"center"}}
                                                                className="d-flex flex-row align-items-center justify-content-center"
                                                            >
                                                                <div
                                                                    className="vote-circle-inline-loading-animation"
                                                                />
                                                            </div>
                                                          :
                                                            <span style={{"width":"100%", "text-align":"center"}}>
                                                                Post
                                                            </span>
                                                        }
                                                    </Button>
                                                </div>
                                            </div>
                                            <hr
                                                className="ask-a-gamer-answer-spotlight-hr"
                                                style={{"width":"100%"}}
                                            />
                                        </>
                                      :
                                        <div
                                            style={{"width":"100%"}}
                                            className="d-flex flex-row justify-content-center align-items-center game-details-fade-animation"
                                        >
                                            <Button
                                                className="d-flex flex-row mx-1 p-2 mt-4 align-items-center chat-with-gamer-button desktop-increase-width-500"
                                                style={{"width":"100%", "box-shadow":"#fffzfff82 0px 0px 10px 3px", "font-size":"1.3em", "font-weight":"600", "text-shadow":"black 0px 2px 2px"}}
                                                onClick={async () => {
                                                    this.setState({
                                                        editingPost: true,
                                                    });
                                                }}
                                            >
                                                <span style={{"width":"100%", "text-align":"center"}}>
                                                    Create New Post
                                                </span>
                                            </Button>
                                        </div>   
                                    }    
                                </div>
                            }

                            {/* Posts */}
                            { this.props.posts
                              ? this.props.posts.map((post, index) => {
                                    const { formattedDate, timezone, timeAgo } = this.formatDate(post.datePosted);
                                    return (
                                        <>
                                            <div
                                                style={{ "margin-bottom": "1em", "margin-top": "1em" }}
                                                className="d-flex flex-column tab-textbox-hover-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile"
                                                key={index}
                                            >
                                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                                    <div
                                                        className="d-inline-flex flex-row align-items-center flex-wrap"
                                                        style={{ "width": "100%" }}
                                                    >
                                                        <div
                                                            className="d-flex flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                                            style={{ "flex-grow": "1" }}
                                                        >
                                                            <p style={{ "width": "100%", "margin-bottom": "0" }}>
                                                                <div className="d-flex flex-column flex-wrap justify-content-center align-items-center overflow-auto text-center" >
                                                                    <div style={{"width":"100%"}}>
                                                                        <div
                                                                            className="d-flex pe-3 justify-content-between align-items-center"
                                                                        >
                                                                            <div style={{ "width": "100%" }}>
                                                                                <div className="d-flex flex-row">
                                                                                    <span
                                                                                        style={{ "color": "#99caff", "font-size": "1.25em", "text-decoration-style": "double", "text-decoration-color": "#fdb4b4cf", "text-wrap":"balance", "text-shadow":"0 0px 3px black" }}
                                                                                        className="fw-bold me-2 mb-2"
                                                                                    >
                                                                                         { post.subjectText }
                                                                                    </span>
                                                                                </div>
                                                                                <div className="mt-2">
                                                                                    <span
                                                                                        className="remove-margin-mobile"
                                                                                        style={{"font-size":"1.1em", "text-align":"start"}}
                                                                                    >
                                                                                        <div className="ms-3 mb-3">
                                                                                            <span style={{"font-weight":"600"}}>
                                                                                                {this.renderFormattedText(post.postText)}
                                                                                            </span>
                                                                                        </div>
                                                                                    </span>
                                                                                </div>
                                                                                <div className="d-flex flex-row mt-3 align-items-center">
                                                                                    <div className="d-flex align-items-center ask-indemni-mobile-date-display">
                                                                                            <span className="game-details-fade-animation ">
                                                                                              <span style={{"color": "#ffb865", "font-size":".9em"}}>{ post.collabName }</span>
                                                                                            </span>
                                                                                        <span
                                                                                            className="ms-3 ask-indemni-mobile-date-font ask-indemni-mobile-remove-left-margin"
                                                                                            style={{"color":"#d9d9d9f0", "font-size":".9em", "font-weight":"400"}}
                                                                                        >
                                                                                             Posted on: {formattedDate + ` (${timeAgo})`}
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                                { this.props.userdata.universityAdmin && !post.doNotDelete &&
                                                                                    <div
                                                                                        style={{"width":"100%"}}
                                                                                        className="d-flex flex-row flex-grow-1 justify-content-end"
                                                                                    >
                                                                                        <Button
                                                                                            disabled={!this.props.userdata.universityAdmin}
                                                                                            className="d-flex flex-row mx-1 my-2 p-2 align-items-center account-profile-cancel"
                                                                                            style={{"font-weight":"600", "width":"150px", "box-shadow":"0 0 10px 0 #000000", "text-shadow":"0 -2px 2px #000000",}}
                                                                                            onClick={async () => {
                                                                                                await this.deletePostButton(post);
                                                                                            }}
                                                                                        >
                                                                                            { this.state.postTextCooldown
                                                                                              ? <div
                                                                                                    style={{"width":"100%", "text-align":"center"}}
                                                                                                    className="d-flex flex-row align-items-center justify-content-center"
                                                                                                >
                                                                                                    <div
                                                                                                        className="vote-circle-inline-loading-animation"
                                                                                                    />
                                                                                                </div>
                                                                                              :
                                                                                                <span style={{"width":"100%", "text-align":"center"}}>
                                                                                                    Delete Post
                                                                                                </span>
                                                                                            }
                                                                                        </Button>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </>
                                    )
                                })
                              : 
                                null
                            }
                        </div>
                    </div>
                </Stack>
            </>
        );
    }
}

export default UniversityHome;