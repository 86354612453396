import React, { Component } from "react";
import { Button, Col, Row } from "react-bootstrap";

// Dispplays unread & read messages for user, and this listener will be used in Navbar to display when new messages occur.
// ToDo (cont.): make count and conditional css show on Notifications tab itself!
class SocialNotifications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: undefined, 
            userdata: undefined,
            messages: [], // Existing messages
            unreadCount: 0, // Tracks the unread message count
        };

        this.formatDate = this.formatDate.bind(this);
    };

    // Changes the timestamp.now to a real js object and transformed to an
    // actual number and date.
    formatDate(date) {
        const options = { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return date.toLocaleDateString('default', options);
    }

    // ToDo (pre-launch): paginate it to 50 total - use Rylans code in explore to figure this out
    // ... optional - Add a search bar to filter notifcations by user (aka the from field of messages)
    // ToDo (pre-launch): Change the color and font styling here - doesn't look great.
    // Maps messages from all users and conditionally displays based unread vs read messages.
    // * Currently we just turn off and hide messages if a user has any privacy setting turn on for messages 
    // ... within the "Inbox" subcollection. Post-launch we should create logic for the Just Friends messages setting.
    render() {
        const { messages, unreadCount, markMessageAsRead } = this.props; // Uses props passed from NewHomePage to NewHomeTabs to here.
        return (
            <>
                <div
                    className="home-notifications-container-mobile"
                    style={{"margin-left":"2em", "margin-right":".2em"}}
                >
                    { this.props.user 
                      ?
                        <div className="d-flex flex-row flex-grow-1 p-2 m-1 justify-content-between align-items-center flex-wrap">
                            {unreadCount > 0 ? <div style={{"font-size":"1.5em"}}>New Notifications: {unreadCount}</div> : <div style={{"font-size":"1.5em"}}> No new notifications </div> }
                        </div>
                          :
                        <div className="d-flex flex-row flex-grow-1 p-2 m-1 justify-content-between align-items-center flex-wrap">
                            <div style={{"font-size":"1.5em"}}> 
                                Create an account to ask questions and receive notifications.
                            </div>
                            {/* <Button
                                className="notifications-tab-button"
                                style={{"font-size":".8em", "margin-right":"2.3em"}}
                                href="/"
                            >
                                <span>
                                    Create Account
                                </span>
                            </Button> */}
                        </div>
                    }
                        <Row className="fluid">
                            <Col
                                className="pe-3 home-notifications-height-large-screens"
                                style={{"margin-top":"0 !important", "padding-top":"0"}}
                            >
                            {messages === 0 ? (
                                <h5>You currently have no notifications</h5>
                              ) : (
                                <>
                                     {messages.map((message, index) => (
                                        <>
                                        <hr />
                                        <div className="d-flex flex-row flex-grow-1 justify-content-between align-items-center flex-wrap">
                                            {/* Called from props passed from NewHomePage */}
                                            <Button
                                                className={`d-flex flex-row flex-grow-1 align-items-center flex-wrap ${message.read ? 'notifications-tab-button' : 'unread-notifications-tab-button'}`}
                                                key={index}
                                                style={{"padding-right":"0"}}
                                                onClick={() => markMessageAsRead(index)}
                                            > 
                                                <div
                                                    className="d-flex flex-column home-notifications-info-text-mobile"
                                                    style={{"font-weight":"400", "font-size":"1em", "border-right":"1px solid #dee2e6", "padding-right":"1em", "box-shadow":"1px 1px 20px 2px #f5f5f526", 
                                                    "text-align":"start", "width":"20%"}}
                                                >
                                                    { message.read
                                                      ? null
                                                      :
                                                        <span
                                                            className="my-1"
                                                            style={{"text-align":"center", "font-weight":"500", "color":"#f3ed95eb"}}
                                                        >
                                                            *New!
                                                        </span>
                                                    }
                                                    <span
                                                        className="pe-2 ms-1 mt-1 me-2"
                                                        style={{"font-size":".95em"}}
                                                    >
                                                        Date: { message.timeSent && message.timeSent.toDate()
                                                            ? this.formatDate(message.timeSent.toDate())
                                                            : undefined
                                                        }
                                                    </span>
                                                    <span
                                                        className="pe-2 ms-1 me-2 mb-1"
                                                        style={{"font-size":".95em"}}
                                                    > 
                                                        From: <span style={{"color":"#adc9fff7", "font-weight":"450"}}>{message.fromName}</span>
                                                    </span>
                                                </div> 
                                                <div
                                                    className="d-flex flex-column flex-wrap py-2 home-notifications-body-text-mobile"
                                                    style={{"margin-left":"1em", "padding-left":"1em", "padding-right":"1em", "align-items":"flex-start", "width":"75%"}}
                                                >
                                                    <span
                                                        className="ps-2"
                                                        style={{"text-align":"start", "color":"#f3dfc4", "font-size":"1.1em", "text-shadow":"0 -1px 1px #000000f7"}}
                                                    >
                                                        "{message.body}"
                                                    </span>
                                                </div>
                                            </Button>
                                        </div>
                                    </>
                                    ))}
                                    {/* Checks if a user has a privacy setting turned on for messages - hides messages & shows this if they do - passed as props from HomePage
                                    { this.props.hideMessages &&
                                    <>
                                        <hr />
                                        <div className="d-flex flex-row flex-grow-1 p-2 m-1 justify-content-between align-items-center flex-wrap">
                                            <Button
                                                className="d-flex flex-row flex-grow-1 align-items-center flex-wrap notifications-tab-button"
                                                href="/settings"
                                            >
                                                <div
                                                    className="d-flex flex-column flex-wrap"
                                                    style={{"margin-left":"1em", "padding-left":"1em", "padding-right":"1em", "align-items":"flex-start", "width":"100%"}}
                                                >
                                                    <span
                                                        className="ps-2"
                                                        style={{"text-align":"center", "width":"100%", "font-size":"1.25em", "color":"#ff6e6ed4"}}
                                                    >
                                                        Privacy Settings Enabled: Notifications are turned off unless "From Anyone" is selected.
                                                    </span>
                                                </div>
                                            </Button>
                                        </div>
                                    </>
                                    } */}
                                </>
                              )}       
                            </Col>
                        </Row>
                </div>
            </>
        );
    }
}

export default SocialNotifications;
