import React, { Component } from "react"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import UniversityHome from "./UniversityHome";
import UniversityLFG from "./UniversityLFG";
import UniversityMembers from "./UniversityMembers";
import UniversityEvents from "./UniversityEvents";

// Visit Account Tabs
class UniversityTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: undefined,
            userdata: undefined,
        }

        // These are the eventKeys of the tabs for non-new users.
        const tabs = [
            "notredame-home",
            "notredame-members",
            "notredame-lfg",
            "notredame-events",
        ]
        this.state.activeKey = tabs[0]

        // If there is a hash in the URL specifying which tab, go to that tab.
        if (!window.location.hash.empty) {
            const removedHash = window.location.hash.substring(1)
            const index = tabs.findIndex(x => x === removedHash)
            if (index >= 0)
                this.state.activeKey = tabs[index]
        }

        window.addEventListener(
            "hashchange",
            () => {
                const removedHash = window.location.hash.substring(1)
                const index = tabs.findIndex(x => x === removedHash)
                if (index >= 0)
                    this.setState({ activeKey: tabs[index] })
            },
            false,
        );
    }

    render() {
        return (
            <>
                <>
                    <Tabs
                        activeKey={this.state.activeKey}
                        onSelect={(k) => window.location.hash = `#${k}`}
                        transition={false}
                        id="visit-account-tabs"
                        className="mb-2 pb-2"
                        justify
                    >
                        <Tab eventKey="notredame-home" title="Home" tabClassName="tab-color">
                            <UniversityHome
                                fbsAuth={this.props.fbsAuth}
                                fbsFirestore={this.props.fbsFirestore}
                                user={this.props.user}
                                username={this.props.username}
                                userdata={this.props.userdata}
                                gamer={this.props.gamer}
                                university={this.props.university}
                                collab={this.props.collab}
                                posts={this.props.posts}
                            />
                        </Tab>
                        <Tab eventKey="notredame-members" title="Members" tabClassName="tab-color">
                            { this.props.collab && this.props.collab.length !== 0 &&
                                <UniversityMembers
                                    fbsAuth={this.props.fbsAuth}
                                    fbsFirestore={this.props.fbsFirestore}
                                    user={this.props.user}
                                    username={this.props.username}
                                    userdata={this.props.userdata}
                                    gamer={this.props.gamer}
                                    university={this.props.university}
                                    collab={this.props.collab}
                                />
                            }
                        </Tab>
                        <Tab eventKey="notredame-lfg" title="LFG" tabClassName="tab-color">
                            <UniversityLFG
                                fbsAuth={this.props.fbsAuth}
                                fbsFirestore={this.props.fbsFirestore}
                                user={this.props.user}
                                username={this.props.username}
                                userdata={this.props.userdata}
                                gamer={this.props.gamer}
                                university={this.props.university}
                                collab={this.props.collab}
                            />
                        </Tab>
                        <Tab eventKey="notredame-events" title="Events" tabClassName="tab-color">
                            <UniversityEvents
                                fbsAuth={this.props.fbsAuth}
                                fbsFirestore={this.props.fbsFirestore}
                                user={this.props.user}
                                username={this.props.username}
                                userdata={this.props.userdata}
                                gamer={this.props.gamer}
                                university={this.props.university}
                                collab={this.props.collab}
                                posts={this.props.posts}
                            />
                        </Tab>
                    </Tabs>
                </>
            </>
        );
    }
}

export default UniversityTabs;
