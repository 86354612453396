// I started to make the functional version of Gamer Profile here, but soon realized
// it wasn't necessary for the demo, so this is old code, but useful for seeing how we 
// created old functions, accessed the DB, altered state, etc.
// I will be updating this page to be GamerProfilePage and import UI and logic from the
// the Demo in "../components/Demo" to start building everything once HomePage and
// GamePage are completed.

import React, { Component } from "react"
import { onAuthStateChanged } from "firebase/auth";
import {
    logout,
    markMessageAsRead,
    setUpMessageListener,
    setUser
} from "../utils.js"
import {
    doc,
    getDoc,
    query,
    where,
    increment,
    collectionGroup,
    getDocs,
    updateDoc,
    onSnapshot,
    arrayUnion,
    arrayRemove,
} from "firebase/firestore"
import { Pencil, ShieldCheck } from "react-bootstrap-icons";
import Footerfixed from "../components/Footer/Footer"
import { Button, Container, Row, Col, Stack, Image} from "react-bootstrap"
import BasicNavbar from "../components/Navbar/Navbar"
import VisitAccountTabs from "../components/VisitAccount/VisitAccountTabs.js";
import { ref, getDownloadURL } from "firebase/storage"

class VisitAccountPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: undefined,
            userdata: undefined,
            messages: [], // Existing messages
            unreadCount: 0, // Tracks the unread message count
            generatedPfp: "", // Checks if the user is new and hasn't generated their PFP yet - conditional for render
            pfp: "", // use the palceholder png as the default state of pfp if the user hasn't generated their pfp.
            fadeOutAnimation: false, // set in handleTimeDelayOfPfp during onboarding new users.
            gamerIsLive: false, // if true, the gamer is live and can answer questions.
            queue: [], // set in setUpQueueListener in onAuthStateChanged- is an array of currently queued askers.
            queueLength: undefined, // set in setUpQueueListener when "Go Live" button is clicked - is a number which is the current length of the queue array.
            chatStarted: false, // set in AskAGamerAnswerChat when clicking "Start Chat" or "End Chat"
            cooldown: false, // Conditional to set timeout of 1.5s to prevent spamming of messages and allow cloud function to finish.
            games: [], // the array of games set in componentDidMount.
            
            // TODO: these should be moved to VisitAccountPage logic and removed from here later.
            gameName: "", // gameName is fetched and set from the url in componentDidMount if a user clicked on an LFG Post, else its an empty string
            visitingFromLFG: true, // most users will visit profiles from LFG posts, so this is set to true by default
            vistiingFromSocialTab: false, // if a user is visiting a profile from their Social Tab, this is set to true for conditional render purposes.

            urlName: "", // set in URLSearchParams in onAuthStateChanged

            gamerFound: false, // if false, the answerer was not found and a different render is shown.
            gamer: undefined, // set in fetchAnswererData, has all relevant answerer data.
            gameNumber: undefined,

            displayGG: false, // if true, the user/asker has given the answerer/gamer a GG today (upvote).
            displayNoob: false, // if true, the user/asker has given the answerer/gamer a Noob today (downvote).
            alreadyVoted: false, // set to true or false based on if the user already gave a GG or Noob today.

            renderAltDisplay: false,
            isFriend: false,
        }

        this.logout = this.logout.bind(this)
        this.setUser = this.setUser.bind(this)

        this.setUpMessageListener = this.setUpMessageListener.bind(this);
        this.markMessageAsRead = this.markMessageAsRead.bind(this);
        
        this.calculatePercentage = this.calculatePercentage.bind(this);
        this.percentageColor = this.percentageColor.bind(this);
        this.playAudio = this.playAudio.bind(this);
        this.fetchVisitedUserData = this.fetchVisitedUserData.bind(this);

        this.updateGGScore = this.updateGGScore.bind(this);
        this.updateNoobScore = this.updateNoobScore.bind(this);
        this.checkDate = this.checkDate.bind(this);

        this.addUserToContacts = this.addUserToContacts.bind(this);
        this.addOrRemoveAsFriend = this.addOrRemoveAsFriend.bind(this);

        onAuthStateChanged(this.props.fbsAuth, async user => {
            if (user) {
                //console.log(`User ${user.uid} logging in (email: ${user.email})`)
                await this.setUser(user)
                    .then(async () => {
                        // Use URLSearchParams to get the "gamer" parameter from the URL
                        const queryParameters = new URLSearchParams(window.location.search);
                        const gamer = queryParameters.get("gamer");
                        const gameName = queryParameters.get("gameName");
                        const gameNumberString = queryParameters.get("gameNumber");
                        const gameNumber = parseInt(gameNumberString, 10); // Converts the number string to a number

                        // Check for gameName to see if the user came from an LFG post or their Social Page.
                        // Set a conditional for display if depending on if gameName is in the url.
                        if (gameName) {
                            this.setState({
                                visitingFromLFG: true,
                                vistiingFromSocialTab: false,
                                gameNumber: gameNumber
                            })
                        } else this.setState({
                            visitingFromLFG: false,
                            vistiingFromSocialTab: true,
                             gameNumber: gameNumber
                        })

                        // gamer should always exist unless the user entered a url manually, but we
                        // check for it then fetch the data we need for displaying the user they are visting.
                        if (gamer) {
                            this.setState({ urlName: gamer, gameName: gameName }, async () => {
                                // Once the gamer state is set, fetch gamer/answerer data
                                await this.fetchVisitedUserData();

                                // Creates a promise for this.state.gamer to be set in fetchAnswererData().
                                // TODO (later): Tried quite a few things and could never guarantee this.state.gamer
                                // being set besides doing this. Probably a better solution.
                                await new Promise((resolve) => {
                                    const checkGamerInterval = setInterval(() => {
                                        if (this.state.gamer) {
                                            clearInterval(checkGamerInterval);
                                            resolve();
                                        }
                                    }, 100);
                                });

                                // TODO: WIP - not sure what to do with GG and Noobs currently.
                                // Check if the user has given a Noob or GG to the answerer today
                                if (this.state.writableUserdata.ggOrNoobGiven) {
                                    const vote = this.state.writableUserdata.ggOrNoobGiven.find(v => v.answererNumber === this.state.gamer.answererNumber);
                                    if (vote) {
                                        this.checkDate(vote.date);
                                        this.setState(prevState => {
                                            if (prevState.alreadyVoted) {
                                                console.log("Already Voted", prevState.alreadyVoted);
                                                if (vote.noob) {
                                                    console.log("Voted Noob");
                                                    return { displayNoob: true, displayGG: false };
                                                } else if (vote.gg) {
                                                    console.log("Voted GG");
                                                    return { displayGG: true, displayNoob: false };
                                                }
                                            }
                                            return null;
                                        });
                                    }
                                }
                                
                            });
                        } else {
                            console.error("Gamer is null or undefined");
                            this.setState({ gamer: false, renderAltDisplay: true})
                        }
                    })
                // Inbox Snapshot
                await this.setUpMessageListener();
            }
            else {
                this.logout();
            }
        })
    }

    // We first fetch the games data from the "AskAGamer" collection.
    async componentDidMount() {
        // Use URLSearchParams to get the game "gameName" parameter from the URL.
        // The states set are for conditional renders based on if the visiting user
        // ... is coming from an LFG post or their Social Tab.
        const searchParameters = new URLSearchParams(window.location.search);
        const gameName = searchParameters.get("gameName");
        // Determines state basesd on if gameName is present which will only be the
        // the case if the user clicked on a profile in the LFG posts in GamePage.
        if (gameName) {
            this.setState({
                postGameName: gameName,
                visitingFromLFG: true,
                vistiingFromSocialTab: false,
            })
        }
        else this.setState({
            postGameName: "",
            visitingFromLFG: false,
            vistiingFromSocialTab: true,
        })
    }

    componentWillUnmount() {
        if (this.unsubscribeMessageListener) {
            this.unsubscribeMessageListener()
            this.unsubscribeMessageListener = undefined
        };
        if (this.unsubscribeQueueListener) {
            this.unsubscribeQueueListener()
            this.unsubscribeQueueListener = undefined
        };
    }

    setUser = setUser;
    logout = logout;

    markMessageAsRead = markMessageAsRead;
    setUpMessageListener = setUpMessageListener;

    // setUpQueueListener() {
    //     const userDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
    //     const unsubscribe = onSnapshot(userDocRef, (doc) => {
    //         if (doc.exists()) {
    //             const profile = doc.data();
    //             this.setState({
    //                 profile,
    //             })

    //             // // TODO: Make sure on final testing this works under must conditions.
    //             // const checkAndToggleLiveStatus = async () => {
    //             //     if (!this.state.gamerIsLive && answererData.live) {
    //             //         this.setState({ gamerIsLive: true})
    //             //         window.alert("We detected you did not properly log off from being Live by clicking Go Offline in your last session. Please be sure to do so next time as otherwise you will remain online and users will queue to chat with you. If this happens often, we will reach out to discuss this with you. Do note that in the future we will provide more flexibility on this. Disregard this message if it was just a quick internet outage, or a brief amount of time since last going Live. Reach out if you need more clarity on this. Thanks!")
    //             //         // TODO: Check if we still need this doc update. currentAskerName is used quite often
    //             //         // ... as a logic check on the asker/user side and in /AskAGamerAnswerGoLive. So, it
    //             //         // ... may make sense to clear this, but it will check the asker out of chat if we do.
    //             //         // Honestly, we just need to check if not setting this to an empty string causes bugs.
    //             //         // if (answererData.currentAskerName !== "") {
    //             //         //     await updateDoc(queueDocRef, {
    //             //         //         currentAskerName: "",
    //             //         //     });
    //             //         // }
    //             //     }
    //             // }
    //             // checkAndToggleLiveStatus().catch(console.error);

    //             // console.log("Queue Data:", queue, queueLength);
    //         }
    //     });
    //     this.unsubscribeQueueListener = unsubscribe;
    // }

        // Sets state of chat started to true - called onClick of "Start Chat" in AskAGamerAnswerGoLive.
    // Updates the AnswererData doc to make the selected user in AskAGamerAnswerGoLive the
    // current asker in the field currentAskerName.
    // async startChat() {
    //     this.setState({chatStarted: true});
    //     const AnswererDocRef = doc(this.props.fbsFirestore, "AnswererData", `${this.state.readonlyUserdata.userNumber}`);
    //     try {
    //         await updateDoc(AnswererDocRef, {
    //             currentAskerName: this.state.queue[0].askerName
    //         });
    //     } catch (error) {
    //         console.error("Error Updating Current Asker Name:", error);
    //     }
    //     console.log("Current Asker Name", this.state.answererData.currentAskerName)
    // }

    // Used in render for displaying GG percentage, passed to percentageColor function below.
    calculatePercentage(GG, Noob) {
        if (GG + Noob === 0) {
            return null; // If there are no votes, return null
        }
        // Calculates percentage and rounds to a whole number
        const percentage = (GG / (GG + Noob)) * 100;
        return Math.round(percentage);
    }

    // Return a CSS class that sets the color based on the GG percentage.
    percentageColor(GG, Noob) {
        const percentage = this.calculatePercentage(GG, Noob);
        if (percentage === null) {
            return '';
        } else if (percentage > 79) {
            return 'game-percentage-green';
        } else if (percentage >= 50) {
            return 'game-percentage-white';
        } else {
            return 'game-percentage-red';
        }
    }

    // Searches db subcollection "PublicReadonlyPrivateWritable" to find where the username string clicked on outside of VistingAccountPage, 
    // ... matches a username in the db. Then sets param location to said username.
    // Set data accordingly if the user is found, else set gamerFound: false to show conditional.
    // TODO: maybe update to call a snpashot on the visited user's data.
    // TODO: Can't get collectionGroup to work on PublicReadonlyPrivateWritable - fix this to avoid more reads.
    // async fetchVisitedUserData () {
    //     console.log("url name", this.state.urlName)
    //     const q = query(
    //         collectionGroup(this.props.fbsFirestore, "PublicReadonlyPrivateWritable"),
    //         where("username", "==", this.state.urlName)
    //     );
    //     try {
    //         const querySnapshot = await getDocs(q);
    //         if (await !querySnapshot.empty) {
    //             querySnapshot.forEach(async (docs) => {
    //                 const gamer = docs.data();
    //                 const gamerNumber = docs.data().userNumber;
    //                 const gamerIsLFG = docs.data().isLFG;
    //                 const gamerPFP = docs.data().pfp;
    //                 this.setState({
    //                     gamer,
    //                     gamerIsLFG,
    //                     gamerFound: true
    //                 });
                    
    //                 // Check for the profile picture and set it if it exists.
    //                 if (gamerPFP !== "") {
    //                     // Create a reference from a Google Cloud Storage URI
    //                     const gsReference = ref(this.props.fbsStorage, `gs://justtesting-c24bc.appspot.com/pfpOne/${gamerNumber.toString(16)}.png`);
    //                     // `url` is the download URL for 'images/stars.jpg'
    //                     const url = await getDownloadURL(gsReference)
    //                     this.setState({pfp: url});
    //                 }
    //             });
    //         } else {
    //             this.setState({
    //                 gamerFound: false
    //             });
    //             console.error("Gamer not found");
    //         }
    //     } catch (error) {
    //         console.error("Error fetching user profile data:", error);
    //     }
    // }

    async fetchVisitedUserData () {
        const { urlName } = this.state
        const q = query(
            collectionGroup(this.props.fbsFirestore, "PublicReadonlyPrivateWritable"),
            where("username", "==", urlName)
        );
        try {
            const querySnapshot = await getDocs(q);
            if (await !querySnapshot.empty) {
                querySnapshot.forEach((docs) => {
                    // console.log(docs.id, " => ", docs.data());
                    const gamer = docs.data();
                    const gamerNumber = docs.data().userNumber;
                    const gamerIsLFG = docs.data().isLFG;
                    const gamerPFP = docs.data().pfp;
                    this.setState({
                        gamer,
                        gamerIsLFG,
                        gamerFound: true
                    });
                    // const foundUserNumber = docs.data().userNumber;
                    // const targetUserDocRef =  doc(this.props.fbsFirestore, "Users", `${foundUserNumber}`, "Readonly", "0", "PublicReadonlyPrivateWritable", "0");
                    // const targetUserDocSnapshot = await getDoc(targetUserDocRef);
                    // if (targetUserDocSnapshot.exists()) {
                    //     const gamer = targetUserDocSnapshot.data();
                    //     const gamerNumber = gamer.data().userNumber;
                    //     const gamerIsLFG = gamer.data().isLFG;
                    //     const gamerPFP = gamer.data().pfp;
                    //     this.setState({
                    //         gamer,
                    //         gamerIsLFG,
                    //         gamerFound: true
                    //     });
                        // Check for the profile picture and set it if it exists.
                        // if (gamerPFP !== "") {
                        //     // Create a reference from a Google Cloud Storage URI
                        //     const gsReference = ref(this.props.fbsStorage, `gs://justtesting-c24bc.appspot.com/pfpOne/${gamerNumber.toString(16)}.png`);
                        //     // `url` is the download URL for 'images/stars.jpg'
                        //     const url = await getDownloadURL(gsReference)
                        //     this.setState({pfp: url});
                        // }
                    }
            );
            } else {
                this.setState({
                    gamerFound: false
                });
                console.error("Gamer not found");
            }
        } catch (error) {
            console.error("Error fetching user profile data:", error);
        }
    }

    // Checks date/time to see if the user already voted for answerer today
    checkDate(voteDate) {
        const firebaseDate = (firebaseDateValue) => {
            if (firebaseDateValue)
                return firebaseDateValue.toDate();
            return undefined;
        }
        const isNowSameDayAsDate = (givenDate) => {
            const currentDate = new Date();
            return givenDate &&
                (givenDate.getUTCDate() === currentDate.getUTCDate()
                 && givenDate.getUTCMonth() === currentDate.getUTCMonth()
                 && givenDate.getUTCFullYear() === currentDate.getUTCFullYear());
        }
        const dateData = voteDate
        const lastDate = firebaseDate(dateData);
        const isToday = isNowSameDayAsDate(lastDate);
        this.setState({ alreadyVoted: isToday})
    }

    // Updates gg/noob vote in the askers PublicReadonlyPrivateWritable doc,
    // and updates the gg/noob amount for the answerer. Asker can
    // increment GG/Noob once a day per answerer - this is determined by
    // this.state.alreadyVoted which is set in onAuthStateChanged() via the checkDate() function.
    // Currently, a user can only change their vote (gg to noob or noob to gg), they can not
    // fully "un-do" a vote as if they never voted that day.
    async updateGGScore() {
        console.log("Already Voted at start:", this.state.alreadyVoted);

        // If the asker has never voted/given a gg or noob ever then we
        // create the ggOrNoobGiven field array for the first time with
        // the answerer data.
        if (this.state.writableUserdata.ggOrNoobGiven === undefined) {
            const ggOrNoobGivenUpdateField = [{
                answererNumber: this.state.gamer.answererNumber,
                date: new Date(),
                gg: true,
                noob: false,
            }];
            const ggorNoobDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
            await updateDoc(ggorNoobDocRef, {
                ggOrNoobGiven: ggOrNoobGivenUpdateField,
            });

            this.setState(prevState => ({
                writableUserdata: {
                    ...prevState.writableUserdata,
                    ggOrNoobGiven: ggOrNoobGivenUpdateField
                },
                displayGG: true,
                displayNoob: false,
                alreadyVoted: true,
            }));
            return;
        }

        if (this.state.displayGG) {
            // Add logic here later if we want for users to undo their vote
            // without having to vote the opposite.
            return;
        }

        try {
            if (this.state.alreadyVoted) {
                // Do not increment total votes, just reverse the current vote for today
                const ggOrNoobGivenField = this.state.writableUserdata.ggOrNoobGiven
                const updateGG = ggOrNoobGivenField.find(v => v.answererNumber === this.state.gamer.answererNumber);
                if (updateGG) {
                    // Filter and update only the unread messages from the recipientUser
                    const ggOrNoobGivenUpdateField = ggOrNoobGivenField.map((answerer) => {
                        if (answerer.answererNumber === this.state.gamer.answererNumber) {
                            return { ...answerer,
                                gg: true,
                                noob: false,
                            };
                        }
                        return answerer;
                    });
                    const ggOrNoobDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
                    await updateDoc(ggOrNoobDocRef, {
                        ggOrNoobGiven: ggOrNoobGivenUpdateField,
                    });
                    const answererDocRef = doc(this.props.fbsFirestore, "AnswererData", `${this.state.gamer.answererNumber}`);
                    await updateDoc(answererDocRef, {
                        answererGGNumber: increment(1),
                        answererNoobNumber: increment(-1),
                    });

                    this.setState(prevState => ({
                        writableUserdata: {
                            ...prevState.writableUserdata,
                            ggOrNoobGiven: ggOrNoobGivenUpdateField
                        },
                        displayGG: true,
                        displayNoob: false,
                        alreadyVoted: true,
                    }));
                }
            } else {
                const ggOrNoobGivenField = this.state.writableUserdata.ggOrNoobGiven
                const updateGG = ggOrNoobGivenField.find(v => v.answererNumber === this.state.gamer.answererNumber);
                if (updateGG) {
                    // Filter to update only the object for the specific answerer
                    const ggOrNoobGivenUpdateField = ggOrNoobGivenField.map((answerer) => {
                        if (answerer.answererNumber === this.state.gamer.answererNumber) {
                            return { ...answerer,
                                date: new Date(),
                                gg: true,
                                noob: false,
                            };
                        }
                        return answerer;
                    });
                    const ggorNoobDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
                    await updateDoc(ggorNoobDocRef, {
                        ggOrNoobGiven: ggOrNoobGivenUpdateField,
                    });
                    const answererDocRef = doc(this.props.fbsFirestore, "AnswererData", `${this.state.gamer.answererNumber}`);
                    await updateDoc(answererDocRef, {
                        answererGGNumber: increment(1),
                        answererTotal: increment(1),
                    });

                    this.setState(prevState => ({
                        writableUserdata: {
                            ...prevState.writableUserdata,
                            ggOrNoobGiven: ggOrNoobGivenUpdateField
                        },
                        displayGG: true,
                        displayNoob: false,
                        alreadyVoted: true,
                    }));
                } else {
                    const ggOrNoobDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
                    const addAnswerer = {
                        answererNumber: this.state.gamer.answererNumber,
                        date: new Date(),
                        gg: true,
                        noob: false,
                    }
                    await updateDoc (ggOrNoobDocRef, {
                        ggOrNoobGiven: arrayUnion(addAnswerer)
                    });
                    const answererDocRef = doc(this.props.fbsFirestore, "AnswererData", `${this.state.gamer.answererNumber}`);
                    await updateDoc(answererDocRef, {
                        answererGGNumber: increment(1),
                        answererTotal: increment(1),
                    });

                    this.setState(prevState => ({
                        writableUserdata: {
                            ...prevState.writableUserdata,
                            ggOrNoobGiven: addAnswerer
                        },
                        displayGG: true,
                        displayNoob: false,
                        alreadyVoted: true,
                    }));
                }
            }
        } catch (error) {console.log(error)};
    }

    // Updates gg/noob vote in the askers PublicReadonlyPrivateWritable doc,
    // and updates the gg/noob amount for the answerer. Asker can
    // increment GG/Noob once a day per answerer - this is determined by
    // this.state.alreadyVoted which is set in onAuthStateChanged() via the checkDate() function.
    // See comments above updateGGScore for the TODO for this function and updateGGScore.
    async updateNoobScore() {
        console.log("Already Voted at start:", this.state.alreadyVoted);

        // If the asker has never voted/given a gg or noob ever then we
        // create the ggOrNoobGiven field array for the first time with
        // the answerer data.
        if (this.state.writableUserdata.ggOrNoobGiven === undefined) {
            const ggOrNoobGivenUpdateField = [{
                answererNumber: this.state.gamer.answererNumber,
                date: new Date(),
                gg: false,
                noob: true,
            }];
            const ggorNoobDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
            await updateDoc(ggorNoobDocRef, {
                ggOrNoobGiven: ggOrNoobGivenUpdateField,
            });

            this.setState(prevState => ({
                writableUserdata: {
                    ...prevState.writableUserdata,
                    ggOrNoobGiven: ggOrNoobGivenUpdateField
                },
                displayGG: false,
                displayNoob: true,
                alreadyVoted: true,
            }));
            return;
        }

        if (this.state.displayNoob) {
            // Add logic here later if we want for users to undo their vote
            // without having to vote the opposite.
            return;
        }

        try {
            if (this.state.alreadyVoted) {
                // Do not increment total votes, just reverse the current vote for today
                const ggOrNoobGivenField = this.state.writableUserdata.ggOrNoobGiven
                const updateNoob = ggOrNoobGivenField.find(v => v.answererNumber === this.state.gamer.answererNumber);
                if (updateNoob) {
                    // Filter and update only the unread messages from the recipientUser
                    const ggOrNoobGivenUpdateField = ggOrNoobGivenField.map((answerer) => {
                        if (answerer.answererNumber === this.state.gamer.answererNumber) {
                            return { ...answerer,
                                gg: false,
                                noob: true,
                            };
                        }
                        return answerer;
                    });
                    const ggOrNoobDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
                    await updateDoc(ggOrNoobDocRef, {
                        ggOrNoobGiven: ggOrNoobGivenUpdateField,
                    });
                    const answererDocRef = doc(this.props.fbsFirestore, "AnswererData", `${this.state.gamer.answererNumber}`);
                    await updateDoc(answererDocRef, {
                        answererNoobNumber: increment(1),
                        answererGGNumber: increment(-1),
                    });

                    this.setState(prevState => ({
                        writableUserdata: {
                            ...prevState.writableUserdata,
                            ggOrNoobGiven: ggOrNoobGivenUpdateField
                        },
                        displayNoob: true,
                        displayGG: false,
                        alreadyVoted: true,
                    }));
                }
            } else {
                const ggOrNoobGivenField = this.state.writableUserdata.ggOrNoobGiven
                const updateNoob = ggOrNoobGivenField.find(v => v.answererNumber === this.state.gamer.answererNumber);
                if (updateNoob) {
                    // Filter to update only the object for the specific answerer
                    const ggOrNoobGivenUpdateField = ggOrNoobGivenField.map((answerer) => {
                        if (answerer.answererNumber === this.state.gamer.answererNumber) {
                            return { ...answerer,
                                date: new Date(),
                                gg: false,
                                noob: true,
                            };
                        }
                        return answerer;
                    });
                    const ggOrNoobDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
                    await updateDoc(ggOrNoobDocRef, {
                        ggOrNoobGiven: ggOrNoobGivenUpdateField,
                    });
                    const answererDocRef = doc(this.props.fbsFirestore, "AnswererData", `${this.state.gamer.answererNumber}`);
                    await updateDoc(answererDocRef, {
                        answererNoobNumber: increment(1),
                        answererTotal: increment(1),
                    });

                    this.setState(prevState => ({
                        writableUserdata: {
                            ...prevState.writableUserdata,
                            ggOrNoobGiven: ggOrNoobGivenUpdateField
                        },
                        displayNoob: true,
                        displayGG: false,
                        alreadyVoted: true,
                    }));
                } else {
                    const ggOrNoobDocRef = doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
                    const addAnswerer = {
                        answererNumber: this.state.gamer.answererNumber,
                        date: new Date(),
                        gg: false,
                        noob: true,
                    }
                    await updateDoc (ggOrNoobDocRef, {
                        ggOrNoobGiven: arrayUnion(addAnswerer)
                    });
                    const answererDocRef = doc(this.props.fbsFirestore, "AnswererData", `${this.state.gamer.answererNumber}`);
                    await updateDoc(answererDocRef, {
                        answererNoobNumber: increment(1),
                        answererTotal: increment(1),
                    });

                    this.setState(prevState => ({
                        writableUserdata: {
                            ...prevState.writableUserdata,
                            ggOrNoobGiven: addAnswerer
                        },
                        displayNoob: true,
                        displayGG: false,
                        alreadyVoted: true,
                    }));
                }
            }
        } catch (error) {console.log(error)};
    }

    // Adds the user to the visiting user's contacts (if they aren't already added) so they can message them
    // Then sends the user to InboxPage so that they can message them..
    async addUserToContacts() {
        try {
            if(this.state.hiddenWritableUserdata.contacts.includes(this.state.gamer.username)) {
                window.location.href = `/gamerlounge#personal-contacts&username=${this.state.gamer.username}`;
            }
            else {
                const contactsDocRef =  doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0");
                await updateDoc(contactsDocRef, { contacts: arrayUnion(this.state.gamer.username) });
                window.location.href = `/gamerlounge#personal-contacts&username=${this.state.gamer.username}`;
            }
        } catch (error) {
            console.error("Error Navigating to Personal Messages:", error);
        }
    }

    // Adds or removes the user from the visiting user's friends, depending on if they are already friends.
    async addOrRemoveAsFriend() {
        try {
            const friendssDocRef =  doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicReadonlyPrivateWritable", "0");
            // Remove targetd user from visting user friend's if they are already friends
            if(this.state.writableUserdata.friends.includes(this.state.gamer.username)) {
                await updateDoc(friendssDocRef, { friends: arrayRemove(this.state.gamer.username) });
                // Update friends state
                const updatedFriends = this.state.writableUserdata.friends.filter((friend) => friend !== this.state.gamer.username);
                this.setState({
                    writableUserdata: {
                        ...this.state.writableUserdata,
                        friends: updatedFriends
                    },
                    isFriend: false,
                });
            }
            // Else add the targetd user to visting user friend's and contacts.
            else {
                await updateDoc(friendssDocRef, { friends: arrayUnion(this.state.gamer.username) });
                // Update friends state
                const addedFriend = this.state.writableUserdata.friends.concat(this.state.gamer.username);
                this.setState({
                    writableUserdata: {
                        ...this.state.writableUserdata,
                        friends: addedFriend
                    },
                    isFriend: true,
                });
                const contactsDocRef =  doc(this.props.fbsFirestore, "Users", `${this.state.readonlyUserdata.userNumber}`, "PublicHiddenPrivateWritable", "0", "Inbox", "0");
                await updateDoc(contactsDocRef, { contacts: arrayUnion(this.state.gamer.username) });
            }
        } catch (error) {
            console.error("Error Adding or Removing Friend:", error);
        }  
    }

    // Called onCLick of certain actions that plays an mp3 file from the 'Public' folder.
    // Currently set to 30% volume for all files, but can be adjusted to be higher/per file accordingly.
    // Passed down as props to children components as well.
    // TODO (eventually - not a priority): Add a "Adjust Sound Effect Volume" option in Settings,
    // so that users can set audio.volume globally however they'd like.
    playAudio(source) {
        const audio = new Audio(source);
        audio.volume = 0.3;
        audio.play();
    };

    render() {
        const out = [];

        // Navbar
        out.push(
            <BasicNavbar
                user={this.state.user}
                userdata={{...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata}}
                unreadCount={this.state.unreadCount}
                universityName={(this.state.readonlyUserdata && this.state.readonlyUserdata.inTheUniveristyProgram) ? this.state.readonlyUserdata.universityName : "University"}
            />
        )

        // Conditional to only shown if we can't find their profile.
        if (!this.state.gamer && this.state.renderAltDisplay)
            out.push(
                <div
                    className="vh-100 fluid d-flex flex-row flex-grow-1 justify-content-center align-items-center glow visting-home-page-fade-animation"
                    style={{"overflow-y":"hidden"}}
                >
                    <h2
                        className="d-flex flex-column align-items-center justify-content-center"
                        style={{"font-weight":"700", "font-size":"2em", "font-style":"italic", "text-align":"center", "margin-bottom":"7em"}}
                    >
                        <span
                            className="desktop-increase-demo-gamer-profile-text-size"
                            style={{"margin-bottom":"2em"}}
                        >
                            Could not locate gamer. This gamer either does not exist or has their profile set to private.
                        </span>
                        <div className="big-circle-inline-loading-animation-demo big-circle-inline-loading-animation-demo-desktop"/>
                    </h2>
                </div>
            );

        // Conditional when loading the user's profile to make sure data is set.
        if (!this.state.gamer && !this.state.renderAltDisplay)
            out.push(
                <div
                    className="vh-100 fluid d-flex flex-row flex-grow-1 justify-content-center align-items-center glow visting-home-page-fade-animation"
                    style={{"overflow-y":"hidden"}}
                >
                    <h2
                        className="d-flex flex-column align-items-center justify-content-center"
                        style={{"font-weight":"700", "font-size":"2em", "font-style":"italic", "text-align":"center", "margin-bottom":"7em"}}
                    >
                        <span
                            className="desktop-increase-demo-gamer-profile-text-size"
                            style={{"margin-bottom":"2em"}}
                        >
                            Loading Profile
                        </span>
                        <div className="big-circle-inline-loading-animation-demo big-circle-inline-loading-animation-demo-desktop"/>
                    </h2>
                </div>
            );

        if (this.state.gamer)
        // Standard Page Body Render
        out.push(
            <>
                <div className="fluid overflow-hidden home-parent-container-mobile">
                    <>
                        <Container
                            className={this.state.fadeOutAnimation ? "d-flex flex-column fluid m-1 p-5 page-fade-out-animation" : "d-flex flex-column fluid m-1 p-5 page-fade-animation remove-padding-medium-container remove-padding-mobile padding-1ish-tablet"}
                            style={{"padding-bottom":"1em","padding-top":"1em", "overflow-x":"hidden"}}
                        >
                            <Row className="pl-1 justify-content-center fluid home-assets-margin-left-mobile home-parent-container-center-mobile">
                                <Col
                                    className="d-flex fluid flex-column align-items-center shadow col-3 fs-5 fw-bold pb-4 sidebar-width-large home-sidebar-row-mobile"
                                    style={{ "height":"1100px" }}
                                >
                                    <Stack
                                        gap={3}
                                        className="d-flex fluid flex-column align-items-center"
                                        style={{ "justify-content":"flex-start" }}
                                    >
                                        <div className="p-2 mt-2">
                                            <Image
                                                className="shadow home-sidebar-image-mobile page-fade-animation"
                                                src="/ProfilePicturePlaceholder.png"
                                                alt="Profile Picture"
                                                rounded
                                                fluid
                                            />
                                        </div>
                                        { this.state.writableUserdata.friends.includes(this.state.gamer.username) &&
                                            <div
                                                style={{"margin-bottom":"-.75em", "font-size":".8em"}}
                                                className="d-flex flex-column align-items-center font-size-75-tablet"
                                            >
                                                <div
                                                    style={{"color":"green", "font-size":"1.1em"}}
                                                    className="px-2"
                                                >
                                                    My friend 😊
                                                </div>
                                            </div>
                                        }
                                        <div className="d-flex flex-column align-items-center font-size-75-tablet">
                                            <div
                                                style={{"color":"#f3df92", "font-size":"1.1em"}}
                                                className="px-2 pb-4"
                                            >
                                                {this.state.gamer.username}
                                            </div>
                                            { this.state.gamer.inTheUniversityProgram && this.state.readonlyUserdata.inTheUniversityProgram && this.state.gamer.realName && this.state.gamer.realName !== "" &&
                                                <div
                                                    style={{"color":"#ffffff", "font-size":".85em", "font-style":"italic", "font-weight":"500", "margin-top":"-1.25em", "margin-bottom":"1em"}}
                                                    className="px-2 pb-2"
                                                >
                                                    ({this.state.gamer.realName})
                                                </div>
                                            }
                                            { this.state.gamer.inTheUniversityProgram  &&
                                                <div
                                                    style={{"color":"#ffb865", "font-size":".9em", "text-wrap":"balance", "margin-top":"-.5em", "margin-left":".85em"}}
                                                    className="px-2 py-1"
                                                >
                                                    {this.state.gamer.universityName} <ShieldCheck style={{"color":"white", "margin-left":".25em", "margin-bottom":".2em", "margin-right":".5em", "align-items":"center", "height":"15px", "width":"auto"}}/>
                                                </div>
                                            }
                                            <div
                                                style={{"font-size":".9em", "text-shadow":"0 -1px 2px #2c2626", "color":"#c3c2c2"}}
                                                className="px-2"
                                            >
                                                <span>User - # </span>
                                                    <span style={{"font-weight":"800"}}>
                                                        {this.state.gamer.userNumber}
                                                 </span>
                                            </div>
                                            <hr
                                                className="ask-a-gamer-answer-spotlight-hr"
                                                style={{"width":"100%"}}
                                            />
                                            <div
                                                style={{"font-size":".9em"}}
                                                className="p-2"
                                            >
                                                <span>Status: </span>
                                                { !this.state.gamer.status
                                                  ?
                                                    <span style={{"font-style":"oblique"}}>
                                                        Not Set
                                                    </span>
                                                  :
                                                    <span className="animate-ask-a-gamer-status">
                                                        {this.state.gamer.status}
                                                    </span>
                                                }
                                            </div>
                                            <div
                                                className="pb-3"
                                                style={{"font-size":".9em"}}
                                            >
                                                <span>Clan: </span>
                                                { !this.state.gamer.clan
                                                  ?
                                                    <span style={{"font-style":"oblique"}}>
                                                        Not set
                                                    </span>
                                                  :
                                                    <span style={{"font-style":"oblique"}}>
                                                        {this.state.gamer.clan}
                                                    </span>
                                                }
                                            </div>
                                            <div
                                                style={{"margin-top":".75em"}}
                                                className="d-flex flex-row align-items-center justify-content-between p-2 gg-noob-button-column-tablet"
                                            >
                                                <div className="ask-a-gamer-gamepad margin-bottom-1-tablet">
                                                    <div className="gamepad-up center-and-flex-important">
                                                        <Button
                                                            className={this.state.displayGG ? "gamepad-button gamepad-border-up-voted" : "gamepad-button gamepad-border-up"}
                                                            // disabled={this.state.displayGG}
                                                            disabled
                                                            onClick={async () => {
                                                                await this.updateGGScore();
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="gamepad-down center-and-flex-important">
                                                        <Button
                                                            className={this.state.displayNoob ? "gamepad-button gamepad-border-down-voted" :  "gamepad-button gamepad-border-down"}
                                                            // disabled={this.state.displayNoob}
                                                            disabled
                                                            onClick={async () => {
                                                                await this.updateNoobScore();
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="gamepad-left center-and-flex-important">
                                                        <Button
                                                            disabled
                                                            className="gamepad-button gamepad-border-left"
                                                        />
                                                    </div>
                                                    <div className="gamepad-right center-and-flex-important">
                                                        <Button
                                                            disabled
                                                            className="gamepad-button gamepad-border-right"
                                                        />
                                                    </div>
                                                </div>
                                                { this.state.gamer
                                                  ?
                                                    <div className="ms-3 remove-margin-left-tablet">
                                                        <p
                                                            style={{"color":"#5ded5ce8", "margin":"0", "font-size":".9em"}}
                                                            className="mx-2 pb-2 text-center"
                                                        >
                                                            GG's: {this.state.gamer.ggTotal ? this.state.gamer.ggTotal : 0}
                                                        </p>
                                                        { this.state.alreadyVoted
                                                          ?
                                                            <>
                                                                { this.state.displayGG
                                                                  ?
                                                                    <p
                                                                        style={{"color":"#9af199e6", "margin":"0", "font-size":".7em"}}
                                                                        className="gg-or-noob-voted-display"
                                                                    >
                                                                        GG Given <span style={{"font-size":"1.3em"}}>&#128525;</span>
                                                                    </p>
                                                                  :
                                                                    <p
                                                                        style={{"color":"#ffbcbcd9", "margin":"0", "font-size":".7em"}}
                                                                        className="gg-or-noob-voted-display"
                                                                    >
                                                                        Noob Given <span style={{"font-size":"1.3em"}}>&#128546;</span>
                                                                    </p>
                                                                }
                                                            </>
                                                          :
                                                            <p
                                                                style={{"color":"#d9d7d7e8", "margin":"0", "font-size":".7em"}}
                                                                className="ms-2 me-2 pb-2 text-start"
                                                            >
                                                                <span className="display-remove-tablet">&larr;</span> Vote!
                                                                { this.state.gamer.ggTotal && this.state.gamer.ggTotal > 0
                                                                  ?
                                                                    <span className={`${this.percentageColor(this.state.gamer.ggTotal, this.state.gamer.noobTotal)}`}>
                                                                         &nbsp;({this.calculatePercentage(this.state.gamer.ggTotal, this.state.gamer.noobTotal)})%
                                                                    </span>
                                                                  : null
                                                                }
                                                            </p>
                                                        }
                                                        <p
                                                            style={{"color":"#ed5c5ce8", "margin":"0", "font-size":".9em"}}
                                                            className="mx-2 text-center"
                                                        >
                                                            Noob's: {this.state.gamer.noobTotal ? this.state.gamer.noobTotal : 0}
                                                        </p>
                                                    </div>
                                                  : null
                                                }
                                            </div>
                                        </div>
                                        { this.state.visitingFromLFG
                                          ?
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center font-size-75-tablet"
                                                style={{"margin-right":".3em"}}
                                            >
                                                <Button
                                                    className="d-flex flex-column mx-1 p-1 align-items-center chat-with-gamer-button"
                                                    style={{"width":"300px", "box-shadow":"#fffzfff82 0px 0px 10px 3px", "font-size":"1em", "font-weight":"550", "text-shadow":"black 0px 2px 2px", "background-color":"#979797c4", "text-wrap":"balance"}}
                                                    href={`/game?${this.state.gameName}&id=${this.state.gameNumber}`}
                                                >
                                                    Back to LFG
                                                </Button>
                                            </div>
                                          :
                                            <div
                                                className="d-flex flex-row align-items-center justify-content-center font-size-75-tablet"
                                                style={{"margin-right":".3em"}}
                                            >
                                                <Button
                                                    className="d-flex flex-column mx-1 p-1 align-items-center chat-with-gamer-button"
                                                    style={{"width":"300px", "box-shadow":"#fffzfff82 0px 0px 10px 3px", "font-size":"1em", "font-weight":"550", "text-shadow":"black 0px 2px 2px", "background-color":"#979797c4", "text-wrap":"balance"}}
                                                    href={`/gamerlounge#friends`}
                                                >
                                                    Back to Gamer Lounge
                                                </Button>
                                            </div>
                                        }
                                            <div
                                                style={{"font-size":".9em"}}
                                                className="d-flex flex-row align-items-center justify-content-between mt-3"
                                            >
                                                {/* Display different options if user is visiting from LFG or their Social Page */}
                                                { this.state.writableUserdata.friends.includes(this.state.gamer.username)
                                                  ?
                                                    <>
                                                        <div
                                                            style={{"font-size":".8em"}}
                                                            className="d-flex flex-row align-items-center justify-content-between"
                                                        >
                                                            <Button
                                                                className="d-flex flex-column mx-2 p-2 align-items-center change-padding-tablet font-size-75-tablet width-auto-tablet"
                                                                style={{"background-color":"#ff50509e", "width":"145px", "text-wrap":"balance", "font-weight":"550" , "font-size":"1.2em"}}
                                                                disabled={this.state.cooldown}
                                                                onClick={async () => {
                                                                    await this.addOrRemoveAsFriend()
                                                                }}
                                                            >
                                                                Remove Friend
                                                            </Button>
                                                        </div>
                                                        <div
                                                            style={{"font-size":".8em", "margin-left":".5em"}}
                                                            className="d-flex flex-row align-items-center justify-content-center font-size-75-tablet"
                                                        >
                                                            <Button
                                                                className="d-flex flex-column mx-2 p-2 align-items-center change-padding-tablet font-size-75-tablet width-auto-tablet"
                                                                style={{"background-color":"#1616169e", "width":"145px", "text-wrap":"balance", "font-weight":"550", "font-size":"1.2em"}}
                                                                onClick={async () => {
                                                                    await this.addUserToContacts();
                                                                }}
                                                            >
                                                                Send Message
                                                            </Button>
                                                        </div>
                                                    </>
                                                  :
                                                    <>
                                                        <div
                                                            style={{"font-size":".8em"}}
                                                            className="d-flex flex-row align-items-center justify-content-between"
                                                        >
                                                            <Button
                                                                className="d-flex flex-column mx-2 p-2 align-items-center change-padding-tablet font-size-75-tablet width-auto-tablet"
                                                                style={{"background-color":"#1616169e", "width":"145px", "text-wrap":"balance", "font-weight":"550", "font-size":"1.2em"}}
                                                                disabled={this.state.cooldown}
                                                                onClick={async () => {
                                                                    await this.addOrRemoveAsFriend()
                                                                }}
                                                            >
                                                                Add Friend
                                                            </Button>
                                                        </div>
                                                        <div
                                                            style={{"font-size":".8em", "margin-left":".5em"}}
                                                            className="d-flex flex-row align-items-center justify-content-center font-size-75-tablet"
                                                        >
                                                            <Button
                                                                className="d-flex flex-column mx-2 p-2 align-items-center change-padding-tablet font-size-75-tablet width-auto-tablet"
                                                                style={{"background-color":"#1616169e", "width":"145px", "text-wrap":"balance", "font-weight":"550", "font-size":"1.2em"}}
                                                                onClick={async () => {
                                                                    await this.addUserToContacts();
                                                                }}
                                                            >
                                                                Send Message
                                                            </Button>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                    </Stack>
                                </Col>
                                <Col
                                    className="d-flex fluid flex-column col-9 fs-6 fw-bold mb-2 pb-4 pe-3 home-content-width-mobile"
                                    style={{ "padding-left":"2em", "max-width":"1300px" }}
                                >
                                    { this.state.gamer && this.state.gamerFound
                                      ?
                                        <VisitAccountTabs
                                            fbsAuth={this.props.fbsAuth}
                                            fbsFirestore={this.props.fbsFirestore}
                                            fbsStorage={this.props.fbsStorage}
                                            user={this.state.user}
                                            username={this.state.username}
                                            userdata={{...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata}}
                                            startChat={this.startChat}
                                            endChat={this.endChat}
                                            playAudio={this.playAudio}
                                            chatStarted={this.state.chatStarted}
                                            gamerIsLive={this.state.gamerIsLive}
                                            queue={this.state.queue}
                                            queueLength={this.state.queueLength}
                                            updatedQueueLength={this.state.updatedQueueLength}
                                            answererData={this.state.answererData}
                                            messages={this.state.messages}
                                            unreadCount={this.state.unreadCount}
                                            handleTimeDelayOfPfp={this.handleTimeDelayOfPfp}
                                            gamer={this.state.gamer}
                                        />
                                      : null
                                    }
                                </Col>
                            </Row>
                            <Footerfixed></Footerfixed>
                        </Container>
                    </>
                </div>
            </>
        )

        return out;
    }
}

export default VisitAccountPage;
