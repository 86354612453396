import React from "react";
import { Outlet } from "react-router-dom";

import BasicNavbar from "../Navbar/Navbar";

function Layout() {
    return (
        <>
            <div className="min-vh-100">
                <Outlet />
            </div>
        </>
    );
};

export default Layout;
