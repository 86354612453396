import React, { Component } from "react"
import { Button, Alert } from "react-bootstrap"

import { logEvent } from "firebase/analytics"

import BasicNavbar from "../components/Navbar/Navbar";
import Footerfixed from "../components/Footer/Footer";

import {
    createUserWithEmailAndPassword,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    sendEmailVerification,
    applyActionCode,
    deleteUser,
} from "firebase/auth";

import {
    collectionGroup,
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    where
} from "firebase/firestore";


class LandingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: undefined, /* currently logged in user */

            readonlyUserdata: undefined, // Readonly
            writableUserdata: undefined, // PublicReadonlyPrivateWritable
            hiddenWritableUserdata: undefined, // PublicHiddenPrivateWritable

            email: undefined, /* for sign in form, etc */
            password: undefined,
            username: undefined,

            showSignupEmailPasswordModal: false, /* If true, email/password signup modal is shown */
            showLoginEmailPasswordModal: false, /* If true, email/password login modal is shown */
            showEmailInputs: false, /* if true, show inputs for email/password login */
            showSignUpEmailInputs: false, /* if true, show inputs for email/password sign-up */
            showPassword: false, /* if true, users can see the password they typed in*/

            showLoginModal: false, /* If true, show login modal */

            showSignupModal: false, /* If true, show signup modal */
            legalAgree: false, /* If true, activate sign up buttons. */
            emailSubscribe: false, /* If true, the user subscribed to emails and we pass their email into createUser *** Only started this at User 108!. */

            showSignupPage: false, /* If true, show signup page instead of landing page (usually by clicking "sign up") */

            copyButtonText: "Copy", /* The text displayed within a copy button. Needed for "copied" display */


            loading: true,
            creatingUser: false, // Sets 8sec timeout for loading render when newUser is created.
            userCreated: false, // Set to ensure landing page doesn't show up after creatingUser.

            userAuthedButNotCreated: false
        }

        this.loginWithEmailPassword = this.loginWithEmailPassword.bind(this)
        this.signupWithEmailPassword = this.signupWithEmailPassword.bind(this)
        this.onUserLoggedIn = this.onUserLoggedIn.bind(this)
        this.onLoggedIn = this.onLoggedIn.bind(this)
        this.onSignedUp = this.onSignedUp.bind(this)
        this.logout = this.logout.bind(this)
        this.validateUsernameThenSignUpWith = this.validateUsernameThenSignUpWith.bind(this)
        this.userExists = this.userExists.bind(this)
        this.handleShowPassword = this.handleShowPassword.bind(this)

        this.handleHashChange = this.handleHashChange.bind(this);

        this.deleteAuthedButNotCreatedAccount = this.deleteAuthedButNotCreatedAccount.bind(this)

        // Subscribe to authorisation state change; if a user becomes
        // unauthorised suddenly, we want to also log them out here. This
        // also allows for login state to persist through refresh and tab
        // close and open.
        onAuthStateChanged(this.props.fbsAuth, async (user) => {
            if (user) {
                // TODO: Alternatively, this could be inside onUserLoggIn when the error is thrown we can throw state.
                // Will figure this out later.
                const hash = window.location.hash.substring(1);
                if (hash === "sign-up") {
                    this.setState({ showSignupPage: true, loading: false, userAuthedButNotCreated: true });
                }
                
                const userExists = await this.userExists(user.uid)
                if (!userExists) {
                    // TODO: No alert, but set state to show post-signup user creation
                    // (username textbox and submit button, call createUser cloud function
                    // with username and firebase uid).
                    window.alert("You've managed to login without existing; sorry about that")
                    return;
                }
            }

            if (user) {
                //console.log(`User ${user.uid} logging in (email: ${user.email})`)
                await this.onUserLoggedIn(user)
            }
            else this.logout()

            this.setState({ loading: false })
        })
    }

    // Used to find #'s in the url
    componentDidMount() {
        this.handleHashChange();
        window.addEventListener("hashchange", this.handleHashChange, false);
    }

    // Remove # listener
    componentWillUnmount() {
        window.removeEventListener("hashchange", this.handleHashChange, false);
    }

    async deleteAuthedButNotCreatedAccount() {
        await deleteUser(this.props.fbsAuth.currentUser)
        .then(() => {
        window.alert("Account & Data Succesfuly Deleted - Rejoin the Indemni family anytime")
    })
    }

    // Currently used a psuedo "fix" for when users click sign-in with google
    // since it auth's them in our database, and createUser doesn't happen,
    // etc. There are other solutions but this works for now, and can be used
    // to pass in other things to display to users and we can search for specific
    // hashes to change display for CTA's and such.
    // This hash is currently set in setUser in Utils.js when the user has a uid,
    // but no user docs.
    handleHashChange() {
        const hash = window.location.hash.substring(1);
        console.log("Current hash:", hash); // Debug log
        if (hash === "sign-up") {
            this.setState({ showSignupPage: true }, () => {
                console.log("showSignupPage set to true"); // Debug log
            });
        } else {
            this.setState({ showSignupPage: false }, () => {
                console.log("showSignupPage set to false"); // Debug log
            });
        }
    }

    async userExists(userId) {
        if (typeof userId !== "string") return false;
        return true; // TODO! Cloud function maybe?
    }

    /// Returns a promise that resolves when the user and userdata state is set
    /// for this component.
    async onUserLoggedIn(user) {
        return new Promise(async (resolve, reject) => {
            // Hide any UI that the user may have logged in from (or signed up and then logged in from).
            this.setState({
                showSignupPage: false,
                showSignupModal: false,
                showLoginModal: false,
                showSignupEmailPasswordModal: false,
                showLoginEmailPasswordModal: false,
                password: undefined
            })

            const userReadonlyQuery = query(
                collectionGroup(this.props.fbsFirestore, "Readonly"),
                where("uid", "==", user.uid)
            );
            //console.log(userReadonlyQuery)

            let userReadonlySnapshots = []
            try {
                //console.log("Attempting query execution...")
                userReadonlySnapshots = await getDocs(userReadonlyQuery);
                //console.log(userReadonlySnapshots)
                //console.log("Query executed")
            } catch(e) {
                console.error(e)
                resolve(false)
                return;
            }

            if (userReadonlySnapshots.empty) {
                console.error(`Login failed: Could not find user in database with uid == ${user.uid}`)
                resolve(false)
                this.setState({ showSignupPage: true, loading: false, userAuthedButNotCreated: true });
                return;
            }

            const userReadonlySnapshot = userReadonlySnapshots.docs[0]
            //console.log(userReadonlySnapshot)

            // This works but only for the hard-coded user...
            //const userReadonlySnapshot = await getDoc(doc(this.props.fbsFirestore, "Users", "0", "Readonly", "0"))

            if (!await userReadonlySnapshot.exists()) {
                console.error(`Login failed: Could not find user in database with uid == ${user.uid}`)
                resolve(false)
                return;
            }

            const userReadonlyData = userReadonlySnapshot.data()

            const userNumber = userReadonlyData.userNumber
            const userNumberString = `${userNumber}`

            const userWritableSnapshot = await getDoc(doc(this.props.fbsFirestore, "Users", userNumberString, "PublicReadonlyPrivateWritable", "0"));
            const userWritableData = userWritableSnapshot.data();

            const userHiddenSnapshot = await getDoc(doc(this.props.fbsFirestore, "Users", userNumberString, "PublicHiddenPrivateWritable", "0", "Inbox", "0"));
            const userHiddenData = userHiddenSnapshot.data();

            this.setState({
                user: user,                             // Firebase Authentication user object
                readonlyUserdata: userReadonlyData,     // /Users/<userNumber>/Readonly/0
                writableUserdata: userWritableData,     // /Users/<userNumber>/PublicReadonlyPrivateWritable/0
                hiddenWritableUserdata: userHiddenData, // /Users/<userNumber>/PublicHiddenPrivateWritable/0
            }, () => {
                resolve(true)
            })
        })
    }

    async onLoggedIn(userCredential) {
        return this.onUserLoggedIn(userCredential.user)
    }

    logout() {
        /* If not logged in, don't try to log out. */
        if (this.state.user === undefined) return;

        signOut(this.props.fbsAuth)
            .then(() => {
                this.setState({
                    username: undefined,
                    email: undefined,
                    password: undefined,
                    user: undefined,
                    readonlyUserdata: undefined,
                    writableUserdata: undefined,
                    hiddenWritableUserdata: undefined, // not used for now, but set as an example for when we do need it. Will remove once we actually use it somewhere else.
                }, () => {
                    console.log("Logged out!")
                })
            })
            .catch((error) => {
                // TODO: What do we do if signing out fails?
                console.error("Could not sign out!")
                console.error(`FIREBASEAUTH (${error.code}): ${error.message}`)
                console.error(error)
            })
    }

    /// @param userCredential
    ///   Firebase User Credential Object
    /// @param method
    ///   A string. One of "Email/Password" or "Google".
    // TODO: Eventually we will need to add logic to check if an email exists first ...
    // Probably will have to switch to email confirmation at some point for security reasons.
    async onSignedUp(userCredential, method) {
        // Render Create User Loading Screen
        this.setState({ creatingUser: true });
        // Analytics sign up event
        if (typeof method === "string" && (method === "Email/Password" || method === "Google" ))
            logEvent(this.props.fbsAnalytics, "sign_up", { method: method })

        // Trim any whitespace and check if email ends with .edu to determine if the user is a university student.
        const trimmedEmail = this.state.email.trim();
        const isUniversityEmail = trimmedEmail.endsWith(".edu");

        console.log("Trimmed email:", trimmedEmail);

        let universityName = "";
        let inUniversityCollab = false;
        let collabName = "";

        // Search the UniversityProgram Collection to find the university that matches the email domain.
        // TODO: update logic, since we will have users who sign up with their college email before they are
        // in the universityProgram ... we would want to account for this, but it's fine for now.
        if (isUniversityEmail) {
            // Extract domain from email
            const domain = "@" + trimmedEmail.split('@')[1];

            // Get the UniversityProgram collection
            const universityProgramsCollection = collection(this.props.fbsFirestore, "UniversityProgram");
            const querySnapshot = await getDocs(universityProgramsCollection);

            // Check each document to find a matching universityDomainOne
            querySnapshot.forEach((doc) => {
                const data = doc.data();
                if (data.universityDomainOne === domain) {
                    universityName = data.universityName;
                    inUniversityCollab = data.inUniversityCollab;
                    collabName = data.collabName;
                }
            });
        }

        // Determine if the user is in the university program based on the universityName
        const inTheUniversityProgram = universityName !== "";

        const response = await fetch("https://createuser-cuw3q2hq5q-uc.a.run.app", {
            method: "post",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
            },

            // Serialize request body as JSON, as specified by Content-Type header.
            body: JSON.stringify({
                username: this.state.username,
                uid: userCredential.user.uid,
                subscribedToEmail: this.state.emailSubscribe ? true : false,
                email: trimmedEmail,
                isUniversityStudent: isUniversityEmail,
                inTheUniveristyProgram: inTheUniversityProgram,
                universityName: universityName,
                inUniversityCollab: inUniversityCollab,
                collabName:  collabName,
            })
        })
        // Failed to send message: show user so hopefully they can show us.
        // Send an email verification link to the user - this is just quality of life currently, so they know they used
        // ... the correct email & it feels more offical, but can be used (or email link verificaiton first) for making sure
        // ... user's verify their email before entering the site.
        // Set 8 second timeout to conditionally render a loading screen to help with better onboarding UX.
        if (response.ok) {
            console.log("Account Created Succesfuly!: Loading");
            try {
                await new Promise((resolve) => {
                    setTimeout(resolve, 8000);
                });
                this.setState({ creatingUser: false });

                console.log("Loading New User Finsihed")
            } catch (error) {
                console.error(error);
            } finally {
                // After 7 seconds or in case of an error, set creatingUser to false
                this.setState({ creatingUser: false, userCreated: true });
                await sendEmailVerification(this.props.fbsAuth.currentUser);
                console.log("Sent Email Verification")
            }

        }

        else {
            window.alert(`Error ${response.status}: ${response.statusText}`);
            this.setState({ creatingUser: false })
        }

        // API:

        // POST | sendMessage(to, body) w/ custom "Authorization" header in form of "ID <firebaseidtoken>"
        //     Pushes a new message object into the messages array of
        //     `Users/${to}/PublicHiddenPrivateWritable/Inbox0/0` document,
        //     as well as `Users/${from}/PublicHiddenPrivateWritable/Inbox0/0`.
        //
        //     NOTE: `from` is fetched from database by querying for a user with the
        //     given uid that in turn is derived from the firebase ID token gathered
        //     from the custom "Authorization" header. This way, authentication is
        //     required to send a message from a user, but a user may only send
        //     messages from themselves (no caller ID spoofing).

        return this.onLoggedIn(userCredential)
    }

    // NOTE: "To protect your project from abuse, Firebase limits the
    // number of new email/password and anonymous sign-ups that your
    // application can have from the same IP address in a short period of
    // time." Luckily, they handle the misuse of this "create user" API
    // themselves.
    signupWithEmailPassword(e) {
        if (e) e.preventDefault()
        if (typeof this.state.email !== "string" || this.state.email.length <= 0) {
            window.alert("Invalid email")
            return;
        }
        if (typeof this.state.password !== "string" || this.state.password.length <= 0) {
            window.alert("Invalid password")
            return;
        }
        // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#createuserwithemailandpassword
        // createUserWithEmailAndPassword(this.props.fbsAuth, this.state.email, this.state.password)
        //     .then((userCredential) => this.onSignedUp(userCredential, "Email/Password"))
        createUserWithEmailAndPassword(this.props.fbsAuth, this.state.email, this.state.password)
        .then((userCredential) => {
            // Ensure the email state is set before calling onSignedUp
            this.setState({ email: this.state.email }, () => {
                this.onSignedUp(userCredential, "Email/Password").then(() => {
                    // Clear the form text boxes after onSignedUp completes
                    this.setState({ email: "", password: "" });
                });
            });
        })
            .catch(error => {
                this.setState({ user: undefined }, () => {
                    console.error(`FIREBASEAUTH (${error.code}): ${error.message}`)
                    console.error(error)
                    // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#error-codes_3
                    if (error.code === "auth/email-already-in-use")
                        window.alert("Email already in use; try logging in instead.")
                    else if (error.code === "auth/invalid-email")
                        window.alert("Email address is invalid; ensure you have typed it correctly.")
                    else if (error.code === "auth/operation-not-allowed")
                        window.alert("Something went wrong with the Firebase Authentication project settings.")
                    else if (error.code === "auth/weak-password")
                        window.alert("Your password was not strong enough to generate a secure hash from. Please choose a stronger password.")
                })
            })
    }

    // Added window.location.href here (and for social logins) to direct users to /askindemni after signing-in.
    loginWithEmailPassword(e) {
        e.preventDefault()
        // Validate email, password (somewhat)
        if (typeof this.state.email !== "string") return;
        if (typeof this.state.password !== "string") return;
        if (this.state.email.length === 0) return;
        if (this.state.password.length === 0) return;
        // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#signinwithemailandpassword
        signInWithEmailAndPassword(this.props.fbsAuth, this.state.email, this.state.password)
            .then(userCredential => {
                logEvent(this.props.fbsAnalytics, "login", { method: "Email/Password" });

                // After Login, navigate to HomePage
                window.location.href = '/account';
            })
            .catch(error => {
                this.setState({ user: undefined }, () => {
                    console.error(`FIREBASEAUTH (${error.code}): ${error.message}`)
                    console.error(error)
                    // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#error-codes_12
                    if (error.code === "auth/invalid-email")
                        window.alert("Email invalid; ensure you have typed it correctly.")
                    else if (error.code === "auth/user-disabled")
                        window.alert("Sorry, but your account has been disabled. Contact support if you feel like this is an error.")
                    else if (error.code === "auth/user-not-found")
                        window.alert("No account was found with this email address; try signing up, instead.")
                    else if (error.code === "auth/wrong-password")
                        window.alert("Invalid password; please enter the correct password then try again.")
                })
                // TODO: What is a sufficient way to show the user this message?
            });
        // Empty the text boxes!
        this.setState({ email: undefined, password: undefined })
    }

    // Crude validation of username followed by actually signing up.
    // Call with "Email/Password", "Google".
    // Usernames are currently capped at 20 characters maximum.
    // FIXME: To reduce possible error, it's probably best to make these strings static getters
    // TODO: Check if 20 character limit can be extended - limits are in place currently because of css wrapping text.
    // ... Also, check for bugs in signup/signin when an email, username, or password is incorrect the first time. 
    // validateUsernameThenSignUpWith(signInWithThis) {
    //     if (typeof this.state.username !== "string" || this.state.username.length === 0 || this.state.username.length > 20) {
    //         window.alert("Invalid username; This username either exceeds the 20 character limit or is already taken by another user.")
    //         return;
    //     }
    //     if (signInWithThis === "Email/Password") {
    //         //this.setState({ showSignupEmailPasswordModal: true })
    //         this.signupWithEmailPassword(undefined)
    //     } else if (signInWithThis === "Google") {
    //         // Authenticate user with popup asking for google login
    //         signInWithPopup(this.props.fbsAuth, this.props.fbsAuthGoogle)
    //             .then((userCredential) => this.onSignedUp(userCredential, "Google"))
    //             .catch((error) => {
    //                 /* TODO: Show user that the login went wrong, somehow */
    //                 console.error(`FIREBASEAUTH (${error.code}): ${error.message}`)
    //             })
    //     } 
    //     else {
    //         window.alert("Unrecognised sign up method; if you are not a developer, please report this occurence")
    //         console.error("Unrecognised sign up method")
    //     }
    // }

    validateUsernameThenSignUpWith(signInWithThis) {
        if (typeof this.state.username !== "string" || this.state.username.length === 0 || this.state.username.length > 20) {
            window.alert("Invalid username; This username either exceeds the 20 character limit or is already taken by another user.");
            return;
        }
        if (signInWithThis === "Email/Password") {
            this.signupWithEmailPassword(undefined);
        } else if (signInWithThis === "Google") {
            signInWithPopup(this.props.fbsAuth, this.props.fbsAuthGoogle)
                .then((userCredential) => {
                    // Ensure the email state is set before calling onSignedUp
                    this.setState({ email: userCredential.user.email }, () => {
                        this.onSignedUp(userCredential, "Google");
                    });
                })
                .catch((error) => {
                    console.error(`FIREBASEAUTH (${error.code}): ${error.message}`);
                });
        } else {
            window.alert("Unrecognised sign up method; if you are not a developer, please report this occurrence.");
            console.error("Unrecognised sign up method");
        }
    }

    //     // Google login with user existence check
    //     loginWithGoogle() {
    //         signInWithPopup(this.props.fbsAuth, this.props.fbsAuthGoogle)
    //             .then(async (userCredential) => {
    //                 const userExists = await this.userExists(userCredential.user.uid);
    //                 if (userExists) {
    //                     await this.onLoggedIn(userCredential.user);
    //                     // After Login, navigate to HomePage
    //                     window.location.href = '/home';
    //                 } else {
    //                     await this.deleteUserAuth(userCredential.user);
    //                     window.alert("User does not exist. Please sign up first.");
    //                 }
    //             })
    //             .catch((error) => {
    //                 console.error(`FIREBASEAUTH (${error.code}): ${error.message}`);
    //                 window.alert(`Login failed: ${error.message}`);
    //             });
    //     }

    //         // Delete user authentication entry
    // async deleteUserAuth(user) {
    //     try {
    //         await deleteUser(user);
    //         console.log("User auth entry deleted");
    //     } catch (error) {
    //         console.error("Error deleting user auth entry: ", error);
    //     }
    // }

    //     // Check if user exists in the database by UID
    //     async userExists(uid) {
    //         const usersCollection = collection(this.props.fbsFirestore, "Users");
    //         const q = query(usersCollection, where("uid", "==", uid));
        
    //         try {
    //             const querySnapshot = await getDocs(q);
    //             return !querySnapshot.empty;
    //         } catch (error) {
    //             console.error("Error checking user existence: ", error);
    //             return false;
    //         }
    //     }

    // Shows or hides passowrd input
    handleShowPassword () {
        if (this.state.showPassword) {
            this.setState({showPassword: false})
        }
        else {
            this.setState({showPassword: true})
        }
    }

    // ToDo (optional): Possibly remove this.state.userCreated throughot LandingPage if a better option is avaiable - I couldn't figure out a better way.
    // ToDo (optional): Possibly add stricter password rules, and change email sign-up to require clicking verification link first.
    // ToDo (post-launch / later): Not a high priority, but we should implement the option for 2FA / multifactor auth - We probably would need another
    // ... provider to do this though.
    render() {
        return (
            <>
                {/* Page shown after user creation (user: defined, userCreated: true & creatingUser: false), or if a user currently loggedin and navigates to href="/"
                    ... ternary, state, and boolean of userCreated needed to prevent showing landing page after creatingUser.*/}
                { this.state.userCreated || this.state.user !== undefined
                  ? <>
                        <div
                            className="align-items-center justify-content-center text-center vh-50"
                            style={{"margin-top":"4em"}}
                        >
                            <h1 className="mt-1 fst-italic" >
                                Welcome to Indemni!
                            </h1>
                            <h2>
                                <Button
                                    className="game-details-information landing-signed-up-button"
                                    href="/account"
                                >
                                    Begin
                                </Button>
                            </h2>
                        </div>
                        <div
                            className="text-center"
                            style={{"margin-top":"2em"}}
                        >
                            <div style={{ "display": "inline-block", "position": "relative" }}>
                                <img
                                    src="/logo-no-background.png"
                                    style={{ "max-width": "min(300px, 100vw)" }}
                                    alt="Large Indemni Logo"
                                />
                            </div>
                            <br />
                            <h4 className="mt-1 p-3 fst-italic" >
                                This Is Your Stage - Showcase Yourself and Play How You Want
                            </h4>
                        </div>
                    </>
                  : null
                }

                {/* Conditional Loading Screen for 7 seconds while user is being auth'ed/created in Firebase  */}
                {this.state.creatingUser && !this.state.userCreated
                 ? <>
                       <div
                           className="align-items-center justify-content-center text-center vh-50 game-details-play"
                           style={{"margin-top":"10em", "font-weight":"700em", "padding-bottom":"1.5em", "margin-left":"10em", "margin-right":"10em" }}
                       >
                           <h1 className="mt-1 fst-italic" >
                               Creating User
                               <div className="lds-ellipsis ps-2"><div></div><div></div><div></div><div></div></div>
                           </h1>
                       </div>
                       <div
                           className="text-center"
                           style={{"margin-top":"8em"}}
                       >
                           <div style={{ "display": "inline-block", "position": "relative" }}>
                               <img src="/logo-no-background.png" style={{ "max-width": "min(300px, 100vw)" }} alt="Large Indemni Logo" />
                           </div>
                           <br />
                           <h4 className="mt-1 fst-italic" >
                               Get Ready Gamers
                           </h4>
                           {/* Shows alert message telling user that they were sent an email verification.
                               ToDo: Just make this a centered div instead of the massive em margins.*/}
                           <Alert
                               variant="dark"
                               style={{"margin-top":"3em", "margin-left":"20em","margin-right":"20em", "font-weight":"700"}}
                               dismissible>
                               <p>
                                   Email Verification Sent! Check your registered email to confirm your account!
                               </p>
                               <hr />
                               <p className="mb-0" style={{"font-style":"italic"}}>
                                   * Be sure to check your spam folder, or wait a minute, if you cant find the email.
                               </p>
                           </Alert>
                       </div>
                   </>
                 :
                 // This is the main Landing Page Render
                 this.state.loading ? <><BasicNavbar user={this.state.user} userdata={{...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata}}/></> :
                 this.state.user === undefined && !this.state.userCreated // unauthenticated
                 ? <>
                       <BasicNavbar
                           user={this.state.user}
                           userdata={{...this.state.readonlyUserdata, ...this.state.writableUserdata, ...this.state.hiddenWritableUserdata}}
                           universityName={("University")}
                           rightSide={
                               this.state.showSignupPage
                                   ? <div
                                         className="d-flex justify-content-between"
                                         style={{"width":"100%"}}>
                                         <span
                                             className="px-2 ms-3"
                                             style={{"font-style":"italic", "margin-left":"5em", "margin-right":"2em", "margin-top":".5em", "color":"#f8f8f8"}}
                                         >
                                             "Gaming is Better With Friends"
                                         </span>
                                         <Button
                                             variant="outline-light"
                                             href="/"
                                             className="me-3"
                                         >
                                             Back To Login
                                         </Button>
                                     </div>
                                   : <div className="d-flex justify-content-between remove-for-mobile">
                                         <Button
                                             variant="outline-light"
                                             className="fw-bold"
                                             onClick={() => this.setState({ showSignupPage: true })}
                                         >
                                             Sign Up
                                         </Button>
                                     </div>
                           }
                       />
                       <div
                           className="mx-3"
                           style={{"overflow-x":"hidden", "padding-bottom":"9em"}}
                       >
                           {/* Flex box for two column layout; login on left, indemni logo on the right */}
                           <div className="d-flex align-items-center justify-content-around flex-wrap" style={{ "min-height": "80vh" }} >

                               {/* LEFT SIDE --- LOG IN, SIGN UP */}
                               { this.state.showSignupPage
                                 ? 
                                   <div className="w-100 text-center" style={{ "max-width": "700px" }} >
                                        <div
                                            style={{"margin-bottom":"3em"}}
                                            className="d-grid gap-2"
                                        >
                                            <h4
                                                className="p-1 mb-2 d-flex flex-column text-center change-waitlist-description-style-desktop"
                                                style={{"width":"360px"}}
                                            >
                                                <span
                                                    style={{"color":"#f9ff7e", "font-weight":"700","font-size":"1.4em"}}
                                                    className="mb-2 mt-3"
                                                >
                                                    The LFG Social
                                                    <br/>
                                                    Gaming Platform
                                                </span>
                                            </h4>
                                            {/* TODO: Finish the logic here for if a user does not want to create an account, we can delete their UID. */}
                                            { this.state. userAuthedButNotCreated &&
                                                <span
                                                    className="pb-2"
                                                    style={{"margin-top":"-1em", "font-weight":"550", "font-size":"1.1em", "color":"#ff8383", "margin-bottom":".5em"}}
                                                >
                                                    It seems you hit "Sign in", before signing up first! Please set your username and finish creating your account. Thanks!
                                                </span>
                                            }
                                            <span style={{"font-size":".95em", "font-weight":"550", "font-style":"oblique", "margin-top":"-1em"}}>
                                                * Accounts are required to post, join groups, and use social features. This takes ~ 15 seconds.
                                            </span>
                                            <hr/>
                                           <h1
                                               className="mb-2 pb-2"
                                               style={{"margin-top":".25em", "color":"#f9ff7e"}}
                                           >
                                               Create Your Indemni Account
                                           </h1>
                                           <div className="d-flex align-items-center justify-content-center w-100">
                                               <div className="chevron"></div>
                                               <div className="chevron"></div>
                                               <div className="chevron"></div>
                                           </div>

                                           {/* Directions for sign-up */}
                                           <h4 style={{"font-weight":"700em", "margin-top":"7em"}}>
                                               - First Choose a Display Name
                                               <br/>
                                               - Next Read & Accept Terms
                                               <br/>
                                               - Then Choose Your Login Preference
                                           </h4>
                                           <hr/>

                                           {/* USERNAME input */}
                                           <div className="form-group mb-4" >
                                               <input type="text" className="form-control"
                                                      value={this.state.username}
                                                      onChange={(x) => this.setState({ username: x.target.value })}
                                                      placeholder="Indemni Display Name (20 characters or less)"
                                               />
                                           </div>

                                           {/* Terms & Conditions */}
                                            <div className={ this.state.legalAgree ? "form-check mb-3 display-lightGreen-color" : "form-check mb-3 display-lightRed-color"}>
                                               <input
                                                   type="checkbox"
                                                   id="legalAgree"
                                                   className="form-check-input p-2"
                                                   onChange={(x) => this.setState({ legalAgree: x.target.checked })}
                                               />
                                               <label
                                                   style={{"white-space": "pre-wrap", "margin-right":"1em", "margin-left":".25em"}}
                                                   for="legalAgree"
                                               >
                                                   I have read and accept the <a target="_blank" href="/IndemniInc.TermsofUse.docx.pdf" >Terms of Service</a>, the <a target="_blank" href="/IndemniInc.TermsofUse.docx.pdf" >Terms of Creator</a>,
                                                   and confirm that I am at least 13 years old. <span style={{"font-style":"italic"}}> {"(After reading, select the box to continue)"}</span>
                                               </label>
                                           </div>

                                           {/* Email subscribe */}
                                           <div className="form-check mb-3">
                                               <input
                                                   type="checkbox"
                                                   id="emailSubscribe"
                                                   className="form-check-input p-2"
                                                   onChange={(x) => this.setState({ emailSubscribe: x.target.checked })}
                                               />
                                               <label
                                                   style={{"white-space": "pre-wrap", "margin-right":"1em", "margin-left":".25em"}}
                                                   for="emailSubscribe"
                                               >
                                                   I would like to receive emails from Indemni about platform updates,
                                                   account information, and giveaway events.<span style={{"font-style":"italic"}}> {"(Optional)"}</span>
                                               </label>
                                           </div>

                                           {/* Conditional showing of email/password inputs if chosen */}
                                           { !this.state.showSignUpEmailInputs && (
                                               <Button
                                                   className="w-100"
                                                   disabled={!this.state.legalAgree}
                                                   onClick={() => {
                                                       this.setState({ showSignUpEmailInputs: true })
                                                   }}
                                               >
                                                   Create Account With Email & Password
                                               </Button>
                                           )}
                                           { this.state.showSignUpEmailInputs && (
                                               <>
                                                   {/* EMAIL input */}
                                                   <div
                                                       className="form-group mb-2"
                                                       style={{"width":"100%"}}
                                                   >
                                                       <input
                                                           type="email"
                                                           style={{"width":"100%"}}
                                                           className="form-control"
                                                           value={this.state.email}
                                                           onChange={(x) => this.setState({ email: x.target.value })}
                                                           placeholder="Email"
                                                       />
                                                   </div>
                                                   {/* PASSWORD input */}
                                                   <div
                                                       className="form-group mb-4 d-flex justify-content-between align-items-center"
                                                       style={{"width":"100%"}}
                                                   >
                                                       <input
                                                           type={this.state.showPassword ? "text" : "password"}
                                                           style={{"width":"80%"}}
                                                           className="form-control"
                                                           value={this.state.password}
                                                           onChange={(x) => this.setState({ password: x.target.value })}
                                                           placeholder="Password (Must be atleast 6 characters)"
                                                       />
                                                       <Button
                                                           className="game-details-information"
                                                           style={{"margin-left":".5em", "margin-right":".5em", "width":"15%", "padding":".5em"}}
                                                           onClick={this.handleShowPassword}
                                                       >
                                                           <span>
                                                               Show &#x1F441;
                                                           </span>
                                                       </Button>
                                                   </div>
                                                   <Button
                                                       className="w-100"
                                                       onClick={() => this.validateUsernameThenSignUpWith("Email/Password")}
                                                       disabled={!this.state.legalAgree}
                                                   >
                                                       Create Account With This Email & Password
                                                   </Button>
                                               </>
                                           )}
                                           {/* SOCIAL sign-up options */}
                                           <Button
                                               className="w-100"
                                               onClick={() => this.validateUsernameThenSignUpWith("Google")}
                                               disabled={!this.state.legalAgree}
                                           >
                                               Create Account With Google
                                           </Button>
                                           { this.state. userAuthedButNotCreated &&
                                                <Button
                                                    style={{"margin-top":"2em", "margin-bottom":"1em"}}
                                                    className="w-100 account-profile-cancel"
                                                    onClick={async () => {
                                                        await this.deleteAuthedButNotCreatedAccount()
                                                    }}
                                                >
                                                    Nevermind - I no longer want to sign up
                                                </Button>
                                            }
                                       </div>
                                   </div>
                                 : 
                                    <div className="w-100 text-center" style={{ "max-width": "640px" }} >
                                       {/* TAGLINE */}
                                       <h1
                                           className="mb-2 pb-2 landing-page-title-font-size-change"
                                           style={{"margin-top":"1em"}}
                                       >
                                           Connect and Play
                                           <br />
                                           With Gamers Like You
                                       </h1>
                                       <h5
                                           className="landing-page-title-font-size-change"
                                           style={{"margin-top":".5em", "font-style":"italic"}}
                                       >
                                           Early Access Now Live!
                                       </h5>
                                        <div className="d-flex flex-row align-items-center justify-content-center p-2">
                                            <ul style={{"font-weight":"500"}}>
                                                <li style={{"color":"#b9e9ff", "width":"350px", "text-align":"start"}}>
                                                    Two Extensive Matchmaking Systems.
                                                </li>
                                                <li style={{"color":"#b9e9ff", "width":"350px", "text-align":"start"}}>
                                                    All the top games for every modern platform.
                                                </li>
                                                <li style={{"color":"#b9e9ff", "width":"350px", "text-align":"start"}}>
                                                    Custom Gaming Profiles & GG voting.
                                                </li>
                                                <li style={{"color":"#b9e9ff", "width":"350px", "text-align":"start"}}>
                                                    Full messaging, Group chat, and Notifications.
                                                </li>
                                                <li style={{"color":"#b9e9ff", "width":"350px", "text-align":"start"}}>
                                                    VC, Events, and more on our  <a href="https://discord.gg/9EZmV5qBY5" target="_blank" className="change-link-color-chat">Discord</a> too!
                                                </li>
                                            </ul>
                                        </div>
                                       <div className="d-flex align-items-center justify-content-center w-100">
                                           <div className="chevron"></div>
                                           <div className="chevron"></div>
                                           <div className="chevron"></div>
                                       </div>

                                       {/* SOCIAL LOGINS */}
                                       {/* After sign in ... directs user to home via href */}
                                        <div className="d-flex justify-content-between"
                                            style={{"margin-bottom":"3em", "margin-top": "11em"}}>
                                            {/* Sign Up Button (alter page state to render "sign up" input page) */}
                                            <Button
                                                variant="outline-light"
                                                className="w-100 mx-1 fw-bold sign-up-homepage"
                                                style={{"font-size":"2.25em"}}
                                                onClick={() => {
                                                    // Alter page state so as to render "sign up" page instead.
                                                    this.setState({ showSignupPage: true })
                                                }}
                                            >
                                                Sign Up
                                            </Button>
                                        </div>

                                        {/* Divider */}
                                        <div className="rule my-3">
                                             <div className="line"><div></div></div>
                                             <div
                                                  style={{"font-size":"1.5em", "padding-bottom":".4em"}}
                                                  className="words"
                                             >
                                                or
                                             </div>
                                             <div className="line"><div></div></div>
                                        </div>

                                        <div className="d-grid gap-2" >
                                           <h3 style={{"font-weight":"700em"}}> Sign In </h3>
                                           <hr/>
                                           <Button
                                               onClick={() => {
                                                   signInWithPopup(this.props.fbsAuth, this.props.fbsAuthGoogle)
                                                       .then(userCredential => {
                                                           logEvent(this.props.fbsAnalytics, "login", { method: "Google" });

                                                           // After Login, navigate to HomePage
                                                           window.location.href = '/account';
                                                       })
                                                       .catch((error) => {
                                                           /* TODO: Show user that the login went wrong, somehow */
                                                           console.error(`FIREBASEAUTH (${error.code}): ${error.message}`)
                                                       })
                                               }}
                                           >
                                               Google Sign in
                                           </Button>
                                           {/* No longer show "Email" Button after onClick */}
                                           { !this.state.showEmailInputs && (
                                               <Button
                                                   onClick={() => {
                                                       this.setState({ showEmailInputs: true })
                                                   }}
                                               >
                                                   Email Sign in
                                               </Button>
                                           )}

                                           {/* Shows email & password inputs to login w/ email onClick of "Email button" */}
                                           { this.state.showEmailInputs && (
                                               <>
                                                   {/* EMAIL input */}
                                                   <div
                                                       className="form-group my-2"
                                                       style={{"width":"100%"}}
                                                   >
                                                       <input
                                                           type="email"
                                                           style={{"width":"100%"}}
                                                           className="form-control"
                                                           value={this.state.email}
                                                           onChange={(x) => this.setState({ email: x.target.value })}
                                                           placeholder="Enter email"
                                                       />
                                                   </div>
                                                   {/* PASSWORD input */}
                                                   <div
                                                       className="form-group mb-2 d-flex justify-content-between align-items-center"
                                                       style={{"width":"100%"}}
                                                   >
                                                       <input
                                                           type={this.state.showPassword ? "text" : "password"}
                                                           style={{"width":"80%"}}
                                                           className="form-control"
                                                           value={this.state.password}
                                                           onChange={(x) => this.setState({ password: x.target.value })}
                                                           placeholder="Password"
                                                       />
                                                       <Button
                                                           className="game-details-information"
                                                           style={{"margin-left":".5em", "margin-right":".5em", "width":"15%", "padding":".5em"}}
                                                           onClick={this.handleShowPassword}
                                                       >
                                                           <span>
                                                               Show &#x1F441;
                                                           </span>
                                                       </Button>
                                                   </div>
                                                   {/* Login Button (Use entered email + password to attempt login) */}
                                                   <Button
                                                       className="w-100"
                                                       onClick={e => {
                                                           // Crude validation of email and password (non-empty, etc)
                                                           if (typeof this.state.email !== "string" || this.state.email.length <= 0) {
                                                               window.alert("Invalid email")
                                                               return;
                                                           }
                                                           if (typeof this.state.password !== "string" || this.state.password.length <= 0) {
                                                               window.alert("Invalid password")
                                                               return;
                                                           }
                                                           // Attempt to sign in with email and password (firebase auth)
                                                           this.loginWithEmailPassword(e)
                                                       }}
                                                   >
                                                       Email Sign in
                                                   </Button>
                                               </>
                                           )}
                                       </div>

                                       <div className="d-flex justify-content-between"
                                            style={{"margin-bottom":"3em", "margin-top": "3em"}}>
                                           {/* Sign Up Button (alter page state to render "sign up" input page) */}
                                           <span
                                               variant="outline-light"
                                               className="w-100 mx-1"
                                               style={{"font-size":"1.1em", "font-weight":"500", "color":"#ededed"}}
                                           >
                                               Forgot Your Password? Click <a href="/resetpassword" className="change-link-color-chat">Here</a>.
                                           </span>
                                       </div>
                                   </div>
                               }

                                {/* RIGHT SIDE --- BIG MF LOGO */}
                                <div
                                   className="text-center remove-margin-top-landing-page"
                                   style={{"margin-top":"3.5em", "max-width":"700px"}}
                                >
                                    { this.state.showSignupPage
                                      ?
                                       <div className="d-grid gap-2">
                                            <hr
                                                className="hr-ask-indemni-title-hr remove-display-landing-page"
                                            />
                                            <h4
                                                className="p-1 mb-2 d-flex flex-column text-center change-waitlist-description-style-desktop"
                                                style={{"width":"360px"}}
                                            >
                                                <span style={{"font-size":".8em", "font-weight":"500"}}>
                                                    Unite with gamers to find perfect teammates, play games, make new friends, show off, and discover people with similar gaming goals & passions - completely free. 
                                                    <br/>
                                                    <br/>
                                                    <span>Curious how it works?</span>
                                                    <span
                                                        style={{"width":"100%"}}
                                                        className="d-flex flex-column align-items-center justify-content-center mt-1"
                                                    >
                                                        <a href="/home" target="_blank" className="change-link-color-chat mt-2">
                                                            Explore LFG
                                                        </a>
                                                        <a href="/gamermatchmaker" target="_blank" className="change-link-color-chat mt-2">
                                                            Try Gamer Matchmaker
                                                        </a>
                                                    </span>
                                                </span>
                                            </h4>
                                            <hr
                                                className="hr-ask-indemni-title-hr"
                                            />
                                        </div>
                                      : null
                                    }
                                   <div style={{ "display": "inline-block", "position": "relative" }}>
                                       <img src="/logo-no-background.png" style={{ "max-width": "min(512px, 95vw)" }} alt="Large Indemni Logo" />
                                       <a href="https://discord.gg/9EZmV5qBY5" style={{ "position": "absolute", "bottom": "8px", "left": "8px" }}>
                                           <img className="discord-logo" src="/discord-logo.svg" alt="Discord Logo" ></img>
                                       </a>
                                   </div>
                                   <br />
                                   <h4
                                        style={{"padding-bottom":"2em"}}
                                        className="mt-1 fst-italic"
                                   >
                                        The LFG Social Gaming Platform
                                    </h4>
                                    {/* <br />
                                      <div style={{"position":"relative", "padding-bottom":"56.25%", "height":"0", "overflow":"hidden", "margin-top":"1em", "margin-bottom":"5em"}}>
                                          <iframe 
                                              style={{"position":"absolute", "top":"0", "left":"0", "width":"100%", "height":"100%"}}
                                              src="https://www.youtube.com/embed/2yBT3DbNlKE?si=0MpW8Hi6fWUDcExD"
                                              title="Indemni Trailer: A Personal Gaming Assistant"
                                              frameborder="0" 
                                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                              allowfullscreen="true">
                                          </iframe>
                                      </div> */}
                               </div>
                           </div>
                           <Footerfixed></Footerfixed>
                       </div>
                   </>
                 : null
                }
            </>
        );
    }
}

export default LandingPage;
