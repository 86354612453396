import React, { Component } from "react";
import { Form, Button } from "react-bootstrap";
import universityList from './world_universities_and_domains.json';


class UniversitySearch extends Component {
    constructor(props) {
        super(props);
    }

    passesFilters(university) {
        if (!university) return false;
        const { countryFilter, universityNameIncludeFilter } = this.props;
        const matchesCountry = countryFilter ? university.country === countryFilter : true;
        const matchesName = universityNameIncludeFilter ? university.name.toLowerCase().includes(universityNameIncludeFilter.toLowerCase()) : true;
        return matchesCountry && matchesName;
    }

    displayUniversity(university) {
        if (this.passesFilters(university)) {
            return (
                <Button
                    className="game-details-information"
                    style={{ marginBottom: '1em', borderBottom: 'solid 2px #cbc2c2', cursor: 'pointer', "width":"100%"}}
                    key={university.name}
                    onClick={() => this.props.handleUniversityClick(university.name)}
                >
                    <h3 style={{ "color":"#ffe4a1", "text-shadow":"1px 1px 2px black", "text-wrap":"pretty", "font-size":"1.2em", "margin-top":".5em" }}>{university.name}</h3>
                    <p style={{ fontWeight: '600', fontStyle: 'oblique' }}>{university.country}</p>
                </Button>
            );
        }
        return null;
    }

    render() {
        const { universities, countryFilter, universityNameIncludeFilter, universityCurrentPage, universitiesPerPage, handleCountryChange, handleUniversitySearchChange, handlePageChange, educationSelected } = this.props;

        // Pagination Logic
        const startIndex = (universityCurrentPage - 1) * universitiesPerPage;
        const endIndex = startIndex + universitiesPerPage;
        const filteredUniversities = universities.filter(this.passesFilters.bind(this));
        const displayedUniversities = filteredUniversities.slice(startIndex, endIndex);
        const totalPages = Math.ceil(filteredUniversities.length / universitiesPerPage);
        const pageNumbersToShow = Math.min(totalPages, 5);
        const middlePageNumber = Math.floor(pageNumbersToShow / 2);
        let firstPageNumber = Math.max(1, universityCurrentPage - middlePageNumber);
        const lastPageNumber = Math.min(totalPages, firstPageNumber + pageNumbersToShow - 1);

        if (lastPageNumber === totalPages) {
            firstPageNumber = Math.max(1, lastPageNumber - pageNumbersToShow + 1);
        }

        return (
            <div className="d-flex flex-column align-items-center justify-content-center mt-2">
                <Form style={{ fontSize: '1.2em', marginBottom: '1em' }}>
                    <Form.Group style={{ marginBottom: '1em' }} controlId="countrySelect">
                        <Form.Label>Select Country</Form.Label>
                        <Form.Control as="select" value={countryFilter} onChange={handleCountryChange} disabled={educationSelected}>
                            <option value="">All Countries</option>
                            {[...new Set(universities.map(u => u.country))].map(country => (
                                <option key={country} value={country}>{country}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                    <Form.Group style={{ marginBottom: '1em' }} controlId="searchInput">
                        <Form.Label>Search University</Form.Label>
                        <Form.Control
                            type="search"
                            value={universityNameIncludeFilter}
                            onChange={handleUniversitySearchChange}
                            placeholder="Search by university name..."
                            disabled={educationSelected}
                        />
                    </Form.Group>
                </Form>
                {!educationSelected && (
                    <div className="d-flex flex-column">
                        {displayedUniversities.map(this.displayUniversity.bind(this))}
                    </div>
                )}
                {/* <div>
                    {Array.from({ length: totalPages }, (_, i) => (
                        <button key={i + 1} onClick={() => handlePageChange(i + 1)}>{i + 1}</button>
                    ))}
                </div> */}
            </div>
        );
    }
}

export default UniversitySearch;