import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

// Pages
import NoPage from "./pages/NoPage";
import LandingPage from "./pages/LandingPage";
import AskIndemniExplorePage from "./pages/AskIndemniExplorePage";
import AskADevExplorePage from "./pages/AskADevExplorePage";
import FAQPage from "./pages/FAQPage";
import SettingsPage from "./pages/SettingsPage";
import Inbox from "./pages/InboxPage";
import HomePage from "./pages/HomePage";
import NewHomePage from "./pages/NewHomePage";
import GamerMatchmakerPage from "./pages/GamerMatchmakerPage";
import GamePage from "./pages/GamePage";
import SocialPage from "./pages/SocialPage";
import VerificationPage from "./pages/VerificationPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import AskADevQuestionsPage from "./pages/AskADevQuestionsPage";
import AskIndemniQuestionsPage from "./pages/AskIndemniQuestionsPage";
import AskAGamerExplorePage from "./pages/AskAGamerExplorePage";
import AskAGamerListPage from "./pages/AskAGamerListPage";
import AskAGamerAnswerPage from "./pages/AskAGamerAnswerPage";
import AskAGamerAskerPage from "./pages/AskAGamerAskerPage";
import AccountPage from "./pages/AccountPage";
import VisitAccountPage from "./pages/VisitAccountPage";
import UniversityPage from "./pages/UniversityPage";
import LeaderboardPage from "./pages/LeaderboardPage";
import NewsPage from "./pages/NewsPage";
import DemoPage from "./pages/DemoPage";
import AskAGamerWaitlistPage from "./pages/AskAGamerWaitlistPage";

// Components
import Layout from "./components/Layout/Layout";

// Bootstrap Sass (CSS)
import "./index.scss";

// Import the initialisation functions from the Firebase SDKs we need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, TwitterAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Web3
// web3.js
import Web3 from "web3"
// WalletConnect
import UniversalProvider from "@walletconnect/universal-provider";
import { EthereumProvider } from "@walletconnect/ethereum-provider";
// Import Moralis
import Moralis from "moralis";
// Import the Moralis EvmChain dataType
import { EvmChain } from "@moralisweb3/common-evm-utils"
// Import Web3Modal modules (WalletConnect)
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5";
// Import Mixpanel for analytics
import mixpanel from "mixpanel-browser";

// Initialize Mixpanel
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN);

// Initialise Firebase
const fbsConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};
const firebase = initializeApp(fbsConfig);
const fbsAnalytics = getAnalytics(firebase);
const fbsAuth = getAuth(firebase);
const fbsAuthGoogle = new GoogleAuthProvider();
const fbsAuthTwitter = new TwitterAuthProvider();
const fbsFirestore = getFirestore(firebase);
const fbsStorage = getStorage(firebase);

// Initialise Moralis
const MORALIS_API_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6ImUwZjNiNzYyLWZkODItNDRmOC04ZGVjLTJhYTYzZTkxNzQ2MSIsIm9yZ0lkIjoiMzQzMTE2IiwidXNlcklkIjoiMzUyNzI1IiwidHlwZUlkIjoiYjQ2NTViYzYtZTMzYi00ZmVhLTk2ODEtZjY3OWY4Yzk4N2U1IiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE2ODY2NjQ3NjQsImV4cCI6NDg0MjQyNDc2NH0.DJJtPDklnwpathDajQEaEKlvhzHnQ2cu9PlkHfynON0";
Moralis.start({
    apiKey: MORALIS_API_KEY
})

// Initialise WalletConnect
const wcV2ApiKey = "1bcbc19f62e1a5ff8a90284807bedbfd"
async function walletconnect_provider2() {
    return new Promise(async (resolve, reject) => {
        const provider = await UniversalProvider.init({
            logger: "error",
            relayUrl: "wss://relay.walletconnect.com",
            projectId: wcV2ApiKey,
            metadata: {
                name: "Indemni",
                description: "WalletConnect v2 UniversalProvider",
                url: "https://indemni.io/",
                // FIXME: Use our icon
                icons: ["https://avatars.githubusercontent.com/u/37784886"],
            },
            client: undefined, // optional instance of @walletconnect/sign-client
        });
        // Create subproviders for each namespace/chain
        // FIXME: This causes crashes, but we really need to re-enable this later.
        await provider.connect({
            namespaces: {
                eip155: {
                    methods: [
                        "eth_requestAccounts",
                        "eth_sendTransaction",
                        "eth_signTransaction",
                        "eth_sign",
                        "eth_signTypedData",
                        "personal_sign",
                    ],
                    chains: ["eip155:1"],
                    events: ["chainChanged", "accountsChanged"],
                    rpcMap: {
                        1: `https://rpc.walletconnect.com?chainId=eip155:1&projectId=${wcV2ApiKey}`,
                    },
                },
                // pairingTopic: "<123...topic>", // optional topic to connect to
                // skipPairing: false, // optional to skip pairing ( later it can be resumed by invoking .pair())
            },
        });
        resolve(new Web3(provider))
    })
}

async function walletconnect_provider() {
    return new Promise(async (resolve, reject) => {
        window.ethereum = await EthereumProvider.init({
            projectId: wcV2ApiKey,
            chains: [1],
            methods: [
                "eth_requestAccounts",
                "eth_sendTransaction",
                "eth_signTransaction",
                "eth_sign",
                "eth_signTypedData",
                "personal_sign",
            ],
            events: ["chainChanged", "accountsChanged"],
            showQrModal: true,
            metadata: {
                name: "Indemni",
                description: "WalletConnect v2 UniversalProvider",
                url: "https://indemni.io/",
                // FIXME: Use our icon 
                icons: ["https://avatars.githubusercontent.com/u/37784886"],
            },
        });
        resolve(new Web3(window.ethereum))
    })
}

async function getWeb3Provider() {
    return new Promise(async (resolve, reject) => {
        // First try browser provider (EIP 1102); MetaMask currently takes this approach
        if (window.ethereum) {
            resolve(new Web3(window.ethereum))
        } else {
            // Then try WalletConnect
            walletconnect_provider()
                .then(resolve)
                .catch(error => {
                    console.error(error)
                    window.alert("Non-Ethereum browser detected, and WalletConnect failed to create provider. You should consider trying MetaMask!")
                    // FIXME: Possibly resolve to null, that way the rest of the site is
                    // accessible and loads and stuff.
                    reject(error)
                })
        }
    })
}

// WalletConnect Web3 Modal - more context/info @ https://docs.walletconnect.com/web3modal/react/about.
// Currently passed as walletModal={defaultWalletModal} into SettingsPage.
// @Rylan: Wasn't working within a standarad async function, this is how the docs said to structure it.
// ToDo: Add more Chains (consider Solana integration at https://solib.dev/  - seems complicated).

// Chain(s) Data
const ethereumMainnet = {
    chainId: 1,
    name: 'Ethereum',
    currency: 'ETH',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: 'https://cloudflare-eth.com'
  };
const polygonMainnet = {
    chainId: 137,
    name: 'Polygon Mainnet',
    currency: 'MATIC',
    explorerUrl: 'https://polygonscan.com/',
    rpcUrl: 'https://polygon-rpc.com'
};
const metadata = {
  name: 'Indemni',
  description: 'The Blockchain Gaming Platform',
  url: 'https://indemni.io',
  icons: ['https://indemni.io/favicon.ico']
};

// Actual Modal & Provider
const defaultWalletModal = createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: [ethereumMainnet, polygonMainnet],
    projectId: wcV2ApiKey,
    themeMode: 'dark',
    tokens: {
        // 137 == polygon/matic chain ID
        137: {
          address: '0xaBCdb656bA1135EeAB16D804039ba02918fC6700',
          image: 'https://indemni.io/favicon.ico'
        }
    },
    // Wallet Shown at top level of modal
    featuredWalletIds: [
        'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
        'a797aa35c0fadbfc1a53e7f675162ed5226968b44a19ee3d24385c64d1d3c393',
        'fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa'
    ]
});

// ^ end of WalletConnect / Web3Modal info.

// async IIFE for "top-level" await
(async function () {
    // Initialise web3 provider (through web3.js)
    window.web3 = await getWeb3Provider()

    // Render DOM
    const root = ReactDOM.createRoot(document.getElementById("root"));
    root.render(
        <React.StrictMode>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={
                                   <LandingPage
                                       firebase={firebase}
                                       fbsAnalytics={fbsAnalytics}
                                       fbsAuth={fbsAuth}
                                       fbsAuthGoogle={fbsAuthGoogle}
                                       fbsAuthTwitter={fbsAuthTwitter}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}

                                       web3={window.web3}

                                       moralis={Moralis}
                                       evmChain={EvmChain}
                                   />
                               }
                        />
                        {/* Old Home page */}
                        {/* <Route path="home" element={
                                   <HomePage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}

                                       moralis={Moralis}
                                       evmChain={EvmChain}
                                       walletModal={defaultWalletModal}
                                   />
                               }
                        /> */}
                        <Route path="home" element={
                                   <NewHomePage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                   />
                               }
                        />
                        <Route path="gamermatchmaker" element={
                                   <GamerMatchmakerPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                   />
                               }
                        />
                        {/* <Route path="demo" element={
                                   <DemoPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                   />
                               }
                        /> */}
                        {/* <Route path="waitlist" element={
                                   <AskAGamerWaitlistPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                   />
                               }
                        /> */}
                        <Route path="game" element={
                                   <GamePage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                   />
                               }
                        />
                        <Route path="gamerlounge" element={
                                   <SocialPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                   />
                               }
                        />
                        {/* The dynamic url for visiting/non-loggedin user - prefered this over
                            urlsearchparams - allows us to create a version of newhome that only
                            shows certain readable info
                         */
                        }
                        {/* <Route path="askindemni" element={
                                   <AskIndemniExplorePage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                   />
                               }
                        />
                        <Route path="askadev" element={
                                   <AskADevExplorePage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                   />
                               }
                        />
                        <Route path="askagamer" element={
                                   <AskAGamerExplorePage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                   />
                               }
                        />
                        <Route path="askadevquestions" element={
                                   <AskADevQuestionsPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                   />
                               }
                        />
                        <Route path="askindemniquestions" element={
                                   <AskIndemniQuestionsPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                   />
                               }
                        />
                        <Route path="askagamerlist" element={
                                   <AskAGamerListPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                       firebase={firebase}
                                   />
                               }
                        />
                        <Route path="askagameranswer" element={
                                   <AskAGamerAnswerPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                       firebase={firebase}
                                   />
                               }
                        /> */}
                        <Route path="account" element={
                                   <AccountPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                       firebase={firebase}
                                   />
                               }
                        />
                        <Route path="visitaccount" element={
                                   <VisitAccountPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                       firebase={firebase}
                                   />
                               }
                        />
                        <Route path="myuniversity" element={
                                   <UniversityPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                       firebase={firebase}
                                   />
                               }
                        />
                        <Route path="leaderboards" element={
                                   <LeaderboardPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                       firebase={firebase}
                                   />
                               }
                        />
                        <Route path="news" element={
                                   <NewsPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                       firebase={firebase}
                                   />
                               }
                        />
                        {/* <Route path="askagamerasker" element={
                                   <AskAGamerAskerPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}
                                       firebase={firebase}
                                   />
                               }
                        />
                        <Route path="inbox" element={
                                     <Inbox
                                        fbsAuth={fbsAuth}
                                        fbsFirestore={fbsFirestore}
                                        fbsStorage={fbsStorage}

                                        walletModal={defaultWalletModal}
                                     />
                                 }
                          /> */}
                        <Route path="faq" element={
                                   <FAQPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                   />
                               }
                        />
                        <Route path="verification" element={
                                   <VerificationPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                   />
                               }
                        />
                        <Route path="resetpassword" element={
                                   <ResetPasswordPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                   />
                               }
                        />
                        <Route path="settings" element={
                                   <SettingsPage
                                       fbsAuth={fbsAuth}
                                       fbsAuthGoogle={fbsAuthGoogle}
                                       fbsAuthTwitter={fbsAuthTwitter}
                                       fbsFirestore={fbsFirestore}
                                       fbsStorage={fbsStorage}

                                       web3={window.web3}
                                       moralis={Moralis}
                                       evmChain={EvmChain}
                                       walletModal={defaultWalletModal}
                                   />
                               }
                        />
                        <Route path="*" element={
                                   <NoPage
                                       fbsAuth={fbsAuth}
                                       fbsFirestore={fbsFirestore}
                                   />
                               }
                        />
                    </Route>
                </Routes>
            </BrowserRouter>
        </React.StrictMode>
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
})()

