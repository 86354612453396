// The LFG Post Group Page.
// TODO: Write Comments
import React, { Component } from "react"
import {
    Button,
    Modal,
    Dropdown,
    Stack,
    Pagination,
} from "react-bootstrap"
import {
    updateDoc,
    doc,
    getDoc,
    arrayRemove,
    arrayUnion,
} from "@firebase/firestore";
import {
    Steam,
    Xbox,
    Playstation,
    NintendoSwitch,
    PcDisplayHorizontal,
    Phone,
    BadgeVr,
    Discord,
    PatchQuestion,
    Check2Square,
    Person,
    PersonFill,
    PersonFillCheck,
    PersonVcard,
    Trash3,
    CardList
} from "react-bootstrap-icons";
import { leaveGroupAlterData } from "../../utils.js";
import { Link } from "react-router-dom";

class SocialGroups extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newUser: false, // If true, then it's a new user. Set in componentDidMount to see if the user just created an account which then dictates some other conditionals.
            cooldown: false, // Conditional to set timeout of .5s to allow DB to update and prevent user activity before it ends.

            showGamertagsSocials: false,
            gamertagNameCopy: "",
            discordNameCopy: "",

            joinedLFGPost: null,
            lfgPostNotFound: false,
            games: [],
        }


        this.formatDate = this.formatDate.bind(this);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.passesFilters = this.passesFilters.bind(this);
        this.displayGame = this.displayGame.bind(this);
        this.renderFormattedText = this.renderFormattedText.bind(this);
        this.playAudio = this.playAudio.bind(this);

        this.handleClose = this.handleClose.bind(this);
        this.handleShow = this.handleShow.bind(this);

        this.leaveGroup = this.leaveGroup.bind(this);
        this.mapLookingNeededToNumber = this.mapLookingNeededToNumber.bind(this);
        this.getPlatformIcon = this.getPlatformIcon.bind(this);
        this.displayMembers = this.displayMembers.bind(this);
    }

    // Look for the LFG post based on information in the "GroupChats"
    // collection, passed as props. If we find a the userNumber that
    // matches the groupNumber (which is just the userNumber of the person
    // that made the post), we set joinedLFGPost equal to that LFG Post object.
    // * Thought was preferrable over writing the entire LFG Post object to
    // the GroupsChats collection for each user when they post. 
    async componentDidMount() {
        const gamesDocRef = doc(this.props.fbsFirestore, "Games", `${this.props.joinedGroupOne.gameNumber}`);
        const gamesSnapshot = await getDoc(gamesDocRef);
    
        if (gamesSnapshot.exists()) {
            const gamesData = gamesSnapshot.data();
            const people = gamesData.people;
            console.log("LFG Post & Game", people, this.props.joinedGroupOne.gameNumber)
            // Using array methods to find the correct person and group member
            const person = people.find(person => person.userNumber === this.props.joinedGroupOne.groupNumber);
            console.log("Group Chat #", this.props.joinedGroupOne.groupNumber )
            if (person) {
                const joinedLFGPost = person.groupMembers.find(member => member.userNumber === this.props.userdata.userNumber);
                if (joinedLFGPost) {
                    console.log("Joined Post", person)
                    this.setState({ joinedLFGPost: person });
                } else {
                    console.error('No matching user found in the group members.');
                    this.setState({ lfgPostNotFound: true});
                    this.props.disableGroupChat();
                }
            } else {
                console.error('No matching group number found in the people array.')
                this.setState({ lfgPostNotFound: true});
                this.props.disableGroupChat();
            }
        } else {
            console.error('Game document does not exist.')
            this.setState({ lfgPostNotFound: true});
            this.props.disableGroupChat();
        }
    }
    
    
    // Called onCLick of certain actions that plays an mp3 file from the 'Public' folder.
    // Currently set to 20% volume for all files, but can be adjusted to be higher/per file accordingly.
    // Passed down as props to children components as well.
    // TODO (eventually - not a priority): Add a "Adjust Sound Effect Volume" option in Settings,
    // so that users can set audio.volume globally however they'd like.
    playAudio(source) {
        const audio = new Audio(source);
        audio.volume = 0.2;
        audio.play();
    };

    // Changes the firestore timestamp to a real js object date.
    // Called in displayPosts() to show when/how long ago a post was made.
    formatDate(date) {
        const now = new Date();
        const pastTime = new Date(date.seconds * 1000); // Converts Firestore timestamp to Date object
        const diff = now - pastTime; // Difference in milliseconds
        const diffMinutes = Math.floor(diff / 60000); // Converts to minutes
        const diffHours = Math.floor(diffMinutes / 60); // Converts to hours
        const diffDays = Math.floor(diffHours / 24); // Converts to days
        
        // Display render of date with user's timezone
        const options = { month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        const formattedDate = pastTime.toLocaleDateString('default', options);
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    
        // Shows how long ago the post was in minutes, hours, or days
        let timeAgo = '';
        if (diffMinutes < 60) {
            timeAgo = `${diffMinutes} min ago`;
        } else if (diffHours < 24) {
            timeAgo = `${diffHours} hour${diffHours > 1 ? 's' : ''} ago`;
        } else {
            timeAgo = `${diffDays} day${diffDays > 1 ? 's' : ''} ago`;
        }
    
        return {
            formattedDate,
            timezone,
            timeAgo
        };
    }

    // Looks for %0A URL encoded line breaks and links.
    // Handles reformatting text to wrap links in <a> tags, and
    // shows the user visual spaces with <br /> if they pressed enter or
    // shift+enter as represented by "\n".
    renderFormattedText = (text) => {
        const linkRegex = /(?:https?|ftp):\/\/[^\s]+(?:\s|$)/g;
        const lines = text.split(/(?:\n|%0A)/);
      
        const elements = [];
      
        lines.forEach((line, lineIndex) => {
            const linkMatches = line.match(linkRegex);
            if (linkMatches) {
                let lastIndex = 0;

                linkMatches.forEach((linkMatch, index) => {
                    const linkStart = line.indexOf(linkMatch, lastIndex);
                    const linkEnd = linkStart + linkMatch.length;

                    // Adds the preceding text as regular text
                    elements.push(<span key={`text-${lineIndex}-${index}`}>{line.substring(lastIndex, linkStart)}</span>);

                    // Wraps the link in an anchor
                    elements.push(
                        <a
                            key={`link-${lineIndex}-${index}`}
                            className="change-link-color-chat"
                            style={{
                                wordWrap: 'break-word',
                                wordBreak: 'break-all',
                                whiteSpace: 'normal',
                            }}
                            href={decodeURIComponent(linkMatch)}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                        {decodeURIComponent(linkMatch)}
                        </a>
                    );

                    // Updates the lastIndex to the end of the current link
                    lastIndex = linkEnd;
                });
            
                // Adds the remaining text after the last link
                elements.push(<span key={`text-${lineIndex}-last`}>{line.substring(lastIndex)}</span>);
            } else {
                // If no links, add the entire line as regular text
                elements.push(<span key={`text-${lineIndex}`}>{line}</span>);
            }

            // Add the line break after each line (except the last line)
            if (lineIndex < lines.length - 1) {
                elements.push(<br key={`br-${lineIndex}`} />);
            }
        });
      
        return elements;
    };

    // Return true if game matches the current filter(s) or nameIncludeFilter === ""
    passesFilters(game) {
        if (!game) return false;
    
        if (this.state.nameIncludeFilter !== "" && game.gameName.toLowerCase().includes(this.state.nameIncludeFilter.toLowerCase()) ) return true;
    }

    // Checks for search input and updates page.
    handleSearchChange(e) {
        const searchValue = e.target.value;
        this.setState({ nameIncludeFilter: searchValue, currentPage: 1 });
    };

    // Called onClick of "Leave Group".
    // Calls removePostData() from utils.js.
    // Removes the users LFG Post from the people array for the specific game
    // and updates their user doc with isLFG === false
    // TODO: Test, maybe add some more state and logic
    async leaveGroup(gameNumber) {
        // Calls removePosttData with appropriate parameters
        await leaveGroupAlterData(
            this.props.userdata.inTheUniveristyProgram,
            this.props.fbsFirestore,
            this.props.userdata.userNumber,  // User number
            this.props.joinedGroupOne.gameNumber, // Game Number
            this.props.joinedGroupOne.groupNumber // Joined Group Number
        );
    }

    // Helper function to map 'looking_needed' string to number
    mapLookingNeededToNumber(lookingNeeded) {
       const map = {
           "Any": 6,
           "1 Gamer": 2,
           "2 Gamers": 3,
           "3 Gamers": 4,
           "4 Gamers": 5,
           "5 Gamers": 6,
           "6+ Gamers": 12,
       };
       return map[lookingNeeded] || 0; // Default to 0 if unexpected value
    }

    getPlatformIcon(platformName) {
        const platformIcons = {
            "PC": <PcDisplayHorizontal className="social-page-modal-svg-icons mb-2" />,
            "Console": <PatchQuestion className="social-page-modal-svg-icons mb-2" />, // Assuming no specific icon for "Console"
            "PS5": <Playstation className="social-page-modal-svg-icons mb-2" />,
            "Xbox": <Xbox className="social-page-modal-svg-icons mb-2" />,
            "Switch": <NintendoSwitch className="social-page-modal-svg-icons mb-2" />,
            "Mobile": <Phone className="social-page-modal-svg-icons mb-2" />, // Replace <Phone> with the correct icon component
            "VR": <BadgeVr className="social-page-modal-svg-icons mb-2" />,
            "Other": <PatchQuestion className="social-page-modal-svg-icons mb-2" />,
            "Steam": <Steam className="social-page-modal-svg-icons mb-2" />,
            "Epic": <span style={{"font-size":"1.1em"}}>Epic</span>,
        };
        return platformIcons[platformName] || <PatchQuestion className="social-page-modal-svg-icons mb-2" />; // Default to "Other" if not matched
    }

    displayMembers() {
        const elements = [];
        const membersLength = this.props.joinedGroupOneMembers.length;
        const neededLength = this.mapLookingNeededToNumber(this.state.joinedLFGPost.looking_needed);
    
        console.log(`Members length: ${membersLength}, Needed length: ${neededLength}`);
    
        for (let i = 0; i < neededLength; i++) {
            if (i < membersLength) {
                const member = this.props.joinedGroupOneMembers[i];
                elements.push(
                    <PersonFill
                        key={member.username} // Assuming username is unique
                        eventKey={i}
                        className="p-1"
                        style={{"height": "41px", "width":"auto"}}
                    >
                        {member.username}
                    </PersonFill>
                );
            } else {
                elements.push(
                    <Person
                        key={`empty-${i}`}
                        eventKey={i}
                        className="p-1"
                        style={{"height": "41px", "width":"auto"}}
                    />
                );
            }
        }
    
        return elements;
    }

    // Display of games when editing and choosing a game for LFG Post.
    displayGame(game) {
        if (this.passesFilters(game)) {
            return (
                    <>
                        <div className="me-2 px-2 explore-games-background ask-a-gamer-explore-margin-right-0-mobile">
                            <div className="d-flex flex-column py-3 px-4 ask-a-gamer-game-list-border ask-a-gamer-explore-padding-left-right-halfem-mobile">
                                <div className="d-flex justify-content-between align-items-center flex-wrap">
                                    <div
                                        className="d-inline-flex flex-row align-items-center justify-content-center flex-wrap ask-a-gamer-explore-flex-column-mobile"
                                        style={{"width":"100%"}}
                                    >
                                        <div
                                            className="d-flex flex-row align-items-center justify-content-center me-2 ask-a-gamer-explore-margin-right-left-0-tablet"
                                            style={{"width":"200px"}}
                                        >
                                            <img
                                                src={game.gameImagePath}
                                                width="250"
                                                alt="Video Game Cover Art"
                                                style={{"width":"100px"}}
                                                className="img-fluid ask-a-gamer-explore-image-tablet"
                                            />
                                            <h5
                                                style={{"font-size":"1em", "text-align":"center"}}
                                                className="mx-3"
                                            >
                                                {game.gameName}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                        <hr className="ask-a-gamer-answer-spotlight-hr"/>
                    </>
            )
        } else return null;
    }

    // Hides Modal that displays the group members Gamertags & Social usernames.
    handleClose() {
        this.setState({showGamertagsSocials: false});
    }
    
    // Shows Modal that displays the group members Gamertags & Social usernames.
    handleShow() {
        this.setState({showGamertagsSocials: true});
    }

    // We have multiple large conditional renders depending on state of "isEditing ..."
    // This changes the display of sections from a standard display to one where users
    // can edit their profile and update the DB in the User & Games Collections.
    // TODO: Finish Editing Updates for About/Bio, Favorite Games, Platforms/Interests,
    // Gamertags/Socials, and Background sections.
    // TODO: Add and update remainder of update...() functions to pass edits to alterPostData.
    // TODO: Finish Chat component, Add Join Group Logic, Bug Testing, Styling for mobile,
    // TODO: All other TODO's in here.
    // @Rylan - I know this is alot of repeated code and could have been done better lol,
    // I optimized for time and it "working" - don't worry i'll clean it up shortly after launch
    // so nothing worry to about for now!
    render() {
        const { formattedDate, timezone, timeAgo } = this.formatDate(this.state.joinedLFGPost && !this.state.lfgPostNotFound ? this.state.joinedLFGPost.postTime : "" );

        // Conditional when loading the user's profile to make sure userdata is set.
        if (!this.state.joinedLFGPost && !this.state.lfgPostNotFound)
            return (
                <div
                    className="vh-100 fluid d-flex flex-row flex-grow-1 justify-content-center align-items-center glow visting-home-page-fade-animation"
                    style={{"overflow-y":"hidden"}}
                >
                    <h2
                        className="d-flex flex-column align-items-center justify-content-center"
                        style={{"font-weight":"700", "font-size":"2em", "font-style":"italic", "text-align":"center", "margin-bottom":"7em"}}
                    >
                        <span
                            className="desktop-increase-demo-gamer-profile-text-size"
                            style={{"margin-bottom":"2em"}}
                        >
                            Loading Profile
                        </span>
                        <div className="big-circle-inline-loading-animation-demo big-circle-inline-loading-animation-demo-desktop"/>
                    </h2>
                </div>
            );

        // Conditional if we can't find the lfgPost for some reason
        if (this.state.lfgPostNotFound)
            return (
                <div
                    className="fluid mt-4 d-flex flex-row flex-grow-1 justify-content-center align-items-center visting-home-page-fade-animation"
                    style={{"overflow-y":"hidden"}}
                >
                    <h2
                        className="d-flex flex-column align-items-center justify-content-center"
                        style={{"font-weight":"600", "font-size":"1.3em", "font-style":"italic", "text-align":"center", "margin-bottom":"3em", "border-bottom":"solid"}}
                    >
                        <span
                            className="desktop-increase-demo-gamer-profile-text-size"
                            style={{"margin-bottom":"2em"}}
                        >
                            Could not find your group - please try refreshing the page or clearing your cache and trying again. If that does not fix it, leave the group with the button below and try joining again.
                        </span>
                        <Button
                            style={{"font-size":".95em", "margin-top":"1em", "margin-bottom":"1em", "font-weight":"600", "width":"100%"}}
                            onClick={ async () => {
                                await this.leaveGroup()
                            }}
                            className="account-profile-cancel desktop-increase-demo-gamer-profile-text-size"
                        >
                            Leave Group
                        </Button>
                    </h2>
                </div>
            );
        
        if (this.state.joinedLFGPost && !this.state.lfgPostNotFound)
        return (
            <>
                <Stack
                    gap={2}
                    className="col-12 fluid mx-auto"
                >
                    { this.props.profile.joinedGroupOne !== -1 && this.props.profile.isInOtherGroups
                      ?
                        <>
                          {/* START of Group */}
                            <div className="p-2 ask-a-gamer-profile-containers-mobile mt-3">
                                <div
                                    style={{"margin-top":"-.75em"}}
                                    className="new-home-profile-flex-between"
                                >
                                    <span style={{"font-size":".75em", "color":"#e0e0e0d9", "text-align":"center", "width":"100%"}}>
                                        * You can currently only join one group at a time
                                    </span>
                                </div>
                                <div className="new-home-profile-flex-between">
                                    <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%"}}>
                                        Group Members
                                    </span>
                                </div>
                                <div
                                    style={{"margin-bottom":"1em"}}
                                    className="d-flex flex-column tab-textbox-hover-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile"
                                >
                                    <>
                                        <div className="d-flex justify-content-between align-items-center flex-wrap">
                                            <div
                                                className="d-inline-flex flex-row align-items-center flex-wrap"
                                                style={{ "width": "100%" }}
                                            >
                                                <div
                                                    className="d-flex align-items-center justify-content-center flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                                    style={{ "flexGrow": "1", "flex-wrap":"wrap" }}
                                                >
                                                    {this.props.joinedGroupOneMembers ? this.displayMembers() : null}
                                                </div>
                                            </div>
                                        </div>
                                        { this.props.joinedGroupOneMembers && this.props.joinedGroupOneMembers.length === this.mapLookingNeededToNumber(this.state.joinedLFGPost.looking_needed) &&
                                            <div className="d-flex justify-content-between align-items-center flex-wrap">
                                                <div
                                                    className="d-inline-flex flex-row align-items-center flex-wrap"
                                                    style={{ "width": "100%" }}
                                                >
                                                    <div
                                                        className="d-flex align-items-center justify-content-center flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                                        style={{ "flexGrow": "1" }}
                                                    >
                                                        <span className="group-full-text-display glow">
                                                            Group Full!
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <hr className="ask-a-gamer-answer-spotlight-hr"/>
                                        <div
                                            className="d-flex flex-row"
                                            style={{"flex-wrap":"wrap", "border":"none"}}
                                        >
                                            <div
                                                className="navuserchatInfo d-flex flex-column align-item-center ask-a-gamer-profile-mastered-games-tablet desktop-increase-font-1-quarter"
                                                style={{"width":"100%", "text-align":"center"}}
                                            >
                                                <>

                                                    { this.props.joinedGroupOneMembers && this.props.joinedGroupOneMembers.map((member) => ( 
                                                      (
                                                        <>
                                                            { member.userNumber === this.props.userdata.userNumber
                                                              ?
                                                                <>
                                                                    <div className="d-flex mt-2 flex-row align-items-center justify-content-center">
                                                                        <h5
                                                                            className="current-user-username-color-styling-one mb-2"
                                                                            style={{"font-size":"1.3em"}}
                                                                        >
                                                                            {this.props.userdata.username} (Me 😄)
                                                                        </h5>
                                                                    </div>
                                                                    <hr/>
                                                                </>
                                                              :
                                                                <>
                                                                    <div className="d-flex mt-2 flex-row align-items-center justify-content-center">
                                                                        <h5
                                                                            className="other-usernames-color-styling-one mb-3"
                                                                            style={{"font-size":"1.3em"}}
                                                                        >
                                                                            {member.username === this.state.joinedLFGPost.username ? `${this.state.joinedLFGPost.username} (Group Leader)` : member.username}
                                                                        </h5>
                                                                    </div>
                                                                    <div className="d-flex flex-column align-items-center justify-content-between">
                                                                        <span style={{"padding": ".75em", "width":"100%"}}>
                                                                            <Button
                                                                                className="tab-textbox-buttons px-4 visit-profile-button-mobile visit-profile-button-medium"
                                                                                style={{"box-shadow":"0px -5px 20px 5px #040404", "color":"#ffffff", "text-shadow":"0 0 2px #000000", "font-weight":"800", "margin-bottom":"-.5em"}}
                                                                                onClick={this.handleShow}
                                                                            >
                                                                                <CardList style={{"color":"white", "margin-left":".25em", "margin-bottom":".2em", "margin-right":".5em", "align-items":"center", "height":"15px", "width":"auto"}}/>
                                                                                Gamertags & Usernames
                                                                            </Button>
                                                                            <Modal 
                                                                                show={this.state.showGamertagsSocials} 
                                                                                onHide={this.handleClose}
                                                                                aria-labelledby="contained-modal-title-vcenter"
                                                                                centered
                                                                                className="px-3"
                                                                            >
                                                                                <Modal.Header closeButton>
                                                                                    <Modal.Title>Gamertags & Usernames</Modal.Title>
                                                                                </Modal.Header>
                                                                                <Modal.Body>
                                                                                    <div className="d-flex flex-column align-items-center justify-content-between">
                                                                                        <div 
                                                                                            className="d-flex justify-content-between align-items-center mb-2 p-1 "
                                                                                            style={{"border-bottom":"1px solid gray", "width":"100%"}}
                                                                                        >
                                                                                            { member.joinGroupPlatformUsername && member.joinGroupPlatformUsername !== ""
                                                                                              ?
                                                                                                <>
                                                                                                    {this.getPlatformIcon(member.joinGroupPlatform)}
                                                                                                    <span style={{"font-size": "1.1em", "font-weight": "500", "margin-bottom":".5em"}}>
                                                                                                        {member.joinGroupPlatformUsername}
                                                                                                    </span>
                                                                                                    <Button
                                                                                                        style={{"height":"auto", "margin-bottom":".5em"}}
                                                                                                        onClick={async () => {
                                                                                                            if ("clipboard" in navigator) {
                                                                                                              await navigator.clipboard.writeText(`${member.joinGroupPlatformUsername}`);
                                                                                                            } else {
                                                                                                              document.execCommand("copy", true, `${member.joinGroupPlatformUsername}`);
                                                                                                            }
                                                                                                            this.setState({ gamertagNameCopy: member.joinGroupPlatformUsername});
                                                                                                        }}
                                                                                                    >
                                                                                                        {this.state.gamertagNameCopy === "" ? "Copy" : "Copied!"}
                                                                                                    </Button>
                                                                                                </>
                                                                                              :
                                                                                                <span style={{"font-size": "1.1em", "font-weight": "500", "margin-bottom":".5em"}}>
                                                                                                    Gamertag not provided
                                                                                                </span>
                                                                                            }
                                                                                        </div>
                                                                                        <div 
                                                                                            className="d-flex justify-content-between align-items-center mb-2 p-1 "
                                                                                            style={{"border-bottom":"1px solid gray", "width":"100%"}}
                                                                                        >
                                                                                            { member.joinGroupDiscord && member.joinGroupDiscord !== ""
                                                                                              ?
                                                                                                <>
                                                                                                    <Discord className="social-page-modal-svg-icons mb-2" />
                                                                                                    <span style={{"font-size": "1.1em", "font-weight": "500", "margin-bottom":".5em"}}>
                                                                                                        {member.joinGroupDiscord}
                                                                                                    </span>
                                                                                                    <Button
                                                                                                        style={{"height":"auto", "margin-bottom":".5em"}}
                                                                                                        onClick={async () => {
                                                                                                            if ("clipboard" in navigator) {
                                                                                                              await navigator.clipboard.writeText(`${member.joinGroupDiscord}`);
                                                                                                            } else {
                                                                                                              document.execCommand("copy", true, `${member.joinGroupDiscord}`);
                                                                                                            }
                                                                                                            this.setState({ discordNameCopy: member.joinGroupDiscord});
                                                                                                        }}
                                                                                                    >
                                                                                                        {this.state.discordNameCopy === "" ? "Copy" : "Copied!"}
                                                                                                    </Button>
                                                                                                </>
                                                                                              :
                                                                                                <span style={{"font-size": "1.1em", "font-weight": "500", "margin-bottom":".5em"}}>
                                                                                                    Discord username not provided
                                                                                                </span>
                                                                                                }
                                                                                        </div>
                                                                                    </div>
                                                                                </Modal.Body>
                                                                                <Modal.Footer>
                                                                                    <Button 
                                                                                        variant="secondary" 
                                                                                        onClick={this.handleClose}
                                                                                    >
                                                                                        Close
                                                                                    </Button>
                                                                                </Modal.Footer>
                                                                            </Modal>
                                                                        </span>
                                                                        <span style={{"padding": ".75em", "margin-top":".5em", "width":"100%"}}>
                                                                            <Link to={`/visitaccount?gamer=${member.username}`}>
                                                                                <Button
                                                                                    className="tab-textbox-buttons px-4 visit-profile-button-mobile visit-profile-button-medium"
                                                                                    style={{"box-shadow":"0px -5px 20px 5px #040404", "color":"#ffffff", "text-shadow":"0 0 2px #000000", "font-weight":"800"}}
                                                                                >
                                                                                    <PersonVcard
                                                                                        style={{"color":"white", "margin-left":".25em", "margin-bottom":".2em", "margin-right":".5em", "align-items":"center", "height":"15px", "width":"auto"}}
                                                                                    />
                                                                                    See Profile
                                                                                </Button>
                                                                            </Link>
                                                                        </span>
                                                                    </div>
                                                                    <hr/>
                                                                </>
                                                            }
                                                        </>
                                                      )
                                                    ))}
                                                </>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </div>
                          {/* END of Group */}

                          {/* START of LFG POST */}
                            <div className="p-2 ask-a-gamer-profile-containers-mobile mt-3">
                                <div className="new-home-profile-flex-between">
                                    <span style={{"font-size":"1.5em", "text-decoration":"underline", "text-align":"center", "width":"100%", "text-wrap":"pretty"}}>
                                        {this.state.joinedLFGPost.username}'s LFG Post
                                    </span>
                                </div>
                                <div
                                    style={{"margin-bottom":"1em"}}
                                    className={"d-flex flex-column tab-textbox-hover-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile"}
                                >
                                    <>
                                        <div className="d-flex justify-content-between align-items-center flex-wrap">
                                            <div
                                                className="d-inline-flex flex-row align-items-center flex-wrap desktop-display-flex-column"
                                                style={{"width":"100%"}}
                                            >
                                                <div
                                                    className="d-flex flex-column flex-grow align-items-center justify-content-center ask-a-gamer-profile-margins-game-spotlight-mobile desktop-increase-posted-time-demo-gamer-profile"
                                                    style={{"flex-grow":"1"}}
                                                >
                                                    <Button
                                                        style={{"font-size":"1.1em", "margin-top":"1em", "margin-bottom":"1em", "font-weight":"600", "width":"100%"}}
                                                        onClick={ async () => {
                                                            await this.leaveGroup()
                                                        }}
                                                        className="account-profile-cancel"
                                                    >
                                                        Leave Group
                                                    </Button>
                                                    <span
                                                        style={{"text-wrap":"balance"}}
                                                        className="delete-lfg-post-warning-message"
                                                    >
                                                        * Leaving the group will remove you from this group's chat.
                                                    </span>
                                                </div>
                                                <div className="d-flex flex-column align-items-center justify-content-center me-2 glow ask-a-gamer-explore-margin-right-left-0-tablet desktop-no-margin-x" >
                                                    <h5
                                                        style={{"text-wrap":"balance", "text-align":"center"}}
                                                        className="my-2"
                                                    >
                                                        {this.state.joinedLFGPost.looking_gameName}
                                                    </h5>
                                                    <img
                                                        src={this.state.joinedLFGPost.looking_gameImagePath}
                                                        width="250"
                                                        alt="Video Game Cover Art"
                                                        style={{"width":"400px"}}
                                                        className="img-fluid ask-a-gamer-explore-image-tablet"
                                                    />
                                                    <h5 className="my-2">
                                                        {this.state.joinedLFGPost.looking_platform === "" ? "Any" : this.state.joinedLFGPost.looking_platform}
                                                    </h5>
                                                </div>
                                                <div
                                                    className="d-flex flex-column flex-grow align-items-center justify-content-center ask-a-gamer-profile-margins-game-spotlight-mobile desktop-increase-posted-time-demo-gamer-profile"
                                                    style={{"flex-grow":"1"}}
                                                >
                                                    <span
                                                        className="my-2"
                                                        style={{"font-size":".9em", "text-align":"center", "text-wrap":"balance"}}
                                                    >
                                                        Posted on: {this.state.joinedLFGPost.postTime === "" ? "N/A": formattedDate + ` (${timeAgo})`}
                                                    </span>
                                                    <span
                                                        className="list-of-stats"
                                                        style={{"color":"#75f7fff0", "font-weight":"600"}}
                                                    >
                                                        Needed: {this.state.joinedLFGPost.looking_needed === "" ? "Any" : this.state.joinedLFGPost.looking_needed}
                                                    </span>
                                                    <span
                                                        className="list-of-stats"
                                                        style={{"color":"#75f7fff0", "font-weight":"600"}}
                                                    >
                                                        Start Time: {this.state.joinedLFGPost.looking_time === "" ? "Now" : this.state.joinedLFGPost.looking_time}
                                                    </span>
                                                </div>
                                                <hr
                                                    className="ask-a-gamer-answer-spotlight-hr"
                                                    style={{"width":"100%"}}
                                                />
                                                <div
                                                    className="d-flex flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                                    style={{"flex-grow":"1"}}
                                                >
                                                    <p style={{"width":"100%"}}>
                                                        <div className="d-flex flex-row flex-wrap justify-content-between overflow-auto" >
                                                            <small
                                                                style={{"font-size":"1.2em", "color":"#ffea99", "text-align":"center", "text-shadow":"0px -3px 2px #000000", "width":"100%", "text-wrap":"pretty"}}
                                                                className="desktop-increase-demo-gamer-profile-text-size desktop-change-width-lfgposts-lookingMessage-900"
                                                            >
                                                                "{this.state.joinedLFGPost.looking_message === "" ? `${this.state.joinedLFGPost.username} did not specify what they wanted to.` : this.renderFormattedText(this.state.joinedLFGPost.looking_message)}"
                                                             </small>
                                                        </div>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="ask-a-gamer-answer-spotlight-hr"/>
                                        <div
                                            className="d-flex flex-row"
                                            style={{"flex-wrap":"wrap", "border":"none"}}
                                        >
                                            <div className="navuserchatInfo d-flex align-item-center ask-a-gamer-profile-mastered-games-tablet change-demo-gamer-profile-objective-comms-commitment">
                                                <div className="desktop-display-flex-column desktop-width-200">
                                                    <h5 style={{"font-size":"1.1em"}}>Objective - {this.state.joinedLFGPost.looking_objective_flexRequire === "" ? "Flexible": this.state.joinedLFGPost.looking_objective_flexRequire}</h5>
                                                    <div className="d-flex flex-row align-items-center justify-content-between desktop-margin-top-1">
                                                        <span style={{"padding": ".75em", "width":"100%"}}>
                                                            <div
                                                                className="tab-textbox-buttons justify-content-around px-3 py-2 text-center"
                                                                style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                                            >
                                                                {this.state.joinedLFGPost.looking_objective === "" ? "Any": this.state.joinedLFGPost.looking_objective}
                                                                <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="desktop-display-flex-column desktop-width-200">
                                                    <h5 style={{"font-size":"1.1em"}}>Skill & Comms</h5>
                                                    <div className="d-flex flex-row align-items-center justify-content-between desktop-margin-top-1">
                                                        <span style={{"padding": ".75em"}}>
                                                            <div
                                                                className="tab-textbox-buttons justify-content-around px-2 py-2 text-center"
                                                                style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em", "width":"105px"}}
                                                            >
                                                                {this.state.joinedLFGPost.looking_skill === "" ? "Any": this.state.joinedLFGPost.looking_skill}
                                                                <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                            </div>
                                                        </span>
                                                        <span style={{"padding": ".75em"}}>
                                                            <div
                                                                className="tab-textbox-buttons justify-content-around px-2 py-2 text-center"
                                                                style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em", "width":"105px"}}
                                                            >
                                                                {this.state.joinedLFGPost.looking_comms === "" ? "Any": this.state.joinedLFGPost.looking_comms}
                                                                <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="desktop-display-flex-column desktop-width-200">
                                                    <h5
                                                        className="desktop-width-max-content"
                                                        style={{"font-size":"1.1em"}}
                                                    >
                                                        Session Length - {this.state.joinedLFGPost.looking_session_flexRequire === "" ? "Flexible": this.state.joinedLFGPost.looking_session_flexRequire}
                                                    </h5>
                                                    <div className="d-flex flex-row align-items-center justify-content-between desktop-margin-top-1">
                                                        <span style={{"padding": ".75em", "width":"100%"}}>
                                                            <div
                                                                className="tab-textbox-buttons justify-content-around px-3 py-2 text-center"
                                                                style={{"box-shadow":"0px -5px 10px 5px #040404", "text-shadow":"0 0 2px #000000", "font-weight":"650", "font-size":".9em"}}
                                                            >
                                                                {this.state.joinedLFGPost.looking_session === "" ? "Any": this.state.joinedLFGPost.looking_session}
                                                                <Check2Square style={{"color":"white", "margin-left":".4em", "margin-bottom":".2em", "align-items":"center"}}/>
                                                            </div>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </div>
                            {/* END of LFG Post */}

                        </>
                      :
                        <>
                            <div className="me-2 px-2 ask-a-gamer-explore-margin-padding-right-left-0-mobile">
                                <div className="p-2 game-page-post-containers-mobile mt-2">
                                    <div
                                        style={{ "margin-bottom": "1em", "margin-top": "1em" }}
                                        className="d-flex flex-column tab-textbox-hover-variation py-3 px-4 ask-a-gamer-profile-padding-right-1-mobile"
                                    >
                                        <div className="d-flex justify-content-between align-items-center flex-wrap">
                                            <div
                                                className="d-inline-flex flex-row align-items-center flex-wrap"
                                                style={{ "width": "100%" }}
                                            >
                                                <div
                                                    className="d-flex flex-grow ms-3 ask-a-gamer-profile-margins-game-spotlight-mobile"
                                                    style={{ "flex-grow": "1" }}
                                                >
                                                    <p style={{ "width": "100%", "margin-bottom": "0" }}>
                                                        <div className="d-flex flex-column flex-wrap justify-content-center align-items-center overflow-auto text-center" >
                                                            <small
                                                                style={{ "font-size": "1.5em", "font-weight": "600", "text-align": "center", "text-shadow": "0px -3px 2px #000000" }}
                                                                className="d-flex flex-column desktop-increase-demo-gamer-profile-text-size"
                                                            >
                                                                <span style={{ "margin-bottom": "1em", "color": "#ff7b68", "font-size": "1.2em", "text-decoration": "underline", "text-decoration-style": "double", "text-decoration-color": "#fdb4b4cf" }}>
                                                                    Looks like you left the group ... or the group leader {this.state.joinedLFGPost.username} removed you from the group.
                                                                </span>
                                                                <span style={{ "font-size": ".8em", "margin-bottom": "1em" }}>
                                                                    If you want to join some other groups head over to Standard LFG or Gamer Matchmaker pages.
                                                                </span>
                                                            </small>
                                                        </div>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    
                </Stack>
            </>
        );
    }
}

export default SocialGroups;
