import React, { Component } from "react"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import SocialNotPressed from "./SocialNotPressed";

class SocialNotPressedTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: undefined,
            userdata: undefined,
        }

        // These are the eventKeys of the tabs for non-new users.
        const tabs = [
            "waiting-room",
        ]
        this.state.activeKey = tabs[0]

        // If there is a hash in the URL specifying which tab, go to that tab.
        if (!window.location.hash.empty) {
            const removedHash = window.location.hash.substring(1)
            const index = tabs.findIndex(x => x === removedHash)
            if (index >= 0)
                this.state.activeKey = tabs[index]
        }

        window.addEventListener(
            "hashchange",
            () => {
                const removedHash = window.location.hash.substring(1)
                const index = tabs.findIndex(x => x === removedHash)
                if (index >= 0)
                    this.setState({ activeKey: tabs[index] })
            },
            false,
        );
    }

    render() {
        return (
            <>
                <>
                    <Tabs
                        activeKey={this.state.activeKey}
                        onSelect={(k) => window.location.hash = `#${k}`}
                        transition={false}
                        id="social-group-tabs"
                        className="mb-2 pb-2"
                        justify
                    >
                        <Tab eventKey="waiting-room" title="Waiting Room" tabClassName="tab-color">
                            <SocialNotPressed
                                fbsAuth={this.props.fbsAuth}
                                fbsFirestore={this.props.fbsFirestore}
                                user={this.props.user}
                                username={this.props.username}
                                userdata={this.props.userdata}
                                answererData={this.props.answererData}
                                profile={this.props.profile}
                                disableGroupChat={this.props.disableGroupChat}
                                couldNotFindGroup={this.props.couldNotFindGroup}
                                onboardingNotFinsihed={this.props.onboardingNotFinsihed}
                                completeGamerLoungeOnboarding={this.props.completeGamerLoungeOnboarding}
                            />
                        </Tab>
                    </Tabs>
                </>
            </>
        );
    }
}

export default SocialNotPressedTabs;
