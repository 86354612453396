import React, { Component } from "react";
import { Button } from "react-bootstrap"

class SocialPersonalChatInput extends Component {
    constructor(props) {
        super(props);
    }

    // The input/textarea of AskAGamerAskerChat.
    // Everything done in this component simply passes the data back to AskAGamerAskerChat,
    // then sets state/calls a function, then passes it back here as props.
    // The logic for modifiedText is for uploading line breaks as %0A in firebase
    // and then rerending them on the front-end through renderFormattedText().
    render() {
        const { messageText, onMessageTextChange, onSendMessage, cooldown, uploadText } = this.props;
        return (
            <>
                <div className="messageinputs">
                    <textarea
                        style={{"font-weight":"500", "text-shadow":"0 0 #0808087a", "resize":"none"}}
                        placeholder={this.props.placeholderMessageInput ? "Select a user..." : "Type Message..."}
                        value={messageText}
                        onChange={ (e) => {
                            const inputText = e.target.value;
                            const modifiedText = inputText.replace(/\n/g, '%0A'); // Uses %0A for URL-encoded newline
                            onMessageTextChange(inputText, modifiedText)
                        }}
                        onKeyDown={ (e) => {
                            if (e.key === 'Enter' && !e.shiftKey) {
                                const inputText = e.target.value;
                                const modifiedText = `${uploadText}%0A`;
                                onMessageTextChange(inputText, modifiedText)
                                onSendMessage();
                            }
                            if (e.key === 'Enter' || e.shiftKey) {
                                const inputText = e.target.value;
                                const modifiedText = `${uploadText}%0A`;
                                onMessageTextChange(inputText, modifiedText)
                            }
                        }}
                        maxLength={269}
                        disabled={this.props.placeholderMessageInput}
                    />
                    <div style={{"z-index":"1", "position":"absolute", "right":"22px"}}>
                        { !cooldown 
                          ?
                            <Button
                                disabled={this.props.placeholderMessageInput || messageText === ""}
                                onClick={onSendMessage}
                                style={{"height":"85%", "width":"100%"}}
                            >
                                {this.props.placeholderMessageInput ? " ... " : " Send "}
                            </Button>
                          :
                            <Button 
                                className="d-flex flex-row align-items-center justify-content between"
                                disabled 
                                style={{"height":"85%", "width":"100%", "padding":".5em"}}
                            >
                                <span style={{"margin-right":".5em"}}>
                                    Sending
                                </span>
                                <div className="circle-inline-loading-animation " />
                            </Button>
                        }   
                    </div>
                </div>
            </>
        )
    }

}

export default SocialPersonalChatInput;
